import React from 'react'
import { Table } from 'react-bootstrap'
import './FlexTable.scss'

/**
 * FlexTable.js
 *
 * This hook is a base table class that will be the base class for any one of our tables. For now, it's pretty simple,
 * but eventually, we will add pagination ability, as well as dropdown abilities in the footer, as well as a spinner overlay.
 * Anything that we need from our tables.
 */
const FlexTable = React.memo(
  ({ totalRows, headerRows, bodyRows, emptyTableMessage }) => (
    <div className="flex-table-container">
      <Table className="flex-table">
        <thead>{headerRows}</thead>
        <tbody>{bodyRows}</tbody>
      </Table>
      {!(totalRows > 0) && (
        <div className="empty-table-message">{emptyTableMessage}</div>
      )}
    </div>
  )
)

export default FlexTable
