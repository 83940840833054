import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { NavLink } from 'react-router-dom'
import '../../AccountSettings.scss'
import NumOfApiCallsTable from '../tables/NumOfApiCallsTable'

const ApiCallsPage = () => {
  const { serviceRequest } = useService()
  const { account } = useUser()
  const [apiCalls, setApiCalls] = useState([])

  //unnecessary api call here. Let's move the data state up for this so we can share it between the table and page components instead of making separate calls
  useEffect(() => {
    serviceRequest({
      path: '/dashboard/calls',
    }).then(apiCalls => setApiCalls(apiCalls))
    // eslint-disable-next-line
  }, [account])

  return (
    <div className="account-settings">
      <div className="sub-page-header">
        <div className="left-col left-col-bottom-spacer">
          <NavLink to="/account-settings/billing">
            <ArrowBackIcon /> Back
          </NavLink>
          <h1>API Calls</h1>
        </div>
      </div>
      <NumOfApiCallsTable tableData={apiCalls} isFullView={true} />
    </div>
  )
}

export default ApiCallsPage
