import React, { useEffect } from 'react'
import { useService } from 'model/hooks/useService'
import { useTheme } from 'model/hooks/useTheme'
import { envLocalhost } from 'model/utils/env'
import { Navigate, useParams } from 'react-router-dom'
import NoDataPage from './components/no-data-page/NoDataPage'
import { useCheckoutQueryParamContext } from './contexts/CheckoutQueryParamContext'
import { handleFetchCheckoutData } from './util/FetchCheckoutData'

const CheckoutPublicRoutes = () => {
  const { checkoutSlug } = useParams()
  const { serviceRequest } = useService()
  const {
    handleSetFieldValues,
    setProjectData,
    projectData,
    isFixedOrAny,
    pageStatus,
    isPageActive,
    initialLoad,
    setInitialLoad,
  } = useCheckoutQueryParamContext()

  const { updateColor, updateImage, getPrimaryStyle } = useTheme()

  //if path is project-selection
  const isProjectSelectionPage =
    !window.location.pathname.includes('stripe-checkout') &&
    !window.location.pathname.includes('choose-amount') &&
    !window.location.pathname.includes('purchase-confirmation')

  // fetch project data
  useEffect(() => {
    if (isProjectSelectionPage)
      handleFetchCheckoutData(
        checkoutSlug,
        serviceRequest,
        handleSetFieldValues,
        setProjectData,
        updateColor,
        updateImage,
        getPrimaryStyle,
        setInitialLoad
      )
    else setInitialLoad(true)
    // eslint-disable-next-line
  }, [checkoutSlug])

  useEffect(() => {
    document.title = 'Buy Carbon Credits | Direct Checkout'
  }, [])

  const hasOnlyOneProject = projectData !== null && projectData?.length === 1

  // if true, it means /projects-checkout endpoint didn't even return an empty array for Projects
  const noData = !!(!projectData || projectData?.length === 0)

  const noProjects = projectData !== null && projectData?.length === 0

  return (
    <>
      {initialLoad && (
        <>
          {envLocalhost() ? (
            <>
              {!noData && isPageActive(pageStatus) && (
                <>
                  {hasOnlyOneProject && isFixedOrAny === 'fixed' && (
                    <Navigate
                      to={`/external-offset-purchase/${checkoutSlug}/stripe-checkout/${projectData[0]?.project_slug}`}
                    />
                  )}
                  {hasOnlyOneProject && isFixedOrAny === 'any' && (
                    <Navigate
                      to={`/external-offset-purchase/${checkoutSlug}/choose-amount/${projectData[0]?.project_slug}`}
                    />
                  )}
                  {projectData?.length > 1 && (
                    <Navigate
                      to={`/external-offset-purchase/${checkoutSlug}/project-selection`}
                    />
                  )}
                </>
              )}
              {(noData || noProjects || !isPageActive(pageStatus)) && (
                <NoDataPage />
              )}
            </>
          ) : (
            <>
              {!noData && isPageActive(pageStatus) && (
                <>
                  {hasOnlyOneProject && isFixedOrAny === 'fixed' && (
                    <Navigate
                      to={`/${checkoutSlug}/stripe-checkout/${projectData[0]?.project_slug}`}
                    />
                  )}
                  {hasOnlyOneProject && isFixedOrAny === 'any' && (
                    <Navigate
                      to={`/${checkoutSlug}/choose-amount/${projectData[0]?.project_slug}`}
                    />
                  )}
                  {projectData?.length > 1 && (
                    <Navigate to={`/${checkoutSlug}/project-selection`} />
                  )}
                </>
              )}
              {(noData || noProjects || !isPageActive(pageStatus)) && (
                <NoDataPage />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default CheckoutPublicRoutes
