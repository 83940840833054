import React from 'react'
import { NotableBuyer } from 'interfaces/Project.interface'
import { capFirstLetter } from 'model/utils/capitalize-first-letter'
import CloverlyTooltip from 'view/shamrock/tooltip/Tooltip'

type Props = {
  notableBuyers: NotableBuyer[]
}

const NotableBuyers = ({ notableBuyers }: Props) => {
  const tooltip = 'Companies which have retired credits from this project.'
  return (
    <>
      {notableBuyers?.length ? (
        <section className="p-4 rounded-xl border border-neutral-80 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <p className="text-lg font-bold">Notable buyers</p>
            <CloverlyTooltip className="mr-3 w-[237px] text-sm px-2 py-1">
              <p>{tooltip}</p>
            </CloverlyTooltip>
          </div>
          <section className="flex gap-2 flex-wrap">
            {notableBuyers.map(buyer => (
              <div
                className="py-1 px-2 rounded bg-neutral-95 font-medium"
                key={buyer.company_name}
              >
                {capFirstLetter(buyer.company_name)}
              </div>
            ))}
          </section>
        </section>
      ) : null}
    </>
  )
}

export default NotableBuyers
