import React from 'react'

const CopyIcon = () => (
  <svg
    fill="none"
    height="13"
    viewBox="0 0 12 13"
    width="12"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m8.33341.0830078h-7c-.644579 0-1.166662.5220832-1.166662 1.1666622v8.16667h1.166662v-8.16667h7zm1.74999 2.3333322h-6.41665c-.64459 0-1.16667.52208-1.16667 1.16667v8.16669c0 .6446.52208 1.1666 1.16667 1.1666h6.41665c.6446 0 1.1667-.522 1.1667-1.1666v-8.16669c0-.64459-.5221-1.16667-1.1667-1.16667zm0 9.33336h-6.41665v-8.16669h6.41665z" />
  </svg>
)

export default CopyIcon
