import React, { useState } from 'react'
import VintageYearModal from '../../../modals/vintage/vintage-year-modal/VintageYearModal'
import InfoIcon from '../../icons/info-icon/InfoIcon'
import './VintageYearButton.scss'

const VintageYearButton = ({
  vintageYear,
  label = '',
  isForMarketplace,
  className = '',
  infoModalVerbiageSelection = 'default',
  deliveryDate,
  projectName,
}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <VintageYearModal
        showModal={showModal}
        setShowModal={bool => setShowModal(bool)}
        verbiageSelection={infoModalVerbiageSelection}
        deliveryDate={deliveryDate}
        projectName={projectName}
        vintageYear={vintageYear}
      />
      {!isForMarketplace ? (
        <div
          className={`vintage-year ${className}`}
          onClick={() => setShowModal(true)}>
          <span className="vintage-year-button">
            {label ? label : vintageYear + ' vintage'}
          </span>
          <InfoIcon />
        </div>
      ) : (
        <div
          className="vintage-year-marketplace"
          onClick={() => setShowModal(true)}>
          <span className="vintage-year-year">{vintageYear}</span>
          <InfoIcon />
        </div>
      )}
    </>
  )
}

export default VintageYearButton
