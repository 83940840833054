const color = {
  textNormal: '#11151B',
  textPrimary: '#007A70',
  textSecondary: '#657A80',
  textMuted: '#60747A',
  neutral95: '#F0F4F5',
  neutral80: '#C4D1D4',
  neutral60: '#8E9FA4',
  neutral40: '#4A5C64',
  secondary95: '#EDF7F6',
  secondary90: '#DBEFF0',
  secondary20: '#1A3F4C',
  yellow90: '#FFEBC7',
  green90: '#DBF5D6',
  purple90: '#ECDDFF',
  white: '#FFFFFF',
}

const projectTagBase = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 2,
  paddingTop: 2,
  paddingBottom: 2,
  paddingLeft: 4,
  paddingRight: 6,
  borderRadius: 4,
  fontSize: 7,
  fontWeight: 500,
}

const mainColumnContainerBase = {
  display: 'flex',
  marginBottom: 14,
}

const projectInventoryRow = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: `0.5px solid ${color.neutral80}`,
  paddingTop: 5,
  paddingBottom: 5,
}

const sidebarLayout = {
  page: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 8,
    paddingTop: 24,
    paddingBottom: 50,
    paddingLeft: 250,
    paddingRight: 24,
  },
  pageHeaderText: {
    fontFamily: 'Inter',
    fontSize: 9,
    fontWeight: 700,
    width: 200,
    marginBottom: 16,
  },
  sideColumn: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 230,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: color.secondary95,
  },
  mainColumnBreaker: {
    marginTop: 4,
    marginBottom: 8,
    borderTop: `0.5px solid ${color.neutral60}`,
  },
  mainColumnContainerHorizontal: {
    ...mainColumnContainerBase,
    display: 'flex',
    flexDirection: 'row',
  },
  unsdgTileBox: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    flexGrow: 2,
    gap: 3,
  },
  unsdgTileContainer: {
    width: 36,
    height: 36,
  },
  unsdgTile: {
    borderRadius: 3,
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  imageTileBox: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    flexGrow: 2,
    gap: 5,
  },
  imageTileContainer: {
    width: 54,
    height: 54,
  },
  imageTile: {
    borderRadius: 4,
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  mainColumnContainerVertical: {
    ...mainColumnContainerBase,
    flexDirection: 'column',
  },
  mainColumnVerticalSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainColumnVerticalSectionText: {
    fontSize: 9,
    fontWeight: 700,
  },
  mainColumnVerticalSectionLink: {
    marginTop: 4,
    fontWeight: 600,
    fontSize: 7,
    textDecoration: 'underline',
    color: color.textPrimary,
  },
  mainColumnSection: {
    fontSize: 9,
    fontWeight: 700,
    maxWidth: 107,
    minWidth: 107,
  },
  projectTags: {
    marginTop: 9,
    marginBottom: 3,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
  },
  projectTagLocation: {
    ...projectTagBase,
    backgroundColor: color.yellow90,
  },
  projectTagMechanism: {
    ...projectTagBase,
    backgroundColor: color.secondary90,
  },
  projectTagType: {
    ...projectTagBase,
    backgroundColor: color.green90,
  },
  projectTagRegistry: {
    ...projectTagBase,
    backgroundColor: color.purple90,
  },
  sideColumnFooter: {
    position: 'absolute',
    left: 0,
    bottom: 6,
    width: 230,
    padding: 24,
    color: color.secondary20,
  },
  sideColumnFooterText: {
    marginTop: 3,
    fontSize: 6,
    color: color.secondary20,
  },
  titleAndLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  pageTitle: {
    fontFamily: 'Inter',
    fontSize: 8,
    color: color.secondary20,
    marginLeft: 5,
  },
  titleText: {
    color: color.textNormal,
    fontWeight: 700,
    fontSize: 16,
    marginTop: 12,
  },
  longDescription: {
    marginTop: 8,
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 2,
  },
  locationIntro: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexGrow: 2,
  },
  locationText: {
    maxWidth: 130,
    maxLines: 5,
    fontSize: 8,
    paddingTop: 1,
  },
  locationMap: {
    boxSizing: 'border-box',
    height: 100,
    border: `0.5px solid ${color.neutral60}`,
    borderRadius: 3,
    marginTop: 8,
    overflow: 'hidden',
  },
  locationMapImage: {
    borderRadius: 3,
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  ratingImage: {
    objectFit: 'scale-down',
    width: 'auto',
    height: 9,
  },
  ratingBodyCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 3,
  },
  ratingIconBox: {
    paddingTop: 0.5,
  },
  registryImage: {
    objectFit: 'scale-down',
    width: 'auto',
    height: 10,
  },
  registryText: {
    fontSize: 8,
    fontWeight: 400,
    maxWidth: 120,
    maxLines: 5,
  },
  registryBodyCell: {
    minHeight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 3,
  },
  registryLink: {
    fontWeight: 600,
    fontSize: 8,
    textDecoration: 'underline',
    color: color.textPrimary,
  },
  shortDescText: {
    color: color.textNormal,
    fontWeight: 400,
    marginTop: 16,
    marginBottom: 8,
    fontSize: 8,
  },
  sidebarDisclaimerText: {
    fontWeight: 400,
    marginTop: 8,
    fontSize: 7,
    textWrap: 'wrap',
    textOverflow: 'none',
    color: color.secondary20,
  },
  sidebarDisclaimerLink: {
    fontWeight: 400,
    fontSize: 7,
    textDecoration: 'underline',
    color: color.secondary20,
    marginTop: 1,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 6,
    bottom: 30,
    left: 250,
    right: 0,
    textAlign: 'left',
    color: color.secondary20,
  },
  footerRight: {
    position: 'absolute',
    fontSize: 6,
    bottom: 30,
    right: 24,
    textAlign: 'left',
    color: color.secondary20,
  },
  horizontalPadded: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'stretch',
    paddingHorizontal: 3,
  },
}

const projectInventory = {
  projectInventorySection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 9,
  },
  projectInventoryHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  projectInventoryHeaderHeavy: {
    textAlign: 'left',
    fontWeight: 700,
    fontSize: 8,
  },
  projectInventoryHeaderLight: {
    color: color.textSecondary,
    textAlign: 'right',
    fontWeight: 400,
    fontSize: 7,
  },
  projectInventoryRow: {
    ...projectInventoryRow,
  },
  projectInventoryRowFirst: {
    ...projectInventoryRow,
    borderTop: `0.5px solid ${color.neutral40}`,
  },
  projectInventoryYear: {
    textAlign: 'left',
    fontWeight: 700,
    fontSize: 8,
    minWidth: 27,
    marginRight: 5,
  },
  projectInventoryForward: {
    textAlign: 'left',
    fontWeight: 400,
    fontSize: 7,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 2,
    paddingLeft: 3,
    paddingRight: 3,
    backgroundColor: color.white,
    color: color.textSecondary,
  },
  projectInventoryCost: {
    textAlign: 'right',
    fontWeight: 600,
    fontSize: 8,
  },
}

const quotePanel = {
  tableContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'flex-start',
    width: '100%',
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexGrow: 2,
    padding: 0,
    margin: 0,
  },
  tableHeader: {
    fontSize: 7,
    color: color.textMuted,
    fontWeight: 600,
  },
}

const quoteRow = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderTop: `0.5px solid white`,
  paddingBottom: 0,
  paddingTop: 4,
  gap: 2,
}

const projectTable = {
  quotePanelContainer: {
    backgroundColor: color.white,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    gap: 4,
    alignItems: 'stretch',
    justifyContent: 'center',
    marginTop: 22,
    marginBottom: 6,
  },
  quotePanelHeading: {
    fontSize: 8,
    fontWeight: 600,
    color: color.textSecondary,
    letterSpacing: 1,
  },
  quoteRow,
  quoteRowWithDivider: {
    ...quoteRow,
    borderTop: `0.5px solid ${color.neutral80}`,
  },
  quoteLabel: {
    color: color.textSecondary,
    fontSize: 8,
    fontWeight: 400,
  },
  quoteData: {
    color: color.textNormal,
    fontSize: 8,
    fontWeight: 600,
  },
  quoteForward: {
    color: color.textSecondary,
    backgroundColor: color.neutral95,
    borderRadius: 2,
    paddingVertical: 1,
    paddingHorizontal: 3,
    fontSize: 6.5,
  },
}

export { sidebarLayout, projectInventory, projectTable, quotePanel }
