import React from 'react'
import { formatCost } from 'model/utils/cost'
import { shortDate } from 'model/utils/date'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import './AccountSettingsTables.scss'

const CreditTable = React.memo(
  ({ id = 'ct', tableData, changePage, showPaginator = true }) => (
    /* notes to make this reusable:
    Pass in an array of strings for the table head tr's.
    Pass in tableDataRows instead of tableData.credits so that it can be generic.
    Convert any other repeated code into reusable code.

  */
    <>
      <div className="table-container">
        <Table>
          <thead>
            <tr>
              <td>Purchase Date</td>
              <td>Type</td>
              <td>Amount</td>
              <td>Receipt</td>
            </tr>
          </thead>
          <tbody>
            {tableData?.credits?.map((credit, index) => (
              <tr key={id + '_row_' + index}>
                <td>
                  {shortDate.format(
                    new Date(credit.created_at?.replace(/ /g, 'T'))
                  )}
                </td>
                <td>{credit.credit_type}</td>
                <td>
                  {formatCost(
                    Math.round(credit.amount_in_usd_cents / 100, 2),
                    'USD'
                  )}
                </td>
                <td className="link-cell">
                  {credit.receipt_url ? (
                    <a
                      target="_blank"
                      href={credit.receipt_url}
                      rel="noreferrer"
                    >
                      View
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!tableData?.total && (
          <div className="empty-table-message">No Credit History</div>
        )}
      </div>

      {showPaginator && tableData?.totalPages > 1 && (
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          pageCount={tableData?.totalPages}
          onPageChange={changePage}
          containerClassName={'pagination-btns'}
          previousLinkClassName={'previous-btn'}
          nextLinkClassName={'next-btn'}
          disabledClassName={'disabled-btns'}
          activeClassName={'pagination-active'}
        />
      )}
    </>
  )
)

export default CreditTable
