import React from 'react'

const SectionNavLink = React.memo(
  ({ name, title, selectedTab, onSelected }) => {
    const onClick = e => {
      e.preventDefault()
      onSelected(name)
    }

    return (
      <li>
        <a
          key={name}
          onClick={e => onClick(e)}
          className={selectedTab === name ? 'active' : ''}>
          {title}
        </a>
      </li>
    )
  }
)

export default SectionNavLink
