import React from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { download } from 'model/utils/download'
import CertificatePlaceholderIcon from 'view/components/icons/certificate-placeholder-icon/CertificatePlaceholderIcon'

const PortfolioCertificate = ({ certificateUrl, transactionId }) => {
  const { infoToast, warningToast } = useToast()
  const { serviceRequest } = useService()

  const downloadCertificate = event => {
    event.preventDefault()
    event.stopPropagation()
    console.log(`Fetching certificate from ${certificateUrl}`)
    serviceRequest({
      path: certificateUrl,
      pathAsUrl: true,
      options: { responseType: 'arraybuffer' },
    })
      .then(certificate => {
        infoToast('Certificate Downloaded.')
        download(
          certificate,
          `Purchase Certificate - ${transactionId}.pdf`,
          'application/pdf'
        )
      })
      .catch(() => warningToast('Could not download certificate at this time.'))
  }

  return certificateUrl && transactionId ? (
    <div className="image-cert-download-box">
      <CertificatePlaceholderIcon />
      <label>Certificate of purchase</label>
      <button className="download" onClick={downloadCertificate}>
        <DownloadIcon />
        <span>Download</span>
      </button>
    </div>
  ) : (
    <></>
  )
}

export default PortfolioCertificate
