import React from 'react'

const ApiDocsIcon = () => (
  <svg
    fill="none"
    height="37"
    viewBox="0 0 54 37"
    width="54"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m4 35.89c-1.79 0-3.25-1.46-3.25-3.25v-28.65c0-1.79 1.46-3.24 3.25-3.24h45.56c1.79 0 3.25 1.46 3.25 3.24v28.65c0 1.79-1.46 3.25-3.25 3.25z"
        fill="#fff"
      />
      <path
        d="m49.56 1.5c1.38 0 2.5 1.12 2.5 2.5v28.65c0 1.38-1.12 2.5-2.5 2.5h-45.56c-1.38 0-2.49-1.12-2.49-2.5v-28.65c-.01-1.38 1.11-2.5 2.49-2.5zm0-1.5h-45.56c-2.21 0-4 1.79-4 4v28.65c0 2.21 1.79 4 3.99 4h45.56c2.21 0 4-1.79 4-4v-28.65c0-2.21-1.79-4-3.99-4z"
        fill="#004453"
      />
      <path
        d="m53.55 8.56h-53.55v-5.04c0-1.94 1.58-3.52 3.52-3.52h45.89c2.29 0 4.14 1.85 4.14 4.14z"
        fill="#004453"
      />
      <path
        d="m23.02 30.38h-16.84001c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h16.84001c.41 0 .75.34.75.75s-.33.75-.75.75z"
        fill="#004453"
      />
      <path
        d="m16.45 14.72h-10.27001c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h10.27001c.41 0 .75.34.75.75s-.33.75-.75.75z"
        fill="#004453"
      />
      <path
        d="m42.8 14.72h-10.27c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h10.27c.41 0 .75.34.75.75s-.33.75-.75.75z"
        fill="#004453"
      />
      <path
        d="m18.62 19.94h-12.44001c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h12.44001c.41 0 .75.34.75.75s-.34.75-.75.75z"
        fill="#00cc8a"
      />
      <g fill="#004453">
        <path d="m40.6 25.16h-12.43c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h12.43c.41 0 .75.34.75.75s-.34.75-.75.75z" />
        <path d="m13.54 25.16h-7.28001c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7.29001c.41 0 .75.34.75.75s-.34.75-.76.75z" />
        <path d="m30.42 19.94h-7.29c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7.29c.41 0 .75.34.75.75s-.34.75-.75.75z" />
        <path d="m27.83 14.72h-7.29c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7.29c.41 0 .75.34.75.75s-.34.75-.75.75z" />
        <path d="m24.18 25.16h-7.28c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7.29c.41 0 .75.34.75.75s-.34.75-.76.75z" />
        <path d="m34.81 30.38h-7.29c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7.29c.41 0 .75.34.75.75s-.33.75-.75.75z" />
      </g>
    </g>
  </svg>
)

export default ApiDocsIcon
