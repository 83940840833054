import React from 'react'
import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'
import { useNavigate } from 'react-router-dom'
import Button from 'view/components/button/Button'
import PurchaseListItem from './PurchaseListItem'
import { useMarketplaceContext } from '../../../MarketplaceContext'
import './VintageSelector.scss'

const PurchaseComplete = ({
  formattedAmount,
  formattedCost,
  formattedCostPerTonne,
  PaymentMethodInfo,
}) => {
  const navigate = useNavigate()
  const {
    project,
    selectedVintage,
    downloadCertificate,
    purchaseTransactionId,
  } = useMarketplaceContext()

  const navigateToReceipt = e => {
    e.preventDefault()
    const receiptPath =
      project?.productType !== ProductType.PORTFOLIO
        ? `/transactions/details/${purchaseTransactionId}`
        : `/transactions/details/${purchaseTransactionId}?portfolioId=${project?.slug}`
    navigate(receiptPath)
  }

  return (
    <div className="vintage-selector">
      <section className="add-to-cart">
        <h3 className="purchase-list-header">Order details</h3>
        <div className="purchase-list">
          <PurchaseListItem
            project={project}
            vintageYear={selectedVintage?.vintage_year}
            formattedAmount={formattedAmount}
            formattedCost={formattedCost}
            formattedCostPerTonne={formattedCostPerTonne}
          />
        </div>

        <div className="divider"></div>

        <div className="carbon-total-row">
          <label>Carbon total</label>
          <data>{formattedAmount}</data>
        </div>

        <div className="payment-total-row">
          <label>Total</label>
          <data>{formattedCost}</data>
        </div>

        <div className="divider"></div>

        <div className="payment-method-row">
          <label>Payment method</label>
          <data className="light">{PaymentMethodInfo()}</data>
        </div>

        <div className="result-and-btn-v2">
          <Button cn="purchase-btn" onClick={navigateToReceipt}>
            View order details
          </Button>
          <Button cn="white-btn" onClick={downloadCertificate}>
            Download certificate of purchase
          </Button>
        </div>
      </section>
    </div>
  )
}

export default PurchaseComplete
