import React, { useEffect, useRef, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { displayDate, shortDate, timeWithZone } from 'model/utils/date'
import { setPageTitle } from 'model/utils/page'
import { getWeightUnit } from 'model/utils/units'
import CopyButton from 'view/components/buttons/copy-button/CopyButton'
import TransactionsTable from './table-with-pagination/TransactionsTable'
import Tag from './Tag'

const FootprintHistory = () => {
  const { account } = useUser()
  const { serviceRequestMulti, spreadResponses } = useService()

  const [page, setPage] = useState(0)
  const [nextPage, setNextPage] = useState(null)
  const [refreshToggle, setRefreshToggle] = useState('flip')
  const [tableData, setTableData] = useState({
    transactions: [],
    totalPages: 1,
  })
  const [transactionCache, setTransactionCache] = useState([])
  const [filter, setFilter] = useState('All Activities')
  const [tag, setTag] = useState(null)
  const [tagsList, setTagsList] = useState([])
  const [activityList, setActivityList] = useState({})

  // search states
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [search, setSearch] = useState('')
  const sourceRef = useRef()

  // current search filters.  Used to determine if cached data should be wiped.
  const [existingSearchFilters, setExistingSearchFilters] = useState({
    accountSlug: undefined,
    searchFilter: undefined,
    activityFilter: undefined,
    tagFilter: undefined,
  })

  const tableDataHeaders = ['Date', 'ID', 'Carbon', 'Activity', 'Tags', 'Note']

  const changePage = ({ selected }) => {
    setPage(selected)
    setRefreshToggle(refreshToggle === 'flip' ? 'flop' : 'flip')
  }

  useEffect(() => {
    setPageTitle('Transaction Footprints')
  }, [])

  useEffect(() => {
    const currentSearchFilters = {
      accountSlug: account.slug,
      searchFilter: search ? 'search=' + encodeURI(search) + '&' : '',
      activityFilter: filter !== 'All Activities' ? `&activity=${filter}` : '',
      tagFilter: tag ? `&tag=${tag}` : '',
    }

    if (
      existingSearchFilters.accountSlug !== currentSearchFilters.accountSlug ||
      existingSearchFilters.searchFilter !==
        currentSearchFilters.searchFilter ||
      existingSearchFilters.activityFilter !==
        currentSearchFilters.activityFilter ||
      existingSearchFilters.tagFilter !== currentSearchFilters.tagFilter
    ) {
      setTransactionCache([])
    }

    if (transactionCache[page]) {
      setTableData(transactionCache[page])
      setNextPage(transactionCache[page].next_page)
      setIsSearchLoading(false)
    } else {
      // the requests array will always include search, so let's add that first
      const requests = [
        {
          method: 'GET',
          path: `/dashboard/transactions/footprint?${
            currentSearchFilters.searchFilter
          }limit=10&page=${page + 1}${currentSearchFilters.activityFilter}${
            currentSearchFilters.tagFilter
          }`,
        },
      ]

      // if tags or activities are undefined, then we are executing the initial call right now, and we should fetch the tags & activities as well
      if (tagsList === undefined || activityList === undefined) {
        requests.push({
          method: 'GET',
          path: '/api/activity',
        })
        requests.push({
          method: 'GET',
          path: '/dashboard/tags',
        })
      } else setIsSearchLoading(true)

      // execute the axios multi request (1 or 2 requests, depending on if we're executing the initial call right now
      serviceRequestMulti({
        requests,
        sourceRef,
        showLoading: tagsList === undefined || activityList === undefined,
      })
        .then(
          spreadResponses(
            (footprintsResponse, activityResponse, tagsResponse) => {
              if (footprintsResponse?.data) {
                //filter to only have is_prod === true
                const prodFootprintsOnly =
                  footprintsResponse?.data?.transactions.filter(
                    transaction => transaction.is_production !== false
                  )
                footprintsResponse.data.transactions = prodFootprintsOnly
                transactionCache[page] = footprintsResponse.data
                setTransactionCache(transactionCache)
                setTableData(transactionCache[page])
                setNextPage(footprintsResponse.data.next_page)
                filterTableData(filter)
              }
              if (activityResponse) {
                if (activityResponse?.data?.activities) {
                  //console.log("Activity Response Returned 👍", activityResponse.data.activities)
                  const newActivity = { 'All Activities': 'All Activities' }
                  activityResponse.data.activities.forEach(
                    a => (newActivity[a] = a)
                  )
                  setActivityList(newActivity)
                } else setActivityList({})
              }
              if (tagsResponse) {
                if (tagsResponse?.data) {
                  //console.log("Tags Response Returned 👍", tagsResponse.data.tags)
                  setTagsList(tagsResponse.data.tags)
                } else setTagsList([])
              }
            }
          )
        )
        .catch(e => console.log('Request error or canceled!', e))
        .finally(() => setIsSearchLoading(false))
    }

    setExistingSearchFilters(currentSearchFilters)
    // eslint-disable-next-line
  }, [search, refreshToggle])

  useEffect(() => {
    if (account.slug !== existingSearchFilters.accountSlug) {
      setTagsList(undefined)
      setTag(null)
      setActivityList({})
      setFilter('All Activities')
    }
    setTransactionCache([])
    setPage(0)
    setRefreshToggle(refreshToggle === 'flip' ? 'flop' : 'flip')
    // eslint-disable-next-line
  }, [account, filter, tag])

  const onSearchChange = search => {
    setTransactionCache([])
    setPage(0)
    setSearch(search)
  }

  const date = date => {
    const dateObj = new Date(date)
    return (
      <div className="date-rows">
        <span className="month-and-year">
          {displayDate(dateObj, shortDate)}
        </span>
        <br />
        <span className="date-format">{timeWithZone(dateObj)}</span>
      </div>
    )
  }

  const filterTableData = state => {
    setTableData({
      ...transactionCache[page],
      transactions: transactionCache[page]?.transactions,
    })
    if (state === 'All Transactions') {
      setTableData(transactionCache[page])
    }
  }

  const displayData = tableData?.transactions
    ?.slice(0, tableData.limit)
    .map((item, index) => {
      return (
        <tr className="no-cursor" key={index}>
          <td className="col-date">{date(item.footprint_date)}</td>
          <td className="col-id">
            {item.footprint_id && (
              <>
                {item.footprint_id}
                <CopyButton
                  textToCopy={item.footprint_id}
                  className="copy-button"
                  toolTipLabel="Click to Copy ID"
                  buttonLabel=""
                />
              </>
            )}
          </td>
          <td>
            {getWeightUnit({
              weightType: account?.display_kg ? 'kg' : 'lbs',
              value: item?.co2e,
              roundUnits: 2,
              format: '%v %u',
            })}
          </td>
          <td className="cell-text-two-lines">
            <div>
              <p>{item.activity}</p>
            </div>
          </td>
          <td>
            {item.tags?.map(tag => {
              return <Tag key={tag} tag={tag} />
            })}
          </td>
          <td className="cell-text-two-lines">
            <div>
              <p>{item.note}</p>
            </div>
          </td>
        </tr>
      )
    })

  return (
    <TransactionsTable
      dropdownExists={true}
      tableDataHeaders={tableDataHeaders}
      onSearchChange={onSearchChange}
      isSearchLoading={isSearchLoading}
      displayData={displayData}
      rowCount={tableData?.transactions?.length}
      pageCount={tableData.totalPages}
      filter={filter}
      setFilter={setFilter}
      filterTableData={filterTableData}
      nextPage={nextPage}
      currentPage={page}
      changePage={changePage}
      emptyTableMessage="No Footprints"
      tag={tag}
      tags={tagsList}
      setTag={setTag}
      dropdownItems={activityList}
      testId="footprint-test-id"
    />
  )
}

export default FootprintHistory
