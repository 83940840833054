import React, { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useService } from 'model/hooks/useService'
import { useParams } from 'react-router-dom'
import ImageModal from './components/image-modal/ImageModal'
import ProjectDescription from './components/ProjectDescription'
import ProjectDetailsMap from './components/ProjectDetailsMap'
import ProjectDetailsPanel from './components/ProjectDetailsPanel'
import { determinePrimaryImg } from './components/ProjectDetailsUtility'
import ProjectUnsdg from './components/ProjectUnsdg'
import './ProjectDetails.scss'

const ProjectDetailsPublic = () => {
  const { serviceRequest } = useService()
  const { id } = useParams()
  const smallWidth = useMediaQuery('(max-width:1010px)')
  const [project, setProject] = useState(null)
  const [projectDescription, setProjectDescription] = useState(null)
  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 2,
  })
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: '',
  })

  const getProjectDetails = () => {
    if (id) {
      serviceRequest({
        path: `/project/${id}`,
        method: 'GET',
        showLoading: false,
      })
        .then(data => {
          setProject(data)
          setViewport({
            ...viewport,
            latitude: data.location.x,
            longitude: data.location.y,
          })
        })
        .catch(e => console.error(e.message))
    } else {
      console.error('Project id missing. Can not load project data.')
    }
  }

  useEffect(() => {
    getProjectDetails()
    // eslint-disable-next-line
  }, [])

  //populate paragraphs for long description
  useEffect(() => {
    if (project) {
      let splitStr = project?.long_description.split('\n')
      let filteredStrArray = splitStr.filter(str => str.trim().length !== 0)
      setProjectDescription({
        firstParagraph: filteredStrArray[0],
        followingParagraphs: project?.long_description.replace(
          filteredStrArray[0],
          ''
        ),
      })
    }
  }, [project])

  return (
    <>
      {imageModal.isOpen && (
        <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
      )}
      {project && (
        <div className="project-details public">
          <div className="page-header private">
            <section className="name-and-location">
              <h1>{project?.short_name || project?.name}</h1>
              <span className="location-in-header">
                {project?.short_name
                  ? project.name || project?.name
                  : (project?.city ? project?.city + ', ' : '') +
                    (project?.country || '')}
              </span>
            </section>
          </div>
          <div className="containers">
            <div className={`left-container ${smallWidth ? 'responsive' : ''}`}>
              {!smallWidth ? (
                <img
                  className="project-image"
                  src={determinePrimaryImg(project?.image_list)}
                  alt="product"
                />
              ) : (
                <></>
              )}

              <ProjectDescription
                project={project}
                projectDescription={projectDescription}
                setImageModal={setImageModal}
              />

              {smallWidth ? (
                <ProjectDetailsMap
                  className="responsive"
                  project={project}
                  viewport={viewport}
                  setViewport={setViewport}
                />
              ) : (
                <></>
              )}

              <ProjectUnsdg unsdg={project.unsdg} />
            </div>
            <div
              className={`right-container ${smallWidth ? 'responsive' : ''}`}
            >
              {smallWidth ? (
                <div className="project-details-image-container responsive public">
                  <img
                    className="project-image"
                    src={determinePrimaryImg(project?.image_list)}
                    alt="product"
                  />
                </div>
              ) : (
                <></>
              )}

              <ProjectDetailsPanel project={project} />

              {!smallWidth ? (
                <ProjectDetailsMap
                  project={project}
                  viewport={viewport}
                  setViewport={setViewport}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProjectDetailsPublic
