import React, { useEffect, useMemo, useState } from 'react'
import { setPageTitle } from 'model/utils/page'
import { Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import CustomerPaysIcon from 'view/components/icons/customer-pays-icon/CustomerPaysIcon'
import StorePaysIcon from 'view/components/icons/store-pays-icon/StorePaysIcon'
import '../../Shopify.scss'
import ShopifyFormControls from '../panels/shopify-form-controls/ShopifyFormControls'
import ShopifyFormPanel from '../panels/shopify-form-panel/ShopifyFormPanel'

const ShopifyBehaviorForm = React.memo(
  ({ shopifyData, saveShopifyDataChanges }) => {
    const [editMode, setEditMode] = useState(false)
    const {
      register,
      formState: { errors },
      watch,
      handleSubmit,
      reset,
      setValue,
    } = useForm({ defaultValues: useMemo(() => shopifyData, [shopifyData]) })
    const [defaultBehavior, setDefaultBehavior] = useState(undefined)
    const [behavior, setBehavior] = useState(undefined)

    useEffect(() => {
      setPageTitle('Behavior | Shopify App')
    }, [])

    useEffect(() => {
      const subscription = watch((value, { type }) => {
        setBehavior(value.behavior)
        setEditMode(type ? true : editMode)
      })
      return () => subscription.unsubscribe()
      // eslint-disable-next-line
    }, [watch])

    useEffect(() => {
      if (shopifyData) {
        shopifyData.flat_rate_amount_in_usd_dollars = (
          shopifyData.flat_rate_amount_in_usd_cents / 100
        ).toFixed(2)
        reset(shopifyData)
        setDefaultBehavior(shopifyData.behavior)
        setBehavior(shopifyData.behavior)
      }
      // eslint-disable-next-line
    }, [shopifyData])

    const changeBehavior = behavior => {
      setBehavior(behavior)
      setValue('behavior', behavior)
      setEditMode(true)
    }

    const cancelChanges = () => {
      reset()
      shopifyData.flat_rate_amount_in_usd_dollars =
        shopifyData.flat_rate_amount_in_usd_cents / 100
      setBehavior(defaultBehavior)
      setEditMode(false)
    }

    const onSubmit = data =>
      saveShopifyDataChanges({
        behavior: data.behavior,
        flat_rate_amount_in_usd_cents:
          data.flat_rate_amount_in_usd_dollars * 100,
        flat_rate_amount_in_currency: data.flat_rate_amount_in_usd_dollars,
        default_checked: data.default_checked,
      }).then(() => setEditMode(false))

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ShopifyFormPanel>
          <ShopifyFormControls
            editMode={editMode}
            setEditMode={setEditMode}
            cancelChanges={cancelChanges}
            saveChanges={handleSubmit(onSubmit)}
          />
          <div className="split-panel">
            <div className="left-panel">
              <h3>Behavior</h3>
              <div className="large-toggle-container">
                <div
                  onClick={() => changeBehavior('default')}
                  className={
                    'tall-box large-toggle ' +
                    (behavior === 'default' || behavior === 'flat_rate'
                      ? 'selected'
                      : '')
                  }
                >
                  <div className="icon-container">
                    <CustomerPaysIcon />
                  </div>
                  <span>Customer Pays</span>
                  <p className="toggle-desc">
                    Your customer decides if they want carbon neutral shipping,
                    and they pay the cost
                  </p>
                </div>
                <div
                  onClick={() => changeBehavior('always_green')}
                  className={
                    'tall-box large-toggle ' +
                    (behavior === 'always_green' ? 'selected' : '')
                  }
                >
                  <div className="icon-container">
                    <StorePaysIcon />
                  </div>
                  <span>Store Pays</span>
                  <p className="toggle-desc">
                    Every order has carbon neutral shipping, and your store pays
                    the cost (most impactful!)
                  </p>
                </div>
              </div>
            </div>
            <div className="right-panel">
              <h3>Rate</h3>
              <Form.Check
                id="behavior"
                name="behavior"
                type="radio"
                label="Default Rate"
                value="default"
                disabled={behavior === 'always_green'}
                {...register('behavior')}
              />
              <p className="radio-info">
                Carbon emissions and cost to offset are calculated when a
                customer adds an item to the cart
              </p>
              <Form.Check
                id="behavior"
                name="behavior"
                type="radio"
                label="Flat Rate"
                value="flat_rate"
                disabled={behavior === 'always_green'}
                {...register('behavior')}
              />
              <div className="radio-info">
                Customers pay a fixed rate for every carbon-neutral shipment
                (great for stores that ship from multiple locations!)
                <InputGroup
                  className={
                    'inner small' +
                    (behavior === 'always_green' ? ' disabled' : '')
                  }
                  type="text"
                >
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    id="flat_rate_amount_in_usd_dollars"
                    name="flat_rate_amount_in_usd_dollars"
                    type="number"
                    min=".28"
                    max="1000000"
                    step=".01"
                    isInvalid={errors.flat_rate_amount_in_usd_dollars}
                    disabled={behavior !== 'flat_rate'}
                    {...register('flat_rate_amount_in_usd_dollars')}
                  />
                </InputGroup>
              </div>
              <div
                className={
                  'payment-section' +
                  (behavior === 'always_green' ? ' disabled' : '')
                }
              >
                <h3>Payment Checkbox</h3>
                <p className="field-info">
                  How your customer decides if they want carbon neutral
                  shipping. Having the checkbox checked by default leads to a
                  higher participation rate (50-60% higher on average!)
                </p>
                <Form.Check
                  type="switch"
                  id="default_checked"
                  name="default_checked"
                  label="Checked by Default"
                  disabled={behavior === 'always_green'}
                  {...register('default_checked')}
                />
              </div>
            </div>
          </div>
        </ShopifyFormPanel>
      </Form>
    )
  }
)

export default ShopifyBehaviorForm
