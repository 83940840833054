/**
 * Formatter for a number that matches the user's locale.
 *
 * Example (en-US): 1,000,000.45
 *
 * @param date
 * @returns {string}
 */
const formatNumber = new Intl.NumberFormat()

const numberFormatter = (decimals: number) =>
  new Intl.NumberFormat('en-NZ', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })

/**
 * Exports public defs.
 */
export { formatNumber, numberFormatter }
