import React, { useEffect, useState } from 'react'
import { useService } from 'model/hooks/useService'
import { useParams } from 'react-router-dom'
import CO2Icon from 'view/components/icons/co2/CO2Icon'
import PriceTagIcon from 'view/components/icons/price-tag-icon/PriceTagIcon'
import PoweredByCloverly from 'view/components/powered-by-cloverly/PoweredByCloverly'
import SusReportHeader from './components/header/SusReportHeader'
import SustainabilityReportMap from './components/map/SustainabilityReportMap'
import OffsetAllocation from './components/offset-allocation/OffsetAllocation'
import ReportStat from './components/report-stat/ReportStat'
import TopProjects from './components/top-projects/TopProjects'
import './SustainabilityReport.scss'

const SustainabilityReport = () => {
  const { accountId } = useParams()
  const { serviceRequest } = useService()

  const [footprint, setFootprints] = useState({})

  useEffect(() => {
    serviceRequest({
      path: `/account/${accountId}/sustainability-report`,
    })
      .then(data => setFootprints(data))
      .catch(e => console.log(e))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {footprint && (
        <div className="sustainability-report-page">
          <SusReportHeader
            logoUrl={footprint?.logo_url}
            siteUrl={footprint?.site_url}
          />
          <SustainabilityReportMap projects={footprint?.projects} />
          <div className="stats-and-header">
            <h2>Our Green Initiative</h2>
            <div className="stats">
              <ReportStat icon={CO2Icon} footprint={footprint} />
              <ReportStat
                icon={PriceTagIcon}
                footprint={footprint}
                isPurchases
              />
            </div>
          </div>
          <div className="carbon-across-projects">
            <h2>Carbon Across Projects</h2>
            <div className="list-of-offset-allocation">
              {footprint?.offset_allocation?.map(allocation => (
                <OffsetAllocation
                  key={allocation.id}
                  offsetAllocation={allocation}
                  footprint={footprint}
                />
              ))}
            </div>
          </div>
          <TopProjects
            projects={footprint?.projects?.slice(0, 10)}
            weightType={footprint?.weight_units}
          />
          <footer>
            <PoweredByCloverly />
          </footer>
        </div>
      )}
    </>
  )
}

export default SustainabilityReport
