import React from 'react'
import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer'

/**
 * @param {number} width
 * @param {number} height
 * @returns {React.JSX.Element}
 */
const LocationIcon = ({ width = 9, height = 9 }) => (
  <Svg width={width} height={height} viewBox="0 0 18 18">
    <G clip-path="url(#clip0_1413_22596)">
      <Path
        d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z"
        stroke="#994D00"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M12.9675 14.4619L12.3094 13.8031C12.2389 13.7323 12.1508 13.6816 12.0541 13.6562L10.5473 13.2603C10.4135 13.2251 10.2974 13.1418 10.2211 13.0264C10.1448 12.9109 10.1138 12.7714 10.1339 12.6345L10.3013 11.4955C10.3155 11.3993 10.3544 11.3085 10.4141 11.2318C10.4739 11.1552 10.5525 11.0953 10.6423 11.0581L12.7833 10.168C12.8822 10.1269 12.9908 10.1149 13.0963 10.1335C13.2018 10.1521 13.2998 10.2004 13.3788 10.2727L15.1177 11.8625"
        stroke="#994D00"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M3.5498 12.9832L4.2473 12.5248C4.32455 12.4737 4.38801 12.4044 4.43204 12.323C4.47607 12.2415 4.49932 12.1505 4.49973 12.0579L4.51449 9.51047C4.51525 9.40007 4.54825 9.2923 4.60941 9.20039L6.08176 6.99961C6.12398 6.93694 6.1784 6.88342 6.24177 6.84224C6.30513 6.80106 6.37614 6.77308 6.45056 6.75995C6.52498 6.74683 6.60128 6.74883 6.67491 6.76585C6.74854 6.78286 6.81799 6.81454 6.8791 6.85898L8.27199 7.77305C8.38928 7.85753 8.53441 7.894 8.67769 7.875L10.8897 7.57547C11.0263 7.55716 11.1514 7.48935 11.2413 7.38492L12.8008 5.56875C12.8885 5.46706 12.9368 5.33737 12.9372 5.20313V3.51562"
        stroke="#994D00"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_1413_22596">
        <Rect width="18" height="18" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
)
export default LocationIcon
