import React from 'react'
import PropTypes from 'prop-types'
import CertificatePlaceholderIcon from 'view/components/icons/certificate-placeholder-icon/CertificatePlaceholderIcon'
import ReceiptPlaceholderIcon from 'view/components/icons/receipt-placeholder-icon/ReceiptPlaceholderIcon'
import './ConfirmationPageLink.scss'

const ConfirmationPageLink = ({ title, isReceipt, url, handleCertClick }) => {
  return (
    <>
      {isReceipt && (
        <a
          className="confirmation-page-link"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <section className="confirmation-link-icon">
            <ReceiptPlaceholderIcon />
          </section>
          <p className="link-title">{title}</p>
        </a>
      )}

      {!isReceipt && (
        <section
          onClick={e => handleCertClick(e)}
          className="confirmation-page-link"
        >
          <section className="confirmation-link-icon">
            <CertificatePlaceholderIcon />
          </section>
          <p className="link-title">{title}</p>
        </section>
      )}
    </>
  )
}

ConfirmationPageLink.propTypes = {
  title: PropTypes.string,
  isReceipt: PropTypes.bool,
  url: PropTypes.string,
  handleCertClick: PropTypes.func,
}

export default ConfirmationPageLink
