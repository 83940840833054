import React from 'react'
import { Project } from 'interfaces/Project.interface'
import { ratingLogoBaseUrl, ratingsMap } from 'model/utils/ratings'

type Props = {
  project: Project
}

const RatingsSection = ({ project }: Props) => {
  const ratingsList = project?.project_ratings.filter(rating =>
    ratingsMap.has(rating.rating_title)
  )

  return (
    <>
      {ratingsList?.length ? (
        <section className="p-4 flex flex-col border border-neutral-80 rounded-xl gap-4">
          <p className="font-bold text-lg">Ratings & Standards</p>
          <section className="flex gap-3">
            {ratingsList?.map(fetchedRating => {
              const rating = ratingsMap.get(fetchedRating.rating_title)
              return (
                <div key={rating?.title} className="flex flex-col gap-1">
                  <p className="font-semibold text-muted text-sm">
                    {rating?.title}
                  </p>
                  <div className="flex gap-2">
                    <img
                      className="w-5 h-4"
                      src={ratingLogoBaseUrl + rating?.logo}
                      alt={rating?.title}
                    />
                    <p>{fetchedRating?.rating}</p>
                  </div>
                </div>
              )
            })}
          </section>
        </section>
      ) : null}
    </>
  )
}

export default RatingsSection
