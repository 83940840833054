// contains a history of all pages that require history retention.  Currently, project, portfolio & transaction detail pages
const allPageHistory = []

// runtime memory of the previous path
let prevPath = null

// runtime memory of the current path
let currentPath = null

/**
 * Hook to allow pages which require access to the referring page for specific functionality.
 * Currently used for project, portfolio & transaction detail page dynamic back buttons.
 * @returns {{
 * getTransactionBackButtonInfo: ((function(): ({path: *, title: string}))|*), updatePaths: updatePaths,
 * getPortfolioBackButtonInfo: ((function(): ({path: *, title: string}))|*),
 * getProjectBackButtonInfo: ((function(): ({path: *, title: string}))|*)}}
 */
export const usePageHistory = () => {
  const updatePaths = () => {
    if (
      currentPath !== undefined &&
      currentPath?.split('?')[0] !== location.pathname
    ) {
      prevPath = currentPath
      currentPath = location.pathname + location.search
    }
    // console.log('Updated paths:', prevPath, currentPath)
  }

  const getProjectBackButtonInfo = ({ version }) => {
    updatePaths()

    // update page history if needed
    if (prevPath?.startsWith('/marketplace')) delete allPageHistory[currentPath]
    else if (
      prevPath?.startsWith('/details') ||
      prevPath?.startsWith('/portfolio-details')
    )
      allPageHistory[currentPath] = prevPath

    // based on page history, return back button data
    if (allPageHistory?.[currentPath]?.startsWith('/details'))
      return { title: 'Back to Transaction', path: allPageHistory[currentPath] }
    else if (allPageHistory?.[currentPath]?.startsWith('/portfolio-details'))
      return { title: 'Back to Portfolio', path: allPageHistory[currentPath] }
    return { title: 'All Projects', path: '/marketplace' + (version ? '-' + version : '') }
  }

  const getPortfolioBackButtonInfo = ({ version }) => {
    updatePaths()

    // update page history if needed
    if (prevPath?.startsWith('/marketplace')) delete allPageHistory[currentPath]
    else if (prevPath?.startsWith('/details'))
      allPageHistory[currentPath] = prevPath

    // based on page history, return back button data
    if (allPageHistory?.[currentPath]?.startsWith('/details'))
      return { title: 'Back to Transaction', path: allPageHistory[currentPath] }
    return { title: 'All Portfolios', path: '/marketplace' + (version ? '-' + version : '') }
  }

  const getTransactionBackButtonInfo = () => {
    updatePaths()

    // update page history if needed
    // for transactions back history, there are purchases & refunds that link here.  If the
    // referer is any one of these, let's save that.
    if (prevPath?.startsWith('/transactions'))
      allPageHistory[currentPath] = prevPath

    // based on page history, return back button data
    if (allPageHistory?.[currentPath]?.startsWith('/transactions'))
      return { title: 'Back', path: allPageHistory[currentPath] }
    return { title: 'Back', path: '/transactions' }
  }

  return {
    updatePaths,
    getPortfolioBackButtonInfo,
    getProjectBackButtonInfo,
    getTransactionBackButtonInfo,
  }
}
