import React from 'react'

/**
 * @param unsdg
 * @returns {React.JSX.Element}
 */
const UnsdgListItem = ({ unsdg }) => (
  <li className="flex gap-3">
    <img className="w-12 h-12 rounded" src={unsdg.icon} alt="icon" />
    <div className="flex flex-col">
      <h6 className="text-sm font-semibold">{unsdg.title}</h6>
      <p className="text-neutral-50 text-sm">{unsdg.description}.</p>
    </div>
  </li>
)

export default UnsdgListItem
