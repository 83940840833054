import React from 'react'
import ProjectList from 'view/pages/checkout-admin/components/pages/checkout-create-page/project-selector/project-list/ProjectList'
import { usePaymentContext } from '../../../../contexts/CheckoutContext'
import '../../../../Checkout.scss'
import './ProjectSelector.scss'

const ProjectSelector = () => {
  const {
    projects,
    setCheckedSelectState,
    setAllSelectState,
    sourceFilterSet,
    destFilterSet,
    setSourceFilterSet,
    setDestFilterSet,
    sourceHasChecked,
    destHasChecked,
  } = usePaymentContext()

  const addAll = e => setAllSelectState(true, e)
  const removeAll = e => setAllSelectState(false, e)
  const addChecked = e => setCheckedSelectState(false, e)
  const removeChecked = e => setCheckedSelectState(true, e)

  return (
    <div className="project-selector">
      <ProjectList
        title="Project list"
        type="source"
        projects={projects}
        filterSet={sourceFilterSet}
        setFilterSet={setSourceFilterSet}
        hasChecked={sourceHasChecked}
        setAll={addAll}
        setChecked={addChecked}
      />
      <ProjectList
        title="Selected projects"
        type="dest"
        projects={projects}
        filterSet={destFilterSet}
        setFilterSet={setDestFilterSet}
        hasChecked={destHasChecked}
        setAll={removeAll}
        setChecked={removeChecked}
      />
    </div>
  )
}

export default ProjectSelector
