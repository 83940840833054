import React, { ReactNode } from 'react'

type Props = {
  icon: ReactNode
  text: string | number
  bgColor: string
}

const PortfolioTag = ({ icon, text, bgColor }: Props) => {
  return (
    <div
      className={`py-1 px-2 items-center justify-center flex gap-1 w-fit rounded-md ${bgColor}`}
    >
      {icon}
      <p className="font-medium text-sm">{text}</p>
    </div>
  )
}

export default PortfolioTag
