import React, { useCallback, useEffect, useState } from 'react'
import { useProject } from 'model/hooks/checkout/useProject'
import { useService } from 'model/hooks/useService'
import { useTheme } from 'model/hooks/useTheme'
import { queryClient } from 'model/utils/queryClient'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CloverlyFooter from 'view/components/cloverly-footer/CloverlyFooter'
import DetailedPortfolioPanel from 'view/pages/checkout-public/components/detailed-project-panel/DetailedPortfolioPanel'
import { handleFetchCheckoutData } from 'view/pages/checkout-public/util/FetchCheckoutData'
import { useCheckoutQueryParamContext } from '../../contexts/CheckoutQueryParamContext'
import ChooseAmountWidget from '../choose-amount-widget/ChooseAmountWidget'
import DetailedProjectPanel from '../detailed-project-panel/DetailedProjectPanel'
import Header from '../header/Header'
import NoDataPage from '../no-data-page/NoDataPage'
import './AmountSelection.scss'

const AmountSelection = () => {
  const { checkoutSlug } = useParams()
  const { serviceRequest } = useService()
  const { updateColor, updateImage, getPrimaryStyle } = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const accountId = localStorage.getItem('account_slug') || null

  const [isReadyToEstimate, setIsReadyToEstimate] = useState(false)

  const {
    setAmount,
    amount,
    pageStatus,
    isPageActive,
    selectedProjectIsPortfolio,
    handleSetFieldValues,
    setProjectData,
    projectData,
    initialLoad,
    setInitialLoad,
  } = useCheckoutQueryParamContext()

  // fetch project data
  useEffect(() => {
    if (!projectData)
      handleFetchCheckoutData(
        checkoutSlug,
        serviceRequest,
        handleSetFieldValues,
        setProjectData,
        updateColor,
        updateImage,
        getPrimaryStyle,
        setInitialLoad
      )
    else setInitialLoad(true)
  }, [checkoutSlug, projectData])

  const setAmountValue = useCallback(e => setAmount(e), [setAmount])

  const { projectId } = useParams()

  //console.log('selectedProjectIsPortfolio', selectedProjectIsPortfolio)

  const { project } = useProject(
    projectId,
    accountId,
    selectedProjectIsPortfolio
  )

  //when Checkout button is clicked
  const handleInvokeEstimate = useCallback(bool => {
    setIsReadyToEstimate(bool)
  }, [])

  //route to stripe checkout or choose amount depending on isAmountFixed
  const handleForwardToNextPage = useCallback(() => {
    let pathToStripe = `/stripe-checkout/${project?.id}`
    const newUrl =
      window.location.pathname.replace(`/choose-amount/${project?.id}`, '') +
      pathToStripe
    //console.log('handleForwardToNextPage:', newUrl)
    navigate({
      pathname: newUrl,
      search: location.search,
      state: { prevPath: location.pathname, urlSearch: location.search },
    })
    // eslint-disable-next-line
  }, [history, location.search, project?.id, location.pathname])

  //go to Stripe checkout page
  useEffect(() => {
    if (isReadyToEstimate) handleForwardToNextPage()
  }, [isReadyToEstimate, handleForwardToNextPage])

  //invalidate/clear the estimate cache when user comes back to this page
  useEffect(() => {
    if (queryClient.getQueryData('estimateFromBE')) {
      queryClient.resetQueries('estimateFromBE', { exact: true })
    }
  }, [])

  return (
    <>
      {initialLoad && (
        <>
          {!isPageActive(pageStatus) ? (
            <NoDataPage />
          ) : (
            <div className="amount-selection-page">
              <Header title="Choose amount to purchase" />
              <div className="panel-and-controls">
                {selectedProjectIsPortfolio ? (
                  <DetailedPortfolioPanel project={project} />
                ) : (
                  <DetailedProjectPanel project={project} />
                )}

                <ChooseAmountWidget
                  project={project}
                  setIsReadyToEstimate={handleInvokeEstimate}
                  setAmountValue={setAmountValue}
                  amount={amount}
                />
              </div>
              <CloverlyFooter />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default AmountSelection
