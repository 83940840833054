import React, { useEffect, useState } from 'react'
import icon1 from '../icons/sus-icons/1.png'
import icon10 from '../icons/sus-icons/10.png'
import icon11 from '../icons/sus-icons/11.png'
import icon12 from '../icons/sus-icons/12.png'
import icon13 from '../icons/sus-icons/13.png'
import icon14 from '../icons/sus-icons/14.png'
import icon15 from '../icons/sus-icons/15.png'
import icon16 from '../icons/sus-icons/16.png'
import icon17 from '../icons/sus-icons/17.png'
import icon2 from '../icons/sus-icons/2.png'
import icon3 from '../icons/sus-icons/3.png'
import icon4 from '../icons/sus-icons/4.png'
import icon5 from '../icons/sus-icons/5.png'
import icon6 from '../icons/sus-icons/6.png'
import icon7 from '../icons/sus-icons/7.png'
import icon8 from '../icons/sus-icons/8.png'
import icon9 from '../icons/sus-icons/9.png'
import UNLogo from '../icons/sus-icons/UN-logo.png'
import './SustainableDevGoals.scss'

//Renders a list of icons depending on icons passed from database
const SustainableDevGoals = ({ icons }) => {
  const allIcons = [
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
    icon7,
    icon8,
    icon9,
    icon10,
    icon11,
    icon12,
    icon13,
    icon14,
    icon15,
    icon16,
    icon17,
  ]
  const [renderedIcons, setRenderedIcons] = useState([])

  const reducedIcons = icon => {
    switch (icon) {
      case 1:
        return icon1
      case 2:
        return icon2
      case 3:
        return icon3
      case 4:
        return icon4
      case 5:
        return icon5
      case 6:
        return icon6
      case 7:
        return icon7
      case 8:
        return icon8
      case 9:
        return icon9
      case 10:
        return icon10
      case 11:
        return icon11
      case 12:
        return icon12
      case 13:
        return icon13
      case 14:
        return icon14
      case 15:
        return icon15
      case 16:
        return icon16
      case 17:
        return icon17
      default:
        return undefined
    }
  }

  useEffect(() => {
    if (icons?.length > 0) {
      let passedIcons = []
      icons.forEach(icon => passedIcons.push(reducedIcons(icon)))
      let iconResults = allIcons.filter(icon =>
        passedIcons.find(passedIcon => icon === passedIcon)
      )
      setRenderedIcons(iconResults)
    }
    // eslint-disable-next-line
  }, [icons])

  return (
    <div className="unsdg-icons">
      {renderedIcons.map(icon => (
        <img key={icon} src={icon} alt="united-nations-project" />
      ))}
      <img src={UNLogo} alt="united-nations-project" />
    </div>
  )
}

export default SustainableDevGoals
