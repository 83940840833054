import React from 'react'
import { Project } from 'interfaces/Project.interface'
import { useUser } from 'model/hooks/account/useUser'
import { usePortfolioDetails } from 'model/hooks/marketplace/usePortfolioDetails'
import { handleCalculateCostPerTonne } from 'model/utils/cost'
import { useParams } from 'react-router-dom'
import PortfolioCalculator from './components/calculator/PortfolioCalculator'
import PortfolioCompositionList from './components/composition/PortfolioCompositionList'
import PortfolioMetrics from './components/metrics/PortfolioMetrics'
import PortfolioHeader from './components/PortfolioHeader'
import PortfolioHighlights from './components/PortfolioHighlights'
import PortfolioOverview from './components/PortfolioOverview'
import { useMarketplaceContext } from '../marketplace/MarketplaceContext'
import ImageGalleryPanel from '../project-details/components/image-gallery-panel/ImageGalleryPanel'
import ProjectUnsdgPanel from '../project-details/components/unsdg-panel/ProjectUnsdgPanel'

const Portfolio = () => {
  const { id: portfolioId } = useParams()
  const { currencyRates } = useMarketplaceContext()
  const { portfolio, portfolioImageList } = usePortfolioDetails(portfolioId)

  const { account } = useUser()
  const price = portfolio?.price_per_tonne_in_USD ?? 0
  const priceTag = `${handleCalculateCostPerTonne(price, account.default_currency, currencyRates)} / t`

  const unsdgList = () => {
    const list: number[] = []
    for (const project of portfolio?.offset_sources as Project[]) {
      project.unsdg?.forEach(unsdg => list.push(unsdg))
    }
    return list
  }

  return (
    <>
      {portfolio ? (
        <section>
          <PortfolioHeader portfolio={portfolio} price={priceTag} />
          <section className="w-full flex gap-5 px-10 py-6">
            <section className="w-3/5 flex flex-col gap-3">
              <PortfolioOverview
                shortDescription={portfolio.short_description}
              />
              <ImageGalleryPanel imageList={portfolioImageList()} />
              <PortfolioHighlights portfolio={portfolio} />
              <PortfolioCompositionList portfolio={portfolio} />
            </section>
            <section className="w-2/5 flex flex-col gap-3">
              <PortfolioCalculator portfolio={portfolio} price={priceTag} />
              <PortfolioMetrics portfolio={portfolio} />
              <ProjectUnsdgPanel unsdgList={unsdgList()} forPortfolio />
            </section>
          </section>
        </section>
      ) : null}
    </>
  )
}

export default Portfolio
