import React, { useEffect } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useParams } from 'react-router-dom'

const EcommerceSession = () => {
  const { token } = useParams()
  const { updateUser } = useUser()
  const { serviceRequest } = useService()

  useEffect(() => {
    serviceRequest({
      path: '/session',
      method: 'POST',
      data: { user: { token } },
    }).then(data => {
      updateUser(data)
      window.location.href = '/shopify'
    })
    // eslint-disable-next-line
  }, [])

  return <></>
}

export default EcommerceSession
