import React from 'react'
import { Dropdown } from 'react-bootstrap'
import CloseIcon from 'view/components/icons/close-icon/CloseIcon'
import '../../../../../../Checkout.scss'

const ProjectListFilter = React.memo(
  ({ filterSet, setFilterSet, filterTypes, clearChecked }) => {
    const renderSubFilterTypeLabel = subFilterType =>
      subFilterType
        ? subFilterType.label
          ? subFilterType.label
          : subFilterType
        : 'All'

    const updateFilterType = filterTypeLabel => {
      filterTypes.forEach(filterType => {
        if (filterType.label === filterTypeLabel)
          setFilterSet({ tier1: filterType, tier2: null })
      })
      clearChecked()
    }

    const updateSubFilterType = subFilterTypeLabel => {
      if (filterSet?.tier1?.label)
        switch (filterSet.tier1.label) {
          case 'Removal Time Period':
            filterSet?.tier1?.children.forEach(childFilterType => {
              if (childFilterType.value === subFilterTypeLabel)
                setFilterSet({ tier1: filterSet.tier1, tier2: childFilterType })
            })
            break
          default:
            setFilterSet({ tier1: filterSet.tier1, tier2: subFilterTypeLabel })
        }
      clearChecked()
    }

    const clearFilterSet = () => {
      setFilterSet({ tier1: null, tier2: null })
      clearChecked()
    }

    const renderFilterTypeChildren = filterType => (
      <>
        <Dropdown
          className="filter-by"
          value={filterSet.tier1}
          onSelect={eventKey => updateSubFilterType(eventKey)}
        >
          <Dropdown.Toggle className="standard-dropdown">
            <span>{renderSubFilterTypeLabel(filterSet.tier2)}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item key="0" eventKey={null}>
              All
            </Dropdown.Item>
            {filterType?.children?.map(type => (
              <Dropdown.Item
                key={type?.value ?? type}
                eventKey={type?.value ?? type}
              >
                {type?.label ?? type}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    )

    return (
      <>
        <Dropdown
          className="filter-by"
          value={filterSet.tier1}
          onSelect={eventKey => updateFilterType(eventKey)}
        >
          <Dropdown.Toggle className="standard-dropdown">
            <span>{filterSet.tier1 ? filterSet.tier1.label : 'Filter by'}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {filterTypes.map(type => (
              <Dropdown.Item key={type.label} eventKey={type.label}>
                {type.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {filterSet.tier1 && (
          <>
            {filterSet.tier1.children &&
              renderFilterTypeChildren(filterSet.tier1)}
            <button
              className="standard-dropdown clear-filters"
              onClick={() => clearFilterSet()}
            >
              <CloseIcon />
            </button>
          </>
        )}
      </>
    )
  }
)

export default ProjectListFilter
