import React from 'react'
import './EmptyProjectsListIcon.scss'

const EmptyProjectsListIcon = () => (
  <div className="empty-projects-list-icon">
    <svg
      width="132"
      height="132"
      viewBox="0 0 132 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="66" cy="66" r="66" fill="#F5FAFA" />
    </svg>
    <svg
      className="magnifier"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_397_18276)">
        <path
          d="M0 17.9C0 22.68 1.86 27.17 5.24 30.55C8.73 34.04 13.31 35.78 17.89 35.78C21.41 35.78 24.92 34.74 27.94 32.69L31.33 36.08L30.54 36.87C30.1 37.31 30.1 38.02 30.54 38.45L46.36 54.27C47.42 55.33 48.82 55.91 50.31 55.91C51.8 55.91 53.21 55.33 54.26 54.27C55.32 53.21 55.9 51.81 55.9 50.32C55.9 48.83 55.32 47.42 54.26 46.37L38.45 30.55C38.01 30.11 37.3 30.11 36.87 30.55L36.08 31.34L32.69 27.95C37.42 20.99 36.71 11.41 30.55 5.25C27.17 1.86 22.68 0 17.9 0C13.12 0 8.62 1.86 5.24 5.24C1.86 8.62 0 13.11 0 17.9ZM32.92 37.66L33.71 36.87L36.87 33.71L37.66 32.92L52.69 47.95C53.32 48.59 53.67 49.43 53.67 50.32C53.67 51.22 53.32 52.06 52.69 52.69C51.42 53.96 49.21 53.96 47.95 52.69L32.92 37.66ZM31.34 29.76L34.5 32.92L32.92 34.5L29.76 31.34L31.34 29.76ZM2.24 17.9C2.24 13.72 3.87 9.79 6.83 6.83C9.79 3.87 13.72 2.24 17.9 2.24C22.08 2.24 26.01 3.87 28.97 6.83C35.07 12.93 35.07 22.87 28.97 28.97C22.87 35.07 12.93 35.07 6.83 28.97C3.87 26.01 2.24 22.08 2.24 17.9Z"
          fill="#004453"
        />
        <path
          d="M13.6298 6.2999C13.7898 6.8999 14.3898 7.2399 14.9998 7.0899C16.8898 6.5799 18.8898 6.5799 20.7898 7.0899C22.6798 7.5999 24.4198 8.5999 25.7998 9.9799C26.0198 10.1999 26.3098 10.3099 26.5898 10.3099C26.8698 10.3099 27.1598 10.1999 27.3798 9.9799C27.8198 9.5399 27.8198 8.8299 27.3798 8.3999C25.7198 6.7399 23.6398 5.5299 21.3698 4.9199C19.0998 4.3099 16.6998 4.3099 14.4198 4.9199C13.8298 5.0899 13.4698 5.7099 13.6298 6.2999ZM8.39982 8.4099C7.95982 8.8499 7.95982 9.5599 8.39982 9.9899C8.61982 10.2099 8.90982 10.3199 9.18982 10.3199C9.46982 10.3199 9.75982 10.2099 9.97982 9.9899C10.2098 9.7599 10.4498 9.5399 10.6998 9.3299C10.9498 9.1199 11.2098 8.9199 11.4798 8.7399C11.9898 8.3899 12.1098 7.6899 11.7498 7.1799C11.3998 6.6699 10.6998 6.5499 10.1898 6.8999C9.87982 7.1199 9.56983 7.3599 9.26983 7.6099C8.96983 7.8599 8.67982 8.1299 8.39982 8.4099Z"
          fill="#004453"
        />
      </g>
      <defs>
        <clipPath id="clip0_397_18276">
          <rect width="55.91" height="55.91" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
)

export default EmptyProjectsListIcon
