import React from 'react'
import { ToastContainer } from 'react-toastify'

const Toast = () => (
  <ToastContainer
    position="bottom-right"
    autoClose={false}
    hideProgressBar={true}
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
)

export default Toast
