import React, { useState } from 'react'
import { Add, Remove } from '@mui/icons-material'
import ReactMarkdown from 'react-markdown'
import './ExpandableProjectData.scss'

type Props = {
  title?: string
  description?: string
}

const ExpandableProjectData = ({ title, description }: Props) => {
  // long description expanded view state
  const [expanded, setExpanded] = useState(false)

  const HeaderToggleButton = ({ title }: { title?: string }) => (
    <button
      className={`flex expandable-header items-center ${expanded ? 'expanded' : ''}`}
      onClick={() => setExpanded(!expanded)}
    >
      {expanded ? <Remove /> : <Add />}
      {title}
    </button>
  )

  return (
    !!title &&
    !!description && (
      <section className="expandable-panel">
        <HeaderToggleButton title={title} />
        {!!description && expanded && (
          <>
            <div className="long-description">
              <ReactMarkdown linkTarget="_blank">{description}</ReactMarkdown>
            </div>
          </>
        )}
      </section>
    )
  )
}

export default ExpandableProjectData
