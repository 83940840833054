import React from 'react'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'

const ResetPasswordForm = () => {
  const { register, errors, handleSubmit, getValues } = useForm()
  const url = new URL(window.location.href)
  const token = url.searchParams.get('token')
  const isNewAccount = url.searchParams.get('new_account') == 'true'
  const { serviceRequest } = useService()
  const { infoToast, warningToast } = useToast()

  const errorMessages = {
    required: 'This field is required',
  }

  const submitForm = () => {
    const data = getValues()
    serviceRequest({
      path: '/password',
      method: 'PUT',
      data: {
        user: {
          email: data.email,
          password: data.confirmPassword,
          token: token,
        },
      },
    }).then(data => {
      if (data.error) {
        warningToast(data.error)
      } else {
        infoToast('Password reset successfully, Redirecting...')
        setTimeout(() => (window.location.href = '/'), 1000)
      }
    })
  }

  return (
    <div className="user-form">
      <form onSubmit={handleSubmit(submitForm)}>
        <Form.Group as={Col} controlId="validationCustom02">
          <Form.Label>{isNewAccount ? 'Confirm Email' : 'Email'}</Form.Label>
          <Form.Control
            type="email"
            isInvalid={errors?.email}
            {...register('email', { required: errorMessages.required })}
          />
          {errors?.email && (
            <p className="invalid-message">{errors?.email.message}</p>
          )}
        </Form.Group>
        <Form.Group as={Col} controlId="validationCustom03">
          <Form.Label>{isNewAccount ? 'Password' : 'New Password'}</Form.Label>
          <Form.Control
            type="password"
            isInvalid={errors?.confirmPassword}
            {...register('confirmPassword', {
              validate: value =>
                value === getValues('password') ||
                errorMessages.passwordsDontMatch,
            })}
          />
          {errors?.confirmPassword && (
            <p className="invalid-message">{errors?.confirmPassword.message}</p>
          )}
        </Form.Group>
        <Button cn={'form-btn dark'} type={'submit'}>
          {isNewAccount ? 'Create Account' : 'Reset Password'}
        </Button>
      </form>
    </div>
  )
}

export default ResetPasswordForm
