import React from 'react'
import './CloverlyFooter.scss'

const CloverlyFooter = ({ cn }) => {
  return (
    <footer className={`cloverly-footer ${cn}`}>
      {/*<CloverlyIcon />*/}
      <p className="text-with-link">
        Powered by{' '}
        <a
          tabIndex="-1"
          href="https://www.cloverly.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cloverly
        </a>
      </p>
    </footer>
  )
}

export default CloverlyFooter
