import React from 'react'
import Logo from 'view/components/cloverly-logo/Logo'
import CloverlyBackgroundLogo from 'view/components/icons/cloverly-background-logo/CloverlyBackgroundLogo'
import './NoDataPage.scss'

const NoDataPage = () => {
  const email = 'support@cloverly.com'

  return (
    <div className="no-data-page-checkout">
      <div className="no-data-logo-container">
        <CloverlyBackgroundLogo />
      </div>
      <section className="no-data-info-box">
        <div className="logo-container">
          <Logo />
        </div>
        <h3 className="info-header">
          This Direct Checkout page is currently unavailable
        </h3>
        <p className="email-info">
          Questions? Email <a href={`mailto: ${email}`}>{email}</a>
        </p>
      </section>
    </div>
  )
}

export default NoDataPage
