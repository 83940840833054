import React, { useMemo } from 'react'
import { View } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { handleGenerateURL } from 'model/utils/image'
import { defaultMethodologyIconUrl } from 'model/utils/methodology'
import QMTag from '../../common-components/QMTag'
import SVGPDFImage from '../../common-components/SVGPDFImage'
import LocationIcon from '../../icons/LocationIcon'
import AvoidanceMechanismIcon from '../../icons/mechinism-icons/AvoidanceMechanismIcon'
import RemovalMechanismIcon from '../../icons/mechinism-icons/RemovalMechanismIcon'
import RegistryIcon from '../../icons/RegistryIcon'

type Props = {
  project: Project
  styles: any
}

const ProjectTagsPanel = ({ project, styles }: Props) => {
  /**
   * @type {string}
   */
  const methodologyIconUrl = useMemo(
    () =>
      handleGenerateURL(
        project.methodology_icon_url || defaultMethodologyIconUrl
      ),
    []
  )

  /**
   * @param {string} mechanism
   * @returns {string}
   */
  const getMechanismLabel = (mechanism: string) => {
    if (mechanism) {
      switch (mechanism.trim().toUpperCase()) {
        case 'AVOIDANCE':
          return 'Avoidance'
        case 'REMOVAL':
          return 'Removal'
      }
    }
    return ''
  }

  /**
   * @param {string} mechanism
   * @returns {React.JSX.Element}
   */
  const MechanismIcon = (mechanism: string) => {
    if (mechanism === 'Avoidance') return <AvoidanceMechanismIcon />
    return <RemovalMechanismIcon />
  }

  return (
    <View style={styles.projectTags}>
      {project.country && (
        <QMTag
          icon={<LocationIcon />}
          label={project.country}
          style={styles.projectTagLocation}
        />
      )}
      {project.offset_classification && (
        <QMTag
          icon={MechanismIcon(getMechanismLabel(project.offset_classification))}
          label={getMechanismLabel(project.offset_classification)}
          style={styles.projectTagMechanism}
        />
      )}
      {project.type && (
        <QMTag
          icon={<SVGPDFImage url={methodologyIconUrl} />}
          label={project.type}
          style={styles.projectTagType}
        />
      )}
      {project.registry_name && (
        <QMTag
          icon={<RegistryIcon />}
          label={project.registry_name}
          style={styles.projectTagRegistry}
        />
      )}
    </View>
  )
}

export default ProjectTagsPanel
