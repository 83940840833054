import React from 'react'

const CollapseIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M3.5 10L8.5 5L13.5 10"
        stroke="#60747A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default CollapseIcon
