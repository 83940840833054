import React from 'react'

const CloseIcon = (): React.ReactNode => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.062 10L0 8.938L3.938 5L0 1.062L1.062 0L5 3.938L8.938 0L10 1.062L6.062 5L10 8.938L8.938 10L5 6.062L1.062 10Z"
      fill="#0B7766"
    />
  </svg>
)

export default CloseIcon
