import React, { useEffect, useRef, useState } from 'react'
import { WebMercatorViewport } from '@math.gl/web-mercator'
import { debounce } from '@mui/material'
import html2canvas from 'html2canvas'
import { useUser } from 'model/hooks/account/useUser'
import { mapConfig } from 'model/utils/react-map'
import ReactMapGL, { Marker } from 'react-map-gl'
import PinIcon from 'view/components/icons/pin-icon/PinIcon'

const PortfolioDetailsMap = ({ projects, updateMapImage }) => {
  const { account } = useUser()
  const mapRef = useRef(null)
  const [mapUpdateAssigned, setMapUpdateAssigned] = useState(false)

  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 1,
  })

  useEffect(() => {
    const pinPoints = []
    if (projects)
      projects?.forEach(project =>
        pinPoints.push({
          latitude: project?.location.x,
          longitude: project?.location.y,
        })
      )
    if (pinPoints.length > 1) setViewport(getBoundsForPoints(pinPoints))
    else if (pinPoints.length === 1)
      setViewport({
        latitude: pinPoints[0].latitude,
        longitude: pinPoints[0].longitude,
        zoom: 9,
      })
  }, [account, projects])

  const applyToArray = (func, array) => func.apply(Math, array)

  const getBoundsForPoints = points => {
    const pointsLong = points.map(point => point.longitude)
    const pointsLat = points.map(point => point.latitude)
    return new WebMercatorViewport({
      width: 320,
      height: 180,
    }).fitBounds(
      [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
      ],
      { padding: { top: 46, right: 5, left: 5, bottom: 5 } }
    )
  }

  const extractMapImage = () => {
    html2canvas(document.getElementsByClassName('mapboxgl-map')[0], {
      logging: false,
    })
      .then(canvas => updateMapImage(canvas.toDataURL('image/jpeg', 0.9)))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (mapRef.current && !mapUpdateAssigned) {
      const map = mapRef.current.getMap()
      const debouncedGetMapImage = debounce(extractMapImage, 500)

      map.on('data', () => {
        debouncedGetMapImage()
      })

      setMapUpdateAssigned(true)
    }
  }, [])

  return (
    <>
      <ReactMapGL
        {...viewport}
        ref={mapRef}
        maxZoom={8}
        preserveDrawingBuffer={true}
        mapboxAccessToken={mapConfig.mapboxAccessToken}
        mapStyle="mapbox://styles/cloverly/ckykicffr64sj14qp56qaxksj"
        width="320px"
        height="180px"
        onMove={evt => setViewport(evt.viewState)}
        onViewportChange={nextViewport => setViewport(nextViewport)}
      >
        {projects?.map((project, idx) => (
          <Marker
            key={`${project?.id} ${idx}`}
            latitude={project?.location?.x}
            longitude={project?.location?.y}
            offset={mapConfig.markerOffset}
          >
            <PinIcon />
          </Marker>
        ))}
      </ReactMapGL>
    </>
  )
}

export default PortfolioDetailsMap
