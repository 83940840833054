import React, { useEffect, useState } from 'react'
import { shortDate, timeWithZone } from 'model/utils/date'
import { Dropdown, DropdownButton, NavDropdown, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'

const DebuggerTable = React.memo(
  ({
    id = 'dt',
    apiCalls = [],
    showFilters = true,
    pageCount = 0,
    changePage,
    filter = 'any',
    setFilter = () => {},
    responseFilter = 'any',
    setResponseFilter = () => {},
  }) => {
    const navigate = useNavigate()

    const [calls, setCalls] = useState(apiCalls)

    useEffect(() => {
      setCalls(apiCalls)
    }, [filter, responseFilter, apiCalls])

    return (
      <div className="table-container">
        {showFilters && (
          <div className="filter-container">
            <DropdownButton
              id="dropdown-basic-button"
              title={
                filter == 'any'
                  ? 'All Environments'
                  : filter[0].toUpperCase() + filter.substring(1)
              }
            >
              <Dropdown.Item onClick={() => setFilter('any')}>
                All Environments
              </Dropdown.Item>
              <NavDropdown.Divider />
              <Dropdown.Item onClick={() => setFilter('production')}>
                Production
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter('sandbox')}>
                Sandbox
              </Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              title={
                responseFilter == 'any'
                  ? 'All Response Types'
                  : `Status: ${responseFilter}`
              }
            >
              <Dropdown.Item onClick={() => setResponseFilter('any')}>
                All Response Types
              </Dropdown.Item>
              <NavDropdown.Divider />
              <Dropdown.Item onClick={() => setResponseFilter('200')}>
                Status: 200
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setResponseFilter('201')}>
                Status: 201
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setResponseFilter('400')}>
                Status: 400
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setResponseFilter('404')}>
                Status: 404
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setResponseFilter('500')}>
                Status: 500
              </Dropdown.Item>
            </DropdownButton>
          </div>
        )}

        <Table>
          <thead>
            <tr>
              <td>Created</td>
              <td>Environment</td>
              <td>Request</td>
              <td>Response</td>
            </tr>
          </thead>
          <tbody>
            {calls?.map((apiCall, index) => (
              <tr
                key={id + '_row_' + index}
                onClick={() =>
                  navigate(
                    `/developer-tools/api-debugger/details/${apiCall._id['$oid']}`
                  )
                }
              >
                <td>
                  <div className="highlight">
                    {shortDate.format(
                      new Date(apiCall.created_at?.replace(/ /g, 'T'))
                    )}
                  </div>
                  {timeWithZone(
                    new Date(apiCall.created_at?.replace(/ /g, 'T'))
                  )}
                </td>
                <td>{apiCall.environment}</td>
                <td>
                  {apiCall.request_method} {apiCall.path}
                </td>
                <td>{apiCall.response_status}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {pageCount > 1 && (
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            initialPage={0}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={'pagination-btns'}
            previousLinkClassName={'previous-btn'}
            nextLinkClassName={'next-btn'}
            disabledClassName={'disabled-btns'}
            activeClassName={'pagination-active'}
          />
        )}

        {!apiCalls?.length && (
          <div className="empty-table-message">No API Calls</div>
        )}
      </div>
    )
  }
)

export default DebuggerTable
