/**
 * Sets the browser's page title.
 *
 * @param pageName
 * @returns {`${string} | Cloverly Dashboard`}
 */
const setPageTitle = pageName =>
  (document.title = `${pageName} | Cloverly Dashboard`)

/**
 * Exports public defs.
 */
export { setPageTitle }
