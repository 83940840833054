import React, { ComponentProps, ReactNode } from 'react'
import { cn } from 'model/utils/shadcn/utils'
import InfoIcon from 'view/components/icons/info-icon/InfoIcon'
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'view/shadcn-components/ui/tooltip'

type Props = {
  trigger?: ReactNode
  children: ReactNode
  className?: ComponentProps<'div'>['className']
  triggerClassName?: ComponentProps<'div'>['className']
  placement?: 'top' | 'right' | 'bottom' | 'left'
}

const CloverlyTooltip = ({
  children,
  className,
  trigger,
  triggerClassName,
  placement,
}: Props) => {
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger className={cn('', triggerClassName)}>
          {trigger ?? <InfoIcon />}
        </TooltipTrigger>
        <TooltipContent
          side={placement ?? 'top'}
          className={cn('bg-neutral-20 text-white py-[14px] px-4', className)}
        >
          {children}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default CloverlyTooltip
