import React from 'react'
import PropTypes from 'prop-types'
import Button from 'view/components/button/Button'
import ButtonLink from 'view/components/button/ButtonLink'

const ProjectPanelButton = ({
  isPurchasable,
  isDisabled,
  isNotEnoughInventory,
  onClickLeadGenNotify = null,
  onClick,
  isForModal,
}) => {
  const purchaseBtnText = isForModal ? 'Purchase' : 'Add to Order'

  return (
    <div className="project-panel-button">
      {isPurchasable === false ? (
        <Button cn="purchase" onClick={onClickLeadGenNotify}>
          Get a Quote
        </Button>
      ) : isNotEnoughInventory ? (
        <ButtonLink
          cn="purchase"
          href="https://www.cloverly.com/contact?type_of_inquiry=Existing%20Customer%20Offset%20Purchase"
          disabled={isDisabled}
          newtab
        >
          Contact Us
        </ButtonLink>
      ) : (
        <Button cn="purchase" disabled={isDisabled} onClick={onClick}>
          {purchaseBtnText}
        </Button>
      )}
    </div>
  )
}

ProjectPanelButton.propTypes = {
  vintageYear: PropTypes.string,
  isPurchasable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isNotEnoughInventory: PropTypes.bool,
  onClickLeadGenNotify: PropTypes.func,
  onClick: PropTypes.func,
  isForModal: PropTypes.bool,
}

export default ProjectPanelButton
