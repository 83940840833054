import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import Subnav from 'view/components/subnav/Subnav'
import SubNavLink from 'view/components/subnav/SubNavLink'

const AccountSettingsSubnav = () => {
  const { account } = useUser()

  /*1. If child account and billing_method isn't parent
    2. OR if parent account
    3. OR if neither child account nor parent account
    4. AND if billing method is NOT no_billing
  */
  const hasBillingTab =
    ((account?.is_child && account?.billing_method !== 'parent') ||
      account?.is_parent ||
      (!account?.is_child && !account?.is_parent)) &&
    account?.billing_method !== 'no_billing'

  return (
    <Subnav>
      <SubNavLink title="Overview" href="/account-settings" exact={true} />
      <SubNavLink title="Users" href="/account-settings/users" exact={true} />
      {hasBillingTab && (
        <SubNavLink
          title="Billing"
          href="/account-settings/billing"
          exact={true}
        />
      )}
      {account?.is_parent && (
        <SubNavLink
          title="Sub Accounts"
          href="/account-settings/children"
          exact={true}
        />
      )}
      {!account?.is_child && account?.is_cobranding_enabled && (
        <SubNavLink
          title="Account Branding"
          href="/account-settings/branding"
          exact={true}
        />
      )}
    </Subnav>
  )
}

export default AccountSettingsSubnav
