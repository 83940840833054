import React, { MouseEvent, useCallback, useEffect, useRef } from 'react'
import { handleCalculateCostPerTonne } from 'model/utils/cost'
import { Form } from 'react-bootstrap'
import { useMarketplaceContext } from '../../pages/marketplace/MarketplaceContext'
import { CloverlyButton } from '../cloverly-button/CloverlyButton'
import TableOverlay from '../table-overlay/TableOverlay'
import './CalculatorInputGroup.scss'

type Props = {
  classname?: string
  shortTonneLabel?: boolean
  showToolbar?: boolean
  cost_per_kg_carbon_in_usd_cents?: number
  addToCart?: (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
  selectedProduct: any
  placeholder?: string
  mode?: any
  enableEstimation?: boolean
  theme?: any
}

const CalculatorInputGroup = ({
  classname = '',
  shortTonneLabel = false,
  showToolbar = false,
  cost_per_kg_carbon_in_usd_cents = 0,
  addToCart,
  selectedProduct,
  placeholder = 'Amount...',
  mode = null,
  enableEstimation = true,
  theme = undefined,
}: Props) => {
  const {
    amount,
    weightType,
    setWeightType,
    weightTypeOptions,
    setAmount,
    currencyRates,
    isEstimationLoading,
    calculatedTotalAmount,
    setIsEstimationLoading,
    debounceFetchEstimate,
    selectedVintage,
    resetEstimate,
    hasInventoryError,
    defaultCurrency,
  } = useMarketplaceContext()

  const inputRef = useRef<HTMLInputElement>(null)

  //cost per tonne
  const costInDollars = cost_per_kg_carbon_in_usd_cents / 100
  const formattedCostPerTonne =
    handleCalculateCostPerTonne(costInDollars, defaultCurrency, currencyRates) +
    '/tonne'

  const getOptionLabel = (option: string) =>
    shortTonneLabel && option === 'tonnes' ? 't' : option

  const handleSetAmount = useCallback((e: any) => {
    setAmount(Number(e.target.value))
  }, [])

  useEffect(() => {
    if (enableEstimation) {
      //console.log('CalculatorInputGroup:useEffect()', selectedProduct, selectedVintage, amount, weightType)
      if (Number(amount) === 0 && inputRef.current) inputRef.current.value = ''
      if (!isNaN(amount) && Number(amount) > 0) {
        setIsEstimationLoading(true)
        debounceFetchEstimate({
          mode,
          amount,
          weightType,
          selectedProduct,
          selectedVintage,
        })
      } else {
        resetEstimate()
      }
    }
  }, [mode, amount, weightType, selectedProduct, selectedVintage])

  /**
   * @param estimatedDollarAmount
   * @param formattedCostPerTonne
   * @returns {React.JSX.Element}
   */
  const CostEstimateLabel = ({
    estimatedDollarAmount,
    formattedCostPerTonne,
  }: {
    estimatedDollarAmount?: number
    formattedCostPerTonne?: string
  }) => (
    <>
      <div className="estimate">{estimatedDollarAmount}</div>
      <div className="cost">{formattedCostPerTonne}</div>
    </>
  )

  return (
    <section className={`calculator ${theme}`}>
      <div className="tabs-and-inputs">
        <div className={`input-fields-and-title ${classname}`}>
          <h6 className="mb-2">Carbon Amount</h6>
          <Form.Group className="input-fields">
            <Form.Control
              type="number"
              min={1}
              placeholder={placeholder}
              onChange={handleSetAmount}
              ref={inputRef}
              value={amount === 0 ? '' : amount}
            />
            <Form.Select
              value={weightType}
              onChange={e => setWeightType(e.target.value)}
            >
              {weightTypeOptions.map((option: any, idx: number) => (
                <option key={idx} value={option}>
                  {getOptionLabel(option)}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
      </div>
      {showToolbar && (
        <div className="calc-toolbar">
          <div className="cost-label">
            {isEstimationLoading ? (
              <TableOverlay />
            ) : calculatedTotalAmount?.estimatedDollarAmount ? (
              <CostEstimateLabel
                estimatedDollarAmount={
                  calculatedTotalAmount?.estimatedDollarAmount
                }
                formattedCostPerTonne={formattedCostPerTonne}
              />
            ) : (
              formattedCostPerTonne
            )}
          </div>
          <CloverlyButton
            disabled={hasInventoryError || isEstimationLoading}
            onClick={addToCart}
          >
            Add to Order
          </CloverlyButton>
        </div>
      )}
    </section>
  )
}

export default CalculatorInputGroup
