import React from 'react'
import CodeIcon from '@mui/icons-material/Code'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import HomeIcon from '@mui/icons-material/Home'
import ListIcon from '@mui/icons-material/List'
import SettingsIcon from '@mui/icons-material/Settings'
import Logo from 'view/components/cloverly-logo/Logo'
import './ThemePreviewWidget.scss'

const ThemePreviewWidget = ({ base64Image }) => (
  <div className="theme-preview-container">
    <div className="app-header">
      {!base64Image ? (
        <Logo />
      ) : (
        <img className="preview" src={`data:image/*;base64,${base64Image}`} />
      )}
    </div>
    <div className="app-body">
      <div className="side-nav">
        <ul>
          <li>
            <a className="item-active">
              <HomeIcon />
              <span>Dashboard</span>
            </a>
          </li>
          <li>
            <a>
              <ListIcon />
              <span>Transactions</span>
            </a>
          </li>
          <li>
            <a>
              <CreditCardIcon />
              <span>Buy Credits</span>
            </a>
          </li>
          <li>
            <a>
              <CodeIcon />
              <span>Developer Tools</span>
            </a>
          </li>
          <li>
            <a>
              <SettingsIcon />
              <span>Account Settings</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="app-page">
        <p>
          Lorem ipsum dolor sit amet, consectetur sed do eiusmod tempor incidunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
          nostrud.
        </p>
        <div className="button-container">
          <button onClick={e => e.preventDefault()}>Primary Button</button>
          <a>Link Text</a>
        </div>
      </div>
    </div>
  </div>
)

export default ThemePreviewWidget
