import React from 'react'
import '../../../../project-details/ProjectDetails.scss'
import VintageListItem from './VintageListItem'

const VintageList = ({
  vintageList = [],
  selectedVintage,
  setSelectedVintage,
  maxHeightEnabled = false
}) => {

  return (
    <section className={`vintage-list ${maxHeightEnabled ? 'max-height-enabled' : ''}`}>
      {vintageList.map((vintage, i) => (
        <VintageListItem
          key={`vintage-${i}`}
          vintage={vintage}
          handleClick={setSelectedVintage}
          isSelected={vintage.id === selectedVintage?.id}
        />
      ))}
    </section>
  )
}

export default VintageList
