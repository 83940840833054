import React, { useCallback } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  VintageModalState,
  usePurchaseModal,
} from 'model/hooks/marketplace/usePurchaseModal'
import { Modal } from 'react-bootstrap'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import InvoiceIcon from 'view/components/icons/invoice-icon/InvoiceIcon'
import PurchaseComplete from './components/PurchaseComplete'
import PurchaseConfirmation from './components/PurchaseConfirmation'
import VintageSelector from './components/VintageSelector'
import { useMarketplaceContext } from '../../MarketplaceContext'
import '../../Marketplace.scss'
import './PurchaseModal.scss'

const PurchaseModal = ({ projectEstimate }) => {
  const { project, showModalWithValues, showEmptyStateModal } =
    useMarketplaceContext()

  const {
    account,
    card,
    closeModal,
    modalState,
    handleSelectionAction,
    handlePurchaseAction,
    renderModalTitle,
    handleBack,
    formattedAmount,
    formattedCost,
    formattedCostPerTonne,
  } = usePurchaseModal()

  const PaymentMethodInfo = useCallback(() => {
    switch (account?.billing_method) {
      case 'stripe':
        return (
          <>
            <div>{card?.brand?.toUpperCase()}</div>
            <div>••••{card?.last4}</div>
          </>
        )
      case 'invoice':
        return (
          <>
            <InvoiceIcon />
            <div>Monthly Invoice</div>
          </>
        )
      default:
        return <></>
    }
  }, [account?.billing_method, card?.brand, card?.last4])

  return (
    <Modal
      onHide={() => closeModal()}
      show={showModalWithValues || showEmptyStateModal}
      size="lg"
      backdrop="static"
      className="buy-vintage-modal"
      centered
    >
      <Modal.Header>
        <div className="label-container">
          {modalState === VintageModalState.CONFIRMATION && (
            <button className="back" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </button>
          )}
          <label>{renderModalTitle(modalState)}</label>
        </div>
        <CloseDialogButton onClick={() => closeModal()} />
      </Modal.Header>
      <Modal.Body>
        {modalState === VintageModalState.SELECTION && (
          <VintageSelector
            listTitle={project.name}
            projectEstimate={projectEstimate}
            handleAction={handleSelectionAction}
            maxHeightEnabled={true}
          />
        )}
        {modalState === VintageModalState.CONFIRMATION && (
          <PurchaseConfirmation
            listTitle={project.name}
            projectEstimate={projectEstimate}
            PaymentMethodInfo={PaymentMethodInfo}
            formattedAmount={formattedAmount}
            formattedCost={formattedCost}
            formattedCostPerTonne={formattedCostPerTonne}
            handleAction={handlePurchaseAction}
          />
        )}
        {modalState === VintageModalState.COMPLETE && (
          <PurchaseComplete
            listTitle={project.name}
            projectEstimate={projectEstimate}
            PaymentMethodInfo={PaymentMethodInfo}
            formattedAmount={formattedAmount}
            formattedCost={formattedCost}
            formattedCostPerTonne={formattedCostPerTonne}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default PurchaseModal
