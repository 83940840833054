import { IssuanceMix } from 'interfaces/Portfolio.interface'
import { Project, ProjectTranche } from 'interfaces/Project.interface'

export const listOfHighlights = (highlights?: string) => {
  if (!highlights) return []
  if (highlights.search(/\*/) < 0) return [highlights]
  return highlights
    .split('*')
    .map(highlight => highlight.trim())
    .filter(highlight => highlight.length > 0)
}

export const metricColors = [
  '#007B71',
  '#58CCAE',
  '#10558A',
  '#9ECBF4',
  '#9A3CA7',
  '#E6A2EF',
  '#BD0012',
  '#F09B57',
  '#238910',
  '#9BE782',
  '#683D0D',
  '#BB9B80',
]
export const sortAlphabetically = (obj: any, desc?: boolean) => {
  // Get the keys and sort them alphabetically
  const sortedKeys = Object.keys(obj).sort((a, b) => {
    return desc ? b.localeCompare(a) : a.localeCompare(b)
  })
  // Create a new object with sorted keys
  const sortedObject: any = {}
  sortedKeys.forEach(key => {
    sortedObject[key] = obj[key]
  })
  return sortedObject
}

export const sortObjByKeys = (obj: Record<string, number>) => {
  const sortedKeys = Object.keys(obj).sort((a, b) => parseInt(a) - parseInt(b))
  const sortedObj: Record<string, number> = {}
  sortedKeys.forEach(key => {
    sortedObj[key] = obj[key]
  })
  return sortedObj
}

export const sortByPercentage = (objToSort: object) => {
  const sortedArray = Object.entries(objToSort)?.sort(
    (a: any, b: any) => b[1] - a[1]
  )
  return Object.fromEntries(sortedArray as [])
}

//format permanence
export const getPermanence = (permanence_mix: any) => {
  const sortedPermMix = sortAlphabetically(permanence_mix, true)
  const formattedPermanence: any = {}
  Object.keys(sortedPermMix).forEach(item => {
    const percentage = sortedPermMix[item]
    const upperCasedItem = item.toUpperCase()
    if (upperCasedItem === 'SHORT')
      formattedPermanence['<100 years'] = percentage
    if (upperCasedItem === 'MEDIUM')
      formattedPermanence['100-999 years'] = percentage
    if (upperCasedItem === 'LONG')
      formattedPermanence['1000+ years'] = percentage
  })
  //add 'unspecified' to the end
  if ('unspecified' in sortedPermMix) {
    formattedPermanence['Unspecified'] = sortedPermMix['unspecified']
  }
  return formattedPermanence
}

export const getIssuanceMix = (issuance_mix?: IssuanceMix) => {
  const keyMapping: Record<string, string> = {
    true: 'Issued',
    false: 'Forward',
  }
  const updatedObject: Record<string, any> = {}
  for (const oldKey in issuance_mix) {
    // Use the new key if mapped, otherwise keep the old key
    const newKey = keyMapping[oldKey as keyof typeof keyMapping] || oldKey
    updatedObject[newKey] = issuance_mix[oldKey as keyof typeof issuance_mix]
  }
  //always return 'Issued' first, then 'Forward'
  return sortAlphabetically(updatedObject, true)
}

//group projects by id
const groupedById = (projects: Project[]): Project[] => {
  return projects.reduce((acc: any, project) => {
    if (!acc[project.id as keyof typeof acc]) {
      acc[project.id] = []
    }
    acc[project.id].push(project)
    return acc
  }, {})
}

//group each project by active_tranche
export const groupedProjects = (projects: Project[]) => {
  const projectsWithTranches: Project[] = []
  Object.values(groupedById(projects)).forEach((projArr: any) => {
    const tranches: ProjectTranche[] = []
    //push each project's active_tranche into a new field called 'tranches'
    projArr.forEach((proj: Project) => tranches.push(proj.active_tranche))
    //by this point, projects already have tranches we need, so only keep the first one to avoid duplicates
    projectsWithTranches.push({ ...projArr[0], tranches })
  })
  return projectsWithTranches
}

/**
 * Convert project estimate to quote (used in the project estimate section)
 * @param projectEstimate
 */
export const convertEstimateToQuote = (projectEstimate: any) => ({
  totalWeightInTonnes: projectEstimate?.total_weight / 1000,
  totalCost: projectEstimate?.total_cost,
  currencyType: projectEstimate?.currency_type,
})
