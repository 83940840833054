import React from 'react'
import { CarouselItem } from 'interfaces/Portfolio.interface'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import './ImageGalleryPanel.scss'

type Props = {
  imageList?: CarouselItem[]
}

type Image = {
  is_primary: boolean
  original: string
  thumbnail: string
  project_name?: string
}

const ImageGalleryPanel = ({ imageList }: Props) => {
  const formattedImageList = () => {
    const formattedImageList: Image[] = []
    const existingImageList: string[] = []
    imageList?.forEach(img => {
      // do not include duplicate images (same url)
      if (!existingImageList.includes(img.url)) {
        formattedImageList.push({
          is_primary: img.is_primary,
          original: img.url,
          thumbnail: img.url,
          project_name: img.project_name,
        })
        existingImageList.push(img.url)
      }
    })
    // sort images so that the primary image is always first
    return formattedImageList.sort((a, b) =>
      (a.is_primary ? 1 : 0) < (b.is_primary ? 1 : 0) ? 1 : -1
    )
  }

  //image with project title (if it exists)
  const renderItem = (item: any) => (
    <div
      className="item-container"
      style={{ backgroundImage: `url(${item.original})` }}
    >
      {item.project_name && (
        <p className="text-sm font-medium bg-white px-[6px] py-2 w-fit rounded-md border border-neutral-80 absolute bottom-2 left-2">
          {item.project_name}
        </p>
      )}
    </div>
  )

  //thumbnail
  const renderThumbInner = (item: ReactImageGalleryItem) => (
    <div
      className="item-thumb-container"
      style={{ backgroundImage: `url(${item.original})` }}
    />
  )

  const images = formattedImageList()

  return (
    !!imageList?.length &&
    images.length > 0 && (
      <ImageGallery
        items={images}
        slideDuration={200}
        renderItem={renderItem}
        renderThumbInner={renderThumbInner}
        showFullscreenButton={false}
        showPlayButton={false}
        showThumbnails={images.length > 1}
        showNav={images.length > 1}
      />
    )
  )
}

export default ImageGalleryPanel
