import React from 'react'
import { Tooltip } from 'react-tooltip'
import ExternalLinkIcon from 'view/components/icons/external-link-icon/ExternalLinkIcon'

const RatingsDataField = ({ rating }) => {
  // the BEZERO rating URL
  const BEZERO_RATING_URL = 'https://bezerocarbon.com/ratings/#rating-scale'

  const ratingTitle =
    rating.rating_title !== 'BeZero Carbon Rating' ? (
      rating.rating_title
    ) : (
      <>
        <div
          data-tip="The BeZero Carbon Rating is conveyed<br>using an eight point alphabetic scale<br> from AAA to D."
          data-effect="solid"
          data-border={true}
          data-border-color="#dee2e6"
          data-text-color="#000"
          data-background-color="#fff"
          data-multiline={true}
        >
          <Tooltip className="rating-tooltip" />
          <a href={BEZERO_RATING_URL} target="_blank" rel="noreferrer">
            {rating.rating_title}
            <ExternalLinkIcon />
          </a>
        </div>
        <div className="updated-indicator">UPDATED</div>
      </>
    )

  return (
    <div className="rating-field">
      <span className="rating">{rating.rating}</span>
      <span className="rating-title">{ratingTitle}</span>
    </div>
  )
}

export default RatingsDataField
