import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DoneIcon from '@mui/icons-material/Done'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import { Col, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import CopyButton from 'view/components/buttons/copy-button/CopyButton'
import PaymentOptions from './payment-options/PaymentOptions'
import ProjectSelector from './project-selector/ProjectSelector'
import RemoveVintageModal from '../../../../../modals/vintage/remove-vintage-modal/RemoveVintageModal'
import VintageYearModal from '../../../../../modals/vintage/vintage-year-modal/VintageYearModal'
import { usePaymentContext } from '../../../contexts/CheckoutContext'
import '../../../Checkout.scss'
import './CheckoutCreatePage.scss'

const CheckoutCreatePage = () => {
  const {
    account,
    getDirectCheckoutUrl,
    loadCheckoutPage,
    dataLoaded,
    navigateToHomePage,
    savePageChanges,
    register,
    errors,
    setValue,
    getValues,
    page,
    onDataLoaded,
    handleSubmit,
    recheckErrors,
    preventFormSubmit,
    setSubmitAttempted,
    updateIncludeVintageProjects,
    showRemoveVintageModal,
    setShowRemoveVintageModal,
    selectedVintageProjectNames,
  } = usePaymentContext()

  const { checkout_page_slug } = useParams()

  // manages open state for archive component
  // const [isOpen, setIsOpen] = useState(false)
  const [showVintageYearModal, setShowVintageYearModal] = useState(false)

  useEffect(() => {
    loadCheckoutPage(checkout_page_slug, onDataLoaded)
    // eslint-disable-next-line
  }, [])

  // if the account has changed, but a page is currently loaded, redirect user to direct checkout admin home page
  useEffect(() => {
    if (page?.checkout_page_slug) navigateToHomePage()
    // eslint-disable-next-line
  }, [account])

  /**
   * Handles any save invocation with a given new status.  New status can be set to the page's current
   * status if we only want to save page changes but not change the page's status.
   *
   * @param data
   */
  const onSubmit = data => {
    setSubmitAttempted(true)
    const errorsFound = recheckErrors()
    if (errorsFound) scroll.scrollTo(0, { containerId: 'pages', duration: 0 })
    else savePageChanges(data)
  }

  return (
    <>
      <VintageYearModal
        showModal={showVintageYearModal}
        setShowModal={bool => setShowVintageYearModal(bool)}
        verbiageSelection="checkout-admin"
      />
      <RemoveVintageModal
        showModal={showRemoveVintageModal}
        setShowModal={bool => setShowRemoveVintageModal(bool)}
        selectedVintageProjectNames={selectedVintageProjectNames}
        updateIncludeVintageProjects={updateIncludeVintageProjects}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control
          isInvalid={errors?.project_selection}
          {...register('project_selection')}
          hidden
        />
        <Form.Control
          isInvalid={errors?.purchase_option}
          {...register('purchase_option')}
          hidden
        />
        <div className="payments create">
          <div className="header-section">
            <button className="arrow-back" onClick={() => navigateToHomePage()}>
              <ArrowBackIcon />
            </button>
            <h1 className="standard">Direct Checkout page settings</h1>
            {dataLoaded && (
              <div className="page-header-button-container">
                {page.status === 'ARCHIVED' && (
                  <button
                    className="unarchive-page mini-icon-left"
                    type="submit"
                    onClick={() => setValue('status', 'DRAFT')}
                  >
                    Unarchive Page
                  </button>
                )}
                {page.status !== 'ARCHIVED' && (
                  <button className="save mini-icon-left" type="submit">
                    <DoneIcon />
                    Save
                  </button>
                )}
                {page.status !== 'ARCHIVED' && (
                  <button
                    className="publish mini-icon-left"
                    type="submit"
                    onClick={() => setValue('status', 'ACTIVE')}
                  >
                    {page.status === 'ACTIVE' && (
                      <>
                        <ToggleOnIcon />
                        Published
                      </>
                    )}
                    {page.status !== 'ACTIVE' && <>Publish Page</>}
                  </button>
                )}
              </div>
            )}
          </div>

          {dataLoaded && (
            <>
              {(errors?.name ||
                errors?.project_selection ||
                errors?.purchase_option ||
                errors?.amount_value) && (
                <div className="error-box">
                  The following settings are required before saving:
                  <ul>
                    {errors?.name && (
                      <li>
                        Name: Please enter a name for your Direct Checkout page.
                      </li>
                    )}
                    {errors?.project_selection && (
                      <li>
                        Project selection: Please select at least one project
                        for your Direct Checkout page.
                      </li>
                    )}
                    {(errors?.purchase_option || errors?.amount_value) && (
                      <li>
                        Purchase options: Please configure your Direct Checkout
                        page’s purchase options.
                      </li>
                    )}
                  </ul>
                </div>
              )}

              <div className="content-section">
                <h3 className="section-title">General settings</h3>

                <Form.Group
                  as={Col}
                  controlId="validationName"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    aria-describedby="nameHelpBlock"
                    placeholder="Enter name..."
                    className="name-field"
                    type="text"
                    isInvalid={errors?.name}
                    {...register('name')}
                    onKeyDown={e => preventFormSubmit(e)}
                  />
                  {errors?.name && (
                    <p className="invalid-message">{errors?.name.message}</p>
                  )}
                  <Form.Text id="nameHelpBlock">
                    The name is used to identify this Direct Checkout page in
                    your account, and will not be publicly visible.
                  </Form.Text>
                </Form.Group>

                {page.status === 'ACTIVE' && checkout_page_slug && (
                  <div className="inline-contents">
                    <p className="section-intro top-spaced">
                      The Direct Checkout page is available at:
                      <br />
                      <a
                        href={getDirectCheckoutUrl() + checkout_page_slug}
                        target={checkout_page_slug}
                        className="inline-link"
                      >
                        {getDirectCheckoutUrl() + checkout_page_slug}
                      </a>
                    </p>
                    <CopyButton
                      className="copy-button"
                      placement="top"
                      buttonLabel="Copy page link"
                      textToCopy={getDirectCheckoutUrl() + checkout_page_slug}
                    />
                  </div>
                )}
              </div>

              <div className="content-section">
                <h3 className="section-title">Project selection</h3>
                <p className="section-intro">
                  Select one or more projects for your customers to choose from.
                </p>
                <div className="vintage-section">
                  <Form.Check
                    {...register('include_forward_credits')}
                    checked={getValues('include_forward_credits')}
                    onChange={e => {
                      updateIncludeVintageProjects(
                        e,
                        !getValues('include_forward_credits')
                      )
                    }}
                  />
                  <span>
                    Make forward credits available to your buyers.{' '}
                    <a onClick={() => setShowVintageYearModal(true)}>
                      Learn more
                    </a>
                  </span>
                </div>
                <ProjectSelector />
              </div>

              {errors?.amount_type && (
                <p className="invalid-message">
                  tt{errors?.amount_type.message}
                </p>
              )}

              <PaymentOptions />

              <div className="content-section">
                <h3 className="section-title">Success message</h3>
                <p className="section-intro">
                  Set a custom message for users who successfully purchase from
                  this page.
                </p>
                <Form.Group as={Col} controlId="validationName">
                  <Form.Label>Success message text</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add message here"
                    className="message-field"
                    {...register('custom_message')}
                  />
                </Form.Group>
              </div>

              <div className="content-section">
                <h3 className="section-title">Save page</h3>
                <button className="publish" type="submit">
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      </form>
    </>
  )
}

export default CheckoutCreatePage
