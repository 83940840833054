/**
 * @type {string}
 */
const defaultMethodologyIconUrl = 'https://imagedelivery.net/OeX1-Pzk5r51De534GGSBA/default.png/public'

/**
 * Exports public defs.
 */
export {
  defaultMethodologyIconUrl
}
