import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { Table } from 'react-bootstrap'

const AccountTable = React.memo(({ id = 'at', accounts }) => {
  const { user } = useUser()
  return (
    <Table>
      <thead>
        <tr>
          <td>Account Name</td>
          <td>Role</td>
        </tr>
      </thead>
      <tbody>
        {accounts?.map((account, index) => (
          <tr key={id + '_row_' + index}>
            <td className="name-cell">
              {account.name}{' '}
              {account.owner_id === user.user_id ? '(Owner)' : ''}
            </td>
            <td>{account.role}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
})

export default AccountTable
