import React from 'react'
import { formatCost } from 'model/utils/cost'
import { displayDate, shortDate, timeWithZone } from 'model/utils/date'
import { getWeightUnit } from 'model/utils/units'
import Tag from './Tag'

const displayTag = tags =>
  tags?.map((tag, idx) => {
    while (idx < 2) {
      return <Tag cn="transaction-table" key={tag.key} tag={tag.key} />
    }
  })

const date = date => {
  const dateObj = new Date(date?.replace(/ /g, 'T'))
  return (
    <div className="date-rows">
      <span className="month-and-year">{displayDate(dateObj, shortDate)}</span>
      <br />
      <span className="date-format">{timeWithZone(dateObj)}</span>
    </div>
  )
}

const navigateToPortfolio = (transactionId, portfolioId, navigate) => {
  transactionId &&
    navigate(
      `/transactions/details/${transactionId}${
        portfolioId ? `?portfolioId=${portfolioId}` : ''
      }`
    )
}

export const getFormattedTableData = (tableData, portfoliosAPI) => {
  const portfolioTag = 'Portfolio'

  // map the transaction ids associated to a single portfolio
  const portfolioTransactionMapping = portfoliosAPI.reduce((obj, item) => {
    obj[item.portfolioId]
      ? obj[item.portfolioId].transcationIds.push(...item.transaction_id)
      : (obj[item.portfolioId] = {
          id: item.portfolioId,
          name: item.portfolioName,
          transactionIds: [item.transaction_id],
        })
    return obj
  }, {})

  // traverse through the table data and replace name for transaction ids associated to a portfolio
  // with the relative portfolio name and portfolio tag in the table data. persist other data.
  tableData?.transactions?.slice(0, tableData.limit)?.map(transaction => {
    let portfolioName = undefined
    const transactionInPortfolio = portfolioTransactionMapping.find(
      portfolio => {
        const foundTransaction = portfolio?.transcationIds.includes(
          transaction.transaction_id
        )
        portfolioName = portfolio?.name
        return foundTransaction
      }
    )

    if (transactionInPortfolio)
      return {
        ...transaction,
        project: {
          ...transaction.project,
          name: (
            <div key={transaction.transaction_id}>
              <p>{portfolioName}</p>
              <p className="portfolio-tag">{portfolioTag}</p>
            </div>
          ),
        },
      }
    return transaction
  })
}

export const getDisplayData = (tableData, account, navigate) =>
  tableData?.transactions?.slice(0, tableData.limit).map((item, idx) => {
    return (
      <tr
        key={idx}
        onClick={() =>
          navigateToPortfolio(item.transaction_id, item?.portfolio_id, navigate)
        }
      >
        <td>{date(item.created_at || item.purchased_at)}</td>
        <td>
          {formatCost(
            item.cost?.in_requested_currency.total_cost,
            item.cost?.requested_currency
          )}
        </td>
        <td>
          {getWeightUnit({
            weightType: account?.display_kg ? 'kg' : 'lbs',
            value: item?.total_co2e_in_kg,
            roundUnits: 2,
            format: '%v %u',
          })}
        </td>
        <td className="cell-text-two-lines">
          <div>
            <p>{item.project?.name || item?.name}</p>
            {item?.name && <p className="portfolio-tag">Portfolio</p>}
          </div>
        </td>
        <td className="cell-text-two-lines">
          <div>{displayTag(item?.tags)}</div>
        </td>
        <td className="cell-text-two-lines">
          <div>
            <p>{item.note}</p>
          </div>
        </td>
      </tr>
    )
  })
