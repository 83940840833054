import { useEffect } from 'react'
import { useService } from 'model/hooks/useService'
import { useQuery } from 'react-query'

export const useProject = (
  projectId,
  accountId = null,
  isPortfolio = false
) => {
  const { serviceRequest } = useService()

  // direct checkout needs an additional account id query param for BE transaction fee
  // as it ends up being evaluated as a public url and public url's do not
  // include account query params by default in useService
  const locationHost = window.location.host
  const checkoutRequest = locationHost.includes('checkout')

  const handleFetchProject = async () => {
    //console.log('isPortfolio', isPortfolio, accountId, checkoutRequest)
    const { data } = await serviceRequest({
      path: `/${isPortfolio ? 'portfolio' : 'project'}/${projectId}${
        (accountId && checkoutRequest) || (accountId && isPortfolio)
          ? `?account_id=${accountId}`
          : ''
      }`,
      method: 'GET',
      dataOnly: false,
    })
    if (data && isPortfolio) {
      //console.log('data', data)
      data.cost_per_kg_carbon_in_usd_cents = data.price_per_tonne_in_USD * 0.1
      data.available_carbon_in_kg = data.volume_in_tonnes * 1000
      data.id = data.slug
      //console.log('data.cost_per_kg_carbon_in_usd_cents',data.cost_per_kg_carbon_in_usd_cents)
    }
    return data
  }

  const { refetch, data } = useQuery('current-project', handleFetchProject, {
    enabled: !!projectId,
  })

  useEffect(() => {
    refetch()
  }, [projectId, refetch])

  useEffect(() => {
    if (data) {
      localStorage.setItem('selected-project', JSON.stringify(data))
      localStorage.setItem(
        'cost-per-unit',
        data?.cost_per_kg_carbon_in_usd_cents * 1000
      )
    }
  }, [data])

  return { project: data }
}
