import React from 'react'

const RegistrationPageBg = () => {
  return (
    <svg
      className="background-design"
      viewBox="0 0 798 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.1"
        d="M-1.00002 6.9736L235.283 243.257C252.578 260.682 280.624 260.682 297.919 243.257L409.414 130.927C426.71 113.502 454.756 113.502 472.051 130.927L535.206 192.303C552.389 209.486 574.988 222.54 600.314 197.215L797 0.528769"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.1"
        d="M0.990883 136.71L65.4736 200.67C87.134 222.213 87.134 257.134 65.4736 278.672L-0.745239 339.92M206.005 2.21861L285.064 81.2776C305.575 101.671 338.831 101.671 359.347 81.2776L438.406 2.21862M642.915 2.21863L575.743 64.6727C567.371 72.9953 567.371 86.4855 575.743 94.8081L590.161 109.145C598.533 117.468 612.099 117.468 620.466 109.145L737 -1"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <circle cx="349.092" cy="191.391" r="5" />
      <circle cx="38.44" cy="174.526" r="4.66246" />
      <circle cx="267.161" cy="62.8353" r="4.66246" />
      <circle cx="605.19" cy="115.286" r="4.66246" />
      <rect
        x="152.946"
        y="166.593"
        width="9.84224"
        height="9.84224"
        transform="rotate(-45 152.946 166.593)"
      />
      <rect
        x="563.13"
        y="216.012"
        width="9.32492"
        height="9.32492"
        transform="rotate(-90 563.13 216.012)"
      />
    </svg>
  )
}

export default RegistrationPageBg
