import React, { useEffect, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { usePortfolioDetails } from 'model/hooks/marketplace/usePortfolioDetails'
import { usePageHistory } from 'model/hooks/usePageHistory'
import { useService } from 'model/hooks/useService'
import { setPageTitle } from 'model/utils/page'
import { useParams } from 'react-router-dom'
import GoBackButton from 'view/components/buttons/go-back/GoBackButton'
import LoadingSpinnerIcon from 'view/components/icons/loading-spinner-icon/LoadingSpinnerIcon'
import ProjectInfoRequestLink from 'view/components/project-info-request-link/ProjectInfoRequestLink'
import { transactionReceiptComponents } from 'view/components/transaction-receipt-components/TransactionReceiptComponents'
import ShortDetails from './components/ShortDetails'
import PortfolioDetails from './portfolio/PortfolioDetails'
import ProjectList from './portfolio/ProjectList'
import TransactionSourceDetails from './TransactionSourceDetails'
import './TransactionReceiptInternal.scss'

const TransactionReceiptInternal = () => {
  const { account } = useUser()
  const { serviceRequest } = useService()

  const queryParams = new URLSearchParams(window.location.search)
  const portfolioId = queryParams.get('portfolioId')

  const { portfolio } = usePortfolioDetails(portfolioId)

  const [isLoaded, setIsLoaded] = useState(false)
  const [project, setProject] = useState(false)
  const [transaction, setTransaction] = useState(null)
  const [portfolioDetails, setPortfolioDetails] = useState(undefined)

  const [refetch, setRefetch] = useState(false)
  const [displayActualNumber, setDisplayActualNumber] = useState(false)
  const [requestedCurrency, setRequestedCurrency] = useState('USD')

  const { id } = useParams()

  const { getTransactionBackButtonInfo } = usePageHistory()

  const backButton = getTransactionBackButtonInfo()

  const { TransactionStateBlock, UserTip, TransactionBasicDetails } =
    transactionReceiptComponents()

  const handleFetchTransactionDetails = () => {
    if (refetch || !isLoaded) {
      serviceRequest({
        path: `/dashboard/transaction/${id}`,
      })
        .then(data => {
          if (data.portfolio_id) {
            const { transactions, ...portfolioDetails } = data
            setTransaction(transactions)
            setPortfolioDetails({
              ...portfolioDetails,
              transaction_state: transactions[0]?.transaction_state,
            })
          } else if (data.project) {
            const projectObj = data.project
            if (data?.active_tranche) {
              data.vintage_year = data.active_tranche.vintage_year
              data.cost_per_kg_carbon_in_usd_cents =
                data.active_tranche.cost_per_kg_carbon_in_usd_cents
            }
            // convert new line chars to new paragraphs for project's technical details text (if exists)
            if (projectObj.technical_details)
              projectObj.technical_details =
                '<p>' +
                projectObj.technical_details
                  ?.trim()
                  ?.replaceAll('\n', '</p><p>') +
                '</p>'
            setTransaction(data)
            setProject(projectObj)
          }
          setIsLoaded(true)
          setRefetch(false)
        })
        .catch(e => console.error(e))
    }
  }

  const transactionState = transaction?.length
    ? transaction[0]?.transaction_state
    : transaction?.transaction_state

  useEffect(() => {
    setPageTitle(`Transaction Details: ${id}`)
    handleFetchTransactionDetails()
    // eslint-disable-next-line
  }, [refetch])

  //display the actual amount if the kg/lbs amount is in mt/t" format underneath the mt/t amount
  useEffect(() => {
    if (account?.display_kg) {
      if (transaction?.total_co2e_in_kg > 999.99) setDisplayActualNumber(true)
    } else {
      //if in lbs
      if (transaction?.total_co2e_in_kg * 2.20462 > 1999.99)
        setDisplayActualNumber(true)
    }
    setRequestedCurrency(
      transaction?.length
        ? transaction[0]?.cost?.requested_currency
        : transaction?.cost?.requested_currency
    )
    // eslint-disable-next-line
  }, [transaction])

  return (
    <>
      {isLoaded && (
        <div
          className={`transaction-details-internal ${
            transaction?.is_cobranding_enabled ? 'cobranded' : ''
          }`}
        >
          {backButton && (
            <GoBackButton title={backButton.title} href={backButton.path} />
          )}
          <div className="header-and-buttons">
            <TransactionStateBlock transaction={transaction} isInHeader />
            <h1 className={transactionState}>Order Details</h1>
          </div>
          <span className="transaction-id">
            {`Transaction ID: ${
              portfolioDetails?.transaction_id || transaction?.transaction_id
            }`}
          </span>

          <div className="containers">
            <section className="left-container">
              <h3>Receipt</h3>
              <section className="left-panel">
                <div className="short-details-wrapper">
                  <TransactionBasicDetails
                    transaction={portfolioId ? portfolioDetails : transaction}
                    account={account}
                    displayActualNumber={displayActualNumber}
                  />
                  <hr />
                  <ShortDetails
                    isReceiptPage={false}
                    transaction={portfolioId ? portfolioDetails : transaction}
                  />
                </div>
              </section>
            </section>
            {!project && portfolioId && (
              <>
                <section className="right-container">
                  <h3>Source</h3>
                  <section className="right-panel">
                    <PortfolioDetails
                      portfolio={portfolio}
                      transactionId={portfolioDetails.transaction_id}
                      requestedCurrency={requestedCurrency}
                      certificateUrl={
                        portfolioDetails?.portfolio_certificate_url
                      }
                    />
                  </section>
                  <section className="right-panel project-list">
                    {!portfolio && (
                      <div style={{ padding: '16px 12px' }}>
                        <LoadingSpinnerIcon
                          className="spinner"
                          strokeWidth={2}
                        />
                      </div>
                    )}
                    {!!portfolio && (
                      <ProjectList
                        transactionId={portfolioDetails.transaction_id}
                        transactions={transaction}
                      />
                    )}
                  </section>

                  <div className="request-link-container">
                    <ProjectInfoRequestLink
                      projectId={portfolio?.slug}
                      projectName={portfolio?.name}
                    />
                  </div>
                </section>
              </>
            )}

            {project && (
              <>
                <section className="right-container">
                  <h3>Source</h3>
                  <section className="right-panel">
                    <TransactionSourceDetails
                      transaction={transaction}
                      project={project}
                    />
                  </section>

                  <div className="request-link-container">
                    <ProjectInfoRequestLink
                      projectId={project?.id}
                      projectName={project?.shortName || project?.name}
                    />
                  </div>

                  <UserTip
                    isCobrandingEnabled={transaction?.is_cobranding_enabled}
                  />
                </section>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default TransactionReceiptInternal
