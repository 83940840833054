import React, { useEffect, useState } from 'react'
import AvoidedConversion from './svg/avoided_conversion.svg'
import AvoidedDeforestation from './svg/avoided_deforestation.svg'
import Biochar from './svg/biochar.svg'
import Biomass from './svg/biomass.svg'
import BlueCarbon from './svg/blue_carbon.svg'
import EnergyEfficiency from './svg/energy_efficiency.svg'
import FugitiveEmissionsCapture from './svg/fugitive_emissions_capture.svg'
import Generic from './svg/generic.svg'
import ImprovedForestManagement from './svg/improved_forest_management.svg'
import IndustrialProcessEmissionsReduction from './svg/industrial_process_emissions_reduction.svg'
import LandfillGasCapture from './svg/landfill_gas_capture.svg'
import LivestockGasCapture from './svg/livestock_gas_capture.svg'
import MarineEfficiency from './svg/marine_efficiency.svg'
import Mineralization from './svg/mineralization.svg'
import OzoneDepletingSubstancesDestructionOrReplacement from './svg/ozone-depleting_substances_destruction_or_replacement.svg'
import ReforestationOrAfforestation from './svg/reforestation_or_afforestation.svg'
import SoilAmendment from './svg/soil_amendment.svg'
import Solar from './svg/solar.svg'
import SustainableGrazing from './svg/sustainable_grazing.svg'
import UrbanForestry from './svg/urban_forestry.svg'
import WasteManagement from './svg/waste_management.svg'
import Wind from './svg/wind.svg'
import WoodenBuildingElements from './svg/wooden_building_elements.svg'

const OffsetTypeIcon = ({ type }) => {
  const [selectedSvg, setSelectedSvg] = useState(undefined)

  useEffect(() => {
    switch (type) {
      case 'Avoided Conversion':
        return setSelectedSvg(AvoidedConversion)
      case 'Avoided Deforestation':
        return setSelectedSvg(AvoidedDeforestation)
      case 'Biochar':
        return setSelectedSvg(Biochar)
      case 'Biomass':
        return setSelectedSvg(Biomass)
      case 'Blue Carbon':
        return setSelectedSvg(BlueCarbon)
      case 'Energy Efficiency':
        return setSelectedSvg(EnergyEfficiency)
      case 'Fugitive Emissions Capture':
        return setSelectedSvg(FugitiveEmissionsCapture)
      case 'Improved Forest Management':
        return setSelectedSvg(ImprovedForestManagement)
      case 'Industrial Process Emissions Reduction':
        return setSelectedSvg(IndustrialProcessEmissionsReduction)
      case 'Landfill Gas Capture':
        return setSelectedSvg(LandfillGasCapture)
      case 'Livestock Gas Capture':
        return setSelectedSvg(LivestockGasCapture)
      case 'Marine Efficiency':
        return setSelectedSvg(MarineEfficiency)
      case 'Mineralization':
        return setSelectedSvg(Mineralization)
      case 'Ozone Depleting Substances Destruction or Replacement':
        return setSelectedSvg(OzoneDepletingSubstancesDestructionOrReplacement)
      case 'Reforestation or Afforestation':
        return setSelectedSvg(ReforestationOrAfforestation)
      case 'Soil Amendment':
        return setSelectedSvg(SoilAmendment)
      case 'Solar':
        return setSelectedSvg(Solar)
      case 'Sustainable Grazing':
        return setSelectedSvg(SustainableGrazing)
      case 'Urban Forestry':
        return setSelectedSvg(UrbanForestry)
      case 'Waste Management':
        return setSelectedSvg(WasteManagement)
      case 'Wind':
        return setSelectedSvg(Wind)
      case 'Wooden Building Elements':
        return setSelectedSvg(WoodenBuildingElements)
      default:
        return setSelectedSvg(Generic)
    }
  }, [type])

  return selectedSvg !== undefined ? <img src={selectedSvg} /> : ''
}

export default OffsetTypeIcon
