import React from 'react'

const CreditCardsIcon = () => (
  <svg fill="none" height="77" viewBox="0 0 111 77" width="111">
    <g>
      <path
        d="m103.979 76.9999h-80.1703c-3.8889 0-7.0387-3.1555-7.0387-7.0513v-47.3846c0-3.8959 3.1498-7.0513 7.0387-7.0513h80.1703c3.889 0 7.039 3.1554 7.039 7.0513v47.4022c-.018 3.8958-3.15 7.0337-7.039 7.0337z"
        fill="#00cc8a"
      />
      <path
        d="m103.98 18.1569c2.429 0 4.382 1.9744 4.382 4.3894v47.4023c0 2.4327-1.971 4.3894-4.382 4.3894h-80.1701c-2.4283 0-4.3816-1.9743-4.3816-4.3894v-47.3846c0-2.4327 1.9709-4.3894 4.3816-4.3894h80.1701zm0-2.6442h-80.1701c-3.8889 0-7.0211 3.1554-7.0211 7.0336v47.4023c0 3.8958 3.1498 7.0337 7.0211 7.0337h80.1701c3.889 0 7.022-3.1555 7.022-7.0337v-47.3846c0-3.8959-3.133-7.0513-7.022-7.0513z"
        fill="#004453"
      />
      <path d="m109.944 28.9629h-92.4709v13.5032h92.4709z" fill="#f9f9f9" />
      <path
        d="m107.304 31.6071v8.2148h-87.1914v-8.2148zm2.64-2.6442h-92.4709v13.5032h92.4709z"
        fill="#004453"
      />
      <path
        d="m87.2093 61.4872h-80.17062c-3.88887 0-7.03868-3.1555-7.03868-7.0513v-47.38462c0-3.89583 3.14981-7.05128 7.03868-7.05128h80.17062c3.8888 0 7.0386 3.15545 7.0386 7.05128v47.40222c-.0176 3.8782-3.1674 7.0337-7.0386 7.0337z"
        fill="#fff"
      />
      <g fill="#004453">
        <path d="m87.2093 2.64423c2.4283 0 4.3815 1.97436 4.3815 4.40705v47.40222c0 2.4327-1.9708 4.3894-4.3815 4.3894h-80.18822c-2.42834 0-4.38157-1.9743-4.38157-4.3894v-47.40222c0-2.43269 1.97083-4.40705 4.38157-4.40705zm0-2.64423h-80.18822c-3.87127 0-7.02108 3.15545-7.02108 7.05128v47.40222c0 3.8959 3.14981 7.0337 7.02108 7.0337h80.17062c3.8888 0 7.021-3.1555 7.021-7.0337v-47.40222c.0176-3.89583-3.1322-7.05128-7.0034-7.05128z" />
        <path d="m22.7347 41.4611h-12.82803c-.72147 0-1.31976-.5993-1.31976-1.3221 0-.7227.59829-1.3221 1.31976-1.3221h12.82803c.7214 0 1.3197.5994 1.3197 1.3221 0 .7228-.5983 1.3221-1.3197 1.3221z" />
        <path d="m31.797 50.6638h-21.89033c-.72147 0-1.31976-.5994-1.31976-1.3221 0-.7228.59829-1.3222 1.31976-1.3222h21.89033c.7214 0 1.3197.5994 1.3197 1.3222 0 .7227-.5983 1.3221-1.3197 1.3221z" />
        <path d="m31.797 19.8493h-21.89033c-.72147 0-1.31976-.5994-1.31976-1.3221 0-.7228.59829-1.3221 1.31976-1.3221h21.89033c.7214 0 1.3197.5993 1.3197 1.3221 0 .7227-.5983 1.3221-1.3197 1.3221z" />
        <path d="m62.0465 50.6638h-21.8903c-.7215 0-1.3198-.5994-1.3198-1.3221 0-.7228.5983-1.3222 1.3198-1.3222h21.8903c.7214 0 1.3197.5994 1.3197 1.3222 0 .7227-.5983 1.3221-1.3197 1.3221z" />
        <path d="m42.3904 41.4611h-12.828c-.7214 0-1.3197-.5993-1.3197-1.3221 0-.7227.5983-1.3221 1.3197-1.3221h12.828c.7215 0 1.3198.5994 1.3198 1.3221 0 .7228-.5983 1.3221-1.3198 1.3221z" />
        <path d="m62.0457 41.4611h-12.828c-.7215 0-1.3198-.5993-1.3198-1.3221 0-.7227.5983-1.3221 1.3198-1.3221h12.828c.7215 0 1.3197.5994 1.3197 1.3221 0 .7228-.5982 1.3221-1.3197 1.3221z" />
        <path d="m81.6836 41.4611h-12.828c-.7214 0-1.3197-.5993-1.3197-1.3221 0-.7227.5983-1.3221 1.3197-1.3221h12.828c.7215 0 1.3198.5994 1.3198 1.3221 0 .7228-.5807 1.3221-1.3198 1.3221z" />
      </g>
      <path
        d="m80.6636 25.0323h-8.9215c-1.7245 0-3.1322-1.4103-3.1322-3.1379v-7.8269c0-1.7276 1.4077-3.1378 3.1322-3.1378h8.9215c1.7245 0 3.1322 1.4102 3.1322 3.1378v7.8446c0 1.7275-1.4077 3.1202-3.1322 3.1202z"
        fill="#00cc8a"
      />
      <path
        d="m80.6635 26.3718h-8.9215c-2.4635 0-4.452-2.0096-4.452-4.4599v-7.8445c0-2.468 1.9885-4.45998 4.452-4.45998h8.9215c2.4636 0 4.452 1.99198 4.452 4.45998v7.8445c0 2.4503-2.006 4.4599-4.452 4.4599zm-8.9215-14.1201c-1.003 0-1.8125.8109-1.8125 1.8157v7.8445c0 1.0048.8095 1.8157 1.8125 1.8157h8.9215c1.003 0 1.8125-.8109 1.8125-1.8157v-7.8445c0-1.0048-.8095-1.8157-1.8125-1.8157z"
        fill="#004453"
      />
    </g>
  </svg>
)

export default CreditCardsIcon
