import React, { useEffect, useReducer, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { setPageTitle } from 'model/utils/page'
import { NavLink } from 'react-router-dom'
import CopyButton from 'view/components/buttons/copy-button/CopyButton'
import ApiDocsIcon from 'view/components/icons/api-docs-icon/ApiDocsIcon'
import ExternalLinkIcon from 'view/components/icons/external-link-icon/ExternalLinkIcon'
import KeyIcon from 'view/components/icons/key-icon/KeyIcon'
import SectionNav from 'view/components/section-nav/SectionNav'
import SectionNavLink from 'view/components/section-nav/SectionNavLink'
import '../../DeveloperTools.scss'
import DocsScreenshot from '../images/docs-screenshot.png'
import PostmanLogo from '../images/postman-logo.png'
import PostmanScreenshot from '../images/postman-screenshot.png'
import DebuggerTable from '../tables/DebuggerTable'

const DeveloperToolsPage = () => {
  const cloverlyDocUrl = 'https://docs.cloverly.com/'
  const cloverlyShippingDocUrl =
    'https://docs.cloverly.com/docs/cloverly-api/b3A6MjgwMzk2OTg-package-shipping'
  const postmanDocUrl =
    'https://documenter.getpostman.com/view/17875149/2s93zFYevc'

  const [selectedTab, setSelectedTab] = useState('docs')
  const { account } = useUser()
  const { serviceRequest } = useService()

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'setApiCalls':
        state.apiCalls = action.apiCalls.data
        break
    }
    return { ...state }
  }, {})

  useEffect(() => {
    setPageTitle('Developer Tools')
  }, [])

  useEffect(() => {
    serviceRequest({
      path: `/dashboard/accounts/${account.slug}/api_debuggers?limit=5`,
    }).then(apiCalls =>
      dispatch({
        type: 'setApiCalls',
        apiCalls,
      })
    )
    // eslint-disable-next-line
  }, [account])

  return (
    <>
      <div className="developer-tools">
        <h1>Developer Tools</h1>
        <div className="top-panels">
          <div className="panel">
            <h3>Your Keys</h3>
            <div className="key-row">
              <KeyIcon className="shift-up" />
              <div className="key-label">
                <div>Sandbox key</div>
                <div>public_key:{account.test_public_key}</div>
              </div>
              <CopyButton
                textToCopy={`public_key:${account.test_public_key}`}
              />
            </div>
            <div className="key-row">
              {account.production_enabled_at != null && (
                <>
                  <KeyIcon className="green-key shift-up" />
                  <div className="key-label">
                    <div>Production key</div>
                    <div>public_key:{account.public_key}</div>
                  </div>
                  <CopyButton textToCopy={`public_key:${account.public_key}`} />
                </>
              )}

              {account.production_enabled_at == null && (
                <>
                  <KeyIcon className="green-key" />
                  <div className="full-message">
                    <NavLink to="/account-settings/billing">
                      Enable Billing
                    </NavLink>{' '}
                    for Production Key
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="panel">
            <h3>API Docs</h3>
            <div className="api-docs-info-container">
              <ApiDocsIcon />
              <p>
                Full documentation for our current API (V 2.0) lives at{' '}
                <a
                  href={cloverlyDocUrl}
                  rel="noopener noreferrer"
                  target="CloverlyDocs"
                >
                  docs.cloverly.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <h2>API Debugger</h2>
        <p>The debugger shows a list of API calls for your account.</p>
        <DebuggerTable
          showFilters={false}
          pageCount={1}
          apiCalls={state.apiCalls || []}
        />

        {!!state.apiCalls?.length && (
          <div className="page-button-container">
            <NavLink to="/developer-tools/api-debugger/all-calls">
              <span>View All API Calls</span>
              <ArrowForwardIcon />
            </NavLink>
          </div>
        )}

        <h2>Using the API</h2>

        <SectionNav>
          <SectionNavLink
            name="docs"
            title="From docs.cloverly.com"
            selectedTab={selectedTab}
            onSelected={tab => setSelectedTab(tab)}
          />
          <SectionNavLink
            name="postman"
            title="From Postman"
            selectedTab={selectedTab}
            onSelected={tab => setSelectedTab(tab)}
          />
        </SectionNav>

        {selectedTab === 'docs' && (
          <div className="tab-panel">
            <div className="tab-panel-cols">
              <div className="tab-panel-cols-left">
                <h3>Instructions:</h3>
                <ol>
                  <li>
                    Head to any of the calculator API endpoints in our
                    documentation, for example, the{' '}
                    <a
                      href={cloverlyShippingDocUrl}
                      rel="noopener noreferrer"
                      target="CloverlyShippingDocs"
                    >
                      shipping endpoint
                    </a>
                    .
                  </li>
                  <li>
                    In the dark colored widget in the upper right, where it says
                    token, enter in your token, preceded by "Bearer". For
                    testing we highly recommend using your Sandbox Key:
                    <br />
                    <b>Bearer public_key: {account.test_public_key}</b>
                  </li>
                  <li>
                    Hit the "Send Request" button, and you should get a
                    response. Reminder: If you use a production key, a real
                    purchase will occur.
                  </li>
                </ol>
              </div>
              <div className="tab-panel-cols-right">
                <img
                  alt="Documentation Screenshot"
                  className="docs-screenshot"
                  src={DocsScreenshot}
                />
                <a
                  className="dark-green-button centered"
                  href={cloverlyDocUrl}
                  rel="noopener noreferrer"
                  target="CloverlyDocs"
                >
                  <span>Cloverly Docs</span>
                  <ExternalLinkIcon />
                </a>
              </div>
            </div>
          </div>
        )}

        {selectedTab === 'postman' && (
          <div className="tab-panel">
            <div className="tab-panel-cols">
              <div className="tab-panel-cols-left">
                <h3>Instructions:</h3>
                <ol>
                  <li>
                    Within postman, search for “Cloverly Public API”
                    <br />
                    <img alt="Postman Screenshot" src={PostmanScreenshot} />
                    <br />
                    Or follow the link{' '}
                    <a
                      href={postmanDocUrl}
                      rel="noopener noreferrer"
                      target="PostmanDocs"
                    >
                      here
                    </a>
                    .
                  </li>
                  <li>
                    When you get to the collection, click variables and input
                    your authentication. You do not need to update the base_url.
                    Add a public_key in the “Variable” column and your key in
                    the “Initial Value” column. For testing, we highly recommend
                    using your Sandbox Key:{' '}
                    <b>Bearer public_key: {account.test_public_key}</b>
                  </li>
                  <li>
                    Fork the collection to your workspace. Right-click the
                    Cloverly Public API folder and click “Create a fork” label
                    and pick a location for the collection.
                  </li>
                  <li>
                    To test a request, choose an endpoint and send the example
                    request. You can play around with what is in the request.
                    See{' '}
                    <a
                      href="https://docs.cloverly.com/"
                      rel="noopener noreferrer"
                      target="CloverlyDocs"
                    >
                      docs.cloverly.com
                    </a>{' '}
                    for more examples.
                  </li>
                </ol>
              </div>
              <div className="tab-panel-cols-right">
                <img alt="Postman Logo" src={PostmanLogo} />
                <a
                  className="dark-green-button below"
                  href={postmanDocUrl}
                  rel="noopener noreferrer"
                  target="PostmanDocs"
                >
                  <span>Postman Link</span>
                  <ExternalLinkIcon />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DeveloperToolsPage
