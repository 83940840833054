import React, { useCallback, useEffect, useState } from 'react'
import { useCurrencyExchangeRates } from 'model/hooks/useCurrencyExchangeRates'
import { useService } from 'model/hooks/useService'
import { useTheme } from 'model/hooks/useTheme'
import { formatCost } from 'model/utils/cost'
import { queryClient } from 'model/utils/queryClient'
import { formatWeightTypeAndAmount } from 'model/utils/units'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CloverlyFooter from 'view/components/cloverly-footer/CloverlyFooter'
import Logo from 'view/components/cloverly-logo/Logo'
import PortfolioPanel from 'view/pages/checkout-public/components/project-selection/components/project-panel/PortfolioPanel'
import ProjectPanel from './components/project-panel/ProjectPanel'
import { useCheckoutQueryParamContext } from '../../contexts/CheckoutQueryParamContext'
import { handleFetchCheckoutData } from '../../util/FetchCheckoutData'
import NoDataPage from '../no-data-page/NoDataPage'
import './ProjectSelection.scss'

const ProjectSelection = () => {
  const {
    weightUnit,
    shopBy,
    currency,
    amount,
    isFixedOrAny,
    projectData,
    handleSetFieldValues,
    setProjectData,
    pageStatus,
    isPageActive,
    initialLoad,
    setInitialLoad,
  } = useCheckoutQueryParamContext()

  const { serviceRequest } = useService()

  const { updateColor, updateImage, getPrimaryStyle } = useTheme()

  const { checkoutSlug } = useParams()

  const navigate = useNavigate()
  const location = useLocation()
  const { currencyRates } = useCurrencyExchangeRates({ showLoading: true })

  const [selectedProject, setSelectedProject] = useState()

  const projects =
    projectData || JSON.parse(localStorage.getItem('checkout-projects'))

  const isMoreThan3Projects =
    projects?.length > 3 ? 'more-than-three' : 'no-invisible-panel'
  const hasEvenNumberOfProjectsClassName =
    projects?.length % 2 === 0 && projects?.length >= 3 ? 'even' : 'odd'

  const noProjects = projectData !== null && projectData?.length === 0

  //return in dollars or weight units depending on unit type
  const determinePurchaseAmount = () => {
    if (shopBy === 'currency') return formatCost(amount, currency)
    if (shopBy === 'carbon')
      return formatWeightTypeAndAmount(amount, weightUnit)
  }

  //route to stripe checkout or choose amount depending on isAmountFixed
  const handleForwardToNextPage = useCallback(() => {
    let pathToStripe = `${location.pathname.replace(
      'project-selection',
      'stripe-checkout'
    )}/${selectedProject}`
    let pathToChooseAmount = `${location.pathname.replace(
      'project-selection',
      'choose-amount'
    )}/${selectedProject}`
    const pathName =
      isFixedOrAny === 'fixed' ? pathToStripe : pathToChooseAmount
    navigate({
      pathname: pathName,
      search: location.search,
      state: { prevPath: location.pathname, urlSearch: location.search },
    })
    // eslint-disable-next-line
  }, [
    selectedProject,
    isFixedOrAny,
    location.search,
    history,
    location.pathname,
  ])

  //go to the next page (either Choose Amount or Stripe page)
  useEffect(() => {
    if (selectedProject) {
      handleForwardToNextPage()
    }
  }, [handleForwardToNextPage, selectedProject])

  //fetch project data
  useEffect(() => {
    if (!projectData)
      handleFetchCheckoutData(
        checkoutSlug,
        serviceRequest,
        handleSetFieldValues,
        setProjectData,
        updateColor,
        updateImage,
        getPrimaryStyle,
        setInitialLoad
      )
    else setInitialLoad(true)
    // eslint-disable-next-line
  }, [checkoutSlug, projectData])

  useEffect(() => {
    if (queryClient.getQueryData('estimateFromBE'))
      queryClient.resetQueries('estimateFromBE', { exact: true })
  }, [])

  const cobrandedLogo = JSON.parse(localStorage.getItem('cobranded-logo'))
  const cobrandedClassName = cobrandedLogo ? 'cobranded' : ''
  const noProjectClassName = noProjects ? 'no-projects' : ''
  const isAmountFixedClassName = isFixedOrAny === 'fixed' ? 'isFixed' : 'any'

  return (
    <>
      {initialLoad && (!isPageActive(pageStatus) || noProjects) ? (
        <NoDataPage />
      ) : (
        <div
          className={`project-selection ${noProjectClassName} ${cobrandedClassName}`}
        >
          {cobrandedLogo ? (
            <img
              className="cobranding-logo"
              src={`data:image/jpeg;base64,${cobrandedLogo}`}
              alt=""
            />
          ) : (
            <Logo />
          )}
          <header
            className={`header ${isFixedOrAny === 'fixed' ? 'isFixed' : ''}`}
          >
            <h1>Select a project</h1>
            <span className="info-text">
              Choose a project to purchase carbon credits from.
            </span>
            {isFixedOrAny === 'fixed' && (
              <div className={`purchase-amount ${cobrandedClassName}`}>
                Purchase amount:{' '}
                <span className="amount">{determinePurchaseAmount()}</span>
              </div>
            )}
          </header>
          <div
            className={`panel-container ${isMoreThan3Projects} ${hasEvenNumberOfProjectsClassName}`}
          >
            {!!currencyRates?.length &&
              projects?.map(project =>
                project.is_portfolio ? (
                  <PortfolioPanel
                    key={project.offset_source_slug}
                    project={project}
                    currencyRates={currencyRates}
                    setSelectedProject={setSelectedProject}
                  />
                ) : (
                  <ProjectPanel
                    key={project.offset_source_slug}
                    project={project}
                    currencyRates={currencyRates}
                    setSelectedProject={setSelectedProject}
                  />
                )
              )}
          </div>
          <CloverlyFooter cn={`${isAmountFixedClassName}`} />
        </div>
      )}
    </>
  )
}

export default ProjectSelection
