import React from 'react'
import { useLoading } from 'model/hooks/useLoading'
import LoadingSpinnerIcon from '../icons/loading-spinner-icon/LoadingSpinnerIcon'
import './LoadingOverlay.scss'

const LoadingOverlay = () => {
  const { isLoading } = useLoading()

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <LoadingSpinnerIcon className="spinner" />
        </div>
      )}
    </>
  )
}

export default LoadingOverlay
