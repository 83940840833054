import React, { useState } from 'react'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import LockIcon from '@mui/icons-material/LockOutlined'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { inputFormatExpiration } from 'model/utils/input-formatters'
import { Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import CreditCardsIcon from 'view/components/icons/credit-cards-icon/CreditCardsIcon'
import './Modal.scss'

const EditCardModal = React.memo(
  ({ showEditCard = false, setShowEditCard, card = null }) => {
    const { register, handleSubmit } = useForm()
    const { account, refreshUser } = useUser()
    const { infoToast, warningToast } = useToast()
    const [expirationValue, setExpirationValue] = useState('')
    const { serviceRequest } = useService()

    const onSubmit = data => {
      const expiry = data.expiry.split(' / ')
      data.expMonth = expiry[0]
      data.expYear = expiry[1]
      delete data.expiry
      serviceRequest({
        path: `/dashboard/accounts/${account.slug}/billing`,
        method: 'POST',
        data,
      }).then(data => {
        if (data.success) {
          infoToast('Card updated successfully')
          setShowEditCard(false)
          refreshUser().then()
        } else {
          warningToast(data.error)
        }
      })
    }

    const onCancel = e => {
      e.preventDefault()
      setShowEditCard(false)
    }

    return (
      <Modal
        show={showEditCard}
        size="lg"
        className="credit-card-modal"
        centered
      >
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CloseDialogButton onClick={e => onCancel(e)} />
            <h2>Edit Card</h2>
            {card != null ? (
              <p>
                {card.brand} ending in {card.last4}, expires {card.exp_month}/
                {card.exp_year}
              </p>
            ) : (
              <p>No card available</p>
            )}
            <div className="card-columns">
              <div className="icon-column">
                <div className="circle">
                  <CreditCardsIcon />
                </div>
              </div>
              <div className="user-inputs">
                <div className="input-icon-container">
                  <label htmlFor="frmCCNum">Card Number</label>
                  <Form.Control
                    id="frmCCNum"
                    name="cardnumber"
                    type="number"
                    maxLength="16"
                    autoComplete="cc-number"
                    {...register('cardNumber', { required: true })}
                  />
                  <CreditCardIcon />
                </div>
                <div className="two-column">
                  <div>
                    <label htmlFor="frmCCExp">Expiration</label>
                    <Form.Control
                      id="frmCCExp"
                      name="cc-exp"
                      type="text"
                      placeholder="MM/YY"
                      autoComplete="cc-exp"
                      value={expirationValue}
                      {...register('expiry', {
                        required: true,
                        onChange: e =>
                          setExpirationValue(
                            inputFormatExpiration(e.target.value)
                          ),
                      })}
                    />
                  </div>
                  <div className="input-icon-container">
                    <label htmlFor="frmCCCVC">CVC</label>
                    <Form.Control
                      id="frmCCCVC"
                      name="cvc"
                      type="number"
                      maxLength="6"
                      autoComplete="cc-csc"
                      {...register('cvc', { required: true })}
                    />
                    <LockIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-group">
              <Button cn={'cancel'} onClick={e => onCancel(e)}>
                Cancel
              </Button>
              <Button type="submit">Update Card</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
)

export default EditCardModal
