import React, { useEffect, useRef, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  determineCurrencyRate,
  formatCost,
  handleCalculateCostPerTonne,
} from 'model/utils/cost'
import { formatWeightTypeAndAmount, getWeightUnit } from 'model/utils/units'
import ExternalLink from 'view/components/external-link/ExternalLink'
import VintageYearButton from 'view/components/vintage-year/vintage-year-button/VintageYearButton'
import { useCheckoutQueryParamContext } from '../../../../contexts/CheckoutQueryParamContext'
import './ProjectPanel.scss'

//similar to Product Item Panel in Marketplace
const ProjectPanel = ({ project, currencyRates, setSelectedProject }) => {
  const linkRef = useRef(null)

  const {
    weightUnit,
    shopBy,
    currency,
    amount,
    isFixedOrAny,
    setSelectedProjectIsPortfolio,
  } = useCheckoutQueryParamContext()

  const [isSelected, setIsSelected] = useState(false)

  const [estimate, setEstimate] = useState({
    total_weight: 0,
    total_cost: 0,
    currency: currency,
    weight_unit: getWeightUnit({ weightType: weightUnit, format: '%u' }),
  })

  // for highlighting the selected panel
  const selectedClassName = () => {
    return isSelected && Number(amount) > 0 ? 'selected' : ''
  }

  const isFixedClassName = () => (isFixedOrAny === 'fixed' ? 'isFixed' : '')

  const [projectPanelClassName, setProjectPanelClassName] = useState(
    `project-item-panel ${selectedClassName()} ${isFixedClassName()}`
  )

  //estimation related data
  const costInDollars = project?.cost_per_unit_in_usd_cents * 0.01
  const costPerDollar = project?.cost_per_unit_in_usd_cents * 0.01

  const formattedCostPerTonne = handleCalculateCostPerTonne(
    costInDollars,
    currency,
    currencyRates
  )

  //determines whether the input amount is in kg or lbs when calculation goes over to tonnes
  const isMetricTonne = weightUnit === 'kg' || weightUnit === 'tonnes'

  const handleClickOnProject = e => {
    //disable panel if amount is 0 when fixed
    if (isFixedOrAny === 'fixed' && Number(amount) <= 0) return
    if (
      e.target instanceof HTMLAnchorElement ||
      e.target.className === 'external-link'
    )
      return
    setSelectedProjectIsPortfolio(false)
    setSelectedProject(project?.offset_source_slug)
  }

  //shopBy === carbon
  const handleEstimateCurrencyAmount = () => {
    let tempWeight = amount
    if (weightUnit === 'kg') {
      tempWeight = amount / 1000
    } else if (weightUnit === 'lbs') {
      tempWeight = amount / (2.20462 * 1000)
    } else {
      tempWeight = amount
    }
    const carbonCost =
      Math.ceil(tempWeight * project?.cost_per_unit_in_usd_cents) / 100
    const totalCost =
      carbonCost * determineCurrencyRate(currency, currencyRates)
    const calculatedEstimate = {
      ...estimate,
      total_cost: totalCost,
      total_weight: amount,
    }
    setEstimate(calculatedEstimate)
  }

  //shopBy === currency
  const handleEstimateCarbonAmount = () => {
    let costPerUnit = costPerDollar
    if (weightUnit === 'tonnes' || weightUnit === 'kg')
      costPerUnit = costPerDollar / 1000
    if (weightUnit === 'lbs') costPerUnit = costPerDollar / 1000
    const calculatedInputDollarAmount = amount / costPerUnit
    const calculatedWeight =
      calculatedInputDollarAmount /
      determineCurrencyRate(currency, currencyRates)
    setEstimate(estimate => ({
      ...estimate,
      total_weight: Number(calculatedWeight),
      total_cost: amount,
    }))
  }

  // determines what to render when amount is fixed depending on carbon vs currency
  const handleRenderTotal = () => {
    const estimatedAmount =
      shopBy === 'carbon'
        ? formatCost(estimate.total_cost, currency)
        : getWeightUnit({
            weightType: weightUnit,
            value: estimate?.total_weight,
            roundUnits: 0,
            format: '%v %u',
            isMT: isMetricTonne,
          })
    return (
      <>
        <span>
          {shopBy === 'carbon'
            ? formatWeightTypeAndAmount(amount, weightUnit)
            : formatCost(amount, currency)}
        </span>
        <span className="amount-and-forward-arrow">
          {estimatedAmount}
          <ArrowForwardIcon
            className={
              isSelected && Number(amount) > 0
                ? 'arrow-visible'
                : 'arrow-hidden'
            }
          />
        </span>
      </>
    )
  }

  const handleHover = isOnHover => {
    if (isOnHover) {
      setIsSelected(true)
      localStorage.setItem('cost-per-unit', project?.cost_per_unit_in_usd_cents)
    } else {
      setIsSelected(false)
    }
  }

  //if "View Details" link is focused, change panel's outline styling
  useEffect(() => {
    if (document.activeElement === linkRef.current) {
      if (!projectPanelClassName.includes('link-is-focused'))
        setProjectPanelClassName(projectPanelClassName + ' link-is-focused')
    } else {
      setProjectPanelClassName(
        projectPanelClassName.replace(' link-is-focused', '')
      )
    }
    // eslint-disable-next-line
  }, [document.activeElement])

  //when user inputs a value
  useEffect(() => {
    if (shopBy === 'carbon') handleEstimateCurrencyAmount()
    if (shopBy === 'currency') handleEstimateCarbonAmount()
    // eslint-disable-next-line
  }, [])

  const cancelEvent = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <section
      className={projectPanelClassName}
      tabIndex="0"
      onMouseOver={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      onFocus={() => handleHover(true)}
      onBlur={() => handleHover(false)}
      onClick={handleClickOnProject}
      onKeyPress={handleClickOnProject}
    >
      <img className="panel-image" src={project?.image_url} alt="" />
      <div className="project-type-container">{project?.type}</div>
      <div className="bottom-half">
        <span className="project-name">{project?.name}</span>
        <div className="details">
          <div className="cost-and-vintage">
            <span className="cost">{formattedCostPerTonne + ' / tonne'}</span>
            {!project?.active_tranche?.is_issued && (
              <span onClick={cancelEvent}>
                <VintageYearButton
                  projectName={project?.short_name || project?.name}
                  deliveryDate={project?.active_tranche?.delivery_date}
                  vintageYear={project?.active_tranche?.vintage_year}
                />
              </span>
            )}
          </div>
          <span className="location">
            {project.city?.trim()}{' '}
            {project.city && project.country ? ',' : null} {project?.country}
          </span>
          <ExternalLink
            ref={linkRef}
            title="View Details"
            url={`https://info.cloverly.com/project-details?project=${project?.offset_source_slug}`}
          />
        </div>
      </div>
      {isFixedOrAny === 'fixed' && (
        <div className={`calculated-amount-section ${selectedClassName()}`}>
          {handleRenderTotal()}
        </div>
      )}
    </section>
  )
}

export default ProjectPanel
