/**
 * Returns a sort method (case-insensitive) that sorts an object by a
 * given property name and if the sort order should be ascending.
 *
 * @param property
 * @param sortAccending
 * @param disableStringSort
 * @returns {function(*, *): number|undefined|number}
 */
export const dynamicSort =
  (property: string, sortAccending = true, disableStringSort = false) =>
  (a: any, b: any) =>
    !disableStringSort &&
    (typeof a?.[property] === 'string' || a?.[property] instanceof String)
      ? a?.[property].localeCompare(b?.[property], 'en', {
          sensitivity: 'base',
        })
      : sortAccending
        ? a?.[property] - b?.[property]
        : b?.[property] - a?.[property]
