import React, { ReactNode } from 'react'
import { Text, View } from '@react-pdf/renderer'

type Props = {
  icon: string | ReactNode
  label: string
  style: any
}
const QMTag = ({ icon, label, style }: Props) => (
  <View style={style}>
    {icon}
    <Text>{label}</Text>
  </View>
)

export default QMTag
