import React from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import './SusReportHeader.scss'

const SusReportHeader = ({ logoUrl, siteUrl }) => {
  const props = { size: 46, round: true }
  const urlToShare = window.location.href

  return (
    <header className="sus-report-header">
      {logoUrl && siteUrl && (
        <a
          className="site-url"
          href={siteUrl}
          target="_blank"
          rel="noopener noreferrer">
          <img className="account-logo" src={logoUrl} alt="account logo" />
        </a>
      )}
      <section className="social-media-icons">
        <FacebookShareButton url={urlToShare}>
          <FacebookIcon {...props} />
        </FacebookShareButton>
        <TwitterShareButton url={urlToShare}>
          <TwitterIcon {...props} />
        </TwitterShareButton>
        <LinkedinShareButton url={urlToShare}>
          <LinkedinIcon {...props} />
        </LinkedinShareButton>
      </section>
    </header>
  )
}

export default SusReportHeader
