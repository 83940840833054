import React from 'react'
import { getWeightUnit } from 'model/utils/units'
import './ReportStat.scss'

const ReportStat = ({ icon, footprint, isPurchases }) => {
  const Icon = icon

  const determineTotalFootprint = () => {
    return getWeightUnit({
      weightType: footprint?.weight_units,
      value: footprint?.total_co2e_purchased_in_kg,
      format: '%v %u',
    })
  }

  return (
    <div className="report-stat">
      <Icon />
      <div className="details">
        <span className="carbon-amount">
          {isPurchases
            ? footprint?.total_number_of_purchases
            : determineTotalFootprint()}
        </span>
        <span className="of-carbon">
          {isPurchases ? 'Purchases' : 'of Carbon'}
        </span>
      </div>
    </div>
  )
}

export default ReportStat
