import { useEffect } from 'react'
import { useService } from 'model/hooks/useService'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useCheckoutQueryParamContext } from 'view/pages/checkout-public/contexts/CheckoutQueryParamContext'
import { useEstimateCalculation } from 'view/pages/checkout-public/contexts/useEstimateCalculation'

export const useEstimateAPIRequest = (
  projectSlug,
  isReadyToFetchEstimate,
  isPortfolio = false
) => {
  const { shopBy, tags, note } = useCheckoutQueryParamContext()
  const { estimate } = useEstimateCalculation()

  const { checkoutSlug } = useParams()

  const { serviceRequest } = useService()

  const { weight_unit, total_cost, total_weight, currency } = estimate

  const handleEstimation = async () => {
    let body = {}
    if (isPortfolio) {
      body = {
        tags: ['storefront', checkoutSlug, ...tags],
        project_match: { portfolio_id: projectSlug },
        note: note,
        currency: currency.toUpperCase(),
      }
    } else {
      body = {
        tags: ['storefront', checkoutSlug, ...tags],
        project_match: { project_id: projectSlug },
        note: note,
        currency: currency,
      }
    }
    //the BE expects 'mt' instead of 't'
    let weightFormat = weight_unit === 'tonnes' ? 'mt' : weight_unit

    let formatted_body = {}
    if (shopBy === 'carbon') {
      if (Number(total_weight) === 0) return
      formatted_body = { value: Number(total_weight), units: weightFormat }
      body.weight = formatted_body
    } else {
      if (Number(total_cost) === 0) return
      formatted_body = {
        value: Number(total_cost),
        units: currency.toUpperCase(),
      }
      body.currency = formatted_body
    }
    const { data } = await serviceRequest({
      path: `/estimates/${shopBy}`,
      method: 'POST',
      data: body,
      dataOnly: false,
      accountSlug: localStorage.getItem('account_slug'),
    })
    return data
  }

  const { refetch, data } = useQuery('estimateFromBE', handleEstimation, {
    enabled: !!isReadyToFetchEstimate,
    onSuccess: data =>
      localStorage.setItem('cached-estimate', JSON.stringify(data)),
  })

  useEffect(() => {
    if (isReadyToFetchEstimate && estimate) {
      refetch()
    }
  }, [isReadyToFetchEstimate, estimate, refetch])

  return { estimateFromBE: data, estimateFromFE: estimate }
}
