import React, { useMemo } from 'react'
import { Document, Page, StyleSheet, Styles } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { useFeatureFlag } from 'model/hooks/useFeatureFlag'
import { registerPDFFonts } from 'view/components/pdf-components/common-components/PDFFonts'
import MainColumn from 'view/components/pdf-components/project-components/MainColumn'
import SideColumn from 'view/components/pdf-components/project-components/SideColumn'
import {
  projectInventory,
  projectTable,
  quotePanel,
  sidebarLayout,
} from 'view/components/pdf-components/styles/PDFStyles'

type Props = {
  project: Project
  defaultCurrency: string
  currencyRates: object
  estimate: object
  projectDescriptionHtml: string | null
}

const ProjectDetailsPDF = ({
  project,
  projectDescriptionHtml,
  defaultCurrency,
  currencyRates,
  estimate,
}: Props) => {
  const { consoleLog } = useFeatureFlag()

  // register pdf fonts
  useMemo(() => {
    registerPDFFonts()
  }, [])

  /**
   * @type {object}
   */
  const styles = useMemo(
    () =>
      StyleSheet.create({
        ...sidebarLayout,
        ...projectInventory,
        ...projectTable,
        ...quotePanel,
      } as Styles),
    []
  )

  /**
   * @type {React.JSX.Element}
   */
  const mainColumn = useMemo(
    () => (
      <MainColumn
        project={project}
        projectDescriptionHtml={projectDescriptionHtml}
        styles={styles}
      />
    ),
    [project, projectDescriptionHtml, defaultCurrency, currencyRates]
  )

  if (!project) return null

  consoleLog('PDF Rerender. projectEstimate:', estimate)

  //consoleLog('projectDescriptionHtml:', projectDescriptionHtml)

  return (
    <Document>
      <Page style={styles.page} size="LETTER">
        <SideColumn
          project={project}
          defaultCurrency={defaultCurrency}
          currencyRates={currencyRates}
          estimate={estimate}
          styles={styles}
        />
        {mainColumn}
      </Page>
    </Document>
  )
}

export default ProjectDetailsPDF
