import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Card, Col, Row } from 'react-bootstrap'
import NoDataAvailablePanel from './NoDataAvailablePanel'

const DashboardProjects = ({ account, footprint }) => {
  const [pieColors] = useState([
    '#04e396',
    '#028ffb',
    '#feb019',
    '#ff4560',
    '#775dd0',
  ])
  const [totalProjectCount, setTotalProjectCount] = useState(0)
  const [chartOptions, setChartOptions] = useState({})
  const [topProjects, setTopProjects] = useState([])

  /**
   * Effect for populating Top Projects panel
   */
  useEffect(() => {
    const topProjectsTemp = []
    if (footprint?.top_projects && footprint?.top_projects.length > 0) {
      setTotalProjectCount(footprint.top_projects.length)
      footprint?.top_projects.forEach((project, i) => {
        if (i < 5)
          topProjectsTemp.push({ name: project.name, slug: project.slug })
      })
    } else {
      setTotalProjectCount(0)
    }
    setTopProjects(topProjectsTemp)
  }, [account, footprint])

  /**
   * Effect for populating Project Types panel
   */
  useEffect(() => {
    const labels = []
    const series = []
    if (footprint?.project_types && footprint?.project_types.length > 0)
      footprint?.project_types.forEach((type, i) => {
        if (i < 5) {
          labels.push(type.name)
          series.push(parseFloat(type.carbon_in_grams))
        }
      })
    setChartOptions({
      width: 180,
      height: 180,
      labels,
      series,
      colors: pieColors,
      legend: { show: false },
      dataLabels: { enabled: false },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: { size: '80%', labels: { show: false } },
        },
      },
    })
    // eslint-disable-next-line
  }, [account, footprint])

  return (
    <Row className="projects">
      <Col className="project-types">
        <Card className="pie-card" body>
          <h3>Project Types</h3>

          {
            // dashboard data available
            (account?.has_offset_data || account?.has_footprint) &&
              footprint?.project_types && (
                <>
                  {!!topProjects?.length && (
                    <div className="content-container">
                      <div className="chart-container">
                        <Chart
                          className="chart"
                          options={chartOptions}
                          series={chartOptions.series || []}
                          type="donut"
                          height="180"
                          width="180"></Chart>
                        <div className="project-count-container">
                          <div className="count-value">{totalProjectCount}</div>
                          <div className="count-label">Projects</div>
                        </div>
                      </div>
                      <div className="legend-container">
                        {chartOptions?.labels?.map((label, i) => (
                          <div key={i} className="legend-row">
                            <div
                              className="legend-marker"
                              style={{ background: pieColors[i] }}></div>
                            <div className="legend-label">{label}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )
          }

          {
            // no dashboard data
            (!account?.has_offset_data || !footprint?.project_types) && (
              <>
                <div className="no-data">
                  <NoDataAvailablePanel />
                </div>
              </>
            )
          }
        </Card>
        <Card body>
          <h3>Top Projects</h3>

          {
            // list top project links
            account?.has_offset_data && footprint?.top_projects && (
              <>
                <ol>
                  {topProjects.map((project, idx) => (
                    <li key={idx}>{project.name}</li>
                  ))}
                </ol>
              </>
            )
          }

          {
            // no dashboard data
            (!account?.has_offset_data || !footprint?.top_projects) && (
              <>
                <div className="no-data">
                  <NoDataAvailablePanel />
                </div>
              </>
            )
          }
        </Card>
      </Col>
    </Row>
  )
}

export default DashboardProjects
