import React, { useState } from 'react'
import { unsdgMap } from 'model/utils/unsdg'
import ExpandButton from 'view/components/buttons/expand-button/ExpandButton'
import UnsdgList from './UnsdgList'

type Props = {
  unsdgList?: number[]
  forPortfolio?: boolean
}

const ProjectUnsdgPanel = ({ unsdgList, forPortfolio }: Props) => {
  const [expanded, setExpanded] = useState(false)

  const panelTitle = 'U.N. Sustainable Development Goals'

  const toggleExpand = () => setExpanded(!expanded)

  const hasMoreThanFour = !!unsdgList?.length && unsdgList.length > 4

  const list = unsdgList?.sort((a, b) => (a > b ? 1 : -1))
  const uniqueList: number[] = Array.from(new Set(list))

  const collapsedUnsdgList = uniqueList?.slice(0, 4)
  const expandedUnsdgList = uniqueList?.slice(4, uniqueList.length)

  const expandBtnLabel = `View ${expanded ? 'less' : 'more'}`

  return (
    !!unsdgList?.length && (
      <section className="flex flex-col p-4 gap-4 rounded-xl border border-neutral-80">
        <h2 className="font-bold text-lg">{panelTitle}</h2>
        {forPortfolio && (
          <p className="text-neutral-50 text-sm">
            SDGs shown are aggregated across all projects in the portfolio.
          </p>
        )}
        <UnsdgList list={collapsedUnsdgList} />
        {hasMoreThanFour && (
          <section className="flex flex-col gap-4">
            {!expanded && (
              <div className="flex gap-1 flex-wrap">
                {expandedUnsdgList?.map((item, idx) => {
                  const unsdg = unsdgMap.get(item)
                  return (
                    <img
                      className="w-8 h-8 rounded"
                      key={`${unsdg?.title} ${idx}`}
                      src={unsdg?.icon}
                      alt={unsdg?.title}
                    />
                  )
                })}
              </div>
            )}
            {expanded && <UnsdgList list={expandedUnsdgList} />}
            <ExpandButton
              toggle={toggleExpand}
              expanded={expanded}
              label={expandBtnLabel}
              className="bg-neutral-95 hover:bg-neutral-90"
            />
          </section>
        )}
      </section>
    )
  )
}

export default ProjectUnsdgPanel
