import React, { useState } from 'react'
import { Portfolio } from 'interfaces/Portfolio.interface'
import { useDownloadPortfolioPdf } from 'model/hooks/portfolio/useDownloadPortfolioPdf'
import { convertEstimateToQuote } from 'model/utils/portfolio/portfolioUtils'
import { useNavigate } from 'react-router-dom'
import { CloverlyButton } from 'view/components/cloverly-button/CloverlyButton'
import FolderOpenIcon from 'view/components/icons/folder-open-icon/FolderOpenIcon'
import PriceTagIcon from 'view/components/icons/price-tag-icon/PriceTagIcon'
import { useMarketplaceContext } from 'view/pages/marketplace/MarketplaceContext'
import PortfolioTag from './PortfolioTag'

type Props = {
  portfolio?: Portfolio | null
  price: string
}

const PortfolioHeader = ({ portfolio, price }: Props) => {
  const { currencyRates, projectEstimate } = useMarketplaceContext()
  const navigate = useNavigate()
  const [pdfLoading, setPdfLoading] = useState(false)
  const { downloadPortfolioPdf } = useDownloadPortfolioPdf({
    portfolio,
    currencyRates,
    quote: convertEstimateToQuote(projectEstimate),
    setPdfLoading,
  })

  const projectCount = portfolio?.offset_sources?.length ?? 0
  const projectCountTag = `${projectCount} projects`
  const handleGoBack = () => navigate(-1)

  return (
    <>
      <section className="px-10 py-4 flex gap-1 text-sm text-muted font-medium border-b border-b-neutral-80">
        <p
          role="link"
          className="cursor-pointer hover:underline text-primary"
          onClick={handleGoBack}
        >
          Portfolios
        </p>
        <p>/</p>
        <p>{portfolio?.name}</p>
      </section>
      <section className="py-6 px-10 border-b border-b-neutral-80 flex justify-between">
        <section className="flex flex-col gap-2">
          <h2 className="text-[32px] font-bold">{portfolio?.name}</h2>
          <div className="flex gap-2">
            <PortfolioTag
              bgColor="bg-green-90"
              icon={<PriceTagIcon />}
              text={price}
            />
            <PortfolioTag
              bgColor="bg-yellow-90"
              icon={<FolderOpenIcon fill="#994D00" />}
              text={projectCountTag}
            />
          </div>
        </section>
        <CloverlyButton
          state="neutral"
          variant="outlined"
          onClick={downloadPortfolioPdf}
          loading={pdfLoading}
        >
          Download as PDF
        </CloverlyButton>
      </section>
    </>
  )
}

export default PortfolioHeader
