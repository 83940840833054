import React from 'react'
import { CurrencyRate } from 'interfaces/Currency.interface'
import { Project } from 'interfaces/Project.interface'
import { handleCalculateCostPerTonne } from 'model/utils/cost'
import { getMinCostPerTonneUsd } from 'model/utils/project/projectUtils'
import Button from 'view/components/button/Button'

type Props = {
  project: Project
  defaultCurrency: string
  currencyRates: CurrencyRate[]
  leadGenNotify: (e: React.MouseEvent) => void
}

const LeadGenPanel = ({
  project,
  defaultCurrency,
  currencyRates,
  leadGenNotify,
}: Props) => {
  const minCostPerTonneUsd = getMinCostPerTonneUsd(project)
  let message = <p>This project is accepting new orders.</p>
  if (minCostPerTonneUsd) {
    message = (
      <p>
        Quotes for this project start at{' '}
        <b>
          {handleCalculateCostPerTonne(
            minCostPerTonneUsd,
            defaultCurrency,
            currencyRates
          )}{' '}
          / t.
        </b>
      </p>
    )
  }
  return (
    <div className="lead-gen-panel">
      {message}
      <Button
        cn="purchase-btn"
        onClick={(e: React.MouseEvent) => leadGenNotify(e)}
      >
        Get a quote
      </Button>
    </div>
  )
}

export default LeadGenPanel
