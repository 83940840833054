import React, { useEffect, useState } from 'react'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { Col, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import { usePaymentContext } from '../../../pages/checkout-admin/contexts/CheckoutContext'
import './DeleteCheckoutModal.scss'

const DeleteCheckoutModal = ({
  showModal = false,
  setShowModal,
  deleteCompleted = () => {},
}) => {
  const { deleteCheckoutPageSlug } = usePaymentContext()
  const { register, handleSubmit, reset, watch } = useForm()
  const { infoToast, warningToast } = useToast()
  const [isConfirmed, setIsConformed] = useState(false)
  const { serviceRequest } = useService()

  const confirmText = 'DELETE'
  const deleteConfirmedMessage = 'The Direct Checkout page has been deleted.'
  const deletePageFailedMessage =
    'Could not delete your page at this time. If the problem persists, contact support@cloverly.com.'

  const confirmDeleteTextVerified = confirmTextEntered =>
    confirmTextEntered
      ? confirmTextEntered.trim().toUpperCase() === confirmText
      : false

  useEffect(() => {
    const subscription = watch(value =>
      setIsConformed(confirmDeleteTextVerified(value?.confirmText))
    )
    return () => subscription.unsubscribe()
  }, [watch])

  const onSubmit = data => {
    if (!confirmDeleteTextVerified(data.confirmText)) return
    serviceRequest({
      path: `/checkout-pages/${deleteCheckoutPageSlug}`,
      method: 'DELETE',
    })
      .then(() => {
        infoToast(deleteConfirmedMessage)
        setShowModal(false)
        deleteCompleted()
        reset()
      })
      .catch(e => {
        warningToast(
          e.response?.data?.Message || deletePageFailedMessage,
          'warning'
        )
        console.error(e)
      })
  }

  const onCancel = e => {
    if (e) {
      e.preventDefault()
      // only close if the button was actually clicked and not return key pressed
      if (e.pageX && e.pageY) {
        reset()
        setShowModal(false)
      }
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        size="lg"
        className="delete-checkout-modal"
        centered
      >
        <Modal.Body>
          <CloseDialogButton onClick={e => onCancel(e)} />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Confirm</h2>
            <h3>This action is irreversible.</h3>
            <p>
              Once you delete this page, you can no longer use it to accept
              payments. You can always create more payment pages.
            </p>
            <Form.Group
              as={Col}
              controlId="confirmControl"
              className="confirm-form-group"
            >
              <Form.Label>
                Type <b>{confirmText}</b> to confirm:
              </Form.Label>
              <Form.Control type="text" {...register('confirmText')} />
            </Form.Group>
            <footer>
              <Button cn="cancel-dark" onClick={e => onCancel(e)}>
                Cancel
              </Button>
              <Button cn="warning" type="submit" disabled={!isConfirmed}>
                Delete page
              </Button>
            </footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DeleteCheckoutModal
