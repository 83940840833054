const formatCost = (cost, currency = 'USD', roundToMillions = true) => {
  let formatter
  try {
    formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    })
  } catch (e) {
    formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    currency = 'USD'
  }

  if (Number(cost) >= 1000000 && roundToMillions) {
    return formatter.format(Number(cost) / 1000000) + 'M'
  }
  //because Intl.NumberFormat doesn't support SGD
  if (currency === 'SGD') {
    formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
    })
    return 'S$' + formatter.format(Number(cost))
  }

  return formatter.format(Number(cost) || 0)
}

/**@param isDollarSymbolExplicit in some use cases, the letter is required along with dollar symbol */
const determineSymbol = (currency, isDollarSymbolExplicit = false) => {
  let symbol = '$'
  if (currency.toUpperCase() === 'EUR') symbol = '€'
  if (currency.toUpperCase() === 'GBP') symbol = '£'
  if (currency.toUpperCase() === 'JPY') symbol = '¥'
  if (isDollarSymbolExplicit) {
    if (currency.toUpperCase() === 'AUD') symbol = 'A$'
    if (currency.toUpperCase() === 'SGD') symbol = 'S$'
    if (currency.toUpperCase() === 'CAD') symbol = 'CA$'
    if (currency.toUpperCase() === 'NZD') symbol = 'NZ$'
  }
  return symbol
}

const formatCostInCurrency = (
  cost,
  currency,
  hasCurrencyInLetters = true,
  isDollarSymbolExplicit = false,
  maximumFractionDigits = 3,
  minimumFractionDigits = 0
) => {
  let currencyLetters = currency
  //whether to display the currency letters after the numbers
  if (!hasCurrencyInLetters) currencyLetters = ''
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits,
  })
  return (
    determineSymbol(currency, isDollarSymbolExplicit) +
    formatter.format(Number(cost)) +
    ' ' +
    currencyLetters
  )
}

const determineCurrencyRate = (currencyType, currencyRates) => {
  let result = 1
  if (!!currencyRates?.length && currencyType) {
    currencyRates?.map(currencyRateFromBE => {
      if (currencyRateFromBE.currency === currencyType.toUpperCase())
        result = currencyRateFromBE?.rate
    })
  }
  return result
}

const handleCalculateCostPerTonne = (cost, currencyType, currencyRates) => {
  // if currency rate is not a valid array (aka false), then set currency type to USD
  const currencyTypeToApply = currencyRates ? currencyType : 'USD'
  let costPerTonne =
    cost * determineCurrencyRate(currencyTypeToApply, currencyRates)
  return formatCost(costPerTonne, currencyTypeToApply)
}

export {
  formatCost,
  determineCurrencyRate,
  formatCostInCurrency,
  determineSymbol,
  handleCalculateCostPerTonne,
}
