const validateIsFixedOrAny = (setIsFixedOrAny, isFixedOrAny, pathname) => {
  if (!['any', 'fixed'].includes(isFixedOrAny)) {
    if (pathname.includes('/stripe-checkout')) setIsFixedOrAny('fixed')
    else if (pathname.includes('choose-amount')) setIsFixedOrAny('any')
    else setIsFixedOrAny('any')
  }
}

const validateWeightUnit = (setWeightUnit, weightUnit) => {
  if (!['tonnes', 'kg', 'lbs'].includes(weightUnit)) setWeightUnit('tonnes')
}

const isValidCurrency = currency =>
  ['USD', 'GBP', 'EUR', 'CAD', 'AUD', 'NZD', 'JPY', 'SGD'].includes(
    currency?.toUpperCase()
  )

const validateCurrency = (setCurrency, currency) => {
  const isDynamic = localStorage.getItem('areSettingsDynamic')
  if (!isValidCurrency(currency)) setCurrency('USD')
  if (isDynamic !== null && isDynamic === false) setCurrency('USD')
}

const validateShopBy = (setShopBy, shopBy) => {
  if (!['carbon', 'currency'].includes(shopBy)) setShopBy('currency')
}

export {
  validateCurrency,
  validateIsFixedOrAny,
  validateShopBy,
  validateWeightUnit,
  isValidCurrency,
}
