import React, { useState } from 'react'
import { Project } from 'interfaces/Project.interface'
import ExpandButton from 'view/components/buttons/expand-button/ExpandButton'
import InternalLink from 'view/components/internal-link/InternalLink'
import ProjectRegistry from 'view/pages/project-details/components/project-registry/ProjectRegistry'
import ProjectTechnologySection from './project-metadata/project-technology/ProjectTechnologySection'
import ProjectMetadata from './project-metadata/ProjectMetadata'
import ProjectTranchSection from './project-metadata/ProjectTranchSection'
import RatingsSection from './project-metadata/RatingsSection'

type Props = {
  project: Project
}

const PortfolioComposition = ({ project }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(!expanded)

  const projectName = project.short_name || project.name
  const imageList = project?.image_list

  const getPrimaryImg = () => {
    const primaryImg = imageList?.find(img => {
      return img.type.toUpperCase() === 'THUMBNAIL' && img.is_primary
    })
    return primaryImg?.url
  }

  return (
    <section className="p-4 flex flex-col border border-neutral-80 rounded-xl gap-2">
      <div className="flex gap-3 justify-between">
        <section className="flex gap-3 w-[50%] max-[1126px]:flex-wrap">
          <ExpandButton
            expanded={expanded}
            toggle={toggleExpanded}
            className="h-fit px-0"
          />
          <img
            src={getPrimaryImg()}
            alt={projectName}
            className="object-cover rounded-full h-10 w-10"
          />
          <section className="flex flex-col gap-[2px]">
            <p className="font-bold">{projectName}</p>
            <ProjectMetadata project={project} />
          </section>
        </section>
        <ProjectTranchSection project={project} />
      </div>
      {expanded && (
        <section className="flex flex-col gap-2">
          <ProjectTechnologySection project={project} />
          <RatingsSection project={project} />
          <ProjectRegistry project={project} />
          <InternalLink
            title="Project Details"
            url={`/marketplace/project-details/${project.id}`}
            hasLinkIconAlways
            className="ml-auto"
            opensNewTab
          />
        </section>
      )}
    </section>
  )
}

export default PortfolioComposition
