import { useEffect, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { formatCost, handleCalculateCostPerTonne } from 'model/utils/cost'
import { useMarketplaceContext } from 'view/pages/marketplace/MarketplaceContext'

/**
 * @type {{RECEIPT: number, CONFIRMATION: number, SELECTION: number}}
 */
export const VintageModalState = {
  SELECTION: 1,
  CONFIRMATION: 2,
  COMPLETE: 3,
}

/**
 * @returns {{modalState: number, setModalState: (value: (((prevState: number) => number) | number)) => void}}
 */
export const usePurchaseModal = () => {
  const { account } = useUser()
  const { serviceRequestMulti, spreadResponses } = useService()
  const [modalState, setModalState] = useState(VintageModalState.SELECTION)
  const [formattedAmount, setFormattedAmount] = useState('')
  const [formattedCost, setFormattedCost] = useState('')
  const [formattedCostPerTonne, setFormattedCostPerTonne] = useState('')
  const [card, setCard] = useState(undefined)

  const {
    setShowEmptyStateModal,
    setShowModalWithValues,
    amount,
    weightType,
    purchaseEstimateWithValues,
    projectEstimate,
    selectedVintage,
    defaultCurrency,
    currencyRates,
    hasInventoryError,
    estimateErrorType,
    isEstimationLoading,
  } = useMarketplaceContext()

  //get project details
  useEffect(() => {
    // if the user has already entered an amount, skip to the confirmation screen
    if (amount) {
      setModalState(VintageModalState.CONFIRMATION)
    }
  }, [])

  useEffect(() => {
    if (!isEstimationLoading && hasInventoryError && estimateErrorType)
      setModalState(VintageModalState.SELECTION)
  }, [hasInventoryError, estimateErrorType, isEstimationLoading])

  useEffect(() => {
    setFormattedAmount(
      amount + ' ' + (weightType === 'tonnes' ? 't' : weightType)
    )
  }, [amount, weightType])

  useEffect(() => {
    if (projectEstimate?.total_cost)
      setFormattedCost(formatCost(projectEstimate?.total_cost, defaultCurrency))
    else setFormattedCost('')
  }, [projectEstimate?.total_cost, defaultCurrency])

  useEffect(() => {
    setFormattedCostPerTonne(
      handleCalculateCostPerTonne(
        selectedVintage?.cost_per_kg_carbon_in_usd_cents * 10,
        defaultCurrency,
        currencyRates
      ) + ' / t'
    )
  }, [selectedVintage?.cost_per_kg_carbon_in_usd_cents])

  //get project details
  useEffect(() => {
    // load credit card information if stripe id exists
    if (account?.billing_method === 'stripe' && account?.stripe_id)
      serviceRequestMulti({
        requests: [
          { path: '/account/billing/card?stripe_id=' + account.stripe_id },
        ],
      }).then(
        spreadResponses(cardResponse => {
          if (cardResponse) setCard(cardResponse.data)
        })
      )
  }, [account?.billing_method, account?.stripe_id])

  const closeModal = () => {
    setShowModalWithValues(false)
    setShowEmptyStateModal(false)
  }

  const handleSelectionAction = () => {
    //console.log('Handling Selection Action...')
    setModalState(VintageModalState.CONFIRMATION)
  }

  const handlePurchaseAction = e => {
    //console.log('Handling Purchase Action...')
    purchaseEstimateWithValues(e).then(() => {
      //console.log("Purchase Complete!")
      setModalState(VintageModalState.COMPLETE)
    })
  }

  const renderModalTitle = modalState => {
    switch (modalState) {
      case VintageModalState.SELECTION:
        return 'Add to Order'
      case VintageModalState.CONFIRMATION:
        return 'Confirm Order'
      case VintageModalState.COMPLETE:
        return 'Order complete'
      default:
        return ''
    }
  }

  const handleBack = e => {
    e.preventDefault()
    switch (modalState) {
      case VintageModalState.CONFIRMATION:
        return setModalState(VintageModalState.SELECTION)
    }
  }

  return {
    account,
    card,
    modalState,
    setModalState,
    closeModal,
    handleBack,
    handleSelectionAction,
    handlePurchaseAction,
    renderModalTitle,
    formattedAmount,
    formattedCost,
    formattedCostPerTonne,
  }
}
