import React from 'react'
import { getUnits, getWeightUnit } from 'model/utils/units'
import { Card, Col, Row } from 'react-bootstrap'
import CountUp from 'react-countup'
import { useNavigate } from 'react-router-dom'
import AppTooltip from 'view/components/app-tooltip/AppTooltip'
import Button from 'view/components/button/Button'
import NoDataAvailablePanel from './NoDataAvailablePanel'
import OffsetChart from './OffsetChart'
import { useMarketplaceContext } from '../../marketplace/MarketplaceContext'

const DashboardStats = ({ account, footprint }) => {
  const { setAmount, setWeightType } = useMarketplaceContext()
  const navigate = useNavigate()

  const handlePurchaseCarbon = () => {
    setAmount(
      Math.floor(
        getUnits({
          isKg: account.display_kg,
          value: footprint?.total_balance ? footprint?.total_balance : 0,
        })
      )
    )
    setWeightType(account?.display_kg ? 'kg' : 'lbs')
    navigate('/marketplace')
  }

  const handleDetermineDecimals = value => {
    return value !== 0 ? 2 : 0
  }

  const isDisplayRealAmount = value => {
    if (account?.display_kg) {
      return value > 999.99 || value < -999.99
    } else {
      //if in lbs
      return value * 2.20462 > 1999.99 || value * 2.20462 < -1999.99
    }
  }

  return (
    <>
      <Row className="stats">
        <Col
          className={
            !(account?.has_footprint && account?.has_offset_data) ? 'wide' : ''
          }
        >
          <Card body>
            <h3>Total Footprint</h3>
            {account?.has_footprint && (
              <>
                <p className="stat-value secondary">
                  <CountUp
                    start={0}
                    end={getWeightUnit({
                      weightType: account?.display_kg ? 'kg' : 'lbs',
                      value: footprint?.total_footprint,
                    })}
                    duration={0.5}
                    decimals={handleDetermineDecimals(
                      footprint?.total_footprint
                    )}
                    delay={0}
                    separator=","
                  >
                    {({ countUpRef }) => (
                      <span className="value-label" ref={countUpRef}>
                        0
                      </span>
                    )}
                  </CountUp>
                  <span>
                    {getWeightUnit({
                      weightType: account?.display_kg ? 'kg' : 'lbs',
                      value: footprint?.total_footprint,
                      format: '%u',
                    })}
                  </span>
                </p>
                {isDisplayRealAmount(footprint?.total_footprint) && (
                  <span className="actual-amount">
                    {getUnits({
                      isKg: account?.display_kg,
                      value: footprint?.total_footprint,
                      format: '%v %u',
                    })}
                  </span>
                )}
              </>
            )}
            {!account?.has_footprint && (
              <>
                <p className="message">
                  Get Additional analytics by sending footprint data through the
                  API or upload directly from the app (coming soon).{' '}
                  <a
                    href="https://docs.cloverly.com/docs/cloverly-api/ZG9jOjM3MTI2MTIw-footprinting"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </a>
                </p>
              </>
            )}
          </Card>
        </Col>
        <Col>
          <Card body>
            <h3>Total Offset</h3>
            <p className="stat-value">
              <CountUp
                start={0}
                end={getWeightUnit({
                  weightType: account?.display_kg ? 'kg' : 'lbs',
                  value: account?.has_offset_data
                    ? footprint?.total_purchases
                    : 0,
                })}
                duration={0.5}
                decimals={
                  account?.has_offset_data
                    ? handleDetermineDecimals(footprint?.total_purchases)
                    : 0
                }
                delay={0}
                separator=","
              >
                {({ countUpRef }) => (
                  <span className="value-label" ref={countUpRef}>
                    0
                  </span>
                )}
              </CountUp>
              <span>
                {getWeightUnit({
                  weightType: account?.display_kg ? 'kg' : 'lbs',
                  value: account?.has_offset_data
                    ? footprint?.total_purchases
                    : 0,
                  format: '%u',
                })}
              </span>
            </p>
            {isDisplayRealAmount(footprint?.total_purchases) && (
              <span className="actual-amount">
                {getUnits({
                  isKg: account?.display_kg,
                  value: account?.has_offset_data
                    ? footprint?.total_purchases
                    : 0,
                  format: '%v %u',
                })}
              </span>
            )}
          </Card>
        </Col>
        {account?.has_footprint && account?.has_offset_data && (
          <>
            <Col>
              <Card body>
                <div className="card-header-with-icons">
                  <div className="title-with-icon">
                    <h3>Carbon Balance</h3>
                    <AppTooltip
                      id="carbon-balance"
                      placement="top"
                      className="panel-header-tooltip-icon"
                      message={
                        <div>
                          The difference between total footprint
                          <br />
                          emissions and total offset purchased.
                        </div>
                      }
                    />
                  </div>
                  {footprint?.total_balance > 0 ? (
                    <Button onClick={() => handlePurchaseCarbon()}>Shop</Button>
                  ) : (
                    ''
                  )}
                </div>
                <p className="stat-value secondary">
                  <CountUp
                    start={0}
                    end={getWeightUnit({
                      weightType: account?.display_kg ? 'kg' : 'lbs',
                      value: footprint?.total_balance
                        ? footprint?.total_balance
                        : 0,
                    })}
                    duration={0.5}
                    decimals={handleDetermineDecimals(footprint?.total_balance)}
                    delay={0}
                    separator=","
                  >
                    {({ countUpRef }) => (
                      <span className="value-label" ref={countUpRef}>
                        0
                      </span>
                    )}
                  </CountUp>
                  <span>
                    {getWeightUnit({
                      weightType: account.display_kg ? 'kg' : 'lbs',
                      value: footprint?.total_balance
                        ? footprint?.total_balance
                        : 0,
                      format: '%u',
                    })}
                  </span>
                </p>
                {isDisplayRealAmount(footprint?.total_balance) && (
                  <span className="actual-amount">
                    {getUnits({
                      isKg: account?.display_kg,
                      value: footprint?.total_balance,
                      format: '%v %u',
                    })}
                  </span>
                )}
              </Card>
            </Col>
          </>
        )}
      </Row>
      <Row className="stats graph">
        <Col>
          <Card body>
            <div className="chart-container">
              <h3>Activity</h3>
              {account?.has_offset_data || account?.has_footprint ? (
                <>
                  <div className="chart-box">
                    <OffsetChart footprint={footprint} />
                  </div>
                </>
              ) : (
                <div className="chart-box no-data">
                  <NoDataAvailablePanel />
                </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default DashboardStats
