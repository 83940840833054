import React, { useState } from 'react'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import LockIcon from '@mui/icons-material/LockOutlined'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { inputFormatExpiration } from 'model/utils/input-formatters'
import { Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import CreditCardsIcon from 'view/components/icons/credit-cards-icon/CreditCardsIcon'
import './Modal.scss'

const AddCardModal = ({
  showAddCard = false,
  setShowAddCard,
  addCardSuccessful,
}) => {
  const { register, handleSubmit, reset } = useForm()
  const [expirationValue, setExpirationValue] = useState('')
  const { account, refreshUser } = useUser()
  const { serviceRequest } = useService()
  const { infoToast, warningToast } = useToast()

  const onSubmit = data => {
    // add card functionality here
    const expiry = data.expiry.split(' / ')
    data.expMonth = expiry[0]
    data.expYear = expiry[1]
    delete data.expiry
    serviceRequest({
      path: `/dashboard/accounts/${account.slug}/billing`,
      method: 'POST',
      data,
    })
      .then(data => {
        if (data.success) {
          refreshUser().then(() => {
            infoToast(data.success)
            setShowAddCard(false)
            if (addCardSuccessful) {
              addCardSuccessful()
            }
            reset()
          })
        } else {
          warningToast(data.error)
        }
      })
      .catch(() => {
        warningToast('Your card could not be added at this time.')
      })
  }

  const onCancel = e => {
    e.preventDefault()
    setShowAddCard(false)
    reset()
  }

  return (
    <Modal show={showAddCard} size="lg" className="credit-card-modal" centered>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CloseDialogButton onClick={e => onCancel(e)} />
          <h2>Add a Card</h2>
          <p>
            If you have any questions, please email{' '}
            <a href="mailto:support@cloverly.com">support@cloverly.com!</a>
          </p>
          <div className="card-columns">
            <div className="icon-column">
              <div className="circle">
                <CreditCardsIcon />
              </div>
            </div>
            <div className="user-inputs">
              <div className="input-icon-container">
                <label htmlFor="frmCCNum">Card Number</label>
                <Form.Control
                  id="frmCCNum"
                  name="cardnumber"
                  type="number"
                  maxLength="16"
                  autoComplete="cc-number"
                  {...register('cardNumber', { required: true })}
                />
                <CreditCardIcon />
              </div>
              <div className="two-column">
                <div>
                  <label htmlFor="frmCCExp">Expiration</label>
                  <Form.Control
                    id="frmCCExp"
                    name="cc-exp"
                    type="text"
                    placeholder="MM/YY"
                    autoComplete="cc-exp"
                    value={expirationValue}
                    {...register('expiry', {
                      required: true,
                      onChange: e =>
                        setExpirationValue(
                          inputFormatExpiration(e.target.value)
                        ),
                    })}
                  />
                </div>
                <div className="input-icon-container">
                  <label htmlFor="frmCCCVC">CVC</label>
                  <Form.Control
                    id="frmCCCVC"
                    name="cvc"
                    type="number"
                    maxLength="6"
                    autoComplete="cc-csc"
                    {...register('cvc', { required: true })}
                  />
                  <LockIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="btn-group">
            <Button cn={'cancel'} onClick={e => onCancel(e)}>
              Cancel
            </Button>
            <Button type="submit">Add Card</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AddCardModal
