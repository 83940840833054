import React from 'react'
import { cn } from 'model/utils/shadcn/utils'

type Props = {
  icon: React.ReactNode
  label: string
  className?: string
}

const ProjectTag = ({ icon, label, className }: Props) => {
  return (
    <div
      className={cn(
        'bg-neutral-90 text-neutral-10 text-sm leading-[16px] font-medium rounded-md h-[26px] flex items-center py-0 px-3 whitespace-nowrap cursor-default',
        '[&>svg]:-ml-[5px] [&>svg]:mr-[3px]',
        className
      )}
    >
      {icon}
      <span>{label}</span>
    </div>
  )
}

export default ProjectTag
