import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'

export const useMarketplaceFilters = () => {
  const filters = {
    'Project Type': 'type',
    Registry: 'registry_name',
    'Removal Time Period': 'removal_time_period',
    Mechanism: 'offset_classification',
  }

  const filterProjectsByTypeValue = (products, filterType, filterValue) => {
    const filterValInNumbers = getFilterValInNumbers(filterValue)
    filterValue = filterValue?.toUpperCase()
    if (filterType && filterValue) {
      if (filterType === 'UN SDG') {
        products = products?.filter(
          product =>
            product.productType === ProductType.PROJECT &&
            (product.unsdg
              ? product?.unsdg?.includes(filterValInNumbers)
              : false)
        )
      } else {
        products = products.filter(
          product =>
            product.productType === ProductType.PROJECT &&
            filterValue === product[filters[filterType]]?.toUpperCase()
        )
      }
    }
    return products
  }

  const filterPortfoliosByTypeValue = (products, filterType, filterValue) => {
    const filterValInNumbers = getFilterValInNumbers(filterValue)
    filterValue = filterValue?.toUpperCase()
    if (filterType && filterValue) {
      if (filterType === 'UN SDG') {
        products = products?.filter(
          product =>
            product.productType === ProductType.PORTFOLIO &&
            inPortfolioByMatch(product, offset_source =>
              offset_source.unsdg
                ? offset_source?.unsdg?.includes(filterValInNumbers)
                : false
            )
        )
      } else {
        products = products.filter(
          product =>
            product.productType === ProductType.PORTFOLIO &&
            inPortfolioByMatch(
              product,
              offset_source =>
                filterValue ===
                offset_source[filters[filterType]]?.toUpperCase()
            )
        )
      }
    }
    return products
  }

  /**
   * Checks to see if one or more offset sources in a portfolio matches the matchFunction.
   *
   * @param portfolio
   * @param matchFunction
   * @returns {boolean}
   */
  const inPortfolioByMatch = (portfolio, matchFunction) =>
    !!portfolio?.offset_sources?.filter(matchFunction)?.length

  /**
   * Because the filter values in dropdown are such as "1. No Poverty" whereas the actual
   * DB values are only in the format of "1", "10"; we split the filter values to be in numbers only.
   *
   * @param filterValue
   * @returns {number|string}
   */
  const getFilterValInNumbers = filterValue =>
    filterValue ? Number(filterValue?.split(':')[0]) : ''

  return {
    filterProjectsByTypeValue,
    filterPortfoliosByTypeValue,
  }
}
