import React from 'react'

type Props = {
  fill?: string
}

const FolderOpenIcon = ({ fill = '#1A3F4C' }: Props) => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.73362 10.8337C1.36668 10.8337 1.04162 10.6918 0.758418 10.4081C0.475218 10.1249 0.333618 9.8001 0.333618 9.4337V1.5665C0.333618 1.2001 0.475218 0.875304 0.758418 0.592104C1.04162 0.308371 1.36668 0.166504 1.73362 0.166504H5.60002L6.93362 1.5001H12.3336C12.7 1.5001 13.0139 1.6417 13.2752 1.9249C13.536 2.2081 13.6664 2.53317 13.6664 2.9001H6.35042L5.01682 1.5665H1.73362V9.4337L3.35042 4.3001H14.6664L12.9168 9.8833C12.8278 10.1724 12.6694 10.403 12.4416 10.5753C12.2139 10.7476 11.9555 10.8337 11.6664 10.8337H1.73362ZM3.20002 9.4337H11.6L12.8 5.6337H4.40002L3.20002 9.4337Z"
      fill={fill}
    />
  </svg>
)

export default FolderOpenIcon
