import React, { useEffect, useState } from 'react'
import CloverlyTooltip from 'view/shamrock/tooltip/Tooltip'
import ExternalLinkIcon from '../icons/external-link-icon/ExternalLinkIcon'
import './ProjectInfoRequestLink.scss'

type Props = {
  id: string
  name: string
  label?: string
  className?: string
}

const ProjectInfoRequestLink = ({
  id,
  name,
  label = 'California AB1305 Request Form',
  className = '',
}: Props) => {
  const formUrl = 'https://share.hsforms.com/1jDT_tnYBQoeABGOD0oJ1oA5cl7t'

  const tooltipMessage = (
    <div>
      California’s Assembly Bill 1305
      <br />
      “Voluntary Carbon Market Disclosure
      <br />
      Act” (AB1305) aims to bring
      <br />
      transparency and accountability to the
      <br />
      voluntary carbon offset market and
      <br />
      related net zero claims in California.
    </div>
  )

  const [url, setUrl] = useState('')

  useEffect(() => {
    setUrl(
      `${formUrl}?project_id=${encodeURIComponent(id)}&project_name=${encodeURIComponent(name)}`
    )
  }, [id, name])

  return (
    <CloverlyTooltip
      className="text-base !bg-secondary-10"
      triggerClassName="w-fit font-semibold"
      trigger={
        <a
          target="_blank"
          className={`flex items-center gap-1 [&_svg]:hover:fill-link-secondary ${className}`}
          rel="noreferrer"
          href={url}
        >
          {label} <ExternalLinkIcon className="fill-link-primary" />
        </a>
      }
    >
      {tooltipMessage}
    </CloverlyTooltip>
  )
}

export default ProjectInfoRequestLink
