import React from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'view/shadcn-components/ui/select'

type Props = {
  onChange: (tag: string) => void
  tagsList: { value: string; label: string }[]
}

const TagFilterDropdown = ({ onChange, tagsList }: Props) => {
  return (
    <Select onValueChange={(value: string) => onChange(value)}>
      <SelectTrigger className="w-fit text-base border-neutral-80">
        <SelectValue placeholder="All filters" />
      </SelectTrigger>
      <SelectContent>
        {tagsList.map(tag => (
          <SelectItem
            className="pl-2 cursor-pointer text-base"
            key={tag.value}
            value={tag.value}
          >
            {tag.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default TagFilterDropdown
