import React from 'react'

const PurchaseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33331 15.8334C3.33331 16.2754 3.50891 16.6993 3.82147 17.0119C4.13403 17.3244 4.55795 17.5 4.99998 17.5C5.44201 17.5 5.86593 17.3244 6.17849 17.0119C6.49105 16.6993 6.66665 16.2754 6.66665 15.8334C6.66665 15.3913 6.49105 14.9674 6.17849 14.6548C5.86593 14.3423 5.44201 14.1667 4.99998 14.1667C4.55795 14.1667 4.13403 14.3423 3.82147 14.6548C3.50891 14.9674 3.33331 15.3913 3.33331 15.8334Z"
        stroke="#60747A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 15.8334C12.5 16.2754 12.6756 16.6993 12.9882 17.0119C13.3007 17.3244 13.7246 17.5 14.1667 17.5C14.6087 17.5 15.0326 17.3244 15.3452 17.0119C15.6577 16.6993 15.8333 16.2754 15.8333 15.8334C15.8333 15.3913 15.6577 14.9674 15.3452 14.6548C15.0326 14.3423 14.6087 14.1667 14.1667 14.1667C13.7246 14.1667 13.3007 14.3423 12.9882 14.6548C12.6756 14.9674 12.5 15.3913 12.5 15.8334Z"
        stroke="#60747A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1666 14.1667H4.99998V2.5H3.33331"
        stroke="#60747A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 4.16669L16.6667 5.00002L15.8333 10.8334H5"
        stroke="#60747A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PurchaseIcon
