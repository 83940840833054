import React from 'react'

/* notes to make this reusable:
  Pass in an array of strings for the table head tr's.
  Pass in tableDataRows instead of tableData.credits so that it can be generic.
  Convert any other repeated code into reusable code.
*/
const NumOfApiCallsTable = ({
  id = 'ct',
  tableData = [],
  isFullView = true,
}) => {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <td>Months</td>
            <td>Production Calls</td>
            <td>Sandbox Calls</td>
          </tr>
        </thead>
        <tbody>
          {!isFullView ? (
            <>
              {tableData?.slice(0, 5).map((item, index) => (
                <tr key={id + '_row_' + index}>
                  <td>{item.month}</td>
                  <td>{item.count}</td>
                  <td>{item.sandbox_count}</td>
                </tr>
              ))}
            </>
          ) : (
            <>
              {tableData?.map((item, index) => (
                <tr key={id + '_row_' + index}>
                  <td>{item.month}</td>
                  <td>{item.count}</td>
                  <td>{item.sandbox_count}</td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {tableData?.length === 0 && (
        <div className="empty-table-message">No API History</div>
      )}
    </div>
  )
}

export default NumOfApiCallsTable
