import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import './ImageModal.scss'

const ImageModal = ({ imageModal, setImageModal }) => {
  const [isLandscape, setIsLandscape] = useState(false)

  //close modal when Escape key is pressed
  useEffect(() => {
    const keyDownHandler = e => {
      if (e.key === 'Escape') {
        e.preventDefault()
        setImageModal({ isOpen: false })
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => document.removeEventListener('keydown', keyDownHandler)
  })

  useEffect(() => {
    const imageWidth = document.querySelector('.full-size-image').naturalWidth
    const imageHeight = document.querySelector('.full-size-image').naturalHeight
    if (imageWidth > imageHeight) {
      setIsLandscape(true)
    } else {
      setIsLandscape(false)
    }
  }, [imageModal?.image])

  return (
    <div>
      <Modal
        className={`image-modal ${isLandscape ? 'landscape' : ''}`}
        show={imageModal.isOpen}
        onHide={() => setImageModal({ isOpen: false })}
        size="lg"
        centered>
        <Modal.Body>
          <div className="full-size-image-container">
            <img
              className={`full-size-image ${isLandscape ? 'landscape' : ''}`}
              src={imageModal.image}
              alt="project-icon"
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ImageModal
