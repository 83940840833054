import React from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useUser } from 'model/hooks/account/useUser'
import UserIcon from 'view/components/icons/navbar-icons/UserIcon'

type Props = {
  openMenu: boolean
}

const CollapsedOptions = ({ openMenu }: Props) => {
  const { user, account } = useUser()

  const username = user ? `${user.first_name} ${user.last_name}` : '-'
  const companyName = account?.name ?? '-'
  return (
    <>
      {openMenu ? (
        <>
          <div className="flex items-center gap-2">
            <UserIcon />
            <div className="flex flex-col items-start">
              <p className="text-sm font-semibold text-left">{username}</p>
              <p className="text-xs text-muted text-left">{companyName}</p>
            </div>
          </div>
          <MoreHorizIcon className="cursor-pointer w-5" />
        </>
      ) : (
        <UserIcon />
      )}
    </>
  )
}

export default CollapsedOptions
