import React, { useEffect, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { setPageTitle } from 'model/utils/page'
import { mapConfig } from 'model/utils/react-map'
import ReactMapGL, { Marker } from 'react-map-gl'
import { useParams } from 'react-router-dom'
import Generic from 'view/components/icons/offset-types/svg/generic.svg'
import PinIcon from 'view/components/icons/pin-icon/PinIcon'
import Navigationbar from 'view/components/navigationbar/Navigationbar'
import { transactionReceiptComponents } from 'view/components/transaction-receipt-components/TransactionReceiptComponents'
import ShortDetails from './components/ShortDetails'
import './TransactionReceipt.scss'

const TransactionReceipt = () => {
  const { account } = useUser()
  const { serviceRequest } = useService()

  const { TransactionBasicDetails, UserTip } = transactionReceiptComponents()

  const [transaction, setTransaction] = useState(null)
  const [pinPoint, setPinPoint] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
  })
  const [viewport, setViewport] = useState({
    latitude: pinPoint.latitude,
    longitude: pinPoint.longitude,
    zoom: 5,
  })
  const [refetch, setRefetch] = useState(false)
  const [displayActualNumber, setDisplayActualNumber] = useState(false)

  const { id } = useParams()

  const handleFetchTransactionDetails = () => {
    serviceRequest({
      path: `/dashboard/transaction/${id}`,
      showLoading: false,
    })
      .then(data => {
        if (data.project) {
          const project = data.project
          // convert new line chars to new paragraphs for project's technical details text (if exists)
          if (project.technical_details)
            project.technical_details =
              '<p>' +
              project.technical_details?.trim()?.replaceAll('\n', '</p><p>') +
              '</p>'
          setTransaction(data)
          setPinPoint({
            latitude: project.latlng.x,
            longitude: project.latlng.y,
          })
          setViewport({
            ...viewport,
            latitude: project.latlng.x,
            longitude: project.latlng.y,
          })
        }
        setRefetch(false)
      })
      .catch(e => console.error(e))
  }

  const project = transaction?.project

  const getMapStyle = () =>
    transaction?.is_cobranding_enabled
      ? 'mapbox://styles/cloverly/ckyx5v3yx000315mnoru09kic'
      : 'mapbox://styles/cloverly/ckykicffr64sj14qp56qaxksj'

  useEffect(() => {
    setPageTitle('Transaction Details')
    handleFetchTransactionDetails()
    // eslint-disable-next-line
  }, [refetch])

  //display the actual amount if the kg/lbs amount is in mt/t" format underneath the mt/t amount
  useEffect(() => {
    if (account?.display_kg) {
      if (transaction?.total_co2e_in_kg > 999.99) setDisplayActualNumber(true)
    } else {
      //if in lbs
      if (transaction?.total_co2e_in_kg * 2.20462 > 1999.99)
        setDisplayActualNumber(true)
    }
    // eslint-disable-next-line
  }, [transaction])

  const RegistryLink = () =>
    project.registry_link ? (
      <>
        You can find verification documents at{' '}
        <a
          className="registry-link-url"
          target="_blank"
          rel="noopener noreferrer"
          href={project.registry_link}
        >
          {project.registry_link}
        </a>
        .
      </>
    ) : null

  return (
    <>
      {transaction && (
        <div
          className={`transaction-details receipt ${
            transaction?.is_cobranding_enabled ? 'cobranded' : ''
          }`}
        >
          <>
            <Navigationbar transaction={transaction} isReceiptPage />
            <div className="map-container">
              <ReactMapGL
                {...viewport}
                width="auto"
                height="400px"
                maxZoom={8}
                minZoom={2.5}
                mapStyle={getMapStyle()}
                mapboxAccessToken={mapConfig.mapboxAccessToken}
                onMove={evt => setViewport(evt.viewState)}
                onViewportChange={nextViewport => setViewport(nextViewport)}
              >
                <Marker
                  latitude={pinPoint.latitude}
                  longitude={pinPoint.longitude}
                  offset={mapConfig.markerOffset}
                >
                  <PinIcon />
                </Marker>
              </ReactMapGL>
            </div>
          </>
          <div className="containers receipt">
            <section className="left-container">
              <div className="short-details-wrapper">
                <TransactionBasicDetails
                  transaction={transaction}
                  account={account}
                  displayActualNumber={displayActualNumber}
                />
                <hr />
                <ShortDetails isReceiptPage transaction={transaction} />
              </div>
              <UserTip
                isCobrandingEnabled={transaction?.is_cobranding_enabled}
              />
            </section>

            {project && (
              <section className="right-container">
                <header>
                  <div
                    className={`header-details ${
                      !transaction?.is_cobranding_enabled ? 'non-cobranded' : ''
                    }`}
                  >
                    <p>{project.type} Project</p>
                    <h2> {project.name}</h2>
                    <p>
                      {project.city}, {project.state}, {project.country}
                    </p>
                  </div>
                  <div className="project-icon">
                    {project?.icon_url ? (
                      <>
                        <img src={project.icon_url} alt="Project type" />
                        <p>{project.type}</p>
                      </>
                    ) : (
                      <>
                        <img
                          className="generic-icon"
                          src={Generic}
                          alt="Project type"
                        />
                        <p>{project.type}</p>
                      </>
                    )}
                  </div>
                </header>
                <section className="technical-details">
                  <p className="technical-details-header">Technical details</p>
                  {project?.technical_details && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: project.technical_details,
                      }}
                    ></div>
                  )}
                  <p>
                    Cloverly buys credits that meet accepted standards for being
                    real, measurable, verifiable, permanent, and additional.
                    “Additional” means that the carbon savings would not have
                    happened without the project and that the project would not
                    have happened unless it got certified to sell carbon
                    credits. {''}
                    <RegistryLink />
                  </p>
                  <p>
                    For a map of projects in our portfolio, visit{' '}
                    <a href="https://info.cloverly.com/project-map">
                      www.cloverly.com/project-map
                    </a>
                    . Learn more about Cloverly at{' '}
                    <a href="https://www.cloverly.com">www.cloverly.com</a>.
                  </p>
                </section>
              </section>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default TransactionReceipt
