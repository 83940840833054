import icon1 from 'view/components/icons/sus-icons/1.png'
import icon10 from 'view/components/icons/sus-icons/10.png'
import icon11 from 'view/components/icons/sus-icons/11.png'
import icon12 from 'view/components/icons/sus-icons/12.png'
import icon13 from 'view/components/icons/sus-icons/13.png'
import icon14 from 'view/components/icons/sus-icons/14.png'
import icon15 from 'view/components/icons/sus-icons/15.png'
import icon16 from 'view/components/icons/sus-icons/16.png'
import icon17 from 'view/components/icons/sus-icons/17.png'
import icon2 from 'view/components/icons/sus-icons/2.png'
import icon3 from 'view/components/icons/sus-icons/3.png'
import icon4 from 'view/components/icons/sus-icons/4.png'
import icon5 from 'view/components/icons/sus-icons/5.png'
import icon6 from 'view/components/icons/sus-icons/6.png'
import icon7 from 'view/components/icons/sus-icons/7.png'
import icon8 from 'view/components/icons/sus-icons/8.png'
import icon9 from 'view/components/icons/sus-icons/9.png'

const unsdgMap = new Map([
  [
    1,
    {
      icon: icon1,
      title: 'No Poverty',
      description: 'End poverty in all its forms everywhere',
    },
  ],
  [
    2,
    {
      icon: icon2,
      title: 'Zero Hunger',
      description:
        'End hunger, achieve food security and improved nutrition and promote sustainable agriculture',
    },
  ],
  [
    3,
    {
      icon: icon3,
      title: 'Good Health and Well-Being',
      description:
        'Ensure healthy lives and promote well-being for all at all ages',
    },
  ],
  [
    4,
    {
      icon: icon4,
      title: 'Quality Education',
      description:
        'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all',
    },
  ],
  [
    5,
    {
      icon: icon5,
      title: 'Gender Equality',
      description: 'Achieve gender equality and empower all women and girls',
    },
  ],
  [
    6,
    {
      icon: icon6,
      title: 'Clean Water and Sanitation',
      description:
        'Ensure availability and sustainable management of water and sanitation for all',
    },
  ],
  [
    7,
    {
      icon: icon7,
      title: 'Affordable and Clean Energy',
      description:
        'Ensure access to affordable, reliable, sustainable and modern energy for all',
    },
  ],
  [
    8,
    {
      icon: icon8,
      title: 'Decent Work and Economic Growth',
      description:
        'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all',
    },
  ],
  [
    9,
    {
      icon: icon9,
      title: 'Industry, Innovation and Infrastructure',
      description:
        'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation',
    },
  ],
  [
    10,
    {
      icon: icon10,
      title: 'Reduced Inequalities',
      description: 'Reduce inequality within and among countries',
    },
  ],
  [
    11,
    {
      icon: icon11,
      title: 'Sustainable Cities and Communities',
      description:
        'Make cities and human settlements inclusive, safe, resilient and sustainable',
    },
  ],
  [
    12,
    {
      icon: icon12,
      title: 'Responsible Consumption and Production',
      description: 'Ensure sustainable consumption and production patterns',
    },
  ],
  [
    13,
    {
      icon: icon13,
      title: 'Climate Action',
      description:
        'Take urgent action to combat climate change and its impacts',
    },
  ],
  [
    14,
    {
      icon: icon14,
      title: 'Life Below Water',
      description:
        'Conserve and sustainably use the oceans, seas and marine resources for sustainable development',
    },
  ],
  [
    15,
    {
      icon: icon15,
      title: 'Life on Land',
      description:
        'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss',
    },
  ],
  [
    16,
    {
      icon: icon16,
      title: 'Peace, Justice and Strong Institutions',
      description:
        'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels',
    },
  ],
  [
    17,
    {
      icon: icon17,
      title: 'Partnerships for the Goals',
      description:
        'Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development',
    },
  ],
])

/**
 * Exports public defs.
 */
export { unsdgMap }
