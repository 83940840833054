import React, { useMemo } from 'react'
import Html from 'react-pdf-html'
import MainColumnSection from './MainColumnSection'

type Props = {
  projectDescriptionHtml: string | null
  styles: any
}

const FullDescriptionSection = ({ projectDescriptionHtml, styles }: Props) => {
  const htmlText = {
    p: {
      fontFamily: 'Inter',
      fontSize: 7,
      margin: 0,
      paddingHorizontal: 0,
      paddingVertical: 4,
      color: 'black',
    },
    h1: {
      fontFamily: 'Inter',
      fontSize: 8,
      marginTop: 3,
      marginBottom: 0,
      padding: 0,
      color: 'black',
    },
    h2: {
      fontFamily: 'Inter',
      fontSize: 8,
      marginTop: 3,
      marginBottom: 0,
      padding: 0,
      color: 'black',
    },
    h3: {
      fontFamily: 'Inter',
      fontSize: 8,
      marginTop: 3,
      marginBottom: 0,
      padding: 0,
      color: 'black',
    },
    h4: {
      fontFamily: 'Inter',
      fontSize: 8,
      marginTop: 3,
      marginBottom: 0,
      padding: 0,
      color: 'black',
    },
    h5: {
      fontFamily: 'Inter',
      fontSize: 8,
      marginTop: 3,
      marginBottom: 0,
      padding: 0,
      color: 'black',
    },
    h6: {
      fontFamily: 'Inter',
      fontSize: 8,
      marginTop: 3,
      marginBottom: 0,
      padding: 0,
      color: 'black',
    },
    ul: {
      fontFamily: 'Inter',
      fontSize: 7,
      margin: '0',
      paddingHorizontal: 0,
      paddingTop: 5,
      paddingBottom: 5,
      color: 'black',
    },
    ol: {
      fontFamily: 'Inter',
      fontSize: 7,
      margin: '0',
      paddingHorizontal: 0,
      paddingTop: 5,
      paddingBottom: 5,
      color: 'black',
    },
    li: {
      fontFamily: 'Inter',
      fontSize: 7,
      marginLeft: -15,
      marginTop: 1,
      marginBottom: 2,
      padding: '0',
      color: 'black',
    },
    a: {
      fontSize: 7,
      margin: '0',
      padding: '0',
      color: '#007A70',
    },
  }

  /**
   * @type {React.JSX.Element}
   */
  const htmlTextBlock = useMemo(
    () => <Html stylesheet={htmlText}>{projectDescriptionHtml as string}</Html>,
    [projectDescriptionHtml]
  )

  if (!projectDescriptionHtml) return null

  return (
    <MainColumnSection
      title="Full Description"
      verticalLayout={true}
      styles={styles}
    >
      {htmlTextBlock}
    </MainColumnSection>
  )
}

export default FullDescriptionSection
