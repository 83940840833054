import React from 'react'
import './ProjectCard.scss'

const ProjectCard = ({ project }) => {
  return (
    <div className="sustainability-report-project-card">
      <h4>{project?.name}</h4>
      <span>
        {project?.city}, {project?.country}
      </span>
      <a href={project?.project_url} target="_blank" rel="noopener noreferrer">
        Learn More
      </a>
    </div>
  )
}

export default ProjectCard
