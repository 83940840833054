import React from 'react'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { useNavbarContext } from 'model/contexts/NavbarContext'
import NavBarLogo from './navbar-logo/NavBarLogo'
import SelectAccountModal from './navbar-modal/SelectAccountModal'
import ExpandedOptions from './navbar-user-options/ExpandedOptions'
import NavbarUserOptions from './navbar-user-options/NavbarUserOptions'
import NavMenu from './NavMenu'
import './NavBar.scss'

const NavBar = () => {
  const {
    menuOpen,
    showExpandedOptionsWhenNavCollapsed,
    toggleExpandedOptionsWhenNavCollapsed,
    toggleNavbar,
  } = useNavbarContext()

  return (
    <>
      <SelectAccountModal />
      <section className={`${'nav-bar'}${menuOpen ? ` ${'open'}` : ''}`}>
        <NavBarLogo menuOpen={menuOpen} />
        <section className="flex flex-col justify-between h-full">
          <NavMenu />
          <section className="flex flex-col gap-3 mb-5">
            <NavbarUserOptions />
            <button
              className="text-muted p-[6px] bg-transparent h-fit hover:bg-neutral-95 rounded-lg w-fit ml-auto mr-1.5"
              onClick={toggleNavbar}
            >
              {menuOpen ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <KeyboardDoubleArrowRightIcon />
              )}
            </button>
          </section>
        </section>
      </section>
      {showExpandedOptionsWhenNavCollapsed && !menuOpen && (
        <ExpandedOptions toggleShow={toggleExpandedOptionsWhenNavCollapsed} />
      )}
    </>
  )
}

export default NavBar
