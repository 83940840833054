import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useUser } from 'model/hooks/account/useUser'
import { setPageTitle } from 'model/utils/page'
import { useNavigate } from 'react-router-dom'
import IdCardIcon from 'view/components/icons/id-card-icon/IdCardIcon'
import '../../Profile.scss'
import AddAccountModal from '../modal/AddAccountModal'
import ResetPasswordModal from '../modal/ResetPasswordModal'
import AccountTable from '../tables/AccountTable'

const ProfilePage = () => {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
  const [showAddAccountModal, setShowAddAccountModal] = useState(false)
  const { user } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    setPageTitle('Profile')
  }, [])

  return (
    <>
      <ResetPasswordModal
        showResetPasswordModal={showResetPasswordModal}
        setShowResetPasswordModal={bool => setShowResetPasswordModal(bool)}
      />
      <AddAccountModal
        showAddAccountModal={showAddAccountModal}
        setShowAddAccountModal={bool => setShowAddAccountModal(bool)}
      />
      <div className="profile-page">
        <div className="page-header">
          <button className="back-button" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
            <span>Back</span>
          </button>
          <h1>Profile</h1>
        </div>
        <div className="vertical-container">
          <div className="panel-profile">
            <h2>Personal Info</h2>
            <div className="icon-info-container">
              <IdCardIcon />
              <div className="info-box">
                <div className="info-text">
                  Name: {user.first_name} {user.last_name}
                </div>
                <div className="info-text">Email: {user.email}</div>
                <button onClick={() => setShowResetPasswordModal(true)}>
                  <span>Reset Password</span>
                </button>
              </div>
            </div>
          </div>
          <div className="section-header-with-button">
            <h2>Accounts</h2>
            <button onClick={() => setShowAddAccountModal(true)}>
              <AddIcon />
              <span>Add New Account</span>
            </button>
          </div>
          <AccountTable accounts={user.accounts} />
        </div>
      </div>
    </>
  )
}

export default ProfilePage
