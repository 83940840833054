import React from 'react'

const ProjectsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.4818 1.66666H3.51884C2.4957 1.66666 1.66699 2.49536 1.66699 3.51851V16.4815C1.66699 17.5046 2.4957 18.3333 3.51884 18.3333H16.4818C17.505 18.3333 18.3337 17.5046 18.3337 16.4815V3.51851C18.3337 2.49536 17.505 1.66666 16.4818 1.66666ZM7.22255 14.6296H5.3707V8.14814H7.22255V14.6296ZM10.9263 14.6296H9.0744V5.37036H10.9263V14.6296ZM14.63 14.6296H12.7781V10.9259H14.63V14.6296Z"
        fill="#657A80"
      />
    </svg>
  )
}

export default ProjectsIcon
