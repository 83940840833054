import React from 'react'
import './InfoIcon.scss'

const InfoIcon = () => {
  return (
    <svg
      className="info-icon"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={true}>
      <path d="M6.80038 10.834H8.20038V6.83398H6.80038V10.834ZM7.50038 5.48358C7.68918 5.48358 7.85318 5.41425 7.99238 5.27558C8.13105 5.13638 8.20038 4.97238 8.20038 4.78358C8.20038 4.59478 8.13105 4.43105 7.99238 4.29238C7.85318 4.15318 7.68918 4.08358 7.50038 4.08358C7.31158 4.08358 7.14758 4.15318 7.00838 4.29238C6.86972 4.43105 6.80038 4.59478 6.80038 4.78358C6.80038 4.97238 6.86972 5.13638 7.00838 5.27558C7.14758 5.41425 7.31158 5.48358 7.50038 5.48358ZM7.50038 14.1668C6.57825 14.1668 5.71158 13.9919 4.90038 13.642C4.08918 13.2921 3.38358 12.8172 2.78358 12.2172C2.18358 11.6172 1.70865 10.9116 1.35878 10.1004C1.00892 9.28918 0.833984 8.42252 0.833984 7.50038C0.833984 6.57825 1.00892 5.71158 1.35878 4.90038C1.70865 4.08918 2.18358 3.38358 2.78358 2.78358C3.38358 2.18358 4.08918 1.70865 4.90038 1.35878C5.71158 1.00892 6.57825 0.833984 7.50038 0.833984C8.42252 0.833984 9.28918 1.00892 10.1004 1.35878C10.9116 1.70865 11.6172 2.18358 12.2172 2.78358C12.8172 3.38358 13.2921 4.08918 13.642 4.90038C13.9919 5.71158 14.1668 6.57825 14.1668 7.50038C14.1668 8.42252 13.9919 9.28918 13.642 10.1004C13.2921 10.9116 12.8172 11.6172 12.2172 12.2172C11.6172 12.8172 10.9116 13.2921 10.1004 13.642C9.28918 13.9919 8.42252 14.1668 7.50038 14.1668ZM7.50038 12.7668C8.95585 12.7668 10.1975 12.2529 11.2252 11.2252C12.2529 10.1975 12.7668 8.95585 12.7668 7.50038C12.7668 6.04492 12.2529 4.80332 11.2252 3.77558C10.1975 2.74785 8.95585 2.23398 7.50038 2.23398C6.04492 2.23398 4.80332 2.74785 3.77558 3.77558C2.74785 4.80332 2.23398 6.04492 2.23398 7.50038C2.23398 8.95585 2.74785 10.1975 3.77558 11.2252C4.80332 12.2529 6.04492 12.7668 7.50038 12.7668Z" />
    </svg>
  )
}

export default InfoIcon
