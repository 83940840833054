import React, { useMemo, useState } from 'react'
import { debounce } from '@mui/material'
import { FormControl } from 'react-bootstrap'
import CancelIcon from '../icons/cancel-icon/CancelIcon'
import SearchIcon from '../icons/search-icon/SearchIcon'
import './TableSearch.scss'

const TableSearch = ({ onSearchChange, inputName }) => {
  const [searchText, setSearchText] = useState('')
  const debounceSearch = useMemo(
    () => debounce(onSearchChange, 350),
    // eslint-disable-next-line
    []
  )

  const onChange = newValue => {
    setSearchText(newValue)
    debounceSearch(newValue)
  }

  return (
    <div className="table-search">
      <div className="search-container">
        <FormControl
          id="search"
          name={inputName}
          type="text"
          value={searchText}
          onChange={e => onChange(e.target.value)}
        />
        <SearchIcon fill="#6c757d" />
        <div
          onClick={() => onChange('')}
          className={
            'cancel-button-container ' + (searchText.length > 0 ? 'show' : '')
          }>
          <CancelIcon fill="#CED4DA" />
        </div>
      </div>
    </div>
  )
}

export default TableSearch
