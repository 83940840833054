import React from 'react'
import { Project } from 'interfaces/Project.interface'
import ProjectTrancheItem from './project-tranche/ProjectTrancheItem'

type Props = {
  project: Project
}

const ProjectTranchSection = ({ project }: Props) => {
  const list = project.tranches

  return (
    <section className="w-[50%] flex flex-col gap-2">
      {list?.map((tranche, idx) => {
        const forwardCredit = !tranche.is_issued
        const shouldHaveBorder = project.tranches?.length !== idx + 1
        const border = `${shouldHaveBorder ? 'border-b border-b-neutral-80' : ''}`
        return (
          <ProjectTrancheItem
            key={tranche.id}
            border={border}
            forwardCredit={forwardCredit}
            project={project}
            tranche={tranche}
          />
        )
      })}
    </section>
  )
}

export default ProjectTranchSection
