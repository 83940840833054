import React from 'react'
import GraphIcon from 'view/components/icons/graph-icon/GraphIcon'
import './NoDataAvailablePanel.scss'

const NoDataAvailablePanel = () => (
  <div className="no-data-available">
    <div className="circle">
      <GraphIcon />
    </div>
    No data available
  </div>
)

export default NoDataAvailablePanel
