import React, { useEffect, useState } from 'react'
import { directCheckoutApiDocUrl } from 'model/utils/direct-checkout'
import { Col, Form } from 'react-bootstrap'
import OptionPreview from 'view/pages/checkout-admin/components/pages/checkout-create-page/payment-options/option-preview/OptionPreview'
import OptionSelect from 'view/pages/checkout-admin/components/pages/checkout-create-page/payment-options/option-select/OptionSelect'
import './PaymentOptions.scss'
import UnitSelector from 'view/pages/checkout-admin/components/pages/checkout-create-page/payment-options/unit-selector/UnitSelector'
import { usePaymentContext } from 'view/pages/checkout-admin/contexts/CheckoutContext'
import 'view/pages/checkout-admin/Checkout.scss'

const PaymentOptions = () => {
  const {
    watch,
    register,
    errors,
    setValue,
    getValues,
    recheckErrors,
    preventFormSubmit,
    submitAttempted,
    projects,
  } = usePaymentContext()

  const [unitType, setUnitType] = useState(null)
  const [unitValue, setUnitValue] = useState(null)
  const [amountValue, setAmountValue] = useState(null)
  const [paymentOption, setPaymentOption] = useState(null)
  const [previewProject, setPreviewProject] = useState(null)
  const [previousPresetOption, setPreviousPresetOption] = useState('DYNAMIC')

  const isBuyerChoiceOption = currentOption => ['ANY'].includes(currentOption)
  const isPresetOption = currentOption =>
    ['FIXED', 'DYNAMIC', 'pendingSelection'].includes(currentOption)

  const optionClicked = newValue => {
    setValue('amount_type', newValue, { shouldDirty: true })
    setPaymentOption(newValue)
    if (isPresetOption(newValue)) setPreviousPresetOption(newValue)
    if (submitAttempted) recheckErrors()
  }

  const updateFormStateValues = values => {
    setPaymentOption(values.amount_type)
    setUnitType(values.unit_type)
    setUnitValue(values.unit_value)
    setAmountValue(values.amount_value)
  }

  useEffect(() => {
    updateFormStateValues(getValues())
  }, [getValues])

  useEffect(() => {
    const subscription = watch(value => {
      updateFormStateValues(value)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (projects && projects.length > 0) {
      setPreviewProject(projects[0])
    }
  }, [projects])

  const buyerChoiceOption = () => (
    <>
      <div
        className={
          'selection-container ' +
          (isBuyerChoiceOption(paymentOption) ? 'selected' : '') +
          (errors?.purchase_option ? 'payment-options-required' : '')
        }
      >
        <div className="container-header">
          <OptionSelect
            valueName="ANY"
            isSelected={isBuyerChoiceOption(paymentOption)}
            optionClicked={optionClicked}
          />
          <div className="title">Buyer choice</div>
          <div className="desc">Buyers can decide how much to purchase</div>
        </div>
        <div
          className={
            'container-body ' +
            (!isBuyerChoiceOption(paymentOption) ? 'hidden' : '')
          }
        >
          <div className="title heavy">Allow the user to shop by:</div>
          <UnitSelector />
        </div>
      </div>
    </>
  )

  const presetOption = () => (
    <>
      <div
        className={
          'selection-container ' +
          (isPresetOption(paymentOption) ? 'selected' : '') +
          (errors?.purchase_option ? 'payment-options-required' : '')
        }
      >
        <div className="container-header">
          <OptionSelect
            valueName={previousPresetOption}
            isSelected={isPresetOption(paymentOption)}
            optionClicked={optionClicked}
          />
          <div className="title">Pre-set value</div>
          <div className="desc">Buyers can only purchase a pre-set amount</div>
        </div>
        <div
          className={
            'container-body ' + (!isPresetOption(paymentOption) ? 'hidden' : '')
          }
        >
          <div className="title heavy">Set the value</div>
          <div className="sub-selection-section">
            <div
              className={
                'sub-selection-container ' +
                (paymentOption === 'DYNAMIC' ? 'selected' : '')
              }
            >
              <OptionSelect
                valueName="DYNAMIC"
                isSelected={paymentOption === 'DYNAMIC'}
                optionClicked={optionClicked}
                size="small"
              />
              <div className="title">Dynamic value</div>
              <div className="section-caption">
                Set the value using{' '}
                <a
                  href={directCheckoutApiDocUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  query parameters
                </a>
              </div>
            </div>
            <div
              className={
                'sub-selection-container ' +
                (paymentOption === 'FIXED' ? 'selected' : '')
              }
            >
              <OptionSelect
                valueName="FIXED"
                isSelected={paymentOption === 'FIXED'}
                optionClicked={optionClicked}
                size="small"
              />
              <div className="title">Fixed value</div>
              <div className="section-caption">
                Set a fixed value for all buyers
              </div>
              <div
                className={
                  'extended-content ' +
                  (paymentOption !== 'FIXED' ? 'hidden' : '')
                }
              >
                <Form.Group as={Col} controlId="validationName">
                  <Form.Control
                    placeholder="Enter value..."
                    step="0.01"
                    className="unit-value-field"
                    type="number"
                    isInvalid={errors?.amount_value}
                    {...register('amount_value')}
                    onKeyDown={e => preventFormSubmit(e)}
                  />
                  {errors?.amount_value && (
                    <p className="invalid-message">
                      {errors?.amount_value.message}{' '}
                    </p>
                  )}
                </Form.Group>
                <div className="title units">Units</div>
                <UnitSelector />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <div className="payment-options">
      <div className="content-section">
        <div className="main-content">
          <h3 className="section-title">Purchase options</h3>
          <p className="section-intro">
            Set the behavior for your Direct Checkout page. Determine whether
            you'd like your users to choose their own purchase amount or if
            you’d like it pre-filled.
          </p>
          <div className="selection-section">
            {buyerChoiceOption()}
            {presetOption()}
          </div>
          {errors?.purchase_option && (
            <p className="invalid-message">
              {errors?.purchase_option.message}{' '}
            </p>
          )}
        </div>
        <OptionPreview
          selectedOption={paymentOption}
          project={previewProject}
          unit_type={unitType}
          unit_value={unitValue}
          amount={amountValue}
        />
      </div>
    </div>
  )
}

export default PaymentOptions
