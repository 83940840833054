import React, { useEffect, useState } from 'react'
import { useNavbarContext } from 'model/contexts/NavbarContext'
import { useUser } from 'model/hooks/account/useUser'
import NavMenuItem from './NavMenuItem'
import ActivityIcon from '../icons/navbar-icons/ActivityIcon'
import DashboardIcon from '../icons/navbar-icons/DashboardIcon'
import DevToolsIcon from '../icons/navbar-icons/DevToolsIcon'
import DirectCheckoutIcon from '../icons/navbar-icons/DirectCheckoutIcon'
import ProjectsIcon from '../icons/navbar-icons/ProjectsIcon'
import PurchaseIcon from '../icons/navbar-icons/PurchaseIcon'
import SettingsIcon from '../icons/navbar-icons/SettingsIcon'
import ShopifyIcon from '../icons/navbar-icons/ShopifyIcon'

const NavMenu = () => {
  const { account } = useUser()

  const { menuOpen, handleSelectRoute } = useNavbarContext()

  // account state
  const [acctState, setAcctState] = useState({
    showPrivateInventory: false,
    showDeveloperTools: false,
    isChild: false,
    shopifyEnabled: false,
    showPoweredByLogo: false,
  })

  useEffect(() => {
    setAcctState({
      showPrivateInventory: !!account?.show_private_inventory,
      showDeveloperTools: !!account?.show_developer_tools,
      isChild: !!account?.is_child,
      shopifyEnabled: account?.ecommerce_type === 'Shopify',
      showPoweredByLogo:
        account?.is_cobranding_enabled && account?.cobranding_image,
    })
  }, [account])

  const menuItems = [
    {
      label: 'Dashboard',
      path: '',
      icon: <DashboardIcon />,
      show: true,
    },
    {
      label: 'Purchase',
      path: 'marketplace',
      icon: <PurchaseIcon />,
      show: !acctState.shopifyEnabled,
    },
    {
      label: 'Activity',
      path: 'transactions',
      icon: <ActivityIcon />,
      show: true,
    },
    {
      label: 'Developer tools',
      path: 'developer-tools',
      icon: <DevToolsIcon />,
      show: !acctState.shopifyEnabled && acctState.showDeveloperTools,
    },
    {
      label: 'Settings',
      path: 'account-settings',
      icon: <SettingsIcon />,
      show: true,
    },
    {
      label: 'Direct checkout',
      path: 'direct-checkout',
      icon: <DirectCheckoutIcon />,
      show: !acctState.shopifyEnabled && !acctState.isChild,
    },
    {
      label: 'Shopify',
      path: 'shopify',
      icon: <ShopifyIcon />,
      show: acctState.shopifyEnabled,
    },
    {
      label: 'Project inventory',
      path: 'project-inventory',
      icon: <ProjectsIcon />,
    },
  ]

  return (
    <div className="nav-group-container">
      {menuItems.map((item, idx) => (
        <>
          {item.show ? (
            <>
              <NavMenuItem
                key={`${item.label} ${idx}`}
                id={item.path}
                onClick={handleSelectRoute}
                label={item.label}
              >
                {menuOpen ? (
                  <>
                    <span
                      style={{
                        marginRight: '8px',
                        display: 'flex',
                        pointerEvents: 'none',
                      }}
                    >
                      {item.icon}
                    </span>
                    {item.label}
                  </>
                ) : (
                  <span
                    style={{
                      pointerEvents: 'none',
                    }}
                  >
                    {item.icon}
                  </span>
                )}
              </NavMenuItem>
            </>
          ) : null}
        </>
      ))}
    </div>
  )
}

export default NavMenu
