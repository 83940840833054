import React, { ReactNode } from 'react'
import { CloverlyButton } from 'view/components/cloverly-button/CloverlyButton'

type Props = {
  email: string
  children: string | ReactNode
}

const EmailButton = ({ email, children }: Props) => {
  return (
    <a href={`mailto:${email}`} className="no-underline w-fit">
      <CloverlyButton size="small" state="neutral" variant="outlined">
        {children}
      </CloverlyButton>
    </a>
  )
}

export default EmailButton
