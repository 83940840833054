import React, { useEffect, useState } from 'react'
import { Project } from 'interfaces/Project.interface'
import { mapConfig } from 'model/utils/react-map'
import ReactMapGL, { Marker } from 'react-map-gl'
import PinIcon from 'view/components/icons/pin-icon/PinIcon'
import LocationIcon from 'view/components/icons/project/location-icon/LocationIcon'
import Spacer from 'view/components/spacer/Spacer'
import ProjectTag from './project-tags/ProjectTag'

type Props = {
  project: Project
}

const ProjectLocationPanel = ({ project }: Props) => {
  /**
   * @type {string}
   */
  const panelTitle = 'Location'

  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 3,
  })

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: Number(project?.location?.x),
      longitude: Number(project?.location?.y),
    })
    // eslint-disable-next-line
  }, [project?.location])

  return (
    !!project?.location?.x &&
    !!project?.location?.y && (
      <section className="project-panel map">
        <header>
          <h2>{panelTitle}</h2>
          <Spacer className="fill-width" />
          {project.region && (
            <ProjectTag
              icon={<LocationIcon />}
              label={project.region}
              className="bg-yellow-90"
            />
          )}
        </header>
        <div className="map-container">
          <label>
            {project.city ? `${project.city},` : ''} {project.country || ''}
          </label>
          <div className="map-box">
            <ReactMapGL
              {...viewport}
              maxZoom={6}
              minZoom={1.5}
              mapboxAccessToken={mapConfig.mapboxAccessToken}
              mapStyle={mapConfig.mapStyle}
              onMove={evt => setViewport(evt.viewState)}
              // onViewportChange={nextViewport => setViewport(nextViewport)}
            >
              <Marker
                latitude={Number(project.location?.x)}
                longitude={Number(project.location?.y)}
                offset={mapConfig.markerOffset}
              >
                <PinIcon />
              </Marker>
            </ReactMapGL>
          </div>
        </div>
      </section>
    )
  )
}

export default ProjectLocationPanel
