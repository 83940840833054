import React, { useEffect, useState } from 'react'
import { useService } from 'model/hooks/useService'
import { useTheme } from 'model/hooks/useTheme'
import { setStoredTargetPath } from 'model/utils/userStorage'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Logo from 'view/components/cloverly-logo/Logo'
import FormContainer from 'view/components/form-container/FormContainer'
import OptionalLink from 'view/components/form-container/optional-link/OptionalLink'
import RegistrationPageBg from 'view/components/vectors/RegistrationPageBg'
import RegistrationForm from './components/registration-form/RegistrationForm'
import './Register.scss'

const Register = () => {
  const search = useLocation().search
  const navigate = useNavigate()
  const { serviceRequest } = useService()
  const { slug } = useParams()
  const [coBrandingSetting, setCoBrandingSetting] = useState({
    is_cobranding_enabled: undefined,
    billing_method: undefined,
    child_billing_method: undefined,
    cobranding_primary_color: undefined,
    cobranding_image: undefined,
  })
  const { brandTextColor, setPrimaryStyle } = useTheme()

  /**
   * Initial load effect.
   */
  useEffect(() => {
    // save the given target path if given, or save null
    setStoredTargetPath(new URLSearchParams(search)?.get('view') || null)

    // if a slug is provided, then this is a parent registration page

    serviceRequest({
      path: `/account/${slug}?child=true`,
      method: 'GET',
    })
      .then(account => {
        setCoBrandingSetting({
          is_cobranding_enabled: account.is_cobranding_enabled,
          billing_method: account.billing_method,
          child_billing_method: account.child_billing_method,
          cobranding_primary_color: account.cobranding_primary_color,
          cobranding_image: account.cobranding_image,
          tos_href: account.tos_href,
          tos_display_text: account.tos_display_text,
        })
      })
      .catch(() => {
        // in the case where the account by slug can not be found, re-route users to the default register page
        navigate('/register')
      })
    // eslint-disable-next-line
  }, [])

  /**
   * When the co-branding settings change, update the UI and set page loaded to true.
   */
  useEffect(() => {
    if (isBrandedDesign()) {
      updateBrandColor(coBrandingSetting.cobranding_primary_color)
    }
    // eslint-disable-next-line
  }, [coBrandingSetting])

  // co-branding functions
  const isBrandedDesign = () => coBrandingSetting.is_cobranding_enabled
  const updateBrandColor = newColor => {
    setPrimaryStyle('--brand-primary', newColor)
    setPrimaryStyle('--link-primary', brandTextColor(newColor))
  }

  return (
    <>
      <div className={'register ' + (!isBrandedDesign() ? 'unbranded' : '')}>
        <div className="left-container">
          <RegistrationPageBg />
          <div className="left-content">
            {isBrandedDesign() && coBrandingSetting?.cobranding_image && (
              <img
                className="co-brand-image"
                src={`data:image/jpeg;base64,${coBrandingSetting?.cobranding_image}`}
              />
            )}
            {!isBrandedDesign() && (
              <a
                className="default-logo"
                target="_blank"
                href="https://www.cloverly.com/"
                rel="noreferrer"
              >
                <Logo />
              </a>
            )}
            <p className="header">Start offsetting carbon emissions today.</p>
            <p className="message">
              Sign up to take climate action and purchase high-quality carbon
              credits from across the world.
            </p>
            {isBrandedDesign() && (
              <div className="powered-by-cloverly">
                <p>Powered by</p>
                <Logo />
              </div>
            )}
          </div>
        </div>
        <FormContainer title={'Create your account'}>
          <OptionalLink
            title={'Already have an account?'}
            linkTitle={'Sign in'}
            to={'/login'}
          />
          <RegistrationForm slug={slug} coBrandingSetting={coBrandingSetting} />
        </FormContainer>
        <div className="branded-top-bar"></div>
      </div>
    </>
  )
}

export default Register
