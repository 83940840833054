import React, { ForwardedRef, ReactNode, forwardRef } from 'react'
import './Button.scss'

type Props = {
  type?: any
  disabled?: boolean
  onClick?: any
  cn?: string
  children?: ReactNode
}

const Button = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const { type, disabled, onClick, cn, children } = props
  return (
    <button
      ref={ref}
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`cloverly-btn ${cn ? cn : ''}`}
    >
      {children}
    </button>
  )
})

export default Button
