import React, { useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DocumentProps } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { useService } from 'model/hooks/useService'
import {
  determineCurrencyRate,
  handleCalculateCostPerTonne,
} from 'model/utils/cost'
import { vintage_credit_min_in_tonnes } from 'model/utils/marketplace/marketplace-constants'
import { getWeightUnit } from 'model/utils/units'
import { useParams } from 'react-router-dom'
import ProjectDetailsPDF from 'view/components/pdf-components/ProjectDetailsPDF'
import { useMarketplaceContext } from 'view/pages/marketplace/MarketplaceContext'

export const useProjectDetails = () => {
  const {
    account,
    projectEstimate: estimate,
    setProject,
    setRefetchProjects,
    project,
    weightType,
    amount,
    setAmount,
    shopBy,
    currencyRates,
    selectedVintage,
    defaultCurrency,
    isEstimationLoading,
  } = useMarketplaceContext()

  const { serviceRequest } = useService()

  // small width style
  const smallWidth = useMediaQuery('(max-width:1010px)')
  const { id: projectId } = useParams()
  const isMounted = useRef(false)
  const [pdf, setPdf] = useState<React.ReactElement<DocumentProps>>(<></>)
  const [projectDescriptionHtml, setProjectDescriptionHtml] = useState<
    string | null
  >(null)
  const [shopByCurrency, setShopByCurrency] = useState(false)
  const [formattedCostPerTonne, setFormattedCostPerTonne] = useState('')
  const [formattedCarbonAmount, setFormattedCarbonAmount] = useState('')
  const [showLeadGenRequestDetailsModal, setShowLeadGenRequestDetailsModal] =
    useState(false)

  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: '',
  })

  const [projectEstimate, setProjectEstimate] = useState({
    total_weight: 0,
    total_cost: 0,
    currency_type: defaultCurrency,
    weight_type: weightType,
    vintage_year: '',
    is_issued: false,
    cost_per_kg_carbon_in_usd_cents: 0,
  })

  type PDFTypes = {
    project: Project
    defaultCurrency: string
    currencyRates: object
    projectEstimate: object
    isEstimationLoading: boolean
    projectDescriptionHtml: string | null
  }

  const onRerenderPDF = ({
    project,
    defaultCurrency,
    currencyRates,
    projectEstimate,
    isEstimationLoading,
    projectDescriptionHtml,
  }: PDFTypes) => {
    if (project && defaultCurrency && currencyRates && !isEstimationLoading)
      setPdf(
        <ProjectDetailsPDF
          project={project}
          projectDescriptionHtml={projectDescriptionHtml}
          defaultCurrency={defaultCurrency}
          currencyRates={currencyRates}
          estimate={projectEstimate}
        />
      )
  }

  /**
   * @type {onRerenderPDF}
   */
  const debounceRenderPDF = useMemo(
    () => debounce(onRerenderPDF, 150),
    // eslint-disable-next-line
    []
  )

  // show lead gen notify modal
  const leadGenNotify = (e: any) => {
    setShowLeadGenRequestDetailsModal(true)
    e.preventDefault()
    e.target.blur()
  }

  //get project details
  useEffect(() => {
    setProject(null)
    serviceRequest({
      path: `/project/${projectId}?vintage_credit_min_in_tonnes=${vintage_credit_min_in_tonnes}`,
      method: 'GET',
    })
      .then((project: Project) => {
        document.title = `${
          project?.short_name || project?.name
        } | Project Details | Cloverly`
        setProject(project)
      })
      .catch((e: any) => console.log(e))
  }, [])

  //update projects list when account changes
  useEffect(() => {
    if (isMounted.current) {
      setRefetchProjects(true)
    } else {
      isMounted.current = true
    }
    // eslint-disable-next-line
  }, [account])

  //mirror the estimate from the marketplace
  useEffect(() => {
    if (Number(estimate?.total_cost) !== 0) {
      setProjectEstimate({
        ...estimate,
        weight_type: weightType,
        vintage_year: selectedVintage?.vintage_year,
        is_issued: selectedVintage?.is_issued,
        cost_per_kg_carbon_in_usd_cents:
          selectedVintage?.cost_per_kg_carbon_in_usd_cents,
      })
    }
  }, [estimate, account, weightType, selectedVintage])

  useEffect(() => {
    if (project && defaultCurrency && currencyRates)
      debounceRenderPDF({
        project,
        defaultCurrency,
        currencyRates,
        projectEstimate,
        isEstimationLoading,
        projectDescriptionHtml,
      })
  }, [
    project,
    defaultCurrency,
    currencyRates,
    projectEstimate,
    isEstimationLoading,
    projectDescriptionHtml,
  ])

  useEffect(() => {
    const shopByCurrency = shopBy === 'currency'
    setShopByCurrency(shopByCurrency)
    const costInDollars =
      (selectedVintage?.cost_per_kg_carbon_in_usd_cents ||
        project?.cost_per_kg_carbon_in_usd_cents) *
      0.01 *
      1000
    setFormattedCostPerTonne(
      handleCalculateCostPerTonne(
        costInDollars,
        defaultCurrency,
        currencyRates
      ) + ' / t CO₂e'
    )
    const weightUnit = getWeightUnit({
      weightType: weightType === 'tonnes' ? 't' : weightType,
      value: projectEstimate.total_weight,
      roundUnits: 0,
      format: '%v %u',
      isMT:
        projectEstimate?.weight_type === 'kg' ||
        projectEstimate?.weight_type === 'tonnes',
    })
    setFormattedCarbonAmount(weightUnit as string)
  }, [
    shopBy,
    project?.cost_per_kg_carbon_in_usd_cents,
    projectEstimate?.weight_type,
    projectEstimate?.total_cost,
    projectEstimate.total_weight,
    selectedVintage?.cost_per_kg_carbon_in_usd_cents,
  ])

  //handle estimate calculation
  useEffect(() => {
    let calculatedEstimate
    if (shopByCurrency) {
      if (amount !== 0 && amount !== '') {
        const calculatedInputDollarAmount =
          amount /
          ((selectedVintage?.cost_per_kg_carbon_in_usd_cents ||
            project?.cost_per_kg_carbon_in_usd_cents) *
            0.01)
        const calculatedWeight =
          Math.round(
            (calculatedInputDollarAmount /
              determineCurrencyRate(defaultCurrency, currencyRates)) *
              100
          ) / 100
        calculatedEstimate = {
          //determineCurrencyRate divides the total amount by USD, CAD, or EUR rates
          total_weight: calculatedWeight,
          total_cost: amount,
          currency_type: defaultCurrency,
          weight_type: weightType,
          vintage_year: selectedVintage?.vintage_year,
          is_issued: selectedVintage?.is_issued,
          cost_per_kg_carbon_in_usd_cents:
            selectedVintage?.cost_per_kg_carbon_in_usd_cents,
        }
      } else {
        //make sure result is 0 on the UI if input is empty
        calculatedEstimate = {
          ...projectEstimate,
          total_weight: 0,
          total_cost: 0,
        }
        setAmount('')
      }
      setProjectEstimate(calculatedEstimate)
    } else {
      let tempWeight = amount
      if (weightType === 'tonnes') {
        tempWeight = amount * 1000
      } else if (weightType === 'lbs') {
        tempWeight = amount / 2.20462
      }
      if (amount !== 0 && amount !== '') {
        const carbonCost =
          Math.ceil(
            tempWeight *
              (selectedVintage?.cost_per_kg_carbon_in_usd_cents ||
                project?.cost_per_kg_carbon_in_usd_cents)
          ) / 100
        calculatedEstimate = {
          total_weight: tempWeight,
          total_cost: carbonCost,
          currency_type: defaultCurrency,
          weight_type: weightType,
          vintage_year: selectedVintage?.vintage_year,
          is_issued: selectedVintage?.is_issued,
          cost_per_kg_carbon_in_usd_cents:
            selectedVintage?.cost_per_kg_carbon_in_usd_cents,
        }
      } else {
        //make sure result is 0 on the UI if input is empty
        calculatedEstimate = {
          ...projectEstimate,
          total_cost: 0,
          total_weight: 0,
        }
      }
      setProjectEstimate(calculatedEstimate)
    }
    // eslint-disable-next-line
  }, [defaultCurrency, amount, weightType, project?.id, account])

  return {
    smallWidth,
    pdf,
    projectDescriptionHtml,
    setProjectDescriptionHtml,
    shopByCurrency,
    imageModal,
    setImageModal,
    formattedCostPerTonne,
    formattedCarbonAmount,
    showLeadGenRequestDetailsModal,
    setShowLeadGenRequestDetailsModal,
    projectEstimate,
    leadGenNotify,
  }
}
