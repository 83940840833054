import React from 'react'
import { Project, ProjectTranche } from 'interfaces/Project.interface'
import { useUser } from 'model/hooks/account/useUser'
import { useCurrencyExchangeRates } from 'model/hooks/useCurrencyExchangeRates'
import { handleCalculateCostPerTonne } from 'model/utils/cost'

type Props = {
  tranche: ProjectTranche
  forwardCredit: boolean
  border: string
  project: Project
}

const ProjectTrancheItem = ({
  tranche,
  forwardCredit,
  border,
  project,
}: Props) => {
  const { account } = useUser()
  const weight = project.purchase_split_percentage.toFixed(0)
  const { currencyRates } = useCurrencyExchangeRates({ showLoading: false })

  const costInKG = tranche.cost_per_kg_carbon_in_usd_cents / 100
  const costInInTonne = costInKG * 1000

  return (
    <section
      className={`flex gap-3 pb-1.5 justify-between ${border} max-[1280px]:flex-wrap`}
      key={tranche.id}
    >
      <div className="flex flex-col gap-1 w-[120px]">
        <p className="text-sm text-neutral-50 font-semibold leading-4">
          Vintage
        </p>
        <div className="flex items-center gap-1">
          <p>{tranche.vintage_year || 'N/A'}</p>
          {forwardCredit && (
            <div className="py-[2px] px-[6px] w-fit bg-blue-95 text-blue-50 rounded-[3px] text-sm leading-4 font-medium">
              Forward
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-1 min-w-[90px]">
        <p className="text-sm text-neutral-50 font-semibold leading-4">Price</p>
        <p>
          {handleCalculateCostPerTonne(
            costInInTonne,
            account?.default_currency,
            currencyRates
          )}
        </p>
      </div>
      <div className="flex flex-col gap-1 w-[60px]">
        <p className="text-sm text-neutral-50 font-semibold leading-4">
          Weight
        </p>
        <p>{weight}%</p>
      </div>
    </section>
  )
}

export default ProjectTrancheItem
