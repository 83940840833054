import React from 'react'

const AirplaneIcon = () => (
  <svg
    fill="none"
    height="59"
    viewBox="0 0 60 59"
    width="60"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m17.5999 39.11-14.19-4.81-2.26 2.58 11.81 7.28z"
        fill="#00cc8a"
      />
      <path
        d="m13.09 45.12-13.09-8.06 3.18-3.63 15.76 5.34zm-10.79-8.41 10.53 6.49 3.45-3.75-12.63-4.28z"
        fill="#004453"
      />
      <path
        d="m19.6599 41.16 4.81 14.19-2.59 2.27-7.27-11.81z"
        fill="#00cc8a"
      />
      <g fill="#004453">
        <path d="m21.7099 58.77-8.07-13.09 6.36-5.85 5.34 15.76zm-6.14-12.83 6.49 10.53 1.54-1.35-4.28-12.62z" />
        <path d="m21.3601 13.59c-.4 0-.78-.16-1.06-.44l-2.3-2.3c-.28-.28-.44-.66-.44-1.06s.16-.78.44-1.06l4.24-4.24c.57-.57 1.56-.57 2.12 0l2.3 2.3c.28.28.44.66.44 1.06s-.16.78-.44 1.06l-4.24 4.24c-.28.28-.66.44-1.06.44zm-2.3-3.8 2.3 2.3 4.24-4.24-2.3-2.3zm-.53-.53.53.53z" />
        <path d="m46.78 31.74c-.45 0-.9-.17-1.24-.51l-1.77-1.77c-.68-.68-.68-1.79 0-2.47l3.89-3.89c.33-.33.77-.51 1.24-.51s.91.18 1.24.51l1.77 1.77c.68.68.68 1.79 0 2.47l-3.89 3.89c-.35.34-.79.51-1.24.51zm2.12-7.66c-.04 0-.11.01-.18.07l-3.89 3.89c-.1.1-.1.26 0 .35l1.77 1.77c.1.1.25.1.35 0l3.89-3.89c.1-.1.1-.26 0-.35l-1.77-1.77c-.06-.06-.17-.08-.17-.07z" />
        <path d="m30.8501 15.81c-.45 0-.9-.17-1.24-.51l-1.75-1.75c-.68-.68-.68-1.79 0-2.47l3.89-3.89001c.68-.68 1.79-.68 2.47 0l1.75 1.75c.68.68.68 1.79001 0 2.47001l-3.89 3.89c-.34.34-.79.51-1.23.51zm2.13-7.64001c-.06 0-.13.02-.18.07l-3.89 3.89001c-.1.1-.1.26 0 .35l1.75 1.75c.1.1.26.1.35 0l3.89-3.89c.1-.1.1-.26 0-.35001l-1.75-1.75c-.04-.05-.1-.07-.17-.07z" />
        <path d="m49.21 42.04c-.47 0-.91-.18-1.24-.51l-1.94-1.94c-.68-.68-.68-1.79 0-2.47l4.24-4.24c.68-.68 1.79-.68 2.47 0l1.94 1.95c.68.68.68 1.79 0 2.47l-4.24 4.24c-.32.32-.76.5-1.23.5zm2.3-8.19c-.06 0-.13.02-.18.07l-4.24 4.24c-.1.1-.1.26 0 .35l1.95 1.94c.07.06.18.05.18.07.04 0 .11-.01.18-.07l4.24-4.24c.06-.06.07-.14.07-.18s-.01-.11-.07-.18l-1.94-1.95c-.06-.02-.12-.05-.19-.05z" />
      </g>
      <path
        d="m39.1797 13.2s-3.01-.83-4.02-1.11c-7.43-2.04-29.69995-8.17002-29.69995-8.17002l-2.22 1.85 21.37995 13.76002 5.84 5.71z"
        fill="#fff"
      />
      <path
        d="m30.55 26.38-6.4-6.27-22.17002-14.27003 3.3-2.75 35.14002 9.67003zm-26.04002-20.69003 20.64002 13.30003 5.21 5.1 7.58-10.46-32.31002-8.88003z"
        fill="#004453"
      />
      <path
        d="m46.2099 20.23s.83 3.01 1.11 4.02c2.04 7.43 8.17 29.7 8.17 29.7l-1.84 2.21-13.77-21.37-5.71-5.84z"
        fill="#fff"
      />
      <path
        d="m53.56 57.43-14.27-22.18-6.27-6.4 13.62-9.87 9.67 35.14zm-18.25-28.38 5.2 5.33 13.21 20.52.94-1.12-8.88-32.3z"
        fill="#004453"
      />
      <path
        d="m16.2399 37.88c1.12-1.25 9.59-10.55 13.43-14.82 6.06-6.87 12.31-12.95 17.06-17.06004 4.88-4.23 9.74-6.339999 11.4-4.68 1.66 1.66-.54 6.85-4.51 11.23004-4.22 4.65-9.83 10.52-17.54 17.14-5.69 4.88-13.2 11.19-14.96 12.7s-11.03 9.53-12.44999 8.09c-1.63-1.64 5.89999-10.74 7.56999-12.6z"
        fill="#fff"
      />
      <path
        d="m9.10993 51.44c-.54 0-.78-.25-.97-.44-2.39-2.39 6.51997-12.47 7.54997-13.62.4-.45 1.76-1.95 3.51-3.88 3.11-3.42 7.46-8.21 9.91-10.95 5.49-6.22 11.74-12.46 17.12-17.12003 4.56-3.94 10.16-6.91 12.42-4.65 2.18 2.18-.57 7.95-4.48 12.26003-4.02 4.43-9.66 10.39-17.6 17.21-3.68 3.16-8.13 6.92-11.29 9.59-1.72 1.45-3.05 2.58-3.67 3.11l-.11.09c-8.1 6.97-11.1 8.4-12.38997 8.4zm.08-1.54c.44 0 2.62997-.5 11.33997-7.99l.11-.1c.62-.53 1.96-1.66 3.68-3.12 3.15-2.67 7.6-6.42 11.28-9.58 7.88-6.77 13.48-12.68 17.47-17.08 3.95-4.34003 5.68-9.05003 4.53-10.19003-1.1-1.1-5.38.39-10.38 4.72-5.34 4.62003-11.53 10.81003-16.99 16.99003-2.46 2.74-6.81 7.54-9.92 10.96-1.75 1.93-3.1 3.42-3.51 3.87-3.74 4.16-7.98997 10.32-7.60997 11.52z"
        fill="#004453"
      />
      <path
        d="m53.7699 54.91-11.37-29.8901 3.45-3.26 8.84 32.02"
        fill="#00cc8a"
      />
      <path
        d="m4.43994 5.58997 29.89996 11.37003 3.26-3.45-32.02996-8.85003"
        fill="#00cc8a"
      />
      <path
        d="m51.96 4.87997s1.66-.91 2.63.06.12 2.69.12 2.69-.66-2.17-2.75-2.75z"
        fill="#004453"
      />
    </g>
  </svg>
)

export default AirplaneIcon
