import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEstimateAPIRequest } from 'model/hooks/checkout/useEstimateAPIRequest'
import { useProject } from 'model/hooks/checkout/useProject'
import { useService } from 'model/hooks/useService'
import { useTheme } from 'model/hooks/useTheme'
import { useToast } from 'model/hooks/useToast'
import {
  handleIsNotEnoughInventory,
  handleIsUnderMinimum,
} from 'model/utils/direct-checkout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CloverlyFooter from 'view/components/cloverly-footer/CloverlyFooter'
import DetailedPortfolioPanel from 'view/pages/checkout-public/components/detailed-project-panel/DetailedPortfolioPanel'
import { handleFetchCheckoutData } from 'view/pages/checkout-public/util/FetchCheckoutData'
import CheckoutForm from './checkout-form/CheckoutForm'
import { useCheckoutQueryParamContext } from '../../contexts/CheckoutQueryParamContext'
import { useEstimateCalculation } from '../../contexts/useEstimateCalculation'
import DetailedProjectPanel from '../detailed-project-panel/DetailedProjectPanel'
import Header from '../header/Header'
import NoDataPage from '../no-data-page/NoDataPage'
import PurchaseDetails from '../purchase-details/PurchaseDetails'
import './StripeCheckout.scss'

const stripePromise =
  process.env.REACT_APP_ENV?.toLowerCase() === 'prod'
    ? loadStripe('pk_live_QqeMkpPqXxVTqcGjNrBBl7Ox')
    : loadStripe('pk_test_EJpEaEY7laiFIY1q0vjaDz0Y')

const StripeCheckout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const toastId = useRef()
  const notEnoughInvToast = useRef()
  const { checkoutSlug } = useParams()
  const { updateColor, updateImage, getPrimaryStyle } = useTheme()

  const { infoToast } = useToast()

  const {
    shopBy,
    weightUnit,
    amount,
    pageStatus,
    isPageActive,
    minPurchaseAmount,
    minimumPurchaseWarningToast,
    selectedProjectIsPortfolio,
    handleSetFieldValues,
    setProjectData,
    projectData,
    initialLoad,
    setInitialLoad,
  } = useCheckoutQueryParamContext()

  const { serviceRequest } = useService()
  const { projectId } = useParams()
  const { project } = useProject(
    projectId,
    localStorage.getItem('account_slug'),
    selectedProjectIsPortfolio
  )

  const [clientSecret, setClientSecret] = useState(null)
  const [stripeId, setStripeId] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isNotEnoughInventory, setIsNotEnoughInventory] = useState(false)

  const { estimate: estimateFromFE, costInUSD } = useEstimateCalculation()

  const determineIfNotEnoughInventory = useCallback(() => {
    return handleIsNotEnoughInventory(
      shopBy,
      weightUnit,
      estimateFromFE,
      project
    )
    // eslint-disable-next-line
  }, [
    estimateFromFE.total_weight,
    project?.available_carbon_in_kg,
    shopBy,
    weightUnit,
  ])

  const determineIfUnderMinimumAmount = useCallback(() => {
    if (costInUSD)
      return handleIsUnderMinimum(costInUSD, minPurchaseAmount / 100)
    else return null
  }, [costInUSD, minPurchaseAmount])

  const { estimateFromBE } = useEstimateAPIRequest(
    projectId,
    !!projectId && project && !determineIfNotEnoughInventory(),
    selectedProjectIsPortfolio
  )

  const termAndServices = 'https://www.cloverly.com/term-of-service'
  const privacyPolicy = 'https://www.cloverly.com/privacy-policy'

  const hasNoContent = !clientSecret || !project || !estimateFromBE

  const stripeOptions = { clientSecret }

  // fetch project data
  useEffect(() => {
    if (!projectData)
      handleFetchCheckoutData(
        checkoutSlug,
        serviceRequest,
        handleSetFieldValues,
        setProjectData,
        updateColor,
        updateImage,
        getPrimaryStyle,
        setInitialLoad
      )
    else setInitialLoad(true)
  }, [checkoutSlug, projectData])

  //route to stripe checkout or choose amount depending on isAmountFixed
  const handleRedirectToConfirmationPage = () => {
    let pathToConfirmation = `/purchase-confirmation/${project?.id}`
    const newUrl =
      window.location.pathname.replace(`/stripe-checkout/${project?.id}`, '') +
      pathToConfirmation
    navigate({
      pathname: newUrl,
      search: location.search + `&stripeId=${stripeId}`,
    })
  }

  const handleGetPaymentConfirmation = id => {
    const transaction_id = selectedProjectIsPortfolio
      ? estimateFromBE?.transactions[0]?.transaction_id
      : estimateFromBE?.transaction_id
    serviceRequest({
      path: `/checkout/confirm-payment-intent?intent_id=${id}&estimate_id=${transaction_id}`,
      method: 'POST',
    }).then(data => localStorage.setItem('receipt_url', data?.receipt_url))
  }

  //toggle notEnoughInventory toast notification
  useEffect(() => {
    if (determineIfNotEnoughInventory()) {
      setIsNotEnoughInventory(true)
      notEnoughInvToast.current = infoToast('Not enough inventory', false)
    } else {
      setIsNotEnoughInventory(false)
      if (toast.isActive(notEnoughInvToast.current))
        toast.dismiss(notEnoughInvToast.current)
    }
    // eslint-disable-next-line
  }, [determineIfNotEnoughInventory, determineIfUnderMinimumAmount, amount])

  //disable checkout btn and display a warning toast when amount is under $3
  useEffect(() => {
    if (!isNaN(costInUSD) && costInUSD > 0) {
      if (determineIfUnderMinimumAmount() && !toast.isActive(toastId.current)) {
        if (!toast.isActive(toastId.current))
          toastId.current = toastId.current = minimumPurchaseWarningToast(
            minPurchaseAmount / 100,
            'USD'
          )
      } else if (
        !determineIfUnderMinimumAmount() &&
        toast.isActive(toastId.current)
      )
        toast.dismiss(toastId.current)
    }
  }, [determineIfUnderMinimumAmount, costInUSD])

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const abortController = new AbortController()
    if (estimateFromBE && !clientSecret) {
      //console.log(estimateFromBE, currency)
      const transaction_id = selectedProjectIsPortfolio
        ? estimateFromBE?.transactions[0]?.transaction_id
        : estimateFromBE?.transaction_id

      serviceRequest({
        path: `/checkout/create-payment-intent?estimate_slug=${transaction_id}`,
        method: 'POST',
      }).then(data => {
        setClientSecret(data.clientSecret)
      })
    }
    return () => abortController.abort()
    // eslint-disable-next-line
  }, [estimateFromBE, clientSecret])

  useEffect(() => {
    if (isSuccess) handleRedirectToConfirmationPage()
    // eslint-disable-next-line
  }, [isSuccess])

  return (
    <>
      {initialLoad && (
        <>
          {!isPageActive(pageStatus) || isNotEnoughInventory ? (
            <NoDataPage />
          ) : (
            <div
              className={`stripe-checkout-page ${
                determineIfNotEnoughInventory() ? 'no-inventory' : ''
              } ${hasNoContent ? 'no-content' : ''}`}
            >
              <Header title="Checkout" />
              {clientSecret && project && estimateFromBE && (
                <>
                  <section className="project-details-and-stripe-form">
                    {selectedProjectIsPortfolio ? (
                      <DetailedPortfolioPanel project={project} />
                    ) : (
                      <DetailedProjectPanel project={project} />
                    )}

                    <section className="form-and-footer">
                      <section className="purchase-details-and-form">
                        <PurchaseDetails
                          project={project}
                          estimateFromBE={estimateFromBE}
                        />
                        <Elements
                          options={stripeOptions}
                          stripe={stripePromise}
                        >
                          <CheckoutForm
                            estimateFromBE={estimateFromBE}
                            clientSecret={clientSecret}
                            setStripeId={setStripeId}
                            setIsSuccess={setIsSuccess}
                            handleGetPaymentConfirmation={
                              handleGetPaymentConfirmation
                            }
                            isNotEnoughInventory={isNotEnoughInventory}
                            determineIfUnderMinimumAmount={
                              determineIfUnderMinimumAmount
                            }
                          />
                        </Elements>
                      </section>
                      <section className="stripe-footer">
                        By purchasing, you agree to the Cloverly’s{' '}
                        <a
                          href={termAndServices}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                          href={privacyPolicy}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </section>
                    </section>
                  </section>
                  <CloverlyFooter />
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default StripeCheckout
