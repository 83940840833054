import { useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import {
  getStoredColor,
  setStoredColor,
  setStoredColorPreview,
} from 'model/utils/userStorage'
import {
  adjustHue,
  darken,
  meetsContrastGuidelines,
  parseToHsl,
  readableColor,
  setLightness,
  setSaturation,
  tint,
  transparentize,
} from 'polished'
import { useQuery, useQueryClient } from 'react-query'

export const useTheme = () => {
  // utilities to get and set CSS variables
  const getPrimaryStyle = property =>
    getComputedStyle(document.documentElement).getPropertyValue(property).trim()
  const setPrimaryStyle = (property, value) =>
    document.documentElement.style.setProperty(property, value)

  const [originalStyles] = useState({
    primary: getPrimaryStyle('--primary'),
    secondary: getPrimaryStyle('--secondary'),
    secondaryLighter: getPrimaryStyle('--secondary-lighter'),
    secondaryInvert: getPrimaryStyle('--secondary-invert'),
    primaryLighter: getPrimaryStyle('--primary-lighter'),
    backgroundLight: getPrimaryStyle('--background-light'),
    backgroundDarker: getPrimaryStyle('--background-darker'),
    cobrandedBackground: getPrimaryStyle('--color-neutral-95'),
    cobrandedBackgroundDarker: getPrimaryStyle('--color-neutral-90'),
    cloverlyLogoGreen: getPrimaryStyle('--cloverly-logo-green'),
  })

  // check saturation and set appropriately for sidebar highlight
  const getHighlightColor = (color, opacity) => {
    const { saturation } = parseToHsl(color)
    const finalSaturation = saturation >= 0.25 ? 0.5 : saturation
    return transparentize(
      opacity,
      setSaturation(finalSaturation, setLightness(0.65, color))
    )
  }

  // check contrast against white and set color accordingly
  const brandTextColor = brandColor => {
    if (!brandColor) {
      return getPrimaryStyle('--link-primary')
    }

    const { hue } = parseToHsl(brandColor)
    const hueDirection = hue >= 170 ? 1 : -1

    const newBrandColor = meetsContrastGuidelines(brandColor, '#FFFFFF').AA
      ? brandColor
      : adjustHue(0.1375 * hueDirection, darken(0.0025, brandColor))

    return meetsContrastGuidelines(newBrandColor, '#FFFFFF').AA
      ? newBrandColor
      : brandTextColor(newBrandColor)
  }

  const rootStyles = {
    ...originalStyles,
    transparentColor: (color, opacity) => getHighlightColor(color, opacity),
    primaryText: color => brandTextColor(color),
    buttonTextColor: color => readableColor(color),
  }

  const { account } = useUser()
  const primaryDefault = rootStyles.primary
  const [color, setColor] = useState(getStoredColor() || primaryDefault)
  const [image, setImage] = useState('')
  const [colorPreview, setColorPreview] = useState(primaryDefault)

  const queryClient = useQueryClient()

  const isAppCobranded = color !== primaryDefault || image !== ''

  const getColor = () => (!account ? color : account.cobranding_primary_color)
  const getColorPreview = () =>
    !account ? color : account.cobranding_primary_color

  const updateImage = newImage => {
    //accept it even if it's an empty string
    if (newImage || newImage === '') setImage(newImage)
    localStorage.setItem('cobranded-logo', JSON.stringify(newImage))
  }

  // call useQuery to update user data from server
  useQuery('theme-color', () => getColor(), {
    //enable only if a user is logged in
    enabled: !!color,
    onSuccess: data => setColor(data),
  })

  useQuery('theme-color-preview', () => getColorPreview(), {
    enabled: !!colorPreview,
    onSuccess: data => setColorPreview(data),
  })

  //incorporate the POST request for updating the Account object in this function.
  const updateColor = newColor => {
    // check if this is a cobranded account
    const isCoBranded = primaryDefault !== newColor

    if (newColor) {
      // set the styles for primary color.
      setPrimaryStyle('--primary', newColor)
      setPrimaryStyle(
        '--background-light',
        !isCoBranded
          ? rootStyles.backgroundLight
          : rootStyles.cobrandedBackground
      )
      setPrimaryStyle(
        '--background-darker',
        !isCoBranded
          ? rootStyles.backgroundDarker
          : rootStyles.cobrandedBackgroundDarker
      )
      setPrimaryStyle(
        '--sidebar-item-hover',
        !isCoBranded
          ? rootStyles.transparentColor(primaryDefault, 0.9)
          : rootStyles.transparentColor(newColor, 0.925)
      )
      setPrimaryStyle(
        '--sidebar-item-active',
        !isCoBranded
          ? rootStyles.transparentColor(primaryDefault, 0.825)
          : rootStyles.transparentColor(newColor, 0.85)
      )
      setPrimaryStyle(
        '--primary-active',
        !isCoBranded
          ? rootStyles.transparentColor(primaryDefault, 0.6)
          : rootStyles.transparentColor(newColor, 0.6)
      )
      setPrimaryStyle(
        '--sidebar-item-highlight',
        !isCoBranded
          ? rootStyles.cloverlyLogoGreen
          : rootStyles.primaryText(newColor)
      )
      setPrimaryStyle(
        '--secondary',
        !isCoBranded
          ? rootStyles.secondary
          : getPrimaryStyle('--color-neutral-20')
      )
      setPrimaryStyle(
        '--primary-lighter',
        !isCoBranded ? rootStyles.primaryLighter : tint(0.25, newColor)
      )
      setPrimaryStyle(
        '--link-primary',
        !isCoBranded
          ? rootStyles.primaryText(primaryDefault)
          : rootStyles.primaryText(newColor)
      )
      setPrimaryStyle(
        '--button-text-primary',
        !isCoBranded
          ? rootStyles.buttonTextColor(primaryDefault)
          : rootStyles.buttonTextColor(newColor)
      )
      setPrimaryStyle(
        '--secondary-lighter',
        !isCoBranded
          ? rootStyles.secondaryLighter
          : getPrimaryStyle('--color-neutral-40')
      )
      setPrimaryStyle(
        '--secondary-invert',
        !isCoBranded
          ? rootStyles.secondaryInvert
          : getPrimaryStyle('--color-secondary-80')
      )
      setColor(newColor)
      setStoredColor(newColor)
      queryClient.setQueryData('theme-color', newColor)
    }
  }

  const updateColorPreview = newColorPreview => {
    // check if this is a cobranded account
    const isCoBranded = primaryDefault !== newColorPreview

    // set the styles for primary preview color.
    setPrimaryStyle('--primary-preview', newColorPreview)
    setPrimaryStyle(
      '--background-light-preview',
      !isCoBranded ? rootStyles.backgroundLight : rootStyles.cobrandedBackground
    )
    setPrimaryStyle(
      '--sidebar-item-hover-preview',
      !isCoBranded
        ? rootStyles.transparentColor(primaryDefault, 0.925)
        : rootStyles.transparentColor(newColorPreview, 0.925)
    )
    setPrimaryStyle(
      '--sidebar-item-active-preview',
      !isCoBranded
        ? rootStyles.transparentColor(primaryDefault, 0.85)
        : rootStyles.transparentColor(newColorPreview, 0.85)
    )
    setPrimaryStyle(
      '--sidebar-item-highlight-preview',
      !isCoBranded
        ? rootStyles.cloverlyLogoGreen
        : rootStyles.primaryText(newColorPreview)
    )
    setPrimaryStyle(
      '--secondary-preview',
      !isCoBranded
        ? rootStyles.secondary
        : getPrimaryStyle('--color-utility-black')
    )
    setPrimaryStyle(
      '--primary-preview-lighter',
      !isCoBranded ? rootStyles.primaryLighter : tint(0.25, newColorPreview)
    )
    setPrimaryStyle(
      '--button-text-primary-preview',
      !isCoBranded
        ? rootStyles.buttonTextColor(primaryDefault)
        : rootStyles.buttonTextColor(newColorPreview)
    )
    setPrimaryStyle(
      '--link-primary-preview',
      !isCoBranded
        ? rootStyles.primaryText(primaryDefault)
        : rootStyles.primaryText(newColorPreview)
    )
    setPrimaryStyle(
      '--secondary-lighter',
      !isCoBranded
        ? rootStyles?.secondaryLighter
        : getPrimaryStyle('--color-neutral-40')
    )
    setPrimaryStyle(
      '--secondary-invert',
      !isCoBranded
        ? rootStyles?.secondaryInvert
        : getPrimaryStyle('--color-secondary-80')
    )
    setColorPreview(newColorPreview)
    setStoredColorPreview(newColorPreview)
    queryClient.setQueryData('theme-color-preview', newColorPreview)
  }

  const clearColor = () => {
    setColor(null)
    localStorage.removeItem('theme-color')
    localStorage.removeItem('theme-color-preview')
    //clear the query. When theme-color is null, apply the Cloverly default theme.
    queryClient.setQueryData('theme-color', null)
    queryClient.setQueryData('theme-color-preview', null)
  }

  return {
    rootStyles,
    primaryDefault,
    color,
    image,
    colorPreview,
    isAppCobranded,
    updateColor,
    updateColorPreview,
    clearColor,
    updateImage,
    brandTextColor,
    setPrimaryStyle,
    getPrimaryStyle,
  }
}
