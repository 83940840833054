import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd/lib'
import { useNavbarContext } from 'model/contexts/NavbarContext'

type Props = {
  id: string
  children: string | React.ReactElement
  onClick: React.MouseEventHandler
  label?: string
}

const NavMenuItem = ({ id, children, onClick, label }: Props) => {
  const { menuOpen, selectedRoute } = useNavbarContext()
  const [closedWidth, setClosedWidth] = useState(false)
  const navbarWidthInPx = getComputedStyle(document.documentElement)
    .getPropertyValue('--sidebar-width')
    .trim()

  const lessThan200 = Number(navbarWidthInPx.replace('px', '')) < 200
  useEffect(() => {
    setClosedWidth(lessThan200)
  }, [navbarWidthInPx])

  const formattedRoute = selectedRoute?.includes('/')
    ? selectedRoute.split('/')[1]
    : selectedRoute

  const selectedItem = formattedRoute === id

  //reuse for tooltip and without tooltip
  const button = () => {
    return (
      <button
        id={id}
        onClick={handleClick}
        className={`nav-group-item ${closedWidth ? 'overflow-clip text-nowrap' : ''} ${
          selectedItem
            ? ' selected-item'
            : menuOpen
              ? 'normal-item'
              : ' closed-normal-item'
        }`}
      >
        {children}
      </button>
    )
  }

  const handleClick = (event: React.MouseEvent) => {
    onClick(event)
  }

  return (
    <div
      style={{
        width: '100%',
        paddingInline: 4,
        display: 'flex',
        alignItems: 'center',
      }}
      {...(selectedItem && { className: 'selected-nav' })}
    >
      {selectedItem ? <div className="selection" /> : <></>}
      {menuOpen ? (
        button()
      ) : (
        <Tooltip
          color="#1A3F4C"
          overlayInnerStyle={{
            backgroundColor: '#1A3F4C',
            color: '#FFFFFF',
            fontWeight: 500,
            fontSize: 14,
            borderRadius: 6,
            padding: '8px 12px',
          }}
          placement="right"
          title={<span>{label}</span>}
        >
          {button()}
        </Tooltip>
      )}
    </div>
  )
}

export default NavMenuItem
