import React, { Fragment } from 'react'
import { Project } from 'interfaces/Project.interface'
import { capFirstLetter } from 'model/utils/capitalize-first-letter'
import EmailButton from 'view/components/buttons/email-button/EmailButton'
import ExternalLink from 'view/components/external-link/ExternalLink'
import CorsiaHeader from './CorsiaHeader'
import corsiaAirplane from '../../../../../../../public/assets/corsiaAirplane.png'
import ExpandablePanel from '../ExpandablePanel'

type Props = {
  project: Project
}

const CorsiaPanel = ({ project }: Props) => {
  const corsiaData = project.quality_markers?.corsia
  const corsiaWebsiteURL =
    'https://www.icao.int/environmental-protection/CORSIA/Pages/default.aspx'

  const projectName = project.short_name || project.name

  const emailSubject = `CORSIA status for ${projectName ?? ''}`
  const email = `supply@cloverly.com?subject=${encodeURIComponent(
    emailSubject
  )}`

  const panelDesc = `According to ${project.registry_name}, credits in the following vintages are CORSIA ${corsiaData?.status}: `

  const shouldDisplayPanel =
    corsiaData &&
    (!!project.quality_markers?.corsia?.status ||
      !!project.quality_markers?.corsia?.vintage_years)

  return (
    <>
      {shouldDisplayPanel ? (
        <ExpandablePanel
          panelHeader={expanded => (
            <CorsiaHeader status={corsiaData.status} expanded={expanded} />
          )}
        >
          <section className="flex flex-col justify-between gap-4">
            <section className="flex gap-2">
              <section className="flex flex-col gap-3">
                <p className="font-bold text-2xl">
                  Status: {capFirstLetter(corsiaData.status)}
                </p>
                <p className="text-sm text-text-normal">
                  {panelDesc}
                  {corsiaData.vintage_years?.map(
                    (vintage: string, idx: number) => (
                      <Fragment key={idx}>
                        {vintage}
                        {idx !== corsiaData.vintage_years?.length - 1
                          ? ', '
                          : '. '}
                      </Fragment>
                    )
                  )}
                  Please contact Cloverly to verify eligibility before purchase.
                </p>
                <EmailButton email={email}>Request information</EmailButton>
              </section>
              <img
                className="object-contain w-1/2"
                src={corsiaAirplane}
                alt="CORSIA Image"
              />
            </section>
            <ExternalLink
              className="font-normal text-sm"
              title="Learn more about CORSIA"
              url={corsiaWebsiteURL}
            />
          </section>
        </ExpandablePanel>
      ) : null}
    </>
  )
}

export default CorsiaPanel
