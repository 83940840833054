import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import AppTooltip from 'view/components/app-tooltip/AppTooltip'
import Subnav from 'view/components/subnav/Subnav'
import SubNavLink from 'view/components/subnav/SubNavLink'
import './ShopifyPageHeader.scss'

const ShopifyPageHeader = React.memo(
  ({ shopifyData, saveShopifyDataChanges }) => {
    const [isDisabled, setIsDisabled] = useState(true)
    const { register, watch } = useForm({
      defaultValues: useMemo(() => shopifyData, [shopifyData]),
    })

    useEffect(() => {
      const subscription = watch(value =>
        saveShopifyDataChanges({ is_enabled: value.is_enabled })
      )
      return () => subscription.unsubscribe()
      // eslint-disable-next-line
    }, [watch])

    useEffect(
      () =>
        setIsDisabled(
          !(
            !!shopifyData &&
            !!shopifyData.default_from_country &&
            !!shopifyData.default_from_postal_code
          )
        ),
      [shopifyData]
    )

    return (
      <div className="shopify-page-header">
        <div className="left-section">
          <div className="header-title-section">
            <h1>Shopify App</h1>
            <AppTooltip
              placement="top"
              id="shopify-app"
              message={
                <div>
                  Set up and configure your Shopify app,
                  <br />
                  from right here in your Cloverly account!
                </div>
              }
            />
          </div>
          <Subnav>
            <SubNavLink
              title="Shipping"
              href="/shopify/shipping"
              exact={true}
            />
            <SubNavLink
              title="Behavior"
              href="/shopify/behavior"
              exact={true}
            />
            <SubNavLink title="Design" href="/shopify/design" exact={true} />
            <SubNavLink
              title="Fulfillment"
              href="/shopify/fulfillment"
              exact={true}
            />
          </Subnav>
        </div>
        <div className="right-section">
          <div className={'enable-panel' + (isDisabled ? ' disabled' : '')}>
            <span className="label">Enable Shopify App</span>
            <AppTooltip
              className="info"
              placement="top"
              id="enabled-shopify"
              message={
                <div>
                  You can turn carbon neutral shipping on or off
                  <br />
                  at any time without losing your app settings.
                </div>
              }
            />
            <Form>
              <Form.Check
                id="is_enabled"
                name="is_enabled"
                type="switch"
                className="master-switch"
                disabled={isDisabled}
                {...register('is_enabled')}
              />
            </Form>
          </div>
        </div>
      </div>
    )
  }
)

export default ShopifyPageHeader
