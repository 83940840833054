import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Event from '@mui/icons-material/Event'
import Replay from '@mui/icons-material/Replay'
import WarningIcon from '@mui/icons-material/Warning'
import { monthAndYear } from 'model/utils/date'
import { getUnits, getWeightUnit } from 'model/utils/units'
import CancelIcon from 'view/components/icons/cancel-icon/CancelIcon'

export const transactionReceiptComponents = () => {
  const transaction_icons = {
    FORWARD: <Event />,
    PURCHASED: <CheckCircleIcon />,
    ESTIMATED: <WarningIcon />,
    CANCELED: <CancelIcon />,
    REFUNDED: <Replay />,
  }

  const TransactionStateBlock = ({ transaction, isInHeader }) => {
    const transactions = transaction?.length ? transaction[0] : transaction

    const transactionState =
      transactions?.fulfillment_state === 'pending delivery' &&
      transactions?.transaction_state?.toUpperCase() === 'PURCHASED'
        ? 'FORWARD'
        : transactions?.transaction_state?.toUpperCase()
    return (
      <div className={`transaction-state ${transactionState}`}>
        {transaction_icons[transactionState]}
        {isInHeader ? null : <span>{transactionState}</span>}
      </div>
    )
  }

  const TransactionBasicDetails = ({
    transaction,
    account,
    displayActualNumber,
  }) => {
    return (
      <div className="basic-details">
        <TransactionStateBlock transaction={transaction} />
        <span
          className={`weight-count ${
            !transaction?.is_cobranding_enabled ? 'non-cobranded' : ''
          }`}
        >
          {getWeightUnit({
            weightType: account?.display_kg ? 'kg' : 'lbs',
            value: transaction?.total_co2e_in_kg,
            roundUnits: 2,
            format: '%v %u',
          })}
        </span>
        {displayActualNumber && (
          <span className="actual-amount">
            {getUnits({
              isKg: account?.display_kg,
              value: transaction?.total_co2e_in_kg,
              roundUnits: 2,
              format: '%v %u',
            })}
          </span>
        )}
        <span className="equivalent-carbon">Equivalent carbon</span>
      </div>
    )
  }

  const ProjectDataCell = ({ label, value, estimatedDeliveryDate }) => {
    const className = `${
      estimatedDeliveryDate ? 'estimated-delivery-date' : null
    }`
    return (
      <div className="project-data-cell">
        {!value || value === '' ? null : (
          <div>
            <label>{label}</label>
            <span className={className}>{value}</span>
          </div>
        )}
      </div>
    )
  }

  // use it as a function rather than a React element
  const registryID = ({ id = null, registry_link }) => {
    if (!id || id === '') return null
    return (
      <a href={registry_link} target="_blank" rel="noreferrer">
        {id}
      </a>
    )
  }

  //if delivered_at === null, hide the entire section
  const DeliveryDate = ({ transaction }) => {
    const transactionState = transaction?.length
      ? transaction[0]?.transaction_state
      : transaction?.transaction_state

    return transaction?.delivered_at ? (
      <>
        {transactionState === 'purchased' &&
          transaction?.fulfillment_state === 'delivered' && (
            <ProjectDataCell
              label="Delivered"
              value={monthAndYear(new Date(transaction?.delivered_at))}
            />
          )}
        {transactionState === 'purchased' &&
          transaction?.fulfillment_state === 'pending delivery' && (
            <ProjectDataCell
              label="Estimated Delivery Date"
              value={monthAndYear(new Date(transaction?.delivered_at))}
              estimatedDeliveryDate
            />
          )}
      </>
    ) : null
  }

  const UserTip = ({ isCobrandingEnabled }) => (
    <p className={`user-tip ${isCobrandingEnabled ? 'cobranded' : ''}`}>
      The information about the resource used to green your transaction is based
      on Cloverly’s most current information regarding the location of your
      transaction and the availability in inventory of Carbon Credits. Please
      note that it may take several weeks to reconcile transactions and if the
      information or resources used to green your transaction change, Cloverly
      will update this page to accurately reflect the resources used and retired
      to green the transaction.
    </p>
  )

  return {
    ProjectDataCell,
    registryID,
    DeliveryDate,
    TransactionStateBlock,
    UserTip,
    TransactionBasicDetails,
  }
}
