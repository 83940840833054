import { Font } from '@react-pdf/renderer'

export const registerPDFFonts = () => {
  const interFontPath='/fonts/Inter/'
  Font.register({
    family: 'Inter',
    fonts: [
      { src: `${interFontPath}Inter-Regular.woff` },

      {
        src: `${interFontPath}Inter-Italic.woff`,
        fontStyle: 'italic',
      },
      {
        src: `${interFontPath}Inter-Italic.woff`,
        fontStyle: 'italic',
        fontWeight: 400,
      },
      {
        src: `${interFontPath}Inter-Bold.woff`,
        fontWeight: 'bold',
      },
      {
        src: `${interFontPath}Inter-BoldItalic.woff`,
        fontWeight: 'bold',
        fontStyle: 'italic',
      },
      {
        src: `${interFontPath}Inter-Regular.woff`,
        fontWeight: 400,
      },
      {
        src: `${interFontPath}Inter-Italic.woff`,
        fontStyle: 'italic',
        fontWeight: 400,
      },
      {
        src: `${interFontPath}Inter-Medium.woff`,
        fontWeight: 500,
      },
      {
        src: `${interFontPath}Inter-MediumItalic.woff`,
        fontWeight: 500,
        fontStyle: 'italic',
      },
      {
        src: `${interFontPath}Inter-SemiBold.woff`,
        fontWeight: 600,
      },
      {
        src: `${interFontPath}Inter-SemiBoldItalic.woff`,
        fontWeight: 600,
        fontStyle: 'italic',
      },
      {
        src: `${interFontPath}Inter-Bold.woff`,
        fontWeight: 700,
      },
      {
        src: `${interFontPath}Inter-BoldItalic.woff`,
        fontWeight: 'bold',
        fontStyle: 700,
      },
    ],
  })
  Font.registerHyphenationCallback(word => [word])
}
