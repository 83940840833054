import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useCurrencyExchangeRates } from 'model/hooks/useCurrencyExchangeRates'
import { handleCalculateCostPerTonne } from 'model/utils/cost'
import LoadingSpinnerIcon from 'view/components/icons/loading-spinner-icon/LoadingSpinnerIcon'
import InternalLink from 'view/components/internal-link/InternalLink'
import PortfolioCertificate from './PortfolioCertificate'
import './PortfolioDetails.scss'

const PortfolioDetails = ({
  portfolio,
  transactionId,
  certificateUrl,
  requestedCurrency,
}) => {
  const { account } = useUser()
  const { currencyRates } = useCurrencyExchangeRates({ showLoading: true })
  const portfolioDescriptor = 'Portfolio'
  const createdBy = portfolio?.created_by
    ? `Created by ${portfolio?.created_by}`
    : undefined
  const projectCount = `${portfolio?.offset_sources?.length || '#'} projects`
  const costPerTonne =
    handleCalculateCostPerTonne(
      portfolio?.price_per_tonne_in_USD,
      requestedCurrency || account?.default_currency,
      currencyRates
    ) + ' / tonne'

  const portfolioCollection = [
    portfolioDescriptor,
    createdBy,
    projectCount,
    costPerTonne,
  ]

  return (
    <>
      {!portfolio && <LoadingSpinnerIcon className="spinner" strokeWidth={2} />}
      {!!portfolio && (
        <div id="portfolioDetails">
          <div className="left-col">
            <div className="header item">
              {portfolio.name || portfolioDescriptor}
            </div>
            <div className="subheader item">
              {portfolioCollection?.filter(Boolean).join(' • ')}
            </div>
            <div className="external-link item">
              <InternalLink
                opensNewTab
                title="Portfolio Details"
                url={`/marketplace/portfolio-details/${portfolio.slug}`}
              />
            </div>
          </div>
          <div className="right-col">
            <PortfolioCertificate
              certificateUrl={certificateUrl}
              transactionId={transactionId}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default PortfolioDetails
