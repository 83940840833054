import React from 'react'
import { Path, Svg } from '@react-pdf/renderer'

/**
 * @param {number} width
 * @param {number} height
 * @returns {React.JSX.Element}
 */
const MediumDurabilityIcon = ({ width = 9, height = 9 }) => (
  <Svg width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d="M5.64952 3.19739C5.44241 2.83867 4.98372 2.71577 4.625 2.92287C2.95688 3.88596 1.65317 5.37256 0.91605 7.15211C0.178936 8.93167 0.0496144 10.9047 0.548146 12.7653C0.655352 13.1654 1.06661 13.4028 1.4667 13.2956C1.8668 13.1884 2.10424 12.7771 1.99703 12.377C1.58397 10.8354 1.69112 9.20063 2.30187 7.72614C2.91262 6.25165 3.99284 5.0199 5.375 4.22191C5.73372 4.0148 5.85662 3.55611 5.64952 3.19739Z" fill="#36444D" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M12.3505 3.19739C12.1434 3.55611 12.2663 4.0148 12.625 4.22191C14.0071 5.0199 15.0874 6.25165 15.6981 7.72614C16.3089 9.20063 16.416 10.8354 16.003 12.377C15.8958 12.7771 16.1332 13.1884 16.5333 13.2956C16.9334 13.4028 17.3446 13.1654 17.4518 12.7653C17.9504 10.9047 17.8211 8.93167 17.0839 7.15211C16.3468 5.37256 15.0431 3.88596 13.375 2.92287C13.0163 2.71577 12.5576 2.83867 12.3505 3.19739Z" fill="#36444D" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M9 12.25C8.86193 12.25 8.75 12.3619 8.75 12.5C8.75 12.6381 8.86193 12.75 9 12.75C9.13807 12.75 9.25 12.6381 9.25 12.5C9.25 12.3619 9.13807 12.25 9 12.25ZM7.25 12.5C7.25 11.5335 8.0335 10.75 9 10.75C9.9665 10.75 10.75 11.5335 10.75 12.5C10.75 13.4665 9.9665 14.25 9 14.25C8.0335 14.25 7.25 13.4665 7.25 12.5Z" fill="#36444D" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M9 13.1309C9.41421 13.1309 9.75 12.7951 9.75 12.3809L9.75 1.99973C9.75 1.58552 9.41421 1.24973 9 1.24973C8.58579 1.24973 8.25 1.58552 8.25 1.99973L8.25 12.3809C8.25 12.7951 8.58579 13.1309 9 13.1309Z" fill="#36444D" />
  </Svg>
)

export default MediumDurabilityIcon
