import React from 'react'
import { DatePicker } from 'antd/lib'
import dayjs, { Dayjs } from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

type Props = {
  dateRange: { startDate: Dayjs; endDate: Dayjs }
  handleSetStartDate: (startDate?: Dayjs) => void
  handleSetEndDate: (endDate?: Dayjs) => void
}

const DatePickerComponent = ({
  dateRange,
  handleSetStartDate,
  handleSetEndDate,
}: Props) => {
  dayjs.extend(LocalizedFormat)
  const startDate = dateRange.startDate
  const endDate = dateRange.endDate

  return (
    <>
      <DatePicker
        allowClear={false}
        onChange={handleSetStartDate}
        defaultValue={startDate}
      />
      <DatePicker
        allowClear={false}
        onChange={handleSetEndDate}
        defaultValue={endDate}
      />
    </>
  )
}

export default DatePickerComponent
