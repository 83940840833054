import React, { useEffect, useReducer, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { setPageTitle } from 'model/utils/page'
import { NavLink } from 'react-router-dom'
import '../../AccountSettings.scss'
import InvoiceTable from '../tables/InvoiceTable'

const InvoicePage = () => {
  const [state, dispatch] = useReducer(reducer, {})
  const [, setTableData] = useState({ invoices: [], totalPages: 1 })
  const [tableCache, setTableCache] = useState([])
  const [page, setPage] = useState(0)
  const changePage = ({ selected }) => setPage(selected)
  const { account } = useUser()
  const [slug, setSlug] = useState(account.slug)
  const { serviceRequest } = useService()

  useEffect(() => {
    setPageTitle('Invoices | Account Settings')
  }, [])

  useEffect(() => {
    if (slug !== account.slug) {
      setTableCache([])
    }
    if (tableCache[page] && slug === account.slug) {
      setTableData(tableCache[page])
    } else {
      setSlug(account.slug)
      serviceRequest({
        path: `/api/invoices?page=${page + 1}&limit=10`,
      }).then(tableData =>
        dispatch({
          type: 'setInvoices',
          tableData,
        })
      )
    }
    // eslint-disable-next-line
  }, [page, account])

  function reducer(state, action) {
    switch (action.type) {
      case 'setInvoices':
        state.tableData = action.tableData
        break
    }
    return { ...state }
  }

  return (
    <div className="account-settings">
      <div className="sub-page-header">
        <div className="left-col left-col-bottom-spacer">
          <NavLink to="/account-settings/billing">
            <ArrowBackIcon /> Back
          </NavLink>
          <h1>Invoices</h1>
        </div>
      </div>
      <InvoiceTable tableData={state.tableData} changePage={changePage} />
    </div>
  )
}

export default InvoicePage
