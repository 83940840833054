import React from 'react'

const Info = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 20.6667H15.3334V12.6667H12.6667V20.6667ZM14.0001 0.666656C6.63341 0.666656 0.666748 6.63332 0.666748 14C0.666748 21.3667 6.63341 27.3333 14.0001 27.3333C21.3667 27.3333 27.3334 21.3667 27.3334 14C27.3334 6.63332 21.3667 0.666656 14.0001 0.666656ZM14.0001 24.6667C8.12008 24.6667 3.33341 19.88 3.33341 14C3.33341 8.11999 8.12008 3.33332 14.0001 3.33332C19.8801 3.33332 24.6667 8.11999 24.6667 14C24.6667 19.88 19.8801 24.6667 14.0001 24.6667ZM12.6667 9.99999H15.3334V7.33332H12.6667V9.99999Z"
      fill="white"
    />
  </svg>
)

export default Info
