import React, { useCallback, useContext, useRef, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useFeatureFlag } from 'model/hooks/useFeatureFlag'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { displayDate, numericDate } from 'model/utils/date'
import { setPageTitle } from 'model/utils/page'
import { defaultFilterTypes } from 'model/utils/project/projectFilters'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

const CheckoutContext = React.createContext()

export const usePaymentContext = () => useContext(CheckoutContext)

export const PaymentProvider = ({ children }) => {
  const { getCurrentEnv } = useFeatureFlag()
  const {
    register,
    watch,
    formState: { errors },
    setError,
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm()
  const { serviceRequest, serviceRequestMulti, spreadResponses } = useService()
  const { warningToast, confirmationToast } = useToast()
  const { account } = useUser()
  const navigate = useNavigate()
  const sourceRef = useRef()

  // default page object
  const defaultPage = {
    id: null,
    checkout_page_slug: null,
    name: '',
    status: 'DRAFT',
    amount_type: null,
    amount_value: null,
    unit_type: 'CARBON',
    unit_value: 'TONNES',
    custom_message: '',
    source_slugs: [],
    include_forward_credits: true,
  }

  const [projects, setProjects] = useState([])
  const [portfolioSlugs, setPortfolioSlugs] = useState([])
  const [sourceFilterSet, setSourceFilterSet] = useState({
    tier1: null,
    tier2: null,
  })
  const [destFilterSet, setDestFilterSet] = useState({
    tier1: null,
    tier2: null,
  })
  const [sourceHasChecked, setSourceHasChecked] = useState(false)
  const [destHasChecked, setDestHasChecked] = useState(false)
  const [pages, setPages] = useState({
    activePages: [],
    draftPages: [],
    archivedPages: [],
  })
  const [dataLoaded, setDataLoaded] = useState(false)
  const [filterTypes, setFilterTypes] = useState(defaultFilterTypes)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [showRemoveVintageModal, setShowRemoveVintageModal] = useState(false)
  const [showDeleteCheckoutModal, setShowDeleteCheckoutModal] = useState(false)
  const [deleteCheckoutPageSlug, setDeleteCheckoutPageSlug] = useState(false)

  // set default page object for new pages
  const [page, setPage] = useState(defaultPage)

  // public direct checkout url
  // todo: this should eventually be replaced by a env var
  const getDirectCheckoutUrl = () => {
    switch (getCurrentEnv()) {
      case 'local':
        return 'http://127.0.0.1:3000/external-offset-purchase/'
      case 'stage':
      case 'staging':
        return 'https://checkout-stag.cloverly.com/'
      default:
        return 'https://checkout.cloverly.com/'
    }
  }

  /**
   * Loads the list of checkout pages for a given account.
   */
  const loadCheckoutPages = () => {
    setDataLoaded(false)
    if (page) setPage(null)
    serviceRequest({
      path: '/checkout-pages',
      method: 'GET',
      sourceRef,
    })
      .then(data => {
        const newPages = { activePages: [], draftPages: [], archivedPages: [] }
        if (Array.isArray(data?.checkout_pages)) {
          data.checkout_pages.forEach(page => {
            // ensure statuses are always uppercase
            page.status = page?.status?.toUpperCase()
            page.created_at = convertDateStringToObject(page.created_at)
            page.active_since = convertDateStringToObject(page.active_since)
            page.archived_at = convertDateStringToObject(page.archived_at)
            if (page.status === 'ACTIVE') newPages.activePages.push(page)
            else if (page.status === 'DRAFT') newPages.draftPages.push(page)
            else if (page.status === 'ARCHIVED')
              newPages.archivedPages.push(page)

            // let's sort each sub-group of pages
            newPages.activePages.sort(
              (a, b) => new Date(a.active_since) < new Date(b.active_since)
            )
            newPages.draftPages.sort(
              (a, b) => new Date(a.created_at) < new Date(b.created_at)
            )
            newPages.archivedPages.sort(
              (a, b) => new Date(a.archived_at) < new Date(b.archived_at)
            )
          })
          setPages(newPages)
          setDataLoaded(true)
        }
      })
      .catch(e => {
        warningToast(
          e.response?.data?.Message || 'Page List Load Failed.',
          'warning'
        )
        // console.error(e)
        setPages({ activePages: [], draftPages: [], archivedPages: [] })
        setDataLoaded(true)
      })
  }

  /**
   * Loads data for a checkout page. includes call to load list of projects. If checkout_page_slug is undefined,
   * then a call for the project data will not be made and will be assumed this is loading a new checkout page.
   *
   * @param checkout_page_slug
   * @param callbackFunction
   */
  const loadCheckoutPage = (checkout_page_slug, callbackFunction) => {
    reset()
    clearErrors()
    setSubmitAttempted(false)
    const requests = [{ path: '/projects', method: 'GET' }]
    if (checkout_page_slug)
      requests.push({
        path: `/checkout-page-admin?checkout_page_slug=${checkout_page_slug}`,
        method: 'GET',
      })
    serviceRequestMulti({ requests, sourceRef })
      .then(
        spreadResponses((projectsResponse, checkoutPageResponse) => {
          let allProjects = []
          let page = undefined
          const projectTypes = []
          const registryTypes = []
          if (projectsResponse?.data) {
            allProjects = projectsResponse?.data.filter(
              project =>
                // project must be purchasable and
                project.is_purchasable &&
                // project type should not be solar or wind
                !['SOLAR', 'WIND'].includes(project.type?.toUpperCase())
            )
            allProjects.forEach(project => {
              project.checked = false
              project.selected = false
              if (!projectTypes.includes(project.type))
                projectTypes.push(project.type)
              if (!registryTypes.includes(project.registry_name))
                registryTypes.push(project.registry_name)
            })
            filterTypes.forEach(filterType => {
              if (filterType.label === 'Project Type')
                filterType.children = projectTypes
              else if (filterType.label === 'Registry')
                filterType.children = registryTypes
            })
          }
          if (checkoutPageResponse?.data) {
            page = checkoutPageResponse?.data
            const source_slugs = []
            // console.log("All Projects:", allProjects)
            // console.log("Checkout Page Response Loaded: ", checkout_page_slug, page)
            if (
              Array.isArray(page.source_slugs) &&
              Array.isArray(allProjects)
            ) {
              // console.log("Found projects associated with this page. count=", page.projects.length)
              let pSlugs = []
              checkoutPageResponse?.data?.source_slugs.forEach(
                offset_source_slug => {
                  let matchFound = false
                  allProjects.forEach(project => {
                    //console.log("Checking matching project:", offset_source_slug, project.id)
                    if (offset_source_slug === project.id) {
                      //console.log("Found matching project, setting to selected.", offset_source_slug, project)
                      source_slugs.push(offset_source_slug)
                      project.selected = true
                      matchFound = true
                    }
                  })
                  // if not match found, we can assume the slug is a portfolio
                  if (!matchFound) {
                    pSlugs.push(offset_source_slug)
                  }
                }
              )
              // for testing--add a available portfolio
              //pSlugs = ['48004762-169f-4f8b-813c-fdb4fd85d400']
              setPortfolioSlugs(pSlugs)
            }
            page.source_slugs = source_slugs
            setValue('include_forward_credits', page.include_forward_credits)
          } else {
            // console.log('No Checkout Page Response. aka New Page.')
          }
          updateFilterTypes(filterTypes)
          updateProjects(allProjects)
          if (callbackFunction) callbackFunction(page)
        })
      )
      .catch(e => warningToast(e.response?.data?.Message || e))
  }

  /**
   * Save changes for a given page.
   *
   * @param formValue
   */
  const savePageChanges = formValue => {
    // console.log('Current Form State to Save:', getValues())
    const isNewPage = !page.checkout_page_slug
    const newStatus = formValue.status
    const updatedValues = getValues()
    updatedValues.status = newStatus
    const sourceSlugs = []
    if (formValue.source_slugs?.length > 0) {
      formValue.source_slugs.forEach(slug => {
        sourceSlugs.push({
          slug,
          is_portfolio: false,
        })
      })
    }
    if (portfolioSlugs?.length > 0) {
      portfolioSlugs.forEach(slug => {
        sourceSlugs.push({
          slug,
          is_portfolio: true,
        })
      })
    }
    formValue.source_slugs = sourceSlugs
    serviceRequest({
      path: '/checkout-page-admin',
      method: 'POST',
      data: formValue,
      sourceRef,
    })
      .then(response => {
        // console.log("Page Changes Saved!", response)
        if (newStatus === 'ACTIVE' && page.status !== 'ACTIVE')
          confirmationToast('Direct Checkout page has been published.')
        else
          confirmationToast(
            'Changes to the Direct Checkout page have been saved.'
          )
        const updatedPage = response
        // ensure statuses are always uppercase
        updatedPage.status = updatedPage?.status?.toUpperCase()
        setDataLoaded(true)
        onDataLoaded(updatedPage)
        scroll.scrollTo(0, {
          containerId: 'pages',
          duration: 0,
        })
        if (isNewPage && updatedPage.checkout_page_slug) {
          navigate(`/direct-checkout/edit/${updatedPage.checkout_page_slug}`)
        }
      })
      .catch(e => {
        warningToast(
          e.response?.data?.Message || 'Changes failed to save.',
          'warning'
        )
      })
  }

  /**
   * Once the page data is loaded, update the page form info if needed.
   *
   * @param page
   */
  const onDataLoaded = page => {
    if (page) {
      setPageTitle('Edit Page | Direct Checkout')
      // console.log('New Page', page)
    } else {
      setPageTitle('Create Page | Direct Checkout')
      page = defaultPage
    }
    setValue('checkout_page_slug', page.checkout_page_slug, {
      shouldDirty: false,
    })
    setValue('name', page.name, { shouldDirty: false })
    setValue('amount_type', page.amount_type, { shouldDirty: false })
    setValue('amount_value', page.amount_value, { shouldDirty: false })
    setValue('unit_type', page.unit_type, { shouldDirty: false })
    setValue('unit_value', page.unit_value, { shouldDirty: false })
    setValue('status', page.status, { shouldDirty: false })
    setValue('source_slugs', page.source_slugs, {
      shouldDirty: false,
    })
    setValue('custom_message', page.custom_message, { shouldDirty: false })
    setValue('include_forward_credits', page.include_forward_credits, {
      shouldDirty: false,
    })
    setPage(page)
    setDataLoaded(true)
  }

  /**
   * @returns {boolean}
   */
  const recheckErrors = () => {
    // console.log('Form Values:', getValues())
    clearErrors()
    let errorsFound = false
    if (getValues('name').trim().length === 0) {
      setError('name', { message: 'Direct Checkout name is required' })
      errorsFound = true
    }
    if (!getValues('source_slugs').length) {
      setError('project_selection', {
        message: 'Project selection is required',
      })
      errorsFound = true
    }
    if (!getValues('amount_type')) {
      setError('purchase_option', { message: 'Purchase options are required' })
      errorsFound = true
    }
    if (getValues('amount_type') === 'FIXED' && !getValues('amount_value')) {
      setError('amount_value', { message: 'Fixed value cannot be blank' })
      errorsFound = true
    }
    return errorsFound
  }

  /**
   *
   * @param filterSet
   * @param project
   * @returns {boolean}
   */
  const generateFilter = (filterSet, project) => {
    if (!filterSet.tier1 || !filterSet.tier2) {
      return true
    } else if (filterSet.tier1) {
      let unsdgId
      switch (filterSet.tier1.label) {
        case 'Project Type':
          return project.type === filterSet.tier2
        case 'Registry':
          return project.registry_name === filterSet.tier2
        case 'UN SDG':
          unsdgId = filterSet.tier2.split(':')[0]
          if (Array.isArray(project.unsdg))
            for (let i = 0; i < project.unsdg.length; i++)
              if (Number(project.unsdg[i]) === Number(unsdgId)) return true
          return false
        case 'Removal Time Period':
          return project.removal_time_period === filterSet.tier2.value
        case 'Mechanism':
          return project.offset_classification === filterSet.tier2
        default:
          return false
      }
    }
    return false
  }

  /**
   *
   * @param projects
   */
  const updateProjects = useCallback(projects => {
    updateHasChecked(projects)
    setProjects(Object.assign([], projects))
    const source_slugs = []
    projects.forEach(project => {
      if (project.selected) source_slugs.push(project.id)
    })
    if (getValues('source_slugs')?.length !== source_slugs?.length)
      setValue('source_slugs', source_slugs, {
        shouldDirty: true,
      })
    // eslint-disable-next-line
  }, [])

  /**
   *
   * @param targetProject
   */
  const toggleChecked = useCallback(
    targetProject => {
      projects.forEach(project1 => {
        if (project1.id === targetProject.id) {
          project1.checked = !project1?.checked
          updateProjects(projects)
        }
      })
    },
    [projects, updateProjects]
  )

  /**
   *
   */
  const clearChecked = () => {
    projects.forEach(project1 => (project1.checked = false))
    updateProjects(projects)
  }

  /**
   * @param e
   * @param includeProjects
   * @param confirmed
   */
  const updateIncludeVintageProjects = (
    e,
    includeProjects,
    confirmed = false
  ) => {
    // the case where the user clicks the checkbox to deselect and there are selected vintage projects
    if (
      !confirmed &&
      !includeProjects &&
      selectedVintageProjectNames().length > 0
    ) {
      e.preventDefault()
      e.stopPropagation()
      setShowRemoveVintageModal(true)
      return
    }
    // case where the user clicks confirm in the remove vintage modal
    else if (confirmed && !includeProjects) {
      removeVintageProjects()
      setShowRemoveVintageModal(true)
    }
    setValue('include_forward_credits', includeProjects)
    updateProjects(projects)
    if (submitAttempted) recheckErrors()
  }

  /**
   * Deselects all vintage projects
   */
  const removeVintageProjects = () => {
    projects.forEach(project => {
      if (
        project?.active_tranche &&
        project?.active_tranche?.is_issued &&
        project.selected
      )
        project.selected = false
    })
  }

  /**
   * Creates a list of selected vintage project names
   */
  const selectedVintageProjectNames = () => {
    let vintageProjectNames = []
    projects.forEach(project => {
      if (
        project?.active_tranche?.vintage_year &&
        !project?.active_tranche?.is_issued &&
        project.selected
      )
        vintageProjectNames.push(project.name)
    })
    return vintageProjectNames
  }

  /**
   * @param selected
   * @param e
   */
  const setAllSelectState = (selected, e) => {
    projects.forEach(project => {
      project.checked = false
      project.selected = selected
    })
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    updateProjects(projects)
    if (submitAttempted) recheckErrors()
  }

  /**
   * @param selected
   * @param e
   */
  const setCheckedSelectState = (selected, e) => {
    projects.forEach(project => {
      if (project.selected === selected) {
        if (project.checked) {
          project.checked = false
          project.selected = !project.selected
        }
      }
    })
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    updateProjects(projects)
    if (submitAttempted) recheckErrors()
  }

  /**
   *
   * @param projects
   */
  const updateHasChecked = projects => {
    let foundSourceChecked = false
    let foundDestChecked = false
    if (Array.isArray(projects))
      projects.forEach(project => {
        foundSourceChecked =
          project.checked && !project.selected ? true : foundSourceChecked
        foundDestChecked =
          project.checked && project.selected ? true : foundDestChecked
      })
    setSourceHasChecked(foundSourceChecked)
    setDestHasChecked(foundDestChecked)
  }

  /**
   *
   * @param filterTypes
   */
  const updateFilterTypes = filterTypes =>
    setFilterTypes(Object.assign([], filterTypes))

  /**
   *
   * @param page
   * @returns {string}
   */
  const getDateLabel = page => {
    switch (page.status) {
      case 'DRAFT':
        return 'Created'
      case 'ACTIVE':
        return 'Active Since'
      case 'ARCHIVED':
        return 'Archived On'
      default:
        return ''
    }
  }

  /**
   *
   * @param page
   * @returns {string|*}
   */
  const getDateValue = page => {
    switch (page.status) {
      case 'DRAFT':
        return displayDate(page.created_at, numericDate)
      case 'ACTIVE':
        return displayDate(page.active_since, numericDate)
      case 'ARCHIVED':
        return displayDate(page.archived_at, numericDate)
      default:
        return ''
    }
  }

  /**
   *
   */
  const navigateToHomePage = () => {
    setDataLoaded(false)
    navigate('/direct-checkout')
  }

  /**
   *
   * @param listPage
   */
  const navigateToCheckoutPage = listPage => {
    // console.log('Navigating to Checkout Page:', listPage)
    setDataLoaded(false)
    if (listPage) {
      navigate(`/direct-checkout/edit/${listPage.checkout_page_slug}`)
    } else {
      navigate('/direct-checkout/new')
    }
  }

  /**
   *
   * @param page
   */
  const openPublicCheckoutPage = page =>
    window.open(getDirectCheckoutUrl() + page.checkout_page_slug, '_blank')

  /**
   * If the user hits enter, prevent the form from auto-submitting.
   *
   * @param e
   */
  const preventFormSubmit = e => {
    if (e.code === 'Enter') e.preventDefault()
  }

  /**
   *
   * @param dateStr
   * @returns {Date|null}
   */
  const convertDateStringToObject = dateStr =>
    dateStr && dateStr !== 'None' ? new Date(dateStr) : null

  /**
   *
   * @param str
   * @returns {*}
   */
  const camelizeString = str =>
    str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      +match === 0
        ? ''
        : index === 0
          ? match.toUpperCase()
          : match.toLowerCase()
    )

  const contextValue = {
    account,
    projects,
    setProjects,
    filterTypes,
    sourceFilterSet,
    setSourceFilterSet,
    destFilterSet,
    setDestFilterSet,
    sourceHasChecked,
    destHasChecked,
    dataLoaded,
    setDataLoaded,
    pages,
    defaultPage,
    getDirectCheckoutUrl,
    toggleChecked,
    page,
    setPage,
    handleSubmit,
    register,
    watch,
    errors,
    clearErrors,
    setError,
    setValue,
    getValues,
    submitAttempted,
    setSubmitAttempted,
    showRemoveVintageModal,
    setShowRemoveVintageModal,
    showDeleteCheckoutModal,
    setShowDeleteCheckoutModal,
    deleteCheckoutPageSlug,
    setDeleteCheckoutPageSlug,
    updateIncludeVintageProjects,
    selectedVintageProjectNames,
    preventFormSubmit,
    onDataLoaded,
    setAllSelectState,
    setCheckedSelectState,
    clearChecked,
    generateFilter,
    loadCheckoutPages,
    loadCheckoutPage,
    savePageChanges,
    navigateToHomePage,
    navigateToCheckoutPage,
    openPublicCheckoutPage,
    getDateLabel,
    getDateValue,
    camelizeString,
    recheckErrors,
  }

  return (
    <CheckoutContext.Provider value={contextValue}>
      {children}
    </CheckoutContext.Provider>
  )
}
