import React, { useEffect, useState } from 'react'
import { DeleteOutline } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/EditOutlined'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import { getWeightUnit } from 'model/utils/units'
import CopyButton from 'view/components/buttons/copy-button/CopyButton'
import { usePaymentContext } from '../../../../../contexts/CheckoutContext'
import '../../../../../Checkout.scss'
import './PageListItem.scss'

const PageListItem = React.memo(({ account, page }) => {
  const {
    getDirectCheckoutUrl,
    navigateToCheckoutPage,
    openPublicCheckoutPage,
    getDateLabel,
    getDateValue,
    setShowDeleteCheckoutModal,
    setDeleteCheckoutPageSlug,
  } = usePaymentContext()

  const [checkoutPageUrl, setCheckoutPageUrl] = useState('')

  const deletePage = checkoutPageSlug => {
    setDeleteCheckoutPageSlug(checkoutPageSlug)
    setShowDeleteCheckoutModal(true)
  }

  useEffect(() => {
    setCheckoutPageUrl(getDirectCheckoutUrl() + page?.checkout_page_slug)
  }, [page?.checkout_page_slug])

  return (
    <>
      <div className="page-list-item" key={page.checkout_page_slug}>
        <div className="panel-header">
          <div className="header-title">
            <h2>{page.name}</h2>
            <div
              className={(page.status !== 'ACTIVE'
                ? page.status
                : 'LIVE'
              ).toLowerCase()}
            >
              {page.status !== 'ACTIVE' ? page.status : 'LIVE'}
            </div>
          </div>
          <button onClick={() => deletePage(page.checkout_page_slug)}>
            <DeleteOutline />
          </button>
          <button onClick={() => openPublicCheckoutPage(page)}>
            <VisibilityIcon />
          </button>
          <button onClick={() => navigateToCheckoutPage(page)}>
            <EditIcon />
          </button>
        </div>
        <div className="panel-body">
          <div className="body-section created">
            <h3>{getDateLabel(page)}</h3>
            <div>{getDateValue(page)}</div>
          </div>
          <div className="body-section volume">
            <h3>Volume</h3>
            <div>
              {page.volume > 0
                ? getWeightUnit({
                    weightType: account?.display_kg ? 'kg' : 'lbs',
                    value: page.volume,
                    roundUnits: 2,
                    format: '%v %u CO₂e',
                  })
                : '-'}
            </div>
            {/* hide for now until transaction page has query params for tag filtering.
              page.volume > 0 && <button><ExternalLinkIcon />All transactions</button>*/}
          </div>
          <div className="body-section url">
            <h3>Public URL</h3>
            <div>
              <a
                className="checkout-page-link"
                href={checkoutPageUrl}
                target="_blank"
                rel="noreferrer"
              >
                {checkoutPageUrl}
              </a>
            </div>
            <CopyButton
              placement="top"
              buttonLabel="Copy page link"
              textToCopy={checkoutPageUrl}
            />
          </div>
        </div>
      </div>
    </>
  )
})

export default PageListItem
