/**
 * Location where registry logos are stored.
 */
const ratingLogoBaseUrl: string = 'https://imagedelivery.net/OeX1-Pzk5r51De534GGSBA/'

/**
 * Rating item properties
 */
type RatingItem = {
  logo: string;
  title: string
}

/**
 * A map of known ratings
 */
const ratingsMap: Map<string, RatingItem> = new Map([
  ['BeZero', <RatingItem>{
    logo: '1d2788b0-3623-48d6-3ceb-9ffac57cd600/public',
    title: 'BeZero Rating'
  }]
])

/**
 * Exports public defs.
 */
export {
  ratingLogoBaseUrl,
  ratingsMap
}
