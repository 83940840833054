import React from 'react'

const IdCardIcon = () => (
  <svg
    fill="none"
    height="39"
    viewBox="0 0 57 39"
    width="57"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m52.7529 39h-48.4952c-2.35238 0-4.2577-1.9053-4.2577-4.2576v-30.48476c0-2.35235 1.90532-4.25764 4.2577-4.25764h48.4952c2.3524 0 4.2577 1.90529 4.2577 4.25764v30.49536c-.0106 2.3417-1.9159 4.247-4.2577 4.247z"
        fill="#fff"
      />
      <g fill="#004453">
        <path d="m52.7529 1.59662c1.469 0 2.6611 1.19214 2.6611 2.66102v30.49536c0 1.4689-1.1921 2.661-2.6611 2.661h-48.4952c-1.4689 0-2.65042-1.1921-2.65042-2.661v-30.49536c-.01064-1.46888 1.18152-2.66102 2.65042-2.66102zm0-1.59662h-48.4952c-2.35238 0-4.2577 1.90529-4.2577 4.25764v30.49536c0 2.3524 1.90532 4.2576 4.24706 4.2576h48.49524c2.3524 0 4.2577-1.9052 4.2577-4.2576v-30.49536c0-2.35235-1.9053-4.25764-4.2471-4.25764z" />
        <path d="m49.0594 28.6865h-17.9249c-.4364 0-.7983-.3619-.7983-.7983 0-.4365.3619-.7984.7983-.7984h17.9249c.4364 0 .7984.3619.7984.7984 0 .4364-.362.7983-.7984.7983z" />
        <path d="m42.0662 12.0068h-10.9317c-.4364 0-.7983-.3619-.7983-.7983s.3619-.7983.7983-.7983h10.9317c.4364 0 .7983.3619.7983.7983s-.3619.7983-.7983.7983z" />
        <path d="m44.3653 17.5732h-13.2308c-.4364 0-.7983-.3619-.7983-.7983s.3619-.7983.7983-.7983h13.2415c.4364 0 .7983.3619.7983.7983s-.3619.7983-.809.7983z" />
        <path d="m38.9681 23.1279h-7.7596c-.4364 0-.7983-.3619-.7983-.7983 0-.4365.3619-.7984.7983-.7984h7.7596c.4365 0 .7984.3619.7984.7984 0 .4364-.3619.7983-.7984.7983z" />
      </g>
      <path
        d="m20.7462 28.929h-8.7602c-1.288 0-2.33109-1.0431-2.33109-2.3311v-3.0122c0-3.7574 3.04429-6.8123 6.81229-6.8123 3.6617 0 6.6208 2.9697 6.6208 6.6207v3.2038c-.0107 1.288-1.0538 2.3311-2.3418 2.3311z"
        fill="#00cc8a"
      />
      <path
        d="m20.7457 29.7287h-8.7602c-1.7244 0-3.12942-1.405-3.12942-3.1293v-3.0123c0-4.1938 3.41682-7.6105 7.61062-7.6105 4.0874 0 7.4191 3.3316 7.4191 7.4189v3.2039c-.0107 1.7243-1.4157 3.1293-3.1401 3.1293zm-4.2896-12.1555c-3.3104 0-6.014 2.6929-6.014 6.0139v3.0123c0 .8515.6919 1.5327 1.5327 1.5327h8.7603c.8515 0 1.5434-.6918 1.5434-1.5327v-3.2039c0-3.2145-2.6079-5.8223-5.8224-5.8223z"
        fill="#004453"
      />
      <path
        d="m16.3606 18.3703c2.5984 0 4.7048-2.1063 4.7048-4.7047 0-2.5983-2.1064-4.70466-4.7048-4.70466-2.5983 0-4.7047 2.10636-4.7047 4.70466 0 2.5984 2.1064 4.7047 4.7047 4.7047z"
        fill="#00cc8a"
      />
      <path
        d="m16.3601 19.1701c-3.0336 0-5.503-2.4695-5.503-5.503 0-3.0336 2.4694-5.50304 5.503-5.50304 3.0337 0 5.5031 2.46944 5.5031 5.50304 0 3.0335-2.4694 5.503-5.5031 5.503zm0-9.40942c-2.1501 0-3.9064 1.74562-3.9064 3.90642 0 2.1607 1.7457 3.9064 3.9064 3.9064 2.1608 0 3.9065-1.7457 3.9065-3.9064 0-2.1608-1.7457-3.90642-3.9065-3.90642z"
        fill="#004453"
      />
    </g>
  </svg>
)

export default IdCardIcon
