import React, { useEffect, useState } from 'react'
import { usePortfolioCalculations } from 'model/hooks/marketplace/usePortfolioCalculations'
import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'
import PropTypes from 'prop-types'
import FolderOpen from 'view/components/icons/folder-open-icon/FolderOpenIcon'
import ProjectPanelAmount from 'view/pages/marketplace/components/panels/project-panel-view/components/ProjectPanelAmount'
import ProjectPanelButton from 'view/pages/marketplace/components/panels/project-panel-view/components/ProjectPanelButton'
import './ProjectPanelView.scss'
import ProjectPanelVintageInfo from 'view/pages/marketplace/components/panels/project-panel-view/components/ProjectPanelVintageInfo'

const ProjectPanelView = ({
  project: product,
  handleClick,
  handleLeadGenNotify,
  costPerTonneFormatted,
  getMinCostPerTonneFormatted,
  isNotEnoughInventory,
  handleAddToCart,
  projectImage,
  userInputData,
  isDisplayRealAmount,
  tooltipAmount,
  totalAmount,
}) => {
  const { generateProjectWeightPercentages } = usePortfolioCalculations()
  const [vintageCount, setVintageCount] = useState(undefined)

  const isAmountMoreThanZero =
    userInputData?.amount !== 0 && userInputData?.amount !== ''

  useEffect(() => {
    setVintageCount(product?.all_tranches?.length || undefined)
  }, [product])

  const getNumberOfProjectsInPortfolio = product => {
    if (!product || product.productType !== ProductType.PORTFOLIO)
      return '0 projects'
    const projectCount = product.offset_sources?.length || 0
    return projectCount === 1 ? '1 project' : `${projectCount} projects`
  }

  const handleAddToCartLocal = e => {
    handleAddToCart(e)
    e.preventDefault()
    e.target.blur()
  }

  const renderProductBadge = product => {
    let projectMix
    if (product.productType === ProductType.PORTFOLIO) {
      let { projectMixArray } = generateProjectWeightPercentages(product)
      projectMix = projectMixArray
    }

    switch (product.productType) {
      case ProductType.PROJECT:
        return (
          <div onClick={handleClick} className="project-type-container">
            {product?.type}
          </div>
        )
      case ProductType.PORTFOLIO:
        return (
          <div className="portfolio-type-container">
            <div onClick={handleClick} className="portfolio-tag">
              <FolderOpen />
              Portfolio
            </div>
            {projectMix?.length > 0 && (
              <div className="project-type-items-container">
                {projectMix.map((projectType, idx) => (
                  <>
                    <div className="item-row center-align">1</div>
                    <div
                      className="item-row"
                      key={`${product.slug}item-${idx}}`}
                    >
                      {projectType.type}
                    </div>
                    <div className="item-row right-align">
                      {projectType.mixPercentageFormatted}
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <section className="project-panel-view">
      {projectImage ? (
        <img
          onClick={handleClick}
          className="project-panel-image"
          src={projectImage}
          alt={product?.offset_source_slug}
        />
      ) : (
        <div onClick={handleClick} className="project-panel-image" />
      )}
      {renderProductBadge(product)}
      <section className="project-panel-bottom-half">
        <section onClick={handleClick} className="project-panel-details">
          <section className="project-name-and-description">
            <h4 className="project-name">
              {product?.short_name || product?.name}
            </h4>
            <p>{product?.short_description}</p>
          </section>
          {product.productType === ProductType.PROJECT && (
            <div className="location-and-vintage-year">
              <span className="project-location">{product?.country}</span>
              <ProjectPanelVintageInfo
                is_purchasable={product?.is_purchasable}
                vintageCount={vintageCount}
              />
            </div>
          )}
          {product.productType === ProductType.PORTFOLIO && (
            <div className="location-and-vintage-year">
              {getNumberOfProjectsInPortfolio(product)}
            </div>
          )}
        </section>
        <section className="project-panel-button-and-amount">
          <ProjectPanelButton
            isPurchasable={product?.is_purchasable}
            isNotEnoughInventory={isNotEnoughInventory}
            onClickLeadGenNotify={handleLeadGenNotify}
            onClick={handleAddToCartLocal}
          />
          <section className="amount">
            <ProjectPanelAmount
              is_purchasable={product?.is_purchasable !== false}
              tooltipAmount={tooltipAmount}
              isDisplayRealAmount={isDisplayRealAmount}
              isAmountMoreThanZero={isAmountMoreThanZero}
              totalAmount={totalAmount}
              costPerTonneFormatted={costPerTonneFormatted}
              getMinCostPerTonneFormatted={getMinCostPerTonneFormatted}
            />
          </section>
        </section>
      </section>
    </section>
  )
}

ProjectPanelView.propTypes = {
  project: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  totalAmount: PropTypes.string,
  costPerTonneFormatted: PropTypes.string,
  getMinCostPerTonneFormatted: PropTypes.string,
  hasInventoryError: PropTypes.bool,
  handleAddToCart: PropTypes.func,
  projectImage: PropTypes.string,
  userInputData: PropTypes.object,
  isDisplayRealAmount: PropTypes.bool,
  tooltipAmount: PropTypes.string,
}

export default ProjectPanelView
