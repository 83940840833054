import React, { ReactNode, useState } from 'react'
import ExpandButton from 'view/components/buttons/expand-button/ExpandButton'

type Props = {
  panelHeader: (expanded: boolean) => ReactNode
  children: ReactNode
}

const ExpandablePanel = ({ panelHeader, children }: Props) => {
  const [expanded, setExpanded] = useState(false)

  const expand = () => !expanded && setExpanded(true)
  const collapse = () => expanded && setExpanded(false)

  return (
    <section
      onClick={expand}
      className="p-4 overflow-hidden flex flex-col border border-neutral-80 rounded-xl gap-2"
    >
      <div
        className="flex gap-3 justify-between items-center cursor-pointer"
        onClick={collapse}
      >
        {panelHeader(expanded)}
        <ExpandButton expanded={expanded} className="h-fit px-0 py-0" />
      </div>
      {expanded && <>{children}</>}
    </section>
  )
}

export default ExpandablePanel
