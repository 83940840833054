import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import FullDescriptionSection from './sections/FullDescriptionSection'
import LocationSection from './sections/LocationSection'
import ProjectMediaSection from './sections/ProjectMediaSection'
import RatingsSection from './sections/RatingsSection'
import RegistrySection from './sections/RegistrySection'
import TechnologySection from './sections/TechnologySection'
import UNSDGSection from './sections/UNSDGSection'

type Props = {
  project: Project
  projectDescriptionHtml: string | null
  styles: any
}

const MainColumn = ({ project, projectDescriptionHtml, styles }: Props) => {
  /**
   * @returns {React.JSX.Element}
   */
  const PageHeader = () => (
    <View
      style={{ fontSize: 9, fontWeight: 700 }}
      fixed
      render={({ pageNumber }) =>
        pageNumber > 1 && (
          <Text style={styles.pageHeaderText}>
            Full Description (continued)
          </Text>
        )
      }
    />
  )

  /**
   * @returns {React.JSX.Element}
   */
  const PageFooter = () => (
    <>
      <Text
        fixed
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
      />
      <Text fixed style={styles.footerRight}>
        For more information, contact sales@cloverly.com
      </Text>
    </>
  )

  return (
    <>
      <PageHeader />
      <TechnologySection project={project} styles={styles} />
      <RatingsSection project={project} styles={styles} />
      <RegistrySection project={project} styles={styles} />
      <UNSDGSection project={project} styles={styles} />
      <LocationSection project={project} styles={styles} />
      <ProjectMediaSection project={project} styles={styles} />
      <FullDescriptionSection
        projectDescriptionHtml={projectDescriptionHtml}
        styles={styles}
      />
      <PageFooter />
    </>
  )
}

export default MainColumn
