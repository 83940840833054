import React, { useEffect, useState } from 'react'
import { setPageTitle } from 'model/utils/page'
import { Link } from 'react-router-dom'
import Button from 'view/components/button/Button'
import FormContainer from 'view/components/form-container/FormContainer'
import PublicPageWrapper from 'view/components/public-page-wrapper/PublicPageWrapper'
import ResetPasswordForm from './components/ResetPasswordForm'
import Submitted from '../send-email/components/Submitted'

const ResetPassword = () => {
  const [submitted, setSubmitted] = useState(false)
  const url = new URL(window.location.href)
  const isNewAccount = url.searchParams.get('new_account') === 'true'
  const pageTitle = isNewAccount ? 'Create Account' : 'Reset Password'

  useEffect(() => {
    setPageTitle(pageTitle)
    // eslint-disable-next-line
  }, [])

  return (
    <PublicPageWrapper cn={'reset-password-wrapper'}>
      {submitted ? (
        <Submitted
          title={'Password Updated'}
          info={'You may now login with your new password'}
        >
          <Button cn={'form-btn login'}>
            <Link to="/login">Log In</Link>
          </Button>
        </Submitted>
      ) : (
        <FormContainer cloverlyLogo={true} title={pageTitle} footer={false}>
          <ResetPasswordForm setSubmitted={setSubmitted} />
        </FormContainer>
      )}
    </PublicPageWrapper>
  )
}

export default ResetPassword
