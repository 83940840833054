import React, { useState } from 'react'
import Button from 'view/components/button/Button'
import CreditCardsIcon from 'view/components/icons/credit-cards-icon/CreditCardsIcon'
import '../../../AccountSettings.scss'
import AddCardModal from '../../modals/AddCardModal'
import AccountSettingsSubnav from '../../navs/AccountSettingsSubnav'

const BillingNoCardPage = () => {
  const [showAddCard, setShowAddCard] = useState(false)

  return (
    <>
      <AddCardModal
        showAddCard={showAddCard}
        setShowAddCard={bool => setShowAddCard(bool)}
      />
      <div className="account-settings">
        <h1>Account Settings</h1>
        <AccountSettingsSubnav />
        <div className="no-card-on-file">
          <div className="circle">
            <CreditCardsIcon />
          </div>
          <h2>Add a card to begin</h2>
          <p>
            Carbon credits purchased through our platform will be charged to
            this card.
          </p>
          <Button onClick={() => setShowAddCard(true)}>Add a card</Button>
        </div>
      </div>
    </>
  )
}

export default BillingNoCardPage
