/* eslint-disable import/order */
import * as Sentry from '@sentry/react'
import 'bootstrap/dist/css/bootstrap.min.css'
import ConfigProvider from 'antd/lib/config-provider'
import mapboxgl from 'mapbox-gl'
import { queryClient } from 'model/utils/queryClient'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { themeMain } from 'view/styles/theme'
import 'mapbox-gl/dist/mapbox-gl.css'
import App from './App'
import 'view/styles/tailwind.scss'
import 'view/styles/index.scss'

window.React = React

mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

if (process.env.REACT_APP_USE_SENTRY) {
  Sentry.init({
    dsn: 'https://ca5d7ef4940842c6908494fb51989f3d@o742054.ingest.sentry.io/6099374',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ConfigProvider theme={themeMain}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </BrowserRouter>
    </QueryClientProvider>
  </ConfigProvider>
)
