import React from 'react'
import Logo from '../cloverly-logo/Logo'
import './PoweredByCloverly.scss'

const PoweredByCloverly = () => {
  return (
    <div className="powered-by-cloverly-logo">
      <span>Powered by</span>
      <a
        href="https://www.cloverly.com"
        target="_blank"
        rel="noopener noreferrer">
        <Logo />
      </a>
    </div>
  )
}

export default PoweredByCloverly
