import React from 'react'
import { useService } from 'model/hooks/useService'
import { Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'

const SendEmailForm = ({ setSubmitted }) => {
  const { register, errors, handleSubmit, getValues } = useForm()
  const { serviceRequest } = useService()

  const errorMessages = {
    required: 'This field is required',
  }

  const submitForm = () => {
    const data = getValues()
    serviceRequest({
      path: '/password',
      method: 'POST',
      data: { password: { email: data.email } },
    }).then(setSubmitted(true))
  }

  return (
    <div className="user-form">
      <form onSubmit={handleSubmit(submitForm)} data-testid="email-form">
        <Form.Group as={Col} controlId="validationCustom01">
          <Form.Label>Email</Form.Label>
          <Form.Control
            data-testid="email-input"
            type="email"
            isInvalid={errors?.email}
            {...register('email', { required: errorMessages.required })}
          />
          {errors?.email && (
            <p data-testid="error-message" className="invalid-message">
              {errors?.email.message}
            </p>
          )}
        </Form.Group>
        <Button cn={'form-btn dark'} type={'submit'}>
          Send Reset Email
        </Button>
      </form>
    </div>
  )
}

export default SendEmailForm
