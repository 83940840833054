import { useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'

export const useAccountUsers = () => {
  const { account } = useUser()
  const { serviceRequest } = useService()
  const [users, setUsers] = useState(undefined)
  const { infoToast, warningToast } = useToast()

  /**
   * @param asParentAccount
   */
  const loadUsers = (asParentAccount = false) => {
    loadUsersRequest(asParentAccount).then(data => setUsers(data))
  }

  /**
   * @param email
   * @param role
   * @returns {Promise<boolean>}
   */
  const addUser = async (email, role) =>
    handleStandardResponse(await addUserRequest(email, role), false, false)

  /**
   * @param user
   * @param asParentAccount
   * @returns {Promise<boolean>}
   */
  const deleteUser = async (user, asParentAccount = false) =>
    handleStandardResponse(
      await deleteUserRequest(user, asParentAccount),
      asParentAccount
    )

  /**
   * @param user
   * @param newRole
   * @returns {Promise<boolean>}
   */
  const changeUserRole = async (user, newRole) =>
    handleStandardResponse(await changeUserRoleRequest(user, newRole))

  /**
   * @param data
   * @param asParentAccount
   * @param reloadUsers
   * @returns {Promise<boolean>}
   */
  const handleStandardResponse = async (
    data,
    asParentAccount = false,
    reloadUsers = true
  ) => {
    if (data?.success) {
      infoToast(data?.success)
      if (reloadUsers) loadUsers(asParentAccount)
      return true
    } else {
      warningToast(data.error)
      return false
    }
  }

  /**
   * @returns {Promise<*>}
   */
  const loadUsersRequest = async asParentAccount =>
    serviceRequest({
      path: asParentAccount ? '/api/children' : '/api/users',
    })

  /**
   *
   * @param email
   * @param role
   * @returns {Promise<*>}
   */
  const addUserRequest = async (email, role) =>
    serviceRequest({
      path: `/dashboard/accounts/${account.slug}/account_users`,
      method: 'POST',
      data: {
        account_user: {
          user: { email },
          role,
        },
      },
    })

  /**
   * @param user
   * @param asParentAccount
   * @returns {Promise<*>}
   */
  const deleteUserRequest = async (user, asParentAccount = false) =>
    serviceRequest({
      path: asParentAccount
        ? `/api/children/${user.id}`
        : `/dashboard/accounts/${account.slug}/account_users/${user.id}`,
      method: 'DELETE',
    })

  /**
   * @param user
   * @param newRole
   * @returns {Promise<*>}
   */
  const changeUserRoleRequest = async (user, newRole) =>
    serviceRequest({
      path: `/dashboard/accounts/${account.slug}/account_users/${user.id}`,
      method: 'PATCH',
      data: {
        account_user: {
          role: newRole,
        },
      },
    })

  return { users, loadUsers, addUser, deleteUser, changeUserRole }
}
