import React from 'react'

const EnvelopeIcon = () => (
  <svg
    fill="none"
    height="37"
    viewBox="0 0 39 37"
    width="39"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m.519775 14.6888 17.562825-13.45776c1.2614-.966725 3.0149-.931951 4.2417.08346l16.1558 13.3812v18.9382h-37.960325z"
        fill="#00cc8a"
      />
      <path
        d="m38.4802 34.1483h-37.960385c-.284165 0-.519815-.2364-.519815-.5216v-18.9381c0-.16.0762395-.313.200995-.4173l17.569705-13.457732c1.4555-1.119737 3.4655-1.078008 4.8863.090413l16.1559 13.381219c.1178.0974.1871.2434.1871.4034v18.9381c0 .2852-.2287.5216-.5198.5216zm-37.44057-1.0432h36.92077v-18.1662l-15.9688-13.22815c-1.0396-.862408-2.5159-.890227-3.5901-.06955l-17.36187 13.3047z"
        fill="#004453"
      />
      <path
        d="m30.1284 31.0465h-21.25693c-1.53172 0-2.77235-1.2449-2.77235-2.7819v-22.70774c0-1.53703 1.24063-2.78196 2.77235-2.78196h21.25693c1.5318 0 2.7724 1.24493 2.7724 2.78196v22.70774c0 1.537-1.2406 2.7819-2.7724 2.7819z"
        fill="#fff"
      />
      <g fill="#004453">
        <path d="m30.1293 3.82595c.9565 0 1.7258.77894 1.7258 1.73176v22.70769c0 .9598-.7762 1.7387-1.7258 1.7387h-21.25695c-.95646 0-1.72579-.7789-1.72579-1.7387v-22.70769c0-.95977.77626-1.73176 1.72579-1.73176zm0-1.04324h-21.25695c-1.53172 0-2.76542 1.24493-2.76542 2.775v22.70769c0 1.5371 1.24063 2.782 2.76542 2.782h21.25695c1.5317 0 2.7654-1.2449 2.7654-2.782v-22.70769c.007-1.53007-1.2337-2.775-2.7654-2.775z" />
        <path d="m25.9561 10.4739h-6.4526c-.2842 0-.5199-.2365-.5199-.52162 0-.28515.2357-.52162.5199-.52162h6.4526c.2842 0 .5198.23647.5198.52162 0 .28512-.2356.52162-.5198.52162z" />
        <path d="m27.3145 14.1116h-7.811c-.2842 0-.5199-.2365-.5199-.5216 0-.2852.2357-.5216.5199-.5216h7.811c.2842 0 .5199.2364.5199.5216 0 .2851-.2357.5216-.5199.5216z" />
        <path d="m24.1264 17.6375h-4.5744c-.2841 0-.5198-.2365-.5198-.5216 0-.2852.2357-.5217.5198-.5217h4.5744c.2842 0 .5198.2365.5198.5217 0 .2851-.2287.5216-.5198.5216z" />
        <path d="m14.2846 16.3784v1.1545h-1.0258v-1.1198c-1.0189-.0625-1.9892-.3825-2.5575-.8276l.5614-1.2588c.5129.3825 1.2545.6607 1.9892.7302v-1.7178c-1.1367-.2782-2.412-.6607-2.412-2.1352 0-1.0919.7901-2.03079 2.412-2.21857v-1.1406h1.0257v1.12669c.7694.06259 1.5179.27124 2.0862.62594l-.5129 1.26574c-.5267-.299-1.0604-.4729-1.5733-.5355v1.7527c1.1367.2712 2.3912.6468 2.3912 2.1003.0069 1.0711-.7694 1.9961-2.3842 2.1978zm-1.0258-4.5555v-1.4605c-.5476.1252-.7624.4242-.7624.765 0 .3547.3049.5495.7624.6955zm1.7674 2.4829c0-.3616-.2981-.5564-.7416-.7094v1.4188c.5128-.1113.7416-.3825.7416-.7094z" />
      </g>
      <path
        d="m.519775 15.0083v19.2581c0 1.2171.984185 2.2116 2.204015 2.2116h33.67711c1.1505 0 2.0862-.9389 2.0862-2.0934v-19.3763l-16.7103 11.406c-.9288.6329-2.1486.6607-3.1051.0557z"
        fill="#00cc8a"
      />
      <path
        d="m36.394 36.9997h-33.6771c-1.49707 0-2.7169-1.224-2.7169-2.7332v-19.2581c0-.1878.103963-.3617.270304-.459.166341-.0974.367336-.0835.526746.0139l18.14505 11.4616c.7762.4938 1.7743.4729 2.5367-.0417l16.7103-11.406c.1594-.1113.3673-.1183.5337-.0278.1732.0904.2772.2642.2772.459v19.3763c0 1.4397-1.1713 2.615-2.606 2.615zm-35.35437-21.0455v18.3123c0 .9319.75547 1.683 1.67727 1.683h33.6771c.8664 0 1.5664-.7024 1.5664-1.5718v-18.3817l-15.8994 10.8496c-1.1021.7511-2.5437.7789-3.6734.0626z"
        fill="#004453"
      />
    </g>
  </svg>
)

export default EnvelopeIcon
