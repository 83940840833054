import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Button } from 'react-bootstrap'

const ShopifyFormControls = ({ editMode, setEditMode, cancelChanges }) => (
  <div className="control-bar">
    {!editMode && (
      <>
        <Button onClick={() => setEditMode(true)} className="edit">
          <EditIcon />
          Edit
        </Button>
      </>
    )}
    {editMode && (
      <>
        <Button onClick={() => cancelChanges()} className="cancel">
          Cancel
        </Button>
        <Button type="submit" className="save">
          Save
        </Button>
      </>
    )}
  </div>
)

export default ShopifyFormControls
