import React from 'react'
import ReactMarkdown from 'react-markdown'

type Props = {
  shortDescription?: string
}

const PortfolioOverview = ({ shortDescription }: Props) => {
  return (
    <>
      {shortDescription ? (
        <section className="rounded-xl border border-neutral-80 p-4 flex flex-col gap-4">
          <p className="text-lg font-bold">Overview</p>
          <ReactMarkdown className="react-markdown">
            {shortDescription}
          </ReactMarkdown>
        </section>
      ) : null}
    </>
  )
}

export default PortfolioOverview
