import React from 'react'
import { capFirstLetter } from 'model/utils/capitalize-first-letter'
import './ShortDetails.scss'

const ShortDetail = ({ title, children }) => {
  return (
    <div className="short-detail">
      {title && <h6>{capFirstLetter(title)}</h6>}
      <div className="short-detail-children">{children}</div>
    </div>
  )
}

export default ShortDetail
