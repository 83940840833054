import React, { useMemo } from 'react'
import { Text, View } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { ratingLogoBaseUrl, ratingsMap } from 'model/utils/ratings'
import MainColumnSection from './MainColumnSection'
import SVGPDFImage from '../../common-components/SVGPDFImage'

type Props = {
  project: Project
  styles: any
}

const RatingsSection = ({ project, styles }: Props) => {
  /**
   * @type {*|*[]}
   */
  const projectRatings = useMemo(
    () =>
      project?.project_ratings
        ? project?.project_ratings.filter(rating =>
            ratingsMap.has(rating.rating_title)
          )
        : [],
    [project?.project_ratings]
  )

  /**
   * @param {object} project
   * @returns {React.JSX.Element}
   */
  const ProjectRegistryNameOrLogo = ({ source }: { source: string }) => (
    <View style={styles.ratingBodyCell}>
      <SVGPDFImage url={ratingLogoBaseUrl + source} />
    </View>
  )

  /**
   * @param ratingsList
   * @returns {React.JSX.Element}
   */
  const RatingsList = ({ ratingsList }: { ratingsList: any[] }) => (
    <View style={styles.tableContainer}>
      {ratingsList.map((ratingObj, index) => (
        <RatingsListItem
          key={`ratings-item-${index}`}
          ratingObj={ratingObj}
          index={index}
        />
      ))}
    </View>
  )

  /**
   * @param unsdg
   * @returns {React.JSX.Element}
   */
  const RatingsListItem = ({
    ratingObj,
    index,
  }: {
    ratingObj: any
    index: number
  }) => {
    const rating = ratingsMap.get(ratingObj.rating_title)
    if (!rating) return null
    return (
      <View key={`rating-${index}`} style={styles.tableCell}>
        <Text style={styles.tableHeader}>{rating.title}</Text>
        <View style={styles.ratingBodyCell}>
          {rating.logo && ProjectRegistryNameOrLogo({ source: rating.logo })}
          <Text>{ratingObj.rating}</Text>
        </View>
      </View>
    )
  }

  return (
    !!project &&
    !!projectRatings.length && (
      <MainColumnSection title="Ratings & Standards" styles={styles}>
        <RatingsList ratingsList={projectRatings} />
      </MainColumnSection>
    )
  )
}

export default RatingsSection
