import React, { useEffect, useReducer, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { setPageTitle } from 'model/utils/page'
import { NavLink } from 'react-router-dom'
import Button from 'view/components/button/Button'
import '../../AccountSettings.scss'
import AddCreditsModal from '../modals/AddCreditsModal'
import CreditTable from '../tables/CreditTable'

const CreditPage = () => {
  const [showAddCredits, setShowAddCredits] = useState(false)
  const [state, dispatch] = useReducer(reducer, {})
  const [, setTableData] = useState({ credits: [], totalPages: 1 })
  const [tableCache, setTableCache] = useState([])
  const [page, setPage] = useState(0)
  const changePage = ({ selected }) => setPage(selected)
  const { account } = useUser()
  const [slug, setSlug] = useState(account.slug)
  const { serviceRequest } = useService()

  useEffect(() => {
    setPageTitle('Credits | Account Settings')
  }, [])

  useEffect(() => {
    if (slug !== account.slug) {
      setTableCache([])
    }
    if (tableCache[page] && slug === account.slug) {
      setTableData(tableCache[page])
    } else {
      setSlug(account.slug)
      serviceRequest({
        path: `/api/credits?page=${page + 1}&limit=10`,
      }).then(tableData =>
        dispatch({
          type: 'setCredits',
          tableData,
        })
      )
    }
    // eslint-disable-next-line
  }, [page, account])

  function reducer(state, action) {
    switch (action.type) {
      case 'setCredits':
        state.tableData = action.tableData
        break
    }
    return { ...state }
  }

  return (
    <>
      <AddCreditsModal
        showAddCredits={showAddCredits}
        setShowAddCredits={bool => setShowAddCredits(bool)}
      />
      <div className="account-settings">
        <div className="sub-page-header">
          <div className="left-col left-col-bottom-spacer">
            <NavLink to="/account-settings/billing">
              <ArrowBackIcon /> Back
            </NavLink>
            <h1>Credits</h1>
          </div>
          <div className="right-col add-credits-btn-container">
            <Button className="" onClick={() => setShowAddCredits(true)}>
              Add Credits
            </Button>
          </div>
        </div>
        <CreditTable tableData={state.tableData} changePage={changePage} />
      </div>
    </>
  )
}

export default CreditPage
