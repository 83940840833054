import './DashboardTable.scss'

const getDashboardTableColumns = columns => {
  const formatAccessors = (value, substituteValue) =>
    value || substituteValue.replace(/\s/g, '').toLowerCase()

  const placeholder = '-'

  return columns?.map(column => {
    const titleText =
      (typeof column.title === 'string' && column.title) || placeholder

    return {
      title: () => (
        <span className="column-title">{column.title || placeholder}</span>
      ),
      dataIndex: formatAccessors(column.dataIndex, titleText),
      key: formatAccessors(column.key, titleText),
      onCell: column.onCell || undefined,
      className: column.className,
      defaultSortOrder: column.defaultSortOrder || 'descend',
      sorter: column.sorter || undefined,
      render: column.render || undefined,
      width: column.width || undefined,
      ellipsis: true,
      fixed: column.fixed,
      align: column.align || 'left',
    }
  })
}

export default getDashboardTableColumns
