/*
 * A collection of product types that are available in the marketplace.
 */
export const ProductType: {
  PROJECT: string,
  PORTFOLIO: string,
  TRANCHE: string
} = {
  PROJECT: 'PROJECT',
  PORTFOLIO: 'PORTFOLIO',
  TRANCHE: 'TRANCHE'
}

/*
 * A collection of listing modes available in the marketplace.
 * Eventually, we're going to add a "Show All" listing mode.
 */
export const MarketPlaceListingModes: {
  PROJECTS: string,
  PORTFOLIOS: string
} = {
  PROJECTS: 'Projects',
  PORTFOLIOS: 'Portfolios'
}

/*
 * A collection of weighting methods for portfolios.
 */
export const WeightingMethod: {
  CUSTOM: string,
  EVEN: string
} = {
  CUSTOM: 'custom',
  EVEN: 'even'
}

/*
 * A collection of various estimation issues.
 */
export const EstimateErrorType: {
  UNKNOWN: string,
  LOW_INVENTORY: string,
  PURCHASE_AMOUNT_TOO_SMALL: string
} = {
  UNKNOWN: 'UNKNOWN',
  LOW_INVENTORY: 'LOW_INVENTORY',
  PURCHASE_AMOUNT_TOO_SMALL: 'PURCHASE_AMOUNT_TOO_SMALL'
}
