import React, { useMemo } from 'react'
import { Text, View } from '@react-pdf/renderer'
import { Project, ProjectTranche } from 'interfaces/Project.interface'
import { handleCalculateCostPerTonne } from 'model/utils/cost'
import { dynamicSort } from 'model/utils/dynamicSort'
import Spacer from '../../common-components/Spacer'

type Props = {
  project: Project
  defaultCurrency: string
  currencyRates: object
  styles: any
}

const InventoryPanel = ({
  project,
  styles,
  defaultCurrency,
  currencyRates,
}: Props) => {
  /**
   * @type {string}
   */
  const vintageCount = useMemo(() => {
    if (!project?.all_tranches?.length) return ''
    project.all_tranches.sort(dynamicSort('remaining_micro_units', false))
    project.all_tranches.sort(dynamicSort('vintage_year', false, true))
    return `${project.all_tranches.length} vintage${project.all_tranches.length === 1 ? '' : 's'}`
  }, [project?.all_tranches])

  if (!project?.all_tranches?.length) return null

  const ProjectInventoryRow = ({
    tranche,
    i,
  }: {
    tranche: ProjectTranche
    i: number
  }) => {
    const cost = `${handleCalculateCostPerTonne(
      tranche?.cost_per_kg_carbon_in_usd_cents * 10,
      defaultCurrency,
      currencyRates
    )} / t`
    return (
      <View
        key={`inventory-item-${i}`}
        style={
          i === 0 ? styles.projectInventoryRowFirst : styles.projectInventoryRow
        }
      >
        <Text style={styles.projectInventoryYear}>{tranche.vintage_year}</Text>
        {!tranche?.is_issued && (
          <View style={styles.projectInventoryForward}>
            <Text>Forward</Text>
          </View>
        )}
        <Spacer />
        <Text style={styles.projectInventoryCost}>{cost}</Text>
      </View>
    )
  }

  return (
    <View style={styles.projectInventorySection}>
      <View style={styles.projectInventoryHeader}>
        <Text style={styles.projectInventoryHeaderHeavy}>
          Available Inventory
        </Text>
        <Text style={styles.projectInventoryHeaderLight}>{vintageCount}</Text>
      </View>
      {project.all_tranches.map((tranche, i) =>
        ProjectInventoryRow({ tranche, i })
      )}
    </View>
  )
}

export default InventoryPanel
