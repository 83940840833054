/**
 * @type {{
 * mapboxAccessToken: string,
 * mapStyle: string,
 * markerOffset: number[]
 * }}
 */

type Props = {
  mapboxAccessToken?: string
  mapStyle: string
  markerOffset: mapboxgl.PointLike
  staticMapApiHost?: string
  mapStaticStyle?: string
  staticMarker?: string
}

const mapConfig: Props = {
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  mapStyle: 'mapbox://styles/cloverly/ckykicffr64sj14qp56qaxksj',
  staticMapApiHost: 'https://api.mapbox.com',
  mapStaticStyle: '/v1/cloverly/ckykicffr64sj14qp56qaxksj',
  staticMarker: 'pin-l+1A3F4C',
  markerOffset: [0, -20.5],
}

/**
 * Exports public defs.
 */
export { mapConfig }
