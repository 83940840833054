import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AllApiCallsPage from './components/pages/AllApiCallsPage'
import DebuggerDetailsPage from './components/pages/DebuggerDetailsPage'
import DeveloperToolsPage from './components/pages/DeveloperToolsPage'

const DeveloperTools = () => (
  <Routes>
    <Route exact path="/" element={<DeveloperToolsPage />} />
    <Route exact path="/api-debugger/all-calls" element={<AllApiCallsPage />} />
    <Route
      exact
      path="/api-debugger/details/:id"
      element={<DebuggerDetailsPage />}
    />
  </Routes>
)

export default DeveloperTools
