import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { WebMercatorViewport } from '@math.gl/web-mercator'
import { useMediaQuery } from '@mui/material'
import { mapConfig } from 'model/utils/react-map'
import ReactMapGL, { Marker, Popup } from 'react-map-gl'
import PinIcon from 'view/components/icons/pin-icon/PinIcon'
import ProjectCard from '../project-card/ProjectCard'
import './SustainabilityReportMap.scss'

const SustainabilityReportMap = ({ projects }) => {
  const smallScreen = useMediaQuery('(max-width:900px)')

  const getMapStyle = () => 'mapbox://styles/cloverly/ckyx5v3yx000315mnoru09kic'
  const [pinPoints, setPinPoints] = useState([])
  const defaultViewport = { latitude: 20, longitude: 0, zoom: 0.5 }
  const [viewport, setViewport] = useState(defaultViewport)
  const [selectedPin, setSelectedPin] = useState(defaultViewport)
  const [project, setProject] = useState()
  const [showProductPanelOnMap, setShowProductPanelOnMap] = useState(false)

  const cardRef = useRef(null)
  const clicked = useRef(0)
  const firstUpdate = useRef(true)

  useEffect(() => {
    const pinPoints = []
    if (projects)
      projects.forEach(project =>
        pinPoints.push({
          latitude: project.location.x,
          longitude: project.location.y,
        })
      )
    if (pinPoints.length > 1) setViewport(getBoundsForPoints(pinPoints))
    else if (pinPoints.length === 1)
      setViewport({
        latitude: pinPoints[0].latitude,
        longitude: pinPoints[0].longitude,
        zoom: 9,
      })
    else setViewport(defaultViewport)
    setPinPoints(pinPoints)
    // eslint-disable-next-line
  }, [projects])

  //skips the inital render, so that the popup is not displayed on initial render without the user selecting a pinpoint
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    setShowProductPanelOnMap(true)
  }, [selectedPin])

  const applyToArray = (func, array) => func.apply(Math, array)

  const getBoundsForPoints = points => {
    const pointsLong = points.map(point => point.longitude)
    const pointsLat = points.map(point => point.latitude)
    return new WebMercatorViewport({
      width: cardRef?.current?.offsetWidth - 48 || 600,
      height: 480,
    }).fitBounds(
      [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
      ],
      { padding: 100 }
    )
  }

  //memoize the pins to optimize in case too many pinpoints exist
  const markerPinPoints = useMemo(() =>
    projects?.map(
      project => {
        return (
          <Marker
            key={project?.id}
            latitude={project.location.x}
            longitude={project.location.y}
            offset={mapConfig.markerOffset}
            onClick={() => {
              clicked.current = project?.id
              setSelectedPin({
                latitude: project.location.x,
                longitude: project.location.y,
              })
              setProject(project)
            }}
          >
            <PinIcon clicked={clicked.current === project?.id} />
          </Marker>
        )
      },
      [pinPoints]
    )
  )

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: selectedPin.latitude,
      longitude: selectedPin.longitude,
    })
    // eslint-disable-next-line
  }, [selectedPin])

  const mobileHeight = () => (smallScreen ? '300px' : '500px')
  const minZoom = () => (smallScreen ? 0.5 : 1.5)

  return (
    <div
      className="sus-report-map-container"
      style={{ height: mobileHeight() }}
    >
      <ReactMapGL
        {...viewport}
        maxZoom={8}
        minZoom={minZoom()}
        mapStyle={getMapStyle()}
        mapboxAccessToken={mapConfig.mapboxAccessToken}
        onMove={evt => setViewport(evt.viewState)}
        onViewportChange={nextViewport => setViewport(nextViewport)}
      >
        {
          // display all markers on the map
          markerPinPoints
        }
        {showProductPanelOnMap && (
          <Popup
            onClose={() => setShowProductPanelOnMap(false)}
            longitude={selectedPin.longitude}
            latitude={selectedPin.latitude}
            anchor={undefined}
            closeButton={false}
            closeOnClick={false}
          >
            <ProjectCard
              project={project}
              partOfModal={false}
              setProject={setProject}
            />
          </Popup>
        )}
      </ReactMapGL>
    </div>
  )
}

export default SustainabilityReportMap
