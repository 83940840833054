import React, { useEffect, useState } from 'react'
import Skeleton from 'antd/lib/skeleton'
import { ProjectTranche } from 'interfaces/Project.interface'
import { formatCost, handleCalculateCostPerTonne } from 'model/utils/cost'
import { dynamicSort } from 'model/utils/dynamicSort'
import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'
import Button from 'view/components/button/Button'
import CalculatorErrorMessage from 'view/components/calculator-input-group/CalculatorErrorMessage'
import CalculatorInputGroup from 'view/components/calculator-input-group/CalculatorInputGroup'
import FolderOpen from 'view/components/icons/folder-open-icon/FolderOpenIcon'
import CloverlyTooltip from 'view/shamrock/tooltip/Tooltip'
import VintageList from './VintageList'
import { useMarketplaceContext } from '../../../MarketplaceContext'
import './VintageSelector.scss'

type Props = {
  projectEstimate: any
  handleAction: (e: any) => void
  maxHeightEnabled?: boolean
  listTitle?: string
}

const VintageSelector = ({
  listTitle = 'Inventory',
  projectEstimate,
  handleAction,
  maxHeightEnabled = false,
}: Props) => {
  const [vintageList, setVintageList] = useState([])

  const {
    project,
    hasInventoryError,
    estimateErrorType,
    isEstimationLoading,
    selectedVintage,
    setSelectedVintage,
    defaultCurrency,
    currencyRates,
  } = useMarketplaceContext()

  useEffect(() => {
    if (project && project.all_tranches) {
      // sort tranches by most remaining_micro_units first, then sort by vintage_year
      project.all_tranches.sort(dynamicSort('remaining_micro_units', false))
      project.all_tranches.sort(dynamicSort('vintage_year', false, true))

      if (project.all_tranches !== vintageList)
        setVintageList(project.all_tranches)

      // ensure that the current selected vintage belongs to this project
      let currentSelectedVintage
      if (selectedVintage) {
        project.all_tranches.forEach((tranche: ProjectTranche) => {
          if (tranche.id === selectedVintage.id) {
            currentSelectedVintage = tranche
          }
        })
      }

      // if we don't have a selected vintage at this point, then assign the active vintage in the all tranches array
      if (!currentSelectedVintage) {
        project?.all_tranches.forEach((tranche: ProjectTranche) => {
          if (tranche.id === project.active_tranche.id)
            setSelectedVintage(tranche)
        })
      }
    }
  }, [project])

  const addToCart = (e: any) => {
    e.target.blur()
    handleAction(e)
  }

  const SelectVintageTooltipMessage = () => (
    <div>
      The vintage of a credit represents the year within which the credit
      <br />
      was produced. When there are repeating vintages on a project, this is
      <br />
      most likely due to multiple issuances within the same year. These are
      <br />
      sometimes priced differently because of different delivery dates.
      <br />
      <br />
      For more questions, please reach out to support@cloverly.com.
    </div>
  )

  return (
    <div className="vintage-selector">
      <section className="add-to-cart">
        {project?.productType === ProductType.PORTFOLIO ? (
          <>
            <div className="cart-header">
              <h3>{listTitle}</h3>
            </div>
            <div className="portfolio-info">
              <div className="info-tag portfolio">
                <FolderOpen />
                <div>Portfolio</div>
              </div>
              {project.total_remaining_units && (
                <div className="info-text">
                  {project.total_remaining_units / 1000} t available
                </div>
              )}
              <div className="price">
                {handleCalculateCostPerTonne(
                  project.price_per_tonne_in_USD,
                  defaultCurrency,
                  currencyRates
                )}{' '}
                / t
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="cart-header">
              <h3>{listTitle}</h3>
              <div className="sub-header flex items-center gap-1">
                <label>Select a vintage</label>
                {/* <AppTooltip
                  placement="top"
                  id="select-vintage-tooltip"
                  isOutlined={false}
                  message={<SelectVintageTooltipMessage />}
                /> */}
                <CloverlyTooltip>
                  <SelectVintageTooltipMessage />
                </CloverlyTooltip>
              </div>
            </div>
            <VintageList
              vintageList={vintageList}
              selectedVintage={selectedVintage}
              setSelectedVintage={setSelectedVintage}
              maxHeightEnabled={maxHeightEnabled}
            />
          </>
        )}

        <div className="divider"></div>
        <CalculatorInputGroup
          placeholder="Enter amount"
          classname="project-details-calculator"
          shortTonneLabel={true}
          selectedProduct={project}
          mode={
            project?.productType === ProductType.PORTFOLIO
              ? ProductType.PORTFOLIO
              : ProductType.TRANCHE
          }
        />

        {!isEstimationLoading && hasInventoryError && (
          <label className="error">
            <CalculatorErrorMessage
              productType={project.productType}
              estimateErrorType={estimateErrorType}
            />
          </label>
        )}

        <div className="result-and-btn-v2">
          {!isEstimationLoading &&
          projectEstimate?.total_cost &&
          !hasInventoryError ? (
            <div className="total-row">
              <label className="total">Total: </label>
              <label className="cost">
                {formatCost(projectEstimate?.total_cost, defaultCurrency)}
              </label>
            </div>
          ) : isEstimationLoading ? (
            <div className="total-row">
              <label className="total">Total: </label>
              <Skeleton.Button className="text-skeleton large" active={true} />
            </div>
          ) : (
            <></>
          )}

          <Button
            cn="purchase-btn"
            disabled={
              isEstimationLoading ||
              hasInventoryError ||
              !projectEstimate?.total_cost
            }
            onClick={addToCart}
          >
            {isEstimationLoading ? 'Calculating estimate...' : 'Add to Order'}
          </Button>
        </div>
      </section>
    </div>
  )
}

export default VintageSelector
