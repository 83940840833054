import React from 'react'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { formatCostInCurrency } from 'model/utils/cost'
import { download } from 'model/utils/download'
import { useNavigate } from 'react-router-dom'
import DashboardTable from 'view/components/table/DashboardTable'
import getDashboardTableColumns from 'view/components/table/getDashboardTableColumns'

const downloadCertificate = (
  event,
  transactionId,
  infoToast,
  serviceRequest
) => {
  event.preventDefault()
  event.stopPropagation()
  serviceRequest({
    path: `/transaction/${transactionId}/certificate`,
    method: 'GET',
    options: { responseType: 'arraybuffer' },
  })
    .then(certificate => {
      infoToast('Certificate Downloaded.')
      download(
        certificate,
        `Purchase Certificate - ${transactionId} .pdf`,
        'application/pdf'
      )
    })
    .catch(e => console.log(e))
}

const ProjectList = ({ transactions }) => {
  const downloadText = 'Download'
  const { infoToast } = useToast()
  const { serviceRequest } = useService()
  const navigate = useNavigate()

  const columns = getDashboardTableColumns([
    {
      title: <span className="header-styles">Project</span>,
      dataIndex: 'project',
      width: '',
      className: 'portfolio-project-column',
    },
    {
      title: <span className="header-styles">Vintage</span>,
      dataIndex: 'vintage',
      width: '86px',
      className: 'portfolio-column',
    },
    {
      title: <span className="header-styles">{'Carbon (kg)'}</span>,
      dataIndex: 'carbon',
      width: '116px',
      className: 'portfolio-column',
    },
    {
      title: <span className="header-styles">Cost</span>,
      dataIndex: 'cost',
      key: 'cost',
      width: '86px',
      className: 'portfolio-column',
    },
  ])

  const formattedProjects = transactions.map(transaction => {
    const purchaseId = transaction.certificate_url?.substring(
      transaction.certificate_url.indexOf('=') + 1,
      transaction.certificate_url.length
    )

    return {
      key: transaction.project?.id,
      project: (
        <div
          className="title-cell"
          onClick={() =>
            navigate(`/marketplace/project-details/${transaction.project?.id}`)
          }
        >
          <img
            className="project-image"
            src={transaction?.image_list && transaction?.image_list[0]?.url}
            alt=""
          />
          <div className="info">
            <span className="title">{transaction.project?.name}</span>
            <span className="type">{transaction.project?.type}</span>
          </div>
        </div>
      ),
      vintage: transaction?.project?.tranche?.vintage_year,
      carbon: transaction?.total_co2e_in_kg,
      cost: formatCostInCurrency(
        transaction?.cost?.in_requested_currency?.total_cost || 0,
        transaction?.cost?.requested_currency || 'USD',
        false,
        true
      ),
      certificate: (
        <button
          className="download-certificate"
          onClick={event =>
            downloadCertificate(event, purchaseId, infoToast, serviceRequest)
          }
        >
          {downloadText}
        </button>
      ),
    }
  })

  return <DashboardTable columns={columns} dataSource={formattedProjects} />
}

export default ProjectList
