import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDetectClickOutside } from 'model/hooks/useDetectClickOutside'
import { useToast } from 'model/hooks/useToast'
import {
  handleIsNotEnoughInventory,
  handleIsUnderMinimum,
} from 'model/utils/direct-checkout'
import { toast } from 'react-toastify'
import Button from 'view/components/button/Button'
import ContactUsButton from 'view/components/buttons/contact-us-button/ContactUsButton'
import ChooseAmountButtons from './ChooseAmountButtons'
import CustomInput from './CustomInput'
import { useCheckoutQueryParamContext } from '../../contexts/CheckoutQueryParamContext'
import { useEstimateCalculation } from '../../contexts/useEstimateCalculation'
import PurchaseDetails from '../purchase-details/PurchaseDetails'
import './ChooseAmountWidget.scss'

const ChooseAmountWidget = ({
  project,
  setIsReadyToEstimate,
  setAmountValue,
  amount,
}) => {
  const {
    weightUnit,
    shopBy,
    currency,
    minPurchaseAmount,
    minimumPurchaseWarningToast,
  } = useCheckoutQueryParamContext()

  const { infoToast } = useToast()
  const toastId = useRef()
  const notEnoughInvToast = useRef()
  const btnRef = useRef()

  const { estimate, costInUSD, minimumAmount } = useEstimateCalculation()

  useDetectClickOutside(btnRef, () => setIsReadyToEstimate(false))

  const [isCustom, setIsCustom] = useState(false)
  const [isNotEnoughInventory, setIsNotEnoughInventory] = useState(false)

  //amount value setter for the ChooseAmount Buttons
  const handleChange = e => {
    if (e === 'Custom') {
      setAmountValue(0)
      setIsCustom(true)
    } else {
      setIsCustom(false)
      setAmountValue(e)
    }
  }

  const isDisplayWeightUnitInParanthesis = () =>
    shopBy === 'carbon' ? `(${weightUnit})` : ''

  const determineIfNotEnoughInventory = useCallback(() => {
    return handleIsNotEnoughInventory(shopBy, weightUnit, estimate, project)
    // eslint-disable-next-line
  }, [
    estimate.total_weight,
    project?.available_carbon_in_kg,
    weightUnit,
    shopBy,
  ])

  const determineIfUnderMinimumAmount = useCallback(() => {
    if (!costInUSD) return null
    return handleIsUnderMinimum(costInUSD, minPurchaseAmount / 100)
  }, [costInUSD, minPurchaseAmount])

  const isDisplayPurchaseDetails =
    (amount > 0 && !determineIfUnderMinimumAmount()) ||
    (shopBy === 'carbon' && amount > 0)

  //disable checkout btn and display an info toast for when not enough inventory is available
  useEffect(() => {
    const abortController = new AbortController()
    if (!isNaN(amount)) {
      if (determineIfNotEnoughInventory()) {
        setIsNotEnoughInventory(true)
        if (!toast.isActive(notEnoughInvToast.current))
          notEnoughInvToast.current = infoToast('Not enough inventory', null)
      } else if (!determineIfNotEnoughInventory()) {
        setIsNotEnoughInventory(false)
        if (toast.isActive(notEnoughInvToast.current))
          toast.dismiss(notEnoughInvToast.current)
      }
    }
    return () => abortController.abort()
  }, [determineIfNotEnoughInventory, amount])

  //disable checkout btn and display a warning toast when amount is under $3 (default) or a custom minimum amount
  useEffect(() => {
    const abortController = new AbortController()
    if (!isNaN(costInUSD) && costInUSD > 0) {
      if (determineIfUnderMinimumAmount() && !toast.isActive(toastId.current))
        toastId.current = minimumPurchaseWarningToast(minimumAmount, currency)
      else if (
        !determineIfUnderMinimumAmount() &&
        toast.isActive(toastId.current)
      )
        toast.dismiss(toastId.current)
    }
    return () => abortController.abort()
    // eslint-disable-next-line
  }, [determineIfUnderMinimumAmount, costInUSD])

  return (
    <div className="choose-amount-widget">
      <h5>Choose amount {isDisplayWeightUnitInParanthesis()}</h5>
      <ChooseAmountButtons
        amount={amount}
        onValueChange={handleChange}
        isCustom={isCustom}
      />
      {isCustom && <CustomInput setAmount={setAmountValue} />}
      {isDisplayPurchaseDetails && (
        <PurchaseDetails
          project={project}
          amount={amount}
          estimate={estimate}
          hideCost={true}
        />
      )}
      {isNotEnoughInventory ? (
        <ContactUsButton
          cn={`${isDisplayPurchaseDetails ? 'displaying-details' : ''}`}
        />
      ) : (
        <Button
          ref={btnRef}
          disabled={
            amount <= 0 ||
            determineIfNotEnoughInventory() ||
            determineIfUnderMinimumAmount()
          }
          cn={`${isDisplayPurchaseDetails ? 'displaying-details' : ''}`}
          onClick={() => setIsReadyToEstimate(true)}
        >
          Checkout
        </Button>
      )}
    </div>
  )
}

export default ChooseAmountWidget
