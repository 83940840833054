import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

type Props = {
  expanded: boolean
}

const CCPHeader = ({ expanded }: Props) => {
  return (
    <>
      <div className={`flex gap-2 ${expanded ? 'w-1/2' : 'w-1/3'}`}>
        <p className="font-semibold min-w-fit">Core Carbon Principles (CCP)</p>
      </div>
      {!expanded && (
        <div className="flex gap-1 items-center w-1/3">
          <CheckCircleOutlineIcon className="text-[#1F9552] size-[18px]" />
          <p>Certified</p>
        </div>
      )}
    </>
  )
}

export default CCPHeader
