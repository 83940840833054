import React from 'react'

const Warning = () => (
  <svg
    width="30"
    height="26"
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.333252 26H29.6666L14.9999 0.666687L0.333252 26ZM16.3333 22H13.6666V19.3334H16.3333V22ZM16.3333 16.6667H13.6666V11.3334H16.3333V16.6667Z"
      fill="white"
    />
  </svg>
)

export default Warning
