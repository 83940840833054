import { datadogRum } from '@datadog/browser-rum'

/**
 * Initializes & starts DataDog Monitoring.
 * https://docs.datadoghq.com/real_user_monitoring/browser/
 */
export const initializeAndStartDataDogMonitoring = (env, version) => {
  // initialize
  datadogRum.init({
    applicationId: '4f533e1f-0be4-4aa2-abc8-a3b8a402253f',
    clientToken: 'pub2d90ae9e303af106814a4b0a768c1f17',
    site: 'datadoghq.com',
    service: 'clovery-app-react',
    env,
    version,
    sampleRate: 100,
    premiumSampleRate: 100, // if not included, the default is 100
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [
      'https://api-stag.cloverly.com',
      'https://api-stag-no-cert.cloverly.com',
      'https://api-prod.cloverly.com',
      'https://api-prod-no-cert.cloverly.com',
    ],
  })
  // start recording
  datadogRum.startSessionReplayRecording()
}
