import React from 'react'

const WidgetDesignIcon = () => (
  <svg
    fill="none"
    height="92"
    viewBox="0 0 148 92"
    width="148"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12.0207 83.8976v-79.92587c0-1.5887 1.3035-2.88854 2.8966-2.88854h118.1657c1.593 0 2.896 1.29984 2.896 2.88854v79.92587c0 1.5887-1.303 2.8886-2.896 2.8886h-118.1657c-1.5931 0-2.8966-1.2854-2.8966-2.8886z"
      fill="#e2e2e2"
    />
    <path
      d="m133.083 87.8694h-118.1657c-2.2014 0-3.9828-1.7765-3.9828-3.9718v-79.92586c0-2.19529 1.7959-3.97174 3.9828-3.97174h118.1657c2.201 0 3.982 1.77645 3.982 3.97174v79.92586c0 2.1953-1.781 3.9718-3.982 3.9718zm-118.1657-85.703c-.9993 0-1.8103.8088-1.8103 1.80534v79.92586c0 .9966.811 1.8054 1.8103 1.8054h118.1657c.999 0 1.81-.8088 1.81-1.8054v-79.92586c0-.99654-.811-1.80534-1.81-1.80534z"
      fill="#004453"
    />
    <path d="m128.81 8.31897h-109.852v69.57053h109.852z" fill="#fff" />
    <path
      d="m128.825 78.9727h-109.867c-.5938 0-1.0862-.4911-1.0862-1.0832v-69.57052c0-.59215.4924-1.0832 1.0862-1.0832h109.852c.594 0 1.086.49105 1.086 1.0832v69.57052c.015.6066-.477 1.0832-1.071 1.0832zm-108.7808-2.1664h107.6948v-67.40412h-107.6948z"
      fill="#004453"
    />
    <path
      d="m1.08621 89.4725v-3.8995c0-.7944.65173-1.4443 1.44828-1.4443h142.93151c.796 0 1.448.6499 1.448 1.4443v3.8995c0 .7944-.652 1.4443-1.448 1.4443h-142.93151c-.79655 0-1.44828-.6499-1.44828-1.4443z"
      fill="#e2e2e2"
    />
    <path
      d="m145.466 92h-142.93151c-1.39035 0-2.53449-1.141-2.53449-2.5275v-3.8995c0-1.3865 1.14414-2.5275 2.53449-2.5275h142.93151c1.39 0 2.534 1.141 2.534 2.5275v3.8995c0 1.3865-1.13 2.5275-2.534 2.5275zm-142.93151-6.7881c-.20276 0-.36207.1589-.36207.3611v3.8995c0 .2022.15931.3611.36207.3611h142.93151c.202 0 .362-.1589.362-.3611v-3.8995c0-.2022-.16-.3611-.362-.3611z"
      fill="#004453"
    />
    <path d="m109.505 24.4515h-29.0819v7.568h29.0819z" fill="#fff" />
    <path
      d="m109.519 33.1171h-29.0959c-.5938 0-1.0862-.491-1.0862-1.0832v-7.5679c0-.5922.4924-1.0832 1.0862-1.0832h29.0819c.593 0 1.086.491 1.086 1.0832v7.5679c.014.5922-.478 1.0832-1.072 1.0832zm-28.0097-2.1664h26.9087v-5.4015h-26.9087z"
      fill="#004453"
    />
    <path d="m67.3451 24.4515h-29.0815v37.3055h29.0815z" fill="#f5fafa" />
    <path
      d="m67.3596 62.8402h-29.096c-.5938 0-1.0862-.4911-1.0862-1.0832v-37.3055c0-.5922.4924-1.0832 1.0862-1.0832h29.0815c.5938 0 1.0863.491 1.0863 1.0832v37.3055c.0144.6066-.478 1.0832-1.0718 1.0832zm-28.0098-2.1664h26.9091v-35.1391h-26.9091z"
      fill="#004453"
    />
    <path d="m109.505 38.9086h-29.0819v7.568h29.0819z" fill="#00cc8a" />
    <path
      d="m109.519 47.5598h-29.0959c-.5938 0-1.0862-.491-1.0862-1.0832v-7.568c0-.5921.4924-1.0832 1.0862-1.0832h29.0819c.593 0 1.086.4911 1.086 1.0832v7.568c.014.5922-.478 1.0832-1.072 1.0832zm-28.0097-2.1664h26.9087v-5.4016h-26.9087z"
      fill="#004453"
    />
    <path d="m109.505 54.189h-29.0819v7.568h29.0819z" fill="#fff" />
    <path
      d="m109.519 62.8402h-29.0959c-.5938 0-1.0862-.491-1.0862-1.0832v-7.568c0-.5921.4924-1.0832 1.0862-1.0832h29.0819c.593 0 1.086.4911 1.086 1.0832v7.568c.014.6066-.478 1.0832-1.072 1.0832zm-28.0097-2.1664h26.9087v-5.4016h-26.9087z"
      fill="#004453"
    />
  </svg>
)

export default WidgetDesignIcon
