import devResponse from 'data/custom-terms/custom-terms-dev.json'
import localResponse from 'data/custom-terms/custom-terms-local.json'
import prodResponse from 'data/custom-terms/custom-terms-prod.json'
import stageResponse from 'data/custom-terms/custom-terms-stage.json'
import { Project } from 'interfaces/Project.interface'

/**
 * Get the starting price response based on the environment
 * @param env
 */
const getStartingPriceResponse = ({
  env,
}: {
  env?: string
}): any[] | undefined => {
  if (!env) return undefined
  switch (env) {
    case 'local':
      return localResponse
    case 'dev':
      return devResponse
    case 'stage':
      return stageResponse
    case 'prod':
      return prodResponse
    default:
      return undefined
  }
}

/**
 * Get the starting price project map
 * @param startingPriceResponse
 */
const getStartingPriceProjectMap = (
  startingPriceResponse: any[] | undefined
): Map<string, number> | undefined =>
  startingPriceResponse
    ? new Map<string, number>(
        startingPriceResponse.map(item => [
          item.id,
          item.starting_price_per_tonne_in_usd,
        ])
      )
    : undefined

/**
 * Get the project starting price project map
 */
const startingPriceProjectMap = getStartingPriceProjectMap(
  getStartingPriceResponse({
    env: process.env.REACT_APP_ENV?.toLowerCase(),
  })
)

/**
 * For a given project id, get the starting price
 * @param id
 */
export const getProjectStartingPriceProjectMap = (
  id: string
): number | undefined => startingPriceProjectMap?.get(id)

/**
 * Get the minimum cost per tonne in usd for a given project based on either the starting price or the tranches
 * @param project
 */
export const getMinCostPerTonneUsd = (project: Project): number | undefined => {
  // if the starting price is set, return it
  const startingPrice = getProjectStartingPriceProjectMap(project.id)
  if (startingPrice) return startingPrice
  // if the starting price is set, but set to zero, return undefined
  if (startingPrice === 0) return undefined
  // at this point, if there are no tranches, return undefined
  if (!project?.all_tranches?.length) return undefined
  // return the minimum cost per kg carbon in usd cents
  return (
    project?.all_tranches?.reduce((acc, tranche) => {
      if (tranche.cost_per_kg_carbon_in_usd_cents < acc) {
        return tranche.cost_per_kg_carbon_in_usd_cents
      }
      return acc
    }, Infinity) * 10
  )
}

/**
 * Convert project estimate to quote.
 */
export const convertEstimateToQuote = (
  selectedVintage: any,
  projectEstimate: any
) => ({
  vintageYear: selectedVintage?.vintage_year,
  vintageCost: selectedVintage?.cost_per_kg_carbon_in_usd_cents * 10,
  isIssued: selectedVintage?.is_issued,
  weightType: projectEstimate?.weight_type,
  totalWeightInTonnes: projectEstimate?.total_weight / 1000,
  totalCost: projectEstimate?.total_cost,
  currencyType: projectEstimate?.currency_type,
})
