import React from 'react'

type Props = {
  className?: string
}

const ExternalLinkIcon = ({ className }: Props) => (
  <svg
    fill="none"
    className={className}
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m12.25 12.25h-10.5v-10.5h5.25v-1.5h-5.25c-.82875 0-1.5.67125-1.5 1.5v10.5c0 .8287.67125 1.5 1.5 1.5h10.5c.8287 0 1.5-.6713 1.5-1.5v-5.25h-1.5zm-3.75-12v1.5h2.6887l-7.37245 7.3725 1.06125 1.0613 7.3725-7.37255v2.68875h1.5v-5.25z" />
  </svg>
)

export default ExternalLinkIcon
