import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CheckoutCreatePage from './components/pages/checkout-create-page/CheckoutCreatePage'
import CheckoutHomePage from './components/pages/checkout-home-page/CheckoutHomePage'
import { PaymentProvider } from './contexts/CheckoutContext'

const Checkout = () => (
  <PaymentProvider>
    <Routes>
      <Route exact path="/" element={<CheckoutHomePage />} />
      <Route exact path="/new" element={<CheckoutCreatePage />} />
      <Route
        exact
        path="/edit/:checkout_page_slug"
        element={<CheckoutCreatePage />}
      />
    </Routes>
  </PaymentProvider>
)

export default Checkout
