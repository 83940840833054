import React from 'react'

const ProjectDataField = ({ title, data }) => {
  return (
    <>
      {data && data?.length > 0 ? (
        <div className="project-data-field">
          <p className="title">{title}</p>
          <p className="data">{data}</p>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default ProjectDataField
