import React from 'react'

const StorePaysIcon = () => (
  <svg
    fill="none"
    height="57"
    viewBox="0 0 66 57"
    width="66"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m51.3201 45.82h-47.73001c-.41 0-.75-.34-.75-.75v-44.32c0-.41.33-.75.75-.75h47.74001c.41 0 .75.34.75.75v44.32c-.01.42-.34.75-.76.75zm-46.98001-1.5h46.24001v-42.82h-46.24001z"
        fill="#004453"
      />
      <path d="m53.46.75h-52.71v14.45h52.71z" fill="#fff" />
      <g fill="#004453">
        <path d="m53.46 15.95h-52.71c-.41 0-.75-.34-.75-.75v-14.45c0-.41.34-.75.75-.75h52.71c.41 0 .75.34.75.75v14.45c0 .41-.33.75-.75.75zm-51.96-1.5h51.21v-12.95h-51.21z" />
        <path d="m51.3301 37.83h-47.74001v1.5h47.74001z" />
        <path d="m53.46 7.21997h-52.71v1.5h52.71z" />
      </g>
      <path d="m18.96 45.07v-13.02-8.47h16.99v21.49z" fill="#fff" />
      <path
        d="m35.95 45.82h-16.99c-.41 0-.75-.34-.75-.75v-21.49c0-.41.34-.75.75-.75h16.98c.41 0 .75.34.75.75v21.49c.01.42-.33.75-.74.75zm-16.24-1.5h15.49v-19.99h-15.49z"
        fill="#004453"
      />
      <path
        d="m50.29 56.23c8.113 0 14.69-6.577 14.69-14.69 0-8.1131-6.577-14.69-14.69-14.69-8.1131 0-14.69 6.5769-14.69 14.69 0 8.113 6.5769 14.69 14.69 14.69z"
        fill="#00cc8a"
      />
      <path
        d="m50.29 56.97c-8.51 0-15.44-6.92-15.44-15.44s6.93-15.43 15.44-15.43 15.44 6.92 15.44 15.44-6.92 15.43-15.44 15.43zm0-29.37c-7.68 0-13.94 6.25-13.94 13.94s6.25 13.94 13.94 13.94 13.94-6.25 13.94-13.94-6.25-13.94-13.94-13.94z"
        fill="#004453"
      />
      <path
        d="m51.1199 47.98v1.94h-1.4199v-1.92c-1.7-.1-3.35-.66-4.25-1.44l.7799-1.75c.85.69 2.1501 1.2 3.4701 1.32v-3.47c-1.92-.47-3.9901-1.09-3.9901-3.5 0-1.77 1.2801-3.31 3.9901-3.57v-1.96h1.4199v1.92c1.28.07 2.5801.45 3.52 1.06l-.7099 1.75c-.92-.54-1.8901-.83-2.8101-.94v3.5c1.92.47 4.02 1.07 4.02 3.48 0 1.78-1.3 3.31-4.02 3.58zm-1.4199-7.42v-3.09c-1.23.19-1.75.81-1.75 1.58 0 .84.74 1.22 1.75 1.51zm3.2199 4.01c0-.85-.76-1.25-1.8-1.54v3.07c1.26-.2 1.8-.79 1.8-1.53z"
        fill="#f9f9f9"
      />
    </g>
  </svg>
)

export default StorePaysIcon
