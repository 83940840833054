import React, { useState } from 'react'
import { Tooltip } from 'antd/lib'
import { useNavbarContext } from 'model/contexts/NavbarContext'
import { useUser } from 'model/hooks/account/useUser'
import CollapsedOptions from './CollapsedOptions'
import ExpandedOptions from './ExpandedOptions'

const NavbarUserOptions = () => {
  const { user, account } = useUser()
  const {
    menuOpen,
    toggleExpandedOptionsWhenNavCollapsed,
    showExpandedOptionsWhenNavCollapsed,
  } = useNavbarContext()

  const [expandOptions, setExpandOptions] = useState(false)

  const username = user ? `${user.first_name} ${user.last_name}` : '-'
  const companyName = account?.name ?? '-'

  const toggleExpandOptions = () => {
    if (menuOpen) setExpandOptions(prev => !prev)
    else toggleExpandedOptionsWhenNavCollapsed()
  }

  const Options = () => (
    <div
      className={`flex justify-between items-center rounded-md p-2 text-neutral-20 border border-neutral-90 hover:bg-neutral-95 cursor-pointer active:bg-secondary-95 ${menuOpen ? 'w-[184px]' : ''} ${
        expandOptions || showExpandedOptionsWhenNavCollapsed
          ? 'bg-secondary-95'
          : ''
      }`}
      onClick={toggleExpandOptions}
    >
      <CollapsedOptions openMenu={menuOpen} />
    </div>
  )

  return (
    <section className="px-2">
      {expandOptions && menuOpen && (
        <ExpandedOptions toggleShow={toggleExpandOptions} />
      )}
      {!menuOpen && !showExpandedOptionsWhenNavCollapsed ? (
        <Tooltip
          color="#1A3F4C"
          overlayInnerStyle={{
            backgroundColor: '#1A3F4C',
            color: '#FFFFFF',
            fontWeight: 500,
            fontSize: 14,
            borderRadius: 6,
            padding: '8px 12px',
          }}
          placement="right"
          title={
            <span className="flex flex-col">
              <p className="text-sm font-semibold">{username}</p>
              <p className="text-xs text-neutral-80">{companyName}</p>
            </span>
          }
        >
          <button className="bg-transparent ">
            <Options />
          </button>
        </Tooltip>
      ) : (
        <button className={`bg-transparent ${menuOpen ? 'w-full' : ''}`}>
          <Options />
        </button>
      )}
    </section>
  )
}

export default NavbarUserOptions
