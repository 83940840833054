import React from 'react'
import AppsIcon from '@mui/icons-material/Apps'
import PinDropIcon from '@mui/icons-material/PinDrop'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { MarketPlaceListingModes } from 'model/utils/marketplace/MarketplaceEnums'
import {
  durabilityFilterList,
  mechanismFilterList,
  unsdgFilterList,
} from 'model/utils/project/projectFilters'
import { Dropdown } from 'react-bootstrap'
import CloseIcon from 'view/components/icons/close-icon/CloseIcon'
import FilterDropdown from './components/filter-dropdown/FilterDropdown'
import { useMarketplaceContext } from '../../MarketplaceContext'
import '../../Marketplace.scss'

const ListControlsPanel = () => {
  const {
    sortOptions,
    listView,
    setListView,
    setSortOrder,
    sortLabel,
    setFilterType,
    filterType,
    setFilterValue,
    marketplaceListingMode,
  } = useMarketplaceContext()

  const filterTypes = [
    'Project Type',
    'Registry',
    'UN SDG',
    'Removal Time Period',
    'Mechanism',
  ]

  //create a function which takes in "filterType" as a param and returns an array of options
  const handleLoadFilterOptions = () => {
    let listOfOptions = []
    let storedProjects = JSON.parse(localStorage.getItem('products')) || []
    switch (filterType) {
      case 'Project Type':
        listOfOptions = generateOptions(storedProjects, 'type')
        break
      case 'Registry':
        listOfOptions = generateOptions(storedProjects, 'registry_name')
        break
      case 'UN SDG':
        listOfOptions = unsdgFilterList
        break
      case 'Removal Time Period':
        listOfOptions = durabilityFilterList
        break
      case 'Mechanism':
        listOfOptions = mechanismFilterList
        break
      default:
        return listOfOptions
    }
    return listOfOptions
  }

  const generateOptions = (projects, fieldToEvaluate) => {
    let optionsToPopulate = []
    if (Array.isArray(projects)) {
      projects.forEach(project => {
        // check for validity, string length and uniqueness
        if (
          project[fieldToEvaluate] &&
          !!project[fieldToEvaluate]?.length &&
          !optionsToPopulate.includes(project[fieldToEvaluate]) &&
          !['project', 'tranche'].includes(project[fieldToEvaluate])
        )
          optionsToPopulate.push(project[fieldToEvaluate])
      })
      // sort by registry
      optionsToPopulate.sort((a, b) => a.localeCompare(b))
    }
    return optionsToPopulate
  }

  const handleSetFilterType = eventKey => {
    setFilterType(eventKey)
    setFilterValue(null)
  }

  const filterTypesByListingMode = (marketplaceListingMode, type) =>
    !(
      marketplaceListingMode === MarketPlaceListingModes.PORTFOLIOS &&
      type === 'Removal Time Period'
    )

  return (
    <div className="list-controls">
      <div className="left-section">
        <div className="dropdown-container">
          <Dropdown value={filterType} onSelect={handleSetFilterType}>
            <Dropdown.Toggle className="standard-dropdown">
              <span>{filterType ?? 'Filter by'}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {filterTypes
                .filter(type =>
                  filterTypesByListingMode(marketplaceListingMode, type)
                )
                .map(type => (
                  <Dropdown.Item key={type} eventKey={type}>
                    {type}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {filterType && (
          <>
            <FilterDropdown options={handleLoadFilterOptions()} />
            <button
              className="standard-dropdown clear-filters"
              onClick={() => setFilterType(null)}
            >
              Clear filters
              <CloseIcon />
            </button>
          </>
        )}
      </div>
      <div className="right-section">
        {listView !== 'map' && (
          <Dropdown
            defaultValue="price-desc"
            onSelect={eventKey => setSortOrder(eventKey)}
          >
            <Dropdown.Toggle className="light-dropdown">
              <SwapVertIcon />
              <span>{sortLabel}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {sortOptions?.map((item, idx) => (
                <Dropdown.Item key={`sort-${idx}`} eventKey={item.value}>
                  {item.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {marketplaceListingMode === MarketPlaceListingModes.PROJECTS && (
          <>
            <span className="the-word-view">View: </span>
            <div className="tab-buttons">
              <button
                className={`tab-button ${
                  listView === 'grid' ? 'active-tab' : ''
                }`}
                onClick={() => setListView('grid')}
              >
                <div className="button-content">
                  <AppsIcon /> <span>Grid</span>
                </div>
              </button>
              <button
                className={`tab-button ${
                  listView === 'map' ? 'active-tab' : ''
                }`}
                onClick={() => setListView('map')}
              >
                <div className="button-content">
                  <PinDropIcon /> <span>Map</span>
                </div>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ListControlsPanel
