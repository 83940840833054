import React from 'react'

const TruckIcon = () => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 71 48"
    width="71"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m64.96 26.7h-14.91v-14.65h5.36c2.92-.08 5.48 1.76 6.43 4.5zm-13.41-1.5h11.38l-2.51-8.18c-.72-2.07-2.7-3.47-4.91-3.47-.03 0-.0499 0-.08 0h-3.8699v11.65z"
        fill="#004453"
      />
      <path
        d="m41.96 42.48h-39.21c-1.52 0-2.75-1.23-2.75-2.75v-36.98c0-1.52 1.23-2.75 2.75-2.75h39.21c1.52 0 2.75 1.23 2.75 2.75v36.98c0 1.52-1.23 2.75-2.75 2.75zm-39.21-40.98c-.69 0-1.25.56-1.25 1.25v36.98c0 .69.56 1.25 1.25 1.25h39.21c.69 0 1.25-.56 1.25-1.25v-36.98c0-.69-.56-1.25-1.25-1.25z"
        fill="#004453"
      />
      <path
        d="m26.4899 16.12c1.08-2.6.35-4.69-2.37-6.04-2.72-1.34997-9.41-2.15997-10.44 1.08-.87 2.72 4.57 6.08 7.04 7.11 1.86.78 4.6.68 5.77-2.15z"
        fill="#00cc8a"
      />
      <path
        d="m22.74 19.44c-.76 0-1.55-.16-2.31-.48-1.87-.78-8.61-4.43-7.46-8.03.31-.97999 1.06-1.73999 2.16-2.20999 2.75-1.17 7.26-.34 9.33.68 3.02 1.48999 3.99 3.97999 2.73 6.99999-.53 1.28-1.42 2.21-2.56 2.68-.59.24-1.23.36-1.89.36zm-4.31-9.77999c-1.01 0-1.97.13-2.72.44999-.71.3-1.14.72-1.32 1.29-.63 1.99 3.97 5.08 6.61 6.19 1.03.43 2.17.47 3.05.11.78-.32 1.37-.95 1.75-1.87.95-2.28.29-3.94-2.01-5.08-1.2-.59-3.38-1.08999-5.36-1.08999z"
        fill="#004453"
      />
      <path
        d="m28.53 21.72c-.34 0-.64-.23-.73-.57-.07-.29-1.86-7.12-10.08-7.92-.41-.04-.71-.41-.67-.82s.41-.72.82-.67c9.31.9 11.37 8.98 11.39 9.06.1.4-.15.81-.55.9-.06.01-.12.02-.18.02z"
        fill="#004453"
      />
      <path
        d="m.889893 39.74v-8.61h43.210007v10.61h-41.21001c-1.1 0-1.999997-.9-1.999997-2z"
        fill="#fff"
      />
      <path
        d="m44.8499 42.49h-41.96001c-1.52 0-2.749997-1.23-2.749997-2.75v-9.36h44.710007zm-43.21001-10.61v7.86c0 .69.56 1.25 1.25 1.25h40.46001v-9.11z"
        fill="#004453"
      />
      <path
        d="m67.6501 26.16h-16.85v-13.36h-6.7v12.21 16.73h23.55c1.23 0 2.23-1 2.23-2.23v-11.12c0-1.24-.99-2.23-2.23-2.23z"
        fill="#fff"
      />
      <path
        d="m67.6501 42.49h-24.3v-30.44h8.19v13.35h16.11c1.64 0 2.98 1.34 2.98 2.98v11.12c0 1.65-1.33 2.99-2.98 2.99zm-22.8-1.5h22.8c.82 0 1.48-.66 1.48-1.48v-11.12c0-.82-.66-1.48-1.48-1.48h-17.6v-13.36h-5.19v27.44z"
        fill="#004453"
      />
      <path
        d="m56.5001 46.9c3.1259 0 5.66-2.5341 5.66-5.66s-2.5341-5.66-5.66-5.66-5.66 2.5341-5.66 5.66 2.5341 5.66 5.66 5.66z"
        fill="#00cc8a"
      />
      <path
        d="m56.5001 47.66c-3.54 0-6.42-2.88-6.42-6.42s2.88-6.42 6.42-6.42 6.42 2.88 6.42 6.42-2.88 6.42-6.42 6.42zm0-11.33c-2.71 0-4.92 2.21-4.92 4.92s2.21 4.92 4.92 4.92 4.92-2.21 4.92-4.92-2.21-4.92-4.92-4.92z"
        fill="#004453"
      />
      <path
        d="m56.5001 42.92c.9278 0 1.68-.7522 1.68-1.68s-.7522-1.68-1.68-1.68c-.9279 0-1.68.7522-1.68 1.68s.7521 1.68 1.68 1.68z"
        fill="#004453"
      />
      <path
        d="m13.5899 46.9c3.126 0 5.66-2.5341 5.66-5.66s-2.534-5.66-5.66-5.66c-3.1259 0-5.65997 2.5341-5.65997 5.66s2.53407 5.66 5.65997 5.66z"
        fill="#00cc8a"
      />
      <path
        d="m13.5899 47.66c-3.54 0-6.40997-2.88-6.40997-6.42s2.87997-6.42 6.40997-6.42 6.41 2.88 6.41 6.42-2.87 6.42-6.41 6.42zm0-11.33c-2.71 0-4.90997 2.21-4.90997 4.92s2.19997 4.92 4.90997 4.92 4.92-2.21 4.92-4.92-2.21-4.92-4.92-4.92z"
        fill="#004453"
      />
      <path
        d="m13.5899 42.92c.9279 0 1.68-.7522 1.68-1.68s-.7521-1.68-1.68-1.68c-.9278 0-1.68.7522-1.68 1.68s.7522 1.68 1.68 1.68z"
        fill="#004453"
      />
      <path d="m43.1501 26.87h-41.59004v3.65h41.59004z" fill="#00cc8a" />
    </g>
  </svg>
)

export default TruckIcon
