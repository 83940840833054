import React, { useCallback, useEffect, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import {
  determineCurrencyRate,
  formatCost,
  handleCalculateCostPerTonne,
} from 'model/utils/cost'
import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'
import { getMinCostPerTonneUsd } from 'model/utils/project/projectUtils'
import { getUnits } from 'model/utils/units'
import { useNavigate } from 'react-router-dom'
import ProjectPanelView from './project-panel-view/ProjectPanelView'
import LeadGenRequestDetailsModal from '../../../../modals/lead-gen/lead-gen-request-details-modal/LeadGenRequestDetailsModal'
import AddCardModal from '../../../account-settings/components/modals/AddCardModal'
import { useMarketplaceContext } from '../../MarketplaceContext'
import '../../Marketplace.scss'

const ProductItemPanel = React.memo(({ project: product, setProject }) => {
  const {
    redirectToModal,
    amount,
    weightType,
    defaultCurrency,
    currencyRates,
    setProjectDetails,
    setProjectEstimate,
    setShowEmptyStateModal,
    setShowModalWithValues,
    setProjectToPurchase,
    setProductTypeToPurchase,
    isDisplayRealAmount,
    handleRenderTooltipContent,
    sortOrder,
    setSelectedVintage,
  } = useMarketplaceContext()

  const { account } = useUser()
  const navigate = useNavigate()
  const [showAddCard, setShowAddCard] = useState(false)
  const [showLeadGenRequestDetailsModal, setShowLeadGenRequestDetailsModal] =
    useState(false)
  const [cachedEvent, setCachedEvent] = useState(undefined)

  const [estimate, setEstimate] = useState({
    total_weight: 0,
    total_cost: 0,
    currency_type: defaultCurrency,
    weight_type: getUnits({ isKg: account.display_kg, format: '%u' }),
  })

  const [isNotEnoughInventory, setIsNotEnoughInventory] = useState(false)

  const setProjectDetailsState = () => {
    setProjectDetails(product)
    setProjectEstimate(estimate)
    setShowEmptyStateModal(false)
    setShowModalWithValues(false)
    setProjectToPurchase('')
    setProductTypeToPurchase('')
    if (product.productType === ProductType.PROJECT) {
      navigate(`/marketplace/project-details/${product?.id}`)
    } else if (product.productType === ProductType.PORTFOLIO) {
      navigate(`/marketplace/portfolio-details/${product?.slug}`)
    }
  }

  useEffect(() => {
    let tempWeight = amount
    let calculatedEstimate = {}
    if (weightType === 'tonnes') {
      tempWeight = amount * 1000
    } else if (weightType === 'lbs') {
      tempWeight = amount / 2.20462
    } else {
      tempWeight = amount
    }

    if (amount !== 0 && amount !== '') {
      const carbonCost =
        Math.round(
          tempWeight *
            product?.cost_per_kg_carbon_in_usd_cents *
            determineCurrencyRate(defaultCurrency, currencyRates)
        ) / 100
      //determine the total calculation according to the weight type (kg vs lbs)
      calculatedEstimate = {
        total_weight: 0,
        total_cost: carbonCost,
        currency_type: defaultCurrency,
        weight_type: weightType,
      }
    } else {
      //make sure result is 0 on the UI if input is empty
      calculatedEstimate = { ...estimate, total_cost: 0 }
    }
    setEstimate(calculatedEstimate)
    // eslint-disable-next-line
  }, [
    defaultCurrency,
    amount,
    weightType,
    currencyRates,
    sortOrder,
    product?.id,
    account,
  ])

  //Change button text to 'Contact us' if insufficient carbon inventory
  useEffect(() => {
    let tempWeight = amount
    if (weightType === 'lbs') {
      tempWeight = amount / 2.20462
    } else if (weightType === 'tonnes') {
      tempWeight = amount * 1000
    } else {
      tempWeight = amount
    }
    setIsNotEnoughInventory(
      estimate.total_weight > product?.available_carbon_in_kg ||
        tempWeight > product?.available_carbon_in_kg
    )
    // eslint-disable-next-line
  }, [estimate, amount])

  const getPrimaryImg = () => {
    const primaryImg = product?.image_list?.find(imgObj => {
      return imgObj.type.toUpperCase() === 'THUMBNAIL' && imgObj.is_primary
    })
    return primaryImg?.url
  }

  const addToCart = e => {
    // if the account billing is stripe, but there's no stripe id, so the add cc modal
    if (account.billing_method === 'stripe' && !account.stripe_id) {
      setCachedEvent(e)
      setShowAddCard(true)
    } else {
      showProjectModal(e)
    }
  }

  const leadGenNotify = e => {
    setShowLeadGenRequestDetailsModal(true)
    e.preventDefault()
  }

  const showProjectModal = e => {
    //console.log("Showing Purchase Modal",product)
    e = e ?? cachedEvent
    // if the product is a portfolio
    setProject(product)
    if (product.productType === ProductType.PORTFOLIO) {
      redirectToModal(e, product.slug, ProductType.PORTFOLIO)
    } else if (product.productType === ProductType.PROJECT) {
      product?.all_tranches?.forEach(tranche => {
        if (tranche.id === product.active_tranche?.id) {
          const vintage = tranche
          setSelectedVintage(vintage)
          redirectToModal(e, vintage?.id, ProductType.TRANCHE)
        }
      })
    }
  }

  //calculated amount
  const totalAmount = useCallback(() => {
    if (amount === 0) return null
    return formatCost(estimate?.total_cost, defaultCurrency)
    // eslint-disable-next-line
  }, [defaultCurrency, amount, estimate?.total_weight, estimate?.total_cost])

  const costInDollars = product?.cost_per_kg_carbon_in_usd_cents * 10
  const costPerTonneFormatted =
    handleCalculateCostPerTonne(costInDollars, defaultCurrency, currencyRates) +
    '/tonne'

  const userInputData = {
    weight_unit: weightType,
    amount: amount,
    currencyType: defaultCurrency,
  }

  const getMinCostPerTonneFormatted = product => {
    if (!product || product.productType === ProductType.PORTFOLIO)
      return undefined
    const minCostPerTonneUsd = getMinCostPerTonneUsd(product)
    if (!minCostPerTonneUsd) return undefined
    return handleCalculateCostPerTonne(
      minCostPerTonneUsd,
      defaultCurrency,
      currencyRates
    )
  }

  return (
    <>
      <AddCardModal
        showAddCard={showAddCard}
        setShowAddCard={bool => setShowAddCard(bool)}
        addCardSuccessful={showProjectModal}
      />
      <LeadGenRequestDetailsModal
        product={product}
        showModal={showLeadGenRequestDetailsModal}
        setShowModal={bool => setShowLeadGenRequestDetailsModal(bool)}
      />
      <ProjectPanelView
        project={product}
        handleClick={setProjectDetailsState}
        handleLeadGenNotify={leadGenNotify}
        totalAmount={totalAmount()}
        costPerTonneFormatted={costPerTonneFormatted}
        getMinCostPerTonneFormatted={getMinCostPerTonneFormatted(product)}
        isNotEnoughInventory={isNotEnoughInventory}
        handleAddToCart={addToCart}
        projectImage={getPrimaryImg()}
        userInputData={userInputData}
        isDisplayRealAmount={isDisplayRealAmount(estimate)}
        tooltipAmount={handleRenderTooltipContent(estimate)}
        setProjectEstimate={setProjectEstimate}
      />
    </>
  )
})

export default ProductItemPanel
