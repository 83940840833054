import React from 'react'
import Skeleton from 'antd/lib/skeleton'
import { handleCalculateCostPerTonne } from 'model/utils/cost'
import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'
import FolderOpen from 'view/components/icons/folder-open-icon/FolderOpenIcon'
import { determinePrimaryImg } from '../../../../project-details/components/ProjectDetailsUtility'
import '../../../../project-details/ProjectDetails.scss'
import './VintageSelector.scss'
import { useMarketplaceContext } from '../../../MarketplaceContext'

const PurchaseListItem = ({
  project,
  vintageYear,
  formattedAmount,
  formattedCost,
  formattedCostPerTonne,
  isEstimationLoading = false,
}) => {
  const { defaultCurrency, currencyRates } = useMarketplaceContext()

  return (
    project && (
      <div className="purchase-list-item">
        <div className="top-row">
          <img
            className="vintage-image"
            src={determinePrimaryImg(project?.image_list)}
            alt="product"
          />
          <label className="vintage-name">{project.name}</label>
          <div className="price-info">
            <div className="amount">{formattedAmount}</div>
            <div className="cost">
              {!isEstimationLoading && formattedCost ? formattedCost : ''}
              {isEstimationLoading && (
                <Skeleton.Button
                  className="text-skeleton small"
                  active={true}
                  loading={true}
                />
              )}
            </div>
          </div>
        </div>
        <div className="bottom-row">
          {project.productType === ProductType.PORTFOLIO ? (
            <>
              <div className="info-tag portfolio">
                <FolderOpen />
                <div>Portfolio</div>
              </div>
              <div className="info-tag">
                {project.offset_sources?.length || 0} project
                {project.offset_sources?.length == 1 ? '' : 's'}
              </div>
              <div className="info-tag">
                {handleCalculateCostPerTonne(
                  project.price_per_tonne_in_USD,
                  defaultCurrency,
                  currencyRates
                )}{' '}
                / t
              </div>
            </>
          ) : (
            <>
              <div className="info-tag">{project.type}</div>
              <div className="info-tag">v{vintageYear}</div>
              <div className="info-tag">{formattedCostPerTonne}</div>
            </>
          )}
        </div>
      </div>
    )
  )
}

export default PurchaseListItem
