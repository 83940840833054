import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import Modal from 'antd/lib/modal'
import { Account } from 'interfaces/Account.interface'
import { useNavbarContext } from 'model/contexts/NavbarContext'
import { useUser } from 'model/hooks/account/useUser'
import ArrowRight from 'view/components/icons/arrows/ArrowRight'
import './SelectAccountModal.scss'

const SelectAccountModal = () => {
  const { account, user, updateAccount } = useUser()
  const accounts = user?.accounts
  const { setShowAccountsModal, showAccountsModal } = useNavbarContext()

  const onClose = () => setShowAccountsModal(false)

  const selectedAccount = (slug: string) => account?.slug === slug

  const handleSelectAccount = (acc: Account) => {
    updateAccount(acc, true)
    onClose()
  }

  const styles = (slug: string) =>
    `hover:bg-neutral-98 hover:border-neutral-80 rounded-lg border cursor-pointer flex items-center justify-between py-2 pr-4 pl-2 ${selectedAccount(slug) ? ' border-secondary-80 bg-secondary-95' : 'border-neutral-90 '}`

  return (
    <Modal
      open={showAccountsModal}
      onCancel={onClose}
      destroyOnClose
      centered
      footer={null}
      className="select-account-modal"
    >
      <p className="text-2xl font-semibold text-neutral-20 mb-6">Accounts</p>
      <section
        className="flex flex-col gap-2"
        style={{ overflowX: 'auto', maxHeight: 362 }}
      >
        {accounts?.length &&
          accounts.map((acc: Account) => (
            <div
              key={acc.slug}
              className={styles(acc.slug)}
              onClick={() => handleSelectAccount(acc)}
            >
              <p className="font-bold">{acc.name}</p>
              {selectedAccount(acc.slug) ? (
                <CheckIcon className="w-4 text-secondary-50" />
              ) : (
                <ArrowRight />
              )}
            </div>
          ))}
      </section>
    </Modal>
  )
}

export default SelectAccountModal
