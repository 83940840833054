import React from 'react'
import LoadingSpinnerIcon from '../icons/loading-spinner-icon/LoadingSpinnerIcon'
import './TableOverlay.scss'

const TableOverlay = () => (
  <div className="table-overlay">
    <LoadingSpinnerIcon className="spinner" strokeWidth={2} />
  </div>
)

export default TableOverlay
