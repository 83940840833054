import React from 'react'
import Button from 'view/components/button/Button'
import { usePaymentContext } from '../../../../contexts/CheckoutContext'
import PageListItem from '../page-list/page-list-item/PageListItem'
import '../CheckoutHomePage.scss'
import './PageList.scss'

const PageList = React.memo(({ account }) => {
  const { pages, navigateToCheckoutPage } = usePaymentContext()

  const displayPageListItems = pages =>
    pages.map(page => (
      <PageListItem
        key={page.checkout_page_slug}
        account={account}
        page={page}
      />
    ))

  return (
    <div className="body-section">
      {pages && (
        <>
          {(pages.activePages.length > 0 || pages.draftPages.length > 0) && (
            <div className="list-container">
              {displayPageListItems(pages.activePages)}
              {displayPageListItems(pages.draftPages)}
            </div>
          )}
          {pages.activePages.length === 0 && pages.draftPages.length === 0 && (
            <div className="empty-container">
              <p>You haven’t created any Direct Checkout pages</p>
              <Button
                className="page-header-button"
                onClick={() => navigateToCheckoutPage()}
              >
                Create new page
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
})

export default PageList
