import React, { CSSProperties } from 'react'
import { cn } from 'model/utils/shadcn/utils'
import CollapseIcon from 'view/components/icons/project/util/collapse-icon/CollapseIcon'
import ExpandIcon from 'view/components/icons/project/util/expand-icon/ExpandIcon'

type Props = {
  toggle?: () => void
  expanded: boolean
  label?: string
  style?: CSSProperties
  className?: string
}
const ExpandButton = ({
  toggle,
  expanded,
  label,
  style,
  className = '',
}: Props) => {
  return (
    <button
      className={cn(
        'text-muted text-sm gap-2 justify-center items-center flex px-[10px] py-2 rounded-md font-medium ',
        className
      )}
      onClick={toggle}
      style={style}
    >
      {label && <p>{label}</p>}
      {expanded ? <CollapseIcon /> : <ExpandIcon />}
    </button>
  )
}

export default ExpandButton
