import React from 'react'

const Confirmation = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0001 0.666656C6.63341 0.666656 0.666748 6.63332 0.666748 14C0.666748 21.36 6.63341 27.3333 14.0001 27.3333C21.3601 27.3333 27.3334 21.36 27.3334 14C27.3334 6.63332 21.3601 0.666656 14.0001 0.666656ZM11.3334 20.6667L4.66675 14L6.55341 12.1133L11.3334 16.8933L21.4467 6.77999L23.3334 8.66666L11.3334 20.6667Z"
      fill="white"
    />
  </svg>
)

export default Confirmation
