import React, { ReactNode } from 'react'
import { Project } from 'interfaces/Project.interface'
import { handleGenerateURL } from 'model/utils/image'
import { defaultMethodologyIconUrl } from 'model/utils/methodology'
import { durabilityMap, mechanismMap } from 'model/utils/technology'
import ExpandablePanel from './expandable-project-data/ExpandableProjectData'

type Props = {
  project: Project
}

const ProjectTechnologyPanel = ({ project }: Props) => {
  /**
   * @type {string}
   */
  const panelTitle = 'Technology'

  /**
   * @param {string} title
   * @param {string} label
   * @param {string} img
   * @param {React.JSX.Element} icon
   * @returns {React.JSX.Element}
   */
  const TechnologyListItem = ({
    title,
    label,
    img,
    icon,
  }: {
    title?: string
    label?: string
    img?: string
    icon?: string | ReactNode
  }) => {
    if (!title || !label) return null
    return (
      <div className="panel-column">
        <h6>
          <span>{title}</span>
        </h6>
        <div className="panel-data">
          {!!img && <img className="technology" src={img} alt={''} />}
          {icon || null}
          <span>{label}</span>
        </div>
      </div>
    )
  }

  const MethodologyItem = ({
    methodology,
    img,
  }: {
    methodology?: string
    img?: string
  }) => {
    img = handleGenerateURL(img || defaultMethodologyIconUrl)
    if (!methodology) return null
    return (
      <TechnologyListItem title="Methodology" label={methodology} img={img} />
    )
  }

  const MechanismItem = ({ mechanism }: { mechanism?: string }) => {
    if (!mechanism) return null
    mechanism = mechanism.trim().toUpperCase()
    const mechanismItem = mechanismMap.get(mechanism)
    if (mechanismItem)
      return (
        <TechnologyListItem
          title="Mechanism"
          label={mechanismItem.label}
          icon={mechanismItem.icon}
        />
      )
    return null
  }

  /**
   * @param {string} mechanism
   * @param {string} durability
   * @returns {React.JSX.Element}
   */
  const DurabilityItem = ({
    mechanism,
    durability,
  }: {
    mechanism: string
    durability: string
  }) => {
    if (!durability || !mechanism) return null
    mechanism = mechanism.trim().toUpperCase()
    durability = durability.trim().toUpperCase()
    if (mechanism !== 'REMOVAL') return null
    const durabilityItem = durabilityMap.get(durability)
    if (durabilityItem)
      return (
        <TechnologyListItem title="Permanence" label={durabilityItem.label} />
      )
    return null
  }

  return (
    !!project && (
      <section className="project-panel">
        <h2>{panelTitle}</h2>
        <div className="panel-table">
          <MethodologyItem
            methodology={project.type}
            img={project.methodology_icon_url}
          />
          <MechanismItem mechanism={project.offset_classification} />
          <DurabilityItem
            durability={project.removal_time_period}
            mechanism={project.offset_classification}
          />
        </div>
        <ExpandablePanel
          title={`About ${project.type}`}
          description={project.methodology_description}
        />
      </section>
    )
  )
}

export default ProjectTechnologyPanel
