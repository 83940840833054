import { Portfolio, PortfolioQuote } from 'interfaces/Portfolio.interface'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { download } from 'model/utils/download'

type Props = {
  downloadSuccessMessage?: string
  endpoint?: string
  portfolio?: Portfolio | null
  currencyRates: any[]
  quote: PortfolioQuote
  setPdfLoading: (val: boolean) => void
}

export const useDownloadPortfolioPdf = ({
  downloadSuccessMessage = 'Download Complete.',
  endpoint = '/portfolio/pdf',
  portfolio,
  currencyRates,
  quote,
  setPdfLoading,
}: Props) => {
  // @todo - this basic auth is only temporary.  eventually should be replaced with v2 endpoint before launch of feature
  const basicAuth = btoa(':Super$ecre7PleezeDon7Sh@reThis')
  const { infoToast, warningToast } = useToast()
  const { serviceRequest } = useService()
  const { account } = useUser()

  const downloadPortfolioPdf = async (e: any) => {
    if (e) e.target.blur()
    if (!portfolio) return
    try {
      setPdfLoading(true)
      portfolio.selectedCurrency = account?.default_currency
      const path = `${process.env.REACT_APP_CLOUD_API_URL}${endpoint}`
      const pdf = await serviceRequest({
        showLoading: false,
        path,
        pathAsUrl: true,
        method: 'POST',
        options: { responseType: 'arraybuffer' },
        basicAuth,
        data: { portfolio, currencyRates, quote },
      })
      infoToast(downloadSuccessMessage)
      download(pdf, `${portfolio.name}.pdf`, 'application/pdf')
    } catch (error) {
      console.error(
        'An error occurred while trying to download the portfolio pdf.',
        error
      )
      warningToast(
        'Could not download the PDF at this time. If this issue persists, contact support@cloverly.com.'
      )
    } finally {
      setPdfLoading(false)
    }
  }

  return {
    downloadPortfolioPdf,
  }
}
