//capitalize every word of a given string
export const capFirstLetter = (str?: string, returnNull?: boolean) => {
  if (str) {
    const strSplitIntoWords = str.trim().split(' ')
    const capitalizedWords = strSplitIntoWords.map(
      word => `${word[0]?.toUpperCase()}${word?.slice(1, str.length)}`
    )
    return capitalizedWords.join(' ')
  }
  return returnNull ? null : '-'
}
