import React from 'react'
import { Portfolio } from 'interfaces/Portfolio.interface'
import { listOfHighlights } from 'model/utils/portfolio/portfolioUtils'

type Props = {
  portfolio?: Portfolio | null
}

const PortfolioHighlights = ({ portfolio }: Props) => {
  const highlights = portfolio?.highlights
  const logo = portfolio?.company_logo
  const companyName = portfolio?.company_name
  return (
    <>
      {highlights || logo ? (
        <section className="p-4 flex gap-4 justify-between border border-neutral-80 rounded-xl">
          <div className="flex flex-col gap-4">
            <p className="text-lg font-bold">At a glance</p>
            {highlights ? (
              <ul className="pl-6">
                {listOfHighlights(highlights)?.map((item, idx) => (
                  <li className="list-disc text-sm" key={idx}>
                    {item}
                  </li>
                ))}
              </ul>
            ) : companyName ? (
              <p>Created for {companyName}</p>
            ) : null}
          </div>
          {logo && <img src={logo} alt={companyName ?? 'company logo'} />}
        </section>
      ) : null}
    </>
  )
}

export default PortfolioHighlights
