import Cookies from 'universal-cookie'

// helper to get user from localstorage
export function getStoredUser() {
  const storedUser = localStorage.getItem('user')
  return storedUser ? JSON.parse(storedUser) : null
}

export function setStoredUser(user) {
  localStorage.setItem('user', JSON.stringify(user))
}

export function clearStoredUser() {
  localStorage.removeItem('user')
  localStorage.removeItem('account')
}

// helpers to get user's current account (will be refactored later)
export function setStoredAccount(account) {
  const cookies = new Cookies()
  cookies.set('current_account', account.slug, { path: '/' })
  localStorage.setItem('account', JSON.stringify(account))
}

export function getStoredAccount() {
  const storedUser = getStoredUser()
  const storedAccount = localStorage.getItem('account')
  return storedAccount
    ? JSON.parse(storedAccount)
    : storedUser?.accounts?.length >= 1
    ? storedUser.accounts[0]
    : null
}

export const getStoredColor = () => {
  const storedColor = JSON.parse(localStorage.getItem('theme-color'))
  return storedColor
}

export const setStoredColor = color => {
  localStorage.setItem('theme-color', JSON.stringify(color))
}

export const getStoredColorPreview = () => {
  const storedColorPreview = localStorage.getItem('theme-color-preview')
  return !JSON.parse(storedColorPreview)
    ? '#0b7766'
    : JSON.parse(storedColorPreview)
}

export const setStoredColorPreview = colorPreview =>
  localStorage.setItem('theme-color-preview', JSON.stringify(colorPreview))

// helpers to get user's target path to the application
export const getStoredTargetPath = () =>
  localStorage.getItem('targetPath') || null
export const setStoredTargetPath = targetPath =>
  targetPath
    ? localStorage.setItem('targetPath', targetPath)
    : localStorage.removeItem('targetPath')

// helpers to get user's initial path to the application
export const getStoredInitialPath = () =>
  localStorage.getItem('initialPath') || null
export const setStoredInitialPath = initialPath =>
  initialPath
    ? localStorage.setItem('initialPath', initialPath)
    : localStorage.removeItem('initialPath')
