import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const ProjectDetailsRedirect = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  navigate(`/marketplace/project-details/${id}`)

  useEffect(() => {
    if (id) {
      //console.log('Redirecting to /marketplace/project-details/' + id)
      navigate(`/marketplace/project-details/${id}`, { replace: true })
    }
  }, [id])
}

export default ProjectDetailsRedirect
