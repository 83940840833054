import React, { useState } from 'react'
import { Project } from 'interfaces/Project.interface'
import ReactMarkdown from 'react-markdown'
import ExpandButton from 'view/components/buttons/expand-button/ExpandButton'

type Props = {
  project: Project
}

const ProjectOverview = ({ project }: Props) => {
  // long description expanded view state
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => setExpanded(!expanded)

  const expandBtnLabel = `Read ${expanded ? 'less' : 'more'}`

  return (
    project &&
    !!(project?.short_description || !!project?.long_description) && (
      <section className="project-panel">
        <h2>Overview</h2>
        {project.short_description && <p>{project.short_description}</p>}
        {!!project?.long_description && (
          <>
            <ExpandButton
              toggle={toggleExpand}
              expanded={expanded}
              label={expandBtnLabel}
              className="w-full text-muted text-sm bg-neutral-95 hover:bg-neutral-90"
            />
            {expanded && (
              <>
                <div className="long-description">
                  <ReactMarkdown linkTarget="_blank">
                    {project.long_description}
                  </ReactMarkdown>
                </div>
                <ExpandButton
                  toggle={toggleExpand}
                  expanded={expanded}
                  label={expandBtnLabel}
                  className="w-full text-muted text-sm bg-neutral-95 hover:bg-neutral-90"
                />
              </>
            )}
          </>
        )}
      </section>
    )
  )
}

export default ProjectOverview
