import React from 'react'
import { formatCost } from 'model/utils/cost'
import { shortDate } from 'model/utils/date'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import './AccountSettingsTables.scss'

const InvoiceTable = React.memo(
  ({ id = 'it', tableData, changePage, showPaginator = true }) => (
    <>
      <div className="table-container">
        <Table>
          <thead>
            <tr>
              <td>Start Date</td>
              <td>End Date</td>
              <td>Invoiced Amount</td>
              <td>Balance</td>
              <td>Status</td>
              <td>Receipt</td>
            </tr>
          </thead>
          <tbody>
            {tableData?.invoices?.map((invoice, index) => (
              <tr key={id + '_row_' + index}>
                <td>
                  {shortDate.format(
                    new Date(invoice.starts_at.replace('Z', ''))
                  )}
                </td>
                <td>
                  {shortDate.format(new Date(invoice.ends_at.replace('Z', '')))}
                </td>
                <td>{formatCost(invoice.amount_in_usd_cents / 100, 'USD')}</td>
                <td>
                  {formatCost(
                    invoice.remaining_balance_in_usd_cents / 100,
                    'USD'
                  )}
                </td>
                <td>{invoice.status}</td>
                <td>
                  <a
                    href={invoice.receipt_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!tableData?.total && (
          <div className="empty-table-message">No Invoice History</div>
        )}
      </div>

      {showPaginator && tableData?.totalPages > 1 && (
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          pageCount={tableData?.totalPages}
          onPageChange={changePage}
          containerClassName={'pagination-btns'}
          previousLinkClassName={'previous-btn'}
          nextLinkClassName={'next-btn'}
          disabledClassName={'disabled-btns'}
          activeClassName={'pagination-active'}
        />
      )}
    </>
  )
)

export default InvoiceTable
