import { dynamicSort } from 'model/utils/dynamicSort'

/**
 * The list of all available sort options for the marketplace.
 *
 * @type {[{label: string, value: string},{label: string, value: string},{label: string, value: string}]}
 */
export const marketplaceSortOptions = [
  { label: 'Default', value: '' },
  { label: 'Price: High to Low', value: 'price-desc' },
  { label: 'Price: Low to High', value: 'price-asc' },
]

/**
 * Returns the label associated with a given sort order.
 *
 * @param sortOrder
 * @returns {string}
 */
export const getSortOptionLabel = sortOrder => {
  const selectedOption = marketplaceSortOptions.filter(
    option => option.value === sortOrder
  )
  return selectedOption?.length ? selectedOption[0].label : 'Default'
}

/**
 * Given an array of products, return a sorted array based on the sort order given.
 *
 * @param products
 * @param sortOrder
 * @returns {[]|*}
 */
export const sortProductsBySortOrder = (products, sortOrder) => {
  // if we have a valid array with at least 1 item
  if (products?.length) {
    switch (sortOrder) {
      case 'price-asc':
        return sortProductsPriceAccending(products)
      case 'price-desc':
        return sortProductsPriceDescending(products)
      default:
        return sortMarketplaceDefault(products)
    }
  }
  // return whatever was given
  return products
}

/**
 * Sort products by price accending.
 *
 * @param products
 * @returns {[]}
 */
const sortProductsPriceAccending = products =>
  products.sort(dynamicSort('cost_per_kg_carbon_in_usd_cents'))

/**
 * Sort products by price descending.
 *
 * @param products
 * @returns {[]}
 */
const sortProductsPriceDescending = products =>
  products.sort(dynamicSort('cost_per_kg_carbon_in_usd_cents', false))

/**
 * Sort products by default sort.
 *
 * @param products
 * @returns {[]}
 */
const sortMarketplaceDefault = products => {
  products.sort(dynamicSort('name'))
  const portfolios =
    products.filter(product => product.productType === 'PORTFOLIO') || []
  const projects =
    products.filter(product => product.productType === 'PROJECT') || []
  // set offset_classification to uppercase for projects
  projects.forEach(
    project =>
      (project.offset_classification = project.offset_classification
        ? project.offset_classification.toUpperCase()
        : '')
  )
  // extract removal projects
  const removalProjects = projects.filter(
    project => project.offset_classification === 'REMOVAL'
  )
  // extract avoidance projects
  const avoidanceProjects = projects.filter(
    project => project.offset_classification === 'AVOIDANCE'
  )
  // extract remaining projects
  const remainingProjects = projects.filter(
    project => !['REMOVAL', 'AVOIDANCE'].includes(project.offset_classification)
  )
  return []
    .concat(portfolios)
    .concat(removalProjects)
    .concat(avoidanceProjects)
    .concat(remainingProjects)
}
