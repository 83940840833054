import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { WebMercatorViewport } from '@math.gl/web-mercator'
import { ClickAwayListener } from '@mui/material'
import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'
import { mapConfig } from 'model/utils/react-map'
import { Col, Row } from 'react-bootstrap'
import ReactMapGL, { Marker, Popup } from 'react-map-gl'
import PinIcon from 'view/components/icons/pin-icon/PinIcon'
import ProductItemPanel from '../panels/ProductItemPanel'

/**
 * Renders the marketplace map component.
 * @param account
 * @param projects
 * @param setShowProductPanelOnMap
 * @param showProductPanelOnMap
 * @param setProject
 * @param project
 * @returns {Element}
 * @constructor
 */
const MarketplaceMap = ({
  account,
  projects,
  setShowProductPanelOnMap,
  showProductPanelOnMap,
  setProject,
  project,
}) => {
  const getMapStyle = () =>
    !account?.is_cobranding_enabled
      ? 'mapbox://styles/cloverly/ckykicffr64sj14qp56qaxksj'
      : 'mapbox://styles/cloverly/ckyx5v3yx000315mnoru09kic'
  const [pinPoints, setPinPoints] = useState([])
  const [defaultViewport] = useState({ latitude: 20, longitude: 0, zoom: 2 })
  const [viewport, setViewport] = useState(defaultViewport)
  const cardRef = useRef(null)
  const [selectedPin, setSelectedPin] = useState(defaultViewport)
  const [currentPin, setCurrentPin] = useState(null)
  const [blockPopupClose, setBlockPopupClose] = useState(false)
  const clicked = useRef(0)
  const firstUpdate = useRef(true)

  useEffect(() => {
    const pinPoints = []
    if (projects)
      projects
        .filter(
          project =>
            project?.productType === ProductType.PROJECT && project?.location
        )
        .forEach(project =>
          pinPoints.push({
            latitude: project.location.x,
            longitude: project.location.y,
          })
        )
    if (pinPoints.length > 1) setViewport(getBoundsForPoints(pinPoints))
    else if (pinPoints.length === 1)
      setViewport({
        latitude: pinPoints[0].latitude,
        longitude: pinPoints[0].longitude,
        zoom: 9,
      })
    else setViewport(defaultViewport)
    setPinPoints(pinPoints)
    // eslint-disable-next-line
  }, [account, projects])

  const getBoundsForPoints = points => {
    const pointsLong = points.map(point => point.longitude)
    const pointsLat = points.map(point => point.latitude)
    return new WebMercatorViewport({
      width: cardRef?.current?.offsetWidth - 48 || 600,
      height: 480,
    }).fitBounds(
      [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
      ],
      { padding: 100 }
    )
  }

  //skips the initial render, so that the popup is not displayed on initial render without the user selecting a pinpoint
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    const blockCloseOnNextClick =
      selectedPin && currentPin && !isSameLocation(selectedPin, currentPin)
    setBlockPopupClose(blockCloseOnNextClick)
    setCurrentPin(selectedPin)
    setShowProductPanelOnMap(true)
    setTimeout(() => setToCloseOnNextClick(), 100)
    // eslint-disable-next-line
  }, [selectedPin])

  const closeOpenPopup = () => {
    setTimeout(() => {
      if (!blockPopupClose) setShowProductPanelOnMap(false)
      setBlockPopupClose(false)
    }, 50)
  }

  const setToCloseOnNextClick = () => setBlockPopupClose(false)

  const isSameLocation = (pinA, pinB) =>
    pinA?.latitude === pinB?.latitude && pinA?.longitude === pinB?.longitude

  const applyToArray = (func, array) => func.apply(Math, array)

  //memoize the pins to optimize in case too many pinpoints exist
  const markerPinPoints = useMemo(() =>
    projects
      ?.filter(product => product.productType === ProductType.PROJECT)
      ?.map(
        project => {
          return (
            <Marker
              key={project?.id}
              latitude={project.location.x}
              longitude={project.location.y}
              offset={mapConfig.markerOffset}
              onClick={() => {
                clicked.current = project?.id
                setSelectedPin({
                  latitude: project.location.x,
                  longitude: project.location.y,
                })
                setProject(project)
              }}
            >
              <PinIcon
                clicked={clicked.current === project?.id}
                pinClass={
                  project.is_purchasable !== false ? 'default' : 'lead-gen'
                }
              />
            </Marker>
          )
        },
        [pinPoints]
      )
  )

  return (
    <Row className="map">
      <Col>
        <ReactMapGL
          {...viewport}
          maxZoom={8}
          minZoom={1.5}
          mapStyle={getMapStyle()}
          className="react-map"
          mapboxAccessToken={mapConfig.mapboxAccessToken}
          onMove={evt => setViewport(evt.viewState)}
          doubleClickZoom={false}
          onViewportChange={nextViewport => setViewport(nextViewport)}
        >
          {
            // display all markers on the map
            markerPinPoints
          }
          {showProductPanelOnMap && (
            <ClickAwayListener onClickAway={() => closeOpenPopup()}>
              <Popup
                onClose={() => setShowProductPanelOnMap(false)}
                longitude={selectedPin.longitude}
                latitude={selectedPin.latitude}
                anchor={undefined}
                closeButton={false}
                closeOnClick={false}
                maxWidth="400px"
              >
                <ProductItemPanel
                  project={project}
                  partOfModal={false}
                  cn="map"
                  setProject={setProject}
                />
              </Popup>
            </ClickAwayListener>
          )}
        </ReactMapGL>
      </Col>
    </Row>
  )
}

export default MarketplaceMap
