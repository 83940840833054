import { Project, ProjectQuote } from 'interfaces/Project.interface'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { download } from 'model/utils/download'

type Props = {
  downloadSuccessMessage?: string
  endpoint?: string
  project?: Project | null
  currencyRates: any[]
  quote?: ProjectQuote
  setPdfLoading: (val: boolean) => void
}

export const useDownloadProjectPdf = ({
  downloadSuccessMessage = 'Download Complete.',
  endpoint = '/project/pdf',
  project,
  currencyRates,
  quote,
  setPdfLoading,
}: Props) => {
  // @todo - this basic auth is only temporary.  eventually should be replaced with v2 endpoint before launch of feature
  const basicAuth = btoa(':Super$ecre7PleezeDon7Sh@reThis')
  const { infoToast, warningToast } = useToast()
  const { serviceRequest } = useService()
  const { account } = useUser()

  const downloadProjectPdf = async (e: any) => {
    if (e) e.target.blur()
    if (!project) return
    try {
      setPdfLoading(true)
      project.selectedCurrency = account?.default_currency
      const path = `${process.env.REACT_APP_CLOUD_API_URL}${endpoint}`
      const pdf = await serviceRequest({
        showLoading: false,
        path,
        pathAsUrl: true,
        method: 'POST',
        options: { responseType: 'arraybuffer' },
        basicAuth,
        data: { project, currencyRates, quote },
      })
      infoToast(downloadSuccessMessage)
      download(pdf, `${project.name}.pdf`, 'application/pdf')
    } catch (error) {
      console.error(
        'An error occurred while trying to download the project pdf.',
        error
      )
      warningToast(
        'Could not download the PDF at this time. If this issue persists, contact support@cloverly.com.'
      )
    } finally {
      setPdfLoading(false)
    }
  }

  return {
    downloadProjectPdf,
  }
}
