import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import CopyIcon from '../../icons/copy-icon/CopyIcon'

const CopyButton = ({
  buttonLabel = 'Copy',
  toolTipLabel = 'Click To Copy',
  placement = 'top',
  textToCopy = '',
  className = '',
}) => {
  const [copyMessage, setCopyMessage] = useState(toolTipLabel)

  const resetTooltipMessage = () => setCopyMessage(toolTipLabel)

  const copyButtonClickHandler = e => {
    setCopyMessage('Copied!')
    navigator.clipboard.writeText(textToCopy)
    e.preventDefault()
  }

  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip>{copyMessage}</Tooltip>}>
      <button
        className={className}
        onMouseLeave={resetTooltipMessage}
        onClick={copyButtonClickHandler}>
        <CopyIcon />
        <span>{buttonLabel}</span>
      </button>
    </OverlayTrigger>
  )
}

export default CopyButton
