import React from 'react'
import { determinePrimaryImg } from '../../../project-details/components/ProjectDetailsUtility'
import './DetailedProjectPanel.scss'

const DetailedPortfolioPanel = ({ project }) => {
  const imageURL = determinePrimaryImg(project?.image_list)

  return (
    <div className="detailed-project-panel">
      <span className="type">Portfolio</span>
      <span className="name">{project?.name}</span>
      <img className="panel-image" src={imageURL} alt="" />
      <span className="short-description">{project?.short_description}</span>
    </div>
  )
}

export default DetailedPortfolioPanel
