import React, { useEffect, useState } from 'react'
import { WebMercatorViewport } from '@math.gl/web-mercator'
import projectsMock from 'model/data/map-project-list.json'
import { useUser } from 'model/hooks/account/useUser'
import { mapConfig } from 'model/utils/react-map'
import ReactMapGL, { Marker } from 'react-map-gl'
import { animateScroll } from 'react-scroll'
import DotIcon from 'view/components/icons/dot-icon/DotIcon'
import OffsetTypeIcon from 'view/components/icons/offset-types/OffsetTypeIcon'
import PinIcon from 'view/components/icons/pin-icon/PinIcon'
import './ProjectMap.scss'

const ProjectMap = () => {
  const getMapStyle = () => 'mapbox://styles/cloverly/ckyx5v3yx000315mnoru09kic'
  const [pinPoints, setPinPoints] = useState([])
  const [projects, setProjects] = useState([])
  const [pageInitialized, setPageInitialized] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [defaultViewport] = useState({ latitude: 20, longitude: 0, zoom: 1 })
  const [viewport, setViewport] = useState(defaultViewport)
  const { account } = useUser()
  // the following uses are required when pulling the list from a service
  // const { serviceRequestMulti, spreadResponses } = useService()
  // const sourceRef = useRef()

  useEffect(() => {
    const pinPoints = []
    let featuredIndex = 0
    const tempProjects = []

    projectsMock.forEach((project, i) => {
      let projectObj = {
        id: `project-${i}`,
        offset_sources_slug: project.id,
        name: project?.short_name || project?.name,
        type: project.type,
        registry_name: project.registry_name,
        image_url: getPrimaryImageUrl(project?.image_list),
        location: project.location,
        is_featured: project.is_featured || false,
        zIndex: project.is_featured ? i : 0,
      }
      if (project.is_featured) tempProjects.push(projectObj)
      else tempProjects.unshift(projectObj)
    })
    tempProjects.forEach(project => {
      pinPoints.push({
        latitude: project.location.x,
        longitude: project.location.y,
        project,
      })
      if (project.is_featured) {
        project.i = featuredIndex
        featuredIndex++
      }
    })
    if (pinPoints.length > 1) setViewport(getBoundsForPoints(pinPoints))
    else if (pinPoints.length === 1)
      setViewport({
        latitude: pinPoints[0].latitude,
        longitude: pinPoints[0].longitude,
        zoom: 9,
      })
    else setViewport(defaultViewport)
    setPinPoints(pinPoints)
    setProjects(tempProjects)
    setPageInitialized(true)

    /*
     * Service call version of loading projects. Saving this for later to a time were we will use
     * this approach--most likely by pulling a cached project list from S3.
    const requests = [{ path: `/projects?all=true`, method: 'GET' }]
    serviceRequestMulti({ requests, sourceRef })
      .then(spreadResponses((projectsResponse) => {
        console.log(projectsResponse)
        projectsResponse.data.forEach((project, i) => {
          let image_url = project?.image_list?.length > 0 && project?.image_list[0]?.url ? project.image_list[0]?.url : null
          let projectObj = {
            id: `project-${i}`,
            offset_sources_slug: project.offset_sources_slug,
            name: project?.short_name || project?.name,
            type: project.type,
            registry_name: project.registry_name,
            tranche_url: project.project_url,
            image_url,
            location: project.location,
            is_featured: project.is_featured || false,
            zIndex: project.is_featured ? i : 0
          }
          if (project.is_featured)
            tempProjects.push(projectObj)
          else
            tempProjects.unshift(projectObj)
        })
        tempProjects.forEach((project, i) => {
          pinPoints.push({ latitude: project.location.x, longitude: project.location.y, project })
          if (project.is_featured) {
            project.i = featuredIndex
            featuredIndex++
          }
        })
        if (pinPoints.length > 1)
          setViewport(getBoundsForPoints(pinPoints))
        else if (pinPoints.length === 1)
          setViewport({ latitude: pinPoints[0].latitude, longitude: pinPoints[0].longitude, zoom: 9 })
        else
          setViewport(defaultViewport)
        setPinPoints(pinPoints)
        setProjects(tempProjects)
        setPageInitialized(true)
      }))
      .catch(e => console.log(e))
      */
    // eslint-disable-next-line
  }, [account])

  const applyToArray = (func, array) => func.apply(Math, array)

  const getBoundsForPoints = points => {
    const pointsLong = points.map(point => point.longitude)
    const pointsLat = points.map(point => point.latitude)
    return new WebMercatorViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    }).fitBounds(
      [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
      ],
      { padding: 100 }
    )
  }

  const viewProject = project => {
    let projectDetailsUrl = `https://${window.location.hostname}/marketplace/project-details/${project.offset_sources_slug}`
    // console.log(projectDetailsUrl)
    window.open(projectDetailsUrl, '_blank')
  }

  const requestAccess = () =>
    window.open('https://www.cloverly.com/account-signup', '_parent')

  const getPrimaryImageUrl = image_list => {
    let image_url = null
    if (image_list && image_list?.length > 0)
      image_list.forEach(
        image =>
          (image_url = image.is_primary && image.url ? image.url : image_url)
      )
    return image_url
  }

  const pinPointClicked = project => {
    // console.log(project)
    setSelectedProject(project)
    if (project.is_featured) {
      // eslint-disable-next-line
      animateScroll.scrollTo(
        project.i * 165 - viewport.height * 0.5 + 165 / 2 + 10,
        {
          containerId: 'projectList',
          duration: 500,
        }
      )
    } else {
      animateScroll.scrollToBottom({
        containerId: 'projectList',
        duration: 500,
      })
    }
  }

  return (
    <>
      {pageInitialized && (
        <div className="project-map">
          <div className="map-container">
            <ReactMapGL
              {...viewport}
              height="100%"
              width="100%"
              maxZoom={8}
              minZoom={2}
              mapStyle={getMapStyle()}
              className="project-map"
              mapboxAccessToken={mapConfig.mapboxAccessToken}
              onMove={evt => setViewport(evt.viewState)}
              onViewportChange={nextViewport => setViewport(nextViewport)}
            >
              {pinPoints?.map((pinPoint, idx) => (
                <Marker
                  onClick={() => pinPointClicked(pinPoint.project)}
                  key={idx}
                  latitude={pinPoint.latitude}
                  longitude={pinPoint.longitude}
                  offset={
                    pinPoint.project.is_featured
                      ? mapConfig.markerOffset
                      : [0, 0]
                  }
                >
                  {pinPoint.project.is_featured ? (
                    <PinIcon clicked={pinPoint.project === selectedProject} />
                  ) : (
                    <DotIcon clicked={pinPoint.project === selectedProject} />
                  )}
                </Marker>
              ))}
            </ReactMapGL>
          </div>
          <div id="projectList" className="project-list">
            {projects?.map(
              project =>
                project.is_featured && (
                  <div
                    className={
                      'project-panel ' +
                      (selectedProject &&
                      selectedProject.id === project.id &&
                      project.is_featured
                        ? 'selected-project'
                        : '')
                    }
                    name={'ProjectTile' + project.id}
                    key={'ProjectTile' + project.id}
                    onClick={() => viewProject(project)}
                  >
                    <div
                      className="project-image"
                      style={{ backgroundImage: `url( ${project.image_url})` }}
                    />
                    <div className="info">
                      <div className="info-top">
                        <div>
                          <div className="title-container">
                            <h3>
                              <span>{project.name}</span>
                            </h3>
                          </div>
                          <div className="project-type">{project.type}</div>
                          {project.registry_name && (
                            <div className="project-type">
                              Registry: {project.registry_name}
                            </div>
                          )}
                        </div>
                        <OffsetTypeIcon type={project.type} />
                      </div>
                    </div>
                  </div>
                )
            )}
            <div
              className={
                'project-panel sign-up ' +
                (selectedProject && !selectedProject.is_featured
                  ? 'selected-project'
                  : '')
              }
            >
              <div>Interested in learning more?</div>
              <button onClick={() => requestAccess()}>
                Request Access to View All Projects
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProjectMap
