import React from 'react'
import { getWeightUnit } from 'model/utils/units'
import Generic from 'view/components/icons/offset-types/svg/generic.svg'
import './OffsetAllocation.scss'

const OffsetAllocation = ({ offsetAllocation, footprint }) => {
  const determineTotalFootprint = () => {
    return getWeightUnit({
      weightType: footprint?.weight_units,
      value: offsetAllocation?.carbon_offset_amount,
      format: '%v %u',
    })
  }

  return (
    <div className="offset-allocation-container">
      <div className="icon-wrapper">
        <img
          src={offsetAllocation?.icon || Generic}
          alt={offsetAllocation?.name}
        />
      </div>
      <div className="details">
        <small className="project-type">{offsetAllocation?.name}</small>
        <span className="carbon-amount">{determineTotalFootprint()}</span>
        <span className="of-carbon">of Carbon</span>
      </div>
    </div>
  )
}

export default OffsetAllocation
