import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ApiCallsPage from './components/pages/ApiCallsPage'
import BillingPage from './components/pages/BillingPage'
import BrandingPage from './components/pages/BrandingPage'
import ChildrenPage from './components/pages/ChildrenPage'
import CreditPage from './components/pages/CreditPage'
import InvoicePage from './components/pages/InvoicePage'
import OverviewPage from './components/pages/OverviewPage'
import UserPage from './components/pages/UserPage'

const AccountSettings = () => (
  <Routes>
    <Route exact path="/" element={<OverviewPage />} />
    <Route exact path="/billing" element={<BillingPage />} />
    <Route exact path="/billing/credits" element={<CreditPage />} />
    <Route exact path="/billing/invoices" element={<InvoicePage />} />
    <Route exact path="/billing/num-of-api-calls" element={<ApiCallsPage />} />
    <Route exact path="/branding" element={<BrandingPage />} />
    <Route exact path="/users" element={<UserPage />} />
    <Route exact path="/children" element={<ChildrenPage />} />
  </Routes>
)

export default AccountSettings
