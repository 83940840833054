import React, { useEffect, useMemo, useState } from 'react'
import { debounce } from '@mui/material'
import { determineSymbol } from 'model/utils/cost'
import CurrencyInput from 'react-currency-input-field'
import { useCheckoutQueryParamContext } from '../../contexts/CheckoutQueryParamContext'

const CustomInput = ({ setAmount }) => {
  const { shopBy, currency } = useCheckoutQueryParamContext()

  const [customAmount, setCustomAmount] = useState(Number)

  useEffect(() => {
    setCustomAmount(0)
    setAmount(0)
    // eslint-disable-next-line
  }, [shopBy])

  useEffect(() => {
    setAmount(customAmount)
  }, [customAmount, setAmount])

  const currencyInputId = 'currency-input'
  const placeholder = 'Enter amount'

  const debouncedChangeHandler = useMemo(
    () => debounce(setCustomAmount, 350),
    []
  )

  return (
    <div className="custom-input">
      <label htmlFor={currencyInputId}>Custom amount</label>
      {shopBy === 'currency' && (
        <CurrencyInput
          id={currencyInputId}
          className={currencyInputId}
          prefix={determineSymbol(currency ?? 'USD')}
          onValueChange={e => {
            if (e === undefined) {
              debouncedChangeHandler(0)
            } else {
              debouncedChangeHandler(Number(e))
            }
          }}
          placeholder={placeholder}
        />
      )}
      {shopBy === 'carbon' && (
        <input
          type="number"
          id={currencyInputId}
          onChange={e => debouncedChangeHandler(Number(e.target.value))}
          placeholder={placeholder}
        />
      )}
    </div>
  )
}

export default CustomInput
