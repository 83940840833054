import React from 'react'
import Skeleton from 'antd/lib/skeleton'
import SkeletonButton from 'antd/lib/skeleton/Button'
import '../../Marketplace.scss'

/**
 * @returns {React.JSX.Element}
 */
const ProductItemSkeleton = () => (
  <section className="project-panel-view skeleton">
    <SkeletonButton active className="image-skeleton rounded-t-[10px]" />
    <div className="bottom-half">
      <Skeleton active />
    </div>
    <div className="bottom">
      <SkeletonButton active className="button-skeleton thin" />
      <SkeletonButton active className="button-skeleton" />
    </div>
  </section>
)

export default ProductItemSkeleton
