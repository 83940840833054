import React, { useEffect, useRef, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import ShopifyBehaviorForm from './components/forms/ShopifyBehaviorForm'
import ShopifyDesignForm from './components/forms/ShopifyDesignForm'
import ShopifyFulfillmentForm from './components/forms/ShopifyFulfillmentForm'
import ShopifyShippingForm from './components/forms/ShopifyShippingForm'
import ShopifyNoCardPanel from './components/panels/shopify-no-card-panel/ShopifyNoCardPanel'
import ShopifyPageHeader from './components/panels/shopify-page-header/ShopifyPageHeader'

const Shopify = () => {
  const { account } = useUser()
  const navigate = useNavigate()
  const { serviceRequest } = useService()
  const { infoToast, warningToast } = useToast()
  const [shopifyData, setShopifyData] = useState(undefined)
  const sourceRef = useRef()

  useEffect(() => {
    // if the account was changed and the new account does not have ecommerce enabled, route users to dashboard
    if (account.ecommerce_type === null) {
      navigate('/')
    }
    serviceRequest({
      path: '/api/shop/',
      sourceRef,
    })
      .then(data =>
        data
          ? setShopifyData(data)
          : console.error(
              'Shopify Data not received. Request may have been cancelled.'
            )
      )
      .catch(error =>
        warningToast(
          error?.response?.data?.Message ??
            'An error occurred while trying to retrieve your Shopify information.  Please refresh and try again. If the problem persists, please contact us.'
        )
      )
    // eslint-disable-next-line
  }, [account])

  const saveShopifyDataChanges = data => {
    const dataModifiedProps = Object.keys(data)
    return serviceRequest({
      path: '/api/shop/',
      method: 'PUT',
      data,
      sourceRef,
    })
      .then(response => {
        dataModifiedProps.forEach(key => (shopifyData[key] = response[key]))
        infoToast('Changes saved successfully.')
      })
      .catch(error => warningToast(error.response.data.Message))
  }

  return (
    <>
      {shopifyData && (
        <div className="shopify-page">
          {account?.billing_method === 'stripe' &&
            account.stripe_id === null && (
              <Routes>
                <Route
                  exact
                  path="/add-card"
                  element={<ShopifyNoCardPanel />}
                />
                <Route
                  exact
                  path="/"
                  element={<Navigate to="/shopify/add-card" replace />}
                />
              </Routes>
            )}
          {((account?.billing_method === 'stripe' &&
            account.stripe_id !== null) ||
            account?.billing_method !== 'stripe') && (
            <>
              <ShopifyPageHeader
                shopifyData={shopifyData}
                saveShopifyDataChanges={saveShopifyDataChanges}
              />
              <Routes>
                <Route
                  exact
                  path="/shipping"
                  element={
                    <ShopifyShippingForm
                      shopifyData={shopifyData}
                      saveShopifyDataChanges={saveShopifyDataChanges}
                    />
                  }
                />
                <Route
                  exact
                  path="/behavior"
                  element={
                    <ShopifyBehaviorForm
                      shopifyData={shopifyData}
                      saveShopifyDataChanges={saveShopifyDataChanges}
                    />
                  }
                />
                <Route
                  exact
                  path="/design"
                  element={<ShopifyDesignForm shopifyData={shopifyData} />}
                />
                <Route
                  exact
                  path="/fulfillment"
                  element={
                    <ShopifyFulfillmentForm
                      shopifyData={shopifyData}
                      saveShopifyDataChanges={saveShopifyDataChanges}
                    />
                  }
                />
                <Route
                  exact
                  path="/"
                  element={<Navigate to="/shopify/shipping" replace />}
                />
              </Routes>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default Shopify
