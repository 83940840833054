import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import CompactLogo from 'view/components/cloverly-logo/CompactLogo'
import Logo from 'view/components/cloverly-logo/Logo'
import CobrandedLogo from './CobrandedLogo'

type Props = {
  menuOpen: boolean
}

const NavBarLogo = ({ menuOpen }: Props) => {
  const { account } = useUser()

  const cobranded =
    account?.is_cobranding_enabled &&
    account?.cobranding_image != '' &&
    account?.cobranding_image != null

  return (
    <>
      {cobranded && (
        <div className="p-2 object-contain h-[60px] mb-8">
          <CobrandedLogo />
        </div>
      )}
      {!cobranded ? (
        menuOpen ? (
          <div className="cloverly-logo">
            <Logo />
          </div>
        ) : (
          <div className="cloverly-logo">
            <CompactLogo />
          </div>
        )
      ) : null}
    </>
  )
}

export default NavBarLogo
