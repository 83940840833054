import React, { useCallback, useEffect } from 'react'
import { directCheckoutApiDocUrl } from 'model/utils/direct-checkout'
import { setPageTitle } from 'model/utils/page'
import Button from 'view/components/button/Button'
import PageList from 'view/pages/checkout-admin/components/pages/checkout-home-page/page-list/PageList'
import DeleteCheckoutModal from '../../../../../modals/direct-checkout/delete-checkout-modal/DeleteCheckoutModal'
import '../../../Checkout.scss'
import { usePaymentContext } from '../../../contexts/CheckoutContext'
import './CheckoutHomePage.scss'

const CheckoutHomePage = () => {
  const {
    account,
    loadCheckoutPages,
    navigateToCheckoutPage,
    showDeleteCheckoutModal,
    setShowDeleteCheckoutModal,
    dataLoaded,
  } = usePaymentContext()

  useEffect(() => {
    setPageTitle('Direct Checkout')
    loadCheckoutPages()
    // eslint-disable-next-line
  }, [account])

  const deleteCompleted = useCallback(() => {
    loadCheckoutPages()
  }, [])

  return (
    <>
      <DeleteCheckoutModal
        showModal={showDeleteCheckoutModal}
        setShowModal={bool => setShowDeleteCheckoutModal(bool)}
        deleteCompleted={deleteCompleted}
      />
      <div className="payments home">
        <div className="header-section">
          <h1 className="standard">Direct Checkout</h1>
          <p className="page-instructions">
            Create custom pages for your customers to purchase carbon credits.{' '}
            <a href={directCheckoutApiDocUrl} target="_blank" rel="noreferrer">
              Learn more here
            </a>
            .
          </p>
          <div className="page-header-button-container">
            <Button
              className="page-header-button"
              onClick={() => navigateToCheckoutPage()}
            >
              Create new page
            </Button>
          </div>
        </div>
        {dataLoaded && <PageList account={account} />}
      </div>
    </>
  )
}

export default CheckoutHomePage
