import React from 'react'
import ExternalLink from 'view/components/external-link/ExternalLink'
import SustainableDevGoals from 'view/components/sustainable-dev-goals/SustainableDevGoals'

const ProjectUnsdg = ({ unsdg }) => {
  return unsdg?.length ? (
    <>
      <div className="unsdg">
        <h6>United Nations Sustainable Development Goals</h6>
        <SustainableDevGoals icons={unsdg} />
      </div>
      <ExternalLink
        url="https://sdgs.un.org/goals"
        title="Learn More about Sustainable Development Goals"
      />
    </>
  ) : (
    <></>
  )
}

export default ProjectUnsdg
