import React from 'react'
import FormContainer from 'view/components/form-container/FormContainer'
import Checkmark from 'view/components/icons/checkmark/Checkmark'

const Submitted = ({ title, info, children }) => {
  return (
    <div data-testid="submitted">
      <FormContainer cloverlyLogo={true} title={title} footer={false}>
        <p className="instructions">{info}</p>
        <Checkmark />
        {children}
      </FormContainer>
    </div>
  )
}

export default Submitted
