/* @param date
 * @returns {string}
 * */
const monthAndYear = date =>
  new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(
    date
  )

/**
 * Formatter for a date in a numeric format.
 *
 *
 * Example (en-US): Dec 12, 2012
 *
 * @param date
 * @returns {string}
 */
const numericDate = new Intl.DateTimeFormat(undefined, {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
})

/**
 * Formatter for a date in a shorter format.
 *
 * Example (en-US): Dec 12, 2012
 *
 * @param date
 * @returns {string}
 */
const shortDate = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
})

/**
 * Formatter for a date in a longer format.
 *
 * Example (en-US): December 12, 2012
 *
 * @param date
 * @returns {string}
 */
const longDate = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
  hour12: true,
})

/**
 * Formatter for time (am/pm format) with user's locale timezone.
 *
 * Example (en-US): 4:45 PM EST
 *
 * @param date
 * @returns {string}
 */
const timeWithZone = date => {
  try {
    return (
      new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }).format(date) + ' GMT'
    )
  } catch (e) {
    console.error(e.message)
    return ''
  }
}

/**
 * Displays long date for valid date, otherwise returns empty string and console logs error.
 *
 * @param dateObj
 * @param dateFormatter
 * @param isStringFormat
 * @returns {string}
 */
const displayDate = (
  dateObj,
  dateFormatter = longDate,
  isStringFormat = false
) => {
  // on safari, having an invalid date at any time will result in a Range Error exception -- let's handle it here
  // so that it does not crash the app
  try {
    if (isStringFormat) dateObj = new Date(dateObj.replace(/ /g, 'T'))
    return dateFormatter.format(dateObj)
  } catch (e) {
    console.error(e.message)
    return ''
  }
}

/**
 * Returns a date object for next month from today.
 *
 * @returns {Date}
 */
const nextMonth = () => {
  const now = new Date()
  return now.getMonth() === 11
    ? new Date(now.getFullYear() + 1, 0, 1)
    : new Date(now.getFullYear(), now.getMonth() + 1, 1)
}

/**
 * @param {number} date1
 * @param {number} date2
 * @returns {number}
 */
const getDifferenceInHours = (date1, date2) =>
  Math.abs(date2 - date1) / (1000 * 60 * 60)

/**
 * @param {Date} date
 * @returns {*}
 */
const formattedUTCDate = (date) =>
  `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()} GMT`

/**
 * Exports public defs.
 */
export {
  numericDate,
  shortDate,
  longDate,
  monthAndYear,
  displayDate,
  timeWithZone,
  nextMonth,
  getDifferenceInHours,
  formattedUTCDate,
}
