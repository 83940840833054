import React from 'react'
import Button from '../../button/Button'
import './ContactUsButton.scss'

const ContactUsButton = ({ disabled, cn = '' }) => {
  return (
    <Button disabled={disabled} cn={`contact-us ${cn}`}>
      <a
        href="https://www.cloverly.com/contact?type_of_inquiry=Existing%20Customer%20Offset%20Purchase"
        target="_blank"
        rel="noopener noreferrer">
        Contact Us
      </a>
    </Button>
  )
}

export default ContactUsButton
