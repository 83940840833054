import React, { useMemo } from 'react'
import Button from 'antd/lib/button'
import { useNavigate } from 'react-router'

interface ButtonProps {
  /**
   * cloverly-defined states
   */
  state?: 'primary' | 'neutral' | 'cancel' | 'disabled'

  variant?: 'filled' | 'outlined' | 'plain'

  loading?: boolean

  disabled?: boolean

  className?: string

  /**
   * How large should the button be?
   */
  size?: 'small' | 'default' | 'large'

  /**
   * Button contents
   */
  children: string | React.ReactNode

  htmlType?: 'button' | 'submit' | 'reset'

  href?: string | null

  /**
   * Optional click handler
   */
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
}

/**
 * Primary UI component for user interaction
 */
export const CloverlyButton = ({
  disabled,
  state = disabled ? 'disabled' : 'primary',
  variant = 'filled',
  size = 'default',
  htmlType = 'button',
  loading = false,
  className = '',
  onClick = () => '',
  children,
  href = null,
  ...props
}: ButtonProps) => {
  const navigate = useNavigate()

  const handleOnClick = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => (href ? navigate(href) : onClick(e))

  const getStateClasses = (state: string) => {
    switch (state) {
      case 'primary': {
        if (variant === 'filled') {
          return '!text-white bg-primary hover:!bg-primary-lighter hover:!border-secondary focus:border-primary-10 focus:bg-primary-40 active:bg-primary-20 active:border-2 active:border-primary-10'
        } else if (variant === 'outlined') {
          return '!text-primary !border-primary !bg-transparent hover:!text-primary-40 !border focus:!border-primary-40'
        } else if (variant === 'plain') {
          return 'shadow-none !text-primary hover:!bg-secondary-95 hover:!border hover:!border-secondary-80'
        }
        break
      }

      case 'neutral': {
        if (variant === 'filled') {
          return 'border-none !text-secondary-20 bg-secondary-90 hover:!border-2 hover:!border-secondary-10'
        } else if (variant === 'outlined') {
          return '!text-secondary-40 !border !border-secondary-40 !bg-transparent hover:text-secondary-10'
        } else if (variant === 'plain') {
          return 'shadow-none !text-secondary hover:!border hover:!border-secondary-80 hover:!bg-secondary-95'
        }
        break
      }

      case 'cancel': {
        if (variant === 'filled') {
          return '!text-red-50 bg-red-95 !border !border-red-95 !border-solid hover:!border-red-50'
        } else if (variant === 'outlined') {
          return '!text-red-50 !border !border-red-50 !bg-transparent hover:!text-red-40'
        } else if (variant === 'plain') {
          return 'shadow-none !text-red-50 hover:!border hover:!border-red-90 hover:!bg-red-95'
        }
        break
      }

      case 'disabled': {
        return 'bg-primary-lighter !text-neutral-70 border-none'
      }
    }
  }

  const getSizeClasses = (size: string) => {
    switch (size) {
      case 'small': {
        return 'text-btn-small p-btn-small !h-8'
      }
      case 'large': {
        return 'text-btn-large p-btn-large'
      }
      default: {
        return 'p-btn-default text-base'
      }
    }
  }

  const BASE_BUTTON_CLASSES =
    'disabled:!pointer-events-none w-fit flex justify-center items-center gap-2 font-medium !rounded-md h-10'

  const computedClasses = useMemo(() => {
    const stateClass = getStateClasses(state)
    const sizeClass = getSizeClasses(size)

    return [stateClass, sizeClass].join(' ')
    //eslint-disable-next-line
  }, [state, size, variant])

  return (
    <Button
      {...props}
      onClick={(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) =>
        handleOnClick(e)
      }
      disabled={disabled}
      htmlType={htmlType}
      loading={loading}
      className={`${BASE_BUTTON_CLASSES} ${computedClasses} ${className}`}
    >
      {children}
    </Button>
  )
}
