import Image from 'antd/lib/image'

export const getRemovalTimePeriodStyleName = removalTimePeriod => {
  if (
    removalTimePeriod?.time_period &&
    ['SHORT', 'MEDIUM', 'LONG'].includes(
      removalTimePeriod.time_period.trim().toUpperCase()
    )
  )
    return removalTimePeriod.time_period?.toLowerCase()
  return 'unspecified'
}

export const getRemovalTimePeriodLabel = removalTimePeriod => {
  if (removalTimePeriod) {
    switch (removalTimePeriod.trim().toUpperCase()) {
      case 'SHORT':
        return '99 years or less'
      case 'MEDIUM':
        return '100-999 years'
      case 'LONG':
        return '1,000 years or more'
    }
  }
  return 'Unspecified'
}

export const getMechanismLabel = mechanism => {
  if (mechanism) {
    switch (mechanism.trim().toUpperCase()) {
      case 'AVOIDANCE':
        return 'Avoidance'
      case 'REMOVAL':
        return 'Removal'
    }
  }
  return ''
}

export const renderSupportingImages = imageList => {
  if (
    imageList?.length > 1 &&
    imageList.some(image => image.is_primary === false)
  ) {
    return (
      <Image.PreviewGroup>
        <div className="supporting-images">
          {imageList?.map(
            (image, idx) =>
              !image?.is_primary && (
                <div key={`img-${idx}`} className="image-container">
                  <Image key={idx} src={image.url} alt="project-icon" />
                </div>
              )
          )}
        </div>
      </Image.PreviewGroup>
    )
  }
}

export const determinePrimaryImg = imageList => {
  let img = imageList?.find(imgObj => {
    return imgObj.type.toUpperCase() === 'THUMBNAIL' && imgObj.is_primary
  })
  return img?.url
}
