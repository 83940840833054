import React from 'react'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { formatCostInCurrency } from 'model/utils/cost'
import Button from 'view/components/button/Button'
import './BuyerChoicePreview.scss'

const BuyerChoicePreview = React.memo(
  ({
    unit_type = 'CURRENCY',
    unit_value = 'USD',
    isCustom = false,
    amount = 42,
    onValueChange = null,
  }) => {
    const amountOptions =
      unit_type === 'CURRENCY'
        ? [10, 25, 50, 100, 250, 'Custom']
        : [1, 5, 10, 20, 50, 'Custom']

    const isCurrencySymbol = amount => {
      const unitValue = unit_value || 'USD'
      if (amount === 'Custom') return 'Custom'
      else if (unit_type === 'CARBON') return amount
      else return formatCostInCurrency(amount, unitValue, false, true)
    }

    return (
      <>
        <div className="choose-amount-widget">
          <h5>Choose amount {unit_type === 'CURRENCY' ? '' : '(tonnes)'}</h5>
          <RadioGroup.Root
            value={isCustom ? 'Custom' : amount}
            onValueChange={onValueChange}
            className="amount-picker"
            aria-label="Amount"
          >
            {amountOptions.map(amount => (
              <RadioGroup.Item
                className="amount-button"
                key={amount}
                value={amount}
              >
                {isCurrencySymbol(amount)}
              </RadioGroup.Item>
            ))}
          </RadioGroup.Root>
          <Button cn="displaying-details" onClick={e => e.preventDefault()}>
            Checkout
          </Button>
        </div>
      </>
    )
  }
)

export default BuyerChoicePreview
