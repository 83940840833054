import React from 'react'
import { Project } from 'interfaces/Project.interface'
import { capFirstLetter } from 'model/utils/capitalize-first-letter'
import { durabilityMap } from 'model/utils/technology'
import AvoidanceIcon from 'view/components/icons/project/mechanism/avoidance-icon/AvoidanceIcon'
import RemovalIcon from 'view/components/icons/project/mechanism/removal-icon/RemovalIcon'
import ExpandablePanel from 'view/pages/project-details/components/expandable-project-data/ExpandableProjectData'
import TechnologyItem from './TechnologyItem'

type Props = {
  project: Project
}

const ProjectTechnologySection = ({ project }: Props) => {
  const offsetClassificationIcon =
    project?.offset_classification?.toUpperCase() === 'REMOVAL' ? (
      <AvoidanceIcon />
    ) : (
      <RemovalIcon />
    )

  const offsetClassName = capFirstLetter(
    project.offset_classification?.toLowerCase(),
    true
  )

  const durabilityItem = durabilityMap.get(project.removal_time_period)

  return (
    <section className="p-4 flex flex-col border border-neutral-80 rounded-xl gap-4">
      <p className="font-bold">Technology</p>
      <section className="flex gap-4">
        <TechnologyItem title="Methodology">
          {project.type ? (
            <>
              <img
                className="w-[18px] h-[18px]"
                src={project.methodology_icon_url}
                alt={project.type}
              />
              <p>{project.type}</p>
            </>
          ) : (
            '-'
          )}
        </TechnologyItem>
        <TechnologyItem title="Mechanism">
          <>
            {offsetClassName ? (
              <>
                {offsetClassificationIcon}
                <p>{offsetClassName}</p>
              </>
            ) : (
              '-'
            )}
          </>
        </TechnologyItem>

        <TechnologyItem title="Permanence">
          <p>{durabilityItem?.label ?? '-'}</p>
        </TechnologyItem>
      </section>
      <ExpandablePanel
        title={`About ${project.type}`}
        description={project.methodology_description}
      />
    </section>
  )
}

export default ProjectTechnologySection
