import React from 'react'
import { NavLink } from 'react-router-dom'

const SubNavLink = React.memo(
  ({ href, title, handleSetTransactionStatus = null }) => (
    <li
      onClick={() => {
        if (typeof handleSetTransactionStatus === 'function') {
          handleSetTransactionStatus(title)
        }
      }}
      data-testid={title}>
      <NavLink
        to={href}
        className={({ isActive }) => (isActive ? 'list-item-active' : '')}
        end>
        {title}
      </NavLink>
    </li>
  )
)

export default SubNavLink
