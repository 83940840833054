import React, { useEffect, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useTheme } from 'model/hooks/useTheme'
import { useToast } from 'model/hooks/useToast'
import { setPageTitle } from 'model/utils/page'
import { Form } from 'react-bootstrap'
import { ChromePicker } from 'react-color'
import { EditableInput } from 'react-color/lib/components/common'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'
import '../../AccountSettings.scss'
import AccountSettingsSubnav from '../navs/AccountSettingsSubnav'
import FileSelectorWidget from '../widgets/file-selector/FileSelectorWidget'
import ThemePreviewWidget from '../widgets/theme-preview/ThemePreviewWidget'
import './BrandingPage.scss'

const BrandingPage = () => {
  React.useEffect(
    () => updateColorPreview(color),
    // eslint-disable-next-line
    []
  )

  const {
    primaryDefault,
    color,
    colorPreview,
    updateColor,
    updateColorPreview,
  } = useTheme()

  const { account, refreshUser } = useUser()

  const clearFileSelectorImage = React.useRef(null)

  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const [base64Image, setBase64Image] = useState(account.cobranding_image)

  const [isStartMode, setIsStartMode] = useState(
    (account.cobranding_primary_color?.toUpperCase() === primaryDefault ||
      account.cobranding_primary_color === null) &&
      (account.cobranding_image === '' || account.cobranding_image === null)
  )

  const handleChange = color => updateColorPreview(color.hex)

  const brandImageUpdated = base64Image => setBase64Image(base64Image)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const { serviceRequest } = useService()

  const { infoToast, warningToast } = useToast()

  const errorMessages = {
    name: 'Please enter a name for this account.',
  }

  const onSubmit = data => {
    serviceRequest({
      path: '/api/branding',
      method: 'PUT',
      data: {
        name: data.name,
        primary_color: colorPreview,
        default_branding: primaryDefault === colorPreview && base64Image === '',
        base64_image: base64Image,
      },
    })
      .then(data => {
        if (data.success) {
          updateColor(colorPreview)
          refreshUser().then(() => infoToast('Your changes have been saved.'))
        }
      })
      .catch(() =>
        warningToast('Unsupported character in Account Name. Please update.')
      )
  }

  useEffect(() => {
    setPageTitle('Branding | Account Settings')
  }, [])

  useEffect(() => {
    reset()
    if (!account.is_cobranding_enabled) {
      history.replace('/account-settings')
    }
    // eslint-disable-next-line
  }, [account])

  const handleClick = () => setDisplayColorPicker(!displayColorPicker)

  const handleClose = () => setDisplayColorPicker(false)

  const restoreDefaults = () => {
    reset()
    clearFileSelectorImage.current()
    setBase64Image('')
    updateColorPreview(primaryDefault)
  }

  return (
    <div className="account-settings branding-container">
      <h1>Account Settings</h1>
      <AccountSettingsSubnav />
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isStartMode && (
          <div className="branding-start-container">
            <h2>Brand your Account</h2>
            <p>
              Customize the Cloverly app to match your
              <br />
              company’s visual style.
            </p>
            <Button onClick={() => setIsStartMode(false)}>Get Started</Button>
          </div>
        )}
        {!isStartMode && (
          <div className="branding-edit-container">
            <div className="header-row">
              {(colorPreview !== primaryDefault || base64Image !== '') && (
                <a onClick={() => restoreDefaults()}>Restore Defaults</a>
              )}
              <button
                onClick={() => setIsStartMode(true)}
                className="btn-cancel"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-save"
                onClick={() => handleSubmit(onSubmit)}
              >
                Save
              </button>
            </div>
            <div className="edit-row">
              <p>
                Customize the Cloverly app to match your company’s visual style.
                This will apply to the primary and sub accounts.
              </p>
              <div className="col-container">
                <div className="editor-col-1">
                  <h2>Logo</h2>
                  <p className="text-small">
                    This logo will be displayed in the app header.
                    <br />
                    Recommended size: 40px tall.
                  </p>
                  <FileSelectorWidget
                    clearImage={clearFileSelectorImage}
                    imageSelected={brandImageUpdated}
                    brandImageUpdated={base64Image =>
                      brandImageUpdated(base64Image)
                    }
                  />
                  <h2>Color</h2>
                  <p className="text-small">
                    This color will be used for buttons, links, and
                    <br />
                    highlights in the app.
                  </p>
                  <div className="color-picker-container">
                    <label>Hex</label>
                    <div className="color-picker">
                      <EditableInput
                        label="hex"
                        value={colorPreview}
                        onClick={handleClick}
                        onChange={handleChange}
                      />
                      <div className="picker" onClick={handleClick} />
                    </div>
                    {displayColorPicker && (
                      <div style={{ position: 'absolute', zIndex: '2' }}>
                        <div className="cover" onClick={handleClose} />
                        <ChromePicker
                          color={colorPreview}
                          disableAlpha={true}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </div>
                  <h2>Account Name</h2>
                  <p className="text-small">
                    This will appear in your app header and the header <br />
                    of any public receipts.
                  </p>
                  <Form.Control
                    className="account-name"
                    defaultValue={account.name}
                    id="name"
                    name="name"
                    isInvalid={errors.name}
                    {...register('name', { required: true })}
                  />
                  {errors.name && (
                    <p className="invalid-message">{errorMessages.name}</p>
                  )}
                </div>
                <div className="editor-col-2">
                  <h2>Preview</h2>
                  <ThemePreviewWidget
                    base64Image={base64Image}
                    themeColor={colorPreview}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  )
}

export default BrandingPage
