import React, { useRef, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useLoading } from 'model/hooks/useLoading'
import { formatCost } from 'model/utils/cost'
import { Col, Form } from 'react-bootstrap'
import './CheckoutForm.scss'
import { useCheckoutQueryParamContext } from 'view/pages/checkout-public/contexts/CheckoutQueryParamContext'

const CheckoutForm = ({
  estimateFromBE,
  setStripeId,
  setIsSuccess,
  handleGetPaymentConfirmation,
  determineIfUnderMinimumAmount,
}) => {
  const emailRef = useRef()
  const stripe = useStripe()
  const elements = useElements()

  const [stripeError, setStripeError] = useState()

  const { updateIsLoading } = useLoading()

  const [isValidEmailFormat, setIsValidEmailFormat] = useState(true)

  const { selectedProjectIsPortfolio, currency } =
    useCheckoutQueryParamContext()

  const handleSubmit = async e => {
    e.preventDefault()
    if (emailRef.current.value === '' || !emailRef.current.value) {
      return setIsValidEmailFormat(false)
    }
    updateIsLoading(true)
    if (!stripe || !elements || !isValidEmailFormat) {
      return updateIsLoading(false)
    }

    stripe
      .confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          receipt_email: emailRef.current.value,
        },
      })
      .then(res => {
        if (res.error) {
          setStripeError(res.error.message)
        }
        if (res.paymentIntent?.status === 'succeeded') {
          handleGetPaymentConfirmation(res.paymentIntent.id)
          localStorage.setItem('stripe_id', res.paymentIntent.id)
          setStripeId(res.paymentIntent.id)
          setIsSuccess(true)
        }
        updateIsLoading(false)
      })
  }

  const validateEmail = email => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const handleEmailBlur = () => {
    setIsValidEmailFormat(!!validateEmail(emailRef.current.value))
  }

  const calcAmount = (estimateFromBE, selectedProjectIsPortfolio) => {
    if (selectedProjectIsPortfolio) {
      return estimateFromBE?.transactions.reduce((total, transaction) => {
        return (
          total +
          transaction.cost.in_requested_currency.transaction_cost +
          transaction.cost.in_requested_currency.carbon_cost
        )
      }, 0)
    } else {
      return (
        estimateFromBE?.cost?.in_requested_currency.transaction_cost +
        estimateFromBE?.cost?.in_requested_currency.carbon_cost
      )
    }
  }

  return (
    <section className="stripe-checkout-form">
      <Form onSubmit={handleSubmit}>
        <PaymentElement />
        <Form.Group className="email-column" as={Col}>
          <Form.Label className="email-label" htmlFor="email">
            Email address
          </Form.Label>
          <Form.Control
            className={`email-field ${!isValidEmailFormat ? 'invalid' : ''}`}
            onChange={() => setIsValidEmailFormat(true)}
            onBlur={handleEmailBlur}
            ref={emailRef}
            id="email"
            type="email"
            required
          />
        </Form.Group>
        {!isValidEmailFormat && (
          <p className="email-validation-message">
            Your email address is incomplete.
          </p>
        )}
        {stripeError && (
          <p role="error" aria-atomic={true} className="stripe-error-container">
            {stripeError}
          </p>
        )}
        <button
          type="submit"
          disabled={!stripe || determineIfUnderMinimumAmount()}
        >
          Pay{' '}
          {formatCost(
            calcAmount(estimateFromBE, selectedProjectIsPortfolio),
            currency
          )}
        </button>
      </Form>
    </section>
  )
}

export default CheckoutForm
