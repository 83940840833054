import React, { forwardRef } from 'react'
import './Button.scss'

const ButtonLink = forwardRef((props, ref) => {
  const { href, disabled, newtab, cn, children } = props
  // eslint-disable-next-line
  return (
    <a
      ref={ref}
      href={!disabled ? href : undefined}
      target={newtab ? '_blank' : undefined}
      rel={newtab ? 'noreferrer' : undefined}
      className={`cloverly-btn btn-link${cn ? ` ${cn}` : ''}${
        disabled ? ` disabled` : ''
      }`}>
      {children}
    </a>
  )
})

export default ButtonLink
