import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from 'react-tooltip'
import InfoIcon from '../icons/info-icon/InfoIcon'
import './AppTooltip.scss'

const AppTooltip = ({
  placement,
  message,
  id,
  className = '',
  tooltipClassName = 'default-tooltip',
  isOutlined = true,
}) => (
  <div className="tooltip-container">
    <Tooltip id={id || 'appTooltip'} className={tooltipClassName}>
      {message}
    </Tooltip>
    <span
      data-tooltip-id={id || 'appTooltip'}
      data-effect="solid"
      data-border={true}
      data-place={placement}
      data-border-color="#999"
      data-text-color="#000"
      data-background-color="#fff"
      data-multiline={true}
    >
      {isOutlined ? (
        <InfoOutlinedIcon className={className} />
      ) : (
        <InfoIcon className={className} />
      )}
    </span>
  </div>
)

export default AppTooltip
