import React from 'react'

const Checkmark = ({ showBackground = true }) => (
  <div className="checkmark">
    <svg
      width="93"
      height="93"
      viewBox="0 0 93 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      {showBackground && (
        <circle opacity="0.15" cx="46.5" cy="46.5" r="46.5" fill="#02C98F" />
      )}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.577 30.9408C68.2666 32.3641 68.4825 34.8875 67.0593 36.5771L43.4731 64.5771C42.7646 65.4181 41.7412 65.9304 40.6434 65.9935C39.5456 66.0566 38.4703 65.665 37.6701 64.9108L25.2563 53.2093C23.6488 51.694 23.574 49.1624 25.0893 47.5549C26.6046 45.9474 29.1362 45.8726 30.7437 47.3879L40.0796 56.1881L60.9408 31.4231C62.364 29.7335 64.8874 29.5176 66.577 30.9408Z"
        fill="#00CC8A"
      />
    </svg>
  </div>
)

export default Checkmark
