import React from 'react'

/**
 * @returns {React.JSX.Element}
 * @constructor
 */
const HighDurabilityIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17 9.75C17.4142 9.75 17.75 10.0858 17.75 10.5C17.75 11.2647 17.6497 12.026 17.4518 12.7647C17.3446 13.1648 16.9333 13.4022 16.5332 13.295C16.1331 13.1878 15.8957 12.7765 16.0029 12.3764C16.1669 11.7644 16.25 11.1336 16.25 10.5C16.25 10.0858 16.5857 9.75 17 9.75Z" fill="#36444D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.42772 1.76874C10.1546 1.65555 11.8763 2.05699 13.375 2.92228C13.7337 3.12939 13.8566 3.58808 13.6495 3.9468C13.4424 4.30552 12.9837 4.42843 12.625 4.22132C11.3832 3.50436 9.95667 3.17174 8.52583 3.26553C7.09498 3.35931 5.72408 3.87528 4.58648 4.74819C3.44888 5.6211 2.59567 6.81175 2.13476 8.16957C1.67384 9.52739 1.62591 10.9914 1.99704 12.3764C2.10424 12.7765 1.86681 13.1878 1.46671 13.295C1.06661 13.4022 0.655356 13.1648 0.54815 12.7647C0.100241 11.0931 0.158083 9.32615 0.714362 7.68741C1.27064 6.04867 2.30037 4.61168 3.67334 3.55816C5.0463 2.50465 6.70084 1.88192 8.42772 1.76874Z" fill="#36444D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 12.25C8.86193 12.25 8.75 12.3619 8.75 12.5C8.75 12.6381 8.86193 12.75 9 12.75C9.13807 12.75 9.25 12.6381 9.25 12.5C9.25 12.3619 9.13807 12.25 9 12.25ZM7.25 12.5C7.25 11.5335 8.0335 10.75 9 10.75C9.9665 10.75 10.75 11.5335 10.75 12.5C10.75 13.4665 9.9665 14.25 9 14.25C8.0335 14.25 7.25 13.4665 7.25 12.5Z" fill="#36444D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.2198 4.99525C17.4991 5.30113 17.4776 5.77551 17.1717 6.05482L9.5057 13.0548C9.19983 13.3341 8.72544 13.3126 8.44614 13.0067C8.16683 12.7008 8.18838 12.2264 8.49425 11.9471L16.1603 4.94713C16.4661 4.66783 16.9405 4.68937 17.2198 4.99525Z" fill="#36444D" />
  </svg>
)

export default HighDurabilityIcon
