import React from 'react'
import { unsdgMap } from 'model/utils/unsdg'
import UnsdgListItem from './UnsdgListItem'

/**
 * @returns {React.JSX.Element}
 */
const UnsdgList = ({ list }) => {
  if (!list.length) return <></>
  return (
    <ul className="flex flex-col gap-4">
      {list.map((unsdgId, idx) => (
        <UnsdgListItem
          key={`unsdg-li-${unsdgId} ${idx}`}
          unsdg={unsdgMap.get(unsdgId)}
        />
      ))}
    </ul>
  )
}

export default UnsdgList
