import React from 'react'
import { Path, Svg } from '@react-pdf/renderer'

/**
 * @param {number} width
 * @param {number} height
 * @returns {React.JSX.Element}
 */
const LowDurabilityIcon = ({ width = 9, height = 9 }) => (
  <Svg width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d="M1.00005 9.75C0.585834 9.75 0.250048 10.0858 0.250048 10.5C0.250048 11.2647 0.350284 12.026 0.548197 12.7647C0.655403 13.1648 1.06666 13.4022 1.46675 13.295C1.86685 13.1878 2.10429 12.7765 1.99709 12.3764C1.8331 11.7644 1.75005 11.1336 1.75005 10.5C1.75005 10.0858 1.41426 9.75 1.00005 9.75Z" fill="#36444D" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M9.57228 1.76874C7.8454 1.65555 6.12373 2.05699 4.625 2.92228C4.26628 3.12939 4.14337 3.58808 4.35048 3.9468C4.55759 4.30552 5.01628 4.42843 5.375 4.22132C6.61681 3.50436 8.04333 3.17174 9.47417 3.26553C10.905 3.35931 12.2759 3.87528 13.4135 4.74819C14.5511 5.6211 15.4043 6.81175 15.8652 8.16957C16.3262 9.52739 16.3741 10.9914 16.003 12.3764C15.8958 12.7765 16.1332 13.1878 16.5333 13.295C16.9334 13.4022 17.3446 13.1648 17.4519 12.7647C17.8998 11.0931 17.8419 9.32615 17.2856 7.68741C16.7294 6.04867 15.6996 4.61168 14.3267 3.55816C12.9537 2.50465 11.2992 1.88192 9.57228 1.76874Z" fill="#36444D" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M9 12.25C8.86193 12.25 8.75 12.3619 8.75 12.5C8.75 12.6381 8.86193 12.75 9 12.75C9.13807 12.75 9.25 12.6381 9.25 12.5C9.25 12.3619 9.13807 12.25 9 12.25ZM7.25 12.5C7.25 11.5335 8.0335 10.75 9 10.75C9.9665 10.75 10.75 11.5335 10.75 12.5C10.75 13.4665 9.9665 14.25 9 14.25C8.0335 14.25 7.25 13.4665 7.25 12.5Z" fill="#36444D" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M0.780163 4.99525C0.500859 5.30113 0.522401 5.77551 0.82828 6.05482L8.4943 13.0548C8.80017 13.3341 9.27456 13.3126 9.55386 13.0067C9.83317 12.7008 9.81162 12.2264 9.50575 11.9471L1.83973 4.94713C1.53385 4.66783 1.05947 4.68937 0.780163 4.99525Z" fill="#36444D" />
  </Svg>
)

export default LowDurabilityIcon
