import React, { useEffect, useReducer, useRef } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { formatCost } from 'model/utils/cost'
import { nextMonth, shortDate } from 'model/utils/date'
import { NavLink } from 'react-router-dom'
import AppTooltip from 'view/components/app-tooltip/AppTooltip'
import EnvelopeIcon from 'view/components/icons/envelope-icon/EnvelopeIcon'
import '../../../AccountSettings.scss'
import AccountSettingsSubnav from '../../navs/AccountSettingsSubnav'
import CreditTable from '../../tables/CreditTable'
import InvoiceTable from '../../tables/InvoiceTable'
import NumOfApiCallsTable from '../../tables/NumOfApiCallsTable'

const BillingInvoicingPage = () => {
  const [state, dispatch] = useReducer(reducer, {})
  const { serviceRequestMulti, spreadResponses } = useService()
  const { account } = useUser()
  const sourceRef = useRef()

  const supportEmail = 'support@cloverly.com'

  useEffect(() => {
    const requests = [
      { path: '/api/credits?page=1&limit=5' },
      { path: '/api/invoices?page=1&limit=5' },
      { path: '/dashboard/calls' },
    ]
    serviceRequestMulti({ requests, sourceRef }).then(
      spreadResponses((creditsTable, invoicesTable, numOfApiCallsTable) => {
        dispatch({ type: 'setCredits', creditsTableData: creditsTable?.data })
        dispatch({
          type: 'setInvoices',
          invoicesTableData: invoicesTable?.data,
        })
        dispatch({
          type: 'setNumOfApiCalls',
          numOfApiCallsTableData: numOfApiCallsTable?.data,
        })
      })
    )
    // eslint-disable-next-line
  }, [account])

  function reducer(state, action) {
    switch (action.type) {
      case 'setCredits':
        state.creditsTableData = action.creditsTableData
        break
      case 'setInvoices':
        state.invoicesTableData = action.invoicesTableData
        break
      case 'setNumOfApiCalls':
        state.numOfApiCallsTableData = action.numOfApiCallsTableData
        break
    }
    return { ...state }
  }

  return (
    <div className="account-settings">
      <h1>Account Settings</h1>
      <AccountSettingsSubnav />
      <div className="billing-panels">
        <div className="col-left">
          <div className="panel">
            <div className="panel-header">
              <span>Invoicing</span>
            </div>
            <div className="panel-text-container">
              <EnvelopeIcon />
              <p>
                Invoices are sent to the account owner. If you have any
                question, please email <a>{supportEmail}</a>.
              </p>
              {/*
                // TODO: Following BE updates to the send_invoices_to feature, replace
                // the above text with below.
                <p>Invoices are sent to {account.send_invoices_to}</p>
              */}
            </div>
          </div>
          <div className="panel">
            <div className="panel-header">
              <span>Next Invoice</span>
            </div>
            <div className="panel-text-container">
              <p>{shortDate.format(nextMonth())}</p>
            </div>
          </div>
        </div>
        <div className="col-right panel">
          <div className="sub-panel-top">
            <div className="panel-header">
              <span>Account Balance</span>
              <AppTooltip
                id="account-balance"
                placement="top"
                className="inline-tooltip-icon"
                message={
                  <div>
                    Need to send invoices to a different address? <br />
                    Please email support@cloverly.com
                  </div>
                }
              />
            </div>
            <div className="price-label-large">
              {formatCost(account.balance_in_usd_cents / 100, 'USD')}
            </div>
          </div>
        </div>
      </div>
      <h2>Invoices</h2>
      <InvoiceTable
        tableData={state?.invoicesTableData}
        showPaginator={false}
      />
      {state?.invoicesTableData?.total > 0 && (
        <div className="page-button-container">
          <NavLink to="/account-settings/billing/invoices">
            View All Invoices <ArrowForwardIcon />
          </NavLink>
        </div>
      )}
      <h2>Credits</h2>
      <CreditTable tableData={state?.creditsTableData} showPaginator={false} />
      {state?.creditsTableData?.total > 0 && (
        <div className="page-button-container">
          <NavLink to="/account-settings/billing/credits">
            View All Credits <ArrowForwardIcon />
          </NavLink>
        </div>
      )}
      <h2>API Calls</h2>
      <NumOfApiCallsTable
        tableData={state?.numOfApiCallsTableData}
        isFullView={false}
      />
      {state?.numOfApiCallsTableData &&
        state?.numOfApiCallsTableData?.length !== 0 && (
          <div className="page-button-container">
            <NavLink to="/account-settings/billing/num-of-api-calls">
              View All API Calls <ArrowForwardIcon />
            </NavLink>
          </div>
        )}
    </div>
  )
}

export default BillingInvoicingPage
