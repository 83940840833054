import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { NavbarContextProps } from 'interfaces/Navbar.interface'
import { useLocation, useNavigate } from 'react-router'

const initialValue = {
  menuOpen: false,
  toggleNavbar: () => null,
  showExpandedOptionsWhenNavCollapsed: false,
  toggleExpandedOptionsWhenNavCollapsed: () => null,
  showAccountsModal: false,
  setShowAccountsModal: () => null,
  selectedRoute: '',
  handleSelectRoute: () => null,
} as NavbarContextProps

const NavbarContext = createContext<NavbarContextProps>(initialValue)

export const NavbarProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [menuOpen, setMenuOpen] = useState(true)
  //renders a different `ExpandedOptions` component when navbar is collapsed
  const [
    showExpandedOptionsWhenNavCollapsed,
    setShowExpandedOptionsWhenNavCollapsed,
  ] = useState(false)
  const [showAccountsModal, setShowAccountsModal] = useState(false)
  const [selectedRoute, setSelectedRoute] = useState(
    location.pathname.split('/')[1]
  )

  //for the rest of the page to adjust space for the navbar
  const setSidebarWidth = (value: string) => {
    setTimeout(() => {
      document.documentElement.style.setProperty('--sidebar-width', value)
    }, 20)
  }

  const toggleNavbar = () => {
    if (menuOpen) setSidebarWidth('52px')
    else setSidebarWidth('200px')
    setMenuOpen(prev => !prev)
  }

  const toggleExpandedOptionsWhenNavCollapsed = () => {
    setShowExpandedOptionsWhenNavCollapsed(prev => !prev)
  }

  const handleSelectRoute = (event: React.MouseEvent | string) => {
    const route =
      typeof event !== 'string' ? (event.target as HTMLButtonElement).id : event
    navigate(route)
  }

  //select a navbar item according to url pathname
  useEffect(() => {
    const currentRoute = location.pathname.split('/')[1]
    setSelectedRoute(currentRoute)
  }, [location.pathname])

  const value = {
    menuOpen,
    toggleNavbar,
    showExpandedOptionsWhenNavCollapsed,
    toggleExpandedOptionsWhenNavCollapsed,
    showAccountsModal,
    setShowAccountsModal,
    selectedRoute,
    handleSelectRoute,
  }
  return (
    <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
  )
}

export const useNavbarContext = () => useContext(NavbarContext)
