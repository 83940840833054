import React from 'react'
import { Project } from 'interfaces/Project.interface'
import ExternalLink from 'view/components/external-link/ExternalLink'
import CCPLogo from 'view/components/icons/ccp-logo/CCPLogo'
import CCPEligibilityCriteria from './CCPEligibilityCriteria'
import CCPHeader from './CCPHeader'
import ExpandablePanel from '../ExpandablePanel'

//core carbon principles
type Props = {
  project: Project
}
const CCPPanel = ({ project }: Props) => {
  const ccpWebsiteUrl = 'https://icvcm.org/assessment-framework/'
  return (
    <>
      {project.quality_markers?.ccp_eligible ? (
        <ExpandablePanel
          panelHeader={expanded => <CCPHeader expanded={expanded} />}
        >
          <section className="flex flex-col justify-between gap-4">
            <section className="flex gap-4">
              <section className="flex flex-col gap-3">
                <p className="font-bold text-2xl">Status: Certified</p>
                <CCPEligibilityCriteria />
              </section>
              <div className="size-[120px]">
                <CCPLogo />
              </div>
            </section>
            <ExternalLink
              className="font-normal text-sm"
              title="Learn more about  ICVCM’s CCP Framwork"
              url={ccpWebsiteUrl}
            />
          </section>
        </ExpandablePanel>
      ) : null}
    </>
  )
}

export default CCPPanel
