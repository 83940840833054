import { useState } from 'react'
import { useService } from 'model/hooks/useService'
import {
  clearStoredUser,
  getStoredAccount,
  getStoredInitialPath,
  getStoredUser,
  setStoredAccount,
  setStoredInitialPath,
  setStoredUser,
} from 'model/utils/userStorage'
import { useQuery, useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

//Get a user by its id from the server to update user data
const getUser = user => (user ? user : null)

const getAccount = account => (account ? account : null)

export const useUser = () => {
  const { serviceRequest } = useService()
  const [user, setUser] = useState(getStoredUser())
  const [account, setAccount] = useState(getStoredAccount())
  const [initialPath] = useState(getStoredInitialPath())
  const location = useLocation()

  const queryClient = useQueryClient()

  // call useQuery to update user data from server
  useQuery('user', () => getUser(user), {
    //enable only if a user is logged in
    enabled: !!user,
    onSuccess: data => setUser(data),
  })

  useQuery('account', () => getAccount(account), {
    enabled: !!account,
    onSuccess: data => setAccount(data),
  })

  const refreshUser = async () => {
    const session = await serviceRequest({ path: '/session' })
    const payload = await session
    updateUser(payload, account.slug)
    return {}
  }

  /**
   * Saves the current path (initial path) to local storage (used for deep linking).
   * Note: We do not want to set the initial path to any public path.
   */
  const pathname = location.pathname
  const updateInitialPath = () =>
    setStoredInitialPath(
      pathname !== '/' &&
        !pathname.startsWith('/login') &&
        !pathname.startsWith('/register') &&
        !pathname.startsWith('/reset-password') &&
        !pathname.startsWith('/forgot-password') &&
        !pathname.startsWith('/ecommerce-session') &&
        !pathname.startsWith('/project-map') &&
        !pathname.startsWith('/project-details-public') &&
        !pathname.startsWith('/details/receipt') &&
        !pathname.startsWith('/external-offset-purchase') &&
        !pathname.startsWith('/sustainability-report')
        ? pathname + location.search
        : getStoredInitialPath()
    )

  //meant to be called from useAuth
  const updateUser = (newUser, account = null) => {
    //set user in state
    setUser(newUser)

    //update user in localStorage
    setStoredUser(newUser)

    const newAccount =
      account != null && newUser.accounts
        ? newUser.accounts.find(acc => acc.slug === account)
        : Array.isArray(newUser.accounts) && newUser.accounts.length > 0
          ? newUser.accounts[0]
          : null

    if (newAccount) updateAccount(newAccount)

    //pre-populate user profile in React Query client
    queryClient.setQueryData('user', newUser)
  }

  const clearUser = () => {
    //update state
    setUser(null)

    //remove from localStorage
    clearStoredUser()

    //reset user to null in query client
    queryClient.setQueryData('user', null)
    queryClient.setQueryData('account', null)
  }

  const updateAccount = (newAccount, pendoUpdate = false) => {
    // changing account, so let's clear our products/marketplace cache
    localStorage.removeItem('products')

    setAccount(newAccount)

    setStoredAccount(newAccount)

    queryClient.setQueryData('account', newAccount)

    if (pendoUpdate && newAccount) {
      const pendoPayload = {
        account: {
          id: newAccount.id,
          name: newAccount.name,
          creationDate: newAccount.created_at,
        },
      }
      if (newAccount.parent_id) {
        pendoPayload.account.parentId = newAccount.parent_id
        pendoPayload.parentAccount = { id: newAccount.parent_id }
      }
      // console.log('Pendo Payload (identify):', pendoPayload)
      window.pendo.identify(pendoPayload)
    }
  }

  return {
    user,
    account,
    updateAccount,
    updateUser,
    clearUser,
    refreshUser,
    initialPath,
    updateInitialPath,
  }
}
