import React, { useEffect, useRef, useState } from 'react'
import { WebMercatorViewport } from '@math.gl/web-mercator'
import { mapConfig } from 'model/utils/react-map'
import { Card, Col, Row } from 'react-bootstrap'
import ReactMapGL, { Marker } from 'react-map-gl'
import PinIcon from 'view/components/icons/pin-icon/PinIcon'

const DashboardMap = ({ account, footprint }) => {
  const getMapStyle = () =>
    !account?.is_cobranding_enabled
      ? 'mapbox://styles/cloverly/ckykicffr64sj14qp56qaxksj'
      : 'mapbox://styles/cloverly/ckyx5v3yx000315mnoru09kic'
  const [pinPoints, setPinPoints] = useState([])
  const [defaultViewport] = useState({ latitude: 20, longitude: 0, zoom: 2 })
  const [viewport, setViewport] = useState(defaultViewport)
  const cardRef = useRef(null)

  /**
   * Effect for populating markers from top projects to map
   */
  useEffect(() => {
    const pinPoints = []
    if (footprint?.top_projects && footprint?.top_projects.length > 0)
      footprint?.top_projects.forEach(project =>
        pinPoints.push({
          latitude: project.latlng.x,
          longitude: project.latlng.y,
        })
      )
    if (pinPoints.length > 1) setViewport(getBoundsForPoints(pinPoints))
    else if (pinPoints.length === 1)
      setViewport({
        latitude: pinPoints[0].latitude,
        longitude: pinPoints[0].longitude,
        zoom: 9,
      })
    else setViewport(defaultViewport)
    setPinPoints(pinPoints)
    // eslint-disable-next-line
  }, [account, footprint])

  const applyToArray = (func, array) => func.apply(Math, array)

  const getBoundsForPoints = points => {
    const pointsLong = points.map(point => point.longitude)
    const pointsLat = points.map(point => point.latitude)
    return new WebMercatorViewport({
      width: cardRef?.current?.offsetWidth - 48 || 600,
      height: 480,
    }).fitBounds(
      [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
      ],
      { padding: 100 }
    )
  }

  return (
    <Row className="map">
      <Col>
        <Card ref={cardRef} body>
          <h3>Impact Area</h3>
          <div className="map-container">
            <ReactMapGL
              {...viewport}
              maxZoom={8}
              minZoom={1.5}
              mapStyle={getMapStyle()}
              className="react-map"
              mapboxAccessToken={mapConfig.mapboxAccessToken}
              onMove={evt => setViewport(evt.viewState)}
              onViewportChange={nextViewport => setViewport(nextViewport)}
            >
              {
                // display all markers on the map
                pinPoints?.map((pinPoint, idx) => (
                  <Marker
                    key={idx}
                    latitude={pinPoint.latitude}
                    longitude={pinPoint.longitude}
                    offset={mapConfig.markerOffset}
                  >
                    <PinIcon />
                  </Marker>
                ))
              }
            </ReactMapGL>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default DashboardMap
