import React, { useCallback, useEffect, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { Route, Routes } from 'react-router-dom'
import Button from 'view/components/button/Button'
import Subnav from 'view/components/subnav/Subnav'
import SubNavLink from 'view/components/subnav/SubNavLink'
import CreateReportModal from './components/CreateReportModal'
import FootprintHistory from './components/FootprintHistory'
import TransactionHistory from './components/TransactionHistory'
import TransactionReports from './components/TransactionReports'
import './Transactions.scss'

const Transactions = () => {
  const { serviceRequest } = useService()
  const { account } = useUser()

  const [showCreateReport, setShowCreateReport] = useState(false)

  const cachedFilter = JSON.parse(
    localStorage.getItem('cached-transactions-filter')
  )

  // to filter between Purchases and Refunds
  const [transactionStatus, setTransactionStatus] = useState(
    cachedFilter || 'purchased'
  )

  const [hasRefunds, setHasRefunds] = useState(false)

  //programmatically decide to display Refunds tab depending on if they exist
  useEffect(() => {
    serviceRequest({
      path: `/dashboard/transactions?page=1&state=refunded`,
      showLoading: false,
    }).then(data => {
      if (data?.transactions?.length > 0) {
        setHasRefunds(true)
      } else {
        //when user is in the "Refunds" tab and they switch to an account with no refunds
        if (transactionStatus === 'refunded') setTransactionStatus('purchased')
      }
    })
    // eslint-disable-next-line
  }, [account])

  const handleSetTransactionStatus = useCallback(status => {
    let prettyStatus = ''
    if (status.toLowerCase() === 'purchases') prettyStatus = 'purchased'
    if (status.toLowerCase() === 'refunds') prettyStatus = 'refunded'
    localStorage.setItem(
      'cached-transactions-filter',
      JSON.stringify(prettyStatus)
    )
    setTransactionStatus(prettyStatus)
  }, [])

  //always reset to Purchases tab when navigating to Transactions from a different route
  useEffect(() => {
    if (window.location.pathname === '/transactions')
      handleSetTransactionStatus('purchases')
    // eslint-disable-next-line
  }, [window.location.pathname])

  return (
    <>
      {
        <CreateReportModal
          setShowCreateReport={setShowCreateReport}
          showCreateReport={showCreateReport}
        />
      }

      <div className="transactions">
        <h1>Transactions</h1>
        <Button onClick={() => setShowCreateReport(true)}>Create Report</Button>
        <Subnav>
          <SubNavLink
            handleSetTransactionStatus={handleSetTransactionStatus}
            title="Purchases"
            href="/transactions"
            exact={true}
          />
          {hasRefunds && (
            <SubNavLink
              handleSetTransactionStatus={handleSetTransactionStatus}
              title="Refunds"
              href="/transactions/refunds"
              exact={false}
            />
          )}
          <SubNavLink
            title="Footprints"
            href="/transactions/footprints"
            exact={false}
          />
          <SubNavLink
            title="Reports"
            href="/transactions/reports"
            exact={false}
          />
        </Subnav>
        <Routes>
          <Route
            exact
            path=""
            element={<TransactionHistory selectedFilter={transactionStatus} />}
          />
          <Route
            exact
            path="refunds"
            element={<TransactionHistory selectedFilter={transactionStatus} />}
          />
          <Route exact path="reports" element={<TransactionReports />} />
          <Route exact path="footprints" element={<FootprintHistory />} />
        </Routes>
      </div>
    </>
  )
}

export default Transactions
