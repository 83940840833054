import React, { MouseEvent, useState } from 'react'
import { Portfolio } from 'interfaces/Portfolio.interface'
import { useUser } from 'model/hooks/account/useUser'
import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'
import { numberFormatter } from 'model/utils/number'
import CalculatorInputGroup from 'view/components/calculator-input-group/CalculatorInputGroup'
import AddCardModal from 'view/pages/account-settings/components/modals/AddCardModal'
import PurchaseModal from 'view/pages/marketplace/components/purchase-modal/PurchaseModal'
import { useMarketplaceContext } from 'view/pages/marketplace/MarketplaceContext'
import CalculatorEstimate from './CalculatorEstimate'

type Props = {
  portfolio?: Portfolio | null
  price: string
}

const PortfolioCalculator = ({ portfolio, price }: Props) => {
  const { account } = useUser()
  const {
    redirectToModal,
    setProject,
    projectEstimate,
    showModalWithValues,
    showEmptyStateModal,
  } = useMarketplaceContext()

  const [showAddCard, setShowAddCard] = useState(false)
  const [cachedEvent, setCachedEvent] = useState<
    MouseEvent<HTMLAnchorElement | HTMLButtonElement> | undefined
  >(undefined)

  const availableAmount = portfolio?.volume_in_tonnes
    ? portfolio?.volume_in_tonnes
    : 0

  const addToCart = (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    // if the account billing is stripe, but there's no stripe id, so the add cc modal
    if (account.billing_method === 'stripe' && !account.stripe_id) {
      setCachedEvent(e)
      setShowAddCard(true)
    } else {
      const portfolioToPurchase = { ...portfolio }
      portfolioToPurchase.productType = ProductType.PORTFOLIO
      portfolioToPurchase.cost_per_kg_carbon_in_usd_cents =
        (portfolioToPurchase.price_per_tonne_in_USD ?? 0) / 10
      setProject(portfolio)
      showProjectModal(e)
    }
  }

  const showProjectModal = (
    e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    e = e ?? cachedEvent
    redirectToModal(e, portfolio?.slug, ProductType.PORTFOLIO)
  }

  const decimals = availableAmount > 1000 ? 0 : 1

  return (
    <>
      {(showModalWithValues || showEmptyStateModal) && (
        <PurchaseModal projectEstimate={projectEstimate} />
      )}
      <AddCardModal
        showAddCard={showAddCard}
        setShowAddCard={setShowAddCard}
        addCardSuccessful={showProjectModal}
      />
      <section className="w-full flex flex-col rounded-xl border border-neutral-80">
        <section className="border-b border-b-neutral-80 p-4">
          <div className="flex justify-between items-center">
            <p className="text-[22px] font-bold">{price}</p>
            <p className="text-muted">
              {numberFormatter(decimals).format(availableAmount)} t available
            </p>
          </div>
          <CalculatorInputGroup
            theme="bg-transparent w-full [&_.tabs-and-inputs]:p-0 [&_.tabs-and-inputs]:w-full max-w-none"
            classname="w-full"
            placeholder="Enter amount"
            selectedProduct={portfolio}
            cost_per_kg_carbon_in_usd_cents={
              (portfolio?.price_per_tonne_in_USD ?? 0) * 100
            }
            shortTonneLabel={true}
            showToolbar={false}
          />
        </section>
        <CalculatorEstimate addToCart={addToCart} portfolio={portfolio} />
      </section>
    </>
  )
}

export default PortfolioCalculator
