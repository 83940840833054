const getPrimaryImg = (image_list?: any[]) => {
  const primaryImg = image_list?.find(imgObj => {
    return imgObj.type.toUpperCase() === 'THUMBNAIL' && imgObj.is_primary
  })
  return primaryImg?.url
}

/**
 * @param url
 */
const handleGenerateURL = (url: string) => {
  let result = url
  if (url && url.includes('.us-east-2')) {
    result = url.replace('.us-east-2', '')
  }
  return result
}

/**
 * Exports public defs.
 */
export { getPrimaryImg, handleGenerateURL }
