import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useTheme } from 'model/hooks/useTheme'
import { useToast } from 'model/hooks/useToast'
import {
  getStoredInitialPath,
  getStoredTargetPath,
  setStoredInitialPath,
  setStoredTargetPath,
} from 'model/utils/userStorage'
import { useNavigate } from 'react-router-dom'

export const useAuth = () => {
  const navigate = useNavigate()
  const { serviceRequest } = useService()
  const { updateUser, clearUser, updateInitialPath } = useUser()
  const { clearColor } = useTheme()
  const { warningToast } = useToast()

  const toastMessages = {
    unknownFailure:
      'Failed to log in. If you continue to see this message, email support@cloverly.com.',
    accountDeactivated:
      'Oh no! Your account has been deactivated. For reactivation help, email support@cloverly.com.',
    noAccounts:
      'Unable to log in at this time. Please try again later. If you continue to have issues logging in, please email support@cloverly.com.',
  }

  //log in
  const logIn = credentials => {
    serviceRequest({
      method: 'POST',
      path: '/session',
      data: { user: credentials },
    })
      .then(user => {
        if (Array.isArray(user.accounts) && user.accounts.length > 0) {
          updateUser(user)
          let targetPath = getStoredTargetPath()
          targetPath = targetPath === 'null' ? null : targetPath
          let initialPath = getStoredInitialPath()
          initialPath = initialPath === 'null' ? null : initialPath
          if (targetPath) {
            navigate(decodeURI(targetPath))
            setStoredTargetPath(null)
            setStoredInitialPath(null)
          } else if (initialPath) {
            navigate(decodeURI(initialPath))
            setStoredInitialPath(null)
          } else {
            navigate('/')
          }
        } else {
          // the user does not have access to any accounts, so deny access to the dashboard
          warningToast(toastMessages.noAccounts)
        }
      })
      .catch(e => {
        // set default message if BE doesn't provide an error message
        let errorMessage =
          e?.response?.data?.error ?? toastMessages.unknownFailure
        // set custom message in the case that the user's account is deactivated
        errorMessage =
          errorMessage === 'This account has been deactivated'
            ? toastMessages.accountDeactivated
            : errorMessage
        warningToast(errorMessage)
      })
  }

  //register
  const registerUser = credentials => {
    serviceRequest({
      path: '/user',
      method: 'POST',
      data: credentials,
    })
      .then(data => {
        //notify HubSpot of user's registration
        serviceRequest({
          path: `/notify/app_status_crm?key=${data?.accounts[0].public_key}`,
          method: 'POST',
        })
        updateUser(data)
        const targetPath = getStoredTargetPath()
        if (targetPath) {
          navigate(decodeURI(targetPath))
          setStoredTargetPath(null)
        } else {
          navigate('/')
        }
      })
      .catch(e => warningToast(e?.response?.data?.error))
  }

  //log out
  const logout = () => {
    updateInitialPath()
    serviceRequest({
      path: '/session',
      method: 'DELETE',
    })
      .then(() => {
        clearUser()
        clearColor()
        localStorage.removeItem('products')
        localStorage.removeItem('project-types')
        navigate('/login')
      })
      .catch(e => warningToast(e?.response?.data?.error))
  }

  return {
    logIn,
    logout,
    registerUser,
  }
}
