import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ExternalLinkIcon from '../icons/external-link-icon/ExternalLinkIcon'
import './InternalLink.scss'

type Props = {
  title: string
  url: string
  hasLinkIconAlways?: boolean
  className?: string
  opensNewTab?: boolean
}

const InternalLink = ({
  title,
  url,
  hasLinkIconAlways = false,
  className = '',
  opensNewTab,
}: Props) => {
  const [displayIconOnLink, setDisplayIconOnLink] = useState(false)

  return (
    <NavLink
      className={`internal-link ${className}`}
      to={url}
      {...(opensNewTab && { target: '_blank' })}
      onMouseOver={() => setDisplayIconOnLink(true)}
      onMouseOut={() => setDisplayIconOnLink(false)}
    >
      {title}
      {(displayIconOnLink || hasLinkIconAlways) && <ExternalLinkIcon />}
    </NavLink>
  )
}

export default InternalLink
