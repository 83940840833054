import React, { useEffect } from 'react'
import { NavbarProvider } from 'model/contexts/NavbarContext'
import { useUser } from 'model/hooks/account/useUser'
import { useQueryParams } from 'model/hooks/useQueryParam'
import { envStageOrProd } from 'model/utils/env'
import { FullStoryAPI } from 'react-fullstory'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { MarketplaceProvider } from '../../pages/marketplace/MarketplaceContext'
import NavBar from '../sidebar/NavBar'

const PrivateOnlyRoutes = () => {
  let { id } = useParams()
  const urlSearchParams = useQueryParams()
  const location = useLocation()
  const { user, account } = useUser()

  useEffect(() => {
    if (!user || !account) {
      console.error(
        'Could not initialize Pendo--user and/or account not defined.'
      )
    } else if (!window.pendo) {
      console.error('Could not initialize Pendo--window.pendo not defined.')
    } else if (!account.is_impersonated) {
      const pendoPayload = {
        visitor: {
          id: user.user_id,
          email: user.email,
          full_name: `${user.first_name} ${user.last_name}`,
          role: account.role,
          creationDate: user.created_at,
        },
        account: {
          id: account.id,
          name: account.name,
          creationDate: account.created_at,
        },
      }
      if (account.parent_id) {
        pendoPayload.account.parentId = account.parent_id
        pendoPayload.parentAccount = { id: account.parent_id }
      }
      // console.log('Pendo Payload (initialize):', pendoPayload)
      window.pendo.initialize(pendoPayload)

      // attach identify information to fullstory monitoring session for stage & prod
      if (envStageOrProd()) {
        FullStoryAPI('identify', user.id, {
          displayName: `${user.first_name} ${user.last_name}`,
          email: user.email,
        })
      }
    }
    // eslint-disable-next-line
  }, [])

  //redirect users to the marketing website for public details if not authenticated
  useEffect(() => {
    if (!user) {
      if (location.pathname.startsWith('/marketplace/project-details/')) {
        if (
          urlSearchParams?.get('weight') ||
          urlSearchParams?.get('weightUnits') ||
          urlSearchParams?.get('shopBy') ||
          urlSearchParams?.get('amount') ||
          urlSearchParams?.get('currency')
        ) {
          redirectToLogin()
        } else {
          redirectToPublicProjectDetails()
        }
      } else {
        redirectToLogin()
      }
    }
    // eslint-disable-next-line
  }, [])

  const redirectToLogin = () =>
    (window.location.href =
      '/login?view=' +
      encodeURIComponent(window.location.pathname + window.location.search))

  const redirectToPublicProjectDetails = () =>
    (window.location.href = `https://info.cloverly.com/project-details?project=${id}`)

  const pathname = window.location.pathname
  // wrap the Marketplace context around only relevant routes
  const isMarketplaceRoute =
    pathname.includes('marketplace') || pathname.length === 1

  return user ? (
    <div className="pages">
      <NavbarProvider>
        <NavBar />
      </NavbarProvider>
      {isMarketplaceRoute ? (
        <MarketplaceProvider>
          <Outlet />
        </MarketplaceProvider>
      ) : (
        <Outlet />
      )}
    </div>
  ) : (
    <></>
  )
}

export default PrivateOnlyRoutes
