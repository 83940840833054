import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { shortDate } from 'model/utils/date'
import { setPageTitle } from 'model/utils/page'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import CloverlyTooltip from 'view/shamrock/tooltip/Tooltip'
import AccountSettingsSubnav from '../navs/AccountSettingsSubnav'
import '../../AccountSettings.scss'

const OverviewPage = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [showSustainabilityControls, setShowSustainabilityControls] =
    useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const { refreshUser, user, account } = useUser()
  const { infoToast, warningToast } = useToast()
  const { serviceRequest } = useService()
  const currencyOptions = [
    'USD',
    'GBP',
    'EUR',
    'AUD',
    'CAD',
    'JPY',
    'NZD',
    'SGD',
  ]

  const errorMessages = {
    name: 'Please enter a name for this account.',
    pretty_slug: 'Please select a slug for your sustainability report.',
    timeZone: 'Please select your time zone.',
    display_public_pages: 'Please select is you want to display public pages.',
    display_kg: 'Please select your weight units preference.',
    default_currency: 'Please select your preferred currency.',
  }

  useEffect(() => {
    setPageTitle('Overview | Account Settings')
    setShowSustainabilityControls(account.display_public_pages)
    // console.log("Account:", account)
    // eslint-disable-next-line
  }, [])

  const enterEditMode = () => {
    reset()
    setIsEditMode(true)
  }

  const cancelChanges = () => {
    setIsEditMode(false)
  }

  const onSubmit = (data: any) => {
    data.display_kg = data.display_kg === 'true'
    serviceRequest({
      path: `/accounts/${account.slug}`,
      method: 'PUT',
      data,
    }).then((data: any) =>
      refreshUser().then(() => {
        if (data.error) {
          warningToast(data.error)
        } else {
          infoToast(data.success)
        }
        setIsEditMode(false)
      })
    )
  }

  const handleSetShowSustainabilityControls = (event: any) => {
    const value = event.target.value === 'true' || false
    setShowSustainabilityControls(value)
  }

  return (
    <div className="account-settings">
      <h1>Account Settings</h1>
      <AccountSettingsSubnav />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <td className="button-cell" colSpan={2}>
                  {!isEditMode && account.role === 'admin' && (
                    <Button
                      onClick={() => enterEditMode()}
                      className="btn-edit"
                    >
                      <EditIcon />
                      Edit
                    </Button>
                  )}

                  {isEditMode && (
                    <>
                      <Button
                        onClick={() => cancelChanges()}
                        className="btn-cancel"
                      >
                        Cancel
                      </Button>
                      <Button type="submit" className="btn-save">
                        Save
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="label-cell">Account Name</td>
                <td>
                  {!isEditMode && <>{account.name}</>}

                  {isEditMode && (
                    <>
                      <Form.Control
                        defaultValue={account.name}
                        id="name"
                        isInvalid={!!errors.name}
                        {...register('name', { required: true })}
                      />
                      {errors.name && (
                        <p className="invalid-message">{errorMessages.name}</p>
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td className="label-cell flex items-center gap-[6px]">
                  <span>Account Owner</span>
                  <CloverlyTooltip className="">
                    <div>
                      This is the owner of your Cloverly account and the
                      <br />
                      main email we contact. Email support@cloverly.com
                      <br />
                      if you would like to change the owner.
                    </div>
                  </CloverlyTooltip>
                </td>
                <td>
                  {user.first_name} {user.last_name}
                </td>
              </tr>
              <tr>
                <td className="label-cell">Production Enabled?</td>
                <td>
                  {account.production_enabled_at != null
                    ? `Yes, Production enabled on ${shortDate.format(
                        new Date(
                          account.production_enabled_at?.replace(/ /g, 'T')
                        )
                      )}`
                    : 'No'}
                </td>
              </tr>

              <tr>
                <td className="label-cell flex items-center gap-[6px]">
                  <span>Weight Units</span>
                  <CloverlyTooltip className="">
                    <div>
                      The unit of measurements Cloverly
                      <br />
                      uses to display youremission and offset
                      <br />
                      data. You can choose between lbs and kg.
                    </div>
                  </CloverlyTooltip>
                </td>
                <td>
                  {!isEditMode && (
                    <>{account.display_kg ? 'Metric (kg/t)' : 'US (lb/t)'}</>
                  )}

                  {isEditMode && (
                    <>
                      <Form.Select
                        defaultValue={account.display_kg}
                        id="display_kg"
                        isInvalid={!!errors.display_kg}
                        {...register('display_kg', { required: true })}
                      >
                        <option value="">Select...</option>
                        <option value="false">US (lb/t)</option>
                        <option value="true">Metric (kg/t)</option>
                      </Form.Select>
                      {errors.display_kg && (
                        <p className="invalid-message">
                          {errorMessages.display_kg}
                        </p>
                      )}
                    </>
                  )}
                </td>
              </tr>

              <tr>
                <td className="label-cell flex items-center gap-[6px]">
                  <span>Currency</span>
                  <CloverlyTooltip>
                    <div>Select your preferred currency.</div>
                  </CloverlyTooltip>
                </td>
                <td>
                  {!isEditMode && (
                    <>{account.default_currency?.toUpperCase()}</>
                  )}

                  {isEditMode && (
                    <>
                      <Form.Select
                        defaultValue={account.default_currency}
                        id="default_currency"
                        isInvalid={!!errors.default_currency}
                        {...register('default_currency', { required: true })}
                      >
                        <option value="">Select...</option>
                        {currencyOptions.map((option, index) => (
                          <option
                            value={option}
                            key={`currency-option-${index}`}
                          >
                            {option}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.default_currency && (
                        <p className="invalid-message">
                          {errorMessages.default_currency}
                        </p>
                      )}
                    </>
                  )}
                </td>
              </tr>

              <tr>
                <td className="label-cell flex items-center gap-[6px]">
                  <span>Display Public Pages</span>
                  <CloverlyTooltip>
                    <div>
                      By default, Cloverly has receipts for your transaction
                      <br />
                      and a sustainability report for your account accessible
                      <br />
                      via a public link. You can choose to disable these.
                    </div>
                  </CloverlyTooltip>
                </td>
                <td>
                  {!isEditMode && (
                    <>{account.display_public_pages ? 'Yes' : 'No'}</>
                  )}
                  {isEditMode && (
                    <>
                      <Form.Select
                        onChangeCapture={handleSetShowSustainabilityControls}
                        defaultValue={account.display_public_pages}
                        id="display_public_pages"
                        isInvalid={!!errors.display_public_pages}
                        {...register('display_public_pages', {
                          required: true,
                        })}
                      >
                        <option value="">Select...</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Form.Select>
                      {errors.display_public_pages && (
                        <p className="invalid-message">
                          {errorMessages.display_public_pages}
                        </p>
                      )}
                    </>
                  )}
                </td>
              </tr>

              {showSustainabilityControls && (
                <>
                  <tr>
                    <td className="label-cell">Sustainability Report</td>
                    <td className="pre-wrap">
                      <Link
                        target="_blank"
                        to={`/sustainability-report/${
                          account.pretty_slug || account.slug
                        }`}
                      >
                        {`${window.location.protocol}//${window.location.hostname}`}
                        /sustainability-report/
                        {account.pretty_slug
                          ? account.pretty_slug
                          : account.slug}
                      </Link>
                    </td>
                  </tr>
                </>
              )}

              <tr>
                <td className="label-cell flex items-center gap-[6px]">
                  <span>URL to Site</span>
                  <CloverlyTooltip>
                    <div>
                      Provide a URL to your site that will be
                      <br />
                      linked in your sustainability report.
                    </div>
                  </CloverlyTooltip>
                </td>
                <td>
                  {!isEditMode && <>{account.site_url || 'Not set'}</>}

                  {isEditMode && (
                    <>
                      <Form.Control
                        className="full-width"
                        defaultValue={account.site_url}
                        id="site_url"
                        {...register('site_url')}
                      />
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td className="label-cell flex items-center gap-[6px]">
                  <span>Logo URL</span>
                  <CloverlyTooltip>
                    <div>
                      Provide a URL for your logo to populate
                      <br />
                      on your sustainability report.
                    </div>
                  </CloverlyTooltip>
                </td>
                <td>
                  {!isEditMode && <>{account.logo_url || 'Not set'}</>}

                  {isEditMode && (
                    <>
                      <Form.Control
                        className="full-width"
                        defaultValue={account.logo_url}
                        id="logo_url"
                        {...register('logo_url')}
                      />
                    </>
                  )}
                </td>
              </tr>

              {
                // this will always be hidden since the parameter timeZone does not exist yet
                account.timeZone && (
                  <tr>
                    <td className="label-cell">Time Zone</td>
                    <td>
                      {!isEditMode && <>{account.timeZone || 'Not Set'}</>}

                      {isEditMode && (
                        <>
                          <Form.Control
                            defaultValue={account.timeZone}
                            id="timeZone"
                            isInvalid={!!errors.timeZone}
                            {...register('timeZone', { required: true })}
                          />
                          {errors.timeZone && (
                            <p className="invalid-message">
                              {errorMessages.timeZone}
                            </p>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </Form>
    </div>
  )
}

export default OverviewPage
