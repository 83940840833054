import React from 'react'
import { useCurrencyExchangeRates } from 'model/hooks/useCurrencyExchangeRates'
import BuyerChoicePreview from './buyer-choice-preview/BuyerChoicePreview'
import './OptionPreview.scss'
import PresetValuePreview from './preset-value-preview/PresetValuePreview'

const OptionPreview = React.memo(
  ({
    selectedOption,
    project = null,
    unit_type = 'CURRENCY',
    unit_value = 'USD',
    amount,
  }) => {
    const { currencyRates } = useCurrencyExchangeRates({ showLoading: true })

    const previewCaption = selectedOption => {
      switch (selectedOption) {
        case 'ANY':
          return 'Preview: Buyers can decide how much to purchase.'
        case 'FIXED':
        case 'DYNAMIC':
        case 'pendingSelection':
          return 'Preview: Buyers can only purchase a pre-set amount.'
        default:
          return 'Once you specify your purchase options, a preview of your selection will appear here.'
      }
    }

    return (
      <div className="options-preview">
        <div className="title">Selected options</div>
        {!selectedOption && <div className="no-preview"></div>}
        {['ANY'].includes(selectedOption) && (
          <BuyerChoicePreview unit_type={unit_type} unit_value={unit_value} />
        )}
        {['DYNAMIC', 'FIXED', 'pendingSelection'].includes(selectedOption) && (
          <PresetValuePreview
            project={project}
            unit_type={unit_type}
            unit_value={unit_value}
            amount={amount}
            currencyRates={currencyRates}
          />
        )}
        <div className="caption">{previewCaption(selectedOption)}</div>
      </div>
    )
  }
)

export default OptionPreview
