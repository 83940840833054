import React, { useEffect, useState } from 'react'
import { Image } from '@react-pdf/renderer'
import { Canvg } from 'canvg'

type Props = {
  url: string
}
const SVGPDFImage = ({ url }: Props) => {
  const [svgDataUri, setSvgDataUri] = useState('')

  /**
   * @param {string} svgString
   * @returns {Promise<string>}
   */
  const svgToDataUri = async (svgString: string) => {
    try {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      if (context) {
        const v = Canvg.fromString(context, svgString.trim())
        v.resize(256, 256)
        await v.render()
        return canvas.toDataURL('image/png')
      }
    } catch (error) {
      console.error('Error occurred:', error)
      return ''
    }
  }

  useEffect(() => {
    async function fetchSvgAndConvertToPng() {
      let dataUri = url
      const data = await fetch(url)
      const text = await data.text()
      // if an svg, convert dataUri to a png via Canvg
      if (text.startsWith('<?xml'))
        dataUri = (await svgToDataUri(text)) as string
      setSvgDataUri(dataUri || '')
    }

    fetchSvgAndConvertToPng().then()
  }, [url])

  return svgDataUri ? (
    <Image style={{ width: 9, height: 9 }} src={svgDataUri} cache={true} />
  ) : (
    <></>
  )
}

export default SVGPDFImage
