import React from 'react'
import { metricColors } from 'model/utils/portfolio/portfolioUtils'

type Props = {
  metric: {
    key: string
    value: number
  }[]
}

const PercentageBars = ({ metric }: Props) => {
  return (
    <section className="flex gap-[1px]">
      {metric.map((item, idx) => (
        <div
          style={{
            width: `${item.value * 100}%`,
            backgroundColor: metricColors[idx % metricColors.length],
          }}
          key={item.key}
          className="rounded-[1px] h-2"
        />
      ))}
    </section>
  )
}

export default PercentageBars
