import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { setPageTitle } from 'model/utils/page'
import DatePickerComponent from 'view/components/date-picker/DatePickerComponent'
import DashboardMap from './components/DashboardMap'
import DashboardProjects from './components/DashboardProjects'
import DashboardStats from './components/DashboardStats'
import NoBillingDashboard from './components/no-billing-dashboard/NoBillingDashboard'
import TagFilterDropdown from './components/TagFilterDropdown'
import './Dashboard.scss'

const Dashboard = () => {
  const { account } = useUser()
  const { serviceRequestMulti, spreadResponses } = useService()

  const today = dayjs()
  const lastYearDate = today.subtract(1, 'year')

  const [billingActivated, setBillingActivated] = useState()
  const [footprint, setFootprint] = useState({})
  const [dateRange, setDateRange] = useState<{
    startDate: Dayjs
    endDate: Dayjs
  }>({
    startDate: lastYearDate,
    endDate: today,
  })
  const [tag, setTag] = useState('')
  const [tagsList, setTagsList] = useState<{ value: string; label: string }[]>(
    []
  )
  const lastMillisecondOfDayEncoded = 'T23%3A59%3A59.999'

  const formattedTags = (tags: { key: string }[]) => {
    return tags.map(tag => ({ value: tag.key, label: tag.key }))
  }

  const handleSetStartDate = (date?: Dayjs) => {
    setDateRange(currentDate => ({
      ...currentDate,
      startDate: dayjs(date) ?? currentDate.startDate,
    }))
  }

  const handleSetEndDate = (date?: Dayjs) => {
    setDateRange(currentDate => ({
      ...currentDate,
      endDate: dayjs(date) ?? currentDate.endDate,
    }))
  }

  const handleTagChange = (value: string) => setTag(value)

  useEffect(() => {
    setPageTitle('Home')
  }, [])

  useEffect(() => {
    // set if billing is activated for the account (billing method is invoicing or strip with a stripe id
    const isBillingActivated =
      account &&
      (account?.billing_method === 'invoice' ||
        (account.billing_method === 'stripe' && account.stripe_id !== null))

    if (
      isBillingActivated ||
      account?.has_footprint ||
      account?.has_offset_data
    ) {
      const tagParam = tag && tag.length > 0 ? `&tag=${tag}` : ''

      // get footprints & tags for date range selected
      const requests = [
        {
          path: `/account/footprint?start_date=${
            dateRange.startDate.toISOString().split('T')[0]
          }&end_date=${
            dateRange.endDate.toISOString().split('T')[0]
          }${lastMillisecondOfDayEncoded}${tagParam}`,
        },
        ...(!tagsList.length ? [{ path: '/dashboard/tags' }] : []),
      ]
      serviceRequestMulti({ requests })
        .then(
          spreadResponses((footprintResponse, tagResponse) => {
            if (footprintResponse) setFootprint(footprintResponse?.data || {})
            if (tagResponse)
              setTagsList(
                formattedTags(
                  tagResponse?.data?.tags?.filter((tag: any) => tag.key?.length)
                ) || []
              )
          })
        )
        .catch(e => console.error(e))
    }

    setBillingActivated(isBillingActivated)
    // eslint-disable-next-line
  }, [dateRange, tag, account])

  return (
    <>
      {(billingActivated !== undefined || account?.has_footprint) && (
        <div className="dashboard">
          {
            // if billing is not activated (aka new account), show the no billing dashboard view
            !billingActivated &&
              !account?.has_footprint &&
              !account?.has_offset_data && <NoBillingDashboard />
          }
          {
            // if billing is activated, so the dashboard widgets
            (billingActivated ||
              account?.has_footprint ||
              account?.has_offset_data) && (
              <>
                <section className="py-[10px] pl-10 pr-[10px] w-full flex gap-2 border-b border-b-neutral-80">
                  <DatePickerComponent
                    dateRange={dateRange}
                    handleSetStartDate={handleSetStartDate}
                    handleSetEndDate={handleSetEndDate}
                  />
                  {!!tagsList?.length && (
                    <TagFilterDropdown
                      tagsList={tagsList}
                      onChange={handleTagChange}
                    />
                  )}
                </section>
                <DashboardStats account={account} footprint={footprint} />
                <DashboardProjects account={account} footprint={footprint} />
                <DashboardMap account={account} footprint={footprint} />
              </>
            )
          }
        </div>
      )}
    </>
  )
}

export default Dashboard
