import React, { useContext, useEffect, useState } from 'react'
import { useQueryParams } from 'model/hooks/useQueryParam'
import { useToast } from 'model/hooks/useToast'
import { formatCostInCurrency } from 'model/utils/cost'
import { isDirectCheckoutFlow } from 'model/utils/direct-checkout'
import {
  isValidCurrency,
  validateCurrency,
  validateIsFixedOrAny,
  validateShopBy,
  validateWeightUnit,
} from '../util/SearchParamValidations'

const CheckoutQueryParamContext = React.createContext()

export function useCheckoutQueryParamContext() {
  return useContext(CheckoutQueryParamContext)
}

export const CheckoutQPProvider = ({ children }) => {
  const { warningToast } = useToast()

  // default minimum purchase
  const DEFAULT_MIN_PURCHASE_AMOUNT = 300

  // location & query params
  const urlSearchParams = useQueryParams()

  const [queryParams] = useState({
    weightUnit: urlSearchParams?.get('weightUnit'),
    currency: urlSearchParams?.get('currency'),
    shopBy: urlSearchParams?.get('shopBy'),
    amount: urlSearchParams?.get('amount'),
    isFixedOrAny: urlSearchParams.get('isFixedOrAny'),
    tags: urlSearchParams.get('tags')?.split(','),
    note: urlSearchParams.get('note'),
    type: urlSearchParams.get('type'),
  })

  const [weightUnit, setWeightUnit] = useState(
    queryParams?.weightUnit?.toLowerCase() || null
  )
  const [currency, setCurrency] = useState(
    queryParams?.currency?.toLowerCase() || null
  )
  const [shopBy, setShopBy] = useState(
    queryParams?.shopBy?.toLowerCase() || null
  )
  const [amount, setAmount] = useState(Number(queryParams?.amount) || null)
  const [isFixedOrAny, setIsFixedOrAny] = useState(
    queryParams?.isFixedOrAny?.toLowerCase() || null
  )
  const [tags, setTags] = useState(queryParams?.tags || [])
  const [note, setNote] = useState(queryParams?.note || '')

  const [projectData, setProjectData] = useState(null)
  const [pageStatus, setPageStatus] = useState('')

  const [selectedProjectIsPortfolio, setSelectedProjectIsPortfolio] = useState(
    queryParams.type === 'portfolio' || false
  )

  const [initialLoad, setInitialLoad] = useState(false)

  // in usd cents
  const [minPurchaseAmount, setMinPurchaseAmount] = useState(
    DEFAULT_MIN_PURCHASE_AMOUNT
  )

  const handleDecideWeightUnit = (unitType, unitValue) =>
    unitType?.toLowerCase() === 'carbon' ? unitValue.toLowerCase() : 'tonnes'

  const handleSetFieldValues = checkout_data => {
    const {
      user_amount_type,
      user_amount_value,
      user_unit_type,
      user_unit_value,
      account_slug,
      status,
      custom_message,
      min_purchase_amount_in_usd_cents,
    } = checkout_data

    // set the minimum purchase amount if defined & a valid number
    if (!isNaN(min_purchase_amount_in_usd_cents))
      setMinPurchaseAmount(min_purchase_amount_in_usd_cents)

    setPageStatus(status)
    localStorage.setItem('success_message', JSON.stringify(custom_message))
    localStorage.setItem('account_slug', account_slug)
    localStorage.setItem(
      'areSettingsDynamic',
      user_amount_type?.toLowerCase() === 'dynamic'
    )
    if (user_amount_type?.toLowerCase() !== 'dynamic')
      localStorage.removeItem('areSettingsDynamic')
    if (
      user_amount_type?.toLowerCase() !== 'dynamic' &&
      (!weightUnit || !shopBy || !currency || !amount || !isFixedOrAny)
    ) {
      const buyersChoiceCurrency =
        isFixedOrAny === 'any' && isValidCurrency(currency) && currency

      setWeightUnit(
        handleDecideWeightUnit(user_unit_type, user_unit_value) || 'tonne'
      )
      setShopBy(user_unit_type?.toLowerCase() || 'currency')
      setAmount(user_amount_value || 10)
      setCurrency(buyersChoiceCurrency || user_unit_value || 'USD')
      setIsFixedOrAny(user_amount_type?.toLowerCase())
    }
    if (user_amount_type?.toLowerCase() === 'dynamic')
      //if it's "dynamic" from the server, set it to fixed, otherwise set it to whatever it came as (fixed or any)
      setIsFixedOrAny(
        user_amount_type?.toLowerCase() === 'dynamic'
          ? 'fixed'
          : user_amount_type?.toLowerCase() || 'any'
      )
  }

  const includeAnd = queryParams => (queryParams.length > 0 ? '&' : '')
  const pathname = window.location.pathname

  const pathIsProjectSelection =
    !pathname.includes('/choose-amount') &&
    !pathname.includes('/stripe-checkout') &&
    !pathname.includes('/purchase-confirmation') &&
    pathname.includes('project-selection')

  const isPageActive = pageStatus =>
    pageStatus ? pageStatus.toUpperCase() === 'ACTIVE' : false

  useEffect(() => {
    let queryParams = ''

    if (
      pathname.startsWith('/external-offset-purchase') ||
      isDirectCheckoutFlow()
    ) {
      if (shopBy) queryParams += includeAnd(queryParams) + `shopBy=${shopBy}`
      if (weightUnit)
        queryParams += includeAnd(queryParams) + `weightUnit=${weightUnit}`
      if (currency)
        queryParams += includeAnd(queryParams) + `currency=${currency}`
      if (isFixedOrAny)
        queryParams += includeAnd(queryParams) + `isFixedOrAny=${isFixedOrAny}`
      if (selectedProjectIsPortfolio)
        queryParams +=
          includeAnd(queryParams) +
          `type=${selectedProjectIsPortfolio ? 'portfolio' : 'project'}`
      if (amount !== null)
        queryParams += includeAnd(queryParams) + `amount=${amount}`
      if (!!tags.length > 0)
        queryParams += includeAnd(queryParams) + `tags=${tags}`
      if (note !== '') queryParams += includeAnd(queryParams) + `note=${note}`

      //make sure amount is 0 before going to Amount Selection page from Project Selection page
      if (pathIsProjectSelection && isFixedOrAny === 'any') {
        setAmount(0)
      }
      if (queryParams.length > 0) {
        const newUrl = window.location.pathname + '?' + queryParams
        window.history.replaceState({ path: newUrl }, '', newUrl)
      }
    }
  }, [
    shopBy,
    weightUnit,
    currency,
    amount,
    isFixedOrAny,
    pathIsProjectSelection,
    tags,
    note,
    pathname,
    selectedProjectIsPortfolio,
  ])

  useEffect(() => {
    validateIsFixedOrAny(setIsFixedOrAny, isFixedOrAny, pathname)
    validateWeightUnit(setWeightUnit, weightUnit)
    validateCurrency(setCurrency, currency)
    validateShopBy(setShopBy, shopBy)
  }, [isFixedOrAny, currency, weightUnit, shopBy, pathname])

  /**
   * Generates the minimum purchase amount warning toast.
   *
   * @param minimumAmount
   * @param currency
   * @returns {Id}
   */
  const minimumPurchaseWarningToast = (minimumAmount, currency) =>
    warningToast(
      `Minimum purchase amount is ${formatCostInCurrency(
        minimumAmount,
        currency,
        false,
        true,
        2,
        2
      )}`,
      'warning',
      false,
      'under-minimum'
    )

  const contextValue = {
    weightUnit,
    shopBy,
    amount,
    currency,
    isFixedOrAny,
    projectData,
    tags,
    note,
    pageStatus,
    setWeightUnit,
    setTags,
    setNote,
    setShopBy,
    setAmount,
    setCurrency,
    setIsFixedOrAny,
    handleSetFieldValues,
    setPageStatus,
    setProjectData,
    isPageActive,
    initialLoad,
    setInitialLoad,
    minPurchaseAmount,
    minimumPurchaseWarningToast,
    selectedProjectIsPortfolio,
    setSelectedProjectIsPortfolio,
  }

  return (
    <>
      <CheckoutQueryParamContext.Provider value={contextValue}>
        {children}
      </CheckoutQueryParamContext.Provider>
    </>
  )
}
