import React from 'react'
import './Spacer.scss'

type Props = {
  className: string
}

const Spacer = ({ className }: Props) => (
  <div className={`spacer-block ${className}`}></div>
)

export default Spacer
