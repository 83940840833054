import React from 'react'

const PlusIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="7" width="2" height="15" fill="#C4C4C4" />
    <rect
      y="8.5"
      width="2"
      height="15"
      transform="rotate(-90 0 8.5)"
      fill="#C4C4C4"
    />
  </svg>
)
export default PlusIcon
