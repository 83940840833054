import React from 'react'
import { formatCost } from 'model/utils/cost'
import VintageYearButton from 'view/components/vintage-year/vintage-year-button/VintageYearButton'
import { determinePrimaryImg } from '../../../../../../../project-details/components/ProjectDetailsUtility'
import '../../../../../../Checkout.scss'

const ProjectListItem = React.memo(({ project, toggleChecked }) => {
  const convert_kg_cents_to_t_dollars = cost => formatCost(cost * 10)
  const imageURL = determinePrimaryImg(project?.image_list)

  return (
    <div
      className={'project-item ' + (project.checked ? 'checked' : '')}
      onClick={() => toggleChecked(project)}
    >
      <div className="left-side">
        <img src={imageURL} />
        <div className="stats">
          {convert_kg_cents_to_t_dollars(
            project?.cost_per_kg_carbon_in_usd_cents
          )}{' '}
          / t
        </div>
      </div>
      <div className="right-side">
        <div className="project-type">{project?.type}</div>
        <div className="project-name">
          {project?.short_name || project?.name}
        </div>
        <div className="project-loc">
          {project?.city}, {project?.country}
        </div>
        {project?.active_tranche?.vintage_year &&
          !project?.active_tranche?.is_issued && (
            <div className="project-vintage">
              <VintageYearButton
                label={'Forward'}
                infoModalVerbiageSelection="checkout-admin"
                projectName={project?.short_name || project?.name}
                deliveryDate={project?.active_tranche?.delivery_date}
              />
            </div>
          )}
      </div>
    </div>
  )
})

export default ProjectListItem
