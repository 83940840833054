import React, { useEffect, useState } from 'react'
import { setPageTitle } from 'model/utils/page'
import FormContainer from 'view/components/form-container/FormContainer'
import OptionalLink from 'view/components/form-container/optional-link/OptionalLink'
import PublicPageWrapper from 'view/components/public-page-wrapper/PublicPageWrapper'
import SendEmailForm from './components/SendEmailForm'
import Submitted from './components/Submitted'
import './SendEmail.scss'

const SendEmail = () => {
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    setPageTitle('Reset Password')
  }, [])

  return (
    <PublicPageWrapper cn={'reset-password-wrapper'}>
      {submitted ? (
        <Submitted
          title={'Request Submitted'}
          info={'Check your email to continue'}
        />
      ) : (
        <FormContainer
          cloverlyLogo={true}
          title="Reset Password"
          footer={false}
        >
          <p className="instructions">
            Enter the email associated with your account, and Cloverly will send
            you a link to reset your password.
          </p>
          <SendEmailForm setSubmitted={setSubmitted} />
          <OptionalLink
            title={'Remember your password?'}
            linkTitle={'Back to Sign In'}
            to={'/login'}
            cn={'link'}
          />
        </FormContainer>
      )}
    </PublicPageWrapper>
  )
}

export default SendEmail
