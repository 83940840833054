import React, { ReactNode } from 'react'

type Props = {
  title: string
  children: ReactNode
}

const TechnologyItem = ({ title, children }: Props) => {
  return (
    <div className="flex flex-col gap-1 grow">
      <p className="font-semibold text-sm text-muted">{title}</p>
      <div className="flex gap-1">{children}</div>
    </div>
  )
}

export default TechnologyItem
