import React from 'react'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { formatCostInCurrency } from 'model/utils/cost'
import { useCheckoutQueryParamContext } from '../../contexts/CheckoutQueryParamContext'

const ChooseAmountButtons = ({ isCustom, amount, onValueChange }) => {
  const { shopBy, currency } = useCheckoutQueryParamContext()

  const amountOptions =
    shopBy === 'currency'
      ? [10, 25, 50, 100, 250, 'Custom']
      : [1, 5, 10, 20, 50, 'Custom']

  const isCurrencySymbol = amount => {
    let result = formatCostInCurrency(amount, currency, false, true)
    if (amount === 'Custom') result = 'Custom'
    if (shopBy === 'carbon') result = amount
    return result
  }

  return (
    <RadioGroup.Root
      value={isCustom ? 'Custom' : amount}
      onValueChange={onValueChange}
      className="amount-picker"
      aria-label="Amount"
    >
      {amountOptions.map(amount => (
        <RadioGroup.Item className="amount-button" key={amount} value={amount}>
          {isCurrencySymbol(amount)}
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  )
}

export default ChooseAmountButtons
