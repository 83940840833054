import React, { useState } from 'react'
import ExternalLinkIcon from '../icons/external-link-icon/ExternalLinkIcon'
import './ExternalLink.scss'

type Props = {
  title: string
  url: string
  className?: string
  hasLinkIconAlways?: boolean
}

const ExternalLink = React.forwardRef(
  ({ title, url, className = '', hasLinkIconAlways }: Props, ref: any) => {
    const [displayIconOnLink, setDisplayIconOnLink] = useState(false)

    return (
      <a
        ref={ref}
        className={'external-link ' + className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onMouseOver={() => setDisplayIconOnLink(true)}
        onMouseOut={() => setDisplayIconOnLink(false)}
      >
        {title}
        {(displayIconOnLink || hasLinkIconAlways) && <ExternalLinkIcon />}
      </a>
    )
  }
)

export default ExternalLink
