import React from 'react'
import { toast } from 'react-toastify'
import Cancel from 'view/components/icons/toast-icons/Cancel'
import Confirmation from 'view/components/icons/toast-icons/Confirmation'
import Info from 'view/components/icons/toast-icons/Info'
import Warning from 'view/components/icons/toast-icons/Warning'

export const useToast = () => {
  const determineIconType = iconType => {
    switch (iconType) {
      case 'warning':
        return <Warning />
      case 'close':
        return <Cancel />
      default:
        return
    }
  }

  const warningToast = (message, iconType, autoCloseTime = null, id) =>
    toast(message, {
      icon: () => determineIconType(iconType),
      autoClose: autoCloseTime ?? 5000,
      className: 'toast-warning',
      closeButton: true,
      toastId: `warning-toast-${id}`,
    })

  const infoToast = (message, autoCloseTime = null, id) =>
    toast(message, {
      icon: () => <Info />,
      autoClose: autoCloseTime ?? 5000,
      className: 'toast-info',
      closeButton: true,
      toastId: `info-toast-${id}`,
    })

  const confirmationToast = message =>
    toast(message, {
      icon: () => <Confirmation />,
      autoClose: 3000,
      className: 'toast-confirmation',
      closeButton: false,
      toastId: 'confirmation-toast',
    })

  return { warningToast, infoToast, confirmationToast }
}
