import React from 'react'
import { useTheme } from 'model/hooks/useTheme'
import { determineCurrencyRate, formatCost } from 'model/utils/cost'
import { formatWeightTypeAndAmount, getWeightUnit } from 'model/utils/units'
import ExternalLink from 'view/components/external-link/ExternalLink'
import { determinePrimaryImg } from '../../../project-details/components/ProjectDetailsUtility'
import { useCheckoutQueryParamContext } from '../../contexts/CheckoutQueryParamContext'
import './PurchaseDetails.scss'

const PurchaseDetails = ({
  project,
  amount: calculatedAmount,
  currencyRates,
  estimate,
  estimateFromBE,
  isOrderConfirmed,
  hideCost = false,
}) => {
  const { weightUnit, currency, shopBy, amount, selectedProjectIsPortfolio } =
    useCheckoutQueryParamContext()
  const { isAppCobranded } = useTheme()

  const isEstimateFromBEUndefined = () =>
    typeof estimateFromBE === 'undefined' || !estimateFromBE

  const handleRenderInputAmount = () => {
    if (shopBy === 'carbon') {
      return formatWeightTypeAndAmount(calculatedAmount || amount, weightUnit)
    } else {
      return formatCost(calculatedAmount || amount, currency)
    }
  }

  //determines whether the input amount is in kg or lbs when calculation goes over to tonnes
  const isMetricTonne = weightUnit === 'kg' || weightUnit === 'tonnes'

  const estimateFromFEWeightOutput = getWeightUnit({
    weightType: weightUnit,
    value: estimate?.total_weight,
    roundUnits: 0,
    format: '%v %u',
    isMT: isMetricTonne,
  })

  const totalWeightEstimateForPortfolio = () => {
    if (estimateFromBE?.transactions) {
      return estimateFromBE?.transactions.reduce((total, transaction) => {
        return total + transaction.total_co2e_in_kg
      }, 0)
    }
    return 0
  }

  const totalCostEstimateForPortfolio = () => {
    if (estimateFromBE?.transactions) {
      return estimateFromBE?.transactions.reduce((total, transaction) => {
        return (
          total +
          transaction.cost.in_usd_cents.transaction_cost +
          transaction.cost.in_usd_cents.carbon_cost
        )
      }, 0)
    }
    return 0
  }

  const totalCostInRequestedCurrencyEstimateForPortfolio = () => {
    if (estimateFromBE?.transactions) {
      return estimateFromBE?.transactions.reduce((total, transaction) => {
        return (
          total +
          transaction.cost.in_requested_currency.transaction_cost +
          transaction.cost.in_requested_currency.carbon_cost
        )
      }, 0)
    }
    return 0
  }

  //console.log('estimateFromBE', selectedProjectIsPortfolio, estimateFromBE)

  const serverSideEstimateWeightOutput = getWeightUnit({
    weightType: weightUnit,
    value: selectedProjectIsPortfolio
      ? totalWeightEstimateForPortfolio()
      : estimateFromBE?.total_co2e_in_kg,
    roundUnits: 0,
    format: '%v %u',
    isMT: isMetricTonne,
  })

  const isTotalInDollarOrCarbon = () => {
    const estimatedCarbonAmount = isEstimateFromBEUndefined()
      ? estimateFromFEWeightOutput
      : serverSideEstimateWeightOutput

    const totalCost =
      selectedProjectIsPortfolio && shopBy === 'currency'
        ? totalCostEstimateForPortfolio()
        : estimate?.total_cost
          ? estimate?.total_cost
          : estimateFromBE?.cost?.in_usd_cents?.transaction_cost +
            estimateFromBE?.cost?.in_usd_cents?.carbon_cost

    const totalCostInRequestedCurrency = selectedProjectIsPortfolio
      ? totalCostInRequestedCurrencyEstimateForPortfolio()
      : estimate?.total_cost
        ? estimate?.total_cost
        : estimateFromBE?.cost?.in_requested_currency?.transaction_cost +
          estimateFromBE?.cost?.in_requested_currency?.carbon_cost

    //console.log('totalCost', totalCost)
    //console.log('totalCostInRequestedCurrency', totalCostInRequestedCurrency)

    const estimatedDollarAmount = !isEstimateFromBEUndefined()
      ? formatCost(totalCostInRequestedCurrency, currency)
      : formatCost(
          totalCost / determineCurrencyRate(currency, currencyRates),
          currency
        )
    return shopBy === 'carbon' ? estimatedDollarAmount : estimatedCarbonAmount
  }

  const cobrandedClassName = isAppCobranded ? 'cobranded' : ''

  return (
    <div className={`purchase-details ${cobrandedClassName}`}>
      <header className="title">
        <h6>Purchase Details</h6>
      </header>
      {!isOrderConfirmed && (
        <span className="project-name">{project?.name}</span>
      )}
      {isOrderConfirmed && (
        <>
          <section className="title-and-external-link">
            <span className="project-name">{project?.name}</span>
            {!selectedProjectIsPortfolio && (
              <ExternalLink
                title="View Details"
                url={project?.project_url}
                hasLinkIconAlways
              />
            )}
          </section>
          {!!project?.image_list?.length > 0 && (
            <img
              className="panel-image"
              src={determinePrimaryImg(project?.image_list)}
              alt=""
            />
          )}
        </>
      )}
      <section
        className={`estimated-results ${isOrderConfirmed ? 'no-border' : ''}`}
      >
        <span className="amount-selected">{handleRenderInputAmount()}</span>
        {!(selectedProjectIsPortfolio && shopBy === 'currency' && hideCost) && (
          <span className="estimated-total">{isTotalInDollarOrCarbon()}</span>
        )}
      </section>
    </div>
  )
}

export default PurchaseDetails
