import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'view/components/button/Button'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import './Modal.scss'

const DeleteUserModal = ({
  title,
  deleteUser = null,
  showDeleteUser,
  setShowDeleteUser,
  deleteUserConfirmed,
}) => (
  <Modal
    size="lg"
    className="account-settings-modal"
    show={showDeleteUser}
    centered
  >
    <Modal.Body>
      <CloseDialogButton onClick={() => setShowDeleteUser(false)} />
      <h2>{title}</h2>
      <p>
        Are you sure you want to remove{' '}
        <strong>
          {deleteUser?.name
            ? deleteUser?.name
            : `${deleteUser?.first_name} ${deleteUser?.last_name}`}
        </strong>
        ? This action cannot be undone.
      </p>
      <div className="btn-group">
        <Button cn={'cancel'} onClick={() => setShowDeleteUser(false)}>
          Cancel
        </Button>
        <Button cn={'remove'} onClick={() => deleteUserConfirmed(true)}>
          {title}
        </Button>
      </div>
    </Modal.Body>
  </Modal>
)

export default DeleteUserModal
