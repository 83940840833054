import React from 'react'
import './CancelIcon.scss'

const CancelIcon = ({ fill = '#DC3545', onClick }) => (
  <svg
    onClick={onClick}
    className="cancel-icon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0001 1.66663C5.39591 1.66663 1.66675 5.39579 1.66675 9.99996C1.66675 14.6041 5.39591 18.3333 10.0001 18.3333C14.6042 18.3333 18.3334 14.6041 18.3334 9.99996C18.3334 5.39579 14.6042 1.66663 10.0001 1.66663ZM14.1667 12.9875L12.9876 14.1666L10.0001 11.1791L7.01258 14.1666L5.83342 12.9875L8.82092 9.99996L5.83342 7.01246L7.01258 5.83329L10.0001 8.82079L12.9876 5.83329L14.1667 7.01246L11.1792 9.99996L14.1667 12.9875Z"
      fill={fill}
    />
  </svg>
)

export default CancelIcon
