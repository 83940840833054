/**
 * Direct Checkout Cloverly API Documentation url.
 *
 * @returns {string}
 */
export const directCheckoutApiDocUrl =
  'https://docs.cloverly.com/docs/cloverly-api/bus00t7jbhpc8-direct-checkout'

/**
 * Determines via the hostname is the flow that should be used is direct checkout.
 *
 * @returns {boolean}
 */
export const isDirectCheckoutFlow = () =>
  /checkout/.test(window.location.hostname)

/**
 * Converts weight amount to given weight unit.
 * @param weightUnit
 * @param weightAmount
 * @returns {*}
 */
const convertWeight = (weightUnit, weightAmount) => {
  let tempWeightAmount = weightAmount
  if (weightUnit === 'tonnes') {
    tempWeightAmount = weightAmount * 1000
  } else if (weightUnit === 'lbs') {
    tempWeightAmount = weightAmount / 2.20462
  }
  return tempWeightAmount
}

/**
 * if shopBy === carbon, account for the weight type as it's not done in useEstimateCalculation for carbon estimations.
 *
 * @param shopBy
 * @param weightUnit
 * @param estimate
 * @param project
 * @returns {boolean}
 */
export const handleIsNotEnoughInventory = (
  shopBy,
  weightUnit,
  estimate,
  project
) => {
  const convertedWeight =
    shopBy === 'carbon'
      ? convertWeight(weightUnit, estimate?.total_weight)
      : estimate?.total_weight
  return convertedWeight > project?.available_carbon_in_kg
}

/**
 * Returns whether cost is less than the minimum amount.
 * @param cost
 * @param minimum
 * @returns {boolean}
 */
export const handleIsUnderMinimum = (cost, minimum) =>
  cost < minimum && cost !== 0 && !isNaN(cost)
