import React from 'react'

const InvoiceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M11.667 2.5V5.83333C11.667 6.05435 11.7548 6.26631 11.9111 6.42259C12.0674 6.57887 12.2793 6.66667 12.5003 6.66667H15.8337M11.667 2.5H5.83366C5.39163 2.5 4.96771 2.67559 4.65515 2.98816C4.34259 3.30072 4.16699 3.72464 4.16699 4.16667V15.8333C4.16699 16.2754 4.34259 16.6993 4.65515 17.0118C4.96771 17.3244 5.39163 17.5 5.83366 17.5H14.167C14.609 17.5 15.0329 17.3244 15.3455 17.0118C15.6581 16.6993 15.8337 16.2754 15.8337 15.8333V6.66667M11.667 2.5L15.8337 6.66667M6.66699 12.5H13.3337M9.16699 9.16667V15M6.66699 9.16667H13.3337V15H6.66699V9.16667Z"
      stroke="#60747A"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>
)

export default InvoiceIcon
