import React from 'react'

const SearchIcon = ({ fill = '#6c757d', onClick }) => (
  <svg
    onClick={onClick}
    className="search-icon"
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m10.9167 9.66667h-.6625l-.2292-.22917c.8167-.94583 1.3083-2.175 1.3083-3.52083 0-2.99167-2.42497-5.41667-5.41663-5.41667-2.99167 0-5.41667 2.425-5.41667 5.41667 0 2.99166 2.425 5.41663 5.41667 5.41663 1.34583 0 2.575-.4916 3.52083-1.3041l.22917.2291v.6584l4.16663 4.1583 1.2417-1.2417zm-5.00003 0c-2.07084 0-3.75-1.67917-3.75-3.75 0-2.07084 1.67916-3.75 3.75-3.75 2.07083 0 3.75 1.67916 3.75 3.75 0 2.07083-1.67917 3.75-3.75 3.75z"
      fill={fill}
    />
  </svg>
)

export default SearchIcon
