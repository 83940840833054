import React from 'react'
import ExternalLink from 'view/components/external-link/ExternalLink'
import ProjectInfoRequestLink from 'view/components/project-info-request-link/ProjectInfoRequestLink'
import ProjectDataField from './ProjectDataField'
import {
  getMechanismLabel,
  getRemovalTimePeriodLabel,
} from './ProjectDetailsUtility'
import RatingsDataField from './RatingsDataField'

const ProjectDetailsPanel = ({ project }) => {
  return (
    <section className="project-data">
      <h3>Project Details</h3>
      {project?.short_name && (
        <div className="project-data-field">
          <span className="title">Location</span>
          <span className="data">
            {project?.city}, {project?.country}
          </span>
        </div>
      )}
      <ProjectDataField title="Methodology Type" data={project?.type} />
      {getMechanismLabel(project?.offset_classification) !== 'Avoidance' && (
        <ProjectDataField
          title="Removal Time Period"
          data={getRemovalTimePeriodLabel(project?.removal_time_period)}
        />
      )}
      <ProjectDataField
        title="Mechanism"
        data={getMechanismLabel(project?.offset_classification)}
      />
      <ProjectDataField title="Registry" data={project.registry_name} />
      {project?.registry_link && (
        <ExternalLink
          url={project?.registry_link}
          title="Registry Listing"
          hasLinkIconAlways={true}
        />
      )}
      <ProjectDataField title="Project ID" data={project.registry_project_id} />
      <ProjectDataField
        title="Ratings"
        data={project?.project_ratings?.map((rating, idx) => (
          <RatingsDataField key={'rating-' + idx} rating={rating} idx={idx} />
        ))}
      />
      <ProjectInfoRequestLink
        projectId={project?.id}
        projectName={project?.shortName || project?.name}
      />
    </section>
  )
}

export default ProjectDetailsPanel
