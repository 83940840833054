import React, { useEffect, useState } from 'react'
import { useAccountUsers } from 'model/hooks/account/useAccountUsers'
import { useUser } from 'model/hooks/account/useUser'
import { setPageTitle } from 'model/utils/page'
import Button from 'view/components/button/Button'
import '../../AccountSettings.scss'
import AddUserModal from '../modals/AddUserModal'
import DeleteUserModal from '../modals/DeleteUserModal'
import AccountSettingsSubnav from '../navs/AccountSettingsSubnav'
import UserTable from '../tables/UserTable'

const UserPage = () => {
  const { account } = useUser()
  const { users, loadUsers, deleteUser, changeUserRole } = useAccountUsers()
  const [showCreateUser, setShowCreateUser] = useState(false)
  const [showDeleteUser, setShowDeleteUser] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)

  useEffect(() => {
    setPageTitle('Users | Account Settings')
  }, [])

  useEffect(() => {
    loadUsers()
    // eslint-disable-next-line
  }, [account])

  const setUserToDeleteConfirm = user => {
    setUserToDelete(user)
    setShowDeleteUser(true)
  }

  const deleteUserConfirmed = () => {
    deleteUser(userToDelete).then(data => {
      if (data) setShowDeleteUser(false)
    })
  }

  const changeUserRoleConfirmed = (user, newRole) => {
    changeUserRole(user, newRole).then()
  }

  const addUserConfirmed = () => loadUsers()

  return (
    <>
      <AddUserModal
        showCreateUser={showCreateUser}
        setShowCreateUser={setShowCreateUser}
        addUserConfirmed={addUserConfirmed}
      />
      <DeleteUserModal
        showDeleteUser={showDeleteUser}
        setShowDeleteUser={setShowDeleteUser}
        deleteUser={userToDelete}
        deleteUserConfirmed={deleteUserConfirmed}
        title="Remove User"
      />
      <div className="account-settings">
        <h1>Account Settings</h1>
        {account.role === 'admin' && (
          <Button onClick={() => setShowCreateUser(true)}>Add User</Button>
        )}
        <AccountSettingsSubnav />
        <UserTable
          users={users}
          removeUser={() => setShowDeleteUser(true)}
          setUserToDelete={setUserToDeleteConfirm}
          changeUserRoleConfirmed={changeUserRoleConfirmed}
        />
      </div>
    </>
  )
}

export default UserPage
