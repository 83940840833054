import React from 'react'

const WidgetEditIcon = () => (
  <svg
    fill="none"
    height="113"
    viewBox="0 0 173 113"
    width="173"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m11.9918 84.1021v-80.1207c0-1.59257 1.3003-2.89558 2.8896-2.89558h117.8806c1.59 0 2.89 1.30301 2.89 2.89558v80.1207c0 1.5926-1.3 2.8956-2.89 2.8956h-117.8806c-1.5893 0-2.8896-1.2886-2.8896-2.8956z"
      fill="#e2e2e2"
    />
    <path
      d="m132.762 88.0835h-117.8806c-2.1961 0-3.9732-1.7808-3.9732-3.9814v-80.12068c0-2.20064 1.7916-3.98142 3.9732-3.98142h117.8806c2.196 0 3.974 1.78078 3.974 3.98142v80.12068c0 2.2006-1.778 3.9814-3.974 3.9814zm-117.8806-85.91182c-.9969 0-1.806.81077-1.806 1.80974v80.12068c0 .999.8091 1.8097 1.806 1.8097h117.8806c.997 0 1.806-.8107 1.806-1.8097v-80.12068c0-.99897-.809-1.80974-1.806-1.80974z"
      fill="#004453"
    />
    <path d="m128.5 8.33923h-109.5875v69.74007h109.5875z" fill="#fff" />
    <path
      d="m128.515 79.1651h-109.6025c-.5924 0-1.0836-.4922-1.0836-1.0858v-69.74004c0-.59359.4912-1.08584 1.0836-1.08584h109.5875c.593 0 1.084.49225 1.084 1.08584v69.74004c.014.6081-.477 1.0858-1.069 1.0858zm-108.5189-2.1716h107.4349v-67.5684h-107.4349z"
      fill="#004453"
    />
    <path
      d="m1.08362 89.6906v-3.9091c0-.7963.65016-1.4478 1.4448-1.4478h142.58658c.795 0 1.445.6515 1.445 1.4478v3.9091c0 .7962-.65 1.4478-1.445 1.4478h-142.58658c-.79464 0-1.4448-.6516-1.4448-1.4478z"
      fill="#e2e2e2"
    />
    <path
      d="m145.115 92.2242h-142.58661c-1.387 0-2.52839-1.1437-2.52839-2.5336v-3.909c0-1.3899 1.14139-2.5337 2.52839-2.5337h142.58661c1.387 0 2.529 1.1438 2.529 2.5337v3.909c0 1.3899-1.127 2.5336-2.529 2.5336zm-142.58661-6.8046c-.20227 0-.36119.1593-.36119.362v3.909c0 .2027.15892.3619.36119.3619h142.58661c.203 0 .362-.1592.362-.3619v-3.909c0-.2027-.159-.362-.362-.362z"
      fill="#004453"
    />
    <path d="m109.241 24.511h-29.0114v7.5865h29.0114z" fill="#fff" />
    <path
      d="m109.256 33.1978h-29.0264c-.5924 0-1.0836-.4923-1.0836-1.0858v-7.5865c0-.5936.4912-1.0858 1.0836-1.0858h29.0114c.592 0 1.084.4922 1.084 1.0858v7.5865c.014.5935-.477 1.0858-1.069 1.0858zm-27.9428-2.1717h26.8448v-5.4147h-26.8448z"
      fill="#004453"
    />
    <path d="m67.183 24.511h-29.0115v37.3965h29.0115z" fill="#f5fafa" />
    <path
      d="m67.1975 62.9933h-29.026c-.5924 0-1.0836-.4922-1.0836-1.0858v-37.3964c0-.5936.4912-1.0859 1.0836-1.0859h29.0115c.5924 0 1.0836.4923 1.0836 1.0859v37.3964c.0145.6081-.4768 1.0858-1.0691 1.0858zm-27.9424-2.1717h26.8443v-35.2247h-26.8443z"
      fill="#004453"
    />
    <path d="m109.241 39.0034h-29.0114v7.5864h29.0114z" fill="#00cc8a" />
    <path
      d="m109.256 47.6757h-29.0264c-.5924 0-1.0836-.4922-1.0836-1.0858v-7.5865c0-.5935.4912-1.0858 1.0836-1.0858h29.0114c.592 0 1.084.4923 1.084 1.0858v7.5865c.014.5936-.477 1.0858-1.069 1.0858zm-27.9428-2.1717h26.8448v-5.4147h-26.8448z"
      fill="#004453"
    />
    <path d="m109.241 54.321h-29.0114v7.5865h29.0114z" fill="#fff" />
    <path
      d="m109.256 62.9933h-29.0264c-.5924 0-1.0836-.4922-1.0836-1.0858v-7.5864c0-.5936.4912-1.0859 1.0836-1.0859h29.0114c.592 0 1.084.4923 1.084 1.0859v7.5864c.014.6081-.477 1.0858-1.069 1.0858zm-27.9428-2.1717h26.8448v-5.4147h-26.8448z"
      fill="#004453"
    />
    <path
      d="m144.393 111.914c15.201 0 27.523-12.348 27.523-27.5803 0-15.2322-12.322-27.5803-27.523-27.5803s-27.523 12.3481-27.523 27.5803c0 15.2323 12.322 27.5803 27.523 27.5803z"
      fill="#00cc8a"
    />
    <path
      d="m144.393 113c-15.777 0-28.607-12.856-28.607-28.6662 0-15.8099 12.83-28.6663 28.607-28.6663s28.607 12.8564 28.607 28.6663c0 15.8102-12.83 28.6662-28.607 28.6662zm0-55.1608c-14.578 0-26.44 11.8864-26.44 26.4946s11.862 26.4942 26.44 26.4942 26.44-11.886 26.44-26.4942-11.862-26.4946-26.44-26.4946z"
      fill="#004453"
    />
    <path
      d="m131.722 91.732v5.2989h5.288l15.575-15.6072-5.288-5.2989zm24.923-14.4055c.549-.5502.549-1.4478 0-1.998l-3.294-3.301c-.549-.5501-1.445-.5501-1.994 0l-2.572 2.5771 5.288 5.2989c0 .0145 2.572-2.577 2.572-2.577z"
      fill="#fff"
    />
  </svg>
)

export default WidgetEditIcon
