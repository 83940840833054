import React, { ReactNode } from 'react'
import AppTooltip from 'view/components/app-tooltip/AppTooltip'

type Props = {
  is_purchasable: boolean
  vintageCount?: number
}

const ProjectPanelVintageInfo = ({
  is_purchasable,
  vintageCount,
}: Props): ReactNode | null => {
  if (is_purchasable)
    return vintageCount ? (
      <div className="bullet-point">
        • {vintageCount} vintage
        {vintageCount === 1 ? '' : 's'}
      </div>
    ) : null

  const SelectVintageTooltipMessage = (): ReactNode => (
    <div>
      This project requires several inputs to calculate unit pricing and
      delivery terms.
    </div>
  )

  return (
    <div className="bullet-point blue">
      <span>• Now accepting orders</span>
      <AppTooltip
        placement="top"
        id="select-vintage-tooltip"
        isOutlined={true}
        className="small-icon"
        message={<SelectVintageTooltipMessage />}
      />
    </div>
  )
}

export default ProjectPanelVintageInfo
