import React from 'react'

type Props = {
  fill?: string
}

const PriceTagIcon = ({ fill = '#007C02' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_2988_10447)">
        <path
          d="M2.97703 9.72703C2.87174 9.62162 2.81257 9.47875 2.8125 9.32977V2.8125H9.32977C9.47875 2.81257 9.62162 2.87174 9.72703 2.97703L16.7105 9.96047C16.8159 10.0659 16.8751 10.209 16.8751 10.3581C16.8751 10.5072 16.8159 10.6502 16.7105 10.7557L10.7578 16.7105C10.6523 16.8159 10.5093 16.8751 10.3602 16.8751C10.2111 16.8751 10.0681 16.8159 9.96258 16.7105L2.97703 9.72703Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.90625 6.75C6.37224 6.75 6.75 6.37224 6.75 5.90625C6.75 5.44026 6.37224 5.0625 5.90625 5.0625C5.44026 5.0625 5.0625 5.44026 5.0625 5.90625C5.0625 6.37224 5.44026 6.75 5.90625 6.75Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2988_10447">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PriceTagIcon
