import React, { ReactNode } from 'react'
import { Text, View } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { handleGenerateURL } from 'model/utils/image'
import { defaultMethodologyIconUrl } from 'model/utils/methodology'
import { durabilityMap, mechanismMap } from 'model/utils/technology'
import MainColumnSection from './MainColumnSection'
import SVGPDFImage from '../../common-components/SVGPDFImage'

type Props = {
  project: Project
  styles: any
}
const TechnologySection = ({ project, styles }: Props) => {
  /**
   * @param {string} methodology
   * @param {string} img
   * @returns {React.JSX.Element}
   */
  const MethodologyItem = ({
    methodology,
    url,
  }: {
    methodology: string
    url: string
  }) => {
    if (!methodology) return null
    url = handleGenerateURL(url || defaultMethodologyIconUrl)
    return (
      <TechnologyListItem
        title="Methodology"
        label={methodology}
        icon={<SVGPDFImage url={url} />}
      />
    )
  }

  /**
   * @param {string} mechanism
   * @returns {React.JSX.Element}
   */
  const MechanismItem = ({ mechanism }: { mechanism: string }) => {
    if (!mechanism) return null
    mechanism = mechanism.trim().toUpperCase()
    const mechanismItem = mechanismMap.get(mechanism)
    if (mechanismItem)
      return (
        <TechnologyListItem
          title="Mechanism"
          label={mechanismItem.label}
          icon={mechanismItem.pdfIcon}
        />
      )
    return null
  }

  /**
   * @param {string} mechanism
   * @param {string} durability
   * @returns {React.JSX.Element}
   */
  const DurabilityItem = ({
    mechanism,
    durability,
  }: {
    mechanism: string
    durability: string
  }) => {
    if (!durability || !mechanism) return null
    mechanism = mechanism.trim().toUpperCase()
    durability = durability.trim().toUpperCase()
    if (mechanism !== 'REMOVAL') return null
    const durabilityItem = durabilityMap.get(durability)
    if (durabilityItem)
      return (
        <TechnologyListItem title="Permanence" label={durabilityItem.label} />
      )
    return null
  }

  /**
   * @param {string} title
   * @param {string} label
   * @param {React.JSX.Element} icon
   * @returns {React.JSX.Element}
   */
  const TechnologyListItem = ({
    title,
    label,
    icon,
  }: {
    title: string
    label: string
    icon?: ReactNode
  }) => {
    if (!title || !label) return null
    return (
      <View style={styles.tableCell}>
        <Text style={styles.tableHeader}>{title}</Text>
        <View style={styles.ratingBodyCell}>
          {icon && <View style={styles.ratingIconBox}>{icon}</View>}
          <Text style={{ maxWidth: 100 }}>{label}</Text>
        </View>
      </View>
    )
  }

  return (
    !!project && (
      <MainColumnSection
        title="Technology"
        styles={styles}
        includeBreaker={false}
      >
        <View style={styles.tableContainer}>
          <MethodologyItem
            methodology={project.type}
            url={project.methodology_icon_url as string}
          />
          <MechanismItem mechanism={project.offset_classification} />
          <DurabilityItem
            durability={project.removal_time_period}
            mechanism={project.offset_classification}
          />
        </View>
      </MainColumnSection>
    )
  )
}

export default TechnologySection
