import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { Col, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import './LeadGenRequestDetailsModal.scss'

const LeadGenNotifySuccessModal = React.memo(
  ({
    product,
    showModal = false,
    setShowModal,
  }: {
    product: any
    showModal: boolean
    setShowModal: (val: boolean) => void
  }) => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm()
    const { infoToast, warningToast } = useToast()
    const { serviceRequest } = useService()
    const { account, user } = useUser()

    const errorMessages = {
      required:
        'This field is required. If unsure, you can enter an estimate and call out details in the field below.',
    }

    const onSubmit = (data: any) => {
      console.log(
        `Submitting request for product: ${product.id} with data:`,
        data
      )
      if (data.weightType === 'tonnes') {
        data.weightType = 'kg'
        data.weightValue = data.weightValue * 1000
      }

      const requestData = {
        account_id: account.id,
        email: user.email,
        comments: data.comments,
        requested_carbon: {
          units: data.weightType,
          value: parseFloat(data.weightValue),
        },
        vintage_year_range: undefined,
      }

      if (data.vintageYearRange) {
        requestData.vintage_year_range = data.vintageYearRange
      }

      serviceRequest({
        path: `/project/${product.id}/lead-generation`,
        method: 'POST',
        includeAccountSlug: false,
        data: requestData,
      })
        .then(() => {
          infoToast('Your request has been submitted successfully.')
          setShowModal(false)
          reset()
        })
        .catch((e: any) => {
          warningToast(
            e.response?.data?.Message || 'Could not request at this time.',
            'warning'
          )
          console.error(e)
        })
    }

    const onCancel = (e?: any) => {
      if (e) e.preventDefault()
      setShowModal(false)
      reset()
    }

    return (
      <>
        <Modal
          onHide={onCancel}
          show={showModal}
          size="lg"
          className="lead-gen-notify-success-modal"
          centered
        >
          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <CloseDialogButton onClick={onCancel} />
              <h2>Add request details</h2>
              <p>
                Tell us more about your interest in this project: How much
                carbon are you looking for? Do you have specific parameters or
                requirements for your purchase?
              </p>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="carbon-amount"
              >
                <Form.Label>Desired Carbon Amount</Form.Label>
                <div className="carbon-input-container">
                  <Form.Control
                    type="number"
                    placeholder="0"
                    isInvalid={!!errors?.weightValue}
                    {...register('weightValue', {
                      required: errorMessages.required,
                    })}
                  />
                  <select
                    defaultValue="tonnes"
                    className="form-select"
                    {...register('weightType')}
                  >
                    <option value="tonnes">tonnes</option>
                    <option value="lbs">lbs</option>
                    <option value="kg">kg</option>
                  </select>
                </div>
                {errors?.weightValue && (
                  <p className="invalid-message">
                    {errors?.weightValue.message?.toString()}
                  </p>
                )}
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="vintage-years"
              >
                <Form.Label>Requested Delivery Year(s)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. 2023 - 2025"
                  isInvalid={!!errors?.vintageYearRange}
                  {...register('vintageYearRange')}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="additional-information"
              >
                <Form.Label>Additional Information</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Add a message (optional)"
                  isInvalid={!!errors?.comments}
                  {...register('comments')}
                />
              </Form.Group>
              <footer>
                <Button cn="cancel" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit">Submit</Button>
              </footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    )
  }
)

export default LeadGenNotifySuccessModal
