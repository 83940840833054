import React from 'react'
import { Image, View } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { handleGenerateURL } from 'model/utils/image'
import MainColumnSection from './MainColumnSection'

type Props = {
  project: Project
  styles: any
}
const ProjectMediaSection = ({ project, styles }: Props) => {
  if (!project?.image_list?.length) return null

  return (
    <MainColumnSection
      title="Project Media"
      styles={styles}
      verticalLayout={false}
    >
      <View style={styles.imageTileBox}>
        {project.image_list
          .sort((a, b) =>
            (a.is_primary ? 1 : 0) < (b.is_primary ? 1 : 0) ? 1 : -1
          )
          .map((image, idx) => (
            <View style={styles.imageTileContainer} key={idx}>
              <Image
                key={idx}
                style={styles.imageTile}
                source={handleGenerateURL(image?.url)}
                cache={true}
              />
            </View>
          ))}
      </View>
    </MainColumnSection>
  )
}

export default ProjectMediaSection
