import React from 'react'
import { Path, Svg } from '@react-pdf/renderer'

/**
 * @param {number} width
 * @param {number} height
 * @returns {React.JSX.Element}
 */
const AvoidanceMechanismIcon = ({ width = 9, height = 9 }) => (
  <Svg width={width} height={height} viewBox="0 0 18 18">
    <Path
      d="M9 1L15.9282 5V13L9 17L2.0718 13V5L9 1Z"
      stroke="#2E6775"
      strokeWidth="1.60714"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M11.5 6.5L6.5 11.5"
      stroke="#2E6775"
      strokeWidth="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M6.5 6.5L11.5 11.5"
      stroke="#2E6775"
      strokeWidth="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

export default AvoidanceMechanismIcon
