import React, { ReactNode } from 'react'
import { Text, View } from '@react-pdf/renderer'

type Props = {
  title: string | ReactNode
  styles: any
  children: ReactNode
  verticalLayout?: boolean
  includeBreaker?: boolean
}
const MainColumnSection = ({
  title,
  styles,
  children,
  verticalLayout = false,
  includeBreaker = true,
}: Props) => {
  if (!styles) return null
  return (
    <>
      {includeBreaker && <View style={styles.mainColumnBreaker}></View>}
      <View
        style={
          verticalLayout
            ? styles.mainColumnContainerVertical
            : styles.mainColumnContainerHorizontal
        }
      >
        {typeof title === 'string' && (
          <Text style={styles.mainColumnSection}>{title}</Text>
        )}
        {typeof title !== 'string' && (
          <View style={styles.mainColumnSection}>{title}</View>
        )}
        {children && <>{children}</>}
      </View>
    </>
  )
}

export default MainColumnSection
