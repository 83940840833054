import React, { useEffect } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { setPageTitle } from 'model/utils/page'
import BillingCardPage from './billing-sub-pages/BillingCardPage'
import BillingInvoicingPage from './billing-sub-pages/BillingInvoicingPage'
import BillingNoCardPage from './billing-sub-pages/BillingNoCardPage'
import '../../AccountSettings.scss'

const BillingPage = () => {
  const { account, refreshUser } = useUser()

  useEffect(() => {
    setPageTitle('Billing | Account Settings')
    refreshUser()
  }, [])

  return (
    <>
      {account?.billing_method === 'invoice' && <BillingInvoicingPage />}
      {account?.billing_method === 'stripe' && account.stripe_id !== null && (
        <BillingCardPage />
      )}
      {account?.billing_method === 'stripe' && account.stripe_id === null && (
        <BillingNoCardPage />
      )}
    </>
  )
}

export default BillingPage
