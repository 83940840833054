import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import EastIcon from '@mui/icons-material/East'
import WestIcon from '@mui/icons-material/West'
import { usePaymentContext } from 'view/pages/checkout-admin/contexts/CheckoutContext'
import '../../../../../Checkout.scss'
import ProjectListFilter from './project-list-filter/ProjectListFilter'
import ProjectListItem from './project-list-item/ProjectListItem'
import './ProjectList.scss'

const ProjectList = React.memo(
  ({
    projects = [],
    filterSet = null,
    setFilterSet = null,
    hasChecked = false,
    type = 'source',
    title = 'default',
    setAll = undefined,
    setChecked = undefined,
  }) => {
    const {
      generateFilter,
      toggleChecked,
      filterTypes,
      clearChecked,
      errors,
      getValues,
    } = usePaymentContext()

    const projectFilter = project => {
      if (
        project?.active_tranche?.vintage_year &&
        !project.active_tranche?.is_issued &&
        !getValues('include_forward_credits')
      )
        return false
      return (
        (type === 'source' ? !project.selected : project.selected) &&
        generateFilter(filterSet, project)
      )
    }

    const hasCheckedFiltered = projects =>
      projects?.length > 0 &&
      projects?.filter(item => projectFilter(item)).length > 0

    return (
      <div
        className={
          'project-list ' +
          (type === 'dest' && errors?.project_selection
            ? 'project-selection-error'
            : '')
        }
      >
        <h4 className="section-title">{title}</h4>
        <div className="list-controls">
          <ProjectListFilter
            filterSet={filterSet}
            setFilterSet={setFilterSet}
            filterTypes={filterTypes}
            clearChecked={clearChecked}
          />
        </div>
        <div className="list-container">
          <div className="item-container">
            {projects?.length > 0 &&
              projects
                ?.filter(item => projectFilter(item))
                .map((project, i) => (
                  <ProjectListItem
                    key={`project-${i}`}
                    project={project}
                    toggleChecked={toggleChecked}
                  />
                ))}
          </div>
          <div
            className={'button-container ' + (hasChecked ? 'has-checked' : '')}
          >
            <button
              className={'add-large ' + type}
              onClick={e => setChecked(e)}
            >
              {type !== 'source' && <WestIcon />}
              {type === 'source' ? 'Add' : 'Remove'} selected
              {type === 'source' && <EastIcon />}
            </button>
            {type === 'dest' && (
              <button
                className={
                  'add-large remove-all ' +
                  (hasCheckedFiltered(projects) ? 'enabled' : '')
                }
                onClick={e => setAll(e)}
              >
                <DeleteIcon />
                Remove all
              </button>
            )}
          </div>
        </div>
        {type === 'dest' && errors?.project_selection && (
          <p className="invalid-message">{errors?.project_selection.message}</p>
        )}
      </div>
    )
  }
)

export default ProjectList
