const inputFormatExpiration = value => {
  value = value.replace(/[^\d]/g, '')
  // if the first two digits is greater then 12, then we can assume we need a preceding zero
  value = value.slice(0, 2) <= 12 ? value : `0${value}`
  // add slash if there are more then 2 chars
  return value.length <= 2
    ? value
    : `${value.slice(0, 2)} / ${value.slice(2, 4)}`
}

export { inputFormatExpiration }
