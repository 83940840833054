import React, { useEffect } from 'react'
import { isDirectCheckoutFlow } from 'model/utils/direct-checkout'
import { Outlet } from 'react-router-dom'
import { CheckoutQPProvider } from 'view/pages/checkout-public/contexts/CheckoutQueryParamContext'

const CheckoutRoutes = () => {
  //initialize pendo
  useEffect(() => {
    const pathname = window.location.pathname
    const checkoutSlug = isDirectCheckoutFlow()
      ? pathname.split('/')[1]
      : pathname.split('/')[2]
    if (!window.pendo) {
      console.error('Could not initialize Pendo--window.pendo not defined.')
    } else if (checkoutSlug) {
      const pendoPayload = {
        visitor: { checkout_slug: checkoutSlug },
      }
      window.pendo.initialize(pendoPayload)
    }
  }, [])

  return (
    <CheckoutQPProvider>
      <Outlet />
    </CheckoutQPProvider>
  )
}

export default CheckoutRoutes
