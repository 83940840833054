import React from 'react'

const GraphIcon = () => (
  <svg
    fill="none"
    height="54"
    viewBox="0 0 54 54"
    width="54"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m3.42429 53.356c-1.53746 0-2.791478-1.2541-2.791478-2.7829v-47.14588c0-1.53746 1.254018-2.791478 2.791478-2.791478h47.13731c1.5374 0 2.7914 1.254018 2.7914 2.791478v47.13728c0 1.5374-1.254 2.7829-2.7914 2.7829h-47.13731z"
      fill="#fff"
    />
    <path
      d="m50.5643 1.28837c1.1853 0 2.1387.96199 2.1387 2.1387v47.13723c0 1.1853-.9619 2.1473-2.1387 2.1473h-47.13723c-1.1853 0-2.1387-.962-2.1387-2.1473v-47.13723c0-1.1853.96199-2.1387 2.1387-2.1387zm0-1.28837h-47.13723c-1.88961 0-3.42707 1.53746-3.42707 3.42707v47.13723c0 1.8982 1.53746 3.4357 3.42707 3.4357h47.13723c1.8982 0 3.4271-1.5375 3.4271-3.4357v-47.13723c.0086-1.88961-1.5289-3.42707-3.4271-3.42707z"
      fill="#004453"
    />
    <g fill="#00cc8a">
      <path d="m11.638 26.3345h-3.42706v20.0299h3.42706z" />
      <path d="m18.5677 8.63232h-3.4271v37.73218h3.4271z" />
      <path d="m25.4896 14.8594h-3.4271v31.505h3.4271z" />
      <path d="m32.4193 26.9956h-3.4271v19.36h3.4271z" />
      <path d="m39.3489 10.3755h-3.427v35.9885h3.427z" />
      <path d="m46.2708 20.4248h-3.427v25.9392h3.427z" />
    </g>
  </svg>
)

export default GraphIcon
