import React, { useEffect, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { displayDate, shortDate } from 'model/utils/date'
import { download } from 'model/utils/download'
import { setPageTitle } from 'model/utils/page'
import TransactionsTable from './table-with-pagination/TransactionsTable'

const TransactionReports = () => {
  const { account } = useUser()
  const [page, setPage] = useState(0)
  const [slug, setSlug] = useState(account.slug)
  const [nextPage, setNextPage] = useState(null)
  const [tableData, setTableData] = useState([])
  const [reportCache, setReportCache] = useState([])
  const tableDataHeaders = [
    'Date created',
    'Start date',
    'End date',
    'Report type',
    '',
  ]
  const changePage = ({ selected }) => setPage(selected)
  const { serviceRequest } = useService()
  const { infoToast } = useToast()

  const getReportType = filepath =>
    filepath?.includes('footprint') ? 'Footprint' : 'Purchase'

  const downloadReport = reportId => {
    serviceRequest({
      path: `/download/report/${reportId}`,
      method: 'GET',
    }).then(reportData => {
      download(reportData, 'Transaction Report.csv', 'text/plain')
      infoToast('Transaction Report Downloaded.')
    })
  }

  const displayData = tableData?.reports?.slice(0, 10)?.map((item, index) => (
    <tr className="no-cursor" key={index}>
      <td>{displayDate(item.created_at, shortDate, true)}</td>
      <td>{displayDate(item.start_date, shortDate, true)}</td>
      <td>{displayDate(item.end_date, shortDate, true)}</td>
      <td>{getReportType(item.filepath)}</td>
      <td>
        <a onClick={() => downloadReport(item.id)}>Download</a>
      </td>
    </tr>
  ))

  useEffect(() => {
    setPageTitle('Transaction Reports')
  }, [])

  useEffect(() => {
    if (slug !== account.slug) {
      setReportCache([])
    }
    if (reportCache[page] && slug === account.slug) {
      setTableData(reportCache[page])
      setNextPage(reportCache[page].next_page)
    } else {
      setSlug(account.slug)
      serviceRequest({
        path: `/api/reports?limit=10&page=${page + 1}`,
      }).then(data => {
        reportCache[page] = data
        setReportCache(reportCache)
        setTableData(data)
        setNextPage(data.nextPage)
      })
    }
    // eslint-disable-next-line
  }, [page, account])

  return (
    <div className="transaction-reports">
      <TransactionsTable
        dropdownExists={false}
        tableDataHeaders={tableDataHeaders}
        displayData={displayData}
        rowCount={tableData?.total}
        pageCount={tableData?.totalPages}
        changePage={changePage}
        emptyTableMessage="No Reports"
        nextPage={nextPage}
        currentPage={page}
      />
    </div>
  )
}

export default TransactionReports
