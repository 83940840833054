import React from 'react'
import { formatCost } from 'model/utils/cost'
import { displayDate, timeWithZone } from 'model/utils/date'
import ShortDetail from './ShortDetail'
import Tag from '../../transactions/components/Tag'
import './ShortDetails.scss'

export const date = (date, title) => {
  const dateObj = new Date(date?.replace(/ /g, 'T'))
  return (
    <ShortDetail title={title}>
      {date ? (
        <>
          <span>{displayDate(dateObj)}</span>
          <br />
          <span>{timeWithZone(dateObj)}</span>
        </>
      ) : (
        '--'
      )}
    </ShortDetail>
  )
}

//isReceiptPage is to check if it's the external receipt page.
const ShortDetails = ({ transaction, isReceiptPage }) => {
  const currency = transaction.cost?.requested_currency

  return (
    <div className="short-details">
      {transaction?.transaction_state?.toUpperCase() === 'ESTIMATED' &&
        date(transaction?.created_at, 'Created')}
      {transaction?.transaction_state?.toUpperCase() === 'PURCHASED' &&
        date(transaction?.purchased_at, 'Purchased')}
      {!isReceiptPage && transaction && (
        <>
          <ShortDetail title="Cost of offset">
            <span>
              {formatCost(
                transaction.cost?.in_requested_currency?.carbon_cost,
                currency
              )}
            </span>
          </ShortDetail>
          <ShortDetail title="Transaction Cost">
            <span>
              {formatCost(
                transaction.cost?.in_requested_currency?.transaction_cost,
                currency
              )}
            </span>
          </ShortDetail>
          <ShortDetail title="Total Cost">
            <span>
              {formatCost(
                transaction.cost?.in_requested_currency?.total_cost,
                currency
              )}
            </span>
          </ShortDetail>
          {transaction?.registry_name && (
            <ShortDetail title="Registry">
              <span>{transaction?.registry_name}</span>
            </ShortDetail>
          )}
          {transaction.tags?.length > 0 && (
            <ShortDetail title="Tags">
              <span>
                {transaction.tags?.map(tag => {
                  return <Tag key={tag?.id} tag={tag?.key} />
                })}
              </span>
            </ShortDetail>
          )}
          {transaction.note && (
            <ShortDetail title={'Note'}>
              <span>{transaction.note}</span>
            </ShortDetail>
          )}
        </>
      )}

      {isReceiptPage && (
        <>
          {transaction?.registry_name && (
            <ShortDetail title="Registry">
              <span>{transaction?.registry_name}</span>
            </ShortDetail>
          )}
          {transaction?.note && (
            <ShortDetail title={'Note'}>
              <span>{transaction.note}</span>
            </ShortDetail>
          )}
        </>
      )}
    </div>
  )
}

export default ShortDetails
