import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProfilePage from './components/pages/ProfilePage'

const Profile = () => (
  <Routes>
    <Route exact path="/" element={<ProfilePage />} />
  </Routes>
)

export default Profile
