import React, { useEffect, useState } from 'react'
import { useAuth } from 'model/hooks/account/useAuth'
import { useUser } from 'model/hooks/account/useUser'
import { FeatureFlag, useFeatureFlag } from 'model/hooks/useFeatureFlag'
import { usePageHistory } from 'model/hooks/usePageHistory'
import { useTheme } from 'model/hooks/useTheme'
import { useToast } from 'model/hooks/useToast'
import { initializeAndStartDataDogMonitoring } from 'model/utils/datadog'
import { isDirectCheckoutFlow } from 'model/utils/direct-checkout'
import { envStageOrProd } from 'model/utils/env'
import { getStoredColor } from 'model/utils/userStorage'
import moment from 'moment'
import { useIdleTimer } from 'react-idle-timer'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import FullStoryTracker from 'view/components/fullstory-tracker/FullStoryTracker'
import LoadingOverlay from 'view/components/loading/LoadingOverlay'
import CheckoutRoutes from 'view/components/routes/CheckoutRoutes'
import PrivateOnlyRoutes from 'view/components/routes/PrivateOnlyRoutes'
import PublicOnlyRoutes from 'view/components/routes/PublicOnlyRoutes'
import Toast from 'view/components/toast-container/Toast'
import AccountSettings from 'view/pages/account-settings/AccountSettings'
import Checkout from 'view/pages/checkout-admin/Checkout'
import CheckoutPublicRoutes from 'view/pages/checkout-public/CheckoutPublicRoutes'
import AmountSelection from 'view/pages/checkout-public/components/amount-selection/AmountSelection'
import PaymentConfirmation from 'view/pages/checkout-public/components/payment-confirmation/PaymentConfirmation'
import ProjectSelection from 'view/pages/checkout-public/components/project-selection/ProjectSelection'
import StripeCheckout from 'view/pages/checkout-public/components/stripe-checkout/StripeCheckout'
import Dashboard from 'view/pages/dashboard/Dashboard'
import DeveloperTools from 'view/pages/developer-tools/DeveloperTools'
import EcommerceSession from 'view/pages/EcommerceSession'
import Impersonate from 'view/pages/Impersonate'
import Login from 'view/pages/login/Login'
import Marketplace from 'view/pages/marketplace/Marketplace'
import Portfolio from 'view/pages/portfolio/Portfolio'
import Profile from 'view/pages/profile/Profile'
import ProjectDetails from 'view/pages/project-details/ProjectDetails'
import ProjectDetailsPublic from 'view/pages/project-details/ProjectDetailsPublic'
import ProjectDetailsRedirect from 'view/pages/project-details/ProjectDetailsRedirect'
import ProjectInventory from 'view/pages/project-inventory/ProjectInventory'
import ProjectMap from 'view/pages/project-map/ProjectMap'
import Register from 'view/pages/register/Register'
import ResetPassword from 'view/pages/reset-password/ResetPassword'
import SendEmail from 'view/pages/send-email/SendEmail'
import Shopify from 'view/pages/shopify/Shopify'
import SustainabilityReport from 'view/pages/sustainability-report/SustainabilityReport'
import TransactionReceipt from 'view/pages/transaction-receipt/TransactionReceipt'
import TransactionReceiptInternal from 'view/pages/transaction-receipt-internal/TransactionReceiptInternal'
import Transactions from 'view/pages/transactions/Transactions'

const App = () => {
  const { updatePaths } = usePageHistory()
  const { updateInitialPath, user, account } = useUser()
  const { infoToast } = useToast()
  const { logout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { getFlag, getCurrentEnv, consoleLog } = useFeatureFlag()
  const { rootStyles, color, updateColor, updateImage } = useTheme()
  const [appLoaded, setAppLoaded] = useState(false)
  const pathName = window.location.pathname

  //update theme to co-branded theme excluding public direct checkout pages (since they are handled separately)
  //this effect will run on every page reload - therefore keeping the appropriate colors in the app.
  useEffect(() => {
    if (
      account?.is_cobranding_enabled &&
      account.cobranding_primary_color &&
      !isDirectCheckoutFlow() &&
      !pathName.includes('external-offset-purchase')
    ) {
      updateColor(account.cobranding_primary_color)
      updateImage(account.cobranding_image)
    } else if (
      account &&
      !isDirectCheckoutFlow() &&
      !pathName.includes('external-offset-purchase')
    ) {
      updateColor(rootStyles.primary)
      updateImage('')
    } else {
      updateColor(getStoredColor())
      updateImage(JSON.parse(localStorage.getItem('cobranded-logo')))
    }
    //eslint-disable-next-line
  }, [account])

  useEffect(() => {
    // output application init logs if current feature flag allows it
    consoleLog(`---------------------------`)
    consoleLog(`CLOVERLY DEMAND APPLICATION`)
    consoleLog('ENVs:')
    consoleLog('◦ REACT_APP_ENV:', getCurrentEnv())
    consoleLog('◦ REACT_APP_CLOVERLY_URL:', process.env.REACT_APP_CLOVERLY_URL)
    consoleLog('◦ REACT_APP_API_URL:', process.env.REACT_APP_API_URL)
    consoleLog('◦ REACT_APP_ECOM_URL:', process.env.REACT_APP_ECOM_URL)
    consoleLog('FEATURE FLAGS:')
    consoleLog('◦ CONSOLE_LOGS:', getFlag(FeatureFlag.CONSOLE_LOGS))
    consoleLog('◦ CUSTOM_TERMS:', getFlag(FeatureFlag.CUSTOM_TERMS))

    // a prod visible console log to ensure this build is live. used for gitlab transition. remove once deployment is confirmed.
    // base-64 encoded string
    // console.log('MjAyNC0wMS0wNA==')

    // save the initial path
    updateInitialPath()
    // updates the theme's color
    if (
      !isDirectCheckoutFlow() &&
      !pathName.includes('external-offset-purchase')
    ) {
      updateColor(color)
    }
    // initialize datadog monitoring for stage & prod
    if (envStageOrProd()) {
      initializeAndStartDataDogMonitoring(process.env.REACT_APP_ENV, '1')
    }
    // after initial path has been saved, we can set app as loaded
    setAppLoaded(true)
    //eslint-disable-next-line
  }, [])

  //when user is idle for 12 hours (while keeping at least 1 tab alive)
  const onIdle = () => {
    navigate('/login')
    logout()
    infoToast('Logged out due to inactivity')
  }

  const twelveHours = moment.duration(12, 'hours')

  const { start, pause } = useIdleTimer({
    onIdle,
    timeout: twelveHours,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: false,
    startManually: true,
    stopOnIdle: false,
    crossTab: true,
    syncTimers: 0,
  })

  const saveTabCloseTime = () => {
    let now = moment()
    localStorage.setItem('tab-close-time', JSON.stringify(now))
  }

  const pathnameIncludes = path => pathName.includes(path)

  const isPathPublic =
    pathnameIncludes('project-map') ||
    pathnameIncludes('receipt') ||
    (pathnameIncludes('project-details') &&
      pathnameIncludes('sustainability-report') &&
      !user &&
      !account)

  //record the time when user closes tab
  useEffect(() => {
    window.onunload = saveTabCloseTime
  }, [])

  //scroll to top by default in each component
  useEffect(() => {
    window.scrollTo(0, 0)
    // save the prev and current path.  Useful in back button functionality.
    updatePaths()
  }, [location])

  /**
   * if it has been 12 hours since user closed every tab,
   * then log them out and redirect them to login page when they return to app
   */
  useEffect(() => {
    if ((user || account) && !isPathPublic) {
      if (history.length > 1) {
        let now = moment()
        let tabCloseTime = JSON.parse(localStorage.getItem('tab-close-time'))
        const duration = moment.duration(now.diff(tabCloseTime))
        let hours = duration.asHours()
        if (hours >= 12) {
          onIdle()
        }
      } else {
        saveTabCloseTime()
      }
    }
    // eslint-disable-next-line
  }, [window])

  //start session time to keep track if idleness (while keeping at least one tab alive)
  useEffect(() => {
    if ((user || account) && !isPathPublic) {
      start()
    } else {
      pause()
    }
    // eslint-disable-next-line
  }, [user, account])

  return (
    <>
      <FullStoryTracker />
      {appLoaded ? (
        <>
          {isDirectCheckoutFlow() ? (
            <Routes>
              <Route element={<CheckoutRoutes />}>
                <Route
                  path="/:checkoutSlug"
                  element={<CheckoutPublicRoutes />}
                  exact
                />
                <Route
                  path="/:checkoutSlug/project-selection"
                  element={<ProjectSelection />}
                  exact
                />
                <Route
                  path="/:checkoutSlug/choose-amount/:projectId"
                  element={<AmountSelection />}
                  exact
                />
                <Route
                  path="/:checkoutSlug/stripe-checkout/:projectId"
                  element={<StripeCheckout />}
                  exact
                />
                <Route
                  path="/:checkoutSlug/purchase-confirmation/:projectId"
                  element={<PaymentConfirmation />}
                  exact
                />
              </Route>
            </Routes>
          ) : (
            <Routes>
              <Route
                path="/sustainability-report/:accountId"
                element={<SustainabilityReport />}
              />
              <Route
                path="/project-details-public/:id"
                element={<ProjectDetailsPublic />}
              />
              <Route
                path="/ecommerce-session/:token"
                element={<EcommerceSession />}
              />
              <Route
                path="/details/receipt/:id"
                element={<TransactionReceipt />}
              />
              <Route path="/reset-password" element={<ResetPassword />} exact />
              <Route path="/project-map" element={<ProjectMap />} />

              <Route
                path="/project-details/:id"
                element={<ProjectDetailsRedirect />}
              />

              <Route element={<PublicOnlyRoutes />}>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/register/:slug" element={<Register />} />
                <Route path="/forgot-password" element={<SendEmail />} />
              </Route>

              <Route element={<PrivateOnlyRoutes />}>
                <Route path="/" element={<Dashboard />} exact />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route
                  path="/marketplace/project-details/:id"
                  element={<ProjectDetails />}
                />
                <Route path="/transactions/*" element={<Transactions />} />
                <Route
                  path="/transactions/details/:id"
                  element={<TransactionReceiptInternal />}
                  exact
                />
                <Route
                  path="/impersonate/:id"
                  element={<Impersonate />}
                  exact
                />
                <Route path="/developer-tools/*" element={<DeveloperTools />} />
                <Route
                  path="/account-settings/*"
                  element={<AccountSettings />}
                />
                <Route path="/shopify/*" element={<Shopify />} />
                <Route
                  path="/project-inventory"
                  element={<ProjectInventory />}
                />
                <Route path="/direct-checkout/*" element={<Checkout />} />
                <Route path="/profile/*" element={<Profile />} />
                <Route
                  path="/marketplace/portfolio-details/:id"
                  element={<Portfolio />}
                />
              </Route>

              <Route element={<CheckoutRoutes />}>
                <Route
                  exact
                  path="/external-offset-purchase/:checkoutSlug"
                  element={<CheckoutPublicRoutes />}
                />
                <Route
                  exact
                  path="/external-offset-purchase/:checkoutSlug/project-selection"
                  element={<ProjectSelection />}
                />
                <Route
                  exact
                  path="/external-offset-purchase/:checkoutSlug/choose-amount/:projectId"
                  element={<AmountSelection />}
                />
                <Route
                  exact
                  path="/external-offset-purchase/:checkoutSlug/stripe-checkout/:projectId"
                  element={<StripeCheckout />}
                />
                <Route
                  exact
                  path="/external-offset-purchase/:checkoutSlug/purchase-confirmation/:projectId"
                  element={<PaymentConfirmation />}
                />
              </Route>

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          )}
        </>
      ) : (
        <></>
      )}
      <LoadingOverlay />
      <Toast />
    </>
  )
}

export default App
