const typography = {
  fontFamily: `var(--inter-font), sans-serif`,
  fontSize: 16,
  lineHeight: 1.35,
  linkDecoration: 'underline',
  linkHoverDecoration: 'none',
  linkFocusDecoration: 'underline dotted',
  colorLinkHover: '#007A70',
}

const colorsMain = {
  // color - primary
  colorPrimaryBg: '#EDF7F6',
  colorPrimaryBgHover: '#DBEFF0',
  colorPrimary: '#007a70',
  // color - links
  colorLink: '#007a70',
  colorLinkHover: '#00997D',
  colorLinkActive: '#004F52',
  // color - success
  colorSuccess: '#009901',
  colorSuccessBg: '#EDFBE9',
  colorSuccessBgHover: '#DBF5D6',
  // color - warning
  colorWarning: '#e67c02',
  colorWarningBg: '#FFF8E6',
  colorWarningBgHover: '#FFEBC7',
  // color - error
  colorError: '#e02932',
  // color - info
  colorInfo: '#006bd6',
  // color - neutral
  colorTextBase: 'rgb(13, 19, 29)',
  colorText: 'rgba(13, 19, 24, 0.9)',
  colorTextSecondary: 'rgba(17, 21, 40, 0.65)',
  colorTextTertiary: 'rgba(17, 21, 40, 0.55)',
  colorTextQuaternary: 'rgba(17, 21, 40, 0.35)',
  colorFillQuaternary: 'rgba(17, 21, 27, 0.02)',
  colorBorder: '#C4D1D4',
  colorBorderSecondary: '#E1E8EA',
  colorBgLayout: '#F9FBFB',
  colorBgMask: 'rgba(0, 10, 20, 0.45)',
}

const colorsDark = {
  // color - primary
  colorPrimary: '#00af7d',
  colorPrimaryBg: '#0d423b',
  colorPrimaryText: '#04cc93',
  colorPrimaryTextActive: '#09bf88',
  // color - links
  colorLink: '#007a70',
  colorLinkHover: '#00997D',
  colorLinkActive: '#004F52',
  // color - success
  colorSuccess: '#7cec43',
  // color - warning
  colorWarning: '#ffbf00',
  // color - error
  colorErrorText: '#ec6e70',
  // color - info
  colorInfo: '#4e98ff',
  // color - neutral
  colorBgBase: '#08080a',
}

export const themeMain = {
  token: {
    // type
    ...typography,
    // colors
    ...colorsMain,
  },
}

export const themeDark = {
  token: {
    // type
    ...typography,
    // colors
    ...colorsDark,
  },
}
