import React, { useRef } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { download } from 'model/utils/download'
import CertificatePlaceholderIcon from 'view/components/icons/certificate-placeholder-icon/CertificatePlaceholderIcon'
import InternalLink from 'view/components/internal-link/InternalLink'
import { transactionReceiptComponents } from 'view/components/transaction-receipt-components/TransactionReceiptComponents'

const TransactionSourceDetails = ({ transaction, project }) => {
  const { infoToast, warningToast } = useToast()
  const { serviceRequest } = useService()
  const { account } = useUser()
  const sourceRef = useRef()
  const { ProjectDataCell, DeliveryDate, registryID } =
    transactionReceiptComponents()

  const isCertificateDownloadable = transaction =>
    transaction?.transaction_state?.toUpperCase() === 'PURCHASED'

  const imageList = transaction?.image_list
  const projectImage = imageList?.[0]
  const thumbnail =
    projectImage && projectImage?.type?.toUpperCase() === 'THUMBNAIL'
      ? projectImage.url
      : null

  const downloadCertificate = event => {
    event.preventDefault()
    event.stopPropagation()
    serviceRequest({
      path: `/transaction/${transaction.transaction_id}/certificate`,
      publicKeyOverride: `public_key:${account?.public_key}`,
      method: 'GET',
      sourceRef,
      options: { responseType: 'arraybuffer' },
    })
      .then(certificate => {
        infoToast('Certificate Downloaded.')
        download(
          certificate,
          `Purchase Certificate - ${transaction.transaction_id}.pdf`,
          'application/pdf'
        )
      })
      .catch(() => warningToast('Could not download certificate at this time.'))
  }

  return (
    <>
      <div className="right-panel-tools">
        <img src={thumbnail} className="project-image" alt="projectImage" />
        {isCertificateDownloadable(transaction) && (
          <div className="image-cert-download-box">
            <CertificatePlaceholderIcon />
            <label className="certificate-of-purchase">
              Certificate of purchase
            </label>
            <button className="download" onClick={downloadCertificate}>
              <DownloadIcon />
              <span>Download</span>
            </button>
          </div>
        )}
      </div>
      <div className="right-panel-info">
        <h2>{transaction.short_name || project.name}</h2>
        <h3>
          {project.type} • {project.country} •{' '}
          {transaction?.project?.tranche?.vintage_year}
        </h3>
        <p className="short_desc">{transaction.short_description}</p>
        <InternalLink
          title="Project Details"
          url={`/marketplace/project-details/${transaction.project.id}`}
          hasLinkIconAlways
        />
        <div className="more-info">
          <ProjectDataCell
            label="Location"
            value={
              (project.city ? project.city + ', ' : '') +
              (project.country || '')
            }
          />
          <ProjectDataCell label="Mechanism" value={transaction?.mechanism} />
          <ProjectDataCell label="Type" value={project?.type} />
          <ProjectDataCell
            label="Registry"
            value={transaction?.registry_name}
          />
          <ProjectDataCell
            label="Registry ID"
            value={registryID({
              id: transaction?.registry_project_id,
              registry_link: transaction?.registry_link,
            })}
          />
          <ProjectDataCell label="Vintage" value={transaction?.vintage_year} />
          <DeliveryDate transaction={transaction} />
        </div>
      </div>
    </>
  )
}

export default TransactionSourceDetails
