import { useState } from 'react'
import { queryClient } from 'model/utils/queryClient'
import { useQuery } from 'react-query'

// Get the isLoading state (used for full page loading overlay spinner)
const getIsLoading = (isLoading: boolean) => isLoading

export const useLoading = () => {
  const [isLoading, setIsLoading] = useState(false)

  useQuery('isLoading', () => getIsLoading(isLoading), {
    onSuccess: isLoading => setIsLoading(isLoading),
  })

  const updateIsLoading = (isLoading: boolean) =>
    queryClient.setQueryData('isLoading', isLoading)

  return { isLoading, updateIsLoading }
}
