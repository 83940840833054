import React from 'react'
import { Project } from 'interfaces/Project.interface'
import { getHost } from 'model/utils/registry'
import ExternalLink from 'view/components/external-link/ExternalLink'

type Props = {
  project: Project
}

const ProjectRegistryLink = ({ project }: Props) => {
  if (project.registry_project_id) {
    if (project.registry_link) {
      return (
        <ExternalLink
          className="no-underline"
          url={project.registry_link}
          title={project.registry_project_id}
          hasLinkIconAlways
        />
      )
    }
    return <>{project.registry_project_id}</>
  }
  if (project.registry_link) {
    const host = getHost(project.registry_link)
    if (host) {
      return (
        <ExternalLink
          className="no-underline"
          url={project.registry_link}
          title={host}
          hasLinkIconAlways
        />
      )
    }
  }
  return <>-</>
}

export default ProjectRegistryLink
