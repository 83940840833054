import React, { useEffect, useRef, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useService } from 'model/hooks/useService'
import {
  determineCurrencyRate,
  formatCost,
  handleCalculateCostPerTonne,
} from 'model/utils/cost'
import { formatWeightTypeAndAmount, getWeightUnit } from 'model/utils/units'
import { useCheckoutQueryParamContext } from 'view/pages/checkout-public/contexts/CheckoutQueryParamContext'
import './ProjectPanel.scss'

//similar to Product Item Panel in Marketplace
const PortfolioPanel = ({ project, currencyRates, setSelectedProject }) => {
  const linkRef = useRef(null)
  const { serviceRequest } = useService()
  const {
    weightUnit,
    shopBy,
    currency,
    amount,
    isFixedOrAny,
    setSelectedProjectIsPortfolio,
  } = useCheckoutQueryParamContext()

  const [isSelected, setIsSelected] = useState(false)

  const [estimate, setEstimate] = useState({
    total_weight: 0,
    total_cost: 0,
    currency: currency,
    weight_unit: getWeightUnit({ weightType: weightUnit, format: '%u' }),
  })

  const [costPerTonne, setCostPerTonne] = useState(0)

  // for highlighting the selected panel
  const selectedClassName = () => {
    return isSelected && Number(amount) > 0 ? 'selected' : ''
  }

  const isFixedClassName = () => (isFixedOrAny === 'fixed' ? 'isFixed' : '')

  const [projectPanelClassName, setProjectPanelClassName] = useState(
    `project-item-panel ${selectedClassName()} ${isFixedClassName()}`
  )

  //estimation related data
  const costInDollars = project?.cost_per_unit_in_usd_cents * 0.01
  const costPerDollar = project?.cost_per_unit_in_usd_cents * 0.01

  const formattedCostPerTonne = handleCalculateCostPerTonne(
    costInDollars,
    currency,
    currencyRates
  )

  //when user inputs a value
  useEffect(() => {
    if (project.source_slug && shopBy === 'currency') {
      const body = {
        project_match: {
          portfolio_id: project.source_slug,
        },
        note: '',
        currency: { value: 100, units: currency.toUpperCase() },
      }
      serviceRequest({
        path: `/estimates/currency`,
        method: 'POST',
        data: body,
        dataOnly: false,
        accountSlug: localStorage.getItem('account_slug'),
      }).then(response => {
        console.log('Ret:', response)
        if (response?.data?.transactions?.length > 0) {
          const totalWeight = response.data.transactions.reduce(
            (acc, curr) =>
              acc + curr.total_micro_units + curr.total_micro_units,
            0
          )
          setCostPerTonne(totalWeight / 1000000)
        }
      })
    }
    // eslint-disable-next-line
  }, [project, shopBy])

  //determines whether the input amount is in kg or lbs when calculation goes over to tonnes
  const isMetricTonne = weightUnit === 'kg' || weightUnit === 'tonnes'

  const handleClickOnProject = _ => {
    setSelectedProjectIsPortfolio(true)
    setSelectedProject(project?.source_slug)
  }

  //shopBy === carbon
  const handleEstimateCurrencyAmount = () => {
    let tempWeight = amount
    if (weightUnit === 'kg') {
      tempWeight = amount / 1000
    } else if (weightUnit === 'lbs') {
      tempWeight = amount / (2.20462 * 1000)
    } else {
      tempWeight = amount
    }
    const carbonCost =
      Math.ceil(tempWeight * project?.cost_per_unit_in_usd_cents) / 100
    const totalCost =
      carbonCost * determineCurrencyRate(currency, currencyRates)
    const calculatedEstimate = {
      ...estimate,
      total_cost: totalCost,
      total_weight: amount,
    }
    setEstimate(calculatedEstimate)
  }

  //shopBy === currency
  const handleEstimateCarbonAmount = () => {
    let costPerUnit = costPerDollar
    if (weightUnit === 'tonnes' || weightUnit === 'kg')
      costPerUnit = costPerDollar / 1000
    if (weightUnit === 'lbs') costPerUnit = costPerDollar / 1000
    const calculatedInputDollarAmount = amount / costPerUnit
    const calculatedWeight =
      calculatedInputDollarAmount /
      determineCurrencyRate(currency, currencyRates)
    setEstimate(estimate => ({
      ...estimate,
      total_weight: Number(calculatedWeight),
      total_cost: amount,
    }))
  }

  // determines what to render when amount is fixed depending on carbon vs currency
  const handleRenderTotal = () => {
    const estimatedAmount =
      shopBy === 'carbon'
        ? formatCost(estimate.total_cost, currency)
        : getWeightUnit({
            weightType: weightUnit,
            value: estimate?.total_weight,
            roundUnits: 0,
            format: '%v %u',
            isMT: isMetricTonne,
          })
    return (
      <>
        <span>
          {shopBy === 'carbon'
            ? formatWeightTypeAndAmount(amount, weightUnit)
            : formatCost(amount, currency)}
        </span>
        <span className="amount-and-forward-arrow">
          {shopBy === 'currency' ? '' : estimatedAmount}
          <ArrowForwardIcon
            className={
              isSelected && Number(amount) > 0
                ? 'arrow-visible'
                : 'arrow-hidden'
            }
          />
        </span>
      </>
    )
  }

  const handleHover = isOnHover => {
    if (isOnHover) {
      setIsSelected(true)
      localStorage.setItem('cost-per-unit', project?.cost_per_unit_in_usd_cents)
    } else {
      setIsSelected(false)
    }
  }

  //if "View Details" link is focused, change panel's outline styling
  useEffect(() => {
    if (document.activeElement === linkRef.current) {
      if (!projectPanelClassName.includes('link-is-focused'))
        setProjectPanelClassName(projectPanelClassName + ' link-is-focused')
    } else {
      setProjectPanelClassName(
        projectPanelClassName.replace(' link-is-focused', '')
      )
    }
    // eslint-disable-next-line
  }, [document.activeElement])

  //when user inputs a value
  useEffect(() => {
    if (shopBy === 'carbon') handleEstimateCurrencyAmount()
    if (shopBy === 'currency') handleEstimateCarbonAmount()
    // eslint-disable-next-line
  }, [])

  return (
    <section
      className={projectPanelClassName}
      tabIndex="0"
      onMouseOver={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      onFocus={() => handleHover(true)}
      onBlur={() => handleHover(false)}
      onClick={handleClickOnProject}
      onKeyPress={handleClickOnProject}
    >
      <img className="panel-image" src={project?.image_url} alt="" />
      <div className="project-type-container portfolio">Portfolio</div>
      <div className="bottom-half">
        <span className="project-name">{project?.name}</span>
        <div className="details">
          <div className="cost-and-vintage">
            {shopBy === 'currency' ? (
              <>
                <span className="cost">
                  {formattedCostPerTonne + ' / tonne '}
                </span>
                <span className="estimated">(estimated)</span>
              </>
            ) : (
              <span className="cost">{formattedCostPerTonne + ' / tonne'}</span>
            )}
          </div>
          <span className="location">
            {project.projects?.length} project
            {project.projects?.length === 1 ? '' : 's'}
          </span>
        </div>
      </div>
      {isFixedOrAny === 'fixed' && (
        <div className={`calculated-amount-section ${selectedClassName()}`}>
          {handleRenderTotal()}
        </div>
      )}
    </section>
  )
}

export default PortfolioPanel
