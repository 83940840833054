import React from 'react'
import './PinIcon.scss'

type Props = {
  clicked?: boolean
  pinClass?: string
}

const PinIcon = ({ clicked, pinClass = 'default' }: Props) => (
  <svg
    className={'pin-icon ' + pinClass + (clicked ? ' clicked' : '')}
    width="30"
    height="41"
    viewBox="0 0 30 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="mark"
      d="M30 15C30 24.8276 17.069 40.8621 15 40.8621C12.931 40.8621 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
      stroke="#fafafa"
      strokeOpacity={0.8}
      strokeWidth={1}
    />
    <circle className="mark" cx="15" cy="14" r="8" />
  </svg>
)

export default PinIcon
