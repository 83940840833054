import React from 'react'

const Cancel = ({ closeToast }) => (
  <svg
    onClick={closeToast}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0001 0.666687C6.63341 0.666687 0.666748 6.63335 0.666748 14C0.666748 21.3667 6.63341 27.3334 14.0001 27.3334C21.3667 27.3334 27.3334 21.3667 27.3334 14C27.3334 6.63335 21.3667 0.666687 14.0001 0.666687ZM20.6667 18.78L18.7801 20.6667L14.0001 15.8867L9.22008 20.6667L7.33342 18.78L12.1134 14L7.33342 9.22002L9.22008 7.33335L14.0001 12.1134L18.7801 7.33335L20.6667 9.22002L15.8867 14L20.6667 18.78Z"
      fill="white"
    />
  </svg>
)

export default Cancel
