import React, { useEffect, useState } from 'react'
import { useQueryParams } from 'model/hooks/useQueryParam'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { download } from 'model/utils/download'
import { queryClient } from 'model/utils/queryClient'
import { useQuery } from 'react-query'
import CloverlyFooter from 'view/components/cloverly-footer/CloverlyFooter'
import { useCheckoutQueryParamContext } from 'view/pages/checkout-public/contexts/CheckoutQueryParamContext'
import ConfirmationPageLink from './components/ConfirmationPageLink'
import Header from '../header/Header'
import PurchaseDetails from '../purchase-details/PurchaseDetails'
import './PaymentConfirmation.scss'

const PaymentConfirmation = () => {
  const { selectedProjectIsPortfolio } = useCheckoutQueryParamContext()

  const { serviceRequest } = useService()

  const { infoToast } = useToast()

  const queryParams = useQueryParams()
  const project = JSON.parse(localStorage.getItem('selected-project'))
  const successMessage = JSON.parse(localStorage.getItem('success_message'))
  const estimate =
    queryClient.getQueryData('estimateFromBE') ||
    JSON.parse(localStorage.getItem('cached-estimate'))
  const [estimateFromBE, setEstimateFromBE] = useState(null)

  const transaction_id = selectedProjectIsPortfolio
    ? estimate?.transactions[0]?.transaction_id
    : estimate?.transaction_id

  const handlePurchase = async () => {
    const stripe_id = localStorage.getItem('stripe_id')
    const receipt_url = localStorage.getItem('receipt_url')

    //console.log('Purchase account slug: ', localStorage.getItem('account_slug'))

    const requestData = selectedProjectIsPortfolio
      ? {
          portfolio_transaction_id: transaction_id,
          stripe_receipt_url: receipt_url,
          stripe_id: stripe_id,
        }
      : {
          estimate_slug: transaction_id,
          stripe_receipt_url: receipt_url,
          stripe_id: stripe_id,
        }

    const { data } = await serviceRequest({
      path: '/purchases',
      method: 'POST',
      data: requestData,
      dataOnly: false,
      accountSlug: localStorage.getItem('account_slug'),
    })
    return data
  }

  //console.log('Purchase Step: estimate:', estimate)

  const { data: purchaseResponse, refetch } = useQuery(
    'purchase-response',
    handlePurchase,
    {
      enabled: !!transaction_id,
    }
  )

  const downloadCertificate = event => {
    event.preventDefault()
    event.stopPropagation()
    serviceRequest({
      path: `/transaction/${purchaseResponse[0].transaction_id}/certificate`,
      method: 'GET',
      options: { responseType: 'arraybuffer' },
    })
      .then(certificate => {
        infoToast('Certificate Downloaded.')
        download(
          certificate,
          `Purchase Certificate - ${purchaseResponse[0].transaction_id} .pdf`,
          'application/pdf'
        )
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    if (transaction_id) {
      refetch()
    }
  }, [transaction_id, refetch])

  useEffect(() => {
    if (purchaseResponse) {
      setEstimateFromBE({
        cost: {
          in_requested_currency: {
            total_cost:
              purchaseResponse?.cost?.in_requested_currency.total_cost,
          },
          requested_currency: purchaseResponse?.cost?.requested_currency,
        },
        total_co2e_in_kg: purchaseResponse?.total_co2e_in_kg,
      })
    }
  }, [queryParams, purchaseResponse])

  const stripeReceiptUrl = localStorage.getItem('receipt_url')

  return (
    <div className="payment-confirmation">
      <Header title="Success!" hasGoBackBtn={false} />
      <p className="inspirational-message">
        {successMessage ||
          'Thank you! Your purchase contributes positively to our planet.'}
      </p>
      <section className="order-details-and-options">
        <h2 className="order-status">Order Confirmed</h2>
        <PurchaseDetails
          project={project ?? purchaseResponse?.project}
          estimateFromBE={estimate || estimateFromBE}
          isOrderConfirmed
        />
        {purchaseResponse && (
          <section className="confirmation-page-links">
            <ConfirmationPageLink
              isReceipt
              title="Receipt"
              url={stripeReceiptUrl}
            />
            <ConfirmationPageLink
              isReceipt={false}
              title="Carbon credit purchase certificate"
              handleCertClick={downloadCertificate}
            />
          </section>
        )}
      </section>
      <CloverlyFooter />
    </div>
  )
}

export default PaymentConfirmation
