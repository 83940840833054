import React, { useMemo } from 'react'
import { Image, Text, View } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { mapConfig } from 'model/utils/react-map'
import MainColumnSection from './MainColumnSection'
import QMTag from '../../common-components/QMTag'
import Spacer from '../../common-components/Spacer'
import LocationIcon from '../../icons/LocationIcon'

type Props = {
  project: Project
  styles: any
}

const LocationSection = ({ project, styles }: Props) => {
  const cityCountry = `${project.city || ''}${project.city && project.country ? ', ' : ''}${project.country || ''}`

  /**
   * @type {string}
   */
  const mapImageSource = useMemo(() => {
    const lat = project.location.y
    const lon = project.location.x
    const zoom = 3
    const mapWidth = 350
    const mapHeight = 350
    return `${mapConfig.staticMapApiHost}/styles${mapConfig.mapStaticStyle}/static/${mapConfig.staticMarker}(${lat},${lon})/${lat},${lon},${zoom},0/${mapWidth}x${mapHeight}@2x?access_token=${mapConfig.mapboxAccessToken}`
  }, [project?.location])

  /**
   * @type {ReactNode}
   */
  const MapImage = useMemo(() => {
    return (
      <Image
        style={styles.locationMapImage}
        source={mapImageSource}
        cache={true}
      />
    )
  }, [project?.location])

  return (
    <MainColumnSection title="Location" styles={styles}>
      <View style={styles.locationContainer}>
        <View style={styles.locationIntro}>
          {cityCountry && (
            <Text style={styles.locationText}>{cityCountry}</Text>
          )}
          <Spacer />
          {project.region && (
            <QMTag
              icon={<LocationIcon />}
              label={project.region}
              style={styles.projectTagLocation}
            />
          )}
        </View>
        {project?.location && (
          <View style={styles.locationMap}>{MapImage}</View>
        )}
      </View>
    </MainColumnSection>
  )
}

export default LocationSection
