import React from 'react'
import { MarketPlaceListingModes } from 'model/utils/marketplace/MarketplaceEnums'
import './MarketplaceListingSubnav.scss'

const MarketplaceListingSubnav = ({
  hasPortfolioListings,
  marketplaceListingMode,
  listingModeUpdated,
}) => {
  /**
   * The array of tabs that are in the navbar.
   *
   * @type {(string)[]}
   */
  const listingOptions = [
    MarketPlaceListingModes.PROJECTS,
    MarketPlaceListingModes.PORTFOLIOS,
  ]

  /**
   * Renders the tabs in the navbar.
   *
   * @param listingOptions
   * @returns {*}
   */
  const renderTabs = listingOptions =>
    listingOptions
      .filter(listingOptionFilter)
      .map((listingOption, idx) => (
        <ListingOptionTab
          key={`listing-option-${idx}`}
          listingOption={listingOption}
        />
      ))

  /**
   * Renders a single tab.
   *
   * @param listingOption
   * @returns {JSX.Element}
   * @constructor
   */
  const ListingOptionTab = ({ listingOption }) => (
    <div
      onClick={() => listingModeUpdated(listingOption)}
      className={
        'listing-option-tab' +
        (marketplaceListingMode === listingOption ? ' selected' : '')
      }
    >
      <span>{listingOption}</span>
      <div className="bottom-bar" />
    </div>
  )

  /**
   * Filters out the portfolio tab from being displayed if there are no portfolio listings.
   *
   * @param listingOption
   * @returns {boolean}
   */
  const listingOptionFilter = listingOption =>
    listingOption !== MarketPlaceListingModes.PORTFOLIOS || hasPortfolioListings

  return <div id="marketplace-listing-subnav">{renderTabs(listingOptions)}</div>
}

export default MarketplaceListingSubnav
