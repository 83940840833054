import React, { ReactNode } from 'react'
import { capFirstLetter } from 'model/utils/capitalize-first-letter'
import PercentageBars from './PercentageBars'
import PercentageTags from './PercentageTags'

type Props = {
  title: string
  metricItem?: any
  children?: ReactNode
}

const MetricPanel = ({ title, metricItem, children }: Props) => {
  //turn mix object into array of objects
  const formattedCompItem: { key: string; value: any }[] = Object.entries(
    metricItem
  ).map(([key, value]) => ({
    key,
    value,
  }))

  return (
    <>
      {formattedCompItem?.length && !!metricItem ? (
        <section className="p-4 flex flex-col gap-3 border border-neutral-80 rounded-xl">
          <p className="text-lg font-bold ">{capFirstLetter(title)}</p>
          <PercentageBars metric={formattedCompItem} />
          <PercentageTags metric={formattedCompItem} />
          {children}
        </section>
      ) : null}
    </>
  )
}

export default MetricPanel
