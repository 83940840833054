import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { setPageTitle } from 'model/utils/page'
import Highlight from 'react-highlight'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import '../../DeveloperTools.scss'

const DebuggerDetailsPage = () => {
  const navigate = useNavigate()

  const { id } = useParams()

  const { account } = useUser()
  const { serviceRequest } = useService()

  const [call, setCall] = useState(null)

  useEffect(() => {
    setPageTitle('API Debugger Details')
    serviceRequest({
      path: `/dashboard/accounts/${account.slug}/api_debuggers/${id}`,
    }).then(data => setCall(data))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {call && (
        <div className="developer-tools">
          <div className="sub-page-header">
            <div className="left-col">
              <NavLink
                to={'/'}
                onClick={e => {
                  navigate(-1)
                  e.preventDefault()
                }}
              >
                <ArrowBackIcon /> Back
              </NavLink>
              <h1>API Debugger Details</h1>
              <div className="details-panel">
                <p>
                  <b>Environment:</b> {call.environment}
                </p>
                <p>
                  <b>Request:</b> {call.request_method} {call.path}
                </p>
                <p>
                  <b>Params:</b>
                </p>
                <div className="highlight-container">
                  <Highlight language="html">
                    {JSON.stringify(call.params, null, 2)}
                  </Highlight>
                </div>
                <p>
                  <b>Response Status:</b> {call.response_status}
                </p>
                <p>
                  <b>Response Body:</b>
                </p>
                <div className="highlight-container">
                  <Highlight language="html">
                    {JSON.stringify(JSON.parse(call.response_body), null, 2)}
                  </Highlight>
                </div>
                <p>
                  <b>Error:</b> {call.error ? call.error : '---'}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DebuggerDetailsPage
