import React from 'react'

const ChartLabel = ({ series, dataPointIndex, labels, display_kg }) => {
  let intlNumberFormatter = new Intl.NumberFormat()

  const label = display_kg ? 'kg' : 'lbs'

  return (
    <div className="chart-theme">
      <p className="date">{labels[dataPointIndex]}</p>
      {/* <p className="data">{getUnits({ isKg: display_kg, value: series[0][dataPointIndex], format: '%v %u footprint' })}</p>
    <p className="data">{getUnits({ isKg: display_kg, value: series[1][dataPointIndex], format: '%v %u offset' })}</p> */}
      <p className="data">
        {intlNumberFormatter.format(series[0][dataPointIndex])} {label}{' '}
        footprint
      </p>
      <p className="data">
        {intlNumberFormatter.format(series[1][dataPointIndex])} {label} offset
      </p>
    </div>
  )
}

export default ChartLabel
