import axios from 'axios'
import { useLoading } from 'model/hooks/useLoading'
import Cookies from 'universal-cookie'

const apiEndpointPaths = [
  '/dashboard/inventory',
  '/dashboard/tags',
  '/dashboard/transactions',
  '/dashboard/transaction',
  '/dashboard/transactions/footprint',
  '/dashboard/calls',
  '/account/',
  '/accounts/',
  '/projects',
  '/project',
  '/estimates/',
  '/purchases',
  '/registries',
  '/projects-checkout',
  '/checkout-pages',
  '/transaction',
  '/checkout/create-payment-intent',
  '/checkout/confirm-payment-intent',
  '/checkout-page-admin',
  '/report',
  '/download/report/',
  '/portfolios',
  '/portfolio',
  '/currency/rates',
  '/marketplace',
  '/portfolios',
]

const ecomEndpointPaths = ['/api/shop/']

export const useService = () => {
  const { updateIsLoading } = useLoading()

  /**
   * Represents the parameters for a service request.
   */
  type ServiceRequestParams = {
    path: string
    method?: string
    data?: any
    options?: any
    sourceRef?: any
    showLoading?: boolean
    dataOnly?: boolean
    publicKeyOverride?: string | null
    basicAuth?: string
    accountSlug?: string | null
    includeAccountSlug?: boolean
    pathAsUrl?: boolean
  }

  /**
   * Represents the parameters for a service request with multiple requests.
   */
  type ServiceRequestMultiParams = {
    requests?: ServiceRequestParams[]
    sourceRef?: any
    showLoading?: boolean
    publicKeyOverride?: string | null
    basicAuth?: string | null
    accountSlug?: string | null
    includeAccountSlug?: boolean
    pathAsUrl?: boolean
  }

  /**
   * Represents the response of a service request.
   */
  type ServiceResponse = {
    data: any
  }

  /**
   * Represents the response of a service request with multiple requests.
   */
  type ServiceResponseMulti = ServiceResponse[]

  /**
   * Represents the service functions.
   */
  type ServiceFunctions = {
    serviceRequest: (params: ServiceRequestParams) => Promise<any>
    serviceRequestMulti: (
      params: ServiceRequestMultiParams
    ) => Promise<ServiceResponseMulti>
    cancelCancelToken: (sourceRef: any) => void
    renewCancelToken: (sourceRef: any) => void
    spreadResponses: <T, R>(callback: (...args: T[]) => R) => (array: T[]) => R
  }

  const serviceRequest = async ({
    path,
    method = 'GET',
    data = {},
    options = {},
    sourceRef = null,
    showLoading = true,
    dataOnly = true,
    publicKeyOverride = null,
    basicAuth = '',
    accountSlug = null,
    includeAccountSlug = true,
    pathAsUrl = false,
  }: ServiceRequestParams) => {
    const responses = await serviceRequestMulti({
      requests: [{ path, method, options, data }],
      sourceRef,
      showLoading,
      publicKeyOverride,
      basicAuth,
      accountSlug,
      includeAccountSlug,
      pathAsUrl,
    })
    return dataOnly ? responses?.[0]?.data : responses?.[0]
  }

  const serviceRequestMulti = async ({
    requests = [],
    sourceRef = null,
    showLoading = true,
    publicKeyOverride = null,
    basicAuth = null,
    accountSlug = null,
    includeAccountSlug = true,
    pathAsUrl = false,
  }: ServiceRequestMultiParams) => {
    try {
      if (showLoading) updateIsLoading(true)

      if (sourceRef) renewCancelToken(sourceRef)

      const storedAccount = localStorage.getItem('account')
      const storedPublicKey = storedAccount
        ? JSON.parse(storedAccount).public_key
        : null
      const publicKey = storedPublicKey
        ? 'Bearer public_key:' + storedPublicKey
        : null

      // for each given request object, convert into an axios request
      const axiosRequests = requests.map(request => {
        const config = {
          ...request.options,
          ...(sourceRef?.current && { cancelToken: sourceRef?.current.token }),
          headers: publicKey && { Authorization: publicKey },
          ...(generateShouldUseCredentials(request.path) && {
            withCredentials: 'include',
          }),
        }

        // special case for purchase certificates download -- remove when demo is complete
        if (publicKeyOverride) {
          config.headers.Authorization = `Bearer ${publicKeyOverride}`
          config.headers.Accept = 'application/pdf'
        } else if (basicAuth) {
          config.headers.Authorization = `Basic ${basicAuth}`
          config.headers.Accept = 'application/pdf'
        }

        const finalRequestUrl = pathAsUrl
          ? request.path
          : generateFinalRequestUrlFromPath(
              request.path,
              accountSlug,
              includeAccountSlug
            )

        switch (request.method) {
          case 'PUT':
            return axios.put(finalRequestUrl, request.data || {}, config)
          case 'PATCH':
            return axios.patch(finalRequestUrl, request.data || {}, config)
          case 'POST':
            return axios.post(finalRequestUrl, request.data || {}, config)
          case 'DELETE':
            return axios.delete(finalRequestUrl, config)
          default:
            return axios.get(finalRequestUrl, config)
        }
      })

      const responses = await axios.all(axiosRequests)
      if (showLoading) updateIsLoading(false)

      return responses
    } catch (e) {
      // if not caused by an axios cancel error exception
      if (!axios.isCancel(e)) {
        if (showLoading) updateIsLoading(false)
        throw e
      }
    }
  }

  /**
   * Decorates a given request url with Cloverly account info and environment target urls.
   *
   * @param path {string} - path of request
   * @param accountSlug
   * @param includeAccountSlug
   * @returns {string}
   */
  const generateFinalRequestUrlFromPath = (
    path: string,
    accountSlug: string | null,
    includeAccountSlug: boolean
  ): string => {
    const pathOnly = path.split('?')[0]
    let additiveUrl: string = process.env.REACT_APP_CLOVERLY_URL || ''
    if (isApiEndpointByPath(pathOnly))
      additiveUrl = process.env.REACT_APP_API_URL || ''
    else if (isEcomEndpointByPath(pathOnly))
      additiveUrl = process.env.REACT_APP_ECOM_URL || ''
    const accountId =
      new Cookies().get('current_account') || accountSlug || null
    const publicUrl = additiveUrl + path
    const privateUrl =
      additiveUrl +
      (path.includes('?')
        ? path.replace('?', `?account_id=${accountId}&`)
        : includeAccountSlug
          ? `${path}?account_id=${accountId}`
          : path)
    return accountId && additiveUrl !== process.env.REACT_APP_CLOVERLY_URL
      ? privateUrl
      : publicUrl
  }

  /**
   * Decorates a given request url with Cloverly account info and environment target urls.
   *
   * @param path {string} - path of request
   * @returns {boolean}
   */
  /**
   * Determines if the current path should use credentials.
   *
   * @param path {string | undefined}
   * @returns {boolean}
   */
  const generateShouldUseCredentials = (path: string | undefined): boolean => {
    const pathOnly = path?.split('?')[0]
    return !(isApiEndpointByPath(pathOnly) || isEcomEndpointByPath(pathOnly))
  }

  /**
   * Determines if the current path is an api endpoint path by searching all api endpoints
   * and seeing if the path starts with any api endpoint in the api endpoint paths array.
   *
   * @param currentPath
   * @returns {boolean}
   */
  /**
   * Determines if the current path is an api endpoint path by searching all api endpoints
   * and seeing if the path starts with any api endpoint in the api endpoint paths array.
   *
   * @param currentPath {string | undefined}
   * @returns {boolean}
   */
  const isApiEndpointByPath = (currentPath: string | undefined): boolean =>
    apiEndpointPaths.filter(apiPath => currentPath?.startsWith(apiPath))
      .length > 0

  /**
   * Determines if the current path is an ecom endpoint path by searching all ecom endpoints
   * and seeing if the path starts with any ecom endpoint in the ecom endpoint paths array.
   *
   * @param currentPath {string | undefined}
   * @returns {boolean}
   */
  const isEcomEndpointByPath = (currentPath: string | undefined): boolean =>
    ecomEndpointPaths.filter(ecomPath => currentPath?.startsWith(ecomPath))
      .length > 0

  /**
   * Cancels a given request source token and reissues a new cancel token.
   *
   * @param sourceRef {any}
   */
  const renewCancelToken = (sourceRef: any): void => {
    if (sourceRef) {
      cancelCancelToken(sourceRef)
      sourceRef.current = axios.CancelToken.source()
    }
  }

  /**
   * Cancels a given request source token.
   *
   * @param sourceRef {any}
   */
  const cancelCancelToken = (sourceRef: any): void => {
    if (sourceRef?.current) {
      sourceRef.current.cancel('Response no longer needed...')
    }
  }

  /**
   * Shorthand for axios spread function.
   *
   * @type {<T, R>(callback: (...args: T[]) => R) => (array: T[]) => R}
   */
  const spreadResponses = axios.spread

  return {
    serviceRequest,
    serviceRequestMulti,
    cancelCancelToken,
    renewCancelToken,
    spreadResponses,
  } as ServiceFunctions
}
