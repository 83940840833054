import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import './CloseDialogButton.scss'

type Props = {
  onClick?: () => void
}

const CloseDialogButton = ({ onClick }: Props) => (
  <button className="close-dialog-button" onClick={onClick}>
    <CloseIcon />
  </button>
)

export default CloseDialogButton
