import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { Col, Form, FormControl, InputGroup, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import CreditCardsIcon from 'view/components/icons/credit-cards-icon/CreditCardsIcon'
import './Modal.scss'

const AddCreditsModal = React.memo(
  ({ showAddCredits = false, setShowAddCredits, card = null }) => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm()

    const { refreshUser, account } = useUser()

    const { infoToast, warningToast } = useToast()

    const { serviceRequest } = useService()

    const onSubmit = data => {
      serviceRequest({
        path: `/dashboard/accounts/${account.slug}/billing/add_credit`,
        method: 'PUT',
        data: { credit: { amount_in_usd: data.amount } },
      }).then(data => {
        if (data.success) {
          infoToast(data.success)
          refreshUser().then()
        } else {
          warningToast(data.error)
        }
        setShowAddCredits(false)
        reset()
      })
    }

    const onCancel = e => {
      e.preventDefault()
      setShowAddCredits(false)
      reset()
    }

    return (
      <Modal
        show={showAddCredits}
        size="lg"
        className="credit-card-modal"
        centered
      >
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CloseDialogButton onClick={e => onCancel(e)} />
            <h2>Add Credits</h2>
            {card != null ? (
              <p>
                {card.brand} ending in {card.last4}, expires {card.exp_month}/
                {card.exp_year}
              </p>
            ) : (
              <p>No card available</p>
            )}
            <div className="card-columns center-aligned">
              <div className="icon-column">
                <div className="circle">
                  <CreditCardsIcon />
                </div>
              </div>
              <Col className="single-field">
                <Form.Label htmlFor="amount">Amount</Form.Label>
                <InputGroup className="mb-2">
                  <InputGroup.Text>$</InputGroup.Text>
                  <FormControl
                    id="amount"
                    name="amount"
                    type="number"
                    isInvalid={errors.amount}
                    {...register('amount', { required: true })}
                  />
                  <InputGroup.Text>.00</InputGroup.Text>
                </InputGroup>
                {errors.amount && (
                  <p className="invalid-message">Please enter credit amount.</p>
                )}
              </Col>
            </div>
            <div className="btn-group">
              <Button cn={'cancel'} onClick={e => onCancel(e)}>
                Cancel
              </Button>
              <Button type="submit">Purchase Credits</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
)

export default AddCreditsModal
