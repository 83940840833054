import React from 'react'
import ReactMarkdown from 'react-markdown'
import { renderSupportingImages } from './ProjectDetailsUtility'

const ProjectDescription = ({ projectDescription, project, setImageModal }) => {
  return projectDescription?.firstParagraph?.length > 0 ||
    project?.image_list?.length > 1 ? (
    <div className="description">
      <ReactMarkdown linkTarget="_blank">
        {projectDescription?.firstParagraph}
      </ReactMarkdown>
      {renderSupportingImages(project?.image_list, setImageModal)}
      <ReactMarkdown linkTarget="_blank">
        {projectDescription?.followingParagraphs}
      </ReactMarkdown>
    </div>
  ) : (
    <></>
  )
}

export default ProjectDescription
