import { useEffect, useState } from 'react'
import { useService } from 'model/hooks/useService'

export const useCurrencyExchangeRates = ({ showLoading = true }) => {
  const { serviceRequest } = useService()

  const [currencyRates, setCurrencyRates] = useState([])

  useEffect(() => {
    serviceRequest({ showLoading, path: '/currency/rates' })
      .then(data => setCurrencyRates(data))
      .catch(err => console.error(err))
    // eslint-disable-next-line
  }, [])

  return { currencyRates }
}
