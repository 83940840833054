import { useEffect, useState } from 'react'
import { CarouselItem, Portfolio } from 'interfaces/Portfolio.interface'
import { Project } from 'interfaces/Project.interface'
import { usePortfolioCalculations } from 'model/hooks/marketplace/usePortfolioCalculations'
import { useService } from 'model/hooks/useService'
import { getPrimaryImg } from 'model/utils/image'
import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'

export const usePortfolioDetails = (portfolioId?: string) => {
  const { serviceRequest } = useService()

  const { generateProjectWeightPercentages, costPerTonne } =
    usePortfolioCalculations()

  const [portfolio, setPortfolio] = useState<Portfolio | null>(null)

  const mockImage =
    'https://cloverly-public.s3.us-east-2.amazonaws.com/Production+Project+Images+/0003+Mai+Ndombe+REDD%2B+Project+Filip-C-Agoo-Everland-Marketing-Congo-3501-WEB.png'

  const portfolioImage = (portfolio: Portfolio) =>
    getPrimaryImg(portfolio?.image_list) || mockImage

  // populates the carousel on the Portfolio page
  const portfolioImageList = () => {
    const imageList: CarouselItem[] = []
    const projects = portfolio?.offset_sources
    if (projects?.length) {
      for (const project of projects as Project[]) {
        project?.image_list?.forEach(image =>
          imageList.push({ ...image, project_name: project.name })
        )
      }
    }
    return imageList
  }

  useEffect(() => {
    setPortfolio(null)
    if (portfolioId)
      serviceRequest({ path: `/portfolio/${portfolioId}` }).then(
        (portfolio: Portfolio) => {
          const updatedPortfolio = portfolio

          // set the product type to this portfolio (used by estimate generation)
          updatedPortfolio.productType = ProductType.PORTFOLIO

          // initializes the unsdg set -- mapped from offset sources below
          const unsdgSet: number[] = []

          // map fields to root level where necessary
          updatedPortfolio.offset_sources.forEach(offset_source => {
            if (offset_source?.active_tranche?.vintage_year)
              // map vintage year
              offset_source.vintage_year =
                offset_source?.active_tranche?.vintage_year

            // map rating
            offset_source.rating = offset_source?.project_ratings?.length
              ? offset_source?.project_ratings[0].rating
              : '-'

            // map unsdg
            if (Array.isArray(offset_source?.unsdg)) {
              offset_source?.unsdg.forEach(unsdg => {
                if (!unsdgSet.includes(unsdg)) unsdgSet.push(unsdg)
              })
            }
          })

          updatedPortfolio.unsdg = unsdgSet.sort()

          const { projectMixArray } =
            generateProjectWeightPercentages(portfolio)
          updatedPortfolio.project_types = projectMixArray
          setPortfolio(updatedPortfolio)
        }
      )
    // eslint-disable-next-line
  }, [portfolioId])

  return {
    portfolio,
    portfolioImage,
    portfolioImageList,
    costPerTonne,
  }
}
