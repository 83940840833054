import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useMarketplaceContext } from '../../../../MarketplaceContext'

const FilterDropdown = ({ options }) => {
  const { filterValue, setFilterValue, filterType } = useMarketplaceContext()

  const isObject = value => typeof value === 'object' && value !== null

  const loopOptions = () => {
    return options.map(option => {
      if (filterValue === option.value) {
        return option.label
      }
      return ''
    })
  }

  return (
    <div className="dropdown-container">
      <Dropdown
        value={filterValue}
        onSelect={eventKey => setFilterValue(eventKey)}>
        <Dropdown.Toggle className="standard-dropdown">
          <span>
            {filterType !== 'Removal Time Period'
              ? filterValue ?? 'All'
              : filterValue && isObject(options[0])
              ? loopOptions()
              : 'All'}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item key="0" eventKey={null}>
            All
          </Dropdown.Item>
          {options?.map((option, idx) => {
            if (filterType === 'Removal Time Period') {
              return (
                option && (
                  <Dropdown.Item key={idx} eventKey={option.value}>
                    {option.label}
                  </Dropdown.Item>
                )
              )
            } else {
              return (
                option && (
                  <Dropdown.Item key={idx} eventKey={option}>
                    {option}
                  </Dropdown.Item>
                )
              )
            }
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default FilterDropdown
