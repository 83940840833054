import React from 'react'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import IdCardIcon from 'view/components/icons/id-card-icon/IdCardIcon'
import '../../Profile.scss'

const ResetPasswordModal = React.memo(
  ({ showResetPasswordModal = false, setShowResetPasswordModal }) => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm()

    const { warningToast, infoToast } = useToast()
    const { serviceRequest } = useService()

    const onSubmit = data => {
      if (data.newPassword !== data.validateNewPassword) {
        warningToast('Passwords do not match')
      } else {
        serviceRequest({
          path: '/dashboard/profile/change-password',
          method: 'POST',
          data: {
            password: {
              current_password: data.currentPassword,
              new_password: data.newPassword,
            },
          },
        }).then(data => {
          if (data.error) {
            warningToast(data.error)
          } else {
            infoToast('Password changed successfully')
            setShowResetPasswordModal(false)
            reset()
          }
        })
      }
    }

    const onCancel = e => {
      e.preventDefault()
      setShowResetPasswordModal(false)
      reset()
    }

    return (
      <Modal
        show={showResetPasswordModal}
        size="lg"
        className="standard-modal"
        centered
      >
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CloseDialogButton onClick={e => onCancel(e)} />
            <h2>Reset Password</h2>
            <div className="icon-panel">
              <div className="icon-column">
                <div className="icon-circle">
                  <IdCardIcon />
                </div>
              </div>
              <div className="form-column">
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label htmlFor="currentPassword">
                      Current Password
                    </Form.Label>
                    <Form.Control
                      id="currentPassword"
                      name="currentPassword"
                      type="password"
                      isInvalid={errors.currentPassword}
                      {...register('currentPassword', { required: true })}
                    />
                    {errors.currentPassword && (
                      <p className="invalid-message">
                        Current Password is required.
                      </p>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label htmlFor="newPassword">New Password</Form.Label>
                    <Form.Control
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      isInvalid={errors.newPassword}
                      {...register('newPassword', { required: true })}
                    />
                    {errors.newPassword && (
                      <p className="invalid-message">
                        New Password is required.
                      </p>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label htmlFor="validateNewPassword">
                      Validate New Password
                    </Form.Label>
                    <Form.Control
                      id="validateNewPassword"
                      name="validateNewPassword"
                      type="password"
                      isInvalid={errors.validateNewPassword}
                      {...register('validateNewPassword', { required: true })}
                    />
                    {errors.validateNewPassword && (
                      <p className="invalid-message">
                        Validate New Password is required.
                      </p>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </div>
            <div className="btn-group">
              <button onClick={e => onCancel(e)}>Cancel</button>
              <button type="submit">Reset Password</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
)

export default ResetPasswordModal
