import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import '../../../../../Checkout.scss'
import './OptionSelect.scss'

const OptionSelect = React.memo(
  ({ valueName, optionClicked, isSelected, size = 'large' }) => {
    return (
      <div
        className={'option-select ' + size + (isSelected ? ' selected' : '')}
        onClick={() => optionClicked(valueName)}>
        {isSelected && size !== 'small' && <CheckIcon />}
        {isSelected && size !== 'large' && <div />}
      </div>
    )
  }
)

export default OptionSelect
