import { capFirstLetter } from 'model/utils/capitalize-first-letter'
import { formatCost } from 'model/utils/cost'
import { WeightingMethod } from 'model/utils/marketplace/MarketplaceEnums'

export const usePortfolioCalculations = () => {
  const formatAsPercent = num => `${parseFloat(num).toFixed(0)}%`

  const costPerTonne = portfolio =>
    formatCost(portfolio.price_per_tonne_in_USD) + ' / tonne'

  /**
   * Converts project_mix object to an iterable array.
   *
   * @param project_mix
   * @returns {[]}
   */
  const getProjectMixArray = project_mix => {
    const projectMixArray = []
    if (project_mix) {
      Object.keys(project_mix).forEach(key =>
        projectMixArray.push({
          type: key,
          mixPercentage: project_mix[key] * 100,
          mixPercentageFormatted: formatAsPercent(project_mix[key] * 100),
        })
      )
    }
    return projectMixArray
  }

  /**
   * Converts mechanism_mix object to an iterable array.
   *
   * @param weighting_method
   * @param mechanism_mix
   * @param totalProjects
   * @returns {[]}
   */
  const getMechanismMixArray = (
    weighting_method,
    mechanism_mix,
    totalProjects
  ) => {
    const mechanismMixArray = []
    Object.keys(mechanism_mix).forEach(key => {
      const percentage =
        weighting_method === WeightingMethod.CUSTOM
          ? mechanism_mix[key]
          : (mechanism_mix[key] / totalProjects) * 100
      mechanismMixArray.push({
        label: key,
        percentage,
        percentageFormatted: formatAsPercent(percentage),
      })
    })
    return mechanismMixArray
  }

  /**
   * Returns a valid weighting method from a given weighting method that may not be well-formed (lowercase, no spaces), and defaults to 'even'.
   *
   * @param weightingMethod
   * @returns {string|string}
   */
  const getWeightingMethod = weightingMethod => {
    const weightingMethodFormatted = weightingMethod?.trim()?.toLowerCase()
    return weightingMethodFormatted &&
      Object.values(WeightingMethod).includes(weightingMethodFormatted)
      ? weightingMethodFormatted
      : WeightingMethod.EVEN
  }

  /**
   * Generate project types array for Project Mix chart and removal_time_mix array for Removal Time Period chart.
   *
   * @param portfolio
   * @returns {{mechanismMixArray: [], projectMixArray: []}}
   */
  const generateProjectWeightPercentages = portfolio => {
    let projectMixArray = []
    let mechanismMixArray = []

    if (portfolio?.offset_sources?.length) {
      const totalProjects = portfolio.offset_sources.length
      const weightingMethod = getWeightingMethod(portfolio.weighting_method)
      let mechanism_mix = {}

      Array.from(portfolio.offset_sources).forEach(project => {
        if (weightingMethod === WeightingMethod.CUSTOM)
          project.weight_percentage = formatAsPercent(
            project.purchase_split_percentage
          )
        else
          project.weight_percentage = formatAsPercent((1 / totalProjects) * 100)

        //populate mechanism_mix object
        if (project.offset_classification) {
          let label = capFirstLetter(project.offset_classification?.trim())
          if (weightingMethod === WeightingMethod.CUSTOM)
            mechanism_mix[label] = !mechanism_mix[label]
              ? project.purchase_split_percentage
              : mechanism_mix[label] + project.purchase_split_percentage
          else
            mechanism_mix[label] = !mechanism_mix[label]
              ? 1
              : mechanism_mix[label] + 1
        }
      })

      // sort mechanism_mix keys
      mechanism_mix = Object.keys(mechanism_mix)
        .sort()
        .reduce((temp_obj, key) => {
          temp_obj[key] = mechanism_mix[key]
          return temp_obj
        }, {})

      mechanismMixArray = getMechanismMixArray(
        weightingMethod,
        mechanism_mix,
        totalProjects
      )

      projectMixArray = getProjectMixArray(portfolio.project_mix)
    }

    return { projectMixArray, mechanismMixArray }
  }

  return {
    generateProjectWeightPercentages,
    costPerTonne,
  }
}
