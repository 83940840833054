import React, { useEffect } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useTheme } from 'model/hooks/useTheme'
import { useToast } from 'model/hooks/useToast'
import { setStoredInitialPath } from 'model/utils/userStorage'
import { useNavigate, useParams } from 'react-router-dom'

const Impersonate = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { updateUser, clearUser, updateInitialPath } = useUser()
  const { warningToast } = useToast()
  const { serviceRequest } = useService()
  const { clearColor } = useTheme()

  useEffect(() => {
    serviceRequest({
      path: `/babka/users/${id}/impersonate/`,
    }).then(data => {
      // check to see if the data returned contains a valid user_id. If session is not valid, data will be a string html, thus, we need to re-auth.
      if (data?.user_id) {
        updateUser(data)
        navigate('/account-settings')
        warningToast('Warning: This account is in impersonation mode.')
      } else {
        updateInitialPath()
        serviceRequest({
          path: '/session',
          method: 'DELETE',
        })
          .then(() => {
            clearUser()
            clearColor()
            localStorage.removeItem('products')
            localStorage.removeItem('project-types')
            setStoredInitialPath(`/impersonate/${id}`)
            navigate('/login')
          })
          .catch(e => warningToast(e?.response?.data?.error))
      }
    })
    // eslint-disable-next-line
  }, [])

  return <></>
}

export default Impersonate
