/**
 *
 * @param {string} checkoutSlug checkoutSlug for fetching page data
 * @param {function} serviceRequest fetch function
 * @param {function} handleSetFieldValues loads query params
 * @param {function} setProjectData loads projects
 * @param {function} updateColor cobranding related
 * @param {function} updateImage cobranding related
 * @param {function} getPrimaryStyle cobranding related
 * @param {function} setInitialLoad indicator that call is completed
 */
export const handleFetchCheckoutData = (
  checkoutSlug,
  serviceRequest,
  handleSetFieldValues,
  setProjectData,
  updateColor,
  updateImage,
  getPrimaryStyle,
  setInitialLoad
) => {
  const primaryDefault = getPrimaryStyle('--permanent-primary')
  serviceRequest({
    method: 'GET',
    path: `/projects-checkout?checkout_page_slug=${checkoutSlug}`,
  })
    .then(checkoutPage => {
      const { checkout_data, projects } = checkoutPage
      handleSetFieldValues(checkout_data)

      if (Array.isArray(projects) && projects.length > 0) {
        projects.forEach(project => {
          if (project.is_portfolio) {
            project.cost_per_unit_in_usd_cents =
              project.price_per_tonne_in_usd * 100
          }
        })
      }

      setProjectData(projects)
      localStorage.setItem('checkout-projects', JSON.stringify(projects))
      //same logic as App.js for setting cobranded colors
      if (checkout_data?.account_cobranding_primary_color) {
        updateColor(checkout_data?.account_cobranding_primary_color)
        //set the logo for the header using LS
        localStorage.setItem(
          'cobranded-logo',
          JSON.stringify(checkout_data?.account_cobranding_image)
        )
      } else {
        updateColor(primaryDefault)
        updateImage('')
        localStorage.removeItem('cobranded-logo')
      }
    })
    .catch(e => console.log(e))
    .finally(() => setInitialLoad(true))
}
