import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { Col, Form, FormControl, InputGroup, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import './Modal.scss'

const AutoBillingModal = React.memo(
  ({ showAutoBilling = false, setShowAutoBilling }) => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm()

    const { account, refreshUser } = useUser()

    const { infoToast, warningToast } = useToast()

    const { serviceRequest } = useService()

    const onSubmit = data => {
      // add card functionality here
      data.is_auto_billing_enabled = document.querySelector(
        '#enabledAutoBilling'
      ).checked
      data.auto_billing_increment_in_usd = parseInt(data.amount)
      data.amount = null
      serviceRequest({
        path: `/dashboard/accounts/${account.slug}/billing/auto_billing`,
        method: 'PUT',
        data: { account: data },
      }).then(data => {
        if (data.success) {
          infoToast(data.success)
          setShowAutoBilling(false)
          refreshUser().then()
          reset()
        } else {
          warningToast(data.error)
        }
      })
    }

    const onCancel = e => {
      e.preventDefault()
      setShowAutoBilling(false)
      reset()
    }

    return (
      <Modal
        show={showAutoBilling}
        size="lg"
        className="credit-card-modal"
        centered
      >
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CloseDialogButton onClick={e => onCancel(e)} />
            <h2>Auto-Billing</h2>
            <p>
              By configuring auto-billing, Cloverly will automatically process
              payments when available account credits goes to zero. If
              auto-billing is not enabled, and your account has zero Available
              Account Credits, then the Cloverly API will reject further
              purchases.
            </p>
            <Col className="single-field">
              <Form.Check
                defaultChecked={account.is_auto_billing_enabled}
                type="checkbox"
                label="Enable Auto Billing"
                id="enabledAutoBilling"
              />
            </Col>
            <Col className="single-field field-indented">
              <Form.Label htmlFor="amount">Auto-Billing Amount</Form.Label>
              <InputGroup className="mb-2">
                <InputGroup.Text>$</InputGroup.Text>
                <FormControl
                  defaultValue={
                    account.auto_billing_increment_in_usd_cents / 100
                  }
                  id="amount"
                  name="amount"
                  type="number"
                  isInvalid={errors.amount}
                  {...register('amount', { required: true })}
                />
                <InputGroup.Text>.00</InputGroup.Text>
              </InputGroup>
              {errors.amount && (
                <p className="invalid-message">
                  Please enter auto billing amount.
                </p>
              )}
            </Col>
            <div className="btn-group">
              <Button cn={'cancel'} onClick={e => onCancel(e)}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
)

export default AutoBillingModal
