import React, { MouseEvent } from 'react'
import Skeleton from 'antd/lib/skeleton'
import { Portfolio } from 'interfaces/Portfolio.interface'
import { formatCost } from 'model/utils/cost'
import { CloverlyButton } from 'view/components/cloverly-button/CloverlyButton'
import { useMarketplaceContext } from 'view/pages/marketplace/MarketplaceContext'

type Props = {
  portfolio?: Portfolio | null
  addToCart: (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
}

const CalculatorEstimate = ({ addToCart }: Props) => {
  const {
    defaultCurrency,
    isEstimationLoading,
    hasInventoryError,
    projectEstimate,
  } = useMarketplaceContext()

  return (
    <section className="p-4">
      <section className="flex flex-col gap-4">
        {projectEstimate?.total_cost > 0 ? (
          <div className="flex justify-between font-bold">
            <p>Total:</p>
            {isEstimationLoading ? (
              <Skeleton.Button className="w-[75px] h-5" active={true} />
            ) : (
              <p>{formatCost(projectEstimate?.total_cost, defaultCurrency)}</p>
            )}
          </div>
        ) : null}
        <CloverlyButton
          disabled={isEstimationLoading || hasInventoryError}
          onClick={addToCart}
          className="w-full"
        >
          {isEstimationLoading ? 'Calculating estimate...' : 'Add to Order'}
        </CloverlyButton>
      </section>
    </section>
  )
}

export default CalculatorEstimate
