import React, { useEffect, useState } from 'react'
import { usePaymentContext } from '../../../../../contexts/CheckoutContext'
import '../../../../../Checkout.scss'
import './UnitSelector.scss'

const UnitSelector = () => {
  const { watch, setValue, getValues, camelizeString } = usePaymentContext()

  const unitOptions = {
    carbon: ['TONNES'],
    currency: ['USD', 'CAD', 'GBP', 'EUR', 'JPY', 'AUD', 'NZD', 'SGD'],
  }

  const [selectedUnit, setSelectedUnit] = useState({
    unitType: null,
    unitValue: null,
  })

  /**
   * @param e
   * @param unitType
   * @param unitValue
   */
  const updateSelectedUnit = (unitType, unitValue, e) => {
    if (getValues('unit_type') !== unitType)
      setValue('unit_type', unitType, { shouldDirty: true })
    if (getValues('unit_value') !== unitValue)
      setValue('unit_value', unitValue, { shouldDirty: true })
    setSelectedUnit({ unitType, unitValue })
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  useEffect(() => {
    const values = getValues()
    // console.log("form Values: Unit Selector", values)
    updateSelectedUnit(values.unit_type, values.unit_value)
    // eslint-disable-next-line
  }, [getValues])

  useEffect(() => {
    const subscription = watch(value => {
      // console.log('watch updated: Unit Selector', value, name, type)
      updateSelectedUnit(value.unit_type, value.unit_value)
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line
  }, [watch])

  const renderUnitGroupOptions = (unitType, unitArray, selectedUnit) => (
    <>
      <div className="button-container">
        {unitArray.map(unitValue => (
          <button
            key={unitType + '-' + unitValue}
            className={
              selectedUnit.unitType === unitType &&
              selectedUnit.unitValue === unitValue
                ? 'selected'
                : ''
            }
            onClick={e => updateSelectedUnit(unitType, unitValue, e)}>
            {['TONNES'].includes(unitValue)
              ? camelizeString(unitValue)
              : unitValue}
          </button>
        ))}
      </div>
    </>
  )

  return (
    <div className="unit-selector">
      <div className="unit-group">
        <div className="unit-title">Carbon</div>
        {renderUnitGroupOptions('CARBON', unitOptions.carbon, selectedUnit)}
      </div>
      <div className="unit-group">
        <div className="unit-title">Currency</div>
        {renderUnitGroupOptions('CURRENCY', unitOptions.currency, selectedUnit)}
      </div>
    </div>
  )
}

export default UnitSelector
