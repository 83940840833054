import React from 'react'
import { Portfolio } from 'interfaces/Portfolio.interface'
import {
  getIssuanceMix,
  getPermanence,
  groupedProjects,
  sortAlphabetically,
  sortByPercentage,
  sortObjByKeys,
} from 'model/utils/portfolio/portfolioUtils'
import PortfolioDetailsMap from 'view/pages/portfolio/components/portfolio-details-map/PortfolioDetailsMap'
import MetricPanel from './MetricPanel'

type Props = {
  portfolio: Portfolio
}

const PortfolioMetrics = ({ portfolio }: Props) => {
  //to be implemented/used for PDF implementation
  const updateMapImage = () => {
    return
  }

  return (
    <>
      <MetricPanel
        title="methodologies"
        metricItem={sortByPercentage(portfolio?.methodology_mix)}
      />
      <MetricPanel
        title="issuance status"
        metricItem={getIssuanceMix(portfolio.issuance_mix)}
      />
      <MetricPanel
        title="vintages"
        metricItem={sortObjByKeys(portfolio.vintage_year_mix)}
      />
      <MetricPanel
        title="mechanism"
        metricItem={sortAlphabetically(portfolio?.mechanism_mix)}
      />
      <MetricPanel
        title="permanence"
        metricItem={getPermanence(portfolio.permanence_mix)}
      />
      <MetricPanel
        title="geography"
        metricItem={sortByPercentage(portfolio.geography_mix)}
      >
        <div className="h-[180px] [&_.mapboxgl-ctrl-bottom-right]:hidden [&_.mapboxgl-ctrl-logo]:hidden">
          <PortfolioDetailsMap
            projects={groupedProjects(portfolio?.offset_sources)}
            updateMapImage={updateMapImage}
          />
        </div>
      </MetricPanel>
    </>
  )
}

export default PortfolioMetrics
