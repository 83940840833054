import React from 'react'

const BookmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
  >
    <g clipPath="url(#clip0_107_6870)">
      <path
        d="M10.5 13.75L6 10.9375L1.5 13.75V1.375C1.5 1.22582 1.55926 1.08274 1.66475 0.977253C1.77024 0.871763 1.91332 0.8125 2.0625 0.8125H9.9375C10.0867 0.8125 10.2298 0.871763 10.3352 0.977253C10.4407 1.08274 10.5 1.22582 10.5 1.375V13.75Z"
        stroke="#0057C2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_107_6870">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default BookmarkIcon
