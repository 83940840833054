import React from 'react'

const TreeGraphIcon = () => (
  <svg
    fill="none"
    width="230"
    height="201"
    viewBox="0 0 230 201"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m181.298 65.2778-39.258 92.4912h78.517z" fill="#00cc8a" />
    <path
      d="m220.557 159.623h-78.517c-.62 0-1.215-.321-1.537-.84-.347-.519-.396-1.162-.173-1.755l39.258-92.4916c.57-1.3594 2.85-1.3594 3.42 0l39.259 92.4916c.248.569.174 1.236-.173 1.755-.323.519-.917.84-1.537.84zm-75.717-3.707h72.916l-36.458-85.8924z"
      fill="#004453"
    />
    <path
      d="m47.289 201c-1.0162 0-1.8588-.84-1.8588-1.854v-27.609c0-1.013.8426-1.854 1.8588-1.854s1.8588.841 1.8588 1.854v27.609c0 1.038-.8178 1.854-1.8588 1.854z"
      fill="#004453"
    />
    <path
      d="m228.141 201h-226.28216c-1.016168 0-1.85884-.816-1.85884-1.854s.842672-1.854 1.85884-1.854h226.28216c1.016 0 1.859.841 1.859 1.854 0 1.014-.843 1.854-1.859 1.854z"
      fill="#004453"
    />
    <path d="m115 122.795-25.1562 59.247h50.3122z" fill="#00cc8a" />
    <g fill="#004453">
      <path d="m140.156 183.896h-50.3121c-.6196 0-1.2144-.322-1.5366-.841-.347-.519-.3966-1.161-.1735-1.755l25.1562-59.247c.57-1.359 2.85-1.359 3.42 0l25.157 59.247c.247.569.173 1.236-.174 1.755s-.917.841-1.537.841zm-47.4867-3.708h44.6867l-22.356-52.648z" />
      <path d="m115 201c-1.016 0-1.859-.84-1.859-1.854v-48.47c0-1.014.843-1.854 1.859-1.854s1.859.84 1.859 1.854v48.47c0 1.038-.843 1.854-1.859 1.854z" />
      <path d="m181.298 201c-1.016 0-1.859-.84-1.859-1.854v-101.958c0-1.0134.843-1.8538 1.859-1.8538s1.859.8404 1.859 1.8538v101.958c0 1.038-.843 1.854-1.859 1.854z" />
      <path d="m181.298 131.149c-.47 0-.941-.173-1.313-.543-.719-.717-.719-1.904 0-2.62l13.036-12.977c.719-.717 1.909-.717 2.628 0 .718.717.718 1.903 0 2.62l-13.037 12.977c-.372.346-.843.543-1.314.543z" />
      <path d="m181.299 151.417c-.471 0-.942-.173-1.314-.543l-13.012-13.002c-.719-.716-.719-1.903 0-2.62.719-.716 1.909-.716 2.627 0l13.012 13.002c.719.716.719 1.903 0 2.62-.372.37-.843.543-1.313.543z" />
    </g>
    <path
      d="m25.0817 176.777c-.3718 0-.7436-.099-1.0658-.321-.8426-.593-1.0409-1.73-.4709-2.571l22.2317-31.835c.2974-.421.7435-.717 1.264-.767.5205-.074 1.041.075 1.4375.396l16.2834 13.248 48.5774-94.271c.322-.618.942-1.0134 1.636-1.0134s1.314.3707 1.636.9886l20.67 38.9049 40.746-96.5453c.396-.93925 1.487-1.38416 2.429-.98868.941.39547 1.388 1.48303.991 2.42228l-42.233 100.1046c-.272.667-.917 1.112-1.636 1.137-.768.049-1.388-.346-1.734-.989l-20.819-39.2259-48.008 93.1589c-.2478.494-.7187.84-1.264.964s-1.1153-.025-1.5614-.371l-16.5065-13.421-21.0668 30.204c-.3718.519-.9666.791-1.5366.791z"
      fill="#ccc"
    />
    <path
      d="m160.95 9.14535 1.512 3.38625 16.705-7.51402 6.32 17.17842 3.47-1.2853-7.659-20.9107z"
      fill="#ccc"
    />
  </svg>
)

export default TreeGraphIcon
