import React from 'react'
import {
  EstimateErrorType,
  ProductType,
} from 'model/utils/marketplace/MarketplaceEnums'

type Props = {
  productType?: string
  estimateErrorType?: string
}

const CalculatorErrorMessage = ({ productType, estimateErrorType }: Props) => {
  const contactEmail = 'support@cloverly.com'

  switch (estimateErrorType) {
    case EstimateErrorType.PURCHASE_AMOUNT_TOO_SMALL:
      return productType === ProductType.PORTFOLIO ? (
        <>
          Amount too small to purchase from this portfolio. Select a greater
          amount or{' '}
          <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">
            contact us
          </a>
          .
        </>
      ) : (
        <>
          Amount too small to purchase from selected vintage. Select a greater
          amount or{' '}
          <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">
            contact us
          </a>
          .
        </>
      )
    default:
      return productType === ProductType.PORTFOLIO ? (
        <>
          Amount exceeds available inventory for this portfolio. Select a lower
          amount or{' '}
          <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">
            contact us
          </a>
          .
        </>
      ) : (
        <>
          Amount exceeds available inventory for selected vintage. If you need
          help with this purchase{' '}
          <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">
            contact us
          </a>
          .
        </>
      )
  }
}

export default CalculatorErrorMessage
