import React from 'react'

const KeyIcon = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m10.5417 8.33333c-.68752-1.94166-2.53336-3.33333-4.70836-3.33333-2.7625 0-4.999996 2.2375-4.999996 5s2.237496 5 4.999996 5c2.175 0 4.02084-1.3917 4.70836-3.3333h3.625v3.3333h3.3333v-3.3333h1.6667v-3.33337zm-4.70836 3.33337c-.92083 0-1.66666-.7459-1.66666-1.6667 0-.92083.74583-1.66667 1.66666-1.66667.92084 0 1.66667.74584 1.66667 1.66667 0 .9208-.74583 1.6667-1.66667 1.6667z"
      fill="#a2a2a2"
    />
  </svg>
)

export default KeyIcon
