import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from '@mui/material'
import { useUser } from 'model/hooks/account/useUser'
import { useMarketplaceFilters } from 'model/hooks/marketplace/useMarketplaceFilters'
import { useCurrencyExchangeRates } from 'model/hooks/useCurrencyExchangeRates'
import { useQueryParams } from 'model/hooks/useQueryParam'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { formatCost, handleCalculateCostPerTonne } from 'model/utils/cost'
import { download } from 'model/utils/download'
import {
  getSortOptionLabel,
  marketplaceSortOptions,
  sortProductsBySortOrder,
} from 'model/utils/marketplace/marketplace-sort'
import {
  EstimateErrorType,
  MarketPlaceListingModes,
  ProductType,
} from 'model/utils/marketplace/MarketplaceEnums'
import { getWeightUnit } from 'model/utils/units'

const MarketplaceContext = React.createContext()

export function useMarketplaceContext() {
  return useContext(MarketplaceContext)
}

export const MarketplaceProvider = ({ children }) => {
  const notInitialRender = useRef(false)
  const { filterProjectsByTypeValue, filterPortfoliosByTypeValue } =
    useMarketplaceFilters()
  const { serviceRequest } = useService()
  const { account } = useUser()
  const { infoToast, warningToast } = useToast()
  const sourceRef = useRef()
  const { currencyRates } = useCurrencyExchangeRates({ showLoading: false })

  // does the context contain a valid cache of marketplace listings
  const [containsCache, setContainsCache] = useState(false)

  //modal if user input data in the calculation header
  const [showModalWithValues, setShowModalWithValues] = useState(false)
  //modal if no input from user in the calculation header when they click purchase
  const [showEmptyStateModal, setShowEmptyStateModal] = useState(false)
  // calculated total amount based off of real-time estimation
  const [calculatedTotalAmount, setCalculatedTotalAmount] = useState({})
  // default currency
  const [defaultCurrency, setDefaultCurrency] = useState(undefined)

  // the listing mode on the main marketplace page (shop all, projects, portfolios
  const [marketplaceListingMode, setMarketplaceListingMode] = useState(
    MarketPlaceListingModes.PROJECTS
  )

  // Sort Options
  const [sortOptions] = useState(marketplaceSortOptions)

  // location & query params
  const urlSearchParams = useQueryParams()

  const [projectToPurchase, setProjectToPurchase] = useState('')
  const [productTypeToPurchase, setProductTypeToPurchase] = useState('')
  const [selectedVintage, setSelectedVintage] = useState(undefined)

  const initialEstimate = {
    cost: {
      in_requested_currency: {
        carbon_cost: 0,
        total_cost: 0,
        transaction_cost: 0,
      },
      requested_currency: account?.default_currency,
    },
  }

  const [estimate, setEstimate] = useState(initialEstimate)

  const [queryParams] = useState({
    amount: Number(urlSearchParams?.get('amount')) || 0,
    weightUnits: urlSearchParams?.get('weightUnits') || 'tonnes',
    filterBy: urlSearchParams?.get('filterBy') || null,
    filter: urlSearchParams?.get('filter') || null,
    sortBy: urlSearchParams?.get('sortBy') || 'default',
    listView: urlSearchParams?.get('listView')?.toLowerCase() || 'grid',
  })

  // query param state
  const [listView, setListView] = useState(queryParams?.listView || 'grid')
  const [sortOrder, setSortOrder] = useState(queryParams?.sortBy || 'default')
  const [sortLabel, setSortLabel] = useState('Default')
  const [filterType, setFilterType] = useState(queryParams?.filterBy)
  const [filterValue, setFilterValue] = useState(queryParams?.filter)
  const [amount, setAmount] = useState(queryParams?.amount)
  const [weightType, setWeightType] = useState(queryParams?.weightUnits)
  const [projects, setProjects] = useState(undefined)
  const [project, setProject] = useState(null)
  const [isBillingEnabled, setIsBillingEnabled] = useState(false)
  const [isEstimationLoading, setIsEstimationLoading] = useState(false)
  const [projectEstimate, setProjectEstimate] = useState({})
  const [projectDetails, setProjectDetails] = useState({})
  const [refetchProjects, setRefetchProjects] = useState(false)
  const [hasInventoryError, setHasInventoryError] = useState(false)
  const [estimateErrorType, setEstimateErrorType] = useState(undefined)
  const [receiptUrl, setReceiptUrl] = useState('')
  const [purchaseTransactionId, setPurchaseTransactionId] = useState('')
  const [hasPortfolioListings, setHasPortfolioListings] = useState(true)
  const weightTypeOptions = ['tonnes', 'lbs', 'kg']

  useEffect(() => {
    setDefaultCurrency(account?.default_currency)
  }, [account?.default_currency])

  useEffect(() => {
    //determines whether the input amount is in kg or lbs when calculation goes over to tonnes
    const isMetricTonne = weightType === 'kg' || weightType === 'tonnes'
    const estimateExists = estimate?.cost?.in_requested_currency?.total_cost > 0
    setCalculatedTotalAmount({
      estimatedCarbonAmount: estimateExists
        ? getWeightUnit({
            weightType: weightType,
            value: estimate?.total_co2e_in_kg,
            roundUnits: 0,
            format: '%v %u',
            isMT: isMetricTonne,
          })
        : null,
      estimatedDollarAmount: estimateExists
        ? formatCost(
            estimate?.cost?.in_requested_currency?.total_cost,
            estimate?.cost?.requested_currency
          )
        : null,
    })
  }, [
    weightType,
    estimate?.cost?.in_requested_currency?.total_cost,
    estimate?.cost?.requested_currency,
    estimate?.total_co2e_in_kg,
  ])

  useEffect(() => {
    if (
      window.location.pathname.startsWith('/marketplace') ||
      window.location.pathname.startsWith('/project-details')
    ) {
      let queryParams = ''
      queryParams += includeAnd(queryParams) + `weight=${amount || 0}`
      queryParams += includeAnd(queryParams) + `weightUnits=${weightType}`
      // the following query params are not applicable to the project details page
      if (!window.location.pathname.startsWith('/project-details')) {
        if (listView) {
          queryParams += includeAnd(queryParams) + `listView=${listView}`
        }
        if (sortOrder) {
          queryParams += includeAnd(queryParams) + `sortBy=${sortOrder}`
        }
        if (filterType) {
          queryParams +=
            includeAnd(queryParams) + `filterBy=${filterType.trim()}`
        }
        if (filterValue) {
          queryParams +=
            includeAnd(queryParams) + `filter=${filterValue.trim()}`
        }
      }
      if (queryParams?.length) {
        const newUrl = window.location.pathname + '?' + queryParams
        window.history.replaceState({ path: newUrl }, '', newUrl)
      }
    }
  }, [amount, weightType, filterType, filterValue, sortOrder, listView])

  const includeAnd = queryParams => (queryParams?.length ? '&' : '')

  //handle price sorting
  useEffect(() => {
    setSortLabel(getSortOptionLabel(sortOrder))
  }, [sortOrder])

  const resetCalculatorValues = () => {
    if (!queryParams.amount) setAmount(amount || 0)
    if (!queryParams.weightUnits) setWeightType('tonnes')
    setProjectEstimate({})
  }

  const isDisplayRealAmount = estimate => estimate?.total_cost > 1000000

  const handleRenderTooltipContent = estimate =>
    formatCost(estimate?.total_cost, defaultCurrency, false)

  /**
   * @param product
   * @param tranche
   * @param amount
   * @param weightType
   */
  const updateEstimation = ({ product, tranche, amount, weightType }) => {
    if (!isNaN(amount) && Number(amount) > 0) {
      if (product) {
        if (product.productType === ProductType.PORTFOLIO)
          handleEstimation(
            product?.slug,
            false,
            amount,
            weightType,
            ProductType.PORTFOLIO
          )
        else
          handleEstimation(
            product?.id,
            false,
            amount,
            weightType,
            ProductType.PROJECT
          )
      }
      if (tranche) {
        handleEstimation(
          tranche.id,
          false,
          amount,
          weightType,
          ProductType.TRANCHE
        )
      }
    } else {
      resetEstimate()
      setHasInventoryError(false)
      setIsEstimationLoading(false)
    }
  }

  const resetEstimate = () => {
    setEstimate({
      ...estimate,
      cost: { in_requested_currency: { total_cost: 0 } },
      total_co2e_in_kg: 0,
    })
    setProjectEstimate({
      total_weight: 0,
      total_cost: 0,
      currency_type: defaultCurrency,
      weight_type: weightType,
    })
    setHasInventoryError(false)
    setEstimateErrorType(undefined)
  }

  /**
   * Request an estimation for either a project, vintage or portfolio.
   *
   * @param id
   * @param showIsLoading
   * @param amountInPurchaseModal
   * @param weightTypePurchaseModal
   * @param estimationType
   */
  const handleEstimation = (
    id = undefined,
    showIsLoading = true,
    amountInPurchaseModal = null,
    weightTypePurchaseModal = null,
    estimationType = undefined
  ) => {
    const inputAmount = amountInPurchaseModal || amount
    const inputWeightType = weightTypePurchaseModal || weightType
    let productId = id || projectToPurchase
    const body = { tags: ['In-app'] }
    //the BE expects 'mt' instead of 't'
    let tempWeightType = inputWeightType === 'tonnes' ? 'mt' : inputWeightType
    const defaultCurrency = account?.default_currency
    body.weight = { value: Number(inputAmount), units: tempWeightType }
    body.currency = defaultCurrency

    switch (estimationType) {
      case ProductType.PROJECT:
        body.project_match = { project_id: productId }
        break
      case ProductType.PORTFOLIO:
        body.project_match = { portfolio_id: productId }
        break
      case ProductType.TRANCHE:
        body.project_match = { tranche_id: productId }
        break
      default:
        return
    }

    setEstimate({})
    resetEstimate()

    if (!isNaN(inputAmount) && Number(inputAmount) > 0) {
      setIsEstimationLoading(true)
      serviceRequest({
        path: '/estimates/carbon',
        method: 'POST',
        data: body,
        sourceRef,
        showLoading: showIsLoading,
      })
        .then(data => {
          let finalEstimate
          if (data?.transactions) {
            let total_cost_in_requested_currency = 0
            let total_co2e_in_kg = 0
            let requested_currency = defaultCurrency
            // iterate through all the transactions returned for the portfolio
            data?.transactions?.forEach(transaction => {
              total_cost_in_requested_currency +=
                transaction.cost?.in_requested_currency?.total_cost
              total_co2e_in_kg += transaction.total_co2e_in_kg
              requested_currency = transaction.cost?.requested_currency
            })
            // construct the estimate object
            finalEstimate = {
              cost: {
                in_requested_currency: {
                  total_cost: total_cost_in_requested_currency,
                },
                requested_currency,
              },
              total_co2e_in_kg,
              transactions: data,
            }
          } else {
            finalEstimate = data
          }

          setProjectEstimate({
            total_weight: finalEstimate.total_co2e_in_kg,
            total_cost: finalEstimate.cost.in_requested_currency.total_cost,
            currency_type: finalEstimate.cost.requested_currency,
            weight_type: inputWeightType,
          })

          setEstimate(finalEstimate)
          setHasInventoryError(false)
          setIsEstimationLoading(false)
        })
        .catch(e => handleEstimationError({ estimationType, e }))
    } else {
      setIsEstimationLoading(false)
      warningToast('Amount must be larger than 0')
    }
  }

  /**
   * Updates the state based on the estimation type and error received.
   *
   * @param estimationType
   * @param e
   */
  const handleEstimationError = ({ estimationType, e }) => {
    if (e.response?.data?.Message) {
      const message = e.response?.data?.Message
      // sine we only get error messages and not error codes, we need to check
      // the message contents to determine what kind of error occurred.
      let estimateErrorType = EstimateErrorType.UNKNOWN
      let estimationErrorMessage = message
      if (message.includes('amount too low')) {
        estimateErrorType = EstimateErrorType.PURCHASE_AMOUNT_TOO_SMALL
        estimationErrorMessage =
          'Amount too small to purchase from this portfolio.'
      }
      if (message.includes('Not enough units')) {
        estimateErrorType = EstimateErrorType.LOW_INVENTORY
        estimationErrorMessage =
          'Amount exceeds available inventory for this portfolio.'
      }

      // we don't have a design for the error message on the portfolio page, so for now, we still have to show this toast.
      // only display warning toast if we get a message in the response
      // also, only show waring if estimation is type portfolio and purchase modal is not open
      if (
        estimationType === ProductType.PORTFOLIO &&
        !showModalWithValues &&
        !showEmptyStateModal
      )
        warningToast(estimationErrorMessage, 'warning')

      setEstimateErrorType(estimateErrorType)
    } else {
      setEstimateErrorType(EstimateErrorType.UNKNOWN)
    }

    setHasInventoryError(true)
    setIsEstimationLoading(false)
  }

  //gets invoked on click of Purchase button
  const purchaseEstimateWithValues = async e => {
    const data =
      project.productType === ProductType.PORTFOLIO
        ? {
            portfolio_transaction_id:
              estimate?.transactions.transactions[0]?.transaction_id,
          }
        : { estimate_slug: estimate.transaction_id }

    e.preventDefault()
    try {
      const response = await serviceRequest({
        path: '/purchases',
        method: 'POST',
        data,
        sourceRef,
        showLoading: true,
      })
      setPurchaseTransactionId(response[0].transaction_id)
      setReceiptUrl(response[0].receipt_url)
    } catch (e) {
      warningToast('Purchase has failed', 'warning')
    }
  }

  //redirect to buy offset modal
  const redirectToModal = async (e, projectId, productType) => {
    e.preventDefault()
    setProjectToPurchase(projectId)
    setProductTypeToPurchase(productType)
    if (Number(amount) === 0) {
      setShowEmptyStateModal(true)
    } else {
      setIsEstimationLoading(true)
      setShowModalWithValues(true)
    }
  }

  // set isBilling enabled based off of account billing availability
  useEffect(() => {
    setIsBillingEnabled(
      account &&
        (account.billing_method === 'invoice' ||
          account.billing_method === 'no_billing' ||
          (account.billing_method === 'stripe' && account.stripe_id !== null))
    )
  }, [account])

  //check to see if exchange rates are available before using them
  useEffect(() => {
    if (!notInitialRender.current) notInitialRender.current = true
    // eslint-disable-next-line
  }, [currencyRates])

  //reset all values if account is switched
  useEffect(() => {
    resetCalculatorValues()
    // eslint-disable-next-line
  }, [account])

  //handle filters
  const filterAndSort = (listingMode = undefined) => {
    // filter
    const modeFilter = listingMode || marketplaceListingMode
    let storedProducts = JSON.parse(localStorage.getItem('products')) || []

    let result =
      modeFilter === MarketPlaceListingModes.PROJECTS
        ? filterProjectsByTypeValue(storedProducts, filterType, filterValue)
        : filterPortfoliosByTypeValue(storedProducts, filterType, filterValue)

    // sort products by sort order
    result = sortProductsBySortOrder(result, sortOrder)

    setProjects(result)
  }

  useEffect(
    () => filterAndSort(),
    // eslint-disable-next-line
    [filterValue, filterType, sortOrder]
  )

  // listing mode methods
  const listingModeUpdated = marketplaceListingMode => {
    setMarketplaceListingMode(marketplaceListingMode)
    filterAndSort(marketplaceListingMode)
    setListView('grid')
  }

  const downloadCertificate = event => {
    event.preventDefault()
    event.stopPropagation()
    serviceRequest({
      path: `/transaction/${purchaseTransactionId}/certificate`,
      method: 'GET',
      options: { responseType: 'arraybuffer' },
    })
      .then(certificate => {
        infoToast('Certificate Downloaded.')
        download(
          certificate,
          `Purchase Certificate - ${purchaseTransactionId} .pdf`,
          'application/pdf'
        )
      })
      .catch(e => console.log(e))
  }

  /**
   * @param mode
   * @param amount
   * @param weightType
   * @param selectedProduct
   * @param selectedVintage
   */
  const onFetchEstimate = ({
    mode,
    amount,
    weightType,
    selectedProduct,
    selectedVintage,
  }) => {
    //console.log('MarketplaceContext:onFetchEstimate()', mode, amount, weightType, selectedProduct, selectedVintage)
    if (!isNaN(amount) && Number(amount) > 0) {
      if (mode === ProductType.TRANCHE && selectedVintage)
        updateEstimation({ tranche: selectedVintage, amount, weightType })
      else if (selectedProduct)
        updateEstimation({ product: selectedProduct, amount, weightType })
    }
  }

  /**
   * @type {onFetchEstimate}
   */
  const debounceFetchEstimate = useMemo(
    () => debounce(onFetchEstimate, 350),
    []
  )

  const value = {
    account,
    sourceRef,
    sortOptions,
    amount,
    weightType,
    estimate,
    weightTypeOptions,
    showModalWithValues,
    showEmptyStateModal,
    isEstimationLoading,
    projectEstimate,
    projectDetails,
    isBillingEnabled,
    refetchProjects,
    sortLabel,
    project,
    sortOrder,
    projects,
    listView,
    filterType,
    filterValue,
    hasInventoryError,
    estimateErrorType,
    setIsEstimationLoading,
    receiptUrl,
    purchaseTransactionId,
    calculatedTotalAmount,
    setHasInventoryError,
    purchaseEstimateWithValues,
    isDisplayRealAmount,
    handleEstimation,
    setAmount,
    setEstimate,
    setWeightType,
    setShowModalWithValues,
    setShowEmptyStateModal,
    redirectToModal,
    setProjectToPurchase,
    setProjectEstimate,
    setProjectDetails,
    setIsBillingEnabled,
    resetCalculatorValues,
    setRefetchProjects,
    handleRenderTooltipContent,
    setListView,
    setProjects,
    setSortOrder,
    setProject,
    filterAndSort,
    setSortLabel,
    setFilterType,
    setFilterValue,
    setReceiptUrl,
    handleCalculateCostPerTonne,
    marketplaceListingMode,
    listingModeUpdated,
    productTypeToPurchase,
    setProductTypeToPurchase,
    hasPortfolioListings,
    setHasPortfolioListings,
    updateEstimation,
    resetEstimate,
    selectedVintage,
    setSelectedVintage,
    downloadCertificate,
    debounceFetchEstimate,
    defaultCurrency,
    containsCache,
    setContainsCache,
    currencyRates,
  }

  return (
    <MarketplaceContext.Provider value={value}>
      {children}
    </MarketplaceContext.Provider>
  )
}
