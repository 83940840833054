import React, { useEffect } from 'react'
import { useProjectDetails } from 'model/hooks/marketplace/useProjectDetails'
import { ProductType } from 'model/utils/marketplace/MarketplaceEnums'
import ReactMarkdown from 'react-markdown'
import { Link, useNavigate } from 'react-router-dom'
import DownloadProjectPDFButton from 'view/components/buttons/download-pdf-button/DownloadProjectPDFButton'
import ProjectInfoRequestLink from 'view/components/project-info-request-link/ProjectInfoRequestLink'
import LeadGenPanel from 'view/pages/project-details/components/LeadGenPanel'
import ProjectUnsdgPanel from 'view/pages/project-details/components/unsdg-panel/ProjectUnsdgPanel'
import ImageGalleryPanel from './components/image-gallery-panel/ImageGalleryPanel'
import ImageModal from './components/image-modal/ImageModal'
import NotableBuyers from './components/notable-buyers/NotableBuyers'
import ProjectRegistry from './components/project-registry/ProjectRegistry'
import ProjectTags from './components/project-tags/ProjectTags'
import ProjectLocationPanel from './components/ProjectLocationPanel'
import ProjectOverview from './components/ProjectOverview'
import ProjectTechnologyPanel from './components/ProjectTechnologyPanel'
import ProjectRatingsPanel from './components/ratings-and-standards/ProjectRatingsPanel'
import LeadGenRequestDetailsModal from '../../modals/lead-gen/lead-gen-request-details-modal/LeadGenRequestDetailsModal'
import VintageSelector from '../marketplace/components/purchase-modal/components/VintageSelector'
import PurchaseModal from '../marketplace/components/purchase-modal/PurchaseModal'
import { useMarketplaceContext } from '../marketplace/MarketplaceContext'
import './ProjectDetails.scss'

const ProjectDetails = () => {
  const navigate = useNavigate()

  const {
    showEmptyStateModal,
    showModalWithValues,
    redirectToModal,
    isBillingEnabled,
    project,
    selectedVintage,
    isEstimationLoading,
    defaultCurrency,
    currencyRates,
  } = useMarketplaceContext()

  const {
    smallWidth,
    imageModal,
    setImageModal,
    showLeadGenRequestDetailsModal,
    setShowLeadGenRequestDetailsModal,
    projectEstimate,
    leadGenNotify,
    setProjectDescriptionHtml,
  } = useProjectDetails()

  const handleAction = (e: any) => {
    if (isBillingEnabled) {
      redirectToModal(e, selectedVintage?.id, ProductType.TRANCHE)
    } else {
      navigate('/account-settings/billing')
    }
  }

  useEffect(() => {
    const checkMarkdown = () => {
      const el = document.querySelector('.react-markdown')
      let mdHTML = ''
      if (el) mdHTML = el.innerHTML
      setProjectDescriptionHtml(mdHTML)
      //console.log('Markdown', mdHTML)
    }
    checkMarkdown()
  }, [project?.long_description])

  return (
    project && (
      <>
        {(showModalWithValues || showEmptyStateModal) && (
          <PurchaseModal projectEstimate={projectEstimate} />
        )}
        <LeadGenRequestDetailsModal
          product={project}
          showModal={showLeadGenRequestDetailsModal}
          setShowModal={bool => setShowLeadGenRequestDetailsModal(bool)}
        />
        {imageModal.isOpen && (
          <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
        )}
        <div className="project-details">
          <div className="page-header private">
            <div className="header-breadcrumb-row">
              <Link to="/marketplace">Projects</Link>&nbsp;/{' '}
              {project.short_name || project.name}
            </div>
            <div className="header-title-row">
              <div className="title-section">
                <div className="left-block">
                  <h1>{project.short_name || project.name}</h1>
                </div>
                <div className="right-block">
                  <DownloadProjectPDFButton
                    project={project}
                    isEstimationLoading={isEstimationLoading}
                  />
                </div>
              </div>
              <ProjectTags project={project} />
            </div>
          </div>
          <div className="containers">
            <div className={`left-container ${smallWidth ? 'responsive' : ''}`}>
              <ProjectOverview project={project} />
              <ImageGalleryPanel imageList={project?.image_list} />
              <ProjectTechnologyPanel project={project} />
              <ProjectRatingsPanel project={{ ...project }} />
              <ProjectRegistry project={project} />
              <ProjectInfoRequestLink
                id={project?.id}
                name={project?.shortName || project?.name}
              />
            </div>
            <div
              className={`right-container tight ${
                smallWidth ? 'responsive' : ''
              }`}
            >
              {project.is_purchasable && (
                <VintageSelector
                  projectEstimate={projectEstimate}
                  handleAction={handleAction}
                />
              )}

              {!project.is_purchasable && (
                <LeadGenPanel
                  project={project}
                  defaultCurrency={defaultCurrency}
                  currencyRates={currencyRates}
                  leadGenNotify={leadGenNotify}
                />
              )}
              <NotableBuyers
                notableBuyers={project.quality_markers?.notable_buyers}
              />
              <ProjectUnsdgPanel unsdgList={project.unsdg} />
              <ProjectLocationPanel project={project} />
            </div>
          </div>
        </div>
        <div style={{ display: 'none' }}>
          <ReactMarkdown className="react-markdown">
            {project.long_description}
          </ReactMarkdown>
        </div>
      </>
    )
  )
}

export default ProjectDetails
