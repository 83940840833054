import React from 'react'
import {
  determineCurrencyRate,
  formatCost,
  formatCostInCurrency,
} from 'model/utils/cost'
import { formatWeightTypeAndAmount, getWeightUnit } from 'model/utils/units'
import ExternalLink from 'view/components/external-link/ExternalLink'
import '../../../../../../../checkout-public/components/project-selection/ProjectSelection.scss'
import './PresetValuePreview.scss'

const PresetValuePreview = React.memo(
  ({
    unit_type = 'CURRENCY',
    unit_value = 'USD',
    amount,
    project = null,
    currencyRates = null,
  }) => {
    const costPerTon =
      project?.cost_per_kg_carbon_in_usd_cents *
      10 *
      determineCurrencyRate(unit_value, currencyRates)

    const formattedAmount = amount => {
      amount = !amount ? 0 : amount
      return unit_type === 'CURRENCY'
        ? formatCostInCurrency(amount, unit_value, false, true)
        : amount + ' tonnes'
    }

    // determines what to render when amount is fixed depending on carbon vs currency
    const handleRenderTotal = () => {
      // console.log(amount, costPerTon)
      const estimatedAmount =
        unit_type !== 'CURRENCY'
          ? formatCost(amount * costPerTon, unit_value)
          : getWeightUnit({
              weightType: 'tonnes',
              value: (amount / costPerTon) * 1000,
              roundUnits: 0,
              format: '%v %u',
              isMT: true,
            })
      return (
        <>
          <span>
            {unit_type !== 'CURRENCY'
              ? formatWeightTypeAndAmount(amount, 'tonnes')
              : formatCost(amount, unit_value)}
          </span>
          <span className="amount-and-forward-arrow">{estimatedAmount}</span>
        </>
      )
    }

    const getPrimaryImage = image_list => {
      let url = ''
      if (image_list)
        image_list.forEach(image => {
          if (image.is_primary) url = image.url
        })
      return url
    }

    return (
      <>
        {project && currencyRates && (
          <>
            <div className="preset-value-preview">
              <div className="purchase-amount">
                Purchase amount:{' '}
                <span className="amount">{formattedAmount(amount)}</span>
              </div>
              <div className="project-item-panel">
                <img
                  className="panel-image"
                  src={getPrimaryImage(project.image_list)}
                  alt={project.id}
                />
                <div className="project-type-container">{project.type}</div>
                <div className="bottom-half">
                  <span className="project-name">{project.name}</span>
                  <div className="details">
                    <span className="cost">
                      {formatCost(costPerTon, unit_value)} / tonne
                    </span>
                    <span className="location">
                      {project.city}, {project.country}
                    </span>
                    <ExternalLink
                      title="View Details"
                      url={`https://info.cloverly.com/project-details?project=${project.id}`}
                    />
                  </div>
                </div>
                <div className="calculated-amount-section">
                  {handleRenderTotal()}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  }
)

export default PresetValuePreview
