import React from 'react'
import { Image, Link, Text, View } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { handleGenerateURL } from 'model/utils/image'
import {
  getHost,
  linkTitle,
  registryLogoBaseUrl,
  registryMap,
} from 'model/utils/registry'
import MainColumnSection from './MainColumnSection'

type Props = {
  project: Project
  styles: any
}
const RegistrySection = ({ project, styles }: Props) => {
  /**
   * @param {object} project
   * @returns {React.JSX.Element}
   */
  const ProjectRegistryNameOrLogo = (project: Project) => {
    const registry = registryMap.get(project.registry_name?.trim())
    return (
      <View style={styles.registryBodyCell}>
        {registry ? (
          <Image
            source={handleGenerateURL(registryLogoBaseUrl + registry.logo)}
            style={styles.registryImage}
            cache={true}
          />
        ) : (
          <Text style={styles.registryText}>{project.registry_name}</Text>
        )}
      </View>
    )
  }

  /**
   * @param {object} project
   * @returns {React.JSX.Element}
   */
  const ProjectRegistryLink = (project: Project) => {
    if (project.registry_project_id) {
      if (project.registry_link)
        return (
          <Link src={project.registry_link} style={styles.registryLink}>
            {project.registry_project_id}
          </Link>
        )
      return <Text>{project.registry_project_id}</Text>
    }
    if (project.registry_link) {
      const host = getHost(project.registry_link)
      if (host)
        return (
          <Link src={project.registry_link} style={styles.registryLink}>
            {host}
          </Link>
        )
    }
    return <Text>-</Text>
  }

  return (
    !!project?.registry_name && (
      <MainColumnSection title="Registry" styles={styles}>
        <View style={styles.tableContainer}>
          <View style={styles.tableCell}>
            <Text style={styles.tableHeader}>Registry</Text>
            {ProjectRegistryNameOrLogo(project)}
          </View>
          {(project?.registry_project_id || getHost(project.registry_link)) && (
            <View style={{ ...styles.tableCell, maxWidth: 116, minWidth: 116 }}>
              <Text style={styles.tableHeader}>{linkTitle(project)}</Text>
              <View style={styles.registryBodyCell}>
                {ProjectRegistryLink(project)}
              </View>
            </View>
          )}
        </View>
      </MainColumnSection>
    )
  )
}

export default RegistrySection
