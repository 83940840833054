import React, { useEffect, useState } from 'react'
import { envStageOrProd } from 'model/utils/env'
import FullStory from 'react-fullstory'
import { useLocation } from 'react-router-dom'

const FullStoryTracker = () => {
  // a list of all entry paths that will not have full story tracking initialized.
  const disabledPaths = ['/details/receipt']

  const location = useLocation()

  const [trackingEnabled, setTrackingEnabled] = useState(false)

  useEffect(() => {
    setTrackingEnabled(!isDisabledPath(location.pathname))
    // eslint-disable-next-line
  }, [location.pathname])

  const isDisabledPath = pathname => {
    let isDisabled = false
    disabledPaths.forEach(disabledPath => {
      if (pathname.startsWith(disabledPath)) isDisabled = true
    })
    // console.log('isDisabled?', isDisabled)
    return isDisabled
  }

  return (
    <>
      {envStageOrProd() && trackingEnabled && <FullStory org="o-1CE66A-na1" />}
    </>
  )
}

export default FullStoryTracker
