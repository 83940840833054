/**
 * Shortcut function that returns true if the current environment is Stage or Production.
 */
export const envStageOrProd = (): boolean =>
  ['stage', 'staging', 'prod'].includes(
    <string>process.env.REACT_APP_ENV?.toLowerCase()
  )

export const envLocalhost = (): boolean =>
  /local/.test(window.location.hostname) ||
  /127\.0\.0\.1/.test(window.location.hostname)
