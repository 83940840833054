import { useCallback, useEffect, useState } from 'react'
import { useCurrencyExchangeRates } from 'model/hooks/useCurrencyExchangeRates'
import { determineCurrencyRate } from 'model/utils/cost'
import { getWeightUnit } from 'model/utils/units'
import { useCheckoutQueryParamContext } from './CheckoutQueryParamContext'

export const useEstimateCalculation = () => {
  const { currencyRates } = useCurrencyExchangeRates({ showLoading: true })

  const { weightUnit, currency, shopBy, amount, minPurchaseAmount } =
    useCheckoutQueryParamContext()

  const costPerUnit = localStorage.getItem('cost-per-unit')

  const [estimate, setEstimate] = useState({
    total_weight: 0,
    total_cost: 0,
    currency: currency,
    weight_unit: getWeightUnit({ weightType: weightUnit, format: '%u' }),
  })

  const [costInUSD, setCostInUSD] = useState(null)

  const costPerDollar = costPerUnit * 0.01

  //to check if user input an amount less than an equivalence of $3
  const minimumAmount =
    (minPurchaseAmount / 100) * determineCurrencyRate(currency, currencyRates)

  //BE-side estimates get triggered only on click of Checkout btn
  const handleEstimateCurrencyAmount = useCallback(() => {
    let tempWeight = amount
    if (weightUnit === 'tonnes') {
      tempWeight = amount * 1000
    } else if (weightUnit === 'lbs') {
      tempWeight = amount / 2.20462
    } else {
      tempWeight = amount
    }
    const carbonCost = Math.ceil((tempWeight * costPerUnit) / 1000) / 100
    const totalCost =
      carbonCost * determineCurrencyRate(currency, currencyRates)
    setCostInUSD(Number(carbonCost))
    setEstimate(estimate => ({
      ...estimate,
      total_cost: Number(totalCost),
      total_weight: amount,
    }))
  }, [amount, currency, currencyRates, costPerUnit, weightUnit])

  const handleEstimateCarbonAmount = useCallback(() => {
    let costPerUnit = costPerDollar
    if (weightUnit === 'tonnes' || weightUnit === 'kg')
      costPerUnit = costPerDollar / 1000
    if (weightUnit === 'lbs') costPerUnit = costPerDollar / 1000
    const calculatedInputDollarAmount = amount / costPerUnit
    const calculatedWeight =
      calculatedInputDollarAmount /
      determineCurrencyRate(currency, currencyRates)
    setEstimate(estimate => ({
      ...estimate,
      total_weight: Number(calculatedWeight),
      total_cost: amount,
    }))
    setCostInUSD(
      Number(amount / determineCurrencyRate(currency, currencyRates))
    )
  }, [amount, costPerDollar, currency, currencyRates, weightUnit])

  //when user inputs a value
  useEffect(() => {
    if (shopBy === 'carbon') handleEstimateCurrencyAmount()
    if (shopBy === 'currency') handleEstimateCarbonAmount()
  }, [handleEstimateCurrencyAmount, handleEstimateCarbonAmount, shopBy])

  return { estimate, setEstimate, costInUSD, minimumAmount }
}
