import React from 'react'
import { Project } from 'interfaces/Project.interface'
import { registryLogoBaseUrl, registryMap } from 'model/utils/registry'

type Props = {
  project: Project
}

const ProjectRegistryNameOrLogo = ({ project }: Props) => {
  const registry = registryMap.get(project.registry_name?.trim())
  if (registry) {
    return (
      <img
        src={registryLogoBaseUrl + registry.logo}
        alt={project.registry_name}
        className="h-5 w-fit"
      />
    )
  }
  return <>{project.registry_name}</>
}

export default ProjectRegistryNameOrLogo
