import React from 'react'

const CCPEligibilityCriteria = () => {
  return (
    <>
      <p className="text-sm text-text-normal">
        To be considered eligible, credits must fund projects to reduce and
        remove emissions that are:
      </p>
      <ul className="text-sm list-disc list-inside ml-2 [&>li>b]:-ml-[6px]">
        <li>
          <b>Compatible with a transition to net zero.</b> The framework rules
          out projects that lock in emissions.
        </li>
        <li>
          <b>Permanent.</b> Projects must compensate for any reversals that
          happen within 40 years.
        </li>
        <li>
          <b>Additional.</b> Reductions and removals would not have happened
          without carbon credit revenue.
        </li>
        <li>
          <b>Robustly quantified.</b> The emissions impact must be measured
          conservatively to minimize the risk of overestimation.
        </li>
      </ul>
    </>
  )
}

export default CCPEligibilityCriteria
