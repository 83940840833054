import React from 'react'

const RegistryIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 6.1875C9 5.59076 9.23705 5.01847 9.65901 4.59651C10.081 4.17455 10.6533 3.9375 11.25 3.9375H15.75C15.8992 3.9375 16.0423 3.99676 16.1477 4.10225C16.2532 4.20774 16.3125 4.35082 16.3125 4.5V13.5C16.3125 13.6492 16.2532 13.7923 16.1477 13.8977C16.0423 14.0032 15.8992 14.0625 15.75 14.0625H11.25C10.6533 14.0625 10.081 14.2996 9.65901 14.7215C9.23705 15.1435 9 15.7158 9 16.3125" stroke="#661DC9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.6875 13.5C1.6875 13.6492 1.74676 13.7923 1.85225 13.8977C1.95774 14.0032 2.10082 14.0625 2.25 14.0625H6.75C7.34674 14.0625 7.91903 14.2996 8.34099 14.7215C8.76295 15.1435 9 15.7158 9 16.3125V6.1875C9 5.59076 8.76295 5.01847 8.34099 4.59651C7.91903 4.17455 7.34674 3.9375 6.75 3.9375H2.25C2.10082 3.9375 1.95774 3.99676 1.85225 4.10225C1.74676 4.20774 1.6875 4.35082 1.6875 4.5V13.5Z" stroke="#661DC9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.25 6.75H14.0625" stroke="#661DC9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.25 9H14.0625" stroke="#661DC9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.25 11.25H14.0625" stroke="#661DC9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
export default RegistryIcon
