import React from 'react'
import HighDurabilityIcon from 'view/components/icons/durability-icons/HighDurabilityIcon'
import LowDurabilityIcon from 'view/components/icons/durability-icons/LowDurabilityIcon'
import MediumDurabilityIcon from 'view/components/icons/durability-icons/MediumDurabilityIcon'
import AvoidanceMechanismIcon from 'view/components/mechinism-icons/AvoidanceMechanismIcon'
import RemovalMechanismIcon from 'view/components/mechinism-icons/RemovalMechanismIcon'
import HighDurabilityIconPDF from 'view/components/pdf-components/icons/durability-icons/HighDurabilityIcon'
import LowDurabilityIconPDF from 'view/components/pdf-components/icons/durability-icons/LowDurabilityIcon'
import MediumDurabilityIconPDF from 'view/components/pdf-components/icons/durability-icons/MediumDurabilityIcon'
import AvoidanceMechanismIconPDF from 'view/components/pdf-components/icons/mechinism-icons/AvoidanceMechanismIcon'
import RemovalMechanismIconPDF from 'view/components/pdf-components/icons/mechinism-icons/RemovalMechanismIcon'

/**
 * @type {Map<string, {icon: React.JSX.Element, pdfIcon: React.JSX.Element, label: string}>}
 */
const mechanismMap = new Map([
  [
    'REMOVAL',
    {
      icon: <RemovalMechanismIcon />,
      pdfIcon: <RemovalMechanismIconPDF />,
      label: 'Removal',
    },
  ],
  [
    'AVOIDANCE',
    {
      icon: <AvoidanceMechanismIcon />,
      pdfIcon: <AvoidanceMechanismIconPDF />,
      label: 'Avoidance',
    },
  ],
])

/**
 * @type {Map<string, {icon: React.JSX.Element, pdfIcon: React.JSX.Element, label: string}>}
 */
const durabilityMap = new Map([
  [
    'SHORT',
    {
      icon: <LowDurabilityIcon />,
      pdfIcon: <LowDurabilityIconPDF />,
      label: '<100 years',
    },
  ],
  [
    'MEDIUM',
    {
      icon: <MediumDurabilityIcon />,
      pdfIcon: <MediumDurabilityIconPDF />,
      label: '100-999 years',
    },
  ],
  [
    'LONG',
    {
      icon: <HighDurabilityIcon />,
      pdfIcon: <HighDurabilityIconPDF />,
      label: '1000+ years',
    },
  ],
])

/**
 * Exports public defs.
 */
export { mechanismMap, durabilityMap }
