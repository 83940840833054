import React from 'react'
import { Image, Link, Text, View } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { unsdgMap } from 'model/utils/unsdg'
import MainColumnSection from './MainColumnSection'

type Props = {
  project: Project
  styles: any
}

const UNSDGSection = ({ project, styles }: Props) => {
  if (!project?.unsdg?.length) return null
  return (
    <MainColumnSection
      styles={styles}
      title={
        <View style={styles.mainColumnVerticalSection}>
          <Text style={styles.mainColumnVerticalSectionText}>
            U.N. Sustainable
          </Text>
          <Text style={styles.mainColumnVerticalSectionText}>
            Development Goals
          </Text>
          <Link
            style={styles.mainColumnVerticalSectionLink}
            src="https://sdgs.un.org/goals"
          >
            Overview of UN SDGs
          </Link>
        </View>
      }
    >
      <View style={styles.unsdgTileBox}>
        {project.unsdg
          .sort((a, b) => (a > b ? 1 : -1))
          .map((unsdgId, idx) => (
            <View style={styles.unsdgTileContainer} key={idx}>
              <Image
                key={idx}
                style={styles.unsdgTile}
                source={unsdgMap.get(unsdgId)?.icon}
                cache={true}
              />
            </View>
          ))}
      </View>
    </MainColumnSection>
  )
}

export default UNSDGSection
