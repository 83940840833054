import React, { useEffect } from 'react'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import { useAuth } from 'model/hooks/account/useAuth'
import { useLocation } from 'react-router-dom'
import Button from 'view/components/button/Button'
import './RegistrationForm.scss'

const getRequiredErrorMessage = field => `${field} is required`

/* eslint-disable no-useless-escape */
const RegistrationForm = ({ slug, coBrandingSetting }) => {
  const { Option } = Select

  // an object with defaultValues prop could be passed as an argument to useForm()
  const { registerUser } = useAuth()
  const [form] = Form.useForm()

  // options
  const buildOptions = [
    'Ecommerce',
    'Enterprise Resource',
    'Flights',
    'Rideshare',
    'Fintech',
    'Supply Chain',
    'Energy',
    'Fleet',
    'Other',
  ]
  const roleOptions = [
    'Developer/IT',
    'Sustainability',
    'Marketing',
    'Ecommerce',
    'Product Manager',
    'Owner/CEO',
    'Other',
  ]
  const billingOptions = [
    {
      key: 'invoice',
      label: 'Invoice (via ACH, Credit Card, or other methods)',
    },
    { key: 'stripe', label: 'Credit Card' },
  ]

  const emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const passwordPattern = new RegExp(
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
  )
  const phonePattern =
    /^\+?((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)?(?:\W*\d){0,13}\d$/

  const errorMessages = {
    required: 'This field is required',
    password:
      'Your password must contain at least one lowercase letter and one digit. Your password must contain at least 6 characters.',
    passwordsDontMatch: 'Passwords do not match',
    phoneNumber: 'Must be a valid phone number',
    email: 'Must be a valid email address',
    billing: 'Must select your billing preference',
    tos: 'Must agree to the terms of service',
  }

  /**
   * Query params fields.
   * These properties can be injected via query params to populate the form fields.
   */
  const urlSearchParams = new URLSearchParams(useLocation().search)
  const queryParamFields = [
    'first_name',
    'last_name',
    'email',
    'phone',
    'country_code',
    'company',
    'build',
    'role',
    'billing',
  ]

  /**
   * Each query param can have its own value validator if necessary.
   */
  const valueValidator = (key, value) => {
    switch (key) {
      case 'build':
        return buildOptions.includes(value)
      case 'role':
        return roleOptions.includes(value)
      default:
        return true
    }
  }

  /**
   * Initial load effect.
   */
  useEffect(
    () =>
      queryParamFields.forEach(param => {
        if (urlSearchParams.get(param)) {
          switch (param) {
            case 'billing':
              billingOptions
                .filter(option => option.key === urlSearchParams.get(param))
                .map(option => form?.setFieldValue(param, option.key))
              break
            default:
              if (valueValidator(param, urlSearchParams.get(param))) {
                form?.setFieldValue('First Name', urlSearchParams.get(param))
              }
              break
          }
        }
      }),
    // eslint-disable-next-line
    []
  )

  // co-branding & parent/child billing helper functions
  const isDefault = () => slug === undefined
  const isChildBillingUserChoice = () =>
    coBrandingSetting.child_billing_method === 'user_choice'

  // const isParentPays = () => coBrandingSetting.billing_method === 'parent'

  // define parent TOS when available
  const { tos_href: parentTOS, tos_display_text: parentTOSDisplayText } =
    !!coBrandingSetting && coBrandingSetting
  const hasParentTOS = parentTOS && parentTOSDisplayText

  const onFinish = () => {
    const formValues = form?.getFieldsValue()

    const user = {
      user: {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        email: formValues.email,
        password: formValues.password,
        company: formValues.company,
        phone: formValues.phone?.replace(/\D/g, ''),
        accepted_tos_at: !!(hasParentTOS
          ? formValues.tos && formValues.parent_tos
          : formValues.tos),
      },
      account: {
        name: formValues.company || null,
      },
    }

    // if slug is defined, then add additional parent related params
    if (slug) {
      user.parent_slug = slug
      if (isChildBillingUserChoice())
        user.account.billing_method = formValues.billing
    }

    registerUser(user)
  }

  // define common fields to keep the form DRY
  const basicFields = [
    { label: 'First Name', name: 'first_name', required: true },
    { label: 'Last Name', name: 'last_name', required: true },
    {
      label: 'Email',
      name: 'email',
      required: true,
      rules: [{ pattern: emailPattern, message: errorMessages.email }],
    },
    {
      label: 'Phone (optional)',
      name: 'phone',
      rules: [{ pattern: phonePattern, message: errorMessages.phoneNumber }],
    },
    { label: 'Country', name: 'country_code', required: true },
    { label: 'Company (optional)', name: 'company' },
  ]

  const tosFields = [
    (hasParentTOS && {
      name: 'parent_tos',
      label: (
        <div className="tos">
          {`I accept the `}{' '}
          <a target="_blank" rel="noopener noreferrer" href={parentTOS}>
            {parentTOSDisplayText}
          </a>
        </div>
      ),
    }) ||
      undefined,
    {
      name: 'tos',
      label: (
        <div className="tos">
          {`I accept the `}{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cloverly.com/term-of-service"
          >
            Cloverly Terms of Service
          </a>
          {` and consent to the `}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cloverly.com/privacy-policy"
          >
            Privacy Policy
          </a>
        </div>
      ),
    },
  ]

  const defaultSelectFields = [
    {
      name: 'product',
      label: 'What are you building? (Optional)',
      options: buildOptions,
    },
    {
      name: 'role',
      label: 'What is your role?',
      rules: [{ required: true, message: getRequiredErrorMessage('Role') }],
      options: roleOptions,
    },
  ]

  return (
    <div className="user-form">
      <Form
        initialValues={{}}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        layout="vertical"
        labelWrap
        labelAlign="left"
        requiredMark={false}
      >
        {basicFields.map(field => {
          return (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={[
                field.required && {
                  required: true,
                  message: getRequiredErrorMessage(field.label),
                },
                ...(field.rules || []),
              ]}
            >
              <Input />
            </Form.Item>
          )
        })}
        {isDefault() &&
          defaultSelectFields.map(field => {
            return (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={field.rules || []}
              >
                <Select>
                  <option value="">Select</option>
                  {field.options.map((option, idx) => (
                    <Option key={idx} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )
          })}
        {!isDefault() && isChildBillingUserChoice() && (
          <Form.Item
            label={`What’s your billing preference?`}
            name="billing"
            rules={[
              {
                required: true,
                message: getRequiredErrorMessage('Billing Preference'),
              },
            ]}
          >
            <Select>
              <option value="">Select</option>
              {billingOptions.map(option => (
                <Option key={option.key} value={option.key}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: getRequiredErrorMessage('Password') },
            {
              pattern: passwordPattern,
              min: 6,
              message: errorMessages.password,
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="password_confirmation"
          dependencies={['password']}
          rules={[
            { required: true, message: errorMessages.required },
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                if (value !== getFieldValue('password')) {
                  return Promise.reject(
                    new Error(errorMessages.passwordsDontMatch)
                  )
                }
              },
            }),
          ]}
        >
          <Input type="password" />
        </Form.Item>
        {tosFields.map(field => {
          return (
            field && (
              <Form.Item
                className="tos"
                key={field.name}
                valuePropName="checked"
                name={field.name}
                rules={[
                  {
                    validator: async (_, checked) => {
                      if (!checked) {
                        return Promise.reject(new Error(errorMessages.tos))
                      }
                    },
                  },
                ]}
              >
                <Checkbox>{field.label}</Checkbox>
              </Form.Item>
            )
          )
        })}
        <Form.Item>
          <Button cn={'form-btn register'} type="primary" htmlType="submit">
            Create Account
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default RegistrationForm
