import React, { useEffect, useState } from 'react'
import { mapConfig } from 'model/utils/react-map'
import ReactMapGL, { Marker } from 'react-map-gl'
import PinIcon from 'view/components/icons/pin-icon/PinIcon'

const ProjectDetailsMap = ({ project, className = '' }) => {
  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 2,
  })

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: project?.location?.x,
      longitude: project?.location?.y,
    })
    // eslint-disable-next-line
  }, [project])

  return (
    <div className="map-container">
      <ReactMapGL
        {...viewport}
        className={`${className}`}
        maxZoom={8}
        minZoom={1.5}
        mapboxAccessToken={mapConfig.mapboxAccessToken}
        mapStyle="mapbox://styles/cloverly/ckykicffr64sj14qp56qaxksj"
        onMove={evt => setViewport(evt.viewState)}
        onViewportChange={nextViewport => setViewport(nextViewport)}
      >
        <Marker
          latitude={project?.location?.x}
          longitude={project?.location?.y}
          offset={mapConfig.markerOffset}
        >
          <PinIcon />
        </Marker>
      </ReactMapGL>
    </div>
  )
}

export default ProjectDetailsMap
