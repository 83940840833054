import React from 'react'
import { Link } from 'react-router-dom'
import './OptionalLink.scss'

const OptionalLink = ({ title, linkTitle, to, cn, isExternalLink = false }) => {
  return (
    <div className={`optional-link ${cn}`}>
      <span>{title}</span>
      {!isExternalLink && <Link to={to}>{linkTitle}</Link>}
      {isExternalLink && <a href={to}>{linkTitle}</a>}
    </div>
  )
}

export default OptionalLink
