import React, { useState } from 'react'
import { Project } from 'interfaces/Project.interface'
import { useDownloadProjectPdf } from 'model/hooks/project/useDownloadProjectPdf'
import { convertEstimateToQuote } from 'model/utils/project/projectUtils'
import { CloverlyButton } from 'view/components/cloverly-button/CloverlyButton'
import { useMarketplaceContext } from 'view/pages/marketplace/MarketplaceContext'

type Props = {
  project: Project
  isEstimationLoading: boolean
}

const DownloadProjectPDFButton = ({ project, isEstimationLoading }: Props) => {
  const { currencyRates, projectEstimate, selectedVintage } =
    useMarketplaceContext()
  const [pdfLoading, setPdfLoading] = useState(false)
  const quote = convertEstimateToQuote(selectedVintage, projectEstimate)
  const { downloadProjectPdf } = useDownloadProjectPdf({
    project,
    currencyRates,
    quote,
    setPdfLoading,
  })
  return (
    <CloverlyButton
      state="neutral"
      variant="outlined"
      onClick={downloadProjectPdf}
      disabled={isEstimationLoading}
      loading={pdfLoading}
    >
      Download as PDF
    </CloverlyButton>
  )
}

export default DownloadProjectPDFButton
