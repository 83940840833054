import React, { useState } from 'react'
import './FileSelectorWidget.scss'

const FileSelectorWidget = ({ clearImage, brandImageUpdated }) => {
  React.useEffect(
    () => (clearImage.current = () => imageSelected()),
    // eslint-disable-next-line
    []
  )

  const [highlight, setHighlight] = useState(false)

  const [preview, setPreview] = useState('')

  const [, setDrop] = useState(false)

  const fileInputRef = React.createRef()

  const handleEnter = e => {
    e.preventDefault()
    e.stopPropagation()
    if (preview === '') {
      setHighlight(true)
    }
  }

  const handleOver = e => {
    e.preventDefault()
    e.stopPropagation()
    if (preview === '') {
      setHighlight(true)
    }
  }

  const handleLeave = e => {
    e.preventDefault()
    e.stopPropagation()
    setHighlight(false)
  }

  const handleUpload = e => {
    e.preventDefault()
    e.stopPropagation()
    setHighlight(false)
    setDrop(false)
    const [file] = e.target.files || e.dataTransfer.files
    uploadFile(file)
  }

  const uploadFile = file => {
    const reader = new FileReader()
    reader.onload = () => imageSelected(btoa(reader.result))
    reader.onerror = () =>
      console.error('There is a problem while selecting image...')
    reader.readAsBinaryString(file)
  }

  const imageSelected = base64Image => {
    setPreview(!base64Image ? '' : `data:image/*;base64,${base64Image}`)
    brandImageUpdated(base64Image)
  }

  return (
    <div
      onDragEnter={e => handleEnter(e)}
      onDragLeave={e => handleLeave(e)}
      onDragOver={e => handleOver(e)}
      onDrop={e => handleUpload(e)}
      className={`logo-preview-container upload${
        highlight ? ' is-highlight' : ''
      }`}>
      <input
        type="file"
        className="upload-file"
        accept="image/*"
        ref={fileInputRef}
        onChange={e => handleUpload(e)}
        onClick={e => (e.target.value = null)}
      />
      {!preview && (
        <div>
          {' '}
          Drag and Drop or{' '}
          <a onClick={() => fileInputRef.current.click()}>Browse</a> to upload
        </div>
      )}
      {preview && (
        <>
          <img className="preview" src={preview} />
          <a className="remove" onClick={() => clearImage.current()}>
            Remove
          </a>
        </>
      )}
    </div>
  )
}

export default FileSelectorWidget
