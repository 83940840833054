import React from 'react'
import { capFirstLetter } from 'model/utils/capitalize-first-letter'
import { metricColors } from 'model/utils/portfolio/portfolioUtils'

type Props = {
  metric: {
    key: string
    value: number
  }[]
}

const PercentageTags = ({ metric }: Props) => {
  return (
    <section className="flex gap-1 flex-wrap">
      {metric?.map((item, idx) => {
        const value = (item.value * 100).toFixed(0)
        return (
          <div
            key={idx}
            className="rounded-md border border-neutral-90 px-2 py-1 w-fit flex gap-2 items-center text-sm"
          >
            <div
              style={{
                width: `${value}%`,
                backgroundColor: metricColors[idx % metricColors.length],
              }}
              className="rounded-full w-2 h-2"
            />
            <p className="font-medium">{capFirstLetter(item.key)}</p>
            <p className="text-neutral-50">{value}%</p>
          </div>
        )
      })}
    </section>
  )
}

export default PercentageTags
