import React from 'react'

const DotIcon = ({ clicked }) => (
  <svg
    className="dot-icon"
    fill="none"
    height="29"
    viewBox="0 0 29 29"
    width="29"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="14.6667"
      cy="10.6667"
      fill={`${clicked ? '#014351' : '#02C98F'}`}
      r="6.66667"
      stroke={`${clicked ? '#02C98F' : '#014351'}`}
      strokeWidth="3"
    />
  </svg>
)

export default DotIcon
