import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { capFirstLetter } from 'model/utils/capitalize-first-letter'

type Props = {
  status: 'eligible' | 'compliant'
}

const CorsiaStatus = ({ status }: Props) => {
  const eligible = status === 'eligible'
  return (
    <div className="flex gap-1 items-center w-1/3">
      {eligible ? (
        <CheckCircleOutlineIcon className="text-neutral-60 size-[18px]" />
      ) : (
        <CheckCircleOutlineIcon className="text-[#1F9552] size-[18px]" />
      )}
      <p>{capFirstLetter(status)}</p>
    </div>
  )
}

export default CorsiaStatus
