interface Units {
  isKg?: boolean
  value?: number
  roundUnits?: number
  format?: string
  includeCommas?: boolean
}

interface WeightUnits {
  weightType: string
  value: number
  roundUnits: number
  format?: string
  includeCommas?: boolean
  reformatLarge?: boolean
  isMT?: boolean
}

/**
 * We're defining this function here as a const so that we don't have to
 * instantiate a new instance everytime getUnits is called. Saves memory.
 *
 * @type {Intl.NumberFormat}
 */
let intlNumberFormatter = new Intl.NumberFormat()

/**
 * @param {boolean} isKg a flag to indicate if the given number is in kgs or lbs
 * @param {number} value the given number value in units
 * @param {number} roundUnits the number of units lbs should be round to
 * @param {string} format a string format which is a regular expression or search string.
 * @param {boolean} includeCommas If the number should include comma separators in the output.  This will make the return type of string.
 * @returns {number|string} The return value after all formatting has been applied
 */
const getUnits = ({
  isKg = true,
  value = 0,
  roundUnits = 0,
  format,
  includeCommas = false,
}: Units) => {
  let updatedValue = value

  if (!isKg) {
    let roundString = '1'
    for (let i = 0; i < roundUnits; i += 1) {
      roundString += '0'
    }
    updatedValue =
      Math.round(value * 2.20462 * Number(roundString)) / Number(roundString)
  }

  if (format)
    return format
      .replace('%v', intlNumberFormatter.format(updatedValue))
      .replace('%u', isKg ? 'kg' : 'lbs')

  if (includeCommas) return intlNumberFormatter.format(updatedValue)

  return updatedValue
}

/**
 * @param {string}weightType
 * @param {number}value
 * @param {number}roundUnits
 * @param {*} format
 * @param {boolean} includeCommas
 * @param {boolean} reformatLarge
 * @param {boolean} isMT  a flag to distinguish the "t" in Marketplace (which is strictly a Metric Tonne) from the
 * other "t"s across the app where it could be either "Us ton" or "Metric tonne"
 * if MT is false, it will always be a US ton. If MT is true, it will always be a Metric tonne.
 * @returns{number|string} The return value after all formatting has been applied
 */

const getWeightUnit = ({
  weightType = 'kg',
  value = 0,
  roundUnits = 0,
  format = undefined,
  includeCommas = false,
  reformatLarge = true,
  isMT = false,
}: WeightUnits) => {
  let updatedValue = value

  //convert to and display in mt or t if the amount is too large
  if (reformatLarge) {
    if (
      weightType === 'kg' &&
      (updatedValue > 999.99 || updatedValue < -999.99)
    ) {
      weightType = 't'
      isMT = true
    } else if (
      weightType === 'lbs' &&
      (updatedValue * 2.20462 > 1999.99 || updatedValue * 2.20462 < -1999.99)
    ) {
      weightType = 't'
    }
  }

  if (weightType === 'lbs') {
    let roundString = '1'
    for (let i = 0; i < roundUnits; i += 1) {
      roundString += '0'
    }
    updatedValue =
      Math.round(value * 2.20462 * Number(roundString)) / Number(roundString)
  }
  //convert to metric tonne or US ton depending on kg or lbs (determined by isMT).
  else if (weightType === 't' || weightType === 'tonnes') {
    intlNumberFormatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
    })
    //for metric tonne vs US ton
    if (isMT) {
      updatedValue = value / 1000
    } else {
      updatedValue = (value * 2.20462) / 2000
    }
  }
  if (format) {
    return format
      .replace('%v', intlNumberFormatter.format(updatedValue))
      .replace('%u', isTonnesPluralOrSingular(updatedValue, weightType))
  }

  if (includeCommas) return intlNumberFormatter.format(updatedValue)

  return Number(updatedValue)
}

const isTonnesPluralOrSingular = (amount: number, weightUnit: string) => {
  if (amount > 1 && amount < 1.01) amount = Math.floor(amount)
  if (amount < 1 && amount > 0.9) amount = Math.round(amount)
  return Number(amount) === 1 && (weightUnit === 'tonnes' || weightUnit === 't')
    ? 'tonne'
    : weightUnit
}

/*
 * When no conversion is required in between different unit types.
 */
const formatWeightTypeAndAmount = (amount: number, weightUnit: string) => {
  return (
    intlNumberFormatter.format(amount) +
    ' ' +
    isTonnesPluralOrSingular(amount, weightUnit)
  )
}

export { getUnits, getWeightUnit, formatWeightTypeAndAmount }
