import React from 'react'
import { Project } from 'interfaces/Project.interface'
import { getHost, linkTitle } from 'model/utils/registry'
import ProjectRegistryLink from './ProjectRegistryLink'
import ProjectRegistryNameOrLogo from './ProjectRegistryNameOrLogo'

type Props = {
  project: Project
  className?: string
}

const ProjectRegistry = ({ project, className = '' }: Props) => {
  return (
    !!project?.registry_name && (
      <section
        className={`p-4 flex flex-col border border-neutral-80 rounded-xl gap-4 ${className}`}
      >
        <h2 className="font-bold text-lg">Registry</h2>
        <section className="flex gap-4">
          <div className="flex flex-col gap-1 grow">
            <h6 className="text-sm font-semibold text-muted">Registry</h6>
            <ProjectRegistryNameOrLogo project={project} />
          </div>
          {(project?.registry_project_id || getHost(project.registry_link)) && (
            <div className="flex flex-col gap-1 grow">
              <h6 className="font-semibold text-muted text-sm">
                {linkTitle(project)}
              </h6>
              <ProjectRegistryLink project={project} />
            </div>
          )}
        </section>
      </section>
    )
  )
}

export default ProjectRegistry
