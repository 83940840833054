import React from 'react'
import EmptyProjectsListIcon from 'view/components/icons/empty-projects-list-icon/EmptyProjectsListIcon'
import './EmptyProjects.scss'

const EmptyProjects = ({ hasResetFilterMessage }) => {
  return (
    <div className="empty-projects-list">
      <EmptyProjectsListIcon />
      <h3>No Projects Found</h3>
      {hasResetFilterMessage && <span>Reset filters to view all projects</span>}
    </div>
  )
}

export default EmptyProjects
