import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import Chart from 'react-apexcharts'
import { renderToString } from 'react-dom/server'
import ChartLabel from './ChartLabel'

const OffsetChart = ({ footprint }) => {
  const { account } = useUser()

  //converts between lbs and kg depending on account.display_kg
  const convertInWeight = data => {
    return data?.map(value => {
      if (!account?.display_kg) value = value * 2.20462
      return Math.ceil(value)
    })
  }

  const data = {
    options: {
      chart: {
        id: 'basic-bar',
        toolbar: {
          autoSelected: 'download',
          show: false,
        },
      },
      tooltip: {
        marker: {
          show: true,
        },
        fixed: {
          enabled: false,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
        custom: ({ series, seriesIndex, dataPointIndex }) =>
          renderToString(
            <ChartLabel
              series={series}
              seriesIndex={seriesIndex}
              dataPointIndex={dataPointIndex}
              labels={footprint?.chart?.tooltipLabels}
              display_kg={account.display_kg}
            />
          ),
      },
      colors: ['#999', '#02C98F'],
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      xaxis: {
        categories: footprint?.chart?.labels || [],
      },
      yaxis: {
        title: {
          text: `${account.display_kg ? 'Kg' : 'Lbs'} Carbon`,
        },
      },
      fill: {
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {},
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 0,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 20,
          vertical: 0,
        },
      },
    },
    series: [
      {
        name: 'Total Footprint',
        data: convertInWeight(footprint?.chart?.dataFootprint) || [],
      },
      {
        name: 'Total Offset',
        data: convertInWeight(footprint?.chart?.dataOffset) || [],
      },
    ],
  }

  return (
    <>
      <Chart
        series={data?.series || []}
        options={data?.options || {}}
        type="area"
        height="100%"
      />
    </>
  )
}

export default OffsetChart
