import React, { useEffect, useMemo, useState } from 'react'
import { setPageTitle } from 'model/utils/page'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import AirplaneIcon from 'view/components/icons/airplane-icon/AirplaneIcon'
import TruckIcon from 'view/components/icons/truck-icon/TruckIcon'
import '../../Shopify.scss'
import ShopifyFormControls from '../panels/shopify-form-controls/ShopifyFormControls'
import ShopifyFormPanel from '../panels/shopify-form-panel/ShopifyFormPanel'

const ShopifyShippingForm = React.memo(
  ({ shopifyData, saveShopifyDataChanges }) => {
    const [editMode, setEditMode] = useState(false)
    const {
      register,
      formState: { errors },
      watch,
      handleSubmit,
      reset,
    } = useForm({ defaultValues: useMemo(() => shopifyData, [shopifyData]) })
    const [defaultShippingMethod, setDefaultShippingMethod] =
      useState(undefined)
    const [shippingMethod, setShippingMethod] = useState(undefined)

    const errorMessages = {
      default_from_postal_code: 'Default postal code required.',
      default_item_weight_in_grams: 'Average product weight required.',
    }

    useEffect(() => {
      setPageTitle('Shipping | Shopify App')
    }, [])

    useEffect(() => {
      const subscription = watch((value, { type }) =>
        setEditMode(type ? true : editMode)
      )
      return () => subscription.unsubscribe()
      // eslint-disable-next-line
    }, [watch])

    useEffect(() => {
      if (shopifyData) {
        reset(shopifyData)
        setDefaultShippingMethod(shopifyData.default_shipping_mode)
        setShippingMethod(shopifyData.default_shipping_mode)
      }
      // eslint-disable-next-line
    }, [shopifyData])

    const changeShippingMethod = method => {
      setShippingMethod(method)
      setEditMode(true)
    }

    const cancelChanges = () => {
      reset()
      setShippingMethod(defaultShippingMethod)
      setEditMode(false)
    }

    const onSubmit = data =>
      saveShopifyDataChanges({
        default_from_country: data.default_from_country,
        default_from_postal_code: data.default_from_postal_code,
        default_item_weight_in_grams: data.default_item_weight_in_grams,
        default_shipping_mode: shippingMethod,
      }).then(() => setEditMode(false))

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ShopifyFormPanel>
          <ShopifyFormControls
            editMode={editMode}
            setEditMode={setEditMode}
            cancelChanges={cancelChanges}
            saveChanges={handleSubmit(onSubmit)}
          />
          <div className="split-panel">
            <div className="left-panel">
              <h3>Shipping Method</h3>
              <div className="large-toggle-container">
                <div
                  onClick={() => changeShippingMethod('air')}
                  className={
                    'large-toggle ' +
                    (shippingMethod === 'air' ? 'selected' : '')
                  }
                >
                  <div className="icon-container">
                    <AirplaneIcon />
                  </div>
                  Air
                </div>
                <div
                  onClick={() => changeShippingMethod('ground')}
                  className={
                    'large-toggle ' +
                    (shippingMethod === 'ground' ? 'selected' : '')
                  }
                >
                  <div className="icon-container">
                    <TruckIcon />
                  </div>
                  Ground
                </div>
              </div>
            </div>
            <div className="right-panel">
              <label htmlFor="default_from_country">Country of Origin</label>
              <Form.Select
                defaultValue={shopifyData?.default_from_country}
                id="default_from_country"
                name="default_from_country"
                {...register('default_from_country', { required: true })}
              >
                <option value="">Select Country...</option>
                <option value="AF">Afghanistan</option>
                <option value="AX">Aland Islands</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarctica</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermuda</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia</option>
                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="BV">Bouvet Island</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cape Verde</option>
                <option value="KY">Cayman Islands</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CX">Christmas Island</option>
                <option value="CC">Cocos (Keeling) Islands</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CG">Congo</option>
                <option value="CD">
                  Congo, Democratic Republic of the Congo
                </option>
                <option value="CK">Cook Islands</option>
                <option value="CR">Costa Rica</option>
                <option value="CI">Cote D'Ivoire</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CW">Curacao</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="SV">El Salvador</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FK">Falkland Islands (Malvinas)</option>
                <option value="FO">Faroe Islands</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GF">French Guiana</option>
                <option value="PF">French Polynesia</option>
                <option value="TF">French Southern Territories</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Greece</option>
                <option value="GL">Greenland</option>
                <option value="GD">Grenada</option>
                <option value="GP">Guadeloupe</option>
                <option value="GU">Guam</option>
                <option value="GT">Guatemala</option>
                <option value="GG">Guernsey</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>
                <option value="HM">Heard Island and Mcdonald Islands</option>
                <option value="VA">Holy See (Vatican City State)</option>
                <option value="HN">Honduras</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran, Islamic Republic of</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IM">Isle of Man</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JE">Jersey</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KI">Kiribati</option>
                <option value="KP">
                  Korea, Democratic People's Republic of
                </option>
                <option value="KR">Korea, Republic of</option>
                <option value="XK">Kosovo</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Lao People's Democratic Republic</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libyan Arab Jamahiriya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MO">Macao</option>
                <option value="MK">
                  Macedonia, the Former Yugoslav Republic of
                </option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MH">Marshall Islands</option>
                <option value="MQ">Martinique</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="YT">Mayotte</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia, Federated States of</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="ME">Montenegro</option>
                <option value="MS">Montserrat</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="MM">Myanmar</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NL">Netherlands</option>
                <option value="AN">Netherlands Antilles</option>
                <option value="NC">New Caledonia</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk Island</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="NO">Norway</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PS">Palestinian Territory, Occupied</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="PR">Puerto Rico</option>
                <option value="QA">Qatar</option>
                <option value="RE">Reunion</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="BL">Saint Barthelemy</option>
                <option value="SH">Saint Helena</option>
                <option value="KN">Saint Kitts and Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="MF">Saint Martin</option>
                <option value="PM">Saint Pierre and Miquelon</option>
                <option value="VC">Saint Vincent and the Grenadines</option>
                <option value="WS">Samoa</option>
                <option value="SM">San Marino</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="CS">Serbia and Montenegro</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SX">Sint Maarten</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="GS">
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="SS">South Sudan</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SJ">Svalbard and Jan Mayen</option>
                <option value="SZ">Swaziland</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan, Province of China</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic of</option>
                <option value="TH">Thailand</option>
                <option value="TL">Timor-Leste</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks and Caicos Islands</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option value="GB">United Kingdom</option>
                <option value="US">United States</option>
                <option value="UM">United States Minor Outlying Islands</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="VE">Venezuela</option>
                <option value="VN">Viet Nam</option>
                <option value="VG">Virgin Islands, British</option>
                <option value="VI">Virgin Islands, U.s.</option>
                <option value="WF">Wallis and Futuna</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </Form.Select>
              <label htmlFor="default_from_postal_code">Postal Code</label>
              <Form.Control
                className="small"
                type="text"
                defaultValue={shopifyData?.default_from_postal_code}
                id="default_from_postal_code"
                name="default_from_postal_code"
                isInvalid={errors.default_from_postal_code}
                {...register('default_from_postal_code', { required: true })}
              />
              {errors.default_from_postal_code && (
                <p className="invalid-message">
                  {errorMessages.default_from_postal_code}
                </p>
              )}
              <label htmlFor="default_item_weight_in_grams">
                Average Product Weight (grams)
              </label>
              <Form.Control
                className="small"
                type="number"
                min="0"
                max="100000000"
                step="1"
                defaultValue={shopifyData?.default_item_weight_in_grams}
                id="default_item_weight_in_grams"
                name="default_item_weight_in_grams"
                isInvalid={errors.default_item_weight_in_grams}
                {...register('default_item_weight_in_grams', {
                  required: true,
                })}
              />
              {errors.default_item_weight_in_grams && (
                <p className="invalid-message">
                  {errorMessages.default_item_weight_in_grams}
                </p>
              )}
              <p>
                Cloverly uses weight from the product record. This value is a
                fallback for when the product weight doesn't exist.
              </p>
            </div>
          </div>
        </ShopifyFormPanel>
      </Form>
    )
  }
)

export default ShopifyShippingForm
