import React from 'react'
import { Portfolio } from 'interfaces/Portfolio.interface'
import { Project } from 'interfaces/Project.interface'
import { groupedProjects } from 'model/utils/portfolio/portfolioUtils'
import ProjectInfoRequestLink from 'view/components/project-info-request-link/ProjectInfoRequestLink'
import PortfolioComposition from './PortfolioComposition'

type Props = {
  portfolio: Portfolio
}

const PortfolioCompositionList = ({ portfolio }: Props) => {
  const projects = portfolio?.offset_sources
  const complianceReportLinkLabel = 'Request California Compliance Report'

  return (
    <section className="flex p-4 flex-col gap-4 rounded-xl border border-neutral-80">
      <p className="text-lg font-bold">Portfolio composition</p>
      {!!projects?.length &&
        groupedProjects(projects)?.map((project: Project, idx) => {
          return (
            <PortfolioComposition
              key={`${project.id} ${idx}`}
              project={project}
            />
          )
        })}
      <ProjectInfoRequestLink
        id={portfolio.slug}
        name={portfolio.name}
        label={complianceReportLinkLabel}
        className="hover:underline hover:text-link-secondary"
      />
    </section>
  )
}

export default PortfolioCompositionList
