import React, { useEffect, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useNavigate } from 'react-router-dom'
import Button from 'view/components/button/Button'
import CreditCardsIcon from 'view/components/icons/credit-cards-icon/CreditCardsIcon'
import AddCardModal from '../../../../account-settings/components/modals/AddCardModal'
import '../../../Shopify.scss'

const ShopifyNoCardPanel = () => {
  const { account } = useUser()
  const navigate = useNavigate()
  const [showAddCard, setShowAddCard] = useState(false)
  const addCardSuccessful = () => navigate('/shopify/shipping')

  useEffect(() => {
    // if account status changed and billing is now enabled, route user to Shopify shipping page
    if (
      (account?.billing_method === 'stripe' && account.stripe_id !== null) ||
      account?.billing_method !== 'stripe'
    )
      addCardSuccessful()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <AddCardModal
        showAddCard={showAddCard}
        setShowAddCard={bool => setShowAddCard(bool)}
        addCardSuccessful={addCardSuccessful}
      />
      <h1>Shopify App</h1>
      <div className="no-card-on-file">
        <div className="circle">
          <CreditCardsIcon />
        </div>
        <h2>Add a card to begin</h2>
        <h3>If my customers are paying, why do I have to add a credit card?</h3>
        <p>
          When your customers buy a Cloverly carbon offset, the offset cost is
          added to their order total and they pay <b>you</b>. We use your credit
          card to collect the offset cost afterward. This provides a seamless
          experience for your customers. This is how it works:
        </p>
        <ul>
          <li>
            You add a card to your account and pre-pay for the offsets your
            customers buy.
          </li>
          <li>
            When your customer makes a purchase and chooses carbon neutral
            shipping, they pay you a small fee directly for the offset.
          </li>
          <li>
            We settle up with you by subtracting that same amount from your
            credit balance.
          </li>
          <li>
            When your credit balance is depleted, our auto-billing will kick in
            and re-up your account credits.
          </li>
        </ul>
        <p>
          When your credit balance is depleted, we charge your card to replenish
          it (you can choose the increment).
        </p>
        <Button onClick={() => setShowAddCard(true)}>Add a card</Button>
      </div>
    </>
  )
}

export default ShopifyNoCardPanel
