import React, { useEffect } from 'react'
import { setPageTitle } from 'model/utils/page'
import ExternalLinkIcon from 'view/components/icons/external-link-icon/ExternalLinkIcon'
import WidgetDesignIcon from 'view/components/icons/widget-design-icon/WidgetDesignIcon'
import WidgetEditIcon from 'view/components/icons/widget-edit-icon/WidgetEditIcon'
import '../../Shopify.scss'
import ShopifyFormPanel from '../panels/shopify-form-panel/ShopifyFormPanel'

const ShopifyDesignForm = React.memo(({ shopifyData }) => {
  useEffect(() => {
    setPageTitle('Design | Shopify App')
  }, [])

  return (
    <ShopifyFormPanel>
      <div className="centered-panel">
        <div className="widget-edit-icon-container">
          {shopifyData?.use_default_views ? (
            <WidgetDesignIcon />
          ) : (
            <WidgetEditIcon />
          )}
        </div>
        <h2>
          {shopifyData?.use_default_views
            ? 'Design your Widget'
            : 'Edit your Design'}
        </h2>
        <p className="centered">
          Customize the Shopify widget to match your
          <br />
          company’s visual style.
        </p>
        <a
          className="large-external"
          target="ShopifyDesigner"
          href={`https://${shopifyData?.domain}/?cloverly=debug`}
        >
          <span>Widget Designer</span>
          <ExternalLinkIcon />
        </a>
      </div>
    </ShopifyFormPanel>
  )
})

export default ShopifyDesignForm
