import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import './GoBackButton.scss'

const GoBackButton = ({
  title,
  href,
  withSearchParams = false,
  onClick = null, // can be injected from caller to run function prior to handling of click of this component
}) => {
  const navigate = useNavigate()

  const decideRoute = () =>
    navigate(withSearchParams || href?.startsWith('/') ? href : `/${href}`)

  const handleClick = () => {
    if (onClick) onClick()
    href ? decideRoute() : navigate(-1)
  }

  return (
    <button className="back-btn" onClick={handleClick}>
      <ArrowBackIcon />
      <span>{title}</span>
    </button>
  )
}

export default GoBackButton
