import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import Button from 'view/components/button/Button'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import './RemoveVintageModal.scss'

const RemoveVintageModal = ({
  showModal = false,
  setShowModal,
  updateIncludeVintageProjects,
  selectedVintageProjectNames,
}) => {
  const confirmRemoval = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    updateIncludeVintageProjects(null, false, true)
    setShowModal(false)
  }

  const onCancel = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setShowModal(false)
  }

  return (
    <>
      <Modal
        onHide={() => onCancel()}
        show={showModal}
        size="lg"
        className="remove-vintage-modal"
        centered
      >
        <Modal.Body>
          <CloseDialogButton onClick={e => onCancel(e)} />
          <h2>Confirm</h2>
          <p>
            Unchecking this box will remove the following projects from your
            Direct Checkout page:
          </p>
          <ul>
            {selectedVintageProjectNames().map((projectName, i) => (
              <li key={`project-name-${i}`}>{projectName}</li>
            ))}
          </ul>
        </Modal.Body>
        <footer>
          <Button cn="cancel" onClick={e => onCancel(e)}>
            Cancel
          </Button>
          <Button onClick={e => confirmRemoval(e)}>Confirm</Button>
        </footer>
      </Modal>
    </>
  )
}

RemoveVintageModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  updateIncludeVintageProjects: PropTypes.func,
  selectedVintageProjectNames: PropTypes.func,
}

export default RemoveVintageModal
