import React from 'react'

const CustomerPaysIcon = () => (
  <svg
    fill="none"
    height="58"
    viewBox="0 0 65 58"
    width="65"
    xmlns="http://www.w3.org/2000/svg">
    <g fill="#004453">
      <path d="m43.22 41.73c-.02 0-.05 0-.07 0-.24-.02-.45-.16-.58-.37-3.29-5.59-10.03-9.06-17.57-9.06s-14.28 3.47-17.57 9.06c-.12.21-.34.34-.58.37-.24.02-.48-.07-.63-.25-4.01-4.56-6.22-10.42-6.22-16.48 0-13.79 11.21-25 25-25 13.78 0 25 11.21 25 25 0 6.07-2.21 11.92-6.21 16.48-.15.16-.36.25-.57.25zm-18.22-40.23c-12.96 0-23.5 10.54-23.5 23.5 0 5.37 1.84 10.55 5.19 14.72 3.72-5.52 10.63-8.91 18.31-8.91s14.58 3.39 18.31 8.91c3.35-4.17 5.19-9.35 5.19-14.72-.01-12.96-10.55-23.5-23.5-23.5z" />
      <path d="m25 49.99c-7.19 0-14.03-3.1-18.78005-8.51-.22-.25-.26-.6-.09-.88 3.56-6.04 10.79005-9.79 18.87005-9.79s15.3 3.75 18.87 9.79c.16.28.13.63-.08.88-4.76 5.41-11.61 8.51-18.79 8.51zm-17.29005-9.09c4.45005 4.83 10.72005 7.59 17.29005 7.59s12.84-2.76 17.29-7.59c-3.39-5.31-9.95-8.59-17.29-8.59s-13.91 3.27-17.29005 8.59z" />
      <path d="m25.0001 29.18c-5.46 0-9.9-4.44-9.9-9.9s4.44-9.9 9.9-9.9 9.9 4.44 9.9 9.9-4.44 9.9-9.9 9.9zm0-18.3c-4.63 0-8.4 3.77-8.4 8.4s3.77 8.4 8.4 8.4 8.4-3.77 8.4-8.4-3.77-8.4-8.4-8.4z" />
    </g>
    <path
      d="m49.2401 56.5c8.113 0 14.6899-6.5769 14.6899-14.69s-6.5769-14.69-14.6899-14.69c-8.1131 0-14.6901 6.5769-14.6901 14.69s6.577 14.69 14.6901 14.69z"
      fill="#00cc8a"
    />
    <path
      d="m49.2401 57.24c-8.51 0-15.4401-6.92-15.4401-15.44s6.9201-15.44 15.4401-15.44 15.4399 6.92 15.4399 15.44-6.9299 15.44-15.4399 15.44zm0-29.37c-7.68 0-13.9401 6.25-13.9401 13.94s6.2501 13.94 13.9401 13.94 13.9399-6.25 13.9399-13.94-6.2499-13.94-13.9399-13.94z"
      fill="#004453"
    />
    <path
      d="m50.0599 48.26v1.94h-1.42v-1.92c-1.7-.1-3.35-.66-4.25-1.44l.78-1.75c.85.69 2.15 1.2 3.47 1.32v-3.47c-1.92-.47-3.99-1.09-3.99-3.5 0-1.77 1.28-3.31 3.99-3.57v-1.97h1.42v1.92c1.28.07 2.58.45 3.52 1.06l-.71 1.75c-.92-.54-1.89-.83-2.81-.94v3.5c1.92.47 4.02 1.07 4.02 3.48.01 1.78-1.29 3.31-4.02 3.59zm-1.42-7.42v-3.09c-1.23.19-1.75.81-1.75 1.58 0 .83.75 1.21 1.75 1.51zm3.23 4c0-.85-.76-1.25-1.8-1.54v3.07c1.26-.19 1.8-.78 1.8-1.53z"
      fill="#fff"
    />
  </svg>
)

export default CustomerPaysIcon
