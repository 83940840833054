import React from 'react'
import { Text } from '@react-pdf/renderer'

/**
 * @returns {React.JSX.Element}
 */
const Spacer = () => (
  <Text style={{ flexGrow: 2 }}>&nbsp;</Text>
)

export default Spacer