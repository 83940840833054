import React, { useEffect } from 'react'
import { setPageTitle } from 'model/utils/page'
import FormContainer from 'view/components/form-container/FormContainer'
import OptionalLink from 'view/components/form-container/optional-link/OptionalLink'
import PublicPageWrapper from 'view/components/public-page-wrapper/PublicPageWrapper'
import LoginForm from './components/login-form/LoginForm'
import './Login.scss'

const Login = () => {
  useEffect(() => {
    setPageTitle('Login')
  }, [])

  return (
    <PublicPageWrapper>
      <FormContainer cloverlyLogo={true} title="Welcome Back!" footer={true}>
        <LoginForm />
        <OptionalLink
          title="Don't have an account?"
          linkTitle="Request Access"
          isExternalLink={true}
          to={'https://www.cloverly.com/account-signup'}
        />
        <OptionalLink
          title="Forgot Password?"
          linkTitle="Reset Password"
          to="/forgot-password"
        />
        <div className={`optional-link sub-section`}>
          Are you a supplier? Click{' '}
          <a href="https://supply.cloverly.com" rel="noreferrer">
            here
          </a>{' '}
          to log into our Supplier Platform.
        </div>
      </FormContainer>
    </PublicPageWrapper>
  )
}

export default Login
