import React, { useEffect, useReducer, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { setPageTitle } from 'model/utils/page'
import { NavLink } from 'react-router-dom'
import '../../DeveloperTools.scss'
import DebuggerTable from '../tables/DebuggerTable'

const AllApiCallsPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const { serviceRequest } = useService()
  const changePage = ({ selected }) => setCurrentPage(selected)
  const { account } = useUser()

  const [filter, setFilter] = useState('any')

  const [responseFilter, setResponseFilter] = useState('any')

  const [shouldRefresh, setShouldRefresh] = useState(false)

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'setApiCalls':
          state.apiCalls = action.apiCalls
          break
      }
      return { ...state }
    },
    { pageSize: 10 }
  )

  useEffect(() => {
    setPageTitle('API Debugger')
  }, [])

  useEffect(() => {
    const filterQuery =
      filter == 'any' ? '' : `&environment=${filter.toLowerCase()}`
    const responseQuery =
      responseFilter == 'any' ? '' : `&response_status=${responseFilter}`

    serviceRequest({
      path: `/dashboard/accounts/${account.slug}/api_debuggers?page=${
        currentPage + 1 || 1
      }&limit=${state.pageSize}${filterQuery}${responseQuery}`,
    }).then(apiCalls =>
      dispatch({
        type: 'setApiCalls',
        apiCalls,
      })
    )
    // eslint-disable-next-line
  }, [currentPage, account, shouldRefresh])

  useEffect(() => {
    setCurrentPage(0)
    setShouldRefresh(!shouldRefresh)
    // eslint-disable-next-line
  }, [filter, responseFilter])

  return (
    <div className="developer-tools">
      <div className="sub-page-header">
        <div className="left-col">
          <NavLink to="/developer-tools">
            <ArrowBackIcon /> Back
          </NavLink>
          <h1>API Debugger</h1>
        </div>
      </div>
      <DebuggerTable
        pageCount={
          state.apiCalls?.next_page != null
            ? state.apiCalls.next_page
            : state.apiCalls?.page
        }
        currentPage={state.apiCalls?.page}
        apiCalls={state.apiCalls?.data || []}
        changePage={changePage}
        filter={filter}
        responseFilter={responseFilter}
        setResponseFilter={setResponseFilter}
        setFilter={setFilter}
      />
    </div>
  )
}

export default AllApiCallsPage
