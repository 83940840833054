import React, { useMemo } from 'react'
import { Link, Text, View } from '@react-pdf/renderer'
import { Project } from 'interfaces/Project.interface'
import { formattedUTCDate } from 'model/utils/date'
import PDFLogo from 'view/components/pdf-components/common-components/PDFLogo'
import InventoryPanel from 'view/components/pdf-components/project-components/panels/InventoryPanel'
import ProjectTagsPanel from 'view/components/pdf-components/project-components/panels/ProjectTagsPanel'
import QuotePanel from 'view/components/pdf-components/project-components/panels/QuotePanel'

type Props = {
  project: Project
  defaultCurrency: string
  currencyRates: object
  estimate: any
  styles: any
}

const SideColumn = ({
  project,
  styles,
  defaultCurrency,
  currencyRates,
  estimate,
}: Props) => {
  /**
   * @type {boolean}
   */
  const quoteMode = useMemo(
    () => estimate && estimate.total_weight > 0,
    [estimate]
  )

  /**
   * @returns {false|React.JSX.Element}
   */
  const ShortDescription = () =>
    !!project.short_description && (
      <Text style={styles.shortDescText}>{project.short_description}</Text>
    )

  /**
   * @returns {false|React.JSX.Element}
   */
  const InventorySection = () =>
    project.is_purchasable && (
      <>
        <InventoryPanel
          project={project}
          styles={styles}
          defaultCurrency={defaultCurrency}
          currencyRates={currencyRates}
        />
        <Text style={styles.sidebarDisclaimerText}>
          The vintage of a credit represents the year within which the credit
          was produced. When there are repeating vintages on a project, this is
          most likely due to multiple issuances within the same year. These are
          sometimes priced differently because of different delivery dates.
        </Text>
        <Text style={styles.sidebarDisclaimerText}>
          For more questions, please reach out to:
        </Text>
        <Link
          style={styles.sidebarDisclaimerLink}
          src="mailto:support@cloverly.com"
        >
          support@cloverly.com
        </Link>
      </>
    )

  return (
    <View style={styles.sideColumn} fixed>
      <View style={styles.titleAndLogo}>
        <PDFLogo />
        <Text style={styles.pageTitle}>PROJECT</Text>
      </View>
      <Text style={styles.titleText}>{project.short_name || project.name}</Text>
      <ProjectTagsPanel project={project} styles={styles} />
      {quoteMode ? (
        <>
          <QuotePanel
            defaultCurrency={defaultCurrency}
            currencyRates={currencyRates}
            estimate={estimate}
            styles={styles}
          />
          <View style={styles.horizontalPadded}>
            <ShortDescription />
          </View>
        </>
      ) : (
        <>
          <ShortDescription />
          <InventorySection />
        </>
      )}
      <View style={styles.sideColumnFooter}>
        <Text style={styles.sideColumnFooterText}>
          Generated {formattedUTCDate(new Date())}
        </Text>
        <Text style={styles.sideColumnFooterText}>
          All project and pricing information shown is subject to change.
        </Text>
      </View>
    </View>
  )
}

export default SideColumn
