import React, { useEffect, useState } from 'react'
import { handleCalculateCostPerTonne } from 'model/utils/cost'
import VintageYearButton from 'view/components/vintage-year/vintage-year-button/VintageYearButton'
import '../../../../project-details/ProjectDetails.scss'
import { useMarketplaceContext } from '../../../MarketplaceContext'

const VintageListItem = ({ vintage, handleClick, isSelected }) => {
  const {
    defaultCurrency,
    currencyRates,
    isEstimationLoading,
    hasInventoryError,
    amount,
    weightType,
  } = useMarketplaceContext()

  const [inventoryIssue, setInventoryIssue] = useState(false)

  useEffect(() => {
    setInventoryIssue(
      vintage?.remaining_micro_units / 1000000 <
        calculateAmountInTonnes({ amount, weightType })
    )
  }, [isEstimationLoading, amount, weightType])

  const formatAvailability = availability =>
    new Intl.NumberFormat().format(availability) + ' t available'

  const calculateAmountInTonnes = ({ amount, weightType }) => {
    switch (weightType) {
      case 'kg':
        return amount * 0.001
      case 'lbs':
        return amount * 0.00045359237
      default:
        return amount
    }
  }

  return (
    <div
      className={
        'vintage-list-item' +
        (isSelected ? ' selected' : '') +
        (inventoryIssue && !isSelected ? ' inventory-issue' : '')
      }
      onClick={() => handleClick(vintage)}
    >
      <div className="data-row">
        <label className="vintage-year-label">{vintage?.vintage_year}</label>
        {!vintage?.is_issued && (
          <VintageYearButton
            label="Forward"
            projectName={vintage?.name}
            deliveryDate={vintage?.delivery_date}
          />
        )}
        <div className="spreader"></div>
        <label className="vintage-price-per-tonne">
          {handleCalculateCostPerTonne(
            vintage?.cost_per_kg_carbon_in_usd_cents * 10,
            defaultCurrency,
            currencyRates
          )}{' '}
          / t
        </label>
      </div>
      <div className="data-row">
        <label
          className={
            `vintage-availability` +
            (isSelected && !isEstimationLoading && hasInventoryError
              ? ' warning'
              : '')
          }
        >
          {formatAvailability(
            Math.floor(vintage?.remaining_micro_units / 1000000)
          )}
        </label>
        <div className="spreader"></div>
      </div>
    </div>
  )
}

export default VintageListItem
