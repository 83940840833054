import React from 'react'

/**
 * @returns {React.JSX.Element}
 * @constructor
 */
const AvoidanceMechanismIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1L15.9282 5V13L9 17L2.0718 13V5L9 1Z" stroke="#2E6775" strokeWidth="1.60714" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5 6.5L6.5 11.5" stroke="#2E6775" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.5 6.5L11.5 11.5" stroke="#2E6775" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default AvoidanceMechanismIcon
