export const unsdgFilterList = [
  '1: No Poverty',
  '2: Zero Hunger',
  '3: Good Health and Well-Being',
  '4: Quality Education',
  '5: Gender Equality',
  '6: Clean Water and Sanitation',
  '7: Affordable and Clean Energy',
  '8: Decent Work and Economic Growth',
  '9: Industry, Innovation and Infrastructure',
  '10: Reduced Inequalities',
  '11: Sustainable Cities and Communities',
  '12: Responsible Consumption and Production',
  '13: Climate Action',
  '14: Life Below Water',
  '15: Life On Land',
  '16: Peace, Justice and Strong Institutions',
  '17: Partnerships For The Goals',
]

export const durabilityFilterList = [
  {
    label: '99 years or less',
    value: 'SHORT',
  },
  {
    label: '100 - 999 years',
    value: 'MEDIUM',
  },
  {
    label: '1,000 years or more',
    value: 'LONG',
  },
]

export const mechanismFilterList = ['Removal', 'Avoidance']

export const defaultFilterTypes = [
  { label: 'Project Type' },
  { label: 'Registry' },
  { label: 'UN SDG', children: unsdgFilterList },
  { label: 'Removal Time Period', children: durabilityFilterList },
  { label: 'Mechanism', children: mechanismFilterList },
]
