import React from 'react'
import { getWeightUnit } from 'model/utils/units'

const TopProjects = ({ projects, weightType }) => {
  return (
    <div className="top-projects">
      <h2>Top Projects</h2>
      <ol className="top-projects-list">
        {projects?.map(project => (
          <li key={project.id}>
            <span className="project-name">{project.name}</span>
            <span className="offset-amount">
              {' '}
              {getWeightUnit({
                weightType: weightType,
                value: project.carbon_offset_amount,
                roundUnits: 0,
                format: '%v %u',
              })}
            </span>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default TopProjects
