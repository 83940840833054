import React from 'react'
import Table from 'antd/lib/table'

const DashboardTable = ({
  dataSource,
  columns,
  size = 'small',
  rowClasses = 'cursor-default hover:background-white',
  rowKey = 'public_id',
  loading = false,
  scrollX = 600,
}) => {
  return (
    <Table
      className="dashboard-table"
      rowClassName={rowClasses}
      dataSource={dataSource}
      columns={columns}
      scroll={{ x: scrollX }}
      size={size}
      rowKey={rowKey}
      pagination={false}
      loading={loading}
    />
  )
}

export default DashboardTable
