import React, { ReactNode, useMemo } from 'react'
import { Text, View } from '@react-pdf/renderer'
import { formatCost, handleCalculateCostPerTonne } from 'model/utils/cost'
import { getWeightUnit } from 'model/utils/units'
import Spacer from '../../common-components/Spacer'

type Props = {
  defaultCurrency: string
  currencyRates: object
  estimate: any
  styles: any
}

const QuotePanel = ({
  styles,
  defaultCurrency,
  currencyRates,
  estimate,
}: Props) => {
  /**
   * @type {string}
   */
  const formattedWeight = useMemo(
    () =>
      getWeightUnit({
        weightType:
          estimate.weight_type === 'tonnes' ? 't' : estimate.weight_type,
        isMT:
          estimate?.weight_type === 'kg' || estimate?.weight_type === 'tonnes',
        value: estimate.total_weight,
        roundUnits: 0,
        format: '%v %u',
        reformatLarge: false,
      }),
    [estimate?.total_weight, estimate?.weight_type]
  )

  /**
   * @type {string}
   */
  const formattedCost = useMemo(
    () => formatCost(estimate?.total_cost, defaultCurrency),
    [estimate?.total_cost, defaultCurrency]
  )

  /**
   * @type {string}
   */
  const formattedUnitPrice = useMemo(
    () =>
      handleCalculateCostPerTonne(
        estimate?.cost_per_kg_carbon_in_usd_cents * 10,
        defaultCurrency,
        currencyRates
      ),
    [estimate?.cost_per_kg_carbon_in_usd_cents, defaultCurrency, currencyRates]
  )

  /**
   * @param {boolean} includeDivider
   * @param {React.JSX.Element} children
   * @returns {React.JSX.Element}
   */
  const QuoteRow = ({
    includeDivider = true,
    children,
  }: {
    includeDivider?: boolean
    children: ReactNode
  }) => {
    return (
      <View
        style={includeDivider ? styles.quoteRowWithDivider : styles.quoteRow}
      >
        {children}
      </View>
    )
  }

  return (
    <View style={styles.quotePanelContainer}>
      <Text style={styles.quotePanelHeading}>QUOTE</Text>
      {estimate?.vintage_year > 0 && (
        <QuoteRow includeDivider={false}>
          <Text style={styles.quoteLabel}>Vintage</Text>
          <Spacer />
          <Text style={styles.quoteData}>{estimate.vintage_year}</Text>
          {estimate?.is_issued === false && (
            <Text style={styles.quoteForward}>Forward</Text>
          )}
        </QuoteRow>
      )}
      <QuoteRow>
        <Text style={styles.quoteLabel}>Unit Price</Text>
        <Spacer />
        <Text style={styles.quoteData}>{formattedUnitPrice} / t</Text>
      </QuoteRow>
      <QuoteRow>
        <Text style={styles.quoteLabel}>Quantity</Text>
        <Spacer />
        <Text style={styles.quoteData}>{formattedWeight}</Text>
      </QuoteRow>
      <QuoteRow>
        <Text style={{ ...styles.quoteLabel, fontWeight: 700 }}>Total</Text>
        <Spacer />
        <Text style={styles.quoteData}>{formattedCost}</Text>
      </QuoteRow>
    </View>
  )
}

export default QuotePanel
