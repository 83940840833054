import React, { useEffect, useRef, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { FeatureFlag, useFeatureFlag } from 'model/hooks/useFeatureFlag'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { getDifferenceInHours } from 'model/utils/date'
import { vintage_credit_min_in_tonnes } from 'model/utils/marketplace/marketplace-constants'
import {
  MarketPlaceListingModes,
  ProductType,
} from 'model/utils/marketplace/MarketplaceEnums'
import { setPageTitle } from 'model/utils/page'
import { useLocation } from 'react-router-dom'
import CalculatorInputGroup from 'view/components/calculator-input-group/CalculatorInputGroup'
import EmptyProjects from './components/empty-projects/EmptyProjects'
import MarketplaceMap from './components/map/MarketplaceMap'
import MarketplaceListingSubnav from './components/marketplace-listing-subnav/MarketplaceListingSubnav'
import ListControlsPanel from './components/panels/ListControlsPanel'
import ProductItemPanel from './components/panels/ProductItemPanel'
import ProductItemSkeleton from './components/panels/ProductItemSkeleton'
import PurchaseModal from './components/purchase-modal/PurchaseModal'
import { useMarketplaceContext } from './MarketplaceContext'
import './Marketplace.scss'

const Marketplace = () => {
  const {
    resetCalculatorValues,
    showEmptyStateModal,
    showModalWithValues,
    sourceRef,
    setRefetchProjects,
    refetchProjects,
    listView,
    projects,
    setProjects,
    project,
    setProject,
    filterAndSort,
    marketplaceListingMode,
    listingModeUpdated,
    hasPortfolioListings,
    setHasPortfolioListings,
    projectEstimate,
    setHasInventoryError,
    setSelectedVintage,
    containsCache,
    setContainsCache,
  } = useMarketplaceContext()

  const location = useLocation()
  const { getFlag } = useFeatureFlag()
  const { serviceRequestMulti, spreadResponses } = useService()
  const { warningToast } = useToast()
  const { account } = useUser()
  const [showProductPanelOnMap, setShowProductPanelOnMap] = useState(false)
  const [isMarketplaceLoading, setIsMarketplaceLoading] = useState(true)
  const [windowIsActive, setWindowIsActive] = useState(true)
  const [lastActive, setLastActive] = useState(undefined)
  const [tabIsExpired, setTabIsExpired] = useState(false)
  const isMounted = useRef(false)

  const onFocus = () => {
    const now = new Date()
    setTabIsExpired(getDifferenceInHours(lastActive, now) >= 48)
    setLastActive(now)
  }

  const onBlur = () => {
    const now = new Date()
    setLastActive(now)
    setTabIsExpired(false)
  }

  useEffect(() => {
    if (!windowIsActive) {
      onBlur()
    } else {
      onFocus()
    }
  }, [windowIsActive])

  //detect browser tab focus and blur
  useEffect(() => {
    setPageTitle('Marketplace')
    window.addEventListener('blur', () => setWindowIsActive(false))
    window.addEventListener('focus', () => setWindowIsActive(true))
    setHasInventoryError(false)
    setSelectedVintage(undefined)
  }, [])

  useEffect(() => {
    setPageTitle('Marketplace')
    //disregard the below functions on the first render
    if (isMounted.current) {
      getProjects()
      resetCalculatorValues()
    } else {
      isMounted.current = true
    }
  }, [account])

  useEffect(() => {
    checkProjectCache()
  }, [location, tabIsExpired, refetchProjects])

  useEffect(() => {
    if (
      !hasPortfolioListings &&
      marketplaceListingMode === MarketPlaceListingModes.PORTFOLIOS
    )
      listingModeUpdated(MarketPlaceListingModes.PROJECTS)
  }, [hasPortfolioListings])

  const checkProjectCache = () => {
    //only make a request to "/marketplace" endpoint if the response isn't already cached
    const localProducts = localStorage.getItem('products')
    const cachedProducts = localProducts ? JSON.parse(localProducts) : null
    if (!containsCache || !cachedProducts || tabIsExpired || refetchProjects) {
      getProjects()
      setRefetchProjects(false)
    } else {
      // if there are cached products, then check to see if any of the products has a portfolio listing to enable the tab
      checkHasPortfolioListings(cachedProducts)
      setIsMarketplaceLoading(false)
    }
  }

  const getProjects = () => {
    setIsMarketplaceLoading(true)
    const requests = [
      {
        path: `/projects?vintage_credit_min_in_tonnes=${vintage_credit_min_in_tonnes}`,
        method: 'GET',
      },
      { path: '/portfolios', method: 'GET' },
    ]
    serviceRequestMulti({
      requests,
      sourceRef,
      showLoading: false,
    })
      .then(
        spreadResponses((projectResponse, portfolioResponse) => {
          if (projectResponse && portfolioResponse) {
            let availableProjectTypes = []
            let projectsResponse = {
              data: {
                projects: projectResponse?.data,
                portfolios: portfolioResponse?.data?.portfolios,
              },
            }
            if (projectsResponse?.data) {
              // for the existing functionality custom terms work, we will ensure that projects that are not purchasable show up in the marketplace.
              if (
                !getFlag(FeatureFlag.CUSTOM_TERMS) &&
                projectsResponse?.data?.projects
              ) {
                projectsResponse.data.projects =
                  projectsResponse.data.projects.filter(
                    project => project.is_purchasable
                  )
              }

              projectsResponse?.data?.projects.forEach(project => {
                project.productType = ProductType.PROJECT

                // set available_carbon_in_kg as the largest amount that any one single vintage has available
                let max = 0
                project.all_tranches.forEach(
                  tranche =>
                    (max =
                      tranche.remaining_micro_units > max
                        ? tranche.remaining_micro_units
                        : max)
                )
                project.available_carbon_in_kg = max / 1000
              })

              projectsResponse?.data?.portfolios.forEach(portfolio => {
                portfolio.productType = ProductType.PORTFOLIO
                portfolio.cost_per_kg_carbon_in_usd_cents =
                  portfolio.price_per_tonne_in_USD / 10
              })

              const products = projectsResponse?.data?.portfolios.concat(
                projectsResponse?.data?.projects
              )

              const filteredProjects = updateProjects(products)
              checkHasPortfolioListings(filteredProjects)
              filteredProjects.forEach(project => {
                if (!availableProjectTypes.includes(project.type))
                  availableProjectTypes.push(project.type)
              })
            }

            setContainsCache(true)
            filterAndSort()
            setIsMarketplaceLoading(false)
          }
        })
      )
      .catch(e => {
        warningToast(e.response?.data?.Message || `Couldn't load projects`)
        setIsMarketplaceLoading(false)
      })
  }

  const updateProjects = products => {
    if (products) {
      //filter out 'solar' and 'wind' type project products
      const filteredProducts = products.filter(product => {
        return (
          product.type?.toUpperCase() !== 'SOLAR' &&
          product.type?.toUpperCase() !== 'WIND'
        )
      })
      setProjects(filteredProducts)
      localStorage.setItem('products', JSON.stringify(filteredProducts))
      return filteredProducts
    } else {
      setProjects([])
      localStorage.setItem('products', [])
      return []
    }
  }

  /**
   * Checks to see if the given array of products has at least one portfolio product, and sets the results
   * to the state.
   *
   * @param products
   */
  const checkHasPortfolioListings = products => {
    setHasPortfolioListings(
      !!products?.length &&
        !!products?.filter(
          product => product.productType === ProductType.PORTFOLIO
        )?.length
    )
  }

  const productTypeFilter = productType => {
    switch (marketplaceListingMode) {
      case MarketPlaceListingModes.PROJECTS:
        return productType === ProductType.PROJECT
      case MarketPlaceListingModes.PORTFOLIOS:
        return productType === ProductType.PORTFOLIO
      default:
        return false
    }
  }

  return (
    <div className="marketplace-page">
      {(showEmptyStateModal || showModalWithValues) && (
        <PurchaseModal projectEstimate={projectEstimate} />
      )}
      <div className="main-section">
        <div className="header-and-calculator">
          <section className="page-header">
            <h1>Carbon Credit Platform</h1>
            <p className="page-instructions">
              Choose from our vetted selection of carbon removal and reduction
              solutions to compensate for your emissions.
            </p>
          </section>
          <CalculatorInputGroup
            shortTonneLabel={true}
            placeholder="Enter amount"
            enableEstimation={false}
            theme="light"
            mode={
              marketplaceListingMode === MarketPlaceListingModes.PROJECTS
                ? ProductType.TRANCHE
                : ProductType.PORTFOLIO
            }
          />
        </div>
        <MarketplaceListingSubnav
          products={projects}
          marketplaceListingMode={marketplaceListingMode}
          listingModeUpdated={listingModeUpdated}
          hasPortfolioListings={hasPortfolioListings}
        />
        <div className={`list-container ${listView}`}>
          <ListControlsPanel marketplaceListingMode={marketplaceListingMode} />
          {!isMarketplaceLoading ? (
            <>
              {listView === 'grid' &&
                (projects?.length ? (
                  <div className="grid-container">
                    {projects
                      ?.filter(product =>
                        productTypeFilter(product.productType)
                      )
                      .map((product, i) => (
                        <ProductItemPanel
                          key={`panel-${product.productType}-${i}`}
                          partOfModal={false}
                          project={product}
                          setProject={setProject}
                        />
                      ))}
                  </div>
                ) : (
                  <EmptyProjects hasResetFilterMessage />
                ))}
              {listView === 'map' && (
                <div className="map-container">
                  <MarketplaceMap
                    account={account}
                    projects={projects}
                    setShowProductPanelOnMap={setShowProductPanelOnMap}
                    showProductPanelOnMap={showProductPanelOnMap}
                    setProject={setProject}
                    project={project}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="grid-container single-row">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(skeleton => (
                <ProductItemSkeleton key={`skeleton-${skeleton}`} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Marketplace
