import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { useUser } from 'model/hooks/account/useUser'
import { Dropdown, Table } from 'react-bootstrap'
import './AccountSettingsTables.scss'

const UserTable = React.memo(
  ({ users, setUserToDelete, changeUserRoleConfirmed }) => {
    const { account } = useUser()

    return (
      <div className="table-container">
        <Table>
          <thead>
            <tr>
              <td>Name</td>
              <td>Email</td>
              <td>Role</td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(users) && !!users?.length ? (
              users?.map((user, index) => (
                <tr key={'at_row_' + index}>
                  <td className="name-cell">
                    {user.first_name} {user.last_name}{' '}
                    {user.user_id === account.owner_id ? '(Owner)' : ''}
                  </td>
                  <td>{user.email}</td>
                  <td>
                    {account.role === 'admin' &&
                    user.user_id !== account.owner_id ? (
                      <Dropdown
                        onSelect={eventKey =>
                          changeUserRoleConfirmed(user, eventKey)
                        }
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <span>{user.role}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey={'admin'}>
                            admin
                          </Dropdown.Item>
                          <Dropdown.Item eventKey={'user'}>user</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <span>{user.role}</span>
                    )}
                  </td>
                  <td
                    className="icon-cell"
                    onClick={() => setUserToDelete(user)}
                  >
                    {account.role === 'admin' &&
                      user.user_id !== account.owner_id && <DeleteIcon />}
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
)

export default UserTable
