import React from 'react'
import { Project } from 'interfaces/Project.interface'
import { capFirstLetter } from 'model/utils/capitalize-first-letter'
import { defaultMethodologyIconUrl } from 'model/utils/methodology'
import LocationIcon from 'view/components/icons/project/location-icon/LocationIcon'
import AvoidanceIcon from 'view/components/icons/project/mechanism/avoidance-icon/AvoidanceIcon'
import RemovalIcon from 'view/components/icons/project/mechanism/removal-icon/RemovalIcon'
import RegistryIcon from 'view/components/icons/project/registry-icon/RegistryIcon'

type Props = {
  project: Project
}

const ProjectMetadata = ({ project }: Props) => {
  const commonStyles =
    'py-0.5 pl-1 pr-2 flex items-center justify-center gap-1 rounded border w-fit'

  const offsetClassificationIcon =
    project?.offset_classification?.toUpperCase() === 'REMOVAL' ? (
      <AvoidanceIcon />
    ) : (
      <RemovalIcon />
    )

  const offsetClassName = capFirstLetter(
    project.offset_classification?.toLowerCase(),
    true
  )

  const registryName = project.registry_name
  const projectName = project.short_name || project.name
  return (
    <section className="flex flex-wrap gap-1 pt-1 ">
      {project.country ? (
        <div className={`border-yellow-70 ${commonStyles}`}>
          <LocationIcon />
          <p className="text-sm leading-2 font-medium">{project.country}</p>
        </div>
      ) : null}
      {project.type ? (
        <div className={`border-green-80 ${commonStyles}`}>
          <img
            className="w-4 h-4"
            src={project.methodology_icon_url || defaultMethodologyIconUrl}
            alt={projectName}
          />
          <p className="text-sm leading-4 font-medium">{project.type}</p>
        </div>
      ) : null}
      {offsetClassName ? (
        <div className={`border-secondary-80 ${commonStyles}`}>
          {offsetClassificationIcon}
          <p className="text-sm font-medium leading-4">{offsetClassName}</p>
        </div>
      ) : null}
      {registryName ? (
        <div className={`border-purple-80 ${commonStyles}`}>
          <RegistryIcon />
          <p className="text-sm font-medium leading-4">{registryName}</p>
        </div>
      ) : null}
    </section>
  )
}

export default ProjectMetadata
