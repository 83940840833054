import React, { useRef } from 'react'
import HelpIcon from '@mui/icons-material/Help'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { useNavbarContext } from 'model/contexts/NavbarContext'
import { useAuth } from 'model/hooks/account/useAuth'
import { useUser } from 'model/hooks/account/useUser'
import { useDetectClickOutside } from 'model/hooks/useDetectClickOutside'
import SettingsIcon from 'view/components/icons/navbar-icons/SettingsIcon'
import OptionItem from './OptionItem'

type Props = {
  toggleShow: () => void
}

const ExpandedOptions = ({ toggleShow }: Props) => {
  const { user, account } = useUser()
  const { logout } = useAuth()
  const {
    handleSelectRoute,
    menuOpen,
    toggleExpandedOptionsWhenNavCollapsed,
    setShowAccountsModal,
  } = useNavbarContext()

  const username = user ? `${user.first_name} ${user.last_name}` : '-'
  const companyName = account?.name ?? '-'

  //close settings dropdown when clicked outside of it
  const dropdownRef = useRef(null)
  useDetectClickOutside(dropdownRef, toggleShow)

  const handleLogout = async (e: React.MouseEvent) => {
    e.preventDefault()
    //reset the sidebar width to 'opened' since default is 'open' on login
    document.documentElement.style.setProperty('--sidebar-width', '200px')
    logout()
  }

  const onClickHelp = () => {
    window.open('https://help.cloverly.com/knowledge', '_blank')
  }

  const onClickSettings = () => {
    // navigate('/profile')
    handleSelectRoute('profile')
    if (!menuOpen) toggleExpandedOptionsWhenNavCollapsed()
    else toggleShow()
  }

  const handleOpenAccountsModal = () => setShowAccountsModal(true)

  const navbarCollapsedStyles = !menuOpen
    ? 'fixed z-10 bottom-[106px] left-2 w-[184px]'
    : ''

  return (
    <section
      ref={dropdownRef}
      className={`mb-1 text-sm flex flex-col gap-[2px] rounded-lg border border-neutral-80 bg-white p-2 ${navbarCollapsedStyles}`}
    >
      {!menuOpen && (
        <>
          <div className="flex flex-col p-2">
            <p className="text-sm font-semibold">{username}</p>
            <p className="text-xs text-muted">{companyName}</p>
          </div>
          <hr className="bg-neutral-80" />
        </>
      )}
      <OptionItem
        icon={<HelpIcon className="w-5" />}
        title="Support"
        onClick={onClickHelp}
      />
      <hr className="bg-neutral-80" />
      <OptionItem
        icon={<SettingsIcon />}
        title="User settings"
        onClick={onClickSettings}
      />
      <OptionItem
        icon={<SettingsBackupRestoreIcon className="w-5" />}
        title="Switch accounts"
        onClick={handleOpenAccountsModal}
      />
      <OptionItem
        icon={<LogoutIcon className="w-5" />}
        title="Log out"
        onClick={handleLogout}
      />
    </section>
  )
}

export default ExpandedOptions
