import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import GroupIcon from '@mui/icons-material/Group'
import HelpIcon from '@mui/icons-material/Help'
import { useAuth } from 'model/hooks/account/useAuth'
import { useUser } from 'model/hooks/account/useUser'
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ReceiptLogo from './ReceiptLogo'
import Button from '../button/Button'
import Logo from '../cloverly-logo/Logo'
import PlusIcon from '../icons/plus-icon/PlusIcon'
import './Navigationbar.scss'

//isReceiptPage is to check if it's the external receipt page
const Navigationbar = ({ transaction, isReceiptPage }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { user, account, updateAccount } = useUser()
  const accounts = user?.accounts
  const { logout } = useAuth()

  const supportUrl = 'https://help.cloverly.com/knowledge'

  return (
    <div className="navbar-container">
      <Navbar bg="light" expand="lg">
        {!isReceiptPage && (
          <Container className="container">
            <Navbar.Brand className="navbar_brand">
              <Link to="/">
                {account?.is_cobranding_enabled &&
                account?.cobranding_image != '' &&
                account?.cobranding_image != null ? (
                  <img
                    className="cobranding-logo"
                    src={`data:image/jpeg;base64,${account?.cobranding_image}`}
                  />
                ) : (
                  <Logo />
                )}
              </Link>
            </Navbar.Brand>
            {account && user ? (
              <div className="nav-controls">
                {accounts?.length > 1 ? (
                  <NavDropdown
                    title={
                      <span>
                        <GroupIcon /> {account.name}{' '}
                      </span>
                    }
                    id="basic-nav-dropdown"
                    className="dropdown"
                  >
                    <div className="menu-header">Accounts</div>
                    <NavDropdown.Divider />
                    {accounts.map(acc => (
                      <NavDropdown.Item
                        key={acc.id}
                        className={
                          acc.slug === account.slug ? 'list-item-active' : ''
                        }
                        onClick={() => updateAccount(acc, true)}
                      >
                        {acc.name} {acc.slug == account.slug ? '(Active)' : ''}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ) : (
                  <NavDropdown
                    title={
                      <span>
                        <GroupIcon /> {account.name}{' '}
                      </span>
                    }
                    id="basic-nav-dropdown"
                    className="dropdown disabled"
                    disabled
                  />
                )}
                <Nav.Link href={supportUrl} target="_blank">
                  <HelpIcon />
                </Nav.Link>
                <NavDropdown
                  title={
                    <AccountCircleIcon
                      className={
                        location.pathname === '/profile' ? 'icon-active' : ''
                      }
                    />
                  }
                  id="user-nav-dropdown"
                  className="dropdown"
                >
                  <div className="menu-header">
                    {user.first_name} {user.last_name}
                  </div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={() => {
                      if (location.pathname !== '/profile') navigate('/profile')
                    }}
                    className="dropdown-item"
                  >
                    {' '}
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => logout()}>
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            ) : (
              <div className="logged-out">
                <Link to="/login">Sign In</Link>
                <Button>Create Account</Button>
              </div>
            )}
          </Container>
        )}

        {isReceiptPage && (
          <>
            {transaction?.cobranding_image &&
            transaction?.is_cobranding_enabled ? (
              <Container className="container cobranded">
                <Navbar.Brand className="navbar_brand public-receipt">
                  <div className="powered-by">
                    <h6>Transaction Receipt</h6>
                    <span>
                      Powered by {transaction?.account_name} and Cloverly
                    </span>
                  </div>
                </Navbar.Brand>
                <div className="logos">
                  <Link to="/">
                    <img
                      className="cobranding-logo"
                      src={`data:image/jpeg;base64,${transaction?.cobranding_image}`}
                    />
                  </Link>
                  <PlusIcon />
                  <Link to="/">
                    <ReceiptLogo />
                  </Link>
                </div>
              </Container>
            ) : (
              <Container className="container">
                <Link className="single-logo" to="/">
                  <Logo />
                </Link>
                {!(account && user) && (
                  <div className="logged-out">
                    <Link to="/login">Sign In</Link>
                    <Button>Create Account</Button>
                  </div>
                )}
              </Container>
            )}
          </>
        )}
      </Navbar>
    </div>
  )
}

export default Navigationbar
