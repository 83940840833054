import React from 'react'
import { monthAndYear } from 'model/utils/date'
import { Modal } from 'react-bootstrap'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import './VintageYearModal.scss'

const LeadGenNotifySuccessModal = ({
  showModal = false,
  setShowModal,
  verbiageSelection = 'default',
  deliveryDate,
  projectName,
  vintageYear,
}) => {
  const onCancel = e => {
    if (e) e.preventDefault()
    setShowModal(false)
  }

  const DefaultVerbiage = () => (
    <>
      <p>
        Forward credits are a way for buyers to purchase carbon that will be
        removed at a future date.
      </p>
      <p>
        Purchasing forward credits allows you to support the scaling of climate
        action. For many high-quality projects, there has been outsized demand
        for their credits, and the only credits available are pre-issued for
        carbon production in the future. Forward credits allow buyers to reserve
        these high-quality, high-demand projects, at a guaranteed price which
        protects against future price increases. Certain types of projects,
        especially ones with high permanence, are early in their development
        (e.g. Direct Air Capture projects), and can only be supported today
        through forward credits. The upfront support of these future carbon
        removals allows carbon project developers to grow their operations and
        drastically scale up their carbon impact to the levels that we need.
      </p>
      <p>
        What happens if a credit does not get generated by the project at the
        future delivery date? Cloverly guarantees every forward credit we sell,
        so that if the project developer does not deliver, Cloverly offers a
        replacement. You can be assured of the carbon you have purchased.
      </p>
    </>
  )

  const CheckoutAdminVerbiage = () => (
    <>
      <p>
        Forward credits are a way for buyers to purchase carbon that will be
        removed at a future date.
      </p>
      <p>
        Enabling your customers to purchase forward credits allows them to
        support the scaling of climate action. For many high-quality projects,
        there has been outsized demand for their credits, and the only credits
        available are pre-issued for carbon production in the future. Forward
        credits allow buyers to reserve these high-quality, high-demand
        projects, at a guaranteed price which protects against future price
        increases. Certain types of projects, especially ones with high
        permanence, are early in their development (e.g. Direct Air Capture
        projects), and can only be supported today through forward credits. The
        upfront support of these future carbon removals allows carbon project
        developers to grow their operations and drastically scale up their
        carbon impact to the levels that we need.
      </p>
      <p>
        What happens if a credit does not get generated by the project at the
        future delivery date? Cloverly guarantees every forward credit we sell,
        so that if the project developer does not deliver, Cloverly offers a
        replacement. You can be assured that your buyers are getting the carbon
        they have purchased.
      </p>
    </>
  )

  const DeliveryDate = ({ date, name, vintageYear }) => {
    return (
      <p className="forward-credits-modal-delivery-date">
        These credits are from {name}&apos;s {vintageYear} vintage, and are
        expected to be delivered by {monthAndYear(new Date(date))}.
      </p>
    )
  }

  return (
    <>
      <Modal
        onHide={() => onCancel()}
        show={showModal}
        size="lg"
        className="vintage-year-modal"
        centered
      >
        <Modal.Body>
          <CloseDialogButton onClick={e => onCancel(e)} />
          {deliveryDate && projectName && (
            <DeliveryDate
              date={deliveryDate}
              name={projectName}
              vintageYear={vintageYear}
            />
          )}
          <h2>About forward credits</h2>
          {verbiageSelection === 'default' && <DefaultVerbiage />}
          {verbiageSelection === 'checkout-admin' && <CheckoutAdminVerbiage />}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LeadGenNotifySuccessModal
