import React from 'react'
import Logo from '../cloverly-logo/Logo'
import './FormContainer.scss'

const FormContainer = ({ cloverlyLogo, title, children, footer }) => {
  return (
    <div className="form-wrapper">
      {cloverlyLogo && <Logo />}
      <h2>{title}</h2>
      {children}
      {footer && (
        <footer className="login-footer">
          <a
            target="_blank"
            href="https://www.cloverly.com/term-of-service"
            rel="noreferrer">
            Terms of Service
          </a>
          <span className="padded">|</span>
          <a
            target="_blank"
            href="https://www.cloverly.com/privacy-policy"
            rel="noreferrer">
            Privacy Policy
          </a>
        </footer>
      )}
    </div>
  )
}

export default FormContainer
