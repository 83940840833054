import React from 'react'
import { Project } from 'interfaces/Project.interface'
import { handleGenerateURL } from 'model/utils/image'
import { defaultMethodologyIconUrl } from 'model/utils/methodology'
import BookmarkIcon from 'view/components/icons/project/bookmark-icon/BookmarkIcon'
import LocationIcon from 'view/components/icons/project/location-icon/LocationIcon'
import AvoidanceIcon from 'view/components/icons/project/mechanism/avoidance-icon/AvoidanceIcon'
import RemovalIcon from 'view/components/icons/project/mechanism/removal-icon/RemovalIcon'
import RegistryIcon from 'view/components/icons/project/registry-icon/RegistryIcon'
import ProjectTag from './ProjectTag'
import { getMechanismLabel } from '../ProjectDetailsUtility'

type Props = {
  project: Project
}

const ProjectTags = ({ project }: Props) => {
  const MechanismIcon = (mechanism: string) => {
    if (mechanism === 'Avoidance') return <AvoidanceIcon />
    return <RemovalIcon />
  }

  return (
    <div className="flex gap-2 items-center flex-wrap">
      {project.country && (
        <ProjectTag
          icon={<LocationIcon />}
          className="bg-yellow-90"
          label={project.country}
        />
      )}
      {project.offset_classification && (
        <ProjectTag
          icon={MechanismIcon(getMechanismLabel(project.offset_classification))}
          className="bg-secondary-90"
          label={getMechanismLabel(project.offset_classification)}
        />
      )}
      {project.type && (
        <ProjectTag
          icon={
            <img
              src={handleGenerateURL(
                project.methodology_icon_url || defaultMethodologyIconUrl
              )}
              alt=""
              className="size-[18px] mr-[3px] -ml-[5px]"
            />
          }
          className="bg-green-90"
          label={project.type}
        />
      )}
      {project.registry_name && (
        <ProjectTag
          icon={<RegistryIcon />}
          className="bg-purple-90"
          label={project.registry_name}
        />
      )}
      {project.quality_markers?.corsia?.status && (
        <ProjectTag
          icon={<BookmarkIcon />}
          className="bg-blue-90"
          label="CORSIA: Eligible"
        />
      )}
      {project.quality_markers?.ccp_eligible && (
        <ProjectTag
          icon={<BookmarkIcon />}
          className="bg-blue-90"
          label="CCP-Eligible"
        />
      )}
    </div>
  )
}

export default ProjectTags
