import React, { useEffect, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useLoading } from 'model/hooks/useLoading'
import { useService } from 'model/hooks/useService'
import { formatNumber } from 'model/utils/number'
import FlexTable from 'view/components/flex-table/FlexTable'
import './ProjectInventory.scss'

const ProjectInventory = () => {
  const { account } = useUser()
  const { serviceRequest } = useService()
  const { updateIsLoading } = useLoading()
  const [tableData, setTableData] = useState({
    show: false,
    projects: [],
    totalPages: 0,
    limit: 100,
  })

  const updatePageData = data => {
    updateIsLoading(false)
    setTableData({
      ...tableData,
      show: true,
      projects: data?.projects,
      totalPages: data?.projects?.length > 0 ? 1 : 0,
    })
  }

  const renderTableData = () => {
    const slicedData = tableData.projects.slice(0, tableData?.limit)
    return slicedData.map((project, idx) => {
      const originalAmount = formatNumber.format(project.total / 1000)
      const remaining = formatNumber.format(project.remaining / 1000)
      const allocated = formatNumber.format(
        project.total / 1000 - project.remaining / 1000
      )
      return (
        <tr key={idx}>
          <td className="strong-cell">{project.name}</td>
          <td className="data-cell">{originalAmount}</td>
          <td className="data-cell">{allocated}</td>
          <td className="data-cell">{remaining}</td>
        </tr>
      )
    })
  }

  useEffect(() => {
    updateIsLoading(true)
    serviceRequest({
      path: '/dashboard/inventory',
    })
      .then(data => updatePageData(data))
      .catch(() => updateIsLoading(false))
    // eslint-disable-next-line
  }, [account])

  return (
    tableData.show && (
      <div className="project-inventory">
        <div className="page-header">
          <h1>Project Inventory</h1>
        </div>
        <p className="info-text">
          Keep track of how much carbon has been allocated from the projects in
          your portfolio. If you see a project running low on inventory, contact
          us at <a href="mailto:support@cloverly.com">support@cloverly.com</a>.
        </p>
        <div className="page-body">
          <FlexTable
            headerRows={
              <tr>
                <td>Project</td>
                <td>Original Amount (tonne)</td>
                <td>Allocated (tonne)</td>
                <td>Remaining (tonne)</td>
              </tr>
            }
            bodyRows={tableData?.projects && renderTableData()}
            totalRows={tableData?.projects?.length}
            emptyTableMessage="There are no projects."
          />
        </div>
      </div>
    )
  )
}

export default ProjectInventory
