import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { Navigate, Outlet } from 'react-router-dom'

const PublicOnlyRoutes = () => {
  const { user, initialPath } = useUser()

  return user ? <Navigate to={initialPath || '/'} /> : <Outlet />
}

export default PublicOnlyRoutes
