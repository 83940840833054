import React from 'react'

type Props = {
  icon: React.ReactNode
  title: string
  className?: string
  onClick?: (value: any) => void
}

const OptionItem = ({ icon, title, className = '', onClick }: Props) => {
  const commonClasses = `flex text-muted font-medium cursor-pointer items-center gap-2 rounded-md p-2 decoration-0 hover:bg-neutral-95 ${className}`

  return (
    <div className={commonClasses} onClick={onClick}>
      {icon}
      <p>{title}</p>
    </div>
  )
}

export default OptionItem
