import React from 'react'
import { Project, ProjectRating } from 'interfaces/Project.interface'
import { ratingLogoBaseUrl, ratingsMap } from 'model/utils/ratings'
import CCPPanel from './ccp/CCPPanel'
import CorsiaPanel from './corsia/CorsiaPanel'

type Props = {
  project: Project
}

const ProjectRatingsPanel = ({ project }: Props) => {
  const panelTitle = 'Ratings & Standards'
  // filtered list of project ratings for the project
  const projectRatings = project?.project_ratings
    ? project?.project_ratings.filter(rating =>
        ratingsMap.has(rating.rating_title)
      )
    : []

  const RatingsList = ({ ratingsList }: { ratingsList: ProjectRating[] }) => {
    return (
      <section className="p-4 overflow-hidden flex flex-col border border-neutral-80 rounded-xl gap-2">
        {ratingsList.map((ratingObj, index) => (
          <RatingsListItem
            key={`ratings-item-${index}`}
            ratingObj={ratingObj}
          />
        ))}
      </section>
    )
  }

  const RatingsListItem = ({ ratingObj }: { ratingObj: ProjectRating }) => {
    const rating = ratingsMap.get(ratingObj.rating_title)
    return (
      <section className="flex items-center justify-between">
        <div className="flex gap-2 w-1/2 min-w-fit justify-between">
          <div className="flex gap-2 w-full">
            <img src={ratingLogoBaseUrl + rating?.logo} alt={''} />
            <h6 className="font-bold min-w-fit">
              {rating && rating.title && <span>{rating.title}</span>}
            </h6>
          </div>
          <span className="-mr-8">{ratingObj.rating}</span>
        </div>
        <span className="w-1/2" />
      </section>
    )
  }

  const corsia = project.quality_markers?.corsia

  const hasBeZero = project.project_ratings?.find(
    rating => rating.rating_title === 'BeZero'
  )

  const shouldRenderPanel =
    (!!corsia && !!corsia.status) ||
    !!corsia?.vintage_years?.length ||
    !!project.quality_markers?.ccp_eligible ||
    !!hasBeZero

  return (
    <>
      {!!shouldRenderPanel && (
        <section className="flex p-4 flex-col rounded-xl border border-neutral-80 gap-1 h-fit">
          <h2 className="text-lg font-bold mb-3">{panelTitle}</h2>
          {hasBeZero && <RatingsList ratingsList={projectRatings} />}
          <CorsiaPanel project={project} />
          <CCPPanel project={project} />
        </section>
      )}
    </>
  )
}

export default ProjectRatingsPanel
