import React from 'react'
import { Dropdown } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import ReactPaginate from 'react-paginate'
import TableOverlay from 'view/components/table-overlay/TableOverlay'
import TableSearch from 'view/components/table-search/TableSearch'
import './TransactionsTable.scss'

const TransactionsTable = ({
  isSearchLoading,
  onSearchChange,
  dropdownExists,
  tableDataHeaders,
  displayData,
  rowCount,
  changePage,
  emptyTableMessage,
  nextPage,
  currentPage,
  tags = [],
  tag,
  setTag,
  testId = '',
}) => {
  return (
    <>
      <div className="table-container" data-testid={testId}>
        {/*seperate the dropdown into its own component */}
        {dropdownExists && (
          <div className="filter-container">
            {tags.length > 0 && (
              <Dropdown onSelect={e => setTag(e)}>
                <Dropdown.Toggle id="dropdown-basic">
                  <span>{tag ? tag : 'All Tags'}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item key={-1} eventKey={null}>
                    All Tags
                  </Dropdown.Item>
                  {tags.map((item, idx) => {
                    return (
                      <Dropdown.Item key={idx} eventKey={item.key}>
                        {item.key}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
            <TableSearch
              inputName={'TransactionTable'}
              onSearchChange={onSearchChange}
            />
          </div>
        )}

        <Table>
          <thead>
            <tr>
              {tableDataHeaders.map((item, idx) => (
                <th key={idx}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>{displayData}</tbody>
        </Table>
        {!rowCount && (
          <div className="empty-table-message">{emptyTableMessage}</div>
        )}
        {isSearchLoading && <TableOverlay />}
      </div>

      {(nextPage != null || currentPage != 0) && (
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          pageCount={nextPage == null ? currentPage + 1 : currentPage + 2}
          onPageChange={changePage}
          containerClassName={'pagination-btns'}
          previousLinkClassName={'previous-btn'}
          nextLinkClassName={'next-btn'}
          disabledClassName={'disabled-btns'}
          activeClassName={'pagination-active'}
        />
      )}
    </>
  )
}

export default TransactionsTable
