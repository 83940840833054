/*
 * Define all feature flags here.  This enum allows the app to reference each feature flag via a strong reference,
 * which allows the compiler to detect missing Feature Flags at compile time when a flag is removed.
 */
export const FeatureFlag = {
  CONSOLE_LOGS: 1,
  CUSTOM_TERMS: 2,
  QUALITY_MARKERS: 3,
}

/**
 * Configure each feature flag in the enum above here.  Each flag can have a default value,
 * as well as have an override per env. If override is not set for an env, the default
 * value will be used.
 */
export const featureFlagsConfig = {
  flags: [
    {
      name: FeatureFlag.CONSOLE_LOGS,
      default: true,
      env: {
        local: true,
        dev: true,
        stage: true,
        prod: false,
      },
    },
    {
      name: FeatureFlag.CUSTOM_TERMS,
      default: false,
      env: {
        local: true,
        dev: true,
        stage: true,
        prod: true,
      },
    },
  ],
}

/**
 * Hook that accesses the feature flag values.
 *
 * @returns {{currentEnv: (function(): *), consoleLog: consoleLog, getFlag: ((function(*): (*|undefined))|*)}}
 */
export const useFeatureFlag = () => {
  const featureFlagManager = {
    featureFlags: [],
    currentEnv: process.env.REACT_APP_ENV?.toLowerCase(),
  }

  if (featureFlagsConfig.flags) {
    featureFlagsConfig.flags.forEach(flag => {
      featureFlagManager.featureFlags[flag.name] = flag
    })
  }

  /**
   * Returns the value for a given feature flag.
   *
   * @param featureFlag
   * @returns {undefined|*}
   */
  const getFlag = featureFlag => {
    const selectedFlag = featureFlagManager.featureFlags?.[featureFlag]
    if (!selectedFlag) return undefined
    return (
      selectedFlag.env?.[(process.env.REACT_APP_ENV || '')?.toLowerCase()] ??
      selectedFlag.default
    )
  }

  /**
   * returns the current env.  this should always be used instead of process.env.REACT_APP_ENV because
   * currentEnv will always be lowercase regardless of the casing of process.env.REACT_APP_ENV.
   */
  const getCurrentEnv = () => featureFlagManager.currentEnv

  /**
   * Console logs input only if the feature flags allow it.
   * This should always be used instead of a bare console.log.
   *
   * @param logs
   */
  const consoleLog = (...logs) => {
    if (getFlag(FeatureFlag.CONSOLE_LOGS)) {
      console.log(...logs)
    }
  }

  return { getFlag, getCurrentEnv, consoleLog }
}
