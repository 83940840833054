import React from 'react'

const CCPLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
    >
      <g clipPath="url(#clip0_4022_70)">
        <path
          d="M19.0608 39.8957C19.0608 35.5104 22.3757 32.5159 26.5481 32.5159C29.8 32.5159 31.6177 34.227 32.6233 36.0021L30.013 37.2855C29.4139 36.1299 28.1305 35.2104 26.5481 35.2104C24.0665 35.2104 22.1849 37.1994 22.1849 39.8949C22.1849 42.5903 24.0665 44.5793 26.5481 44.5793C28.1305 44.5793 29.4139 43.6811 30.013 42.5042L32.6233 43.7663C31.5964 45.5423 29.8 47.2747 26.5481 47.2747C22.3766 47.2747 19.0608 44.2802 19.0608 39.8949V39.8957Z"
          fill="#343892"
        />
        <path
          d="M33.7549 39.8957C33.7549 35.5104 37.0698 32.5159 41.2422 32.5159C44.4941 32.5159 46.3118 34.227 47.3173 36.0021L44.7071 37.2855C44.108 36.1299 42.8246 35.2104 41.2422 35.2104C38.7606 35.2104 36.879 37.1994 36.879 39.8949C36.879 42.5903 38.7606 44.5793 41.2422 44.5793C42.8246 44.5793 44.108 43.6811 44.7071 42.5042L47.3173 43.7663C46.2905 45.5423 44.4941 47.2747 41.2422 47.2747C37.0707 47.2747 33.7549 44.2802 33.7549 39.8949V39.8957Z"
          fill="#343892"
        />
        <path
          d="M49.1332 32.7502H55.8075C58.9094 32.7502 60.5993 34.8465 60.5993 37.3494C60.5993 39.8523 58.8872 41.906 55.8075 41.906H52.1703V47.0182H49.1332V32.7502ZM55.401 35.3604H52.1703V39.2958H55.401C56.5983 39.2958 57.4973 38.5254 57.4973 37.3281C57.4973 36.1308 56.5983 35.3595 55.401 35.3595V35.3604Z"
          fill="#343892"
        />
        <path
          d="M10.1011 34.4729C11.4129 34.6309 12.1912 35.7172 12.0448 36.9438C11.9374 37.8384 11.4271 38.315 10.8768 38.5724L10.6265 37.8943C11.0028 37.7319 11.3206 37.3556 11.3791 36.863C11.4768 36.0509 10.9052 35.3471 10.0097 35.2388C9.11415 35.1305 8.39258 35.679 8.29584 36.4911C8.23637 36.9837 8.45027 37.4239 8.78398 37.6716L8.38104 38.2715C7.90798 37.8837 7.52279 37.3059 7.63018 36.4113C7.77663 35.1847 8.78931 34.3149 10.1011 34.4729Z"
          fill="#343892"
        />
        <path
          d="M11.9525 42.0223C12.0448 43.3066 11.1714 44.2793 9.89162 44.3716C8.61268 44.463 7.60887 43.6252 7.51657 42.3409C7.42426 41.0567 8.2976 40.0839 9.57743 39.9916C10.8564 39.9002 11.8602 40.738 11.9525 42.0223ZM11.2842 42.0702C11.222 41.204 10.52 40.699 9.63246 40.762C8.75202 40.825 8.12276 41.4259 8.18488 42.2921C8.24701 43.1521 8.95527 43.6625 9.8357 43.6003C10.7232 43.5364 11.3445 42.9302 11.2833 42.0702H11.2842Z"
          fill="#343892"
        />
        <path
          d="M10.1171 47.8108L9.92626 47.0892L8.37485 47.5002L8.18225 46.7724L12.3386 45.6727L12.8223 47.494C13.0397 48.3149 12.6457 48.9912 11.8788 49.1936C11.1378 49.3897 10.6097 49.0108 10.3975 48.5342L9.0334 49.9791L8.81151 49.1395L10.1189 47.809L10.1171 47.8108ZM12.1531 47.565L11.8895 46.5709L10.568 46.9206L10.8316 47.9155C10.9407 48.3256 11.2815 48.5546 11.68 48.4498C12.0785 48.3442 12.2623 47.9768 12.1531 47.5658V47.565Z"
          fill="#343892"
        />
        <path
          d="M13.7302 50.3083L14.9497 52.9834L14.3462 53.2594L13.4382 51.2695L12.4238 51.7328L13.3122 53.6819L12.7087 53.9579L11.8194 52.0089L10.7348 52.5041L11.6427 54.494L11.0383 54.77L9.81885 52.095L13.7285 50.3092L13.7302 50.3083Z"
          fill="#343892"
        />
        <path
          d="M15.3455 57.7166C16.3627 56.8734 17.6851 57.0554 18.4741 58.0059C19.0492 58.6991 19.043 59.3993 18.8513 59.9754L18.1874 59.6905C18.3286 59.3053 18.2744 58.8162 17.9585 58.4337C17.4375 57.8053 16.5331 57.7352 15.839 58.3103C15.1441 58.8863 15.0456 59.7872 15.5674 60.4165C15.8834 60.7981 16.3502 60.9463 16.7594 60.8744L16.9174 61.5791C16.3112 61.6555 15.6269 61.5374 15.0518 60.8443C14.2636 59.8928 14.3293 58.5597 15.3464 57.7157L15.3455 57.7166Z"
          fill="#343892"
        />
        <path
          d="M20.3779 64.7495L18.8176 63.4448L18.0464 63.8744L17.4144 63.3454L21.4678 61.1328L22.1894 61.7363L20.7241 66.1118L20.0921 65.5829L20.3797 64.7486L20.3779 64.7495ZM19.407 63.072L20.6415 64.1042L21.4181 61.9236L19.407 63.072Z"
          fill="#343892"
        />
        <path
          d="M24.5023 66.5778L23.8553 66.2068L23.0583 67.5985L22.405 67.2239L24.5387 63.4963L26.1744 64.4335C26.9111 64.856 27.1161 65.6113 26.722 66.2991C26.3413 66.9639 25.7013 67.0722 25.2132 66.8893L25.2851 68.8756L24.5316 68.4434L24.5023 66.5787V66.5778ZM25.7564 64.9581L24.8635 64.4459L24.1845 65.6308L25.0774 66.1429C25.4457 66.3541 25.8487 66.2725 26.0528 65.9148C26.2578 65.5571 26.1238 65.1693 25.7555 64.9581H25.7564Z"
          fill="#343892"
        />
        <path
          d="M28.7944 65.763L30.7816 66.4748C31.5209 66.7393 31.7668 67.3482 31.5626 67.918C31.3798 68.4274 30.9307 68.6502 30.5269 68.5943C30.9334 68.8153 31.1295 69.3718 30.9511 69.8688C30.7275 70.4936 30.1417 70.8105 29.3837 70.5398L27.3424 69.8075L28.7953 65.7621L28.7944 65.763ZM29.4379 69.8546C29.8257 69.9939 30.1328 69.8635 30.2589 69.512C30.368 69.2093 30.2464 68.8783 29.8408 68.7327L28.6782 68.3156L28.2752 69.4374L29.4379 69.8546ZM30.0352 68.0964C30.3991 68.2268 30.6831 68.0822 30.7914 67.7786C30.9005 67.4751 30.7745 67.1769 30.4115 67.0464L29.279 66.6399L28.9018 67.689L30.0352 68.0955V68.0964Z"
          fill="#343892"
        />
        <path
          d="M35.4651 67.5452C36.7378 67.7413 37.4896 68.8099 37.2925 70.0773C37.0955 71.3448 36.0535 72.1355 34.7817 71.9385C33.5099 71.7415 32.7572 70.6729 32.9543 69.4064C33.1522 68.139 34.1933 67.3482 35.4651 67.5452ZM35.3621 68.2073C34.5039 68.0751 33.8542 68.6458 33.7175 69.5244C33.5817 70.3969 34.0255 71.1433 34.8846 71.2764C35.7367 71.4078 36.3935 70.8309 36.5293 69.9593C36.6659 69.0806 36.2151 68.3387 35.363 68.2073H35.3621Z"
          fill="#343892"
        />
        <path
          d="M39.917 69.1365L40.0457 72.2642L39.2939 72.2953L39.1173 68.0023L39.8886 67.9712L42.2059 70.9249L42.0808 67.8807L42.8325 67.8496L43.0092 72.1426L42.2823 72.1728L39.9161 69.1374L39.917 69.1365Z"
          fill="#343892"
        />
        <path
          d="M47.0102 67.0544L48.8021 66.4739C49.6586 66.197 50.3269 66.6301 50.5514 67.3224C50.776 68.0147 50.484 68.7593 49.6328 69.0345L48.5563 69.3833L49.0515 70.9081L48.3353 71.1397L47.0093 67.0544H47.0102ZM48.9095 67.136L47.9305 67.4529L48.3521 68.7513L49.3311 68.4345C49.7349 68.304 49.9453 67.9517 49.8183 67.5594C49.6914 67.1671 49.3142 67.0056 48.9095 67.136Z"
          fill="#343892"
        />
        <path
          d="M54.0244 67.1502L53.3641 67.4982L54.1123 68.9182L53.4458 69.2697L51.4435 65.4657L53.1121 64.5861C53.8638 64.1894 54.612 64.4219 54.9812 65.1231C55.338 65.8021 55.0859 66.4012 54.6697 66.7153L56.3844 67.72L55.6158 68.1248L54.0253 67.1494L54.0244 67.1502ZM53.3295 65.2216L52.418 65.7018L53.0544 66.9106L53.9659 66.4296C54.3413 66.2316 54.4895 65.8473 54.2969 65.4826C54.1043 65.1178 53.7049 65.0219 53.3295 65.2207V65.2216Z"
          fill="#343892"
        />
        <path
          d="M55.6584 63.0258L56.2726 62.5909L58.7595 66.0967L58.1453 66.5316L55.6584 63.0258Z"
          fill="#343892"
        />
        <path
          d="M59.0471 61.8871L61.2011 64.1583L60.6544 64.6758L57.698 61.5579L58.2589 61.0271L61.9493 61.7291L59.8529 59.5183L60.3997 59.0009L63.3552 62.1188L62.8271 62.6185L59.0462 61.8862L59.0471 61.8871Z"
          fill="#343892"
        />
        <path
          d="M63.1279 59.1411C62.0566 58.3689 61.9013 57.0429 62.6238 56.0418C63.151 55.3114 63.829 55.1418 64.4361 55.1818L64.3261 55.8954C63.9178 55.8554 63.4572 56.0311 63.1669 56.4323C62.6886 57.0953 62.8474 57.9873 63.5787 58.5145C64.311 59.0417 65.2074 58.9112 65.6858 58.2482C65.976 57.8462 66.0026 57.358 65.8304 56.9799L66.4739 56.6498C66.7002 57.2178 66.7561 57.9083 66.2298 58.6396C65.5065 59.6416 64.1991 59.9123 63.1279 59.1402V59.1411Z"
          fill="#343892"
        />
        <path
          d="M64.3732 53.3685L64.7246 52.7029L68.5233 54.7105L68.1718 55.3761L64.3732 53.3685Z"
          fill="#343892"
        />
        <path
          d="M65.511 51.1231L66.1669 49.3551C66.4802 48.5102 67.2355 48.2537 67.918 48.5075C68.6005 48.7605 69.0043 49.4528 68.6928 50.2915L68.2987 51.3539L69.804 51.9112L69.5413 52.6177L65.511 51.1249V51.1231ZM66.7535 49.6817L66.395 50.6474L67.6766 51.1222L68.0351 50.1566C68.1825 49.7581 68.0369 49.3747 67.65 49.2309C67.263 49.088 66.9018 49.2832 66.7544 49.6817H66.7535Z"
          fill="#343892"
        />
        <path
          d="M66.9994 46.6499L67.1458 45.9115L70.712 46.6153L71.0785 44.7585L71.73 44.8872L71.217 47.4833L66.9994 46.6508V46.6499Z"
          fill="#343892"
        />
        <path
          d="M67.6881 42.5619L67.8053 39.6241L68.4683 39.6508L68.3804 41.8359L69.4942 41.8803L69.5803 39.7395L70.2433 39.7653L70.1572 41.906L71.3483 41.953L71.4362 39.7679L72.0992 39.7937L71.982 42.7314L67.689 42.5601L67.6881 42.5619Z"
          fill="#343892"
        />
        <path
          d="M70.8104 37.5517C71.0882 37.2198 71.3083 36.7272 71.2355 36.1326C71.1619 35.5184 70.8317 35.3125 70.5504 35.3462C70.1669 35.3924 70.1057 35.8601 70.0347 36.3935C69.9459 37.1115 69.8394 37.9538 68.9377 38.0621C68.2339 38.1473 67.6153 37.5926 67.5034 36.6598C67.4227 35.995 67.5691 35.4332 67.9055 34.9903L68.5143 35.3515C68.2223 35.7234 68.1229 36.1965 68.1771 36.6438C68.2303 37.0849 68.4753 37.3405 68.7948 37.3024C69.1276 37.2624 69.1835 36.8142 69.251 36.2941C69.3451 35.5699 69.4613 34.7063 70.3897 34.5945C71.0997 34.5084 71.7681 34.9344 71.9065 36.0846C72.0015 36.871 71.7956 37.4728 71.4379 37.8979L70.8122 37.5517H70.8104Z"
          fill="#343892"
        />
        <path
          d="M27.6237 10.2041L28.3213 9.92181L29.9304 13.9069L29.2319 14.1891L27.6228 10.2041H27.6237Z"
          fill="#343892"
        />
        <path
          d="M30.7593 11.2744C30.438 9.99371 31.1738 8.87985 32.372 8.57898C33.2453 8.35975 33.8728 8.66684 34.3103 9.08842L33.7663 9.56414C33.4805 9.27037 33.0154 9.10795 32.5353 9.22865C31.7427 9.42746 31.2901 10.212 31.5084 11.0863C31.7276 11.9605 32.4971 12.4389 33.2897 12.2401C33.7707 12.1194 34.1053 11.7635 34.2172 11.3632L34.921 11.5247C34.7275 12.1043 34.3254 12.6705 33.4521 12.8889C32.2539 13.1897 31.0806 12.5543 30.7593 11.2744Z"
          fill="#343892"
        />
        <path
          d="M35.6941 7.96389L36.5488 7.91152L38.0576 11.3028L39.1422 7.75177L39.996 7.6994L38.5759 12.0901L37.6387 12.1469L35.6941 7.96211V7.96389Z"
          fill="#343892"
        />
        <path
          d="M41.3619 9.89781C41.5154 8.58603 42.5982 7.805 43.8248 7.94878C44.7194 8.05351 45.1978 8.56384 45.457 9.11323L44.7798 9.3644C44.6165 8.98897 44.2393 8.67212 43.7467 8.61443C42.9355 8.51947 42.2334 9.09193 42.1287 9.98745C42.024 10.883 42.5742 11.6028 43.3863 11.6977C43.878 11.7554 44.3183 11.5406 44.5641 11.206L45.165 11.6072C44.7789 12.0812 44.2029 12.4672 43.3082 12.3625C42.0817 12.2187 41.2083 11.2078 41.3619 9.89692V9.89781Z"
          fill="#343892"
        />
        <path
          d="M50.863 10.8271L48.5865 13.5012L48.2812 13.4L48.0584 9.89693L47.0351 12.9865L46.3197 12.7495L47.6714 8.66858L48.6806 9.00318L48.8767 12.1176L50.8985 9.73895L51.9076 10.0736L50.5559 14.1545L49.8406 13.9175L50.8639 10.828L50.863 10.8271Z"
          fill="#343892"
        />
        <path
          d="M66.994 32.3259C66.9079 32.017 66.813 31.7135 66.718 31.4091C64.5586 24.6309 59.9523 18.9259 53.7502 15.35C53.4679 15.184 53.1875 15.0322 52.907 14.8805L54.0874 12.7015C54.3919 12.8657 54.6963 13.0317 54.9963 13.2074C61.7274 17.0886 66.7322 23.289 69.0815 30.6635C69.1871 30.999 69.2901 31.3301 69.3824 31.6656L66.994 32.3268V32.3259ZM12.2809 31.7721L9.90137 31.0789C10.0008 30.7381 10.1108 30.4026 10.2253 30.0662C12.6856 22.8665 17.7019 16.8313 24.3487 13.0734C24.6451 12.9048 24.9496 12.7406 25.2531 12.58L26.4078 14.7722C26.1273 14.9195 25.8469 15.0722 25.5708 15.2284C19.4495 18.6889 14.8334 24.2422 12.5702 30.8686C12.4672 31.1694 12.3687 31.4676 12.28 31.7712L12.2809 31.7721Z"
          fill="#343892"
        />
        <path
          d="M79.9246 79.9982H-0.0754395V-0.00268555H79.9246V79.9973V79.9982ZM1.61088 78.3119H78.2382V1.68364H1.61088V78.311V78.3119Z"
          fill="#343892"
        />
      </g>
      <defs>
        <clipPath id="clip0_4022_70">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CCPLogo
