import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import GoBackButton from 'view/components/buttons/go-back/GoBackButton'
import Logo from 'view/components/cloverly-logo/Logo'
import './Header.scss'

const Header = ({ title, hasGoBackBtn = true }) => {
  const cachedProjects = JSON.parse(localStorage.getItem('checkout-projects'))
  const shouldHaveGoBackBtn = cachedProjects?.length > 1

  const smallWidth = useMediaQuery('(max-width:700px)')

  const cobrandedLogo = JSON.parse(localStorage.getItem('cobranded-logo'))

  return (
    <div className="offset-purchase-header">
      {smallWidth && (
        <header className="small-view">
          <section className="logo-and-back-btn">
            {hasGoBackBtn && shouldHaveGoBackBtn && (
              <GoBackButton title="Back" withSearchParams />
            )}
            {cobrandedLogo !== null ? (
              <img
                className="cobranding-logo"
                src={`data:image/jpeg;base64,${cobrandedLogo}`}
                alt=""
              />
            ) : (
              <Logo />
            )}
          </section>
          <h1 className="checkout-page-title">{title}</h1>
        </header>
      )}
      {!smallWidth && (
        <header className="large-view">
          {cobrandedLogo !== null ? (
            <img
              className="cobranding-logo"
              src={`data:image/jpeg;base64,${cobrandedLogo}`}
              alt=""
            />
          ) : (
            <Logo />
          )}
          <section className="back-btn-and-title">
            {hasGoBackBtn && shouldHaveGoBackBtn && (
              <GoBackButton title="Back" withSearchParams />
            )}
            <h1 className="checkout-page-title">{title}</h1>
          </section>
        </header>
      )}
    </div>
  )
}

export default Header
