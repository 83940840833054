import React from 'react'
import ExternalLink from 'view/components/external-link/ExternalLink'
import VintageYearButton from 'view/components/vintage-year/vintage-year-button/VintageYearButton'
import { determinePrimaryImg } from '../../../project-details/components/ProjectDetailsUtility'
import './DetailedProjectPanel.scss'

const DetailedProjectPanel = ({ project }) => {
  const imageURL = determinePrimaryImg(project?.image_list)

  return (
    <div className="detailed-project-panel">
      <span className="type">{project?.type}</span>
      <span className="name">{project?.name}</span>
      <span className="location">
        {project?.city?.trim()}, {project?.country}
      </span>
      <ExternalLink title="View Details" url={project?.project_url} />
      <img className="panel-image" src={imageURL} alt="" />
      <span className="short-description">{project?.short_description}</span>
      {project &&
        project.is_purchasable !== false &&
        !project?.active_tranche?.is_issued && (
          <div className="vintage">
            <div className="label">Vintage</div>
            <div className="info">
              {project?.active_tranche?.vintage_year}{' '}
              <span
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <VintageYearButton
                  label="Forward"
                  projectName={project?.short_name || project?.name}
                  deliveryDate={project?.active_tranche?.delivery_date}
                />
              </span>
            </div>
          </div>
        )}
    </div>
  )
}

export default DetailedProjectPanel
