import React from 'react'
import Skeleton from 'antd/lib/skeleton'
import Button from 'view/components/button/Button'
import PurchaseListItem from './PurchaseListItem'
import { useMarketplaceContext } from '../../../MarketplaceContext'
import './VintageSelector.scss'

const PurchaseConfirmation = ({
  projectEstimate,
  handleAction,
  formattedAmount,
  formattedCost,
  formattedCostPerTonne,
  PaymentMethodInfo,
}) => {
  const { project, hasInventoryError, selectedVintage, isEstimationLoading } =
    useMarketplaceContext()

  return (
    <div className="vintage-selector">
      <section className="add-to-cart">
        <div className="purchase-list">
          <PurchaseListItem
            project={project}
            vintageYear={selectedVintage?.vintage_year}
            isEstimationLoading={isEstimationLoading}
            formattedAmount={formattedAmount}
            formattedCost={formattedCost}
            formattedCostPerTonne={formattedCostPerTonne}
          />
        </div>

        <div className="divider"></div>

        <div className="carbon-total-row">
          <label>Carbon total</label>
          <data>{formattedAmount}</data>
        </div>

        <div className="payment-total-row">
          <label>Total</label>
          {!isEstimationLoading && <data>{formattedCost}</data>}
          {isEstimationLoading && (
            <Skeleton.Button
              className="text-skeleton small"
              active={true}
              loading={true}
            />
          )}
        </div>

        <div className="divider"></div>

        <div className="payment-method-row">
          <label>Payment method</label>
          <data className="light">{PaymentMethodInfo()}</data>
        </div>

        <div className="result-and-btn-v2">
          <Button
            cn="purchase-btn"
            disabled={
              !projectEstimate?.total_cost ||
              hasInventoryError ||
              isEstimationLoading
            }
            onClick={handleAction}
          >
            {formattedCost && !isEstimationLoading
              ? `Purchase for ${formattedCost}`
              : `Loading...`}
          </Button>
        </div>
      </section>
    </div>
  )
}

export default PurchaseConfirmation
