import React, { ReactNode } from 'react'
import { Tooltip } from 'react-tooltip'

type Props = {
  is_purchasable: boolean
  isAmountMoreThanZero: boolean
  isDisplayRealAmount: boolean
  tooltipAmount: string
  totalAmount: number
  costPerTonneFormatted: number
  getMinCostPerTonneFormatted: string
}

const ProjectPanelAmount = ({
  is_purchasable,
  isAmountMoreThanZero,
  isDisplayRealAmount,
  tooltipAmount,
  totalAmount,
  costPerTonneFormatted,
  getMinCostPerTonneFormatted,
}: Props): ReactNode | null => {
  const costPerTonneClassName = `cost-per-tonne ${
    isAmountMoreThanZero ? 'has-total' : ''
  }`

  const totalAmountClassName = `total-amount ${isDisplayRealAmount ? 'dotted' : ''}`

  if (is_purchasable) {
    return (
      <>
        {isAmountMoreThanZero && (
          <>
            <Tooltip className="amount-tooltip" />
            <span
              className={totalAmountClassName}
              data-tip={tooltipAmount}
              data-effect="solid"
              data-border={true}
              data-place="top"
              data-multiline={true}
            >
              {totalAmount}
            </span>
          </>
        )}
        <span className={costPerTonneClassName}>{costPerTonneFormatted}</span>
      </>
    )
  }

  if (getMinCostPerTonneFormatted) {
    return (
      <div className="flex flex-col">
        <span className="text-sm font-semibold text-text-muted leading-4">
          Starting at
        </span>
        <span className="text-base font-bold text-primary-30 leading-5">
          {getMinCostPerTonneFormatted}/tonne
        </span>
      </div>
    )
  }

  return (
    <span className="text-sm font-semibold text-text-muted leading-4">
      Price provided
      <br />
      in quote.
    </span>
  )
}

export default ProjectPanelAmount
