/**
 * Location where registry logos are stored.
 *
 * @type {string}
 */
const registryLogoBaseUrl = 'https://imagedelivery.net/OeX1-Pzk5r51De534GGSBA/'

/**
 * Maps registry data to the registry name.
 *
 * @type {Map<string, {logo: string}>}
 */
const registryMap = new Map([
  ['American Carbon Registry', {
    logo: '56d810a9-9f26-44b7-655c-efc132ba5500/public'
  }],
  ['Carbon Standard International', {
    logo: '7598bb6a-6560-47bb-d3a0-a92da18afc00/public'
  }],
  ['CarbonFuture', {
    logo: '99d61d2c-196d-470f-a3d0-608ea9068300/public'
  }],
  ['City Forest Credits', {
    logo: '82872b9d-637c-489b-0321-6ab667646e00/public'
  }],
  ['Gold Standard', {
    logo: 'a7510bd4-f8c2-4ffc-bb76-2ee22383f000/public'
  }],
  ['Plan Vivo', {
    logo: '28dcd696-5c78-40fc-6da7-cd5920729e00/public'
  }],
  ['Puro.earth', {
    logo: 'b4dd578c-3d13-4ee2-7b21-f802f87c4400/public'
  }],
  ['Verra', {
    logo: '9387e392-a319-4d38-5773-82dab3ebb000/public'
  }],
])

/**
 * @param {object} project
 * @returns {string}
 */
const linkTitle = project => !project?.registry_project_id && project?.registry_link && getHost(project?.registry_link)
  ? 'Project Website'
  : 'Project ID'

/**
 * @param {string} str
 * @returns {string|undefined}
 */
const getHost = str => {
  try {
    return new URL(str).hostname.replace('www.', '')
  } catch (e) {
    return undefined
  }
}

/**
 * Exports public defs.
 */
export {
  registryLogoBaseUrl,
  registryMap,
  linkTitle,
  getHost
}
