/**
 * download contents to a file on the user's computer.
 * @param content
 * @param fileName
 * @param contentType
 */
export const download = (
  content: any,
  fileName: string = 'download.txt',
  contentType: string = 'plain/text'
): void => {
  const linkElement = document.createElement('a')
  const blob = new Blob([content], { type: contentType })
  const url = window.URL.createObjectURL(blob)
  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false
  })
  linkElement.setAttribute('href', url)
  linkElement.setAttribute('download', fileName)
  linkElement.dispatchEvent(clickEvent)
}
