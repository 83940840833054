import React, { useState } from 'react'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { Col, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'
import DateToolTip from './DateToolTip'

const CreateReportModal = ({ setShowCreateReport, showCreateReport }) => {
  const { serviceRequest } = useService()
  const { infoToast } = useToast()

  const [reportType, setReportType] = useState('purchases')

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    start_date: undefined,
    end_date: undefined,
  })

  const errorMessages = {
    required: 'This field is required',
    datePriority: 'Start Date cannot be after End Date',
  }

  const onSubmit = () => {
    const path = reportType === 'purchases' ? '/report' : '/report/footprint'

    const data = getValues()
    let startDate = new Date(data.start_date)
    startDate.setUTCHours(0, 0, 0, 0)
    data.start_date = startDate.toISOString()
    let endDate = new Date(data.end_date)
    endDate.setUTCHours(23, 59, 59, 999)
    data.end_date = endDate.toISOString()

    serviceRequest({
      path,
      method: 'POST',
      data,
    }).then(data => {
      infoToast(data.message)
      reset()
      setShowCreateReport(false)
    })
  }

  const cancel = e => {
    e.preventDefault()
    reset()
    setShowCreateReport(false)
  }

  const checkDates = value => value <= getValues('end_date')

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showCreateReport}
      data-testid="create-report-modal"
    >
      <Modal.Body>
        <h2>Create Report</h2>

        <h3>Type of Report</h3>
        <Form.Group>
          <Form.Check
            id="Purchases"
            label="Purchases"
            aria-label="Purchases Report Type"
            checked={reportType === 'purchases'}
            onChange={() => setReportType('purchases')}
            type="radio"
            inline
          />
          <Form.Check
            id="Footprints"
            label="Footprints"
            aria-label="Footprints Report Type"
            checked={reportType === 'footprints'}
            onChange={() => setReportType('footprints')}
            type="radio"
            inline
          />
        </Form.Group>

        <h3>Date Range</h3>
        <p>
          Select a start and end date to generate a CSV report of your
          transactions.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="date-selectors">
            <Form.Group as={Col} controlId="validationCustom01">
              <Form.Label className="flex items-center">
                Start Date <DateToolTip />
              </Form.Label>
              <Form.Control
                type="date"
                isInvalid={errors?.start_date}
                {...register('start_date', {
                  required: errorMessages.required,
                  validate: value =>
                    checkDates(value) || errorMessages.datePriority,
                })}
              ></Form.Control>
              {errors?.start_date && (
                <p className="invalid-message">{errors.start_date.message}</p>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="validationCustom01">
              <Form.Label className="flex items-center">
                End Date <DateToolTip />
              </Form.Label>
              <Form.Control
                type="date"
                isInvalid={errors?.end_date}
                {...register('end_date', {
                  required: errorMessages.required,
                })}
              ></Form.Control>
              {errors?.end_date && (
                <p className="invalid-message">{errors.end_date.message}</p>
              )}
            </Form.Group>
          </div>

          <div className="btn-group">
            <Button cn={'cancel'} onClick={cancel}>
              Cancel
            </Button>
            <Button type="submit">Generate CSV</Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateReportModal
