import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { displayDate, timeWithZone } from 'model/utils/date'
import { Table } from 'react-bootstrap'
import './AccountSettingsTables.scss'

const AccountsTable = React.memo(({ users, setUserToDelete }) => {
  return (
    <div className="table-container">
      <Table>
        <thead>
          <tr>
            <td>Account Name</td>
            <td>Email</td>
            <td>Last Activity</td>
            <td>Total Spent</td>
            <td>Public Key</td>
            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(users) && !!users?.length ? (
            users?.map((user, index) => (
              <tr key={'ut_row_' + index}>
                <td className="name-cell">{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {user.last_activity ? (
                    <div className="date-rows">
                      <span className="month-and-year">
                        {displayDate(
                          new Date(user.last_activity?.replace(/ /g, 'T'))
                        )}
                      </span>
                      <br />
                      <span className="date-format">
                        {timeWithZone(
                          new Date(user.last_activity?.replace(/ /g, 'T'))
                        )}
                      </span>
                    </div>
                  ) : (
                    <>Never</>
                  )}
                </td>
                <td>${user.lifetime_spend}</td>
                <td>{user.public_key}</td>
                <td className="icon-cell" onClick={() => setUserToDelete(user)}>
                  <DeleteIcon />
                </td>
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {!users?.length && (
        <div className="empty-table-message">{'No sub account to show'}</div>
      )}
    </div>
  )
})

export default AccountsTable
