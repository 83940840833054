import React, { useEffect, useState } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import { useAccountUsers } from 'model/hooks/account/useAccountUsers'
import { useUser } from 'model/hooks/account/useUser'
import { setPageTitle } from 'model/utils/page'
import CopyButton from 'view/components/buttons/copy-button/CopyButton'
import ExternalLinkIcon from 'view/components/icons/external-link-icon/ExternalLinkIcon'
import '../../AccountSettings.scss'
import DeleteUserModal from '../modals/DeleteUserModal'
import AccountSettingsSubnav from '../navs/AccountSettingsSubnav'
import AccountsTable from '../tables/AccountsTable'
import './ChildrenPage.scss'

const ChildrenPage = () => {
  const subAccountsDocsUrl =
    'https://docs.cloverly.com/docs/cloverly-api/3f852cb3d5c0a-create-sub-account'

  const { account } = useUser()
  const { users, loadUsers, deleteUser } = useAccountUsers()
  const [showDeleteUser, setShowDeleteUser] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)

  useEffect(() => {
    setPageTitle('Sub Accounts | Account Settings')
  }, [])

  useEffect(() => {
    loadUsers(true)
    // eslint-disable-next-line
  }, [account])

  const setUserToDeleteConfirm = user => {
    setUserToDelete(user)
    setShowDeleteUser(true)
  }

  const deleteUserConfirmed = () => {
    deleteUser(userToDelete, true).then(data => {
      if (data) setShowDeleteUser(false)
    })
  }

  return (
    <>
      <DeleteUserModal
        showDeleteUser={showDeleteUser}
        setShowDeleteUser={setShowDeleteUser}
        deleteUser={userToDelete}
        deleteUserConfirmed={deleteUserConfirmed}
        title="Remove Sub Account"
      />
      <div className="account-settings">
        <h1>Account Settings</h1>
        <AccountSettingsSubnav />
        <div className="sub-accounts-link-panel-container">
          <div className="panel">
            <h3>Sub Account Creation Link</h3>
            <p>
              Your customers can use the link below to sign up for a Sub
              Account.
            </p>
            <hr />
            <div className="link-info">
              <LinkIcon />
              <p>
                {window.location.hostname}/register/{account.slug}
              </p>
              <CopyButton
                className="copy-button"
                placement="top"
                buttonLabel="Copy Link"
                textToCopy={`${window.location.hostname}/register/${account.slug}`}
              />
            </div>
          </div>
          <div className="panel panel-smaller">
            <h3>API Sub Account Documentation</h3>
            <p>
              You can also create Sub Accounts programmatically using our API.
            </p>
            <a href={subAccountsDocsUrl} target="_blank" rel="noreferrer">
              <span>Sub Account Docs</span> <ExternalLinkIcon />
            </a>
          </div>
        </div>
        <h2>Sub Accounts</h2>
        <AccountsTable
          users={users}
          removeUser={() => setShowDeleteUser(true)}
          setUserToDelete={setUserToDeleteConfirm}
        />
      </div>
    </>
  )
}

export default ChildrenPage
