import React from 'react'
import { useUser } from 'model/hooks/account/useUser'

const CobrandedLogo = () => {
  const { account } = useUser()
  return (
    <img
      className="h-full object-contain"
      src={`data:image/jpeg;base64,${account?.cobranding_image}`}
    />
  )
}

export default CobrandedLogo
