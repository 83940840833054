import React, { useEffect } from 'react'
import { useAuth } from 'model/hooks/account/useAuth'
import { setStoredTargetPath } from 'model/utils/userStorage'
import { Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import Button from 'view/components/button/Button'
import './LoginForm.scss'

const LoginForm = () => {
  // an object with defaultValues prop could be passed as an argument to useForm()
  const { register, errors, handleSubmit, getValues } = useForm()
  const { logIn } = useAuth()
  const search = useLocation().search

  const errorMessages = {
    required: 'This field is required',
  }

  /**
   * Save the given target path if given, or save null
   */
  useEffect(
    () => setStoredTargetPath(new URLSearchParams(search)?.get('view') || null),
    // eslint-disable-next-line
    []
  )

  /**
   * Login with form values.
   */
  const onSubmit = () => logIn(getValues())

  return (
    <div className="user-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Col} controlId="validationCustom01">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            isInvalid={errors?.email}
            autoComplete="email"
            {...register('email', { required: errorMessages.required })}
          />
          {errors?.email && (
            <p className="invalid-message">{errors?.email.message}</p>
          )}
        </Form.Group>
        <Form.Group as={Col} controlId="validationCustom02">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            autoComplete="current-password"
            isInvalid={errors?.password}
            {...register('password', { required: errorMessages.required })}
          />
          {errors?.password && (
            <p className="invalid-message">{errors?.password.message}</p>
          )}
        </Form.Group>
        <Button cn={'form-btn dark'} type={'submit'}>
          Log In
        </Button>
      </form>
    </div>
  )
}

export default LoginForm
