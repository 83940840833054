import React, { useState } from 'react'
import Button from 'view/components/button/Button'
import TreeGraphIcon from 'view/components/icons/tree-graph-icon/TreeGraphIcon'
import AddCardModal from '../../../account-settings/components/modals/AddCardModal'
import './NoBillingDashboard.scss'

const NoBillingDashboard = () => {
  const [showAddCard, setShowAddCard] = useState(false)

  return (
    <>
      <AddCardModal
        showAddCard={showAddCard}
        setShowAddCard={bool => setShowAddCard(bool)}
      />
      <div className="no-billing-dashboard">
        <TreeGraphIcon />
        <h1>Welcome to Cloverly!</h1>
        <p>
          Get started with your Cloverly account by setting up your billing.
        </p>
        <Button onClick={() => setShowAddCard(true)}>Add a card</Button>
      </div>
    </>
  )
}

export default NoBillingDashboard
