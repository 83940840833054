import React, { useEffect, useReducer, useRef, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { formatCost } from 'model/utils/cost'
import { NavLink } from 'react-router-dom'
import AppTooltip from 'view/components/app-tooltip/AppTooltip'
import Button from 'view/components/button/Button'
import CreditCardsIcon from 'view/components/icons/credit-cards-icon/CreditCardsIcon'
import '../../../AccountSettings.scss'
import AddCreditsModal from '../../modals/AddCreditsModal'
import AutoBillingModal from '../../modals/AutoBillingModal'
import EditCardModal from '../../modals/EditCardModal'
import AccountSettingsSubnav from '../../navs/AccountSettingsSubnav'
import CreditTable from '../../tables/CreditTable'
import InvoiceTable from '../../tables/InvoiceTable'
import NumOfApiCallsTable from '../../tables/NumOfApiCallsTable'

const BillingCardPage = () => {
  const [card, setCard] = useState(undefined)
  const [showAddCredits, setShowAddCredits] = useState(false)
  const [showEditCard, setShowEditCard] = useState(false)
  const [showAutoBilling, setShowAutoBilling] = useState(false)
  const [state, dispatch] = useReducer(reducer, {})
  const { serviceRequestMulti, spreadResponses } = useService()
  const { account } = useUser()
  const sourceRef = useRef()
  const sourceStripeRef = useRef()

  useEffect(() => {
    // load credit card information if stripe id exists
    if (account?.stripe_id)
      serviceRequestMulti({
        requests: [
          { path: '/account/billing/card?stripe_id=' + account.stripe_id },
        ],
        sourceRef: sourceStripeRef,
      }).then(
        spreadResponses(cardResponse => {
          if (cardResponse) setCard(cardResponse.data)
        })
      )

    const requests = [
      { path: '/api/credits?page=1&limit=5' },
      { path: '/api/invoices?page=1&limit=5' },
      { path: '/dashboard/calls' },
    ]
    serviceRequestMulti({ requests, sourceRef }).then(
      spreadResponses((creditsTable, invoicesTable, numOfApiCallsTable) => {
        dispatch({ type: 'setCredits', creditsTableData: creditsTable?.data })
        dispatch({
          type: 'setInvoices',
          invoicesTableData: invoicesTable?.data,
        })
        dispatch({
          type: 'setNumOfApiCalls',
          numOfApiCallsTableData: numOfApiCallsTable?.data,
        })
      })
    )
    // eslint-disable-next-line
  }, [account])

  function reducer(state, action) {
    switch (action.type) {
      case 'setCredits':
        state.creditsTableData = action.creditsTableData
        break
      case 'setInvoices':
        state.invoicesTableData = action.invoicesTableData
        break
      case 'setNumOfApiCalls':
        state.numOfApiCallsTableData = action.numOfApiCallsTableData
        break
      default:
        break
    }
    return { ...state }
  }

  const cardInfo = card => {
    if (card === undefined) return <>Loading...</>
    else if (card === null) return <p>No card available</p>
    else
      return (
        <p>
          {card.brand} ending in {card.last4}, expires {card.exp_month}/
          {card.exp_year}
        </p>
      )
  }

  return (
    <>
      <AddCreditsModal
        showAddCredits={showAddCredits}
        setShowAddCredits={bool => setShowAddCredits(bool)}
        card={card}
      />
      <EditCardModal
        showEditCard={showEditCard}
        setShowEditCard={bool => setShowEditCard(bool)}
        card={card}
      />
      <AutoBillingModal
        showAutoBilling={showAutoBilling}
        setShowAutoBilling={bool => setShowAutoBilling(bool)}
      />
      <div className="account-settings">
        <h1>Account Settings</h1>
        <AccountSettingsSubnav />
        <div className="billing-panels">
          <div className="col-left">
            <div className="panel">
              <div className="panel-header">
                <span>Card</span>
                <span className="spacer" />
                <Button onClick={() => setShowEditCard(true)}>Edit</Button>
              </div>
              <div className="panel-text-container">
                <CreditCardsIcon />
                {cardInfo(card)}
              </div>
            </div>
            <div className="panel">
              <div className="panel-header">
                <span>Auto Billing</span>
                <span className="spacer" />
                <Button onClick={() => setShowAutoBilling(true)}>Edit</Button>
              </div>
              <div className="panel-text-container">
                <p>
                  {account.is_auto_billing_enabled
                    ? `Enabled, Max amount of ${formatCost(
                        account.auto_billing_increment_in_usd_cents / 100,
                        'USD'
                      )}`
                    : 'Disabled'}
                </p>
              </div>
            </div>
          </div>
          <div className="col-right panel">
            <div className="sub-panel-top">
              <div className="panel-header">
                <span>Available Credits</span>
                <AppTooltip
                  id="available-credits"
                  placement="top"
                  className="inline-tooltip-icon"
                  message={
                    <div>
                      Available credits is the balance of your account credits,
                      which
                      <br />
                      are available to be used for the purchase of carbon
                      offsets.
                    </div>
                  }
                />
              </div>
              <div className="price-label-large green-text">
                {formatCost(account.balance_in_usd_cents / 100, 'USD')}
              </div>
            </div>
          </div>
        </div>
        <h2>Invoices</h2>
        <InvoiceTable
          tableData={state?.invoicesTableData}
          showPaginator={false}
        />
        {state?.invoicesTableData?.total > 0 && (
          <div className="page-button-container">
            <NavLink to="/account-settings/billing/invoices">
              View All Invoices <ArrowForwardIcon />
            </NavLink>
          </div>
        )}
        <h2>Credits</h2>
        <CreditTable
          tableData={state?.creditsTableData}
          showPaginator={false}
        />
        {state?.creditsTableData?.total > 0 && (
          <div className="page-button-container">
            <NavLink to="/account-settings/billing/credits">
              View All Credits <ArrowForwardIcon />
            </NavLink>
          </div>
        )}
        <h2>API Calls</h2>
        <NumOfApiCallsTable
          tableData={state?.numOfApiCallsTableData}
          isFullView={false}
        />
        {state?.numOfApiCallsTableData &&
          state?.numOfApiCallsTableData?.length !== 0 && (
            <div className="page-button-container">
              <NavLink to="/account-settings/billing/num-of-api-calls">
                View All API Calls <ArrowForwardIcon />
              </NavLink>
            </div>
          )}
      </div>
    </>
  )
}

export default BillingCardPage
