import React from 'react'
import { useAccountUsers } from 'model/hooks/account/useAccountUsers'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'view/components/button/Button'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import './Modal.scss'

const AddUserModal = ({
  showCreateUser = false,
  setShowCreateUser,
  addUserConfirmed,
}) => {
  const validEmailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const { addUser } = useAccountUsers()

  const onSubmit = data => {
    addUser(data.email, data.userRole).then(data => {
      if (data) {
        addUserConfirmed()
        close()
      }
    })
  }

  const close = e => {
    if (e) e.preventDefault()
    setShowCreateUser(false)
    reset()
  }

  return (
    <Modal
      show={showCreateUser}
      size="lg"
      className="account-settings-modal"
      centered
    >
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CloseDialogButton onClick={e => close(e)} />
          <h2>Add User to Account</h2>
          <p>
            Adding a user to the account gives the person permissions to access
            the account's Cloverly dashboard. This functionality is not
            associated with API access.
          </p>
          <Row>
            <Form.Group as={Col}>
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Control
                id="email"
                name="email"
                type="text"
                isInvalid={errors.email}
                {...register('email', {
                  required: true,
                  pattern: validEmailPattern,
                })}
              />
              {errors.email && (
                <p className="invalid-message">
                  Please enter a valid email address.
                </p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label htmlFor="role">Role</Form.Label>
              <Form.Select
                id="role"
                name="role"
                isInvalid={errors.userRole}
                {...register('userRole', { required: true })}
              >
                <option value="">Select Role...</option>
                <option value="admin">admin</option>
                <option value="user">user</option>
              </Form.Select>
              {errors.userRole && (
                <p className="invalid-message">Please select a user role.</p>
              )}
            </Form.Group>
          </Row>
          <div className="modal-desc">
            <div>
              <div className="desc-head">User permissions include:</div>
              <ul>
                <li>View Sandbox API keys</li>
                <li>View Instructions</li>
                <li>View Sandbox and Production Transactions</li>
                <li>View Sandbox and Production Debugger</li>
                <li>View-only billing information</li>
                <li>View-only account information</li>
              </ul>
            </div>
            <div>
              <div className="desc-head">
                Admins have all user permissions, plus the following:
              </div>
              <ul>
                <li>View Production API keys</li>
                <li>Modify billing information</li>
                <li>Modify account information</li>
                <li>Add users to account</li>
              </ul>
            </div>
          </div>
          <div className="btn-group">
            <Button cn={'cancel'} onClick={e => close(e)}>
              Cancel
            </Button>
            <Button type="submit">Add User</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AddUserModal
