import React from 'react'
import CloverlyTooltip from 'view/shamrock/tooltip/Tooltip'

const DateToolTip = () => (
  <CloverlyTooltip>
    Report will include data from the selected date
  </CloverlyTooltip>
)

export default DateToolTip
