import React, { useEffect, useRef, useState } from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { setPageTitle } from 'model/utils/page'
import { useNavigate } from 'react-router-dom'
import { getDisplayData, getFormattedTableData } from './formatTableData'
import TransactionsTable from './table-with-pagination/TransactionsTable'

const TransactionHistory = ({ selectedFilter }) => {
  const navigate = useNavigate()
  const { serviceRequestMulti, spreadResponses } = useService()
  const { account } = useUser()
  const [page, setPage] = useState(0)
  const [nextPage, setNextPage] = useState(null)
  const [refreshToggle, setRefreshToggle] = useState('flip')
  const [tableData, setTableData] = useState({
    transactions: [],
    totalPages: 1,
  })
  const [transactionCache, setTransactionCache] = useState([])
  const [tag, setTag] = useState(null)
  const [tagsList, setTagsList] = useState(undefined)

  // TODO: for portfolios MVP (currently unused)
  // TODO: replace the data passed into the table with formattedTableData
  const [, setFormattedTableData] = useState([])
  const portfoliosAPI = [{ portfolioId: '', transactionId: '' }]

  // search states
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [search, setSearch] = useState('')
  const sourceRef = useRef()

  // current search filters.  Used to determine if cached data should be wiped.
  const [existingSearchFilters, setExistingSearchFilters] = useState({
    accountSlug: undefined,
    searchFilter: undefined,
    environmentFilter: undefined,
    tagFilter: undefined,
  })

  const tableDataHeaders = ['Date', 'Cost', 'Carbon', 'Source', 'Tags', 'Note']

  const changePage = ({ selected }) => {
    setPage(selected)
    setRefreshToggle(refreshToggle === 'flip' ? 'flop' : 'flip')
  }

  useEffect(() => {
    setPageTitle('Transaction History')
  }, [])

  useEffect(() => {
    const currentSearchFilters = {
      accountSlug: account.slug,
      searchFilter: search ? 'search=' + encodeURI(search) + '&' : '',
      environmentFilter: `&state=${selectedFilter.toLowerCase()}`,
      tagFilter: tag ? `&tag=${tag}` : '',
    }

    if (
      existingSearchFilters.accountSlug !== currentSearchFilters.accountSlug ||
      existingSearchFilters.searchFilter !==
        currentSearchFilters.searchFilter ||
      existingSearchFilters.environmentFilter !==
        currentSearchFilters.environmentFilter ||
      existingSearchFilters.tagFilter !== currentSearchFilters.tagFilter
    ) {
      setTransactionCache([])
    }

    if (transactionCache[page]) {
      setTableData(transactionCache[page])
      setNextPage(transactionCache[page].next_page)
      setIsSearchLoading(false)
    } else {
      // the requests array will always include search, so let's add that first
      const requests = [
        {
          method: 'GET',
          path: `/dashboard/transactions?${
            currentSearchFilters.searchFilter
          }limit=10&page=${page + 1}${currentSearchFilters.environmentFilter}${
            currentSearchFilters.tagFilter
          }`,
        },
      ]

      // if tags are undefined, then we are executing the initial call right now, and we should fetch the tags as well
      if (tagsList === undefined)
        requests.push({
          method: 'GET',
          path: '/dashboard/tags',
        })
      else setIsSearchLoading(true)

      // execute the axios multi request (1 or 2 requests, depending on if we're executing the initial call right now
      serviceRequestMulti({
        requests,
        sourceRef,
        showLoading: tagsList === undefined,
      })
        .then(
          spreadResponses((transactionsResponse, tagsResponse) => {
            if (transactionsResponse?.data) {
              //console.log("Transactions Response Returned 👍", transactionsResponse.data)
              transactionCache[page] = transactionsResponse.data
              setTransactionCache(transactionCache)
              setTableData(transactionCache[page])
              setNextPage(transactionsResponse.data.next_page)
              filterTableData(selectedFilter)
            }
            if (tagsResponse) {
              if (tagsResponse?.data) {
                //console.log("Tags Response Returned 👍", tagsResponse.data.tags)
                setTagsList(tagsResponse.data.tags)
              } else setTagsList([])
            }
          })
        )
        .catch(e => console.log('Request error or canceled!', e))
        .finally(() => {
          setIsSearchLoading(false)
        })
    }

    setExistingSearchFilters(currentSearchFilters)
    // eslint-disable-next-line
  }, [search, refreshToggle])

  useEffect(() => {
    if (account.slug !== existingSearchFilters.accountSlug) {
      setTagsList(undefined)
      setTag(null)
      // setFilter('All Transactions')
    }
    setTransactionCache([])
    setPage(0)
    setRefreshToggle(refreshToggle === 'flip' ? 'flop' : 'flip')
    // eslint-disable-next-line
  }, [account, selectedFilter, tag])

  const onSearchChange = search => {
    setTransactionCache([])
    setPage(0)
    setSearch(search)
  }

  const filterTableData = state => {
    setTableData({
      ...transactionCache[page],
      transactions: transactionCache[page]?.transactions.filter(
        item => item.transaction_state.toLowerCase() === state.toLowerCase()
      ),
    })
    if (state === 'All Transactions') {
      setTableData(transactionCache[page])
    }
  }

  useEffect(() => {
    // TODO: replace the data passed into the table with formattedTableData
    // run useEffect hook on every portfoliosApi / tableData dep change
    setFormattedTableData(
      getFormattedTableData(tableData, portfoliosAPI, navigate)
    )
    // eslint-disable-next-line
  }, [])

  return (
    <TransactionsTable
      dropdownExists={true}
      tableDataHeaders={tableDataHeaders}
      onSearchChange={onSearchChange}
      isSearchLoading={isSearchLoading}
      displayData={getDisplayData(tableData, account, navigate)}
      rowCount={tableData?.transactions?.length}
      pageCount={tableData.totalPages}
      nextPage={nextPage}
      currentPage={page}
      changePage={changePage}
      emptyTableMessage="No Transactions"
      tag={tag}
      tags={tagsList}
      setTag={setTag}
    />
  )
}

export default TransactionHistory
