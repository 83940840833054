import { QueryClient } from 'react-query'

export function queryErrorHandler(error: Error | any): void {
  const id: string = 'react-query-error'
  const title: string =
    error instanceof Error
      ? // remove the initial 'Error: ' that accompanies many errors
      error.toString().replace(/^Error:\s*/, '')
      : 'error connecting to server'
  console.log('Query Client Error:', id, title)
}

export const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})
