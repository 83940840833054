import React from 'react'
import CorsiaStatus from './CorsiaStatus'
import corsiaLogo from '../../../../../../../public/assets/corsia.png'

type Props = {
  expanded: boolean
  status: 'eligible' | 'compliant'
}

const CorsiaHeader = ({ expanded, status }: Props) => (
  <>
    <div className="flex gap-2 w-1/3">
      <img className="object-contain" src={corsiaLogo} alt="CORSIA" />
      <p className="font-semibold">CORSIA</p>
    </div>
    {!expanded && <CorsiaStatus status={status} />}
  </>
)

export default CorsiaHeader
