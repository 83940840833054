import React, { useEffect, useMemo, useState } from 'react'
import { setPageTitle } from 'model/utils/page'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import '../../Shopify.scss'
import ShopifyFormControls from '../panels/shopify-form-controls/ShopifyFormControls'
import ShopifyFormPanel from '../panels/shopify-form-panel/ShopifyFormPanel'

const ShopifyFulfillmentForm = React.memo(
  ({ shopifyData, saveShopifyDataChanges }) => {
    const [editMode, setEditMode] = useState(false)
    const {
      register,
      formState: { errors },
      watch,
      handleSubmit,
      reset,
    } = useForm({ defaultValues: useMemo(() => shopifyData, [shopifyData]) })

    const errorMessages = {
      sku_format: 'Variant SKU required.',
    }

    useEffect(() => {
      setPageTitle('Fulfillment | Shopify App')
    }, [])

    useEffect(() => {
      const subscription = watch((value, { type }) =>
        setEditMode(type ? true : editMode)
      )
      return () => subscription.unsubscribe()
      // eslint-disable-next-line
    }, [watch])

    useEffect(() => {
      if (shopifyData) {
        reset(shopifyData)
      }
      // eslint-disable-next-line
    }, [shopifyData])

    const cancelChanges = () => {
      reset()
      setEditMode(false)
    }

    const onSubmit = data =>
      saveShopifyDataChanges({
        sku_format: data.sku_format,
        force_fulfillment: data.force_fulfillment,
        use_product_for_order: data.use_product_for_order,
      }).then(() => setEditMode(false))

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ShopifyFormPanel>
          <ShopifyFormControls
            editMode={editMode}
            setEditMode={setEditMode}
            cancelChanges={cancelChanges}
            saveChanges={handleSubmit(onSubmit)}
          />
          <div className="split-panel">
            <div className="left-panel">
              <label htmlFor="sku_format">Variant SKU</label>
              <Form.Control
                type="text"
                defaultValue={shopifyData?.sku_format}
                id="sku_format"
                name="sku_format"
                className="fulfillment"
                isInvalid={errors.sku_format}
                {...register('sku_format', { required: true })}
              />
              {errors.sku_format && (
                <p className="invalid-message">{errorMessages.sku_format}</p>
              )}
              <p className="field-info">
                Change this to set a custom SKU for the variants created by
                Cloverly
              </p>
              <h3 className="fulfillment">Shopify Fulfillment</h3>
              <Form.Check
                type="checkbox"
                label="Force Fulfillment"
                name="groupOptions1"
                id="groupOptions1"
                {...register('force_fulfillment')}
              />
              <p className="checkbox-info">
                Have Cloverly notify the fulfillment service through the default
                webhook
              </p>
              <Form.Check
                type="checkbox"
                label="Use Product for Order"
                name="groupOptions2"
                id="use_product_for_order"
                {...register('use_product_for_order')}
              />
              <p className="checkbox-info">
                Mark the Cloverly variant with the offset estimate identifier.
                This ensures proper processing and is useful with your shop
                handles checkouts outside of the normal Shopify process
              </p>
            </div>
            <div className="right-panel info">
              <h4>Fulfillment Options</h4>
              <p>
                By default, our Shopify app should work with your existing
                fulfillment system. The Cloverly carbon offset is a digital
                product that does not require shipping. However, with some
                fulfillment systems, additional steps are needed to completely
                fulfill orders with carbon offsets. Because there are so many
                variations of Shopify fulfillment setups, we have two options
                for fulfillment help:
              </p>
              <p>
                <b>Force Fulfillment:</b> when this option is enabled, the
                Cloverly carbon offset is automatically fulfilled. You will see
                “partial fulfillment” for orders with carbon offsets, and orders
                will be marked as “fulfilled” after you fulfill the other items
                on the order. We recommend only enabling this option if you have
                problems fulfilling orders with carbon offsets.
              </p>
              <p>
                <b>Use Product for Order:</b> when this option is enabled, the
                Cloverly carbon offset is given an identification number. This
                is a backup if your system doesn’t work with our force
                fulfillment feature. The outcome will be the same, the Cloverly
                product will be automatically marked as fulfilled.
              </p>
              <p>
                There are a lot of fulfillment setups possible within Shopify.
                If Cloverly isn’t working with yours, please reach out to{' '}
                <a href="mailto:support@cloverly.com">support@cloverly.com</a>{' '}
                for help!
              </p>
            </div>
          </div>
        </ShopifyFormPanel>
      </Form>
    )
  }
)

export default ShopifyFulfillmentForm
