import React from 'react'
import { useUser } from 'model/hooks/account/useUser'
import { useService } from 'model/hooks/useService'
import { useToast } from 'model/hooks/useToast'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import CloseDialogButton from 'view/components/buttons/close-dialog-button/CloseDialogButton'
import '../../Profile.scss'

const AddAccountModal = React.memo(
  ({ showAddAccountModal = false, setShowAddAccountModal }) => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm()

    const { infoToast, warningToast } = useToast()

    const { refreshUser } = useUser()
    const { serviceRequest } = useService()

    const onSubmit = data => {
      // add user functionality here
      serviceRequest({
        path: '/dashboard/accounts',
        method: 'POST',
        data: { account: { name: data.accountName } },
      }).then(data => {
        if (data.error) {
          warningToast(data.error)
        } else {
          infoToast('Account added successfully')
          refreshUser().then(() => {
            setShowAddAccountModal(false)
            reset()
          })
        }
      })
    }

    const onCancel = e => {
      e.preventDefault()
      setShowAddAccountModal(false)
      reset()
    }

    return (
      <Modal
        show={showAddAccountModal}
        size="lg"
        className="standard-modal"
        centered
      >
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CloseDialogButton onClick={e => onCancel(e)} />
            <h2>Add New Account</h2>
            <p>
              The new account will have it's own purchases, billing records,
              users, and API keys.
            </p>
            <p>
              Since you are creating the account, you will be assigned as the
              owner and an admin of the account. After creating, you can invite
              additional users to join you on the account.
            </p>
            <Row>
              <Form.Group as={Col}>
                <Form.Label htmlFor="accountName">Account Name</Form.Label>
                <Form.Control
                  id="accountName"
                  name="accountName"
                  type="text"
                  isInvalid={errors.accountName}
                  {...register('accountName', { required: true })}
                />
                {errors.accountName && (
                  <p className="invalid-message">Account Name is required.</p>
                )}
              </Form.Group>
            </Row>
            <div className="btn-group">
              <button onClick={e => onCancel(e)}>Cancel</button>
              <button type="submit">Create Account</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
)

export default AddAccountModal
